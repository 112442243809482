import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { cloneDeep } from 'lodash';
import { PALLETE_OBJ } from 'src/app/app.constants';
import { SessionService } from 'src/app/_services/session.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';






@Component({
  selector: 'graduated-classification-dialog',
  templateUrl: './graduated-classification-dialog.component.html',
  styleUrls: ['./graduated-classification-dialog.component.scss']
})
export class GraduatedClassificationDialog implements OnDestroy {
  minimumValue: number = 0;
  maximumValue: number = 1;
  fromValueControl: FormControl = new FormControl(null, [Validators.required, Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]);
  toValueControl: FormControl = new FormControl(null, [Validators.required, Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]);
  subscriptionArr: any[] = [];
  classificationStyle: any[] = [];
  palleteObj = PALLETE_OBJ;
  selectedColor: string = '#ffffff';
  defaultValueControl: FormControl = new FormControl(null, [Validators.pattern(/^-?[0-9]\d*(\.\d+)?$/)]);
  valueValidationError: string = null;
  colorValidationError: string = null;
  hex: any = null;
  validForm: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<GraduatedClassificationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private sessionService: SessionService) { }

  ngOnInit() {

    if (this.data.type === 'classificationStyle') {
      this.classificationStyle = cloneDeep(this.data.classificationStyle);
      if (this.data.classificationStyle.length) {
        this.fromValueControl.setValue(this.classificationStyle[this.data.index].from);
        this.toValueControl.setValue(this.classificationStyle[this.data.index].to);
        this.selectedColor = this.classificationStyle[this.data.index].color;
        this.minimumValue = this.classificationStyle[0].from;
        this.maximumValue = this.classificationStyle[this.data.classificationStyle.length - 1].to;
      }
      // this.subscriptionArr.push(
      //   this.fromValueControl.valueChanges.subscribe((resp: any) => {
      //     this.validForm = this.isFormValid();
      //   }));
      // this.subscriptionArr.push(
      //   this.toValueControl.valueChanges.subscribe((resp: any) => {
      //     this.validForm = this.isFormValid();
      //   }));

    } else if (this.data.type === 'defaultValue') {
      this.selectedColor = this.data.defaultValue.color;
      this.defaultValueControl.setValue(this.data.defaultValue.value);

    } else if (this.data.type === 'add-class') {
      // this.fromValueControl.setValue(0);
      //   this.toValueControl.setValue(0);
    }
    this.validForm = this.isFormValid();
  }
  ngOnDestroy() {
    
  }

  fromValueChanged(event) {
    this.fromValueControl.setValue(event.target.value);
    this.validForm = this.isFormValid();
  }

  toValueChanged(event) {
    this.toValueControl.setValue(event.target.value);
    this.validForm = this.isFormValid();
  }

  numberOnly(event: any) {
    let value = event.key;
    if ((isNaN(value) || (typeof (+event.key) !== 'number') || (event.keyCode == 32)) && ![45, 46].includes(event.keyCode)) {
      event.preventDefault();
      return false
    } else {
      return true
    }
  }


  buttonClicked(bool) {
    if(!bool) {
      this.dialogRef.close();
      return;
    }
    if(!(this.isFormValid() && this.validateSelectedColor()))return;
    if (this.data.type === 'classificationStyle') {
      let fromValue = +this.fromValueControl.value
      let toValue = +this.toValueControl.value
      if (fromValue < toValue) {
        let index = this.data.index;
        let classificationStyle = this.classificationStyle.reduce((prev, curr, i) => {
          if (i < index) {
            if (curr.to <= fromValue) {
              return [...prev, curr];
            } else {
              return prev
            }
          } else if (i === index) {
            curr.from = fromValue;
            curr.to = toValue;
            curr.color = this.selectedColor
            curr.here = true
            return [...prev, curr];
          } else {
            if (curr.from >= toValue) {
              return [...prev, curr];
            } else {
              return prev
            }
          }
        }, []);

        let lastIndexObjOfNewClassification = classificationStyle[classificationStyle.length - 1];
        let firstIndexOfNewClassification = classificationStyle[0];
        if (firstIndexOfNewClassification.from > this.minimumValue) {
          classificationStyle.unshift({ color: this.classificationStyle[0].color, from: this.minimumValue, to: fromValue })
        }
        if (lastIndexObjOfNewClassification.to < this.maximumValue) {
          classificationStyle.push({ color: this.classificationStyle[this.classificationStyle.length - 1].color, from: lastIndexObjOfNewClassification.from, to: this.maximumValue })
        }
        let addedObjIndex = classificationStyle.findIndex((eachObj) => eachObj.here);

        if (addedObjIndex !== 0) {
          classificationStyle[addedObjIndex - 1].to = fromValue;
        }
        let changedObjIndex = classificationStyle.findIndex((eachObj) => eachObj.here);

        if (changedObjIndex !== classificationStyle.length - 1) {

          classificationStyle[changedObjIndex + 1].from = toValue;
        } else {
          classificationStyle[changedObjIndex].from = this.fromValueControl.value
        }
        delete classificationStyle[changedObjIndex]['here'];
        this.dialogRef.close(classificationStyle)
      }
    } else if (this.data.type === 'defaultValue') {
      this.dialogRef.close({ value: parseFloat(this.defaultValueControl.value), color: this.selectedColor });
    } else if (this.data.type === 'add-class') {
      let fromValue = +this.fromValueControl.value
      let toValue = +this.toValueControl.value
      let classificationStyle = [...this.data.classificationStyle];
      const existingClassIndex = classificationStyle.findIndex((ele, ind) => ele.from === fromValue && ele.to === toValue);
      if(existingClassIndex >= 0) {
        this.sessionService.openAlertDialog("Duplicate Class", "A class already exists with the exact min and max values.");
        return;
      }
      let fromIndex = classificationStyle.findIndex((ele, ind) => ele.from <= fromValue && ele.to > fromValue);
      let toIndex = classificationStyle.findIndex((ele, ind) => ele.from < toValue && ele.to >= toValue);
      let obj = {
        from: fromValue,
        to: toValue,
        color: this.selectedColor
      }
      if(fromValue === toValue) {
        const classificationLength = classificationStyle.length;
        if(![...Array(classificationLength).keys()].includes(fromIndex)) {
          // show alert that no feature will satisfy the class because the to/from value out of the range of the selected property value 
          // if(fromValue < classificationStyle[0].from) {
          //   classificationStyle.unshift(obj)
          // } else {
          //   classificationStyle.push(obj)
          // }
          this.showOutOfBoundAlert();
          return;
        } else {
          if(fromValue === classificationStyle[0]['from']) {
            classificationStyle.unshift(obj);
            classificationStyle[1]['from'] = fromValue + 1.e-15;
          } else if(toValue === classificationStyle[classificationStyle.length - 1]['to']) {
            classificationStyle[classificationStyle.length - 1]['to'] = toValue - 1.e-15;
            classificationStyle.push(obj);
          } else {
            const tarEle = cloneDeep(classificationStyle[fromIndex]);
            classificationStyle.splice(fromIndex + 1, 0, tarEle);
            classificationStyle[fromIndex]['to'] = fromValue - 1.e-15;
            classificationStyle[fromIndex + 1]['from'] = fromValue + 1.e-15;
            classificationStyle.splice(fromIndex + 1, 0, obj);
          }
          this.dialogRef.close(classificationStyle);
        }
      } else {
        if(fromIndex === -1 || toIndex === -1) {
          this.showOutOfBoundAlert();
          return;
          // show alert that either one or both of the specified values don't fall in the range of the selected property value;
        } else {
          // show alert that the ranges will get disturbed and some of them even get deleted.
          const confDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
              alertText: 'Other ranges may get affected (deleted/modified) because of the entered to and from values. Would you like to continue?',
              alertTitle: ''
            },
            disableClose: true
          })
          confDialog.afterClosed().subscribe(response => {
            if(!response) return;
            if(fromIndex === toIndex) {
              const tarEle = cloneDeep(classificationStyle[fromIndex]);
              classificationStyle.splice(fromIndex + 1, 0, tarEle);
              classificationStyle[fromIndex]['to'] = fromValue;
              classificationStyle[fromIndex + 1]['from'] = toValue;
              classificationStyle.splice(fromIndex + 1, 0, obj);
            } else {
              classificationStyle[fromIndex]['to'] = fromValue;
              classificationStyle[toIndex]['from'] = toValue;
              if(toIndex - fromIndex > 1) classificationStyle.splice(fromIndex + 1, (toIndex - fromIndex - 1));
              classificationStyle.splice(fromIndex + 1, 0, obj);
            }
            this.dialogRef.close(classificationStyle);
          })
        }
      }
    }
  }

  showOutOfBoundAlert = () => {
    this.sessionService.openAlertDialog("Out Of Range", "No feature will satisfy the class because the to/from value is/are out of the range of the selected property value");
  }

  isFormValid() {
    switch (this.data.type) {
      case "classificationStyle":
      case "add-class":
        if(this.fromValueControl.valid && this.toValueControl.valid) {
          this.valueValidationError = null;
          let valid = true;
          if(!this.isColorSelected()) valid = false;
          if(this.fromValueControl.valid && this.toValueControl.valid && (+this.fromValueControl.value > +this.toValueControl.value)) {
            this.valueValidationError = "Min value should always be less than or equal to max value";
            valid = false;
          }
          return valid;
        } else return false;
      case "defaultValue":
        if(this.isColorSelected() && this.defaultValueControl.valid) return true;
    }
    return false;
  }

  isColorSelected = (): boolean => {
    let regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    if(!(this.selectedColor && regex.test(this.selectedColor.trim()))) {
      return false;
    }
    return true;
  }

  validateSelectedColor = (): boolean => {
    const res = this.isColorSelected();
    if(!res) this.colorValidationError = "Invalid color code";
    else this.colorValidationError = null;
    return res;
  }
}
