import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { surveyAnalyticsSuccessAction, surveyAnalyticsActionTypes, surveyAnalyticsStartAction } from '../actions/surveyor-analytics.actions'
import { from } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { SurveyAnalyticsService } from '../../_services/survey-analytics.service';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

@Injectable()
export class  SurveyAnalyticsEffects {
  constructor(
    private actions$: Actions,
    private SurveyAnalyticsService: SurveyAnalyticsService,
    private toaster: ToastrService
  ) { }


  @Effect()

  surveyorData$ = this.actions$.pipe(
    ofType(surveyAnalyticsActionTypes.surveyAnalyticsStart),
    switchMap((action: surveyAnalyticsStartAction) => {

      return this.SurveyAnalyticsService.getLayerTableData(action.params).pipe(
        map((response: any) => {
          if (response.success) {
            response.data.page = action.params.page
            response.data.surveyor = action.params.surveyor
            return new surveyAnalyticsSuccessAction(response.data);
          }
          else {
            if (response.error.lenght > 0) {
              this.toaster.error(response.error[0].message)
            }
          }
        }),
      )
    })
  )


}
