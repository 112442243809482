import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { GetRasterToolProcessingStartAction, SessionManagementTypes } from 'src/app/store/actions/session-management.actions';
import { SessionService } from 'src/app/_services/session.service';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: "app-spatial-filter",
  templateUrl: "./spatial-filter-dialog.component.html",
  styleUrls: ["./spatial-filter-dialog.component.scss"]
})

export class SpatialFilterDialogComponent {
  rasterFile: FormControl = new FormControl(null, [Validators.required]);
  vectorLayer: FormControl = new FormControl(null, [Validators.required]);
  minSlope: FormControl = new FormControl(0, [Validators.required, Validators.min(0), Validators.max(90), Validators.pattern(/^(\d*\.)?\d+$/)]);
  maxSlope: FormControl = new FormControl(90, [Validators.required, Validators.min(0), Validators.max(90), Validators.pattern(/^(\d*\.)?\d+$/)]);
  bufferRadius: FormControl = new FormControl(null, [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]);
  enableAction: boolean = false;
  slopeError: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    private store: Store<any>,
    private sessionService: SessionService,
    private actions: Actions,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;

   }
  ngOnInit() {
    this.actions.pipe(
      ofType(SessionManagementTypes.getRasterToolProcessingSuccess),
      tap((resp: any) => {
        if (resp && resp.data && resp.data.success) {
          this.cancelClicked();
        }
      })
    ).subscribe()
  }
  confirmButtonClicked() {
    if (!this.enableAction) return;
    const rData = {
      vector_layer_id: this.vectorLayer.value.parent_layer_id,
      elevation_raster_id: this.rasterFile.value.parent_layer_id,
      min_slope: +this.minSlope.value,
      max_slope: +this.maxSlope.value,
      buffer_radius: +this.bufferRadius.value,
      session_id: this.data.sessionId
    }
    this.store.dispatch(new GetRasterToolProcessingStartAction(rData));
  }
  checkFormValid() {
    this.enableAction = this.isFormValid();
  }
  isFormValid() {
    this.slopeError = (
      this.minSlope.valid && this.maxSlope.valid &&
      +this.minSlope.value >= +this.maxSlope.value
    )
    return (!!this.rasterFile.value &&
      !!this.vectorLayer.value &&
      this.minSlope.valid && this.maxSlope.valid &&
      +this.minSlope.value < +this.maxSlope.value &&
      this.bufferRadius.valid
    )
  }
  cancelClicked() {
    this.sessionService.selectedRasterToolIndex = null;
    this.dialogRef.close();
  }
}