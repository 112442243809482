import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelect, MatOption } from "@angular/material";
import { SessionService } from "src/app/_services/session.service";
import { cloneDeep } from "lodash";
import { ADVANCED_TOOL_TYPE, LAYER_ACTIVE_ICONS, LAYER_INACTIVE_ICONS } from "src/app/app.constants";
import { SpatialJoinSummaryDialog } from "../spatial-join-summary/spatial-join-summary.component";
import { SpatialJoinAddDialog } from "../spatial-join-add/spatial-join-add.component";
import { CHOICES, GEOMETRY_OPTIONS, INPUT_PARAMS_OBJ, TILObject, TypeSubTypeObject } from "src/app/dialogs/advanced-tools-dialog/advanced-tools-dialog.component";
import { InputParams } from "src/app/dialogs/advanced-tools-dialog/input-params.model";
import { GetAdvancedToolsSearchResultStartAction, GetAdvancedToolsSearchResultSuccessAction, GetLayerAttributesStartAction, GetLayerAttributesSuccessAction, GetSimilaritySearchStartAction, GetUniquePropertyValuesSuccessAction, SessionManagementTypes } from "src/app/store/actions/session-management.actions";
import { Store } from "@ngrx/store";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";
import { Subscription } from "rxjs";
import { layerAttributesSelector, propertiesTypeSelector, uniquePropertyValuesSelector } from 'src/app/store/selectors/session-management.selector';
import { Actions, ofType } from '@ngrx/effects';
import { first, tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-spatial-join",
  templateUrl: "./similarity-search.component.html",
  styleUrls: ["./similarity-search.component.scss"]
})

export class SimilaritySearch {

  @ViewChild('select',{}) select: MatSelect;

  layerActiveIcons = LAYER_ACTIVE_ICONS;
  layerInactiveIcons = LAYER_INACTIVE_ICONS;
  inputLayer: FormControl = new FormControl("", [Validators.required]);
  joinLayer: FormControl = new FormControl("", [Validators.required]);
  groupName: FormControl = new FormControl(1, [Validators.required,Validators.max(100), Validators.min(0)]);
  isAllRanks: boolean = false;
  isBestMatch: boolean = false;
  panelOpenState = false;
  relations: FormControl = new FormControl([]);
  inputLayersList: Array<any> = [];
  joinLayersList: Array<any> = [];
  spatialRelations: Array<any> = [
    {key: 'Intersects', value: 0},
    {key: 'Contains', value: 1},
    {key: 'Within', value: 5},
    {key: 'Overlaps', value: 4},
    {key: 'Crosses', value: 6},
    {key: 'Touches', value: 3},
    {key: 'Equals', value: 2}
  ]
  calculateSummary: boolean = false;
  selectedToolIndex: number = null;
  sessionId: string = null;
  selectedRelations: Array<number> = [];
  selectedRelationsKeys: Array<string> = [];
  actionChoices: any;
  geometryOptions: { type: string; display_text: string; icon: string; }[];
  showChoices: boolean;
  showAdditionalInputs: boolean;
  submitClicked: boolean;
  additionalInputObj: any = INPUT_PARAMS_OBJ;
  additionalInputs: Array<any>;
  additionalInputKeys: Array<string>;
  hasErrors: boolean;
  errorMessage: string = "";
  inputParams: InputParams = {
    difference: null,
    radius: null,
    steps: null,
    distance: null,
    units: null,
    tolerance: null,
    maxEdge: null,
    concavity: null,
    propertyName: null,
    highQuality: null,
    mutate: null,
    cellSize: null,
    gridType: null,
    property: null,
    weight: null,
    breaks: null,
    cellSide: null,
    extent: null
  }
  selectedChoice: any;
  selectedLayer1: any = null;
  selectedLayer2: any = null;
  selectedLayers: Array<any> = [];
  selectedLayerIds: Array<string> = [];
  layersList1: Array<any> = [];
  showGeometrySelectionOptions = false;
  layersList2: Array<any> = [];
  subscriptionArr: Array<Subscription> = [];
  activeLayerIds: Array<string> = [];
  matchMethod: FormControl = new FormControl('');
  selectedAttribute: FormControl = new FormControl('');

  DISPLAY_HEADER = {
    27: {
      'display_header': 'SELECT AREA OF INTEREST'
    },
    28: {
      'display_header': 'SELECT AREA OF INTEREST'
    },
    29: {
      'display_header': 'SELECT AREA OF INTEREST'
    },
    30: {
      'display_header': 'SELECT AREA OF INTEREST'
    }
  }
  layerAttributes: any[];
  matchMethodOptions = [
    { key : 'ATTRIBUTE_VALUES', value : 'ATTRIBUTE' },
    { key : 'RANKED_ATTRIBUTE_VALUES', value : 'RANKED ATTRIBUTE' },
    { key : 'ATTRIBUTE_PROFILES', value : 'ATTRIBUTE PROFILES' },
    ]
  isEntered: boolean = false;
  attributeSelection: Subscription;
  showPopUp: boolean = false;
  warningMessage: any;
  finalPayload: {};
  constructor(
    private dialogRef: MatDialogRef<SimilaritySearch>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService,
    private _store: Store<any>,
    private navigationService: NavigationCtrl,
    private actions: Actions,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.sessionService.setSimilarSearchTab(this.data.currentTabNo);
    this.selectedToolIndex = this.data.selectedToolIndex;
    this.additionalInputs = this.additionalInputObj[this.selectedToolIndex];
    if(this.data.isSecondScreen){
    this.sessionService.getsimilarSearchAttributes().subscribe(item => {
      this.layerAttributes = item;
      })
    }
    switch(this.selectedToolIndex) {
      case 23:
        this.actionChoices = CHOICES.filter(choice => choice.index === 2);
        this.geometryOptions = GEOMETRY_OPTIONS.filter(option => option.type === 'point');
        this.showChoices = true;
        this.showAdditionalInputs = false;
        break;
      case 26:
        this.actionChoices = CHOICES.filter(choice => choice.index === 2);
        this.geometryOptions = GEOMETRY_OPTIONS.filter(option => option.type === 'polygon');
        // this.showAdditionalInputs = (this.selectedLayer1 && (this.additionalInputs.length > 0));
        break;
      case 27:
      case 28:
      case 29:
      case 30:
        this.actionChoices = CHOICES.filter(choice => choice.index === 2);
        this.geometryOptions = GEOMETRY_OPTIONS.filter(option => option.type === 'polygon');
        this.showChoices = true;
        this.showAdditionalInputs = true;
        break;
      case 33:
        this.actionChoices = [];
        this.showChoices = false;
        this.showAdditionalInputs = false;
      default:
        this.actionChoices = CHOICES;
        this.showChoices = false;
        // this.showAdditionalInputs = true;
        this.geometryOptions = GEOMETRY_OPTIONS;
        this.showAdditionalInputs = (this.selectedLayer1 && (this.additionalInputs.length > 0));
        break;
    };
    this.additionalInputKeys = this.listInputKeys();
    const layersList1Types = this.getLayerTypeList(TILObject.find(obj => obj.index === this.selectedToolIndex).list1);
    const layersList2Types = this.getLayerTypeList(TILObject.find(obj => obj.index === this.selectedToolIndex).list2);
    // this.layersList1 = this.sessionService.sessionLayers.filter(layer => TypeSubTypeObject[(TILObject.find(obj => obj.index === this.selectedToolIndex).list1)].indexOf(layer.type.toLowerCase()) > -1);
    // this.layersList2 = this.sessionService.sessionLayers.filter(layer => TypeSubTypeObject[(TILObject.find(obj => obj.index === this.selectedToolIndex).list2)].indexOf(layer.type.toLowerCase()) > -1);
    this.layersList1 = this.sessionService.sessionLayers.filter(layer => (layer.show && ((layer.layer_type === 1) || (layer.layer_type === 3) || (layer.layer_type === 4)))).filter(layer => layersList1Types.indexOf(layer.type.toLowerCase()) > -1);
    if(this.data.currentTabNo == 2){
    //   console.log("entered");
      let selectedLayer;
      this.sessionService.getSimilarSearchData().subscribe(data => selectedLayer = data[0].input_layer_1);
      this.layersList1 = this.layersList1.filter(layer => (layer.type == selectedLayer.type))
    }
    this.layersList2 = this.sessionService.sessionLayers.filter(layer => (layer.show && ((layer.layer_type === 1) || (layer.layer_type === 3) || (layer.layer_type === 4)))).filter(layer => layersList2Types.indexOf(layer.type.toLowerCase()) > -1);
    this.activeLayerIds = this.layersList1.map(l => l.id);
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        tap((action: GetAdvancedToolsSearchResultSuccessAction) => {
          this.navigationService.setShowLayerTable(true);
          this.sessionService.setShowAdvancedToolsSelectionData(true);
          this.dialogRef.close(true);
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this._store.select(layerAttributesSelector).subscribe(properties => {
        if(this.selectedToolIndex === ADVANCED_TOOL_TYPE['dissolve']) {
          this.additionalInputs[0].propertyName = properties;
        }
      })
    );
    this.subscriptionArr.push(
      this._store.select(propertiesTypeSelector).subscribe(properties => {
        if([ADVANCED_TOOL_TYPE['interpolate'], ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines'], ADVANCED_TOOL_TYPE['tin']].includes(this.selectedToolIndex)) {
          const filteredProperties = Object.keys(properties).filter(property => properties[property] === 'Number');
          const propertyIndex = this.additionalInputs.findIndex((inputs, i) => {
            if(Object.keys(inputs)[0] === "property") return true;
          });
          if(!filteredProperties.length) this.inputParams['property'] = null;
          this.additionalInputs[+propertyIndex].property = filteredProperties;
        }
      })
    );
    this.subscriptionArr.push(
      this._store.select(uniquePropertyValuesSelector).subscribe(values => {
        if([ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines']].includes(this.selectedToolIndex)) {
          const breakIndex = this.additionalInputs.findIndex((inputs, i) => {
            if(Object.keys(inputs)[0] === "breaks") return true;
          });
          this.additionalInputs[+breakIndex].breaks = values.sort(function (a, b) { return a-b});
          if(!values.length) this.inputParams['breaks'] = null;
        }
      })
    );
    this.subscriptionArr.push(
      this.sessionService.getIsWarningEnabledForSimilaritySearch().subscribe(response => {
        if (response) {
          this.warningMessage = this.sessionService.warningMessage;
          this.showPopUp = true;
        } else {
          this.showPopUp = false;
        }
      })
    );
  }

  setSelectedOptions(value) {
    const index = this.selectedRelations.findIndex(relation => relation === value);
    if(index === -1) {
      this.selectedRelations.push(value);
    } else {
      this.selectedRelations.splice(index, 1);
    }
    this.selectedRelationsKeys = this.spatialRelations.filter(sr => this.selectedRelations.includes(sr.value)).map(val => val.key);
  }

  removeFilterChip(key){
    let relations = {}
    relations = this.spatialRelations.filter(sr => (sr.key == key));
    // console.log(relations)
    this.select.options.forEach((item: MatOption) => {
      if (item.selected && (relations[0].value == item.value)) {
        item.deselect()
      }
    });
    this.setSelectedOptions(relations[0].value);
}

getLayerTypeList(arr) {
  if(!arr.length) return arr;
  let result = []
  arr.forEach(type => {
    result = result.concat(TypeSubTypeObject[type])
  });
  return result;
}

 
  
  enableNext = () => {
    // return this.inputLayer.valid && this.joinLayer.valid && this.relations.valid && this.relations.value.length;
    return this.inputLayer.valid && this.joinLayer.valid && this.selectedRelations.length;
  }

  toProperCase(str) {
    return str[0].toUpperCase() + str.slice(1);
  }
  checkHeader(){
    return Object.keys(this.DISPLAY_HEADER).some(index => index === this.data.selectedToolIndex.toString())
  }

  geometrySelected(geometryType) {
    if(this.hasErrors) return;
    this.sessionService.selectedGeometryType = geometryType;
    this.sessionService.setSelectedAdvancedToolGeometryType(geometryType);
    switch (this.selectedToolIndex) {
      case 1:
      case 6:
      case 7:
      case 8:
        this.sessionService.selectedGeometryLayerId = this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null;
        this.sessionService.selectedGraphicsLayerType = this.selectedLayer2 ? this.selectedLayer2.type : null;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 24:
      case 25:
      case 26:
      case 31:
      case 38:
        this.sessionService.selectedGeometryLayerId = this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null;
        this.sessionService.selectedGraphicsLayerType = this.selectedLayer1 ? this.selectedLayer1.type : null;
      default:
        break;
    }
    this.sessionService.setAdvancedToolsGraphicsSelectionActive(true);
    if(this.navigationService.pointPlotSubscription) {
      // this.navigationService.pointPlotSubscription.unsubscribe();
      this.navigationService.pointPlotSubscription = null;
      this.sessionService.setAdvancedToolsDrawnCoordinates("");
    }
    this.navigationService.showGeometry();
    const data = {
      selectedLayer1: this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
      selectedLayer2: this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null,
      type: 'graphics'
    }
    this.dialogRef.close(data);
  }

  next = () => {
    if(this.calculateSummary) {
      const spatialSummaryDialog = this.dialog.open(SpatialJoinSummaryDialog, {
        width: "600px",
        // height:"583px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          selectedToolIndex: this.selectedToolIndex,
          inputLayer: this.inputLayer.value,
          joinLayer: this.joinLayer.value,
          // relations: this.relations.value,
          relations: this.selectedRelations,
          sessionId: this.sessionId
        }
      })
      spatialSummaryDialog.afterClosed().subscribe((response: any) => {
        if(response) this.dialogRef.close();
      })
    } else {
      const spatialAddDialog = this.dialog.open(SpatialJoinAddDialog, {
        width: "600px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          selectedToolIndex: this.selectedToolIndex,
          inputLayer: this.inputLayer.value,
          joinLayer: this.joinLayer.value,
          // relations: this.relations.value,
          relations: this.selectedRelations,
          sessionId: this.sessionId
        }
      })
      spatialAddDialog.afterClosed().subscribe((response: any) => {
        if(response) this.dialogRef.close();
      })
    }
  }

  close = () => {
    this.dialogRef.close();
  }

  onClickArrrow(type){
    if(type == 'up')
      if(this.groupName.value < this.layerAttributes.length)
        this.groupName.setValue(this.groupName.value + 1);
    if(type == 'down')
      this.groupName.setValue(this.groupName.value - 1);
  }

  validateInputs() {
    if(!this.showAdditionalInputs) {
      this.hasErrors = false;
      this.errorMessage = "";
    } else {
      // if (this.hasErrors) return;
      for(let i = 0; i < this.additionalInputs.length; i++) {
        let input = this.additionalInputs[i];
        const value = (this.inputParams[this.additionalInputKeys[i]] ? this.inputParams[this.additionalInputKeys[i]].toString() : null) || (Array.isArray(this.additionalInputs[i][this.additionalInputKeys[i]]) ? null : this.additionalInputs[i][this.additionalInputKeys[i]]);
        if(input.mandatory) { //check if the input is mandatory or not.
          if(value && value.toString() && value.toString().length) {
            if (input.type === 'input') {
              switch(this.selectedToolIndex) {
                case 5: //can be of negative value
                case 14:
                  if(!(/^-?[0-9]\d*(\.\d+)?$/.test(this.inputParams[this.additionalInputKeys[i]]))) {
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive or negative number`;
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    if(this.selectedToolIndex === 5) {
                      // if((this.inputParams.units) && (this.inputParams.radius)) {
                      // this.inputParams[this.additionalInputKeys[i]] = this.convertValueToKilometers(this.inputParams.radius)
                      // }
                    // } else {

                      this.inputParams[this.additionalInputKeys[i]] = +value;
                    }
                  }
                  break;
                case 10:
                case 11:
                case 12:
                case 18:
                case 27:
                case 28:
                case 29:
                case 30:
                  if(!(/^[0-9]\d*(\.\d+)?$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 15:
                  const iValue = (+value).toFixed(20);
                  if(!(/^[0-1]?(\.\d+)?$/.test(iValue)) || (+value > 1) || (+value < 0)) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} should be a decimal value from 0 to 1`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 22:
                  if(!(/^[0-9]*$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
              }
            } else if(input.type === 'dropdown' || input.type === 'multi_select' || input.type === 'switch') {
              this.hasErrors = false;
              this.errorMessage = "";
            }
          } else {
            this.hasErrors = true;
            this.errorMessage = `${this.additionalInputs[i].display_text} cannot be empty`;
            return;
          }
        } else {
          if(value && value.toString() && value.toString().length) {
            // const value = this.inputParams[this.additionalInputKeys[i]];
            if (input.type === 'input') {
              switch(this.selectedToolIndex) {
                case 5: //can be of negative value
                case 14:
                  if(!(/^-?[0-9]\d*(\.\d+)?$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive or negative number`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 10:
                case 11:
                case 12:
                case 18:
                case 27:
                case 28:
                case 29:
                case 30:
                  if(!(/^[0-9]\d*(\.\d+)?$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 15:
                  const iValue = (+value).toFixed(20);
                  if(!(/^[0-1]?(\.\d+)?$/.test(iValue)) || (+value > 1) || (+value < 0)) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} should be a decimal value from 0 to 1`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 22:
                  if(!(/^[0-9]*$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
              }
                  break;
              }
            } else if(input.type === 'dropdown' || input.type === 'multi_select' || input.type === 'switch') {
              this.hasErrors = false;
              this.errorMessage = "";
            }
          } else {
            this.hasErrors = false;
            this.errorMessage = "";
          }
        }
      }
    }
  }

  listInputKeys() {
    if(!this.showAdditionalInputs) return [];
    const inputKeys = [];
    this.additionalInputs.forEach(element => {
      inputKeys.push(Object.keys(element)[0])
    });
    return inputKeys;
  }

  selectLayer1(sLayer) {
    this.selectedLayer1 = sLayer;
    this.sessionService.setFirstSelectedAdvancedToolLayer(sLayer);
    this.showAdditionalInputs = (this.selectedLayer1 && !!this.additionalInputs.length);
    this.additionalInputKeys = this.listInputKeys();
    if(![6, 7, 8].includes(this.selectedToolIndex)) this.layersList2 = this.layersList2.filter(layer => layer.id !== sLayer.id);
    this.showHideChoices();
    // if(this.selectedToolIndex === ADVANCED_TOOL_TYPE['dissolve']) {
    if([ADVANCED_TOOL_TYPE['dissolve'], ADVANCED_TOOL_TYPE['interpolate'], ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines'], ADVANCED_TOOL_TYPE['tin']].includes(this.selectedToolIndex)) {
      this._store.dispatch(new GetLayerAttributesSuccessAction([{all_attribues: [], attribute_type: {}}]));
      this._store.dispatch(new GetUniquePropertyValuesSuccessAction([]));
      this._store.dispatch(new GetLayerAttributesStartAction(sLayer.parent_layer_id));
    }
    if(this.showAdditionalInputs && [5, 14, 27, 28, 29, 30].includes(this.selectedToolIndex)) {
      this.inputParams['units'] = "meters";
    }
  }

  showHideChoices(){
    // if(this.hasErrors) {
    //   this.showChoices = false;
    //   return;
    // }
    const layer1 = (TILObject.find(obj => obj.index === this.selectedToolIndex)).list1;
    const layer2 = (TILObject.find(obj => obj.index === this.selectedToolIndex)).list2;
    if(layer1.length && layer2.length) {
      if(this.selectedLayer1 && Object.keys(this.selectedLayer1).length && this.selectedLayer2 && Object.keys(this.selectedLayer2).length) this.showChoices = true;
      else this.showChoices = false;
      return; 
    } else if(layer1.length && !layer2.length) {
      if(Object.keys(this.selectedLayer1).length) this.showChoices = true;
      else this.showChoices = false;
      return;
    } else if(!layer1.length && layer2.length) {
      if(Object.keys(this.selectedLayer2).length) this.showChoices = true;
      else this.showChoices = false;
      return;
    }
  }
  selectLayer2(sLayer) {
    this.selectedLayer2 = sLayer;
    this.sessionService.setSecondSelectedAdvancedToolLayer(sLayer);
    if(![6, 7, 8].includes(this.selectedToolIndex)) this.layersList1 = this.layersList1.filter(layer => layer.id !== sLayer.id);
    this.showHideChoices();
  }

  setChoice(value) {
    this.submitClicked = true;
    this.validateInputs();
    if(this.hasErrors) return;
    // const choiceKeys = Object.keys(CHOICES);
    const choiceKeys = CHOICES.map(choice => choice.index);
    this.selectedChoice = value;
    this.sessionService.setAdditionalInputs(this.inputParams);
    let selectedLayerIds = [];
    if(this.selectedLayer1) selectedLayerIds.push(this.selectedLayer1.parent_layer_id);
    if(this.selectedLayer2) selectedLayerIds.push(this.selectedLayer2.parent_layer_id);
    this.sessionService.setSelectedLayersInTools(selectedLayerIds);
    switch (+value) {
      case choiceKeys[0]:
        //open query dialog
        this.showGeometrySelectionOptions = false;
        this.sessionService.setSelectedAdvancedToolChoiceType('query');
        this.sessionService.setAdvancedToolsGraphicsSelectionActive(false);
        const data = {
          selectedLayer1: this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
          selectedLayer1Type: (this.selectLayer1 && this.selectLayer1 != null) ? this.selectedLayer1.type : null,
          selectedLayer2: this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null,
          selectedLayer2Type: this.selectedLayer2 ? this.selectedLayer2.type : null,
          type: 'query'
        }
        this.dialogRef.close(data);
        break;
      case choiceKeys[1]:
        //show draw options
        if(this.hasErrors) {
          this.showGeometrySelectionOptions = false;
        } else {
          this.showGeometrySelectionOptions = true;
        }
        this.sessionService.setSelectedAdvancedToolChoiceType('graphics');
        break;
      case choiceKeys[2]:
        //Directly make api call to get the fearures
        this.sendRequest();
        // this.showGeometrySelectionOptions = false;
        // this.sessionService.setSelectedAdvancedToolChoiceType('all');
        // this.sessionService.setAdvancedToolsGraphicsSelectionActive(false);
        break;
      default:
        break;
    } 
  }
  sendRequest() {
    const payload = {
      input_layer_1:{
        layer_id: this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
        type: this.selectedLayer1 ? this.selectedLayer1.type : null
      },
      // input_layer_2:{
      //   layer_id: this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null,
      //   type: this.selectedLayer2 ? this.selectedLayer2.type : null
      // },
      input_params:{...this.inputParams},
      feature_type: ADVANCED_TOOL_TYPE[this.selectedToolIndex],
      selection_type: "all",
      query: {limit: 100, page: 1}
    }
    let layerData;
    let testarray = [];
    testarray.push(payload);
    if(this.data.currentTabNo == 2){
      this.sessionService.getsimilarSearchAttributes().subscribe(data => {
      if(!this.isEntered)
    {
      console.log(data);
      this.layerAttributes = data
      this.isEntered = true;
    }
    });
  }
    this.sessionService.getSimilarSearchData().subscribe(data => layerData = data);
    this.sessionService.setSimilarSearchData([...layerData,...testarray]);
    console.log(this.data.currentTabNo);
    this._store.dispatch(new GetLayerAttributesStartAction(payload.input_layer_1.layer_id));
    this.attributeSelection = this._store.select(layerAttributesSelector).subscribe(attributes => {
      setTimeout(() => {
      //   // if ((this.isQueryData || this.isGraphicsData) && !this.showAdvancedToolsSelectionData) {
        if(this.data.currentTabNo == 1){
          this.sessionService.setsimilarSearchAttributes(attributes);
          this.dialogRef.close(payload);
          this.attributeSelection.unsubscribe();
        }
        else{
          console.log(this.layerAttributes)
          console.log(attributes)
          attributes = attributes.map(item => item.toLowerCase())
          this.layerAttributes = this.layerAttributes.filter(item => attributes.includes(item.toLowerCase()))
        // this.layerAttributes =  this.layerAttributes.filter(item => attributes.includes(item))
        if(this.layerAttributes.length > 0){
          this.sessionService.setsimilarSearchAttributes(this.layerAttributes);
          this.dialogRef.close(payload);
          this.attributeSelection.unsubscribe();
        }else{
          if(this.data.currentTabNo == 2){
            this.toastr.warning("Attributes are not matched ..")
            this.attributeSelection.unsubscribe();
          }
        }
      }
      //     // if (!Object.keys(this.userData).length) return;
      //     // this.displayedColumns = columns;
      //   // }
      }, 2000);
    });

    // setTimeout(() => {

    // })
    // this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    // this.navigationService.setShowLayerTable(true);
    // this.sessionService.setShowAdvancedToolsSelectionData(true);
    // this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  submitData(){
    console.log(this.isAllRanks);
    console.log(this.isBestMatch);
    console.log(this.groupName)
    let selectedLayers = [];
    this.sessionService.getSimilarSearchData().subscribe(
      data =>{
        if(data){
          selectedLayers = data;
        }
      }
    )

    if(this.isAllRanks && !this.groupName.valid){
      this.toastr.warning(`Please enter valid rank values ..`)
      return false;
    }
    
    if(selectedLayers.length > 0 && this.selectedAttribute.value && this.matchMethod.value){
      
    const payload = {
      "input_layer_1": selectedLayers[0].input_layer_1,
      "input_layer_2": selectedLayers[1].input_layer_1,
      "input_params": {
        "numResults": !this.isAllRanks ? this.layerAttributes.length : this.groupName.value,
        "fieldNames": this.selectedAttribute.value,
        "similarityType": !this.isBestMatch ? "MOST_SIMILAR" : "MOST_DISSIMILAR",
        "matchMethod": this.matchMethod.value,
        "allRank": !this.isAllRanks ? "True" : "False"
      },
      "feature_type": "similarity-search",
      "selection_type_input" : selectedLayers[0].selection_type,
      "selection_type_target" : selectedLayers[1].selection_type
    }
    let finalPayload = {};
    // if(selectedLayers[0].selection_type == 'graphics'){
      let query1 = {
        'query_input' : selectedLayers[0].query
      }
      finalPayload = Object.assign(payload,query1);
    // }
    // if(selectedLayers[1].selection_type == 'graphics'){
      let query2 = {
        'query_target' : selectedLayers[1].query
      }
      finalPayload = Object.assign(payload,query2);
      this.finalPayload = finalPayload;
    // }
    this._store.dispatch(new GetSimilaritySearchStartAction(finalPayload));
    // this.navigationService.setShowLayerTable(true);
    // this.sessionService.setShowAdvancedToolsSelectionData(true);
    // this.sessionService.setAdvancedToolsSearchPayload(finalPayload);
    console.log(finalPayload)
  }else{
    if(!(selectedLayers.length > 0) || !(this.matchMethod.value)){
      this.toastr.warning(`Please fill all fields..`)
      return false;
    }else if(this.selectedAttribute.value < 1){
      this.toastr.warning(`Selected attribute names has null values and cannot be considered for analysis.Please re-select attributes.`)
      return false;
    }
    
  }
  }

  onClickBack(){
    console.log("On click back")
    this.dialogRef.close("move-back");
  }

  onClickYes(){
    this.showPopUp = false;
    this.navigationService.setShowLayerTable(true);
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.sessionService.setAdvancedToolsSearchPayload(this.finalPayload);
    this._store.dispatch(new GetSimilaritySearchStartAction(this.finalPayload));
    // this.dialogRef.close();
    this.sessionService.setIsWarningEnabledForSimilaritySearch(false);
  }

  onClickCancel(){
    this.showPopUp = false;
    this.sessionService.warningMessage = '';
    this.sessionService.setIsWarningEnabledForSimilaritySearch(false);
    // this.dialogRef.close();
  }
}