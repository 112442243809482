import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA,} from "@angular/material";
import { SessionService } from 'src/app/_services/session.service';
@Component({
  selector: 'app-data-library-layer-details',
  templateUrl: './data-library-layer-details-dialog.component.html',
  styleUrls: ['./data-library-layer-details-dialog.component.scss']
})
export class DataLibraryLayerDetailsDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DataLibraryLayerDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService
  ) { }
  ngOnInit() {
    const size = +this.data.size
    if(size) {
      if(size < (1024 * 1024)) {
        this.data.size = `${this.sessionService.toDecimalString(size/1024, 2)} KB`;
      } else if (size < (1024 *1024 * 1024)) {
        this.data.size = `${this.sessionService.toDecimalString(size/(1024*1024), 2)} MB`;
      } else {
        this.data.size = `${this.sessionService.toDecimalString(size/(1024*1024*1024), 2)} GB`;
      }
    }
  }
  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}
