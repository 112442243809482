import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-query-name-dialog',
  templateUrl: './query-name-dialog.component.html',
  styleUrls: ['./query-name-dialog.component.scss']
})
export class QueryNameDialogComponent implements OnInit {
  queryName: string = '';
  constructor(
    private dialogRef: MatDialogRef<QueryNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
  ) { 
    dialogRef.disableClose =true;
  }

  ngOnInit() {
  }

  onCancelClick() {
    this.dialogRef.close({status: false});
  }

  onSaveClick() {
    if(this.queryName.trim().length == 0) {
      this.toast.info("Enter a query name");
      return;
    } else {
      let regex =/[!@#$%^&*(),.?":{}|<>]/g
      if(!regex.test(this.queryName.trim())) {
        let index = this.data.queries.filter(({layer_id}) => layer_id == this.data.selectedLayer.parent_layer_id).findIndex((query) =>query.query_name.toLowerCase() ===  this.queryName.toLowerCase());
        if(index === -1) {
  
          this.dialogRef.close({status: true, queryName: this.queryName });
        } else {
          this.toast.error('Query name already exists');
        }

      } else {
        this.toast.error('please enter valid query name')
      }
    }
  }

  
}
