import { isArray } from 'util';
import { PropertyTax } from "../../models/property-tax";
import { User, Ward } from "../../models/user-ward-assignments"
import { PropertyTaxActionTypes, PropertyTaxActions } from "../actions/property-tax.actions";
// import * as fromProperty from '../actions/auth.actions'

export interface State {
    data: PropertyTax | null;
    loading: boolean;
    loaded: boolean;
    error: any;
    users: User[];
    wards: Ward[];
    checkedUsers: User[];
    checkedWards: User[];
    ward: Ward;
    user: User;
    access: boolean;
    totalWardCount: number;
    total: number;
    floorData: any;
    editedGeometryResp: any,
    adminData: any,
    adminApprovedData: any,
    filteredData: any,
    cityosLayerAttributes: any,
    layerPremiseTableData: any
    userData: any,
    propertyTaxForms: any,
    entryLogs: any
}


export const initialState: State = {
    data: null,
    users: [],
    wards: [],
    checkedUsers: [],
    checkedWards: [],
    loading: false,
    loaded: false,
    error: null,
    ward: null,
    user: null,
    access: false,
    totalWardCount: 0,
    total: 0,
    floorData: null,
    editedGeometryResp: {},
    adminData: {},
    adminApprovedData: {},
    filteredData: {},
    cityosLayerAttributes: {},
    layerPremiseTableData: {},
    userData: {},
    propertyTaxForms: {},
    entryLogs: {}
};


export function PropertyTaxReducer(
    state = initialState,
    action: PropertyTaxActions
): State {
    switch (action.type) {
        case PropertyTaxActionTypes.CHECK_ACCESS_PROPERTY_TAX:
            return {
                ...state,
                loading: true,
            }

        case PropertyTaxActionTypes.CHECK_ACCESS_PROPERTY_TAX_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                access: action.payload
            }

        case PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA:
            return {
                ...state,
                loading: true,
            }

        case PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload
            }

        case PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            }

        case PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA:
            return {
                ...state,
                loading: true,
                data: null
            }

        case PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload
            }

        case PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                data: null,
                error: action.payload
            }

        case PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA:
            return {
                ...state,
                loading: true,
                data: null
            }

        case PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload
            }

        case PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                data: null,
                error: action.payload
            }

        case PropertyTaxActionTypes.LIST_USERS:
            return {
                ...state,
                loading: true,
            }

        case PropertyTaxActionTypes.LIST_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                users: action.payload,
                total: action.total
                // users: [...state.users, ...action.payload]
            }

        case PropertyTaxActionTypes.LIST_WARDS:
            return {
                ...state,
                loading: true,
            }

        case PropertyTaxActionTypes.LIST_WARDS_SUCCESS:
            let returnResult = {
                ...state,
                loading: false,
                loaded: true,
                wards: action.payload.wards,
                totalWardCount: action.payload.count,
                userData: (action.payload) ? action.payload.userData || {} : {}
                // wards: [...state.wards, ...action.payload]
            }

            return returnResult;

        case PropertyTaxActionTypes.FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload,
            }

        case PropertyTaxActionTypes.USER_WARD_ASSIGN_REVOKE:
            return {
                ...state,
                loading: true,
                data: null
            }

        case PropertyTaxActionTypes.USER_WARD_ASSIGN_REVOKE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true
            }


        case PropertyTaxActionTypes.RESET_WARDS_AND_USERS:
            return {
                ...state,
                wards: [],
                users: [],
                checkedUsers: [],
                checkedWards: [],
                totalWardCount: 0
            }

        case PropertyTaxActionTypes.GET_PREMISE_FLOOR_COLLECTED_DATA_START:
            return {
                ...state,
                floorData: null,
            }

        case PropertyTaxActionTypes.GET_PREMISE_FLOOR_COLLECTED_DATA_SUCCESS:
            return {
                ...state,
                floorData: action.data,
            }
        case PropertyTaxActionTypes.EDIT_GEOMETRY_SUCCESS_ACTION:
            return {
                ...state,
                editedGeometryResp: action.response
            }
        case PropertyTaxActionTypes.GET_ADMIN_PANEL_DATA_SUCCESS_ACTION:
            return {
                ...state,
                adminData: action.response
            }
        case PropertyTaxActionTypes.APPROVE_GEOMETRY_SUCCESS_ACTION:

            return {
                ...state,
                adminApprovedData: action.response

            }
        case PropertyTaxActionTypes.GET_FILTERED_DATA_SUCCES_ACTION:

            return {
                ...state,
                filteredData: action.response
            }

        case PropertyTaxActionTypes.GET_CITY_OS_ATTRIBUTE_SUCCESS_ACTION:
            let obj = {
                attributes: action.response.attributes.data[0].all_attribues,
                index: action.response.index
            }
            return {
                ...state, cityosLayerAttributes: obj,
            };
            case PropertyTaxActionTypes.GET_ADMIN_PANEL_PREMISE_DATA_SUCCESS_ACTION:
                if (action.response.layer_id === state.layerPremiseTableData.layerId &&
                    +action.response.page !== 1 &&
                    action.response.is_approved === state.layerPremiseTableData.dataStatus
                  ) {
                    return {
                      ...state, layerPremiseTableData: {
                        layerId: action.response.layer_id,
                        totalPages: action.response.pages,
                        totalRecords: action.response.total,
                        pageNumber: +action.response.page,
                        dataStatus: action.response.is_approved,
                        data: [...state.layerPremiseTableData.data, ...action.response.docs],
                        templateProperties: action.response.template_properties,
                        propertiesType: action.response.attribute_type,
                        floorNumbers: action.response.floorNumbers
                      }
                    };
                  } else {
                    return {
                      ...state, layerPremiseTableData: {
                        layerId: action.response.layer_id,
                        totalPages: action.response.pages,
                        totalRecords: action.response.total,
                        pageNumber: +action.response.page,
                        dataStatus: action.response.is_approved,
                        data: action.response.docs,
                        templateProperties: action.response.template_properties,
                        propertiesType: action.response.attribute_type,
                        floorNumbers: action.response.floorNumbers
                      }
                    }
                  }
                  case PropertyTaxActionTypes.GET_PROPERTY_TAX_FORMS_SUCCESS_ACTION:
                      return {
                          ...state, 
                          propertyTaxForms: action.response  
                    }
                case PropertyTaxActionTypes.GET_PROPERTY_TAX_ENTRY_LOGS_SUCCESS_ACTION:
                    return {
                        ...state,
                        entryLogs: action.response
                    }
                

        default:
            return state;
    }
}
