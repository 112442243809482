import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/session-management.reducer';

export const sessionsFeatureSelector = createFeatureSelector<State>("sessions");

export const allSessionsSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.sessions || {})
)

export const allLayersSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layers || [])
)

export const allUsersSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.users || [])
)

export const allSurveyorsSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.surveyors || [])
)

export const activeSessionSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.activeSessionDetails || {})
)

export const assignedUsersSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.assignedUsers || [])
)

export const assignedSurveyorsSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.assignedSurveyors || [])
)

export const layerTableDataSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerTableData.data || [])
)

export const regressionDataSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerTableData.regressionData || [])
)

export const dcLayerDataSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.dcLayerData || {})
)
export const tableJoinStatusSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerTableData.tableJoinData || {})
)

export const totalLayerPagesSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerTableData.totalPages || 0)
)

export const totalRecordsSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerTableData.totalRecords || 0)
)

export const layerTableAttributesSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerTableData.templateProperties || [])
)

export const layerAttributesSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.layerAttributes || [])
)

export const uniquePropertyValuesSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.uniquePropertyValues || [])
)

export const styleTypesSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.styleTypes || {})
)

export const savedQueriesSelector = createSelector(
  sessionsFeatureSelector,
  state => (state.savedQueries || [])
)

export const rowDataDetailsSelector = createSelector(
  sessionsFeatureSelector,
  state => state.rowDataDetails || {}
)

export const propertiesTypeSelector = createSelector(
  sessionsFeatureSelector,
  state => state.layerTableData.propertiesType || {}
)

export const selectedLayerIdSelector = createSelector(
  sessionsFeatureSelector,
  state => state.layerTableData.layerId || null
)

export const myUnapprovedEditedFeatruesSelector = createSelector(
  sessionsFeatureSelector,
  state => state.myUnapprovedFeatures || []
)

export const layerAttributeInfoSelector = createSelector(
  sessionsFeatureSelector,
  state => state.layerAttributeInfo || {}
)
export const deleteSessionSelector = createSelector(
  sessionsFeatureSelector,
  state => state.deleteSession || {}

)
export const uploadFileSelector = createSelector(
  sessionsFeatureSelector,
  state => state.uploadFileStatus || {}
)
export const getProcessingLayerStatus = createSelector(
  sessionsFeatureSelector,
  state => state.processinglayers
)
export const updateIconSelector = createSelector(
  sessionsFeatureSelector,
  state => state.updateIcon
)
export const getIconsSelector = createSelector(
  sessionsFeatureSelector,
  state => state.getIcons
)
export const getProcessedLayerSelector = createSelector(
  sessionsFeatureSelector,
  state => state.getProcessedList
)
export const SeachedMapValues = createSelector(
  sessionsFeatureSelector,
  state => state.SearchedMapresults
)

export const SeachedHereMapValues = createSelector(
  sessionsFeatureSelector,
  state => state.SearchedHereMapresults
)
export const PlaceDetails = createSelector(
  sessionsFeatureSelector,
  state => state.placeDetails
)
export const layerDeleteSelector = createSelector(
  sessionsFeatureSelector,

  state => state.deletedProcessingLayer.data
)
export const notificationDeleteSelector = createSelector(
  sessionsFeatureSelector,
  state => state.deleteNotification
)
export const clearAllNotificationSelector = createSelector(
  sessionsFeatureSelector,
  state => state.clearAllNotification
)
export const classificationDataSelector = createSelector(
  sessionsFeatureSelector,
  state => state.classificationData
)
export const layerDetailsSelector =createSelector (
  sessionsFeatureSelector,
  state => state.layerDetails
)

export const dataInsightsDataSelector =createSelector (
  sessionsFeatureSelector,
  state => state.dataInsightsLayerData
)

export const dataInsightsSelector =createSelector (
  sessionsFeatureSelector,
  state => state.dataInsightsData
)
