import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent, MatChipInput, MatChipList, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { GetUsersStartAction } from 'src/app/store/actions/user-management.actions';
import { usersSelector } from 'src/app/store/selectors/user-management.selector';
import { startWith, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import moment from "moment-mini-ts"
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
}

@Component({
  selector: "app-pending-approval-filters",
  templateUrl: "./pending-approval-filters-dialog.component.html",
  styleUrls: ["./pending-approval-filters-dialog.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})

export class PendingApprovalFilterComponent {
  today: Date;
  filterForm: FormGroup;
  selectedUsers: Array<any> = [];
  nameFilterSelected = false;
  dateFilterSelected = false;
  serchSelected : boolean = false;
  fromDate: any;
  toDate: any;
  filteredUsers: Observable<any[]>;
  users: Array<any> = [];
  removable = true;
  stoppers$: Subject<any> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<PendingApprovalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _store: Store<any>
  ) {
    this.today = new Date();
    this.filterForm = this._fb.group({
      users: [""],
      dateFrom: [null],
      dateTo: [null]
    })
  }
  ngOnInit() {
    const payload = {
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
      filter: 3,
      admin: true,
      search: ""
    };
    this._store.dispatch(new GetUsersStartAction(payload));
    this._store.select(usersSelector).pipe(takeUntil(this.stoppers$)).subscribe((users: any) => {
      this.users = users.data.length ? users.data.filter(user => (user.user_role === 3 || user.user_role === 1)) : [];
      this.nameFilterSelected = this.data.filters.nameFilterSelected;
      this.dateFilterSelected = this.data.filters.dateFilterSelected;
      if(this.data.filters.users.length && this.data.filters.nameFilterSelected) {
        this.data.filters.users.forEach(id => {
          const userData = this.users.find(user => user._id === id);
          if(userData) {
            const selectedValues = this.selectedUsers.reduce((acc, user) => {
              return [...acc, user];
            }, []);
            const selectedUser = selectedValues.find(value => value._id === userData._id);
            if (!selectedUser) {
              this.selectedUsers.push(userData);
            }
          }
        });
      }
      this.fromDate = this.dateFilterSelected ? this.data.filters.fromDate : null;
      this.filterForm.get("dateFrom").setValue(this.fromDate);
      this.toDate = this.dateFilterSelected ? this.data.filters.toDate : null;
      this.filterForm.get("dateTo").setValue(this.toDate);
    });
    this.filteredUsers = this.filterForm
      .get("users")
      .valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.filterForm.get("dateFrom").valueChanges.subscribe(value => this.fromDate = value ? moment(value).toISOString() : null);
    this.filterForm.get("dateTo").valueChanges.subscribe(value => {
      value ? this.toDate = moment(value).endOf("day").toISOString() : null;
    });
  }
  private _filter(value) {
    if(value){
      this.serchSelected = true;
    }
    else{
      this.serchSelected = false;
    }
    if (typeof (value) === "string") {
      const filterValue = value.toLowerCase();
      return this.users.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
    } else {
      return this.users;
    }
  }

  crossClicked(){
    this.serchSelected = !this.serchSelected; 
    this.filterForm.patchValue({'users':  ""})
  } 

  selected(event) {
 
    const data = event.option.value;
    if (data) {
      const selectedValues = this.selectedUsers.reduce((acc, user) => {
        return [...acc, user];
      }, []);
      const user = selectedValues.find(value => value._id === data._id);
      if (!user) {
        this.selectedUsers.push(data);
      }
      this.filterForm.get("users").setValue("");
    }
  }
  remove(user) {
    const selectedUserIds = this.selectedUsers.reduce((acc, element) => {
      return [...acc, element._id];
    }, []);
    const index = selectedUserIds.indexOf(user._id);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }
  apply() {
   
    this.close(true);
  }
  clear() {
    this.filterForm.setValue(
      {
        users: [],
        dateFrom: null,
        dateTo: null,
      }
    )
    this.nameFilterSelected = false;
    this.dateFilterSelected = false;
    this.selectedUsers = [];
    // this.close(true);
  }
  close(flag) {
    if(flag) {
      const result = {
        users: this.selectedUsers.length ? this.selectedUsers.map(user => user._id) : [],
        fromDate: this.fromDate,
        toDate: this.toDate,
        nameFilterSelected: this.nameFilterSelected,
        dateFilterSelected: this.dateFilterSelected
      }
      this.dialogRef.close(result);
    } else this.dialogRef.close();
  }

  ngOnDestroy() {
    this.stoppers$.next();
    this.stoppers$.complete();
  }
}
