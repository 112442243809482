import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { OrderModule } from 'ngx-order-pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorPickerModule } from 'ngx-color-picker';
import {MatSliderModule} from '@angular/material/slider';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';

export const APP_IMPORTS = [
  FormsModule,
  ReactiveFormsModule,
  BrowserModule,
  HttpClientModule,
  BrowserAnimationsModule,
  BrowserModule,
  MatSidenavModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTabsModule,
  MatInputModule,
  OrderModule,
  DragDropModule,
  MatTooltipModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatDialogModule,
  NgxSpinnerModule,
  ColorPickerModule,
  MatSliderModule,
  MatProgressBarModule,
  MatTableModule,
  MatSlideToggleModule,
  MatSelectModule,
  ScrollingModule,
  NgxAudioPlayerModule,
  NgxMaterialTimepickerModule,
  DeviceDetectorModule.forRoot(),
  MatBadgeModule
  // MatColorPickerModule
];
