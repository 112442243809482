import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/profile.reducer';

export const profileFeatureSelector = createFeatureSelector<State>("profile");

export const userProfileSelector = createSelector(
  profileFeatureSelector,
  (state) => (state.user || {})
)
export const userDataSelector = createSelector(
  profileFeatureSelector,
  (state) => (state.userDetails || {})
)
export const updatedDetailsselector = createSelector(
  profileFeatureSelector,
  (state) => (state.updatedDetails || {})
)
export const orgDetailsSelector = createSelector(
  profileFeatureSelector,
  (state) => (state.orgDetails || {})
)

export const subscriptionDetails = createSelector(
  profileFeatureSelector,
  (state) => (state.subscriptionDetails || {})

)

export const regenerateToken = createSelector(
  profileFeatureSelector,
  (state) => (state.regeneratedToken || {})

)