import { Component, OnInit, Inject } from '@angular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit {
  msbapTitle = 'Audio Title';
  msbapAudioUrl = 'assets/images/sample.audio.mp3'; 
  activeIndex = 0;
  msbapDisplayTitle = false;
  iframe_html: any;
  constructor(
    public dialogRef: MatDialogRef<TableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private embedService: EmbedVideoService
  ) { }

  ngOnInit() {
    if (this.data.type === "video") this.iframe_html = this.embedService.embed(this.data.data);
    // switch (this.data.type) {
    //   case 'image':

    //     break;

    //   default:
    //     break;
    // }
  }
  
  close(event) {
    if (event) {
      this.dialogRef.close(event);
    }
    else {
      this.dialogRef.close();
    }
  }

  rotateImage(direction, index) {
    const offset = 90;
    switch (direction) {
      case "left":
        if (isNaN(index)) {
          this.data.degree -= offset;
          break;
        }
        this.data.degree[index] -= offset;
        break;
      case "right":
        if (isNaN(index)) {
          this.data.degree += offset;
          break;
        }
        this.data.degree[index] += offset;
        break;
    }
 
  }

  apply(deg: number) {
    // rotate image
    return this.close({
      degree: this.data.degree
    });
  }

  getDegree(index: number) {
    if (isNaN(index)) {
      if (!this.data || !this.data.degree || isNaN(this.data.degree)) {
        return 0;
      }
      return this.data.degree;
    }
    if (!this.data || !this.data.degree || isNaN(this.data.degree[index])) {
      return 0;
    }
    return this.data.degree[index];
  }
}
