import { Component} from '@angular/core'
import { Router } from '@angular/router';
@Component({
  selector: 'error-component',
  templateUrl: './error-component.html',
  styleUrls: ['./error-component.scss']
})
export class errorComponent  {
  isTokenPresent: any;
  constructor(private router: Router) {}
  ngOnInit() {
  }
  backNavigationClicked() {
    this.isTokenPresent  = (localStorage.getItem('token') ? true : false)
    if(this.isTokenPresent) {
      this.router.navigate(['./app-management'])
    } else {
      this.router.navigate(['./sign'])
    }

  }
}
