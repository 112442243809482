import { ProjectManagementActionTypes, ProjectManagementSuccesActions, GetProjectListSuccess, GetSurveyorsDetailsSuccess } from '../actions/project-management.actions';

export interface ProfileManagement {
    projectList: any;
}


export interface State {
    projectList: any;
    addedProject: any;
    updatedProject: any;
    projectDetail: any;
    surveyorsList: any;
    parentForm: any;
}

export const initialState: State = {
    projectList: {},
    addedProject: {},
    updatedProject: {},
    projectDetail: {},
    surveyorsList: {},
    parentForm: []
};

export function projectManagementReducer(state = initialState, action: ProjectManagementSuccesActions): State {
    switch (action.type) {
        case ProjectManagementActionTypes.GET_PROJECT_LIST_SUCCESS:
            let docs = (action as GetProjectListSuccess).response.docs || [];
            let page = parseInt(action.response.page);
            if (page === 1) {
                state.projectList.docs = []
            }
            return { ...state, projectList: { 'docs': [...state.projectList.docs, ...docs], 'page': page, 'totalPages': (action as GetProjectListSuccess).response.pages } }

        case ProjectManagementActionTypes.ADD_PROJECT_SUCCESS:
            return { ...state, addedProject: action.response }
        case ProjectManagementActionTypes.UPDATE_PROJECT_SUCCESS:
            return { ...state, updatedProject: action.response }
        case ProjectManagementActionTypes.GET_PROJECT_DETAILS_SUCCESS:
            return { ...state, projectDetail: action.response }
        case ProjectManagementActionTypes.GET_surveyors_DETAILS_SUCCESS:
            let Docs = (action as GetSurveyorsDetailsSuccess).response.data.docs;

            let Page = parseInt(action.response.data.page);
            if (Page === 1) {
                state.surveyorsList.Docs = []
            }
            return { ...state, surveyorsList: { 'Docs': [...state.surveyorsList.Docs, ...Docs], 'page': Page, 'totalPages': (action as GetSurveyorsDetailsSuccess).response.data.pages } }
        case ProjectManagementActionTypes.SAVE_PARENT_FORM:
            return { ...state, parentForm: [action.response] }
        case ProjectManagementActionTypes.RemovEprojectDetail:
            return { ...state, projectDetail: {}, updatedProject: {} }


        default: {
            return state;
        }
    }



}

