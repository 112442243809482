import { Routes } from "@angular/router";
// import { HomeComponent } from "./app-home/home/home.component";
import { ShareMapComponent } from './share-map/share-map.component';
import { WmsPreviewComponent } from './wms-preview/wms-preview.component';
import { errorComponent } from './errorcomponent/error-component';
import { datalibraryupload } from './datalibrary-upload/datalibrary-upload.component';
import { AuthGuard } from './_guards/auth.guard';
export const routes: Routes = [
  // { path: "", redirectTo: "forgot-password", pathMatch: "full" },
  {
    path: "",
    redirectTo: "app-management",
    pathMatch: "full"
  },
  {
    path: "sign/**",
    redirectTo: "sign",
    pathMatch: "full"
  },
  // { path: "home/:id", component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: "home",
    loadChildren: "src/app/app-home/app-home.module#AppHomeModule"
  },

  {
    path: "app-management",
    loadChildren: "src/app/app-management/app-management.module#AppManagementModule"
  },
  {
    path: "sign",
    loadChildren: "src/app/signup/signup.module#SignupModule"
  },
  {
    path: "preview/:id",
    component: ShareMapComponent
  },
  {
    path: 'preview-wms/:id',
    component: WmsPreviewComponent
  },
  {
    path: "subscription",
    loadChildren: "src/app/app-subscription/app-subscription.module#AppSubscriptionModule"
  },
  {
    path:"datalibrary-upload",
    canActivate: [AuthGuard],
    component:datalibraryupload
  },
  {
    path: '404',
    component: errorComponent
  },
  {
    path: "**",
    component:errorComponent
  },

];

export const COMPONENT_DECLARATIONS = [
  //  HomeComponent,
  //  AppComponent
  //  DashboardComponent,

];
