import { Injectable } from "@angular/core";
import connProp from '../../assets/connection.properties.json';
import { share } from 'rxjs/operators';
import { environment as env } from "../../environments/environment";
import { Subject } from 'rxjs';
import { API } from "./API.service";
@Injectable()
export class SurveyAnalyticsService {

  connProps: any = connProp;
  _url = `${this.connProps.propertyTaxSurvey}`;

  constructor(
    private apiService: API,
  ) { }

  getLayerTableData(params) {
    const subURL = this._url + this.generateQueryString(params)
    return this.apiService.getNew(subURL, true, false);
  }

  generateQueryString(paramObject) {
    let queryString;
    if (paramObject.limit) {
      queryString = "?limit=" + paramObject.limit
    }
    if (paramObject.page) {
      queryString += "&page=" + paramObject.page
    }
    if(!paramObject.limit){
      if (paramObject.filter) {
        queryString = "?filter=" + paramObject.filter
      }
    }else{
      if (paramObject.filter) {
        queryString += "&filter=" + paramObject.filter
      }
    }

    if (paramObject.surveyor) {
      queryString += "&surveyor=" + paramObject.surveyor
    }
    if (paramObject.ward) {
      queryString += "&ward=" + paramObject.ward
    }
    if (paramObject.startDate) {
      queryString += "&startDate=" + paramObject.startDate
    }
    if (paramObject.endDate) {
      queryString += "&endDate=" + paramObject.endDate
    }
    if (paramObject.approvalStatus || paramObject.approvalStatus == null) {
      queryString += "&approvalStatus=" + paramObject.approvalStatus
    }
    return queryString;
  }

}

