import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms'
import { MatDialogRef, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatInput, MAT_DIALOG_DATA } from '@angular/material';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { User, GetParam, Ward } from "../../models/user-ward-assignments"
import { Pagination } from 'src/app/models/pagination';
import { listWardsSelector, listWardsCountSelector } from "../../store/selectors/property-tax.selector";
import { Subscription } from 'rxjs';
import * as PropertyTaxActions from '../../store/actions/property-tax.actions';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
}

@Component({
  selector: 'app-survey-analytics-dialog',
  templateUrl: './survey-analytics-dialog.component.html',
  styleUrls: ['./survey-analytics-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})

export class SurveyAnalyticsDialogComponent implements OnInit {
  wardCount: any;
  limit: number = 20;
  private wardsSubscription: Subscription;
  private formsPagination: Pagination = new Pagination();
  wards: Ward[] = [];
  private wardsPagination: Pagination = new Pagination();
  ward: FormControl = new FormControl("");
  totalWardsPages: any;
  startDate: FormControl = new FormControl("");
  endDate: FormControl = new FormControl("");
  approvalStatus: FormControl = new FormControl("");
  today = new Date();
  timeFormat: any = "T00:00:00.000Z"
  firstDate: any
  lastDate: any
  date: any
  formatedDate: any
  start: any
  end: any
  constructor(public dialogRef: MatDialogRef<SurveyAnalyticsDialogComponent>,
    private _store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // get all wards
    let apiData: GetParam = {
      params: ["wards"],
      queryParams: this.wardsPagination
    };

    this._store.dispatch(new PropertyTaxActions.ResetWardsAndUsers());
    this._store.dispatch(new PropertyTaxActions.ListWards(apiData));
    this.wardsSubscription = this._store.select(listWardsSelector).subscribe((wards: Ward[]) => {
      this.totalWardsPages = Math.ceil(this.wardCount / this.limit)
      this._store.select(listWardsCountSelector).subscribe(count => this.wardCount = count);

      if (this.wardCount === 0) {
        this.wards = [];
        return;
      }
      this.wards.push(...wards);
      if (this.wards.length < this.wardCount) {

        this.wardsPagination.page++;
        let apiData: GetParam = {
          params: ["wards"],
          queryParams: this.wardsPagination
        };
        this._store.dispatch(new PropertyTaxActions.ListWards(apiData));
        return;
      }
    });

    // dates
    this.startDate.setValue(this.data.startDate);
    this.endDate.setValue(this.data.date);
    this.ward.setValue(this.data.ward);
    this.approvalStatus.setValue(this.data.approvalStatus);
  }
  closeFilters() {
    this.dialogRef.close([]);
  }

  resetFilters() {
    this.startDate.setValue("");
    this.endDate.setValue("");
    this.ward.setValue("");
    this.approvalStatus.setValue("");
  }


  applyFilters() {
    let fitlterArray: any = []
    let filter: any = {};
    let filteredItems: any = {}


    if (this.startDate.value && this.startDate.value != "" && this.startDate.value != undefined) {
      if (this.startDate.value instanceof Date) {
        this.firstDate = this.startDate.value
        this.formatDate(this.startDate.value);
        filter.startDate = this.start
        filteredItems.startDate = this.start
      }
      else if (this.startDate.value._d instanceof Date) {
        this.firstDate = this.startDate.value
        this.formatDate(this.startDate.value._d);
        filter.startDate = this.start
        filteredItems.startDate = this.start
      }
      else {
        filter.startDate = this.startDate.value
        filteredItems.startDate = this.startDate.value
      }

    }



    if (this.endDate.value && this.endDate.value != "" && this.endDate.value != undefined) {
      if (this.endDate.value instanceof Date) {
        this.lastDate = this.endDate.value
        this.formatLastDate(this.lastDate);
        filter.endDate = this.end
        filteredItems.date = this.date
      }
      else if (this.endDate.value._d instanceof Date) {
        this.lastDate = this.endDate.value._d
        this.formatLastDate(this.lastDate);
        filter.endDate = this.end
        filteredItems.date = this.date
        filteredItems.endDate = this.end
      }
      else {
        this.formatdate(this.endDate.value)
        filter.endDate = this.formatedDate
        filteredItems.date = this.endDate.value
      }

    }



    if (this.ward.value != "" || this.ward.value != undefined) {
      filter.ward = this.ward.value
      filteredItems.ward = this.ward.value
    }

    if (this.approvalStatus.value == undefined) {
      this.approvalStatus.setValue("null")
    }
    if (this.approvalStatus.value != "") {
      filter.approvalStatus = this.approvalStatus.value
      filteredItems.approvalStatus = this.approvalStatus.value
    }
    fitlterArray.push({ filter: filter, filterItems: filteredItems })
    this.dialogRef.close(fitlterArray);



  }

  formatLastDate(date) {

    let newdate = new Date(date)
    newdate.setDate(newdate.getDate() + 1)
    let secondDate = newdate.getDate()
    let formatdate = date.getDate()
    if (formatdate < 10) {
      formatdate = "0" + secondDate
    }
    let second = secondDate.toString()
    if (secondDate < 10) {
      second = "0" + secondDate
    }
    let secondMonth = newdate.getMonth() + 1
    let secondmon = secondMonth.toString()
    if (secondMonth < 10) {
      secondmon = "0" + secondMonth
    }
    this.end = newdate.getFullYear() + "-" +
      secondmon + "-" +
      second + this.timeFormat
    this.date = date.getFullYear() + "-" +
      secondmon + "-" +
      formatdate + this.timeFormat


  }
  formatdate(date) {

    let newdate = new Date(date)
    newdate.setDate(newdate.getDate() + 1)
    let secondDate = newdate.getDate()
    let second = secondDate.toString()
    if (secondDate < 10) {
      second = "0" + secondDate
    }
    let secondMonth = newdate.getMonth() + 1
    let secondmon = secondMonth.toString()
    if (secondMonth < 10) {
      secondmon = "0" + secondMonth
    }
    this.formatedDate = newdate.getFullYear() + "-" +
      secondmon + "-" +
      second + this.timeFormat
  }
  formatDate(date) {
    let firstDate = date.getDate()
    if (firstDate < 10) {
      firstDate = "0" + firstDate
    }

    let firstMonth = date.getMonth() + 1
    if (firstMonth < 10) {
      firstMonth = "0" + firstMonth
    }
    this.start = date.getFullYear() + "-" +
      firstMonth + "-" +
      firstDate + this.timeFormat
  }
  listRemainingWards() {

    this.wardsPagination.page++;
    let apiData: GetParam = {
      params: ["wards"],
      queryParams: this.wardsPagination
    };
    this._store.dispatch(new PropertyTaxActions.ListWards(apiData));
  };
  onWardsTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 0;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit) {
      this.wardsPagination.page++;
      this.listRemainingWards();
    }
  };
}
