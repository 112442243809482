import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LAYER_ACTIVE_ICONS, LAYER_INACTIVE_ICONS } from "src/app/app.constants";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";
import { SessionService } from "src/app/_services/session.service";
import { cloneDeep } from "lodash";
import { Store } from "@ngrx/store";
import { CreateNetworkLayersStartAction } from "src/app/store/actions/session-management.actions";

@Component({
  selector: 'app-service-area-dialog',
  templateUrl: './service-area-dialog.component.html',
  styleUrls: ['./service-area-dialog.component.scss']
})

export class ServiceAreaDialog {
  selectedNetworkLayer: any = null;
  selectedPointLayer: any = null;
  networkLayersList: Array<any> = [];
  pointLayersList: Array<any> = [];
  layerActiveIcons = LAYER_ACTIVE_ICONS;
  layerInactiveIcons = LAYER_INACTIVE_ICONS;
  analysisType: string = null;
  startPoint: FormControl = new FormControl(null, [this.LatLngValidator.bind(this)]);
  distance: FormControl = new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]);
  layerName: FormControl = new FormControl('network_analysis_layer', Validators.required);
  selectedUnit: string = null;
  sessionId: string = null;
  analysisOptions: Array<any> = [
    {
      display_text: "SERVICE AREA (Layer)",
      value: "layer"
    }, {
      display_text: "SERVICE AREA (Point)",
      value: "point"
    }
  ];
  units: Array<any> = [
    {
      display_text: "Meters",
      value: "meters"
    }, {
      display_text: "Kilometers",
      value: "kilometers"
    }
  ]
  enableApply: boolean = false;
  enableSelectPointOnMap: boolean = false;
  coordinates: Array<number> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<ServiceAreaDialog>,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl,
    private _store: Store<any>
  ) {}

  ngOnInit() {
    this.sessionId = this.sessionService.sessionDetails ? this.sessionService.sessionDetails._id : null;
    this.networkLayersList = this.sessionService.sessionLayers.filter((layer: any) => (layer.show && (layer.layer_type === 1) && (['polyline', 'linestring', 'multilinestring'].includes(layer.type))));
    this.pointLayersList = this.sessionService.sessionLayers.filter((layer: any) => (layer.show && (layer.layer_type === 1) && (['point', 'multipoint'].includes(layer.type))));
    this.startPoint.valueChanges.subscribe((value: any) => {
      this.validateForm();
    })
    this.distance.valueChanges.subscribe((value: any) => {
      this.validateForm();
    })
  }

  selectNetworkLayer(layer) {
    this.selectedNetworkLayer = layer;
    this.validateForm();
  }

  selectPointLayer(layer) {
    this.selectedPointLayer = layer;
    this.validateForm();
  }

  setAnalysisType(event) {
    this.analysisType = event.value;
    this.validateForm();
  }

  setUnit(event) {
    this.selectedUnit = event.value;
    this.validateForm();
  }

  validateForm() {
    if(this.analysisType === 'layer') {
      this.enableSelectPointOnMap = false;
      if(this.selectedNetworkLayer && this.selectedPointLayer && this.distance.value > 0 && this.selectedUnit && this.layerName.valid) this.enableApply = true;
      else this.enableApply = false;
    } else if (this.analysisType === 'point') {
      if(this.selectedNetworkLayer && this.distance.value > 0 && this.selectedUnit && this.layerName.valid) {
        if(this.startPoint.valid) {
          this.enableSelectPointOnMap = true;
          this.enableApply = true;
        } else {
          this.enableSelectPointOnMap = true;
          this.enableApply = false;
        }
      } else {
        this.enableSelectPointOnMap = false;
        this.enableApply = false;
      }
    } else {
      this.enableApply = false;
      this.enableSelectPointOnMap = false;
    }
  }

  LatLngValidator(control: FormControl) {
    if (!control.value) return { 'invalidLatLng': true };
    let latLng = control.value;
    let latLngSplit = latLng.split(",");
    if (latLngSplit.length != 2) return { 'invalidLatLng': true };
    let lat = latLngSplit[0].trim();
    let lng = latLngSplit[1].trim();
    let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
    const isValid = pattern.test(lat) && pattern.test(lng);
    if(isValid) this.coordinates = [+lat, +lng];
    return isValid ? null : { 'invalidLatLng': true };
  }

  apply() {
    // let data = cloneDeep(NT_ANALYSIS_PAYLOAD);
    // data.networkLayerId = this.selectedNetworkLayer.parent_layer_id;
    // data.type = NT_TYPE[this.analysisType];  //[file,point],
    // data.pointLayerId = this.selectedPointLayer.parent_layer_id;
    // data.coordinates = this.coordinates;
    // data.distance = this.distance.value;
    // data.units = this.selectedUnit; //['meters','kilometers'],
    // data.sessionId = this.sessionId;
    let data = this.getDataValue();
    this._store.dispatch(new CreateNetworkLayersStartAction(data));
    this.dialogRef.close(data);
  }

  getDataValue() {
    return {
      networkLayerId: this.selectedNetworkLayer ? this.selectedNetworkLayer.parent_layer_id : null,
      type: NT_TYPE[this.analysisType],  //[file,point],
      pointLayerId: this.selectedPointLayer ? this.selectedPointLayer.parent_layer_id : null,
      coordinates: this.coordinates,
      distance: this.distance.value,
      units: this.selectedUnit, //['meters','kilometers'],
      sessionId: this.sessionId,
      name: this.layerName.value
    }
  }

  selectOnMap() {
    // console.log("Select Point On Map.........");
    this.sessionService.setSelectedAdvancedToolChoiceType('graphics');
    this.sessionService.selectedGeometryType = 'point';
    this.sessionService.setSelectedAdvancedToolGeometryType('point');
    this.sessionService.setAdvancedToolsGraphicsSelectionActive(true);
    this.sessionService.setNetworkAnalysisActive(true);
    if(this.navigationService.pointPlotSubscription) {
      // this.navigationService.pointPlotSubscription.unsubscribe();
      this.navigationService.pointPlotSubscription = null;
      this.sessionService.setAdvancedToolsDrawnCoordinates("");
    }
    const data = this.getDataValue();
    this.sessionService.setNetworkAnalysisData(data);
    this.navigationService.showGeometry();
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }
}

const NT_ANALYSIS_PAYLOAD = {
  networkLayerId: null,
  type: null,  //[file,point],
  pointLayerId: null,
  coordinates: null,
  distance: 0,
  units: null, //['meters','kilometers'],
  sessionId: null,
  name: "network_analysis_layer"
}

const NT_TYPE = {
  layer: 'file',
  point: 'point'
}