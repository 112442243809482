import {
  HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment as env } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@ngrx/store';
import { ShowLoaderAction, HideLoaderAction } from '../store/actions/loader.actions';
import { orgDetailsSelector } from '../store/selectors/profile.selector'
import { MatDialog } from '@angular/material';
import { SubscriptionUpgradeDialogComponent } from '../dialogs/subscription/subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { SubscriptionErrorDialogComponent } from '../dialogs/subscription/subscription-error-dialog/subscription-error-dialog.component';
import { SessionService } from './session.service';
import { LogErrorAction } from '../store/actions/user-management.actions';
import { DataApprovalManagementService } from './data-approval-management.service';


@Injectable()
export class ServerURLInterceptor implements HttpInterceptor {
  orgDetails: any = null;
  [x: string]: any;
  skipToastrFor = ["/auth/login", "/auth/signup", "/auth/forgot_password", "/auth/verify", "/auth/resend", "/auth/password"];
  skipLoadingFor = ['/layers/attributes/', '/sessions/map-preview', '/sessions/preview/'];
  constructor(
    private router: Router,
    private toaster: ToastrService,
    private deviceDetector: DeviceDetectorService,
    private _store: Store<any>,
    public dialog: MatDialog,
    public DAMService: DataApprovalManagementService,
    public sessionService: SessionService
  ) {
    if(localStorage.getItem('token')){
      console.log(localStorage.getItem('token'))
      this._store.select(orgDetailsSelector).subscribe(orgRes => {
        this.orgDetails = orgRes;
      })
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let showLoader = true;
    let authReq;
    const userToken = localStorage.getItem('token');
    const baseURL = `${env.apiPath}`;
    let basePath = null;
    if(["uat", "cityos","local"].includes(env.envName)) {
      basePath = this.sessionService.currentHost;
    } else {
      basePath = (new URL(baseURL)).host;
    }
    if (userToken && (req.url.indexOf(basePath) !== -1 || req.url.startsWith("/"))) {
      let browser: string = this.deviceDetector.getDeviceInfo().browser;
      if (!browser) {
        browser = '';
      }
      if (req.method === 'GET' && browser.toLowerCase().indexOf('ie') !== -1) {
        authReq = req.clone({
          params: req.params.set('iebust', '' + Date.now()),
          headers: req.headers.set('Authorization', `Bearer ${userToken}`)
        });
      } else {
        authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${userToken}`)
        });
      }
      const hideLoading = this.skipLoadingFor.filter(endPoint => req.url.indexOf(endPoint) !== -1).length ? true : false;
      (hideLoading || req.params.get('nonBlocking')) && (showLoader = false);
      showLoader && this._store.dispatch(new ShowLoaderAction());
    } else {
      authReq = req;
    }

    return next.handle(authReq).pipe(
      finalize(() => {
        showLoader && this._store.dispatch(new HideLoaderAction());
      }),
      catchError((res) => {
        const userToken = localStorage.getItem('token');
        if (authReq.method === 'POST' || authReq.method === 'PUT') {
          // emitter.next({ "val": false, "msg": msg });
        }
        if(authReq.url.includes('get-bands-info')) {
          this.sessionService.errorInBandExtraction.next(true)
        }
        if (typeof res.error === 'string') {
          try {
            res.error = JSON.parse(res.error);
          } catch (e) {
            res.error = { message: res.error };
          }
        }
        if(res.status === 404) {
          this.router.navigateByUrl('404', {skipLocationChange: true});
          return;
        }
        if (res.status === 0 || (res.status < 599 && res.status > 399 && res.status !== 401
          && res.status !== 403)) {
          if (this.skipToastrFor.indexOf(authReq.url.replace(baseURL, '')) !== -1) return throwError(res.error);
          this.showErrorFromResponse(res);
        } else if (res.status === 401) {
          this.router.navigate(['sign/signIn']);
          localStorage.clear();
          this.dialog.closeAll();
        } else if (res.status === 403) {
          // this.router.navigate(['sign/verified'])
        } else if (res.error && res.error.__globals) {
          res.error.__globals.forEach(error => {
            // this.e.set(error, 'error', res.url);
            this.toaster.error(error);
            return;
          });
        }
        if (res.status == 405) {
          this.toaster.error(res.error.msg);
          return throwError(res);
        } else if (res.status === 400) {
          const key = +res.error.key;
          if ([10040, 10041].includes(key)) {
            showLoader && this._store.dispatch(new HideLoaderAction());
            const dialogRef = this.dialog.open(SubscriptionUpgradeDialogComponent, {
              width: '600px',
              hasBackdrop: true,
              data: {
                message: res.error.error[0].message
              }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                if(this.dialog.openDialogs.length) this.dialog.closeAll();
                this.router.navigate(["/subscription/upgrade"]);
                return;
              }
            });
          } else if (key === 10042) {
            const msgs = res.error.error[0].message.split(".").map(msg => {
              return msg + '.';
            });
            const dialogRef = this.dialog.open(SubscriptionErrorDialogComponent, {
              width: '800px',
              hasBackdrop: true,
              data: {
                messageHeader: "Downgrading this subscription is not possible, because :",
                messages: msgs.splice(0, msgs.length - 1)
              }
            });
            dialogRef.afterClosed().subscribe(_ => {});
          }
          return throwError(res);
        }
        if (res.status !== 402) return throwError(res);
      }));
  }
  showErrorFromResponse(res) {
    let end_points_arr = ['bulk_invite'];
    let splittedUrl = res.url.split('/')
    splittedUrl.forEach(((splittedEnd) => {
         for( let i=0; i<= end_points_arr.length -1; i++) {
           if(end_points_arr[i] === splittedEnd) {
            this._store.dispatch(new HideLoaderAction());
            break;
           }
         } 
    }))
    const erroBody = res.error;
    delete erroBody.code;
    if (res.status === 400) {
      if(erroBody.key==="GET PREMISES ERROR" && erroBody.error[0].message==="Invalid building ids."){
        this.DAMService.setInvalidError(true)
      }
      else{
        this.DAMService.setInvalidError(false)
      }
      if ([10040, 10041, 10042].includes(+res.error.key)) return;
      if (erroBody.msg) {
        this.toaster.error(`${erroBody.msg}`)
      } else {

        if (typeof erroBody.error == 'string') {
          this.toaster.error(`${erroBody.error}`)
        } else {
          // if erroBody.error is of type object 
          this.handleErrorResponse(erroBody.error)
        }
      }
    } else {
      Object.keys(erroBody).forEach((type) => {
        if (typeof type === 'string' && typeof erroBody[type] === 'string') {
          // this.e.set(erroBody[type], type, res.url);
          this.toaster.error(erroBody[type]);
        }
        else {
          if (erroBody.error) {
            this.toaster.error(erroBody.error[0].message)
          }
        }
      });
    }
  }

  handleErrorResponse(errorBody) {
    let errMessage = ""
    errorBody.forEach((element) => {
      errMessage += element.message;
    });
    this.toaster.error(errMessage, null, { enableHtml: true })
  }
}
