import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogContent } from '@angular/material';
import { LegendCtrl } from 'src/app/_services/mapLegendsCtrl.service';
import { API, LayerService } from 'src/app/_services';
import { Storeservice } from 'src/app/_services/store.services';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
import dataFile from '../../../assets/style1.json';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import {
  ShowLoaderAction,
  HideLoaderAction
} from 'src/app/store/actions/loader.actions';
import { activeSessionSelector } from 'src/app/store/selectors/session-management.selector';
import { ActivatedRoute, Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { SessionService } from 'src/app/_services/session.service';
import { Actions, ofType } from '@ngrx/effects';
import { SessionManagementTypes, GetSessionDetailsStartAction } from 'src/app/store/actions/session-management.actions';
import { tap, takeUntil } from 'rxjs/operators';
import { SessionPublishService } from 'src/app/_services/sessionPublish.service';
import { Options, LabelType } from 'ng5-slider';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss']

})
export class publishDialogComponent implements OnDestroy {
  istoggleShow: any = "private";
  istogglelinkType: any = "WMS";
  isShowConfirm = false;
  sessionId: string;
  shareUrl: string = '';
  sampleJson = dataFile;
  url: any;
  layerDetails: any;
  source: any = {};
  pbfPath: string;
  rasterPbfPath: string;
  layers: any = [];
  uniqueLayers = [];
  // plotData: (layerObj: any, uniqueID: any, paintObj: any, layoutObj: any, type: any) => { id: any; type: any; source: string; paint: any; layout: any; maxzoom: any; minzoom: any; };
  layerData: any = {
    id: '',
    type: '',
    source: '',
    paint: {},
    layout: {},
    maxzoom: 0,
    minzoom: 0,
    'source-layer': ''
  };
  changedLayerObjs: any = [];
  copyClickedFlag: boolean = false;
  copyWMSClicked: boolean = false;
  bBox: Array<number> = [];
  // URLData = {
  //   'WMS_URL': '',
  //   'WMTS_URL': '',
  //   'WFS_URL': '',
  //   'WFS_API': ''
  // };
  showNonBlockingLoader: boolean = false;
  copyWfsApiClickedFlag: boolean = false;
  copyWmtsClickedFlag: boolean = false;
  wfsApi: string = ''
  wfsClicked: boolean = false;
  sessionLayers: any = [];
  content: string = 'Use WMS (Web Map Services) option to create georeferenced map images on your mobile and/or web applications';
  wmsDoneClicked: boolean = false;
  wfsDoneClicked: boolean = false;
  wmsDoneButtonClicked: boolean = false;
  wfsDoneButtonClicked: boolean = false;
  wFSUrl: string = '';
  wmtsUrl: string = '';
  wmsUrl: string = '';
  showSlideToggleButton: boolean = false;
  wmsPublished: boolean = false
  wfsPublished: boolean = false;
  embedWmsUrlCopied: boolean = false;
  embedWfsUrlCopied: boolean = false;
  wfsPublishRequirements = {
    'showMapLegend': false,
    'searchBar': false,
    'customMinZoomRange': 1,
    'customMaxZoomRange': 22,
    'attributeDisplayOnHover': true,
    'zoomRangesDefault': false
  }
  wmsIframeElement: string = null;
  wfsIframeElement: string = null;
  sliderOptions: Options = {
    floor: 1,
    ceil: 22,
    minRange: 1,
    noSwitching: true,
    pushRange: true,
    disabled: false,
    translate: (v: number, label: LabelType) => {
      switch (label) {
        case LabelType.Low:
        case LabelType.High:
          return 'Z-' + v;
        default: return ''


      }
    }
  }
  alertOpened: boolean = false;
  eligibleForPublishedUrlCall: boolean = false;
  stopper$: Subject<any> = new Subject();
  timeoutRef: any;


  constructor(public dialogRef: MatDialogRef<publishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public legendService: LegendCtrl,
    public api: API,
    public storeService: Storeservice,
    public navService: NavigationCtrl,
    public layerService: LayerService,
    private toaster: ToastrService,
    private _store: Store<any>,
    private sessionService: SessionService,
    private publishService: SessionPublishService,
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    dialogRef.disableClose = true;
  }


  ngOnInit() {
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    this.sessionId = snapshot.url.split('/').pop();
    this.sessionService.showUnApprovedData = false;
    if (this.sessionId.length) {
      if (this.data.showUrlByDefault) {
        this.istoggleShow = "showURL";
        this.layerService.getPublishedLinks(this.sessionService.sessionId).subscribe((resp: any) => {
          if (resp && Object.keys(resp).length) {
            if (resp.data.wms_publish) {
              this.istogglelinkType = "WMS";
              this.wmsDoneButtonClicked = true
            } else if (resp.data.wfs_publish) {
              this.istogglelinkType = 'WFS'
            } else {
              this.istogglelinkType = 'WMS'
            }

            this.shareUrl = resp.data.WMS_PREVIEW_URL || '';
            this.wmtsUrl = resp.data.WMTS_URL || '';
            this.wmsUrl = resp.data.WMS_URL || '';
            this.wFSUrl = resp.data.WFS_URL || '';
            this.wfsApi = resp.data.WFS_API || '';
            this.wmsPublished = resp.data.wms_publish; // this case for showing publish or re-publish button
            this.wfsPublished = resp.data.wfs_publish;  // this case for showing publish or re-publish button
            if (resp.data.wms_publish) {
              this.wmsIframeElement = `<iframe src="${resp.data.WMS_PREVIEW_URL}" title="Published from Online Transerve Stack" width="500" height="500"></iframe>`
            }
            if (resp.data.wfs_publish_details) {
              this.wfsPublishRequirements.searchBar = resp.data.wfs_publish_details.searchBar;
              this.wfsPublishRequirements.showMapLegend = resp.data.wfs_publish_details.showMapLegend;
              this.wfsPublishRequirements.attributeDisplayOnHover = !resp.data.wfs_publish_details.attributeDisplayonClick;
              this.wfsPublishRequirements.zoomRangesDefault = resp.data.wfs_publish_details.zoomRangesDefault;
              this.wfsPublishRequirements.customMinZoomRange = resp.data.wfs_publish_details.customMinZoomRange;
              this.wfsPublishRequirements.customMaxZoomRange = resp.data.wfs_publish_details.customMaxZoomRange;
            }
            if (resp.data.wfs_publish) {
              this.wfsIframeElement = `<iframe src="${resp.data.WFS_URL}" title="Published from Online Transerve Stack" width="500" height="500"></iframe>`
            }
            this.eligibleForPublishedUrlCall = resp.data.wms_publish || resp.data.wfs_publish;
          }

        })
      }
    }
    this.actions$
      .pipe(
        ofType(SessionManagementTypes.uploadStyleForSessionPublishSuccess),
        takeUntil(this.stopper$),
        tap((response: any) => {
          this.istoggleShow = 'showURL';
          this._store.dispatch(new HideLoaderAction());
          // this._store.dispatch(new GetSessionDetailsStartAction(this.sessionId));
          this.showNonBlockingLoader = true;
          this.timeoutRef = setTimeout(() => {
            this.eligibleForPublishedUrlCall = true;
            if (this.istogglelinkType === 'WMS') {
              this.shareUrl = response.data.WMS_PREVIEW_URL || '';
              this.wmtsUrl = response.data.WMTS_URL || '';
              this.wmsUrl = response.data.WMS_URL || '';
              this.wmsIframeElement = `<iframe src=\"${response.data.WMS_PREVIEW_URL}\" title=\"Published from Online Transerve Stack\" width=\"500\" height=\"500\"></iframe>`
              this.wmsDoneClicked = true;
            } else {
              this.wFSUrl = response.data.WFS_URL;
              this.wfsApi = response.data.WFS_API;
              this.wfsIframeElement = `<iframe src="${response.data.WFS_URL}" title="Published from Online Transerve Stack" width="500" height="500"></iframe>`
              this.wfsDoneClicked = true;
            }
            this.showNonBlockingLoader = (this.showNonBlockingLoader && false);
            this.sliderOptions = { ...this.sliderOptions, disabled: false }
          }, 10000);
        })
      ).subscribe();
    this.sessionLayers = this.sessionService.sessionLayers.filter((layer) => layer.show || false).filter(({ layer_type }) => layer_type !== 4).filter((layer) => this.layerService.filterGraduatedLayers(layer)).map((layer) => layer.parent_layer_id);
    this.showSlideToggleButton = this.sessionService.sessionLayers.filter((layer) => layer.show).some((layer) => layer.layer_type === 3);
  }

  postStyledata() {
    this.sliderOptions = { ...this.sliderOptions, disabled: true }
    this._store.dispatch(new ShowLoaderAction());
    this.publishService.ngOnInitService();
    if (this.istoggleShow === 'private') {
      this.publishService.publishSession(this.sessionId, 'private', true, this.istogglelinkType, { 'session_layers': this.sessionLayers })
    } else {
      this.publishService.wfsPublishDetails = this.wfsPublishRequirements;
      this.publishService.publishSession(this.sessionId, 'public', true, this.istogglelinkType, { 'session_layers': this.sessionLayers })
    }
  }

  close() {
    if (this.eligibleForPublishedUrlCall) {
      this.dialogRef.close('published')
    } else {
      this.dialogRef.close();
    }
  }

  istoggle(event) {
    this.embedWmsUrlCopied = false;
    this.embedWfsUrlCopied = false;
    if (event === "publish") {
      if (this.showNonBlockingLoader) return;
      this.istoggleShow = event;
    } else if (event === "showURL" && this.istoggleShow != 'private') {
      if (this.showNonBlockingLoader) return;
      this.postStyledata();
      this.sessionService.showUrlByDefault = true;
      if (this.istogglelinkType === 'WMS') {
        this.wmsDoneButtonClicked = true;
        if (this.wmsPublished) {
          this.copyClickedFlag = false;
          this.copyWmtsClickedFlag = false;
          this.copyWMSClicked = false;
        }
      } else {
        this.wfsDoneButtonClicked = true;
        if (this.wfsPublished) {
          this.copyClickedFlag = false;
          this.copyWfsApiClickedFlag = false;
          this.copyWMSClicked = false;
        }
      }
      //send requirements via api call
    } else if (this.istoggleShow == 'private' && event === "showURL") {
      if (this.showNonBlockingLoader) return;
      this.postStyledata();
      this.dialogRef.close('unpublished');
      this.sessionService.showUrlByDefault = false;
    } else if (event == "private") {
      if (this.showNonBlockingLoader) return;
      this.istoggleShow = "private";
      this.copyClickedFlag = false;
      this.copyWMSClicked = false;
    } else if (event === "cancel") {
      // this.istoggleShow = null;
      if (this.eligibleForPublishedUrlCall) {
        this.dialogRef.close('published')
      } else {
        this.dialogRef.close();
      }
      this.showNonBlockingLoader = false;
    }
  }


  copyClicked(type) {
    if (this.showNonBlockingLoader) return;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    switch (type) {
      case 'shareUrl':
        if (this.istogglelinkType === 'WMS') {
          selBox.value = this.shareUrl;
        }
        else {
          selBox.value = this.wFSUrl;
        }
        this.copyClickedFlag = true;
        break;
      case 'wfsApi':
        this.copyWfsApiClickedFlag = true
        selBox.value = this.wfsApi;
        break;
      case 'wmtsUrl':
        this.copyWmtsClickedFlag = true;
        selBox.value = this.wmtsUrl;
        break;
      case 'wmsUrl':
        this.copyWMSClicked = true;
        selBox.value = this.wmsUrl;
        break;
      case 'embedWmsUrl':
        this.embedWmsUrlCopied = true;
        selBox.value = this.wmsIframeElement;

        break;
      case 'embedWfsUrl':
        this.embedWfsUrlCopied = true;
        selBox.value = this.wfsIframeElement;
        break;
    }
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  wmsWfsToggleClicked(linkType) {
    if (this.showNonBlockingLoader) return;
    this.sessionService.showUnApprovedData = false;
    this.istogglelinkType = linkType;
    if (this.istogglelinkType == 'WMS') {
      this.content = 'Use WMS (Web Map Services) option to create georeferenced map images on your mobile and/or web applications';
      if (!this.shareUrl.length) {
        this.istoggleShow = 'publish';
      } else {
        this.istoggleShow = 'showURL'
      }
      this.copyClickedFlag = false;
      this.copyWfsApiClickedFlag = false;
      this.copyWmtsClickedFlag = false;
      this.wfsClicked = false;
      this.copyWMSClicked = false;
    } else {
      this.content = 'WFS (Web Feature Services) option will enable you to access georeferenced maps and the feature attributes of all layers in this session';
      this.wfsClicked = true;

      if ((!this.wfsApi.length) && (!this.wFSUrl.length)) {
        this.istoggleShow = 'publish';
      } else {
        this.istoggleShow = 'showURL'
      }
      this.copyClickedFlag = false;
      this.copyWfsApiClickedFlag = false;
      this.copyWmtsClickedFlag = false;
      this.copyWMSClicked = false;
    }
  }

  closeClicked() {
    if (this.eligibleForPublishedUrlCall) {
      this.dialogRef.close('published')
    } else {
      this.dialogRef.close();
    }
  }

  onCopy(event) {
    if (this.showNonBlockingLoader) {
      event.preventDefault()
    }
  }

  slideButtonToggled(event) {
    if (this.showNonBlockingLoader) return
    this.sessionService.showUnApprovedData = event.target.checked
  }

  getPublishButtonName(caseNumber) {
    switch (caseNumber) {
      case 'wfs':
        return this.istoggleShow == 'private' ? 'UN-PUBLISH' : (this.wfsPublished ? 'RE-PUBLISH' : 'PUBLISH')
      case 'wms':
        return this.istoggleShow == 'private' ? 'UN-PUBLISH' : (this.wmsPublished ? 'RE-PUBLISH' : 'PUBLISH')
    }
  }
  shareAction(action, type) {
    let url = (type === 'wms') ? this.shareUrl : this.wFSUrl;
    if (!(url.length)) return
    switch (action) {
      case 'facebook':
        this.socialWindow('facebook', url)
        break;
      case 'twitter':
        this.socialWindow('twitter', url)
        break;
      case 'linkedin':
        this.socialWindow('linkedin', url)
        break;
    }
  }
  socialWindow(platform, publishedUrl) {

    let leftPosition;
    let topPosition;
    leftPosition = (window.screen.width / 2) - ((626 / 2) + 10);
    topPosition = (window.screen.height / 2) - ((436 / 2) + 50)
    let params = `toolbar=0,menubar=no,toolbar=no,status=0,width=626,resizable=yes,left=${leftPosition},topPosition=${topPosition},screenX=${leftPosition},screenY=${topPosition},height=436;`
    let url;
    switch (platform) {
      case 'facebook':
        url = "https://www.facebook.com/sharer.php?u=" + publishedUrl;
        break;
      case 'twitter':
        url = "https://twitter.com/intent/tweet?url=" + publishedUrl;
        break;
      case 'linkedin':
        url = "https://www.linkedin.com/shareArticle?mini=true&url=" + publishedUrl;
        break;
    }
    window.open(url, 'sharer', params)
  }
  minZoomSliderChange(event) {

    if (this.wfsDoneClicked) {
      this.wfsPublished = true;
      this.wfsDoneClicked = false;
    }
  }
  maxZoomSliderChange(event) {
    if (this.wfsDoneClicked) {
      this.wfsPublished = true;
      this.wfsDoneClicked = false;
    }
  }

  wfsToggleChange(event) {
    if (this.wfsDoneClicked) {
      this.wfsPublished = true;
      this.wfsDoneClicked = false;
    }
  }

  openLink(url){
    window.open(url,'_blank').focus();
  }

  ngOnDestroy() {
    this.stopper$.next()
    clearTimeout(this.timeoutRef)
  }





}