import { Action, createFeatureSelector, createSelector } from "@ngrx/store";
import { LoaderActionTypes } from "../actions/loader.actions";

export interface State {
  loading: number;
}

export const initialState: State = {
  loading: 0,
};

export function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case LoaderActionTypes.ShowLoader:
      return { loading: state.loading + 1 };
    case LoaderActionTypes.HideLoader:
      return { loading: state.loading - 1 < 0 ? 0 : state.loading - 1 };
    default:
      return state;
  }
}

export const loaderSelector = createSelector(
  createFeatureSelector<State>("loader"),
  loader => loader.loading
);
