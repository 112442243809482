import { Injectable } from "@angular/core";
import { API } from './API.service';
import { HttpClient } from '@angular/common/http';
import connProp from "src/assets/connection.properties.json";
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class SubscriptionService {
  connprops = connProp;
  subscriptionPlans: BehaviorSubject<Array<any>> = new BehaviorSubject([]);   //  holds all available subscription plans
  selectedPlan: BehaviorSubject<any> = new BehaviorSubject({}); //  the plan that the user has selected in the subscription slides
  newSubscriptionInfo: BehaviorSubject<any> = new BehaviorSubject({});  //  response of user's new subscription request.
  currentUrl: BehaviorSubject<string> = new BehaviorSubject("");
  neeToUpgrade: BehaviorSubject<boolean> = new BehaviorSubject(false);
  paymentRequired: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isNewUser:boolean=false
  constructor(
    private api: API,
    public http: HttpClient,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.url);
      };
    });
  }

  getCurrentUrl(): Observable<string> {
    return this.currentUrl.asObservable();
  }

  setSubscriptionPlans(plans) {
    this.subscriptionPlans.next(plans);
  }

  getSelectedPlan(): Observable<any> {
    return this.selectedPlan.asObservable();
  }

  setSelectedPlan(plan) {
    this.selectedPlan.next(plan);
  }

  getNewSubscriptionInfo(): Observable<any> {
    return this.newSubscriptionInfo.asObservable();
  }

  setNewSubscriptionInfo(info) {
    this.newSubscriptionInfo.next(info);
  }

  getNeedToUpgrade(): Observable<boolean> {
    return this.neeToUpgrade.asObservable();
  }

  setNeedToUpgrade(val) {
    this.neeToUpgrade.next(val);
  }

  getPaymentRequired(): Observable<boolean> {
    return this.paymentRequired.asObservable();
  }

  setPaymentRequired(val) {
    this.paymentRequired.next(val);
  }

  //  API request go below here.
  getSubscriptionPlans() {
    return this.api.getNew(this.connprops.subscriptionPlans, true);
  }

  subscribeToNewPlan(data) {
    const suburl = `${this.connprops.subscribeToPlan}`;
    const payload = {
      subURL: suburl,
      datum: data
    }
    return this.api.postNew(payload, true);
  }

  upgradeSubscriptionPlan(data) {
    const suburl = `${this.connprops.upgradePlan}`;
    const payload = {
      subURL: suburl,
      datum: data
    }
    return this.api.postNew(payload, true);
  }

  authorizeSubscriptionPayment(data) {
    const subURL = `${this.connprops.authorizePayment}`;
    const payload = {
      subURL,
      datum: data
    }
    return this.api.postNew(payload, true);
  }

  cancelSubscription(data) {
    const subURL = `${this.connprops.cancelSubscription}`
    const payload = {
      subURL,
      datum: data
    }
    return this.api.postNew(payload, true);
  }

  sendEmail(data) {
    const subURL = `${this.connprops.sendEmailSA}`
    let obj = { planId: data }
    const payload = {
      subURL,
      datum: obj
    }
    return this.api.postNew(payload, true);
  }

  optFreePlan(data) {
    const subURL = `${this.connprops.freePlan}`
    let obj = { planId: data }
    const payload = {
      subURL,
      datum: obj
    }
    return this.api.postNew(payload, true);
  }

  isPaymentRequired() {
    return this.api.getNew(this.connprops.isPaymentRequired, true);
  }

  hasValidSubscription() {
   // console.log("Test"+JSON.stringify(this.connprops))
    //return this.api.getNew(this.connprops.isValidSubscription, true);
    if(window.location.href.toString().includes("/preview")==false){
      return this.api.getNew(this.connprops.isValidSubscription, true);
     }
  }
  getAllInvoices() {
    return this.api.getNew(this.connprops.getInvoices, true)
  }
  getParticularInvioiceDetail(id) {
    const subUrl = `${this.connprops.getInvoiceId}/${id}`
    return this.api.getNew(subUrl, true, false)
  }

}