import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogContent } from '@angular/material';
import { Store } from '@ngrx/store';
import { CreateUpdateGroupStartAction, CreateUpdateGroupSuccessAction, DataManagementActionTypes, GetGroupLayersStartAction, GetGroupLayersSuccessAction, GetLayersListStartAction, GetLayersListSuccessAction, GetSelectedGroupLayerIdsStartAction, GetSelectedGroupLayerIdsSuccessAction, GetVectorLayersStartAction } from 'src/app/store/actions/data-management.actions';
import { DeleteSessionStartAction } from 'src/app/store/actions/session-management.actions';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, debounce, debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { groupLayerIdsSelector, layersListSelector, groupLayersSelector, groupNamesSelector } from 'src/app/store/selectors/data-management.selector';
import { Storeservice } from 'src/app/_services/store.services';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/_services/session.service';
import { LAYER_INACTIVE_ICONS, L_TYPE_OBJ } from 'src/app/app.constants';
import {uniq , uniqBy, cloneDeep} from 'lodash'
import { Actions, ofType } from '@ngrx/effects';
@Component({
  selector: 'grouplayer-dialog',
  templateUrl: './grouplayer-dialog.component.html',
  styleUrls: ['./grouplayer-dialog.component.scss']
})
export class GroupLayerComponent implements OnDestroy {

  groupName: FormControl;
  searchLayer: FormControl;
  stopper$: Subject<any> = new Subject();
  private subscriptionsArr: Array<Subscription> = [];
  groupLayers: Array<any> = [];
  layersToDisplay: any[] = [];
  selectedLayerIds: any[] = [];
  allLayersSelected: boolean = false;
  searchedString: string = '';
  layerType: string = ''
  layers: any[] = [];
  enteredValue: boolean = false;
  option: string = '';
  iconTypes = LAYER_INACTIVE_ICONS;
  groupNames: Array<string> = [];
  currentPageNumber: number = 1;
  totalPages: number = 0;
  groupLayersPageNumber: number = 0;
  totalGroupLayersPages: number = 0;
  checkedLayers : any[]=[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _store: Store<any>,
    public dialogRef: MatDialogRef<GroupLayerComponent>,
    public storeService: Storeservice,
    public toaster: ToastrService,
    private actions: Actions,
    public sessionService: SessionService) {

    this.groupName = new FormControl('',[ Validators.required, this.noWhitespaceValidator]);
    this.searchLayer = new FormControl('');
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.option = this.data.layerType
    this.groupName.setValue(this.data.groupName);
    if (this.data && (this.data.groupName) && (this.data.groupName.length)) {
      this.enteredValue = true;
    }
    switch (this.data.layerType) {
      case 'vector':
        this.layerType = "VECTOR"
        break;
      case 'raster':
        this.layerType = "RASTER"
        break;
      case 'tabular':
        this.layerType = "TABULAR"
        break;
    }
    if(this.data.action_type === "edit") {
      const payload = {
        layerType: this.data.layer_type,
        groupName: this.data.groupName
      }
      this._store.dispatch(new GetSelectedGroupLayerIdsStartAction(payload));
      this.getGroupLayers();
    } else {
      if(this.data.layerType) {
        this.getLayers();
      }
    }
    this.subscriptionsArr.push(
      this._store.select(groupNamesSelector).subscribe((data: Array<any>) => {
        this.groupNames = data;
      })
    )
    this.subscriptionsArr.push(
      this.groupName.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((val) => {
        if (val.trim().length) {
          this.enteredValue = true;
        } else {
          this.enteredValue = false;
        }
      })
    );
    this.subscriptionsArr.push(
      this.searchLayer.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((searchText) => {
        this.allLayersSelected = false;
        this.searchedString = searchText.trim();
        if(this.data.action_type === "edit") {
          this.getGroupLayers();
          this.getLayers();
        } else {
          this.getLayers();
        }
      })
    );
    this.subscriptionsArr.push(
      this._store.select(groupLayerIdsSelector).subscribe((response: Array<string>) => {
        if(!this.data.layerType) return;
        this.selectedLayerIds = cloneDeep(response);
      })
    );
    this.subscriptionsArr.push(
      this._store.select(groupLayersSelector).subscribe((response: any) => {
        if(!this.data.layerType || (this.data.action_type === "create") || !response.data.length) return;
        this.groupLayers = cloneDeep(response.data);
        this.layersToDisplay = this.groupLayers;
        this.totalGroupLayersPages = response.totalPages;
        this.groupLayersPageNumber = response.page;
        if(this.groupLayersPageNumber && (this.totalGroupLayersPages === this.groupLayersPageNumber)) this.getLayers();
        this.handleAllDataCheck();
      })
    );
    this.subscriptionsArr.push(
      this._store.select(layersListSelector).subscribe((resp: any) => {
        if(!this.data.layerType) return;
        if (resp && resp.layers) {
          this.totalPages = resp.totalPages;
          this.currentPageNumber = resp.page;
          const layers = cloneDeep(resp.layers);
          this.layersToDisplay = [...this.groupLayers, ...layers];
          this.handleAllDataCheck();
        }
      })
    );
    this.subscriptionsArr.push(
      this.actions.pipe(
        ofType(DataManagementActionTypes.createUpdateGroupSuccess),
        tap((action: CreateUpdateGroupSuccessAction) => {
          console.log("Actions:::: ", action);
          if(action.response.success) {
            if(this.data.action_type === 'create'){
              this.toaster.success("Group created successfully");
            } else {
              this.toaster.success("Group updated successfully");
            }
            this.cancel(true);
          }
        })
      ).subscribe()
    );
  }

  getGroupLayers(loadMore = false) {
    let payload = cloneDeep(GROUP_LAYERS_LIST_PARAMS);
    payload.search = this.searchedString;
    payload.groupName = this.data.groupName;
    payload.layerType = this.data.layer_type;
    if(loadMore) payload.page = this.groupLayersPageNumber + 1;
    this._store.dispatch(new GetGroupLayersStartAction(payload));
  }

  getLayers(loadMore = false) {
    let payload = cloneDeep(LAYERS_LIST_PARAMS)
    payload.search = this.searchedString;
    if(loadMore) payload.page = this.currentPageNumber + 1;
    payload.layerType = this.data.layer_type;
    if(this.data.groupName) payload.group_name = this.data.groupName;
    this._store.dispatch(new GetLayersListStartAction(payload));
  }

  checkUnChecklayer(event, layer, i) {
    if (event.checked) {
      this.selectedLayerIds.push(layer._id);
    } else {
      const ind = this.selectedLayerIds.indexOf(layer._id);
      if(ind >= 0) {
        this.selectedLayerIds.splice(ind, 1);
      }
    }
    this.handleAllDataCheck();
  }

  addToGroupClicked() {
    const existingGroups = this.groupNames.length ? this.groupNames.map((grp: any) => grp.name) : [];
    if(this.selectedLayerIds.length && this.groupName.value.trim().length) {
      if(this.groupName.valid) {
        const grpName = this.groupName.value.trim();
        if(this.data.action_type === 'create') {
          if(existingGroups.includes(grpName)) {
            this.toaster.error("Group name already exists");
          } else {
            this.createUpdateGroup(grpName);
          }
        } else {
          this.createUpdateGroup(grpName);
        }
      }
    }
  }

  createUpdateGroup(grpName) {
    const payload = {
      groupName: grpName,
      layer_ids: this.selectedLayerIds,
      layer_type: L_TYPE_OBJ[this.data.layerType]
    }
    this._store.dispatch(new CreateUpdateGroupStartAction(payload));
  }
  
  selectAllClicked(event) {
    this.allLayersSelected = event.checked;
    const layerIds = this.layersToDisplay.map(layer => layer.id);
    if (event.checked) {
      this.selectedLayerIds = uniq([...this.selectedLayerIds, ...layerIds]);
    } else {
      layerIds.forEach(id => {
        const ind = this.selectedLayerIds.indexOf(id);
        if(ind >= 0) {
          this.selectedLayerIds.splice(ind, 1);
        }
      })
    }
  }

  handleAllDataCheck() {
    const layerIds = this.layersToDisplay.map(layer => layer._id);
    let allSelected = true;
    if(this.selectedLayerIds.length) {
      layerIds.forEach((id) => {
        if(!this.selectedLayerIds.includes(id)) {
          allSelected = false;
          return;
        }
      })
      this.allLayersSelected = allSelected;
    } else {
      this.allLayersSelected = false;
    }
  }

  selectType(event) {
    const lTypeObj = {
      vector: 1,
      raster: 2,
      tabular: 8
    }
    this.data.layerType = event.value;
    this.data.layer_type = lTypeObj[event.value];
    this.getLayers();
    this.groupNames = this.groupNames.filter((grp: any) => grp.layer_type === this.data.layer_type);
  }

  onScroll(event) {
    const tableViewHeight = event.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = event.target.scrollHeight; // length of all table
    const scrollLocation = event.target.scrollTop; // how far user scrolled
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 30;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit) {
      this.loadMoreData();
    }
  }

  loadMoreData() {
    if(this.groupLayersPageNumber < this.totalGroupLayersPages) {
      this.getGroupLayers(true);
      return;
    }
    if(this.currentPageNumber < this.totalPages) {
      if(this.currentPageNumber === 0) this.getLayers();
      else this.getLayers(true);
    }
  }

  cancel(response = false) {
    this._store.dispatch(new GetSelectedGroupLayerIdsSuccessAction([]));
    this._store.dispatch(new GetGroupLayersSuccessAction({docs: [], limit: 20, page: "1", pages: 1, total: 0}));
    this._store.dispatch(new GetLayersListSuccessAction({data: [], count: 0, page: 1, pages: 1}));
    this.dialogRef.close(response);
  }

  public noWhitespaceValidator(control: FormControl) {
    if (!control.value) return null;
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnDestroy() {
    if(this.subscriptionsArr.length) {
      this.subscriptionsArr.map((eachSubscription) => eachSubscription.unsubscribe())
    }
    this.subscriptionsArr = null;
    this.stopper$.next();
  }

}

export const GROUP_LAYERS_LIST_PARAMS = {
  search: "",
  groupName: "",
  layerType: null,
  asc: false,
  page: 1,
  limit: 20
}

export const LAYERS_LIST_PARAMS = {
  search: "",
  page: 1,
  asc: false,
  limit: 20,
  layerType: null,
  group_name: null
}