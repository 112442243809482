import { State } from '../reducers/subscription.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const subscriptionFeatureSelector = createFeatureSelector<State>("subscriptions");

export const plansSelector = createSelector(
    subscriptionFeatureSelector,
    (state) => (state.plans || [])
)

export const subscriptionCurrencySelector = createSelector(
    subscriptionFeatureSelector,
    (state) => (state.currency || "INR")
)
export const subscriptionInvoiceSelector = createSelector(
    subscriptionFeatureSelector,
    (state) => (state.invoices)
)
export const sentEmailSelector = createSelector(
    subscriptionFeatureSelector,
    (state) => (state.sentEmail)
)
export const optFreeSelector = createSelector(
    subscriptionFeatureSelector,
    (state) => (state.freePlan)
)