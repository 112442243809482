import { Component, OnInit ,Inject, ChangeDetectorRef} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataManagementActionTypes,GetDLAttributesStartAction, GetTabularDLAttributesStartAction, GetTabularDLAttributesSuccessAction, JoinYourDataStartAction, JoinYourDataTabularStartAction } from 'src/app/store/actions/data-management.actions';
import { joinDataAttributesSelector } from 'src/app/store/selectors/data-management.selector';
import { SessionService } from 'src/app/_services/session.service';
import { JoinResultDialog } from '../join-result-dialog/join-result-dialog.component';
import _ from 'lodash';
import { LAYER_ACTIVE_ICONS, LAYER_TAG_IMAGES } from 'src/app/app.constants';
import { GoogleAnalyticsService } from 'src/app/_services/google-analytics.service';
@Component({
  selector: 'app-data-field-selection',
  templateUrl: './field-selection-dialog.component.html',
  styleUrls: ['./field-selection-dialog.component.scss']
})
export class FieldSelectionDialog implements OnInit {
  subscriptionArray: Array<Subscription> = [];
  layerAttributes: Array<any> = [];
  libraryAttributes: Array<any> = [];
  libraryAttributesInfo: any = null;
  layerAttributesInfo: any = null;
  filteredLayerAttrs: Array<any> = [];
  filteredLibAttrs: Array<any> = [];
  sheetName: string = null;
  sheetNames: Array<string> = [];
  hasSheets:boolean = false;
  fileName: string = null;
  selectedLayerAttr: string = null;
  selectedLibraryAttr: string = null;
  selectableAttrType: string = null;
  selectedLibraryAttrType: string = null;
  selectedLayerAttrType: any = null;
  selectedLayerForGeometry: string = null
  joinOptions: Array<any> = [
    {display_text: 'Perform a one-to-one join to get singularly matched layer entries', type: 'all'}
  ]
  joinType: FormControl = new FormControl(this.joinOptions[0].type, Validators.required);
  sheetNameField: FormControl = new FormControl(null);
  discardRecNotJoined: boolean = false;
  startTime: number = null;
  endTime: number = null;
  matchCount: number = null;
  mismatchCount: number = null;
  name1: string = null;
  name2: string = null;
  searchLayerAttrField: FormControl = new FormControl(null);
  searchLibAttrField: FormControl = new FormControl(null);
  serchSelected : boolean = false;
  serchSelectedAttrField: boolean = false;
  hasLayerAttrSelectionErr: boolean = false;
  hasLibraryAttrSelectionErr: boolean = false;
  layerTagImages = LAYER_TAG_IMAGES;
  layerTypeIcons = LAYER_ACTIVE_ICONS;
  constructor(
    public dialogRef: MatDialogRef<FieldSelectionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<any>,
    private _actions: Actions,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private gaService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    if(this.data.selectedLayer && this.data.selectedLibrary) {
      if(this.data.type === 'vector-vector') {
        this.selectedLayerForGeometry = this.data.selectedLibrary.layer_id;
      }
      this.name1 = this.data.selectedLayer.name;
      this.name2 = this.data.selectedLibrary.name;
      let payload = {
        layer_id: this.data.selectedLayer._id,
        library_id: this.data.selectedLibrary.layer_id,
        data_type: this.data.dataType,
        type: this.data.type
      }
      this._store.dispatch(new GetTabularDLAttributesSuccessAction({
        libraryData: {attributeTypes: []},
        layerData: {attributeTypes: []},
        fileName: ""
      }))
      this._store.dispatch(new GetTabularDLAttributesStartAction(payload));
    }
    this.subscriptionArray.push(
      this.searchLayerAttrField.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchStr: any) => {
        if(searchStr){
          this.serchSelectedAttrField = true;
        }
        else{
          this.serchSelectedAttrField = false;
        }
        this.filteredLayerAttrs = this.layerAttributes.filter(attr => (attr.name.toLowerCase()).includes(searchStr.trim()));
      })
    );
    this.subscriptionArray.push(
      this.searchLibAttrField.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchStr: any) => {
        if(searchStr){
          this.serchSelected = true;
        }
        else{
          this.serchSelected = false;
        }
        this.filteredLibAttrs = this.libraryAttributes.filter(attr => (attr.name.toLowerCase()).includes(searchStr.trim()));
      })
    );
    this.subscriptionArray.push(
      this.sheetNameField.valueChanges.subscribe(val => {
        if(!this.sheetName) return;
        this.sheetName = val;
        this.sessionService.selectedTabularDataSheetName = this.sheetName;
        this.layerAttributes.map(attr => attr.selected = false);
        this.libraryAttributes.map(attr => attr.selected = false);
        this.selectedLibraryAttrType = null;
        this.selectedLibraryAttr = null;
        this.selectedLayerAttr = null;
        this.selectedLayerAttrType = null;
        this.selectableAttrType = null;
        if(this.data.type === 'tabular-vector') {
          this.setLibraryAttributes();
        } else {
          this.setLayerAttributes();
        }
      })
    );
    this.subscriptionArray.push(
      this._actions.pipe(
        ofType(DataManagementActionTypes.joinYourDataSuccess),
        map((response: any) => {
          this.endTime = Math.floor(Date.now());
          const diff = +(Math.floor((this.endTime - this.startTime) / 60) / 1000).toFixed(3);
          if(response.response.table_join) {
            this.matchCount = response.response.table_join.matchCount;
            this.mismatchCount = response.response.table_join.misMatch;
            const data = {
              dialog_title: "Join Your Data",
              success_count_text: `${this.matchCount} feature(s) from input layer were successfully joined`,
              error_count_text: `${this.mismatchCount} feature(s) from input layer could not join`,
              time_elapsed_text: `Join operation completed in ${diff} seconds`
            }
            const resultDialog = this.dialog.open(JoinResultDialog, {
              maxWidth: 800,
              hasBackdrop: true,
              disableClose: true,
              data
            })
            this.close(false);
          }
        })
      ).subscribe()
    );
    this.subscriptionArray.push(
      this._actions.pipe(
        ofType(DataManagementActionTypes.joinYourDataTabularSuccess),
        map((response: any) => {
          this.endTime = Math.floor(Date.now());
          const diff = +(Math.floor((this.endTime - this.startTime) / 60) / 1000).toFixed(3);
          if(response.response.table_join) {
            this.matchCount = response.response.table_join.matchCount;
            this.mismatchCount = response.response.table_join.misMatch;
            const data = {
              dialog_title: "Join My Data",
              success_count_text: `${this.matchCount} feature(s) from input layer were successfully joined`,
              error_count_text: `${this.mismatchCount} feature(s) from input layer could not join`,
              time_elapsed_text: `Join operation completed in ${diff} seconds`,
              type:"vector-tabular"
            }
            const resultDialog = this.dialog.open(JoinResultDialog, {
              maxWidth: 800,
              hasBackdrop: true,
              disableClose: true,
              data
            })
            this.close(false);
          }
        })
      ).subscribe()
    );
  }

  ngAfterViewInit() {
    this.subscriptionArray.push(
      this._store.select(joinDataAttributesSelector).subscribe((data: any) => {
        if(data.layerAttributes.length) {
          let layerInfo = _.cloneDeep(data.layerAttributes);
          if(this.data.type === "vector-tabular") {
            this.sheetNames = layerInfo.map(lbr => lbr.sheet_name);
            if(!this.sheetNames.filter(sn => !!sn).length) return;
            this.layerAttributesInfo = layerInfo.reduce((acc, cur) => {
              acc[cur.sheet_name] = cur.attribute_type;
              return acc;
            }, {})
            this.sheetName = this.sheetNames[0];
            this.sessionService.selectedTabularDataSheetName = this.sheetName;
            if( this.sheetName){
              this.hasSheets=true;
            }
            this.sheetNameField.setValue(this.sheetName);
            this.cdr.detectChanges();
            this.setLayerAttributes();
          } else {
            if(data.layerAttributes[0].hasOwnProperty('sheet_name')) {
              if(!data.layerAttributes[0].sheet_name) {
                this.layerAttributes = _.cloneDeep(data.layerAttributes[0].attribute_type);
              }
            } else {
              this.layerAttributes = _.cloneDeep(data.layerAttributes);
            }
            this.layerAttributes.map(attr => attr.selected = false);
            this.filteredLayerAttrs = this.layerAttributes;
          }
        }
        if(data.libraryInfo.length) {
          let libraryInfo = _.cloneDeep(data.libraryInfo);
          if(["vector-vector", "vector-tabular"].includes(this.data.type)) {
            this.libraryAttributes = libraryInfo;
            this.filteredLibAttrs = this.libraryAttributes;
            this.hasSheets = false;
            this.cdr.detectChanges();
          } else {
            this.sheetNames = libraryInfo.map(lbr => lbr.sheet_name);
            if(!this.sheetNames.filter(sn => !!sn).length) return;
            this.libraryAttributesInfo = libraryInfo.reduce((acc, cur) => {
              acc[cur.sheet_name] = cur.attribute_type;
              return acc;
            }, {})
            this.sheetName = this.sheetNames[0];
            this.sessionService.selectedTabularDataSheetName = this.sheetName;
            if( this.sheetName){
              this.hasSheets=true;
            }
            this.sheetNameField.setValue(this.sheetName);
            this.cdr.detectChanges();
            this.setLibraryAttributes();
          }
        }
        this.fileName = data.fileName;
      })
    );
  }
    
  setLibraryAttributes() {
    this.libraryAttributes = this.libraryAttributesInfo[this.sheetName];
    this.libraryAttributes.map(attr => attr.selected = false);
    this.filteredLibAttrs = this.libraryAttributes;
  }

  setLayerAttributes() {
    console.log("layerAttributesInfo: ", this.layerAttributesInfo);
    console.log("sheetName: ", this.sheetName);
    this.layerAttributes = this.layerAttributesInfo[this.sheetName];
    this.layerAttributes.map(attr => attr.selected = false);
    this.filteredLayerAttrs = this.layerAttributes;
  }
  crossClicked(){
    this.serchSelected = !this.serchSelected; 
    this.searchLibAttrField.setValue('');
  }
  crossClickedAtri(){
    this.serchSelectedAttrField = !this.serchSelectedAttrField; 
    this.searchLayerAttrField.setValue('');
  }
  selectLayerAttr(attr) {
    if(attr.selected) {
      this.layerAttributes.map(attr => attr.selected = false);
      this.selectedLayerAttrType = null;
      this.selectedLayerAttr = null;
      if(!this.selectedLibraryAttr || !this.selectedLibraryAttrType) this.selectableAttrType = null;
    } else {
      this.layerAttributes.map(attr => attr.selected = false);
      const attribute = this.layerAttributes.find(att => att.name === attr.name);
      if(attribute) attribute.selected = true;
      this.selectableAttrType = attr.type;
      this.selectedLayerAttrType = attr.type;
      this.selectedLayerAttr = attr.name;
      if(this.selectedLayerAttrType !== this.selectedLibraryAttrType) {
        this.libraryAttributes.map(attr => attr.selected = false);
        this.selectedLibraryAttr = null;
        this.selectedLibraryAttrType = null;
      }
    }
  }

  selectLibraryAttr(attr) {
    if(attr.selected) {
      this.libraryAttributes.map(attr => attr.selected = false);
      this.selectedLibraryAttrType = null;
      this.selectedLibraryAttr = null
      if(!this.selectedLayerAttr || !this.selectedLayerAttrType) this.selectableAttrType = null;
    } else {
      this.libraryAttributes.map(attr => attr.selected = false);
      const attribute = this.libraryAttributes.find(att => att.name === attr.name);
      if(attribute) attribute.selected = true;
      this.selectableAttrType = attr.type;
      this.selectedLibraryAttrType = attr.type;
      this.selectedLibraryAttr = attr.name;
    }
  }

  joinData() {
    if(!this.isFormValid()) return;
    this.gaService.eventEmitter(`join_your_data`, "data library", "click", `join_your_data`, 1);
    let payload = {
      input_layer_1: {
        layer_id: this.data.selectedLayer._id,
        attribute_name: this.selectedLayerAttr
      },
      input_layer_2: {
        attribute_name: this.selectedLibraryAttr,
        layer_id: this.data.selectedLibrary.layer_id
      },
      discard: this.discardRecNotJoined,
      data_type: this.data.dataType
    }
    if(this.data.type === "tabular-vector") {
      payload['feature_type'] = "tabular-vector";
      payload.input_layer_2['sheet_name'] = this.sheetName;
    } else if(this.data.type === "vector-tabular") {
      payload['feature_type'] = "vector-tabular";
      payload.input_layer_1['sheet_name'] = this.sheetName;
    } else {
      payload['feature_type'] = "vector-vector";
      payload.input_layer_1['sheet_name'] = this.sheetName;
      payload['geomentry'] = this.selectedLayerForGeometry;
    }
    this.startTime = Math.floor(Date.now());
    this._store.dispatch(new JoinYourDataTabularStartAction(payload));
    let data: any = _.cloneDeep(payload);
    data.input_layer_1.layer_name = this.data.selectedLayer.name;
    data.input_layer_1.layer_type = this.data.selectedLayer.layer_type;
    data.input_layer_1.attribute_type = this.selectedLayerAttrType;
    data.input_layer_2.attribute_type = this.selectedLibraryAttrType;
    data.input_layer_2.layer_name = this.data.selectedLibrary.name;
    data.input_layer_2.layer_type = this.data.selectedLibrary.layer_type;
    data.selection_type = this.joinType.value;
    data.name1 = this.name1;
    data.name2 = this.name2;
    this.sessionService.setJoinDataRequest(data);
  }

  setSelectedGeometry(event) {
    this.selectedLayerForGeometry = event.value;
  }

  isFormValid() {
    if(!this.joinType.valid) this.joinType.markAsTouched({onlySelf: true});
    if(!this.selectedLayerAttr) this.hasLayerAttrSelectionErr = true; else this.hasLayerAttrSelectionErr = false;
    if(!this.selectedLibraryAttr) this.hasLibraryAttrSelectionErr = true; else this.hasLibraryAttrSelectionErr = false;    
    return (this.joinType.valid && this.selectedLayerAttr && this.selectedLibraryAttr);
  }

  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.subscriptionArray.length) {
      this.subscriptionArray.map(sub => sub.unsubscribe());
      this.subscriptionArray = [];
    }
    this.dialogRef.close();
  }
}
