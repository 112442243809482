import { Injectable } from "@angular/core";
import { API } from "./API.service";
import connProp from 'src/assets/connection.properties.json';
import { share } from 'rxjs/operators';
import { environment as env } from "../../environments/environment";
import { SessionService } from './session.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { SubLegendModel } from '../app-home/print-and-export/print-and-export.component';
import { cloneDeep } from 'lodash';
import * as turf from '@turf/turf';

@Injectable()
export class LayerService {
  uploadPayloadSubject: Subject<any> = new Subject()
  connProps: any = connProp;

  constructor(
    private apiService: API,
  ) {
  }

  getAll(options) {
    const path = `${this.connProps.layers}`;
    return this.apiService.get(path, true, false, options);
  }

  renameLayer(options) {
    let path = `${this.connProps.RenameLayer}${options._id}?name=${options.name}`;
    if (options.type) {
      path = `${this.connProps.RenameLayer}${options._id}?name=${options.name}&type=${options.type}`;
    }
    return this.apiService.get(path);
  }

  renameGroup(data) {
    const payload = {
      subURL: `${this.connProps.RenameGroup}`,
      datum: data
    }
    return this.apiService.post(payload, true);
  }

  /*  Methods from LAyerSerachService: Starts */
  getLayerGraphicsData(dataObj) {
    console.log("May 25 Code Reaches here")
    const path = `${this.connProps.layerGraphicData}`;
    return this.apiService.post({ subURL: path, datum: dataObj }, true).pipe(share());
  }
  getPublicLayerGraphicsData(sessionHitId, userToken, dataObj) {
    const path = `${this.connProps.publicGraphics}?session_hit_id=${sessionHitId}&access_token=${userToken}`;
    return this.apiService.post({ subURL: path, datum: dataObj }, true)
  }
  getLayerProperties(dataObj) {
    const path = `${this.connProps.layerPropertiesData}`;
    return this.apiService.post({ subURL: path, datum: dataObj }, true);
  }
  getLayerAttributes(layerId) {
    const path = `${this.connProps.layerAttributes}` + '/' + layerId;
    return this.apiService.get(path, true);
  }

  uploadLayer(fileType, fd) {
    let path = "";
    switch (fileType) {
      case "vectorFile":
        path = `${this.connProps.uploadLayerData}`;
        break;
      case "kmlFile":
      case "kmzFile":
        path = `${this.connProps.uploadKMLLayer}`;
        break;
      case "dxfFile":
        path = `${this.connProps.uploadDxfLayer}`;
        break;
      case "jsonFile":
        path = `${this.connProps.uploadGeojsonLayer}`;
        break;
      case "geojsonFile":
        path = `${this.connProps.uploadGeojsonLayer}`;
        break;
      case "csvFile":
        path = `${this.connProps.uplaodCSVLayer}`;
    }
    return this.apiService.post({ subURL: path, datum: fd }, true, env.uploadPath);
  }

  uploadKMLLayer(fd) {
    const path = `${this.connProps.uploadKMLLayer}`;
    return this.apiService.post({ subURL: path, datum: fd }, true, env.uploadPath);
  }
  uploadRasterLayer(formData: any) {
    const path = `${this.connProps.uploadRasterLayer}`;
    return this.apiService.post({ subURL: path, datum: formData }, true, env.rasterUploadInfoApiPath);
  }
  uploadTabularLayer(formData: any) {
    const path = `${this.connProps.uploadTabularLayer}`;
    return this.apiService.post({ subURL: path, datum: formData }, true, env.rasterUploadInfoApiPath);
  }
  uploadStyle(sessionId, fd) {
    const path = `${this.connProps.publishUpload}${sessionId}`;
    return this.apiService.post({ subURL: path, datum: fd }, true);
  }
  // datalibraryUpload(obj: any) {
  //   const path = `${this.connProps.datalibraryUpload}`;
  //   return this.apiService.post({ subURL: path, datum: obj }, true);
  // }
  changeFilePermission(data) {
    const payload = {
      subURL: `${this.connProps.changeFilePermission}`,
      datum: data
    }
    return this.apiService.post(payload, true);
  }



  getLayerTableData(layerId, payload) {
    const subURL = `${this.connProps.layerTableData}${layerId}`
    return this.apiService.getNew(subURL, true, false, payload);
  }

  postLayerTableData(layerId, payload) {
    const subURL = `${this.connProps.layerTableData}${layerId}`
    return this.apiService.post({ subURL, datum: payload }, true);
  }

  updateLayerTableDataEntry(layerEntryId, payload) {
    const subURL = `${this.connProps.updateLayerDataEntry}${layerEntryId}`;
    return this.apiService.post({ subURL, datum: payload }, true);
  }


  getLayerUniqueProperties(layerId, payload) {
    const subURL = `${this.connProps.layerUniqueValues}${layerId}`
    return this.apiService.getNew(subURL, true, false, payload);
  }

  getLayerStyleTypes() {
    const subURL = `${this.connProps.layerStyleTypes}`
    return this.apiService.getNew(subURL, true)
  }

  getIdentifyResultsForData(payload) {
    const subURL = `${this.connProps.identifyPoint}`;
    return this.apiService.post({ subURL, datum: payload }, true);
  }

  getIdentifyResultsForWMS(payload) {
    const subURL = `${this.connProps.publicIdentify}`;
    return this.apiService.postNew({ subURL: subURL, datum: payload }, false);
  }
  /*  Methods from LAyerSerachService: Ends */
  getShareStyleJson(sessionId, accessToken) {
    const subURL = `${this.connProps.getStyleJsonForShare}${sessionId}?access_token=${accessToken}`;
    return this.apiService.getNew(subURL, false);
  }

  getSavedQueries() {
    return this.apiService.getNew(`${this.connProps.queries}/`, false);
  }

  getLayerQueryData(layerId, query, apiData) {
    let path = `${this.connProps.getLayerQueryData}/${layerId}?q=${query}`;
    // for (var key in params) {
    //   path += "&" + key + "=" + params[key];
    // }
    return this.apiService.getNew(path, true, false, apiData);
  }

  createQuery(dataObj) {
    return this.apiService.post({
      subURL: `${this.connProps.queries}`,
      datum: dataObj
    }, true);
  }

  getSelectionGraphics(data: any) {
    const payload = {
      subURL: `${this.connProps.getSelectionGraphics}`,
      datum: data
    }
    return this.apiService.postNew(payload, true);
  }

  getRowDataDetails(id: string) {
    let path = `${this.connProps.getRowDataDetails}${id}`;
    return this.apiService.getNew(path, true);
  }

  setRowDataDetails(id: string, data: any) {
    const payload = {
      subURL: `${this.connProps.setRowDataDetails}${id}`,
      datum: data
    }
    return this.apiService.postNew(payload, true);
  }

  getSignedUrlForUploadedFiles(dataObj) {
    const path = `${this.connProps.signedUrlsForFiles}`;
    return this.apiService.post({ subURL: path, datum: dataObj }, true);
  }
  getPublishedLinks(sessionId) {
    const path = `${this.connProps.showUrl}${sessionId}`
    return this.apiService.getNew(path);
  }
  getBaseMapSession() {
    const path = `${this.connProps.getBaseSessions}`;
    return this.apiService.getNew(path, true);
  }

  sendDataForApproval(id, payload) {
    const path = `${this.connProps.sendForApproval}${id}`;
    return this.apiService.post({ subURL: path, datum: payload }, true);
  }

  addDataForApproval(payload) {

    console.log("Approval"+JSON.stringify(payload))

    return this.apiService.postNewForm({ subURL: `${this.connProps.addForApproval}`, datum: payload }, true);
  }

  getUnapprovedFeatures(layerId, payload) {
    const body = {
      subURL: `${this.connProps.unapprovedFeatures}${layerId}`,
      datum: {
        users: payload.users,
        fromDate: payload.fromDate,
        toDate: payload.toDate
      }
    }
    const params = {
      page: payload.page,
      limit: payload.limit,
      approval_status: payload.approval_status,
    }
    return this.apiService.postNew(body, true, params);
  }

  approveFeature(data) {
    const payload = {
      subURL: `${this.connProps.approveFeature}`,
      datum: data
    }
    return this.apiService.postNew(payload, true);
  }


  assignSurveyors(sessionId: string, surveyors: Array<string>) {
    const payload = {
      subURL: `${this.connProps.assignSurveyors}/${sessionId}`,
      datum: surveyors
    }
    return this.apiService.postNew(payload, true);
  }

  getMyUnapprovedFeatures(layerId) {
    const path = `${this.connProps.myUnapprovedFeatures}${layerId}`;
    return this.apiService.getNew(path, true);
  }

  exportData(payload) {
    const suburl = `${this.connProps.exportData}`;
    return this.apiService.postFile(suburl, true, false, payload);
  }

  exportQueryData(payload) {
    const suburl = `${this.connProps.exportQueryData}`;
    return this.apiService.postFile(suburl, true, false, payload);
  }

  exportGraphicData(data) {
    const suburl = `${this.connProps.exportGraphicData}`;
    return this.apiService.postFile(suburl, true, false, data);
  }

  exportSearchResult(payload) {
    const suburl = `${this.connProps.exportAdvancedResult}`;
    if(['regression', 'nearest-hub', 'spatial-join','similarity-search','search-by-location','dissolve','intersect','union','buffer','difference'].includes(payload.feature_type) && env.production) {
      const hostUrl = this.apiService.changeBaseUrl(env.apiPath);
      const subURL = `${hostUrl}${suburl}`;
      return this.apiService.postFile(subURL, true, true, payload);
    } else {
      return this.apiService.postFile(suburl, true, false, payload);
    }
  }
  getAttributeInfo(layerId: string, attribute: string) {
    const payload = {
      subURL: `${this.connProps.layerAttributeInfo}`,
      datum: {
        "layer_id": layerId,
        "attribute": attribute
      }
    }
    return this.apiService.postNew(payload, true);
  }

  createTemporaryLayer(data) {
    const suburl = `${this.connProps.createTemporaryLayer}`;
    const payload = {
      subURL: suburl,
      datum: data
    }
    if(['regression', 'nearest-hub', 'spatial-join','similarity-search','search-by-location','dissolve','intersect','union','buffer','difference'].includes(data.feature_type) && env.production) {
      const hostUrl = this.apiService.changeBaseUrl(env.apiPath);
      return this.apiService.post(payload, true, hostUrl);
    } else {
      return this.apiService.postNew(payload, true);
    }
  }

  
  createPermanentLayer(data) {
    const payload = {
      subURL: `${this.connProps.createPermanentLayer}`,
      datum: data
    }
    console.log("data.feature_type",data);
    
    if(['regression', 'nearest-hub', 'spatial-join','similarity-search','search-by-location','dissolve','intersect','union','buffer','difference'].includes(data.feature_type) && env.production) {
      console.log("Inside Permanent");
      
      const hostUrl = this.apiService.changeBaseUrl(env.apiPath);
      return this.apiService.post(payload, true, hostUrl);
    } else {
      return this.apiService.postNew(payload, true);
    }
  }

  updateQuery(id, data) {
    const payload = {
      subURL: `${this.connProps.updateQuery}${id}`,
      datum: data
    }
    return this.apiService.postNew(payload, true)
  }
  deleteQuery(id) {
    const path = `${this.connProps.deleteQuery}${id}`;
    return this.apiService.getNew(path, true);
  }
  sessionUploadFilesessionUploadFile
  sendFileToS3WithType(url, fd, type) {
    return this.apiService.putFileToS3WithType(url, fd, type);
  }
  getWmsStyleObj(sessionId, accessToken) {
    const subURL = `${this.connProps.wmsPreview}${sessionId}?access_token=${accessToken}`;
    return this.apiService.getNew(subURL, false);
  }

  getOSMTranserveSources() {
    const path = `${this.connProps.osmTranserveSources}`
    return this.apiService.getNew(path, true);
  }

  getOSMLayersInfo(sessionId: string) {
    const path = `${this.connProps.osmLayersInfo}${sessionId}`;
    return this.apiService.getNew(path, false);
  }

  getPointLayerIcons(accessToken: string) {
    const subURL = `${this.connProps.PointLayerIcons}?access_token=${accessToken}`;
    return this.apiService.getNew(subURL, false);
  }

  sessionUploadFile(formData, id, data) {
    let path;
    if (data.type === 'dxffiles') {
      path = `${this.connProps.sessionUploadFile}/${id}?type=${data.type}&projection_id=${data.projection_id}`
    } else if (data.type === 'csvfiles') {
      path = `${this.connProps.sessionUploadFile}/${id}?type=${data.type}`
    } else {
      path = `${this.connProps.sessionUploadFile}/${id}?type=${data.type}`
    }
    let payload = {
      subURL: path,
      datum: formData
    }
    return this.apiService.post(payload, true, env.uploadPath);
  }

  getProcessingVectorLayers(sessionId) {
    const path = `${this.connProps.getProcessingVectorLayers}${sessionId}`
    return this.apiService.getNew(path, true);
  }

  saveMapOnCloud(data) {
    const payload = {
      subURL: `${this.connProps.saveMapOnCloud}`,
      datum: data
    }
    return this.apiService.postNew(payload, true);
  }

  setSessionPreviewIcon(formData, sessionId) {
    console.log("Data comes here");
    const path = `${this.connProps.setSessionPreviewPic}${sessionId}`;
    let payload = {
      subURL: path,
      datum: formData
    }
    return this.apiService.postNew(payload, true);
  }
  updateIconSelection(payload) {
    const subURL = `/organisation/add_icon/${payload.sessionId}`
    const obj = {
      datum: {
        name: payload.iconName
      },
      subURL
    }
    return this.apiService.postNew(obj, true)

  }
  getPublishInfo(sessionHitId: any, userToken: any) {

    const subURL = `${this.connProps.publishInfo}${sessionHitId}?access_token=${userToken}`;

    return this.apiService.getNew(subURL, false);

  }

  createNetworkLayers(data) {
    const subURL = `${this.connProps.CreateNetworkLayers}`;
    const payload = {
      subURL,
      datum: data
    }
    const hostUrl = this.apiService.changeBaseUrl(env.apiPath);
    return this.apiService.post(payload, true, hostUrl);
  }

  getFirstFeatureDetails(layerId) {
    const subURL = `${this.connProps.firstFeatureDetails}${layerId}`;
    return this.apiService.getNew(subURL, true);
  }

  getLegendColor(layer) {
    if (layer.layer_type === 1 || layer.layer_type === 6) {
      if (layer.style_type === 1) {
        return layer.icon_style[0].iconColor;
      }
      else if (layer.style_type === 2) {
        return layer.category_icon_style.find(style => style.is_fall_back).iconColor;
      } else if (layer.style_type === 3) {
        return '';
      } else if (layer.style_type === 4) {
        return layer.heat_map_style.bubble_icon_style[0].iconColor;
      } else if (layer.style_type === 5) {
        return layer.heat_map_style.heat_map_icon_style[0].iconColorFrom;
      } else if (layer.style_type === 6) {
        return layer.heat_map_style.dotted_icon_style[0].iconColor;
      } else if (layer.style_type === 7) {
        return layer.heat_map_style.clustered_icon_style[0].iconColorFrom;
      }
    } else if (layer.layer_type === 2) {
      return ''
    } else if (layer.layer_type === 3 || layer.layer_type === 4) {
      if (layer.style_type === 1) {
        return layer.icon_style[0].iconColor;
      } else {
        return '';
      }
    } else if (layer.layer_type === 5) {
      return '';
    }
  }

  getLegendZoomRanges(layer) {
    let zoomRanges: Array<SubLegendModel> = [];
    switch (layer.layer_type) {
      case 1:
      case 6:
        if (layer.style_type === 1) {
          return [];
        } else if (layer.style_type === 2) {
          layer.category_icon_style.forEach(style => {
            if (((style.minZoomLevel <= 22) && (style.maxZoomLevel > 1)) && !style.is_fall_back && (style.categoryKey == layer.category_label)) {
              zoomRanges.push({
                sub_legend_color: style.iconColor,
                sub_legend_line1: style.categoryValue,
                sub_legend_line2: '' // `z${style.minZoomLevel} - z${style.maxZoomLevel}`
              })
            }
          });
          return zoomRanges;
        } else if (layer.style_type === 3) {
          let classificationStyles = [];
          const clMethod = layer.classification_method;
          switch (clMethod) {
            case 1:
              classificationStyles = this.getLegendsColorNText(clMethod, layer.classification_style.equal_interval.ranges || []);
              break;
            case 2:
              classificationStyles = this.getLegendsColorNText(clMethod, layer.classification_style.quantile.ranges || []);
              break;
            case 3:
              classificationStyles = this.getLegendsColorNText(clMethod, layer.classification_style.natural_breaks.ranges || []);
              break;
            case 4:
              classificationStyles = this.getLegendsColorNText(clMethod, layer.classification_style.standard_deviation.ranges || []);
              break;
            case 5:
              classificationStyles = this.getLegendsColorNText(clMethod, layer.classification_style.pretty_breaks.ranges || []);
              break;
            case 6:
              classificationStyles = this.getLegendsColorNText(clMethod, layer.classification_style.manual.ranges || []);
              break;
          }
          classificationStyles.forEach(style => {
            zoomRanges.push({
              sub_legend_color: style.color,
              sub_legend_line1: layer.graduated_label,
              sub_legend_line2: style.text
            })
          })
          return zoomRanges
      
        }
      case 2:
      case 3:
      case 4:
      case 5:
        return [];
    }
    return [];
  }

  getLegendsColorNText(clMethod, classificationArray) {
    let classificationStyleList = [];
    switch (clMethod) {
      case 1:
      case 2:
      case 3:
      case 5:
      case 6:
        classificationArray.forEach(clas => {
          if (clas.from) {
            classificationStyleList.push({
              color: clas.color,
              text: `${(+clas.from % 1 === 0) ? clas.from : (+clas.from).toFixed(2)} - ${(+clas.to % 1 === 0) ? clas.to : (+clas.to).toFixed(2)}`,
              fromText:clas.from,
              toText:clas.to,
            })
          } else {
            classificationStyleList.push({
              color: clas.color,
              text: `${clas.from} - ${(+clas.to % 1 === 0) ? clas.to : (+clas.to).toFixed(2)}`,
              fromText:clas.from,
              toText:clas.to,
            })
          }
        });
        break;
      case 4:
        const arrLen = classificationArray.length;
        classificationArray.forEach((clas, i) => {
          if (i === 0) {
            classificationStyleList.push({
              color: clas.color,
              text: `< ${(+clas.to % 1 === 0) ? clas.to : (+clas.to).toFixed(2)} (1 SD)`,
              fromText:clas.from,
              toText:clas.to,
            })
          } else if (i === arrLen - 1) {
            classificationStyleList.push({
              color: clas.color,
              text: `> ${(+clas.from % 1 === 0) ? clas.from : (+clas.from).toFixed(2)} (${arrLen} SD)`,
              fromText:clas.from,
              toText:clas.to,
            })
          } else {
            if (clas.from) {
              classificationStyleList.push({
                color: clas.color,
                text: `${(+clas.from % 1 === 0) ? clas.from : (+clas.from).toFixed(2)} - ${(+clas.to % 1 === 0) ? clas.to : (+clas.to).toFixed(2)} (${i + 1} SD)`,
                fromText:clas.from,
                toText:clas.to,
              })
            } else {
              classificationStyleList.push({
                color: clas.color,
                text: `${clas.from} - ${(+clas.to % 1 === 0) ? clas.to : (+clas.to).toFixed(2)} (${i + 1} SD)`,
                fromText:clas.from,
                toText:clas.to,
              })
            }
          }
        });
        break;
    }
    return classificationStyleList;
  }

  validateAddress(response) {
    return response.reduce((prev, curr) => {
      let addressTwo = cloneDeep(curr).properties.Address.split(',').filter((e, i) => i !== 0).join(',')
      curr['addressOne'] = cloneDeep(curr).properties.Address.split(',')[0];
      curr['addressTwo'] = addressTwo;
      return [...prev, curr]
    }, [])
  }
  validateHereAddress(response) {
    return response.reduce((prev, curr) => {
      let addressTwo = cloneDeep(curr).address.label.split(',').filter((e, i) => i !== 0).join(',')
      // console.log(cloneDeep(curr).address);
      curr['addressOne'] = cloneDeep(curr).address.label.split(',')[0];
      curr['addressTwo'] = addressTwo;
      // console.log(curr['addressOne']);
  
      return [...prev, curr]
    }, [])
  }
  filterGraduatedLayers(layer) {
    if (layer.style_type == 3) {
      let availableConditions = [];
      let graduatedConditions = [];
      switch (layer.classification_method) {
        case 1:
          graduatedConditions = layer.classification_style.equal_interval['ranges'];
          break;
        case 2:
          graduatedConditions = layer.classification_style.quantile['ranges'];
          break;
        case 3:
          graduatedConditions = layer.classification_style.natural_breaks['ranges'];
          break;
        case 4:
          graduatedConditions = layer.classification_style.standard_deviation['ranges'];

          break;
        case 5:
          graduatedConditions = layer.classification_style.pretty_breaks['ranges'];
          break;
          case 6:
          graduatedConditions =layer.classification_style.manual['ranges']
      }
      availableConditions = (graduatedConditions || []).filter((conditionObj) => {
        return (((conditionObj.from) || (conditionObj.from == 0)) ? true : false)
      })

      return (availableConditions.length) ? true : false
    } else {
      return true
    }

  }
  getScreenBounds = (map) => {
    const bounds = map.getBounds();
    const southEast = bounds.getSouthEast();
    const northWest = bounds.getNorthWest();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    if (northWest.lng === northEast.lng && northWest.lat === northEast.lat) {
      return null;
    }
    const polygon = turf.polygon([
      [
        [northWest.lng, northWest.lat],
        [northEast.lng, northEast.lat],
        [southEast.lng, southEast.lat],
        [southWest.lng, southWest.lat],
        [northWest.lng, northWest.lat]
      ]
    ]);
    return polygon;
  };
  layerInSBound(map, layer) {
    const polygon = this.getScreenBounds(map);
    const lPolygon = this.getLayerBBox(layer);
    return lPolygon ? !(turf.booleanDisjoint(polygon, lPolygon)) : true;
  }
  getLayerBBox = (layer) => {
    const bbox = layer.bounding_box || [];
    if (Array.isArray(bbox) && bbox.length && bbox.length === 4) {
      const southEast = { lat: bbox[1], lng: bbox[2] };
      const northWest = { lat: bbox[3], lng: bbox[0] };
      const northEast = { lat: bbox[1], lng: bbox[0] };
      const southWest = { lat: bbox[3], lng: bbox[2] };
      return turf.polygon([
        [
          [northWest.lng, northWest.lat],
          [northEast.lng, northEast.lat],
          [southEast.lng, southEast.lat],
          [southWest.lng, southWest.lat],
          [northWest.lng, northWest.lat]
        ]
      ]);
    }
    return null;
  }


  segregateLayersForGraphicsCall(map, legendsToDisplay, layerDetailsInMap) {
    let layersThatNeedGraphicCall = [];
    let vectorLayerDetailsInMap = []
    if (legendsToDisplay.length) {
      let idsToShowVectorLayers = legendsToDisplay.filter((layer) => [1, 3].includes(layer.layer_type)).filter(layer => layer.show_legend).map(({ _id }) => _id);
      vectorLayerDetailsInMap = layerDetailsInMap.filter(({ layer_type }) => [1, 3].includes(layer_type)).filter(layer => [1, 2, 3].includes(layer.style_type)).filter((layer) => idsToShowVectorLayers.includes(layer.id));
    } else {
      vectorLayerDetailsInMap = layerDetailsInMap.filter(({ layer_type }) => [1, 3].includes(layer_type))
    }
    for (let i = 0; i < vectorLayerDetailsInMap.length; i++) {
      if (this.layerInSBound(map, vectorLayerDetailsInMap[i])) {
        let obj: any = {};
        obj['parent_layer_id'] = vectorLayerDetailsInMap[i].parent_layer_id || '';
        obj['id'] = vectorLayerDetailsInMap[i]._id;
        obj['style_type'] = vectorLayerDetailsInMap[i].style_type;
        let index = layersThatNeedGraphicCall.findIndex((obj) => obj.parent_layer_id === vectorLayerDetailsInMap[i].parent_layer_id);
        if (index == -1) {
          layersThatNeedGraphicCall.push(obj);
        }

      }
    }
    return layersThatNeedGraphicCall
  }


}
