import { ArrayType } from "@angular/compiler";
import { isArray } from "util";

const operater_array = ["(", ")", "+", "-", "*", "/", ":", "!", "!:", 'not :', "<", ">", "<:", ">:", "<>", "and", "or", "not", "like", "in", "exists", "is null", "is not null", "not in", "between", "not between", "! between"];
const digit_reg_ex = /^\d+$/;
const float_reg_exp = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
const operater_priority = {
  "/": 1,
  "*": 1,
  ":": 2,
  "+": 2,
  "-": 2,
  "<": 3,
  ">": 3,
  "<:": 3,
  ">:": 3,
  "not :": 1,
  "!:": 4,
  "<>": 4,
  "not": 5,
  "!": 5,
  "or": 6,
  "and": 6,
  "like": 1,
  "in": 1,
  "between": 1,
  "not between": 1,
  "! between": 1,
  "not in": 1,
  "exists": 1,
  "is null": 1,
  "is not null": 1
};

const EQUIVALENT_MAPBOX_OPERATOR = {
  "+": "+",
  "-": "-",
  "*": "*",
  "/": "/",
  "$gt": ">",
  "$gte": ">=",
  "$lt": "<",
  "$lte": "<=",
  "$in": "in",
  "$or": "any",
  "$and": "all"
}

export const infixToPostfix = (input) => {
  var element, index, op_arr, res_arr, temp_array;
  res_arr = [];
  op_arr = [];
  index = 0;
  while (index < input.length) {
    element = input[index];
    // If an element is an operator
    if (operater_array.indexOf(element.toLowerCase()) >= 0) {
      element = element.toLowerCase();
      if (element !== ")") {
        
        // if priority if incoming operater is less than operater in array
        while (operater_priority[op_arr[op_arr.length - 1]] < operater_priority[element] && op_arr.length > 0) {
          res_arr.push(op_arr.pop());
        }
        op_arr.push(element);
        index++;
        continue;
      }
      // pop operater till matching opening bracket is found
      while (op_arr[op_arr.length - 1] !== "(" && op_arr.length > 0) {
        res_arr.push(op_arr[op_arr.length - 1]);
        op_arr.pop();
      }
      op_arr.pop();
      index++;
      continue;
    }
    temp_array = [input[index - 1]];
    while (element === "," && index < input.length) {
      temp_array.push(input[++index]);
      element = input[++index];
    }
    
    // An array was detected
    if (temp_array.length > 1) {
      res_arr.pop();
      res_arr.push(temp_array);
      continue;
    }
    res_arr.push(element);
    ++index;
  }
  while (op_arr.length > 0) {
    res_arr.push(op_arr.pop());
  }
  return res_arr;
}

const createObjectFromArray = (resArr, prefix) => {
  var element, final_res_array, i, index, len, query_result_object, ref, result;
  final_res_array = [];
  query_result_object = {};
  for (let i = 0; i<resArr.length; i++) {
    
    element = resArr[i];
    if ((typeof element === "string") && (!(ref = operater_array.indexOf(element.toLowerCase()) >= 0)) || (typeof element !== "string")) {
      final_res_array.push(element);
      continue;
    }
    result = evaluateObject(element, final_res_array, prefix);
    if (!result) {
      return void 0;
    }
  }
  return final_res_array[0];
}

export const getQueryObject = (queryString, prefix) => {
  let queryArray = queryString.match(/\(|\)|is null|is not null|not in|not :|between|! between|not between|"[^"]*"|'[^']*'|[-+]?[0-9]+\.[0-9]+([eE][-+]?[0-9]+)?|\^?\w+\$?|\w+|[-+]?\d+(.\d+)?|\d+|!:|<>|<:|>:|<|>|:|!|and|or|like|in|,|exists|\+|-|\/|\*/gi);
  let postfixExpArray = infixToPostfix(queryArray);
  if (!postfixExpArray === null) {
    return false;
  }
  return createObjectFromArray(postfixExpArray, prefix);
}

const trimStartAndEndDoubleQuotes = (str) => {
  if (str[0] === '"' && str[str.length - 1] === '"') {
    str = str.substr(1);
    str = str.substr(0, str.length - 1);
  }
  return str;
}

export const createArithmeticOperatorExp = (operater, operand_1, operand_2, prefix) => {
  var query_result_object;
  if (float_reg_exp.test(operand_1)) {
    operand_1 = parseFloat(operand_1);
  } else if (!(operand_2.includes("this"))) {
    operand_1 = "this." + prefix + operand_1;
  }
  if (float_reg_exp.test(operand_2)) {
    operand_2 = parseFloat(operand_2);
  } else if (!(operand_2.includes("this"))) {
    operand_2 = "this." + prefix + operand_2;
  }

  if (float_reg_exp.test(operand_1) && float_reg_exp.test(operand_2)) {
    switch (operater) {
      case "+":
        query_result_object = operand_1 + operand_2;
        break;
      case "-":
        query_result_object = operand_1 - operand_2;
        break;
      case "*":
        query_result_object = operand_1 * operand_2;
        break;
      case "/":
        query_result_object = operand_1 / operand_2;
      case "%":
        query_result_object = operand_1 % operand_2;
    }
  } else {
    query_result_object = operand_1 + operater + operand_2;
  }
  return query_result_object;
}

const createComparisonOperatorExp = (operater, mongo_operater, operand_1, operand_2, prefix) => {
  var query_result_object;
  query_result_object = {};
  if (float_reg_exp.test(operand_2)) {
    operand_2 = parseFloat(operand_2);
    query_result_object[prefix + operand_1] = {};
    query_result_object[prefix + operand_1][mongo_operater] = operand_2;
  } else {
    if (!(operand_1.includes("this"))) {
      operand_1 = "this." + prefix + operand_1;
    }
    if (!(operand_2.includes("this"))) {
      operand_2 = "this." + prefix + operand_2;
    }
    query_result_object['$where'] = operand_1 + operater + operand_2;
  }
  return query_result_object;
}

const createArrayOperatorExp = (mongo_operater, operand_1, operand_2, prefix) => {
  var array, query_result_object;
  query_result_object = {};
  array = operand_2;
  if (array instanceof Array) {
    array = array.map(function(element) {
      if (digit_reg_ex.test(element)) {
        return parseFloat(element);
      } else {
        return trimStartAndEndDoubleQuotes(element);
      }
    });
  } else {
    array = [array];
  }
  query_result_object[prefix + operand_1] = {};
  query_result_object[prefix + operand_1][mongo_operater] = array;
  return query_result_object;
}

/* return intermediate query object */
const constructIntermediateObject = (key, operand_1, operand_2) => {
  var temp_query_result_object;
  temp_query_result_object = {};
  if (operand_1[key] !== void 0) {
    if (operand_2[key] !== void 0) {
      temp_query_result_object[key] = operand_1[key].concat(operand_2[key]);
    } else {
      operand_1[key].push(operand_2);
      temp_query_result_object = operand_1;
    }
  } else if (operand_2[key] !== void 0) {
    operand_2[key].push(operand_1);
    temp_query_result_object = operand_2;
  } else {
    temp_query_result_object[key] = [];
    temp_query_result_object[key].push(operand_1);
    temp_query_result_object[key].push(operand_2);
  }
  return temp_query_result_object;
}

const evaluateObject = (operator, final_res_array, prefix) => {
  var end, max, min, operand_1, operand_2, query_result_object, start, temp_variable;
  operand_2 = final_res_array.pop(); // Right hand side
  if (operand_2 === void 0) {
    return false;
  }
  operand_1 = final_res_array.pop(); // Left hand side
  query_result_object = {};
  if (typeof operator === "string") {
    switch (operator.toLowerCase()) {
      case ":":
        if (operand_1 === void 0) {
          return false;
        }
        if (float_reg_exp.test(operand_2)) {
          operand_2 = parseFloat(operand_2);
        } else {
          operand_2 = trimStartAndEndDoubleQuotes(operand_2);
        }
        query_result_object[prefix + operand_1] = operand_2;
        break;
      case "like":
        if (operand_1 === void 0) {
          return false;
        }
        operand_2 = trimStartAndEndDoubleQuotes(operand_2);
        // replace _ with . for regular expression search
        operand_2 = operand_2.replace(/_/g, ".");
        start = ".*";
        end = ".*";
        if (operand_2[0] === "^") {
          operand_2 = operand_2.substr(1);
          start = "^";
        }
        if (operand_2[operand_2.length - 1] === "$") {
          operand_2 = operand_2.substr(0, operand_2.length - 1);
          end = "$";
        }
        query_result_object[prefix + operand_1] = {
          $regex: new RegExp(start + operand_2.toString().trim() + end, "i")
        };
        break;
      case "+":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createArithmeticOperatorExp("+", operand_1, operand_2, prefix);
        break;
      case "-":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createArithmeticOperatorExp("-", operand_1, operand_2, prefix);
        break;
      case "*":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createArithmeticOperatorExp("*", operand_1, operand_2, prefix);
        break;
      case "/":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createArithmeticOperatorExp("/", operand_1, operand_2, prefix);
        break;
      case "<>":
      case "!:":
        if (operand_1 === void 0) {
          return false;
        }
        if (float_reg_exp.test(operand_2)) {
          operand_2 = parseFloat(operand_2);
        }
        query_result_object[prefix + operand_1] = {
          $ne: operand_2
        };
        break;
      case ">":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createComparisonOperatorExp(">", "$gt", operand_1, operand_2, prefix);
        break;
      case ">:":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createComparisonOperatorExp(">=", "$gte", operand_1, operand_2, prefix);
        break;
      case "<":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createComparisonOperatorExp("<", "$lt", operand_1, operand_2, prefix);
        break;
      case "<:":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createComparisonOperatorExp("<=", "$lte", operand_1, operand_2, prefix);
        break;
      case "in":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createArrayOperatorExp("$in", operand_1, operand_2, prefix);
        break;
      case "nin":
      case "not in":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = createArrayOperatorExp("$nin", operand_1, operand_2, prefix);
        break;
      case "between":
        if (operand_1 === void 0) {
          return false;
        }
        if (operand_2 instanceof Array) {
          if ((float_reg_exp.test(operand_2[0])) && (float_reg_exp.test(operand_2[1]))) {
            operand_2 = operand_2.map(function(element) {
              return parseFloat(element);
            });
          } else {
            return false;
          }
        } else {
          false;
        }
        if (operand_2[0] < operand_2[1]) {
          min = operand_2[0];
          max = operand_2[1];
        } else {
          min = operand_2[1];
          max = operand_2[0];
        }
        query_result_object[prefix + operand_1] = {
          $gte: min,
          $lte: max
        };
        break;
      case "not between":
      case "! between":
        if (operand_1 === void 0) {
          return false;
        }
        if (operand_2 instanceof Array) {
          if ((float_reg_exp.test(operand_2[0])) && (float_reg_exp.test(operand_2[1]))) {
            operand_2 = operand_2.map(function(element) {
              return parseFloat(element);
            });
          } else {
            return false;
          }
        } else {
          false;
        }
        if (operand_2[0] < operand_2[1]) {
          min = operand_2[0];
          max = operand_2[1];
        } else {
          min = operand_2[1];
          max = operand_2[0];
        }
        query_result_object[prefix + operand_1] = {
          $gt: max,
          $lt: min
        };
        break;
      /* case "not":
      case "!":
        temp_variable = operand_2[Object.keys(operand_2)];
        if (typeof temp_variable === "string") {
          temp_variable = new RegExp(temp_variable, "i");
        }
        query_result_object[Object.keys(operand_2)] = {
          $not: temp_variable
        };
        break; */
      case "nor":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = constructIntermediateObject("$nor", operand_1, operand_2);
        break;
      case "or":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = constructIntermediateObject("$or", operand_1, operand_2);
        break;
      case "and":
        if (operand_1 === void 0) {
          return false;
        }
        query_result_object = constructIntermediateObject("$and", operand_1, operand_2);
        break;
      case "exists":
        if (operand_1 === void 0) {
          return false;
        }
        if (operand_2 === 'false') {
          query_result_object[prefix + operand_1] = {
            $exists: false
          };
        } else {
          query_result_object[prefix + operand_1] = {
            $exists: true
          };
        }
        break;
      case "is null":
        query_result_object[prefix + operand_2] = null;
        if (operand_1 !== void 0) {
          return final_res_array.push(operand_1);
        }
        break;
      case "is null":
        query_result_object[prefix + operand_2] = {
          $exists: false
        };
        if (operand_1 !== void 0) {
          return final_res_array.push(operand_1);
        }
        break;
      case "is not null":
        query_result_object[prefix + operand_2] = {
          $exists: true
        };
        if (operand_1 !== void 0) {
          return final_res_array.push(operand_1);
        }
    }
  }
  return final_res_array.push(query_result_object);
}

const notArrayOfValues = (arr: any) => {
  if((typeof arr[0] != "object")) return false;
  return true;
}

const arrayType = () => {
  let a: Array<any> = [];
  return a;
}

export const mapBoxSpecificStyle = (obj, stack=arrayType()) => {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if(Array.isArray(obj[property]) && notArrayOfValues(obj[property])) {
        let rValue: Array<any> = [EQUIVALENT_MAPBOX_OPERATOR[property]]
        obj[property].forEach(element => {
          rValue.push(mapBoxSpecificStyle(element, []));
        });
        return rValue;
      } else if ((typeof obj[property] == "object") && !Array.isArray(obj[property])) {
        return mapBoxSpecificStyle(obj[property], [["get",property]]);
      } else {
        console.log([property, ...stack, obj[property]]);
        if(property === '$exists') {
          if(obj[property]) {
            return ["!=", ["get", property], null];
          } else {
            return ["==", ["get", property], null];
          }
        } else if(!stack.length) {
          return ["==", ["get", property], Array.isArray(obj[property]) ? ["literal", obj[property]] : obj[property]];
        } else
        return [EQUIVALENT_MAPBOX_OPERATOR[property], ...stack, Array.isArray(obj[property]) ? ["literal", obj[property]] : obj[property]];
      }
    }
  }   
  // Object.keys(obj).forEach(key => {
  //   if(isArray(obj[key])) {
  //     rValue = [key];
  //     obj[key].forEach((subObj: any) => {
  //       rValue.push(mapBoxSpecificStyle(subObj))
  //     });
  //     // return rValue;
  //   } else {
  //     if(Object.keys(obj[key]).length) {
  //       const innerKey = Object.keys(obj[key])[0];
  //       return [innerKey, ['get', key], obj[key][innerKey]]
  //     } else {
  //       return ['=', ['get', key], obj[key]]
  //     }
  //   }
  // })
}