import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { cloneDeep } from "lodash";
import { LegendCtrl, TEXT_LABEL_ATTRIBUTE } from "src/app/_services/mapLegendsCtrl.service";
import { SessionService } from "src/app/_services/session.service";

@Component({
  selector: "app-advanced-label-dialog",
  templateUrl: "./advanced-label-dialog.component.html",
  styleUrls: ["./advanced-label-dialog.component.scss"]
})

export class AdvancedLabelDialogComponent {
  selectedValue: string = null;
  selectedStatus: boolean = false;
  selectedExpression: string = null;
  selectedLayer: any = null;
  selectedTextStyleValue: string = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AdvancedLabelDialogComponent>,
    private sessionService: SessionService,
    private legendService: LegendCtrl
  ) { }

  ngOnInit() {
    this.selectedLayer = this.data.selectedLayer;
    this.selectedTextStyleValue = this.data.selectedTextStyleValue;
  }

  selectedMapStyling(event) {
    this.selectedValue = event.value;
    this.selectedStatus = event.status;
    this.selectedExpression = event.map_expression;
    if(event.value === 'expression') {
      this.dialogRef.close(false);
    }
  }

  applyLabels() {
    if(!this.selectedValue || this.selectedValue === 'expression') {
      this.dialogRef.close();
      return;
    }
    let fieldLabel: any = cloneDeep(FIELD_LABEL);
    fieldLabel.classes[0] = {
      class_id: 1,
      expression: `[${this.selectedValue}]`,
      is_default: true,
      map_expression: this.selectedExpression,
      map_query: [],
      name: "default",
      query: ""
    }
    this.sessionService.selectedLayers[0][TEXT_LABEL_ATTRIBUTE[this.selectedLayer.style_type]] = fieldLabel;
    this.sessionService.sendLayerDetailsToServer();
    this.legendService.applyLabel(this.selectedLayer, this.selectedExpression);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close(false);
  }
}

export const FIELD_LABEL = {
  selected_class_id: 1,
  classes: [
    {
      class_id: 1,
      expression: "",
      is_default: true,
      map_expression: [],
      map_query: [],
      name: "default",
      query: ""
    }
  ]
}