import { Component, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { Store } from "@ngrx/store";
import {
  CreateEmptyVectorLayerStartAction,
  EditVectorlayerAttributes,
  GetLayerById
} from "src/app/store/actions/data-management.actions";
import { Actions, ofType } from "@ngrx/effects";
import { layerInfoSelector } from '../../../store/selectors/data-management.selector';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: "app-create-vector-layer",
  templateUrl: "./create-vector-layer.component.html",
  styleUrls: ["./create-vector-layer.component.scss"]
})
export class CreateVectorLayerComponent {
  // selectedLayerType = "point";
  layerTypeData = [
    {
      type: this.data.edit ? ["point"] : "point",
      display_text: "Point",
      inactive_icon: "assets/images/Darkpoint.svg",
      active_icon: "assets/images/Oval_Copy_17.png"
    },
    {
      type: this.data.edit ? ["polyline", "linestring", "multilinestring"] : "polyline",
      display_text: "Polyline",
      inactive_icon: "assets/images/Darkpolyline.svg",
      active_icon: "assets/images/Path_Copy_4_1.png"
    },
    {
      type: this.data.edit ? ["polygon", "multipolygon"] : "polygon",
      display_text: "Polygon",
      inactive_icon: "assets/images/Dankpolygon.svg",
      active_icon: "assets/images/Polygon-Active.svg"
    }
  ];
  layerObj: VectorLayer = {
    name: "",
    type: "",
    attribute_type: [
      {
        key: "id",
        label: "ID",
        type: "Number",
        show: true
      }
    ]
  };
  layerAttributes: Array<AttributeType> = [];
  layerName: string = "";
  layerAttributeTypes: Array<string> = ["String", "Number", "Date", "Document"];
  hasErrors = false;
  saveClicked = false;
  errorMessage: string = "";
  confirmDialog: MatDialogRef<ConfirmDialogComponent, any>;
  title: string = 'Create a New Layer';
  is_new_layer: boolean = true
  hidePropertyData: any = []

  @ViewChild('layer_attributes') layerAttrs: ElementRef<any>;
  constructor(
    private _store: Store<any>,
    private actions$: Actions,
    public dialogRef: MatDialogRef<CreateVectorLayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private toaster: ToastrService
  ) { }
  ngOnInit() {
    if (this.data.edit) {
      this.title = 'Add/Edit Attributes'
      this._store.dispatch(new GetLayerById(this.data.id))
      this._store.select(layerInfoSelector).subscribe(res => {
        if (res.data) {
          let response = res.data[0]
          let keys = Object.keys(response.attributes)
          this.is_new_layer = res.data[0].is_new
          this.layerObj['name'] = response.name
          this.layerObj['type'] = response.type
          this.layerObj['attribute_type'] = keys.map(key => {
            response.attributes[key].key = key
            response.attributes[key].type_editable = false
            response.attributes[key].removable = false
            response.attributes[key].oldId = key

            return response.attributes[key]
          })
        }
      })
    }
  }
  ngOnDestroy() { }
  setLayerType(layerType) {
    if (!this.data.edit)
      this.layerObj.type = layerType.type;
  }
  addNewAttribute() {
    const newAttribute: AttributeType = {
      key: "",
      label: "",
      type: "",
      show: true
    };

    if (this.data.edit) {
      newAttribute.type_editable = true,
        newAttribute.show = true,
        newAttribute.oldId = null,
        newAttribute.removable = true
    }

    this.layerObj.attribute_type.push(newAttribute);
    setTimeout(() => {
      this.layerAttrs.nativeElement.scrollTop = this.layerAttrs.nativeElement.scrollHeight;
    }, 100);
  }
  deleteAttribute(index) {
    this.confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      role: "dialog",
      height: "200px",
      width: "400px",
      data: {
        alertTitle: "Confirm",
        alertText: `Do you really want to delete this attribute?`
      }
    });
    this.confirmDialog.afterClosed().subscribe(response => {
      if (response) {
        this.layerObj.attribute_type.splice(index, 1);
      }
    });
  }
  close() {
    this.dialogRef.close(false);
  }
  save() {
    this.saveClicked = true;
    this.hasErrors = this.formValidationResp();
    if (this.hasErrors) return;
    let payload = { name: '', type: '', attributes: {} }
    let layerObj = {}

    // Changes as per updated payload in TOS-2521
    this.layerObj.attribute_type.map(attribute => {

      if (this.data.edit) {
        layerObj[attribute.key] = {
          "label": attribute.label,
          "type": attribute.type,
          "show": attribute['show'],
          "oldId": attribute.key == attribute['oldId'] ? null : attribute['oldId']
        }

        if (layerObj[attribute.key].oldId == null) {
          delete layerObj[attribute.key].oldId
        }

        return layerObj[attribute.key]
      }
      else {
        layerObj[attribute.key] = {
          "label": attribute.label,
          "type": attribute.type,
          "show": attribute['show']
        }
      }
    })

    payload = { name: this.layerObj.name, type: this.layerObj.type, attributes: layerObj }

    if (this.hidePropertyData.length > 0) {
      this.toaster.warning('While hiding this attribute will not affect any of the previous session data')
    }
    if (this.data.edit)
      this._store.dispatch(new EditVectorlayerAttributes(this.data.id, payload));
    else
      this._store.dispatch(new CreateEmptyVectorLayerStartAction(payload));
    this.dialogRef.close(true)

  }

  validateForm() {
    if (!this.saveClicked) return;
    this.hasErrors = this.formValidationResp();
  }

  formValidationResp() {
    if (!this.data.edit) {
      if (this.hasLayerNameError()) return true;
      if (this.hasLayerTypeError()) return true;
    }
    if (this.hasLayerAttributesError()) return true;


    this.errorMessage = "";
    return false;
  }

  hasLayerNameError() {
    if (!(this.layerObj.name && this.layerObj.name.length)) {
      this.errorMessage = "Layer should have a valid name";
      return true;
    }
    // var re = /^\w+$/;
    // if (!re.test(this.layerObj.name)) {
    //   this.errorMessage =
    //     "Layer name should contain only alphabets, numbers and underscore";
    //   return true;
    // }

    if (this.layerObj.name) {
      const isWhitespace = (this.layerObj.name || '').trim().length === 0;
      const isValid = !isWhitespace;
      if (!isValid) {
        this.errorMessage = `Please enter a valid name`
        return true
      } else {
        return false
      }
    }
    return false;
  }

  hasLayerTypeError() {
    if (!(this.layerObj.type && this.layerObj.type.trim().length)) {
      this.errorMessage = "Select a layer type";
      return true;
    }
    return false;
  }

  hasLayerAttributesError() {
    if (
      !(this.layerObj.attribute_type && this.layerObj.attribute_type.length > 1)
    ) {
      this.errorMessage = "Please add layer attributes";
      return true;
    } else {
      let val = false;
      this.layerObj.attribute_type.forEach(attrObj => {
        const attrRow = `{ "Attribute": "${attrObj.key}", "Label": "${attrObj.label}", "Data Type": "${attrObj.type}" }`;
        if (!attrObj.key.length) {
          this.errorMessage = `"Attribute" cannot be empty at ${attrRow}`;
          val = true;
          return;
        }
        // let re = /[&\/\\#, +()$~%.'":*?<>{}]/;
        // if (re.test(attrObj.key)) {
        //   this.errorMessage = `At ${attrRow} "Attribute" should contain only alphabets, numbers and underscore`;
        //   val = true;
        //   return;
        // }

        if (attrObj.key) {
          const isWhitespace = (attrObj.key || '').trim().length === 0;
          const isValid = !isWhitespace;
          if (!isValid) {
            val =true
            this.errorMessage = `At ${attrRow} "Attribute" does not have a valid name`
          }
          return
        }

        if (!attrObj.type) {
          this.errorMessage = `${attrRow} does not have a valid "Data Type" field`;
          val = true;
          return;
        }
      });
      if (val) return val;
      const attrKeyOccurances = this.layerObj.attribute_type.reduce(
        (acc, curr) => {
          if (curr.key.trim() in acc) {
            acc[curr.key.trim()]++;
          } else {
            acc[curr.key.trim()] = 1;
          }
          return acc;
        },
        {}
      );
      Object.keys(attrKeyOccurances).forEach(key => {
        if (attrKeyOccurances[key] > 1) {
          this.errorMessage = `Attribute is a unique field. "${key}" is added in ${attrKeyOccurances[key]} rows`;
          val = true;
          return;
        }
      });
      if (val) return val;
    }
    return false;
  }

  showHideAttribute(index, flag) {
    if (!flag) {
      this.hidePropertyData.push(index)
    }
    else {
      this.hidePropertyData.splice(this.hidePropertyData.findIndex(ind => ind == index), 1)
    }
    this.layerObj.attribute_type[index]['show'] = flag
  }
}

export interface VectorLayer {
  name: string;
  type: string;
  attribute_type: Array<AttributeType>;
}

export interface AttributeType {
  key: string;
  label: string;
  type: string;
  show?: boolean;
  oldId?: string;
  removable?: boolean;
  type_editable?: boolean
}
