import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from '../reducers/property-tax.reducer';

import { PropertyTax } from '../../models/property-tax'
import { propertyTaxSelector } from './city-os-property-tax.selectors';
import { state } from '@angular/animations';

export const propertyTaxFeatureSelector = createFeatureSelector<State>("propertyTax");

export const getPopertyTaxSelector = createSelector(
    propertyTaxFeatureSelector,
    // state => (state.data || new PropertyTax())
    state => (state.data || null)
)

export const postPopertyTaxSelector = createSelector(
    propertyTaxFeatureSelector,
    // state => (state.data || new PropertyTax())
    state => (state.data || null)
)

export const putPopertyTaxSelector = createSelector(
    propertyTaxFeatureSelector,
    // state => (state.data || new PropertyTax())
    state => (state.data || null)
)

export const listUsersSelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.users || [])
)

export const listWardsSelector = createSelector(
    propertyTaxFeatureSelector,
    state => {
        return (state.wards || [])
    }
)
export const listWardsCountSelector = createSelector(
    propertyTaxFeatureSelector,
    state => {
        return (state.totalWardCount || 0)
    }
)

export const listUsersCountSelector = createSelector(
    propertyTaxFeatureSelector,
    state => {
        return (state.total || 0)
    }
)

export const getWardSelector = createSelector(
    propertyTaxFeatureSelector,
    state => {
        return (state.ward || null)
    }
)

export const checkAccessPopertyTaxSelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.access || false)
)

// export const userWardAssign = createSelector(
//     propertyTaxFeatureSelector,
//     state => (state.wards || [])
// )

export const getFloorSelector = createSelector(
  propertyTaxFeatureSelector,
  state => (state.floorData || [])
)

export const editGeometrySelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.editedGeometryResp || {})
)
export const  adminPanelSelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.adminData || {})
)
export const adminApprovedSelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.adminApprovedData || {})
)

export const filteredDataSelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.filteredData || {})
)

export const cityosLayerAttributeSelector = createSelector(
    propertyTaxFeatureSelector,
    state => (state.cityosLayerAttributes || {})
)
export const cityosAdminTablePremiseSelector =createSelector(
    propertyTaxFeatureSelector,
    state => (state.layerPremiseTableData)
)
export const propertyTaxUserDataelector = createSelector(
    propertyTaxFeatureSelector,
    state  => (state.userData)
)
export const propertyTaxFormSelector = createSelector(
    propertyTaxFeatureSelector,
    state => state.propertyTaxForms
)
export const propertyTaxEntryLogsSelector = createSelector(
    propertyTaxFeatureSelector,
    state => state.entryLogs
)