import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
  LogErrorAction, LogFilureAction,

} from "../actions/user-management.actions";
import { mergeMap, map, catchError, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { Storeservice } from "src/app/_services/store.services";
import { Router, ActivatedRoute } from "@angular/router";
import { userProfileactionTypes, GetUsersProfileSuccessAction, GetUsersProfileStartAction, GetUserUpdateStartAction, GetUserUpdateSuccessAction, GetChangePasswordAction, GetChangePasswordSuccessAction, GetUserDetailsStartAction, GetUserDetailsSuccessAction, GetOrgDetailsSuccessAction, GetOrgDetailsStartAction, SetOrgDetailsStartAction, SetOrgDetailsSuccessAction, GetProfileSubscriptionDetails, GetProfileSubscriptionDetailsSuccess, GetRegenerateTokenStartAction, GetRegenerateTokenSuccessAction } from '../actions/profile.action';
import { ToastrService } from 'ngx-toastr';
import { API } from "src/app/_services";

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private stores: Storeservice,
    private api: API,
    private _router: Router,
    private toaster: ToastrService,
  ) { }


  @Effect()
  $profileData = this.actions$.pipe(
    ofType(userProfileactionTypes.getUsersProfileStart),
    switchMap((action: GetUsersProfileStartAction) => {
      return this.stores.getProfileDetails().pipe(
        map((result: any) => {
          if (result.success) {
            return new GetUsersProfileSuccessAction(result.data);
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )


  @Effect()
  $updateUser = this.actions$.pipe(
    ofType(userProfileactionTypes.getUserUpdateStart),
    switchMap((action: GetUserUpdateStartAction) => {
      return this.stores.profileUpdateUser(action.payload).pipe(
        map((result: any) => {
          if (result.success) {
            this.toaster.success("successfully updated");
            return new GetUserUpdateSuccessAction(result);
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  $changeUserPassword = this.actions$.pipe(
    ofType(userProfileactionTypes.getChangePassword),
    switchMap((action: GetChangePasswordAction) => {
      return this.stores.changeUserPassword(action.payload).pipe(
        map((result: any) => {
          if (result.success) {
            this.toaster.success("successfully updated");
            return new GetChangePasswordSuccessAction(result.data[0]);
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  $getUserDetails = this.actions$.pipe(
    ofType(userProfileactionTypes.getUserDataStart),
    switchMap((action: GetUserDetailsStartAction) => {
      return this.stores.getUserRoleDetails().pipe(
        map((response: any) => {
          if (response.length) {
            return new GetUserDetailsSuccessAction(response[0]);
          }
          return of(new LogFilureAction('could not fetch user data.'));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
  @Effect()
  getUserOrgdetails = this.actions$.pipe(
    ofType(userProfileactionTypes.getOrgDetailsStart),
    switchMap((action: GetOrgDetailsStartAction) => {
      return this.stores.getUserOrgDetails(action.id).pipe(
        map((resp: any) => {
          if (resp.success) return new GetOrgDetailsSuccessAction(resp.data);
          return of(new LogFilureAction('could not fetch organization data.'));
        }),
        catchError((err: Error) => of(new LogErrorAction(err)))
      )
    })
  )
  @Effect()
  setOrgDetails$ = this.actions$.pipe(
    ofType(userProfileactionTypes.setOrgdetailsStartAction),
    switchMap((action: SetOrgDetailsStartAction) => {
      return this.stores.postOrganizationDetails(action.payload, action.id).pipe(
        map((resp: any) => {
          if(resp.success) {
            this.toaster.success("Organization details saved successfully.")
            return new SetOrgDetailsSuccessAction(resp.data);
          }
          return of(new LogFilureAction("Error in saving the organization details!"));
        }),
        catchError((err: Error) => of(new LogErrorAction(err)))
      )
    })
  )

  @Effect()
  getprofilesubscriptionInfo$ = this.actions$.pipe(
    ofType(userProfileactionTypes.getProfileSubscriptionDetails),
    switchMap((action: GetProfileSubscriptionDetails) => {
      return this.stores.getProfileSubscriptionDetails().pipe(
        map((resp: any) => {
          return new GetProfileSubscriptionDetailsSuccess(resp);
        }),
        catchError((err: Error) => of(new LogErrorAction(err)))
      )
    })
  )

  @Effect()
  getRegeneratedToken$ = this.actions$.pipe(
    ofType(userProfileactionTypes.getRegeneratedToken),
    switchMap((action: GetRegenerateTokenStartAction) => {
      return this.api.regenerateToken(action.id).pipe(
        map((resp: any) => {
          return new GetRegenerateTokenSuccessAction(resp);
        }),
        catchError((err: Error) => of(new LogErrorAction(err)))
      )
    })
  )

}




