import { Action } from '@ngrx/store'

export enum userProfileactionTypes {
  getUsersProfileStart = "[PROFILE] Get Users Profile Start",
  getUsersProfileSuccess = "[PROFILE] Get Users Profile Success",
  getUserUpdateStart = "[PROFILE] Get User Update  Start",
  getUserUpdateSuccess = "[PROFILE] Get User Update Success",
  getChangePassword = "[PROFILE] Get User Change  Start",
  getChangePasswordSuccess = "[PROFILE] Get User Change Success",
  getUserDataStart = "[USER DETAILS] Get user details start",
  getUserDataSuccess = "[USER DETAILS] Get user details success",
  getOrgDetailsStart = "[User Details] Get user org details start",
  getOrgDetailsSuccess = "[user details] Get user org details success",
  setOrgdetailsStartAction = "[User- Details] post org details start action",
  setOrgdetailsSuccessAction = "[User- Details] post org details success action",
  getProfileSubscriptionDetails = "[Subscription] get subscription details",
  getProfileSubscriptionDetailsSuccess = "[Subscription] get subscription details success",
  getRegeneratedToken = "[ACCESS TOKEN] regenerate access token",
  getRegeneratedTokenSuccess = "[ACCESS TOKEN] regenerate access token success",

}




export class GetUsersProfileStartAction implements Action {
  readonly type = userProfileactionTypes.getUsersProfileStart;
  constructor() { }
}




export class GetUsersProfileSuccessAction implements Action {
  readonly type = userProfileactionTypes.getUsersProfileSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class GetUserUpdateStartAction implements Action {
  readonly type = userProfileactionTypes.getUserUpdateStart;
  payload;
  constructor(data) {
    this.payload = data;

  }
}

export class GetUserUpdateSuccessAction implements Action {
  readonly type = userProfileactionTypes.getUserUpdateSuccess;

  constructor(public response: any) {
  }
}
export class GetChangePasswordAction implements Action {
  readonly type = userProfileactionTypes.getChangePassword;
  payload;
  constructor(data) {
    this.payload = data;

  }
}

export class GetChangePasswordSuccessAction implements Action {
  readonly type = userProfileactionTypes.getChangePasswordSuccess;

  constructor(public response) {
  }
}
export class GetUserDetailsStartAction implements Action {
  readonly type = userProfileactionTypes.getUserDataStart;
  constructor() { }
}

export class GetUserDetailsSuccessAction implements Action {
  readonly type = userProfileactionTypes.getUserDataSuccess;
  constructor(public response) { }
}
export class GetOrgDetailsStartAction implements Action {
  readonly type = userProfileactionTypes.getOrgDetailsStart;
  constructor(public id) { }
}
export class GetOrgDetailsSuccessAction implements Action {
  readonly type = userProfileactionTypes.getOrgDetailsSuccess;
  constructor(public response) { }
}
export class SetOrgDetailsStartAction implements Action {
  readonly type = userProfileactionTypes.setOrgdetailsStartAction;
  constructor(public payload, public id) { }
}

export class SetOrgDetailsSuccessAction implements Action {
  readonly type = userProfileactionTypes.setOrgdetailsSuccessAction;
  constructor(public response) { }
}

export class GetProfileSubscriptionDetails implements Action {
  readonly type = userProfileactionTypes.getProfileSubscriptionDetails;
  constructor() { }
}
export class GetProfileSubscriptionDetailsSuccess implements Action {
  readonly type = userProfileactionTypes.getProfileSubscriptionDetailsSuccess;
  constructor(public response) { }
}

export class GetRegenerateTokenStartAction implements Action {
  readonly type = userProfileactionTypes.getRegeneratedToken;
  constructor(public id) { }
}

export class GetRegenerateTokenSuccessAction implements Action {
  readonly type = userProfileactionTypes.getRegeneratedTokenSuccess;
  constructor(public response) { }
}

export type UserProfileActions = GetUsersProfileSuccessAction |
  GetUserUpdateSuccessAction | GetChangePasswordSuccessAction | GetUserDetailsSuccessAction | GetOrgDetailsSuccessAction | SetOrgDetailsSuccessAction | GetProfileSubscriptionDetailsSuccess | GetRegenerateTokenSuccessAction;