import { Component, OnInit ,Inject} from '@angular/core';

import { FormControl } from '@angular/forms'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from "@angular/material";
@Component({
  selector: 'app-data-approval-error',
  templateUrl: './data-approval-error.component.html',
  styleUrls: ['./data-approval-error.component.scss']
})
export class DataApprovalErrorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DataApprovalErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    
  }
  close(flag) {
    this.dialogRef.close();
  }
}
