import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/user-management.reducer';

export const usersFeatureSelector = createFeatureSelector<State>("users");

export const usersSelector = createSelector(
  usersFeatureSelector,
  (state) => (state || [])
)

export const usersCountSelector = createSelector(
  usersFeatureSelector,
  state => (state.count || 0)
)

export const selectedUserSelector = createSelector(
  usersFeatureSelector,
  (state) => (state.selectedUser || {})
)

export const rolesSelector = createSelector(
  usersFeatureSelector,
  (state) => (state.roles || [])
)
export const addUpdateUserSelector = createSelector(
  usersFeatureSelector,
  (state) => (state.addUpdateUser)

)

export const surveyorListSelector = createSelector(
  usersFeatureSelector,
  (state) =>  (state.surveyorsList)
)