import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  SessionManagementTypes,
  CreateSessionStartAction,
  CreateSessionSuccessAction,
  AddLayersStartAction,
  AddLayersSuccessAction,
  UpdateSessionStartAction,
  UpdateSessionSuccessAction,
  CopyLayerStartAction,
  CopyLayerSuccessAction,
  GetSessionDetailsStartAction,
  GetSessionDetailsSuccessAction,
  GetSessionsStartAction,
  GetSessionsSuccessAction,
  DeleteSessionStartAction,
  DeleteSessionSuccessAction,
  AssignUsersStartAction,
  AssignUsersSuccessAction,
  GetAssignedUsersStartAction,
  GetAssignedUsersSuccessAction,
  GetUsersListStartAction,
  GetUsersListSuccessAction,
  CopySessionStartAction,
  PrivatizeSessionStartAction,
  PrivatizeSessionSuccessAction,
  CopySessionSuccessAction,
  GetLayerTableDataStartAction,
  GetLayerTableDataSuccessAction,
  ApproveLayerTableDataStartAction,
  ApproveLayerTableDataSuccessAction,
  GetLayerAttributesStartAction,
  GetLayerAttributesSuccessAction,
  GetUniquePropertyValuesStartAction,
  GetUniquePropertyValuesSuccessAction,
  GetStyleTypesStartAction,
  GetStyleTypesSuccessAction,
  GetSavedQueriesStartAction,
  GetSavedQueriesSuccessAction,
  GetQueryTableDataStartAction,
  GetQueryTableDataSuccessAction,
  GetGraphicsLayerDataStartAction,
  GetGraphicsLayerDataSuccessAction,
  GetCollectedDataRowDetailsStartAction,
  GetCollectedDataRowDetailsSuccessAction,
  SaveCollectedDataRowDetailsStartAction,
  SaveCollectedDataRowDetailsSuccessAction,
  SendDataForApprovalStartAction,
  SendDataForApprovalSuccessAction,
  AddDataForApprovalStartAction,
  AddDataForApprovalSuccessAction,
  GetPendingFeaturesStartAction,
  GetPendingFeaturesSuccessAction,
  ApproveRequestedFeatureEditSuccessAction,
  ApproveRequestedFeatureEditStartAction,
  GetSurveyorsListStartAction,
  GetSurveyorsListSuccessAction,
  UploadStyleForSessionPublishStartAction,
  UploadStyleForSessionPublishSuccessAction,
  AssignSurveyorsStartAction,
  AssignSurveyorsSuccessAction,
  GetAssignedSurveyorsStartAction,
  GetAssignedSurveyorsSuccessAction,
  GetAllUnapprovedFeatureEditsStartAction,
  GetAllUnapprovedFeatureEditsSuccessAction,
  GetLayerAttributeInfoStartAction,
  GetLayerAttributeInfoSuccessAction,
  CreateTemporaryLayerStartAction,
  CreateTemporaryLayerSuccessAction,
  CreatePermanentLayerStartAction,
  CreatePermanentLayerSuccessAction,
  ExportSearchResultStartAction,
  ExportSearchResultSuccessAction,
  ExportDataStartAction,
  ExportDataSuccessAction,
  GetAdvancedToolsSearchResultStartAction,
  GetAdvancedToolsSearchResultSuccessAction,
  ExportQuerySearchDataStartAction,
  ExportQuerySearchDataSuccessAction,
  ExportGraphicStartAction,
  ExportGraphicSuccessAction,
  UpdateQueryStartAction,
  DeleteQueryStartAction,
  UploadFileStartAction,
  UploadFileSuccessAction,
  ProcessingFileStartAction,
  ProcessingFileSuccessAction,
  UpdateIconStartAction,
  UpdateIconSuccessAction,
  GetIconsListStartAction,
  GetIconsSuccessAction,
  GetOSMTranserveSourcesStartAction,
  GetOSMTranserveSourcesSuccessAction,
  GetProcessedListStartAction,
  GetProcessingListSuccessAction,
  GetSearchedMapResults,
  GetSearchedMapResultsSuccess,
  GetPlaceDetails,
  GetPlaceDetailsSuccess,
  GetRasterToolProcessingStartAction,
  GetRasterToolProcessingSuccessAction,
  DeleteProcessingStartAction,
  DeleteProcessingSuccessAction,
  ExportAsGeoTiffStartAction,
  ExportAsGeoTiffSuccessAction,
  LibraryUploadAuthenticationStartAction,
  LibraryUploadAuthenticationSuccessAction, DeleteNotificationStartAction, DeleteNotificationSuccessAction, ClearAllNotificationStartAction, ClearAllNotificationSuccessAction, GetClassificationDataStartAction, GetClassificationDataSuccessAction, GetDataCollectedLayerDataStartAction, GetDataCollectedLayerDataSuccessAction, DCLayerDataApprovalStartAction, DCLayerDataApprovalSuccessAction, GetAllLayersInfoStartAction, GetAllLayersInfoSuccessAction, GetLayerNameStartAction, GetLayerNameSuccessAction, CreateNetworkLayersStartAction, CreateNetworkLayersSuccessAction, GetSimilaritySearchStartAction, GetSimilaritySearchSuccessAction, GetSelectByLocationStartAction, GetDataInsightdLayerDataStartAction, GetDataInsightdLayerDataSuccessAction, GetDataInsightStartAction, GetDataInsightSuccessAction, GetSearchedHereMapResults, GetSearchedHereMapResultsSuccess, ClearFileStorageAction, ClearFileStorageActionSingle,ClearFileStorageSuccess,ClearFileStorageSuccessSingle,
  copyLayerFromSession,
  copyLayerFromSessionSuccess,
  moveLayerFromSession,
  moveLayerFromSessionSuccess
} from '../actions/session-management.actions';
import { switchMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import { API, LayerService } from 'src/app/_services';
import { of, Observable, forkJoin } from 'rxjs';
import { LogErrorAction, LogFilureAction } from '../actions/user-management.actions';
import { Injectable } from '@angular/core';
import { Storeservice } from 'src/app/_services/store.services';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { HideLoaderAction } from '../actions/loader.actions';
import { Store, Action } from '@ngrx/store';
import { SessionPublishService } from 'src/app/_services/sessionPublish.service';

@Injectable()
export class SessionManagementEffects {
  constructor(
    private actions$: Actions,
    private _api: API,
    private apiInitializer: Storeservice,
    private _router: Router,
    private sessions: SessionService,
    private toastr: ToastrService,
    private layerService: LayerService,
    private _store: Store<any>,
    private publishService: SessionPublishService
  ) { }

  @Effect()
  newSessoin$ = this.actions$.pipe(
    ofType(SessionManagementTypes.createSessionStart),
    switchMap((action: CreateSessionStartAction) => {
      return this.apiInitializer.createSession().pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success("New Session Created Successfully");
            return new CreateSessionSuccessAction(response.data);
            // this._router.navigate(["./home", response.data._id]);
            // return new GetSessionsStartAction(SESSION_PAYLOAD);
          }
          return of(new LogFilureAction("Error Creating New Session"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  addedLayers$ = this.actions$.pipe(
    ofType(SessionManagementTypes.addLayersStart),
    switchMap((action: AddLayersStartAction) => {
      return this.apiInitializer.addLayers(action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success("New Layer Added Successfully");
            this.sessions.fitBounds = true;
            return new AddLayersSuccessAction(response.data);
          }
          return of(new LogFilureAction("Layer could not be loaded successfully."))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  // @Effect()
  // getLyers$ = this.actions$.pipe(
  //   ofType(SessionManagementTypes.addLayersSuccess),
  //   map((action: AddLayersSuccessAction) => {
  //     return new GetSessionDetailsSuccessAction(action.data);
  //   })
  // )


  @Effect()
  clearFileStorage$ = this.actions$.pipe(
    ofType(SessionManagementTypes.clearFileStorageAction),
    switchMap((action: ClearFileStorageAction) => {
      
      return this.apiInitializer.clearFileStorage("").pipe(
        map((response: any) => {
          
          if (response.success ==true) {

            this.toastr.success("Deleted all notifications successfully");
            this.sessions.fitBounds = true;
            return new ClearFileStorageSuccess(response.data);
          }else{

            return of(new LogFilureAction("Notifications  could not be deleted."))

          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
@Effect()
  clearFileStorageV3$ = this.actions$.pipe(
    ofType(SessionManagementTypes.clearFileStorageActionV3),
    switchMap((action: ClearFileStorageAction) => {
      
      return this.apiInitializer.clearFileStorageV3("").pipe(
        map((response: any) => {
          if (response.success ==true) {
            //this.toastr.success("Deleted all notifications successfully");
            this.sessions.fitBounds = true;
            return new ClearFileStorageSuccess(response.data);

            
          }else{
            return of(new LogFilureAction("Notifications  could not be deleted."))
}
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )


  @Effect()
  clearFileStorageSingle$ = this.actions$.pipe(
    ofType(SessionManagementTypes.clearFileStorageActionSingle),
    switchMap((action: ClearFileStorageActionSingle) => {
      
      return this.apiInitializer.clearFileStorageSingle(action.deleteId).pipe(
        map((response: any) => {
          if (response.success == true) {
            
            this.toastr.success("Deleted notification successfully");
            this.sessions.fitBounds = true;
            return new ClearFileStorageSuccessSingle(response.data);
          }else {
            
            return of(new LogFilureAction("Notification could not be deleted."))

          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
   )

   @Effect()
  clearFileStorageSingleV3$ = this.actions$.pipe(
    ofType(SessionManagementTypes.clearFileStorageActionSingleV3),
    switchMap((action: ClearFileStorageActionSingle) => {
      
      return this.apiInitializer.ClearFileStorageActionSingleV3(action.deleteId).pipe(
        map((response: any) => {
          if (response.success == true) {
           // this.toastr.success("Deleted notification successfully");
            this.sessions.fitBounds = true;
            return new ClearFileStorageSuccessSingle(response.data);
            }
            else {
              return of(new LogFilureAction("Notification could not be deleted."))

            }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
   )



 

  @Effect()
  updateSession$ = this.actions$.pipe(
    ofType(SessionManagementTypes.updateSessionStart),
    switchMap((action: UpdateSessionStartAction) => {
      return this.apiInitializer.updateSession(action.sessionId, action.datum).pipe(
        map((response: any) => {
          if (response.status === "success") {
            return new UpdateSessionSuccessAction(response.data);
          } else {
            if (response.error && response.error.error && response.error.error.length > 0) {
              const msg = response.error.error[0].message;
              if (msg) {
                alert(msg);
              }
            } else {
              alert('Failed to update the session');
            }
            return of(new LogFilureAction("Failed to update the session"));
          }
        }),
        catchError((error: Error) => {
          return of(new GetSessionDetailsStartAction(action.sessionId))
        })
      )
    })
  )

  @Effect()
  layerCopy$ = this.actions$.pipe(
    ofType(SessionManagementTypes.copyLayerStart),
    switchMap((action: CopyLayerStartAction) => {
      return this.apiInitializer.copyLayer(action.sessionId, action.datum).pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success('Duplicate layer has been created');
            return new GetSessionDetailsSuccessAction(response.data);
          } else {
            return of(new LogFilureAction("Error in duplicating the layer!"));
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  sessionDetails$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSessionDetailsStart),
    switchMap((action: GetSessionDetailsStartAction) => {
      return this.apiInitializer.getSessionDetails(action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            return new GetSessionDetailsSuccessAction(response.data);
          } else {
            this.sessions.resetSessionDetails();
            return of(new LogFilureAction("Error in fetching session details"));
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  userSessions$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSessionsStart),
    switchMap((action: GetSessionsStartAction) => {
      return this.apiInitializer.getSessions(action.payload).pipe(
        map((response: any) => new GetSessionsSuccessAction(response.data)),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  deleteSession$ = this.actions$.pipe(
    ofType(SessionManagementTypes.deleteSessionStart),
    switchMap((action: DeleteSessionStartAction) => {
      let localData = JSON.parse(localStorage.getItem('mapView'));
      if (localData && localData.length) {
        localData.forEach((obj, i) => {
          if (obj.sessionId === action.sessionId) { localData.splice(i, 1); }
        })

        localStorage.setItem('mapView', JSON.stringify(localData));
      }
      return this.apiInitializer.deleteSession(action.sessionId).pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success("Session deleted");
            return new DeleteSessionSuccessAction(response)
          } else {
            return of(new LogFilureAction("Could not delete the session"));
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  copySession$ = this.actions$.pipe(
    ofType(SessionManagementTypes.copySessionStart),
    switchMap((action: CopySessionStartAction) => {
      return this.apiInitializer.copySession(action.sessionId).pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success('Copy has been created successfully');
            return new CopySessionSuccessAction(response.data);
          } else {
            return of(new LogFilureAction("Could not create copy of the selected session"))
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  updatedSessions$ = this.actions$.pipe(
    ofType(SessionManagementTypes.copySessionSuccess),
    map((action: CopySessionSuccessAction) => {
      return new GetSessionsStartAction(SESSION_PAYLOAD);
    })
  )

  @Effect()
  privateSession$ = this.actions$.pipe(
    ofType(SessionManagementTypes.privatizeSessionStart),
    switchMap((action: PrivatizeSessionStartAction) => {
      return this.apiInitializer.privatizeSession(action.sessionId).pipe(
        map((response: any) => {
          if (response.ok) {
            return new PrivatizeSessionSuccessAction(action.sessionName);
          } else {
            return of(new LogFilureAction("Could not make the session private"))
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  assignUsers$ = this.actions$.pipe(
    ofType(SessionManagementTypes.assignUsersStart),
    switchMap((action: AssignUsersStartAction) => {
      return this.apiInitializer.shareSession(action.sessionId, action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            return new AssignUsersSuccessAction();
          }
          return of(new LogFilureAction("Could not assign session to the selected users"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  assignedUsers$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getAssignedUsersStart),
    switchMap((action: GetAssignedUsersStartAction) => {
      return this.apiInitializer.getAssignedUsers(action.sessionId).pipe(
        map((response: any) => {
          if (response.status === "success") {
            return new GetAssignedUsersSuccessAction(response.data)
          }
          return of(new LogFilureAction("Could not fetch assigned analysts list for the selected session"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  assignedSurveyors$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getAssignedSurveyorsStart),
    switchMap((action: GetAssignedSurveyorsStartAction) => {
      return this.apiInitializer.getAssignedSurveyors(action.sessionId).pipe(
        map((response: any) => {
          if (response.status === "success") {
            return new GetAssignedSurveyorsSuccessAction(response.data)
          }
          return of(new LogFilureAction("Could not fetch assigned surveyors list for the selected session"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  users$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getUsersListStart),
    switchMap((action: GetUsersListStartAction) => {
      return this.apiInitializer.getAllUsers(action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            return new GetUsersListSuccessAction(response.data.data);
          }
          return of(new LogFilureAction("Unable to fetch users list at the moment!"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  surveyors$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSurveyorsListStart),
    switchMap((action: GetSurveyorsListStartAction) => {
      return this.apiInitializer.getAllSurveyors().pipe(
        map((response: any) => {
          if (response.success) {
            return new GetSurveyorsListSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch surveyors list at the moment"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  layerTableData$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getLayerTableDataStart),
    switchMap((action: GetLayerTableDataStartAction) => {
      return this.layerService.getLayerTableData(action.layerId, action.params).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetLayerTableDataSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch table data for the selected layer"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  queryTableData$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getQueryTableDataStart),
    switchMap((action: GetQueryTableDataStartAction) => {
      return this.layerService.getLayerQueryData(action.layerId, action.query, action.apiData).pipe(
        map((response: any) => {
          if (response.data) {
            return new GetQueryTableDataSuccessAction(response);
          }
          return of(new LogFilureAction("Unable to fetch table data for the selected query"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  updatedDataLayerEntry$ = this.actions$.pipe(
    ofType(SessionManagementTypes.approveLayerTableDataStart),
    switchMap((action: ApproveLayerTableDataStartAction) => {
      return this.layerService.updateLayerTableDataEntry(action.layerEntryId, action.params).pipe(
        map((response: any) => {
          if (response.success) {
            return new ApproveLayerTableDataSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to update layer data entries at the moment"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  layerAttributes$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getLayerAttributesStart),
    switchMap((action: GetLayerAttributesStartAction) => {
      return this.layerService.getLayerAttributes(action.layerId).pipe(
        map((response: any) => {
          // if(response.success) {
          return new GetLayerAttributesSuccessAction(response.data);
          // }
          // return of(new LogFilureAction("Unable to get layer attributes"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  uniqueAttributeValues$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getUniquePropertyValuesStart),
    switchMap((action: GetUniquePropertyValuesStartAction) => {
      return this.layerService.getLayerUniqueProperties(action.layerId, action.payload).pipe(
        map((response: any) => {
          // if(response.success) {
          return new GetUniquePropertyValuesSuccessAction(response.data);
          // }
          // return of(new LogFilureAction("Unable to get unique property values"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  styleTypes$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getStyleTypesStart),
    switchMap((action: GetStyleTypesStartAction) => {
      return this.layerService.getLayerStyleTypes().pipe(
        map((response: any) => {
          // if(response.success) {
          return new GetStyleTypesSuccessAction(response.data);
          // }
          // return of(new LogFilureAction("Unable to get the style types"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  savedQueries$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSavedQueriesStart),
    switchMap((action: GetSavedQueriesStartAction) => {
      return this.layerService.getSavedQueries().pipe(
        map((response: any) => {
          return new GetSavedQueriesSuccessAction(response.data);
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  graphicsAttributes$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getGraphicsLayerDataStart),
    switchMap((action: GetGraphicsLayerDataStartAction) => {
      return this.layerService.getSelectionGraphics(action.payload).pipe(
        map((response: any) => {
          return new GetGraphicsLayerDataSuccessAction(response.data[0]);
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  rowDataDetails$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getCollectedDataRowDetailsStart),
    switchMap((action: GetCollectedDataRowDetailsStartAction) => {
      return this.layerService.getRowDataDetails(action.layer_entry_id).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetCollectedDataRowDetailsSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch row data at the moment"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  saveRowDataDetails$ = this.actions$.pipe(
    ofType(SessionManagementTypes.saveCollectedDataRowDetailsStart),
    switchMap((action: SaveCollectedDataRowDetailsStartAction) => {
      return this.layerService.setRowDataDetails(action.layer_entry_id, action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            return new SaveCollectedDataRowDetailsSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to save the edited data at the moment"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  sendForApproval$ = this.actions$.pipe(
    ofType(SessionManagementTypes.sendDataForApprovalStart),
    switchMap((action: SendDataForApprovalStartAction) => {
      return this.layerService.sendDataForApproval(action.layer_entry_id, action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            switch (action.payload.entry_status) {
              case 1:
              case 2:
                if (action.payload.is_reverted) this.toastr.success("Existing approval request undone successfully.")
                else this.toastr.success("Data sent for approval successfully.");
                break;
              case 3:
                this.toastr.success("Delete request placed for approval successfully.");
                break;
            }
            return new SendDataForApprovalSuccessAction(response.data);
          }
          this.toastr.error("An error occurred. Please try again later");
          return of(new LogFilureAction("Unable to send the editted data for approval"))
        }),
        catchError((error: Error) => {
          this.toastr.error("An error occurred. Please try again later");
          return of(new LogErrorAction(error))
        })
      )
    })
  )

  @Effect()
  addForApproval$ = this.actions$.pipe(
    ofType(SessionManagementTypes.addDataForApprovalStart),
    switchMap((action: AddDataForApprovalStartAction) => {
      return this.layerService.addDataForApproval(action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            this.toastr.success("Data sent for approval successfully.");
            return new AddDataForApprovalSuccessAction(response.data);
          }
          this.toastr.error("An error occurred. Please try again later");
          return of(new LogFilureAction("Unable to send the added data for approval"))
        }),
        catchError((error: Error) => {
          this.toastr.error("An error occurred. Please try again later");
          return of(new LogErrorAction(error))
        })
      )
    })
  )

  @Effect()
  approvalRequests$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getPendingFeaturesStart),
    switchMap((action: GetPendingFeaturesStartAction) => {
      return this.layerService.getUnapprovedFeatures(action.layerId, action.params).pipe(
        map((response: any) => {
          if (response.success) return new GetPendingFeaturesSuccessAction(response.data);
          return of(new LogFilureAction("Error fetching the unapproved features"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  approveRequest$ = this.actions$.pipe(
    ofType(SessionManagementTypes.approveRequestedFeatureEditStart),
    switchMap((action: ApproveRequestedFeatureEditStartAction) => {
      return this.layerService.approveFeature(action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            if (action.payload.approve_status) {
              this.toastr.success("The changes are being processed, and shall be merged with the layer in a few minutes.");
            } else {
              this.toastr.success("Data rejected successfully.");
            }
            return new ApproveRequestedFeatureEditSuccessAction(response)
          };
          this.toastr.error("An error occurred. Please try again later");
          return of(new LogFilureAction("An error occurred. Please try again later"))
        }),
        catchError((error: Error) => {
          this.toastr.error("An error occurred. Please try again later");
          return of(new LogErrorAction(error))
        })
      )
    })
  )

  @Effect()
  publishSession$ = this.actions$.pipe(
    ofType(SessionManagementTypes.uploadStyleForSessionPublishStart),
    switchMap((action: UploadStyleForSessionPublishStartAction) => {
      return this.layerService.uploadStyle(action.sessionId, action.payload).pipe(
        map((response: any) => {
          if (response.status === 'success') {
            return new UploadStyleForSessionPublishSuccessAction(response.data);
          }
          new HideLoaderAction();
          return of(new LogFilureAction('Error publishing the session.'))
        }),
        catchError((error: Error) => {
          new HideLoaderAction();
          if (
            error.message
          ) {
            const errMessage = error.message;
            if (errMessage) {
              this.toastr.error(errMessage);
            } else {
              this.toastr.error(
                'Styles upload failed please try again'
              );
            }
          } else {
            this.toastr.error(
              'Unable to upload Styles file, please check the network connection and try  again'
            );
          }
          return of(new LogErrorAction(error))
        })
      )
    })
  )

  @Effect()
  assignSurveyors$ = this.actions$.pipe(
    ofType(SessionManagementTypes.assignSurveyorsStart),
    switchMap((action: AssignSurveyorsStartAction) => {
      return this.layerService.assignSurveyors(action.sessionId, action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success("Session successfully updated with the surveyors list.");
            return new AssignSurveyorsSuccessAction(response.data);
          }
          return of(new LogFilureAction("Session could not be assigned to surveyors. Please try again later."))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  editedFeatures$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getAllUnapprovedFeatureEditsStart),
    switchMap((action: GetAllUnapprovedFeatureEditsStartAction) => {
      return this.layerService.getMyUnapprovedFeatures(action.layerID).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetAllUnapprovedFeatureEditsSuccessAction(response.data);
          }
          return of(new LogFilureAction("Could not fetch data at the moment. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  exportCSV$ = this.actions$.pipe(
    ofType(SessionManagementTypes.exportDataStart),
    switchMap((action: ExportDataStartAction) => {
      return this.layerService.exportData(action.payload).pipe(
        map((response: any) => {
          if (response) {
            return new ExportDataSuccessAction(response);
          }
          return of(new LogFilureAction("Could not export. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  exportQuery$ = this.actions$.pipe(
    ofType(SessionManagementTypes.exportQuerySearchDataStart),
    switchMap((action: ExportQuerySearchDataStartAction) => {
      return this.layerService.exportQueryData(action.payload).pipe(
        map((response: any) => {
          if (response) {
            return new ExportQuerySearchDataSuccessAction(response);
          }
          return of(new LogFilureAction("could not export. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  exportAdvSearch$ = this.actions$.pipe(
    ofType(SessionManagementTypes.exportSearchResultStart),
    switchMap((action: ExportSearchResultStartAction) => {
      return this.layerService.exportSearchResult(action.paylod).pipe(
        map((response: any) => {
          if (response) {
            return new ExportSearchResultSuccessAction(response);
          }
          return of(new LogFilureAction("Could not export. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  attributeInfo$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getLayerAttributeInfoStart),
    switchMap((action: GetLayerAttributeInfoStartAction) => {
      return this.layerService.getAttributeInfo(action.layerID, action.attr).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetLayerAttributeInfoSuccessAction(response.data);
          }
          return of(new LogFilureAction("Could not fetch data at the moment. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  pointsWithInPoly$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getAdvancedToolsSearchResultStart),
    switchMap((action: GetAdvancedToolsSearchResultStartAction) => {
      return this.apiInitializer.getAdvancedToolsSearchResult(action.payload).pipe(
        map((response: any) => {
          if (response.data) {
            return new GetAdvancedToolsSearchResultSuccessAction(response);
          }
          return of(new LogFilureAction("could not get data. Please try again later "))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  rasterToolProcessed$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getRasterToolProcessingStart),
    switchMap((action: GetRasterToolProcessingStartAction) => {
      return this.apiInitializer.getRasterToolResult(action.payload).pipe(
        map((response: any) => {
          if (response && response.success) {
            this.toastr.success('Request submitted successfully')
            return new GetRasterToolProcessingSuccessAction(response);
          }
          return of(new LogFilureAction("could not process the request"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  tempLayer$ = this.actions$.pipe(
    ofType(SessionManagementTypes.createTemporaryLayerStart),
    switchMap((action: CreateTemporaryLayerStartAction) => {
      return this.layerService.createTemporaryLayer(action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            if (action.payload.make_permanent) {
              this.toastr.success("Permanent layer is created successfully");
              return new CreatePermanentLayerSuccessAction(response.data);
            }
            this.toastr.success("Temporary layer created successfully");
            return new CreateTemporaryLayerSuccessAction(response.data);
          }
          return of(new LogFilureAction("could not generate the temporary layer. Please try again later."))
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error))
        })
      )
    })
  )

  @Effect()
  permLayer$ = this.actions$.pipe(
    ofType(SessionManagementTypes.createPermanentLayerStart),
    switchMap((action: CreatePermanentLayerStartAction) => {
      return this.layerService.createPermanentLayer(action.payload).pipe(
        map((response: any) => {
          if (response.status === "success") {
            this.toastr.success("Permanent layer is created successfully");
            return new CreatePermanentLayerSuccessAction(response.data)
          }
          return of(new LogFilureAction("could not generate the temporary layer. Please try again later."))
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error))
        })
      )
    })
  )
  @Effect()
  graphicLayers$ = this.actions$.pipe(
    ofType(SessionManagementTypes.exportGraphicStartAction),
    switchMap((action: ExportGraphicStartAction) => {
      return this.layerService.exportGraphicData(action.payload).pipe(
        map((resp: any) => {
          return new ExportGraphicSuccessAction(resp);
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error))
        })
      )
    })
  )
  @Effect({ dispatch: false })
  updateQuery$ = this.actions$.pipe(
    ofType(SessionManagementTypes.updateQueryStartAction),
    switchMap((action: UpdateQueryStartAction) => {
      let doneOnce: boolean = false
      return this.layerService.updateQuery(action.id, action.data).pipe(
        map((resp: any) => {
          if (resp && resp.success) {
            if (!doneOnce) {

              this.toastr.success('query updated successfully')
              doneOnce = false;
            }

            this._store.dispatch(new GetSavedQueriesStartAction());
          }

        })
      )
    })
  )
  @Effect({ dispatch: false })
  deleteQuery$ = this.actions$.pipe(
    ofType(SessionManagementTypes.deleteQueryStartAction),
    switchMap((action: DeleteQueryStartAction) => {
      return this.layerService.deleteQuery(action.id).pipe(
        map((resp: any) => {
          if (resp && (resp.success)) {
            return this._store.dispatch(new GetSavedQueriesStartAction())
          }
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))

        })
      )
    })
  )
  @Effect()
  uploadLayer$ = this.actions$.pipe(
    ofType(SessionManagementTypes.uploadFileStartAction),
    switchMap((action: UploadFileStartAction) => {
      return this.layerService.sessionUploadFile(action.formData, action.id, action.data).pipe(
        map((resp: any) => {
          if (resp && resp.success) {
            this.toastr.success('File uploaded successfully');
            // this._store.dispatch(new GetSessionDetailsStartAction(action.id));
            return new UploadFileSuccessAction(resp);
          }
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )
  @Effect()
  processingFiles$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getProcessingFilesStartAction),
    switchMap((action: ProcessingFileStartAction) => {
      return this.layerService.getProcessingVectorLayers(action.sessionId).pipe(
        map((resp: any) => {
          if (resp && resp.success) {
            return new ProcessingFileSuccessAction(resp);
          }
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )
  @Effect()
  updateIcon$ = this.actions$.pipe(
    ofType(SessionManagementTypes.updateIconStartAction),
    switchMap((action: UpdateIconStartAction) => {
      return this.layerService.updateIconSelection({ iconName: action.iconName, sessionId: action.sessionId }).pipe(
        map((resp: any) => {
          if (resp && resp.success) {
            return new UpdateIconSuccessAction(resp)
          }
          return of(new LogFilureAction("Error getting organisation details"))
        }),
        catchError((err: Error) => {
          return of((new LogErrorAction(err)))
        })
      )
    })
  )


  @Effect()
  geticonsList$: Observable<Action> = this.actions$.pipe(
    ofType(SessionManagementTypes.getIconsListStartAction),
    switchMap((action: GetIconsListStartAction) => {
      return this._api.getNew(`/icons/export`, true, false).pipe(map((resp) => {
        if (resp) {
          return new GetIconsSuccessAction(resp)
        }
      }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  tosmSources$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getOSMTranserveSourcesStart),
    switchMap((action: GetOSMTranserveSourcesStartAction) => {
      return this.layerService.getOSMTranserveSources().pipe(
        map((resp: any) => {
          if (resp.success) {
            return new GetOSMTranserveSourcesSuccessAction(resp.sourceLayers);
          }
          return of(new LogFilureAction("Could not get the osm resources. Try again later!"));
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )
  @Effect()
  getProcessedList$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getProcessedLayerListStartAction),
    switchMap((action: GetProcessedListStartAction) => {
      return this.apiInitializer.getProcessedLayerList(action.url).pipe(
        map((resp: any) => {
          if (resp.sucess) {
            return new GetProcessingListSuccessAction(resp)
          }
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  getSearchedMapResults$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSearchedMapResults),
    switchMap((action: GetSearchedMapResults) => {
      let call = this.apiInitializer.getSearchedMapResult(action.searchValue, action.payload);
      return ((action.published) ? this.apiInitializer.postPublishedSearchMapResults(action.searchValue, action.payload) : call).pipe(
        switchMap((resp: any) => {
          const result: Action[] = []
          console.log(resp);
          if (resp && resp.success) {
            result.push(new GetSearchedMapResultsSuccess(resp.data.values, true))
          }
          return result;
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
    // let call1 = this.apiInitializer.getSearchedMapResult(action.searchValue, action.payload);
    // let call2 = this.apiInitializer.getSearchedHEREMapResult(action.searchValue, action.payload);
    //   return ((action.published) ? this.apiInitializer.postPublishedSearchMapResults(action.searchValue, action.payload): 
    //   forkJoin(
    //     [call1,call2]
    //     )
    //     ).pipe(
    //       switchMap((resp: any) => {
    //         const result: Action[] =  []
    //       console.log(resp);
    //       if (resp.items) {
    //         result.push(new GetSearchedMapResultsSuccess(resp.items))
    //       }
    //       if (resp[0] && resp[0].success) {
    //         result.push(new GetSearchedMapResultsSuccess(resp[0].data.values))
    //       }
    //       if (resp[1] && resp[1].items) {
    //         result.push(new GetSearchedMapResultsSuccess(resp[1].items,true))
    //       }
    //       return result;
    //     }),
    //     catchError((err: Error) => {
    //       return of(new LogErrorAction(err))
    //     })
    //   )
    // })
  )

  @Effect()
  getSearchedHereMapResults$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSearchedHereMapResults),
    switchMap((action: GetSearchedHereMapResults) => {
      let call = this.apiInitializer.getSearchedHEREMapResult(action.searchValue, action.payload);
      return ((action.published) ? this.apiInitializer.postPublishedSearchMapResults(action.searchValue, action.payload) : call).pipe(
        switchMap((resp: any) => {
          const result: Action[] = []
          console.log(resp);
          if (resp && resp.items) {
            result.push(new GetSearchedHereMapResultsSuccess(resp.items, true))
          }
          return result;
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  getPlaceDetails$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getPlaceDetails),
    switchMap((action: GetPlaceDetails) => {
      return ((action.published) ? this.apiInitializer.getPublishedPlaceDetails(action.id, action.index) : this.apiInitializer.getPlaceDetails(action.id, action.index)).pipe(
        map((resp: any) => {
          console.log("resp", resp);
          if (resp) {
            return new GetPlaceDetailsSuccess(resp)
          }
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )
  @Effect()
  deleteProcessingLayer$ = this.actions$.pipe(
    ofType(SessionManagementTypes.processingLayerDeleteStart),
    switchMap((action: DeleteProcessingStartAction) => {
      return this.apiInitializer.deleteProcessingLayer(action.payload).pipe(
        map((resp: any) => {
          return new DeleteProcessingSuccessAction(resp)
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  exportAsGeotiff$ = this.actions$.pipe(
    ofType(SessionManagementTypes.exportAsGeoTiffStart),
    switchMap((action: ExportAsGeoTiffStartAction) => {
      return this.apiInitializer.exportAsGeoTiff(action.payload).pipe(
        map((resp: any) => {
          return new ExportAsGeoTiffSuccessAction(resp)
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  authnticateUser$ = this.actions$.pipe(
    ofType(SessionManagementTypes.libraryUploadAuthenticationStart),
    switchMap((action: LibraryUploadAuthenticationStartAction) => {
      return this.apiInitializer.validateUserForLibUpload().pipe(
        map((resp: any) => {
          return new LibraryUploadAuthenticationSuccessAction(resp);
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err));
        })
      )
    })
  )

  @Effect()
  notificationDelete$ = this.actions$.pipe(
    ofType(SessionManagementTypes.deleteNotificationStart),
    switchMap((action: DeleteNotificationStartAction) => {
      return this.apiInitializer.deleteNotification({ id: action.payload.id }).pipe(
        map((resp: any) => {
          return new DeleteNotificationSuccessAction(resp);
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err));
        })
      )
    })
  )

  @Effect()
  clearAllNotification$ = this.actions$.pipe(
    ofType(SessionManagementTypes.clearAllNotificationStart),
    switchMap((action: ClearAllNotificationStartAction) => {
      return this.apiInitializer.clearAllNotifications().pipe(
        map((resp: any) => {
          return new ClearAllNotificationSuccessAction(resp);
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )
  @Effect()
  getClassificationMethod$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getGraduatedClassificationDataStartAction),
    switchMap((action: GetClassificationDataStartAction) => {
      return this.apiInitializer.getClassificationMethodRanges(action.payload).pipe(
        map((resp: any) => {
          return new GetClassificationDataSuccessAction(resp);
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  dcData$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getDataCollectedLayerDataStart),
    switchMap((action: GetDataCollectedLayerDataStartAction) => {
      return this.apiInitializer.getDCLayerData(action.payload).pipe(
        map((resp: any) => {
          if (resp.success) {
            return new GetDataCollectedLayerDataSuccessAction(resp.data);
          }
          return of(new LogFilureAction("Error fetching data"));
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err));
        })
      )
    })
  )

  @Effect()
  approvalActions$ = this.actions$.pipe(
    ofType(SessionManagementTypes.DCLayerDataApprovalStart),
    switchMap((action: DCLayerDataApprovalStartAction) => {
      return this.apiInitializer.approveDCCollectedData(action.payload).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.toastr.success(resp.data.message);
            return new DCLayerDataApprovalSuccessAction(resp.data);
          }
          return of(new LogFilureAction("Error in performing the action"));
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err));
        })
      )
    })
  )

  @Effect()
  layers$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getAllLayersInfoStart),
    switchMap((action: GetAllLayersInfoStartAction) => {
      return this.layerService.getAll(action.payload).pipe(
        map((resp: any) => {
          if (resp.success) {
            return new GetAllLayersInfoSuccessAction(resp.data);
          }
          return of(new LogFilureAction("Error in fetching the layers"));
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err));
        })
      )
    })
  )
  @Effect()
  layerDetails$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getLayerNameDetailsStart),
    switchMap((action: GetLayerNameStartAction) => {
      return this.apiInitializer.getLayerDetails(action.layerdId).pipe(
        map((resp: any) => {
          return new GetLayerNameSuccessAction(resp)
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )

    })
  )
  @Effect()
  networkLayer$ = this.actions$.pipe(
    ofType(SessionManagementTypes.createNetworkLayersStart),
    switchMap((action: CreateNetworkLayersStartAction) => {
      return this.layerService.createNetworkLayers(action.payload).pipe(
        map((resp: any) => {
          this.toastr.success("Network layer created successfully")
          return new CreateNetworkLayersSuccessAction(resp)
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  similaritySearch$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSimilaritySearchStart),
    switchMap((action: GetSimilaritySearchStartAction) => {
      return this.apiInitializer.getAdvancedToolsSearchResult(action.payload).pipe(
        map((response: any) => {
          if (response.data) {
            if (response.warning && !this.sessions.warningMessage) {
              this.sessions.warningMessage = '';
              this.sessions.warningMessage = response.warning;
              this.sessions.setIsWarningEnabledForSimilaritySearch(true);
              return new GetSimilaritySearchSuccessAction(response);
            } else {
              this.sessions.warningMessage = '';
              return new GetAdvancedToolsSearchResultSuccessAction(response);
            }
          }
          return of(new LogFilureAction("could not get data. Please try again later "))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  selectByLoc$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getSelectByLocationStart),
    switchMap((action: GetSelectByLocationStartAction) => {
      if(action && action.payload){
      return this.apiInitializer.getAdvancedToolsSearchResult(action.payload).pipe(
        map((response: any) => {
          if (response.data) {
            return new GetAdvancedToolsSearchResultSuccessAction(response);
          }
          return of(new LogFilureAction("could not get data. Please try again later "))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )}
    })
  )

  @Effect()
  DataInsightdLayerData$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getDataInsightdLayerDataStart),
    switchMap((action: GetDataInsightdLayerDataStartAction) => {
      return this.apiInitializer.getDataInsightsLayerData(action.payload).pipe(
        map((response: any) => {
          if (response.data) {
            // return new GetAdvancedToolsSearchResultSuccessAction(response);
            return new GetDataInsightdLayerDataSuccessAction(response)
          }
          return of(new LogFilureAction("could not get data. Please try again later "))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  DataInsightData$ = this.actions$.pipe(
    ofType(SessionManagementTypes.getDataInsightStartAction),
    switchMap((action: GetDataInsightStartAction) => {
      if(action && action.payload){
      return this.apiInitializer.getDataInsightsData(action.payload).pipe(
        map((response: any) => {
          if (response) {
            // return new GetAdvancedToolsSearchResultSuccessAction(response);
            return new GetDataInsightSuccessAction(response)
          }
          return of(new LogFilureAction("could not get data. Please try again later "))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )}
    })
  )

  @Effect()
  copeLayerFromSection$ = this.actions$.pipe(
    ofType(SessionManagementTypes.copyLayerFromSession),
    switchMap((action: copyLayerFromSession) => {
      return this.apiInitializer.copyLayerFromSection(action.data).pipe(
        map((response: any) => {
          if (response.status === "success") {
            action.data.data.newSession?this.toastr.success('New Session has been created with Selected Layer'):this.toastr.success('Layer copied successfully');
            return new GetSessionDetailsSuccessAction(response.data);
          } else {
            return of(new LogFilureAction("Error in Layer copied!"));
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
  
  @Effect()
  moveLayerFromSection$ = this.actions$.pipe(
    ofType(SessionManagementTypes.moveLayerFromSession),
    switchMap((action: moveLayerFromSession) => {
      return this.apiInitializer.moveLayerFromSection(action.data).pipe(
        map((response: any) => {
          if (response.status === "success") {
            action.data.data.newSession?"":this.sessions.setMoveLayerToSessionSuccess(true);
            action.data.data.newSession?this.toastr.success('New Session has been created with Selected Layer'):this.toastr.success('Layer moved successfully');
            return new GetSessionDetailsSuccessAction(response.data);
          } else {
            return of(new LogFilureAction("Error in Layer movied!"));
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
}

export const SESSION_PAYLOAD: any = {
  "search": "",
  "page": 1,
  "limit": 20,
  "sort": null,
  "order": "asc"
}