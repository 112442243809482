import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelect, MatOption } from "@angular/material";
import { SessionService } from "src/app/_services/session.service";
import { cloneDeep } from "lodash";
import { LAYER_ACTIVE_ICONS, LAYER_INACTIVE_ICONS } from "src/app/app.constants";
import { SpatialJoinSummaryDialog } from "../spatial-join-summary/spatial-join-summary.component";
import { SpatialJoinAddDialog } from "../spatial-join-add/spatial-join-add.component";

@Component({
  selector: "app-spatial-join",
  templateUrl: "./spatial-join.component.html",
  styleUrls: ["./spatial-join.component.scss"]
})

export class SpatialJoinDialog {

  @ViewChild('select',{}) select: MatSelect;

  layerActiveIcons = LAYER_ACTIVE_ICONS;
  layerInactiveIcons = LAYER_INACTIVE_ICONS;
  inputLayer: FormControl = new FormControl("", [Validators.required]);
  joinLayer: FormControl = new FormControl("", [Validators.required]);
  panelOpenState = false;
  relations: FormControl = new FormControl([]);
  inputLayersList: Array<any> = [];
  joinLayersList: Array<any> = [];
  spatialRelations: Array<any> = [
    {key: 'Intersects', value: 0},
    {key: 'Contains', value: 1},
    {key: 'Within', value: 5},
    {key: 'Overlaps', value: 4},
    {key: 'Crosses', value: 6},
    {key: 'Touches', value: 3},
    {key: 'Equals', value: 2}
  ]
  calculateSummary: boolean = false;
  selectedToolIndex: number = null;
  sessionId: string = null;
  selectedRelations: Array<number> = [];
  selectedRelationsKeys: Array<string> = [];
  constructor(
    private dialogRef: MatDialogRef<SpatialJoinDialog>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.selectedToolIndex = this.data.selectedToolIndex;
    const layersList = this.sessionService.sessionLayers.filter((layer: any) => [1].includes(layer.layer_type) && layer.show);
    this.sessionId = this.sessionService.sessionId;
    this.inputLayersList = cloneDeep(layersList);
    this.joinLayersList = cloneDeep(layersList);
  }

  setSelectedOptions(value) {
    const index = this.selectedRelations.findIndex(relation => relation === value);
    if(index === -1) {
      this.selectedRelations.push(value);
    } else {
      this.selectedRelations.splice(index, 1);
    }
    this.selectedRelationsKeys = this.spatialRelations.filter(sr => this.selectedRelations.includes(sr.value)).map(val => val.key);
  }

  removeFilterChip(key){
    let relations = {}
    relations = this.spatialRelations.filter(sr => (sr.key == key));
    // console.log(relations)
    this.select.options.forEach((item: MatOption) => {
      if (item.selected && (relations[0].value == item.value)) {
        item.deselect()
      }
    });
    this.setSelectedOptions(relations[0].value);
}

 
  
  enableNext = () => {
    // return this.inputLayer.valid && this.joinLayer.valid && this.relations.valid && this.relations.value.length;
    return this.inputLayer.valid && this.joinLayer.valid && this.selectedRelations.length;
  }

  next = () => {
    if(this.calculateSummary) {
      const spatialSummaryDialog = this.dialog.open(SpatialJoinSummaryDialog, {
        width: "600px",
        // height:"583px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          selectedToolIndex: this.selectedToolIndex,
          inputLayer: this.inputLayer.value,
          joinLayer: this.joinLayer.value,
          // relations: this.relations.value,
          relations: this.selectedRelations,
          sessionId: this.sessionId
        }
      })
      spatialSummaryDialog.afterClosed().subscribe((response: any) => {
        if(response) this.dialogRef.close();
      })
    } else {
      const spatialAddDialog = this.dialog.open(SpatialJoinAddDialog, {
        width: "600px",
        disableClose: true,
        hasBackdrop: true,
        data: {
          selectedToolIndex: this.selectedToolIndex,
          inputLayer: this.inputLayer.value,
          joinLayer: this.joinLayer.value,
          // relations: this.relations.value,
          relations: this.selectedRelations,
          sessionId: this.sessionId
        }
      })
      spatialAddDialog.afterClosed().subscribe((response: any) => {
        if(response) this.dialogRef.close();
      })
    }
  }

  close = () => {
    this.dialogRef.close();
  }
}