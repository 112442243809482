import { Component, OnInit ,Inject} from '@angular/core';
import _ from 'lodash';

import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
@Component({
  selector: 'app-data-library-filter',
  templateUrl: './data-library-filter-dialog.component.html',
  styleUrls: ['./data-library-filter-dialog.component.scss']
})
export class DataLibraryFilterDialog implements OnInit {
  options: Array<any> = [];
  selectionHasChanged: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DataLibraryFilterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if(this.data) {
      this.options = _.cloneDeep(this.data.data);
      this.options.map(opt => opt.checked = false);
      if(["Regions", "Data Types"].includes(this.data.type)) {
        const total = this.options.reduce((acc, curr) => {
          return acc += curr.total ? +curr.total : 0;
        }, 0)
        this.options.unshift({category: "All", total, checked: false});
      } else {
        this.options.unshift({category: "All", checked: false});
      }
      const allCategories = this.options.map(opt => opt.category);
      const selectedOptions = this.data.selectedOptions ? this.data.selectedOptions.split(",") : [];
      if(selectedOptions.length) {
        selectedOptions.map(opt => {
          this.options[allCategories.lastIndexOf(opt)].checked = true;
        })
      } else {
        this.options[allCategories.lastIndexOf("All")].checked = true;
      }
    }
  }

  changeCheckedOptions(option, event, bool) {
    this.selectionHasChanged = true;
    const allCategories = this.options.map(opt => opt.category);
    if(bool){
      option.checked=!option.checked;
    }
    else{
      option.checked = event.target.checked;
    }
    if(event.target.checked || bool) {
      if(option.category === "All") {
        this.options.map(opt => {
          if(opt.category !== "All") opt.checked = false;
        })
      } else {
        this.options[allCategories.lastIndexOf("All")].checked = false;
      }
    } else {
      if(option.category === "All") {
        option.checked = true;
      }
    }
  }

  close() {
    if(this.selectionHasChanged) {
      this.dialogRef.close(this.options.filter(opt => opt.checked).map(op => op.category).join(","));
    } else {
      this.dialogRef.close(false);
    }
  }
  ngOnDestroy() {
    this.close();
  }
}
