import { surveyAnalyticsStartAction, SurveyAnalyticsActions, surveyAnalyticsActionTypes, surveyAnalyticsSuccessAction } from '../actions/surveyor-analytics.actions'
import { surveyorData } from '../selectors/survey-analytics.selector';
export interface State {
    surveyorData: {
        data: Array<any>
        surveyorSummary: Array<any>
    }

}
export const initialState: State = {
    surveyorData: {
        data: [],
        surveyorSummary: []
    }

}

export function surveyAnalyticsReducer(state = initialState, action: SurveyAnalyticsActions): State {
    switch (action.type) {
        case surveyAnalyticsActionTypes.surveyAnalyticsSuccess:
            if (action.data.page == 1 || action.data.surveyor ) {
                if(action.data[0] != null){
                    return {
                        ...state, surveyorData: {
                            data: action.data,
                            surveyorSummary: [...action.data[0].surveyor_summary[0].surveyor_details]
                        }
                    };
                }else{
                    return state
                }

            } 
            else {
                return {
                    ...state, surveyorData: {
                        data: action.data,
                        surveyorSummary: [...state.surveyorData.surveyorSummary, ...action.data[0].surveyor_summary[0].surveyor_details]
                    }
                };
            }

        default:
            return state;
    }

}