import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/project-management.reducer';




export const projectManagementSelector = createFeatureSelector<State>("projects");


export const projectListSelector =createSelector(

    projectManagementSelector,
    (state) => state.projectList
    ) 


    export const addProjectSelector =createSelector(

        projectManagementSelector,
        (state) => state.addedProject
    )
    export const updatedProjectSelector =createSelector(
        projectManagementSelector,
        (state) => state.updatedProject
    )
    export const projectDetailSelector =createSelector(
        projectManagementSelector,
    (state) => state.projectDetail
    )
    export const surveyorsListSelector =createSelector(
        projectManagementSelector,
        (state) => state.surveyorsList 

    )
    export const parentFormselector =createSelector (
        projectManagementSelector,
        (state) => state.parentForm
    )
