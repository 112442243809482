import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { activeSessionSelector } from 'src/app/store/selectors/session-management.selector';
import { Subscription } from 'rxjs';
import { BASE_MAP_INDICES } from 'src/app/app.constants';
@Component({
  selector: 'app-basemap-dialog',
  templateUrl: './basemap-dialog.component.html',
  styleUrls: ['./basemap-dialog.component.scss']
})
export class BasemapDialogComponent implements OnInit {
  selectedIndex: number = null;
  subscriptionArr: Array<Subscription> = [];
  baseMapOPtions: Array<any> = [
    {
      name: "No Base Map",
      description: "Blank Session",
      icon: "assets/images/mpp.png"
    },
    {
      name: "OSM Base Map",
      description: "Session with OSM Base",
      icon: "assets/images/Screenshot.png"
    },
    {
      name: "Transerve's OSM Rendition",
      description: "Session with OSM Maps enriched by Transerve",
      icon: "assets/images/Screenshot.png"
    },
    {
      name: "HERE Base Map",
      description: "Session with HERE Base map",
      icon: "assets/images/Screenshot.png"
    }
    // ,
    // {
    //   name: "HERE Aerial Map",
    //   description: "Session with HERE Aerial map",
    //   icon: "assets/images/Screenshot.png"
    // },
    // {
    //   name: "HERE Traffic Map",
    //   description: "Session with HERE Traffic map",
    //   icon: "assets/images/Screenshot.png"
    // }
  ]
  constructor(
    public dialogRef: MatDialogRef<BasemapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscriptionArr.push(
      this._store.select(activeSessionSelector).subscribe(sessionDetails => {
        // if((!sessionDetails.base_layer) || sessionDetails.base_layer == 'None') this.selectedIndex = 1;
        // else if (sessionDetails.base_layer === "TRANSERVE-OSM") this.selectedIndex = 2;
        // else this.selectedIndex = 0;
        if((!sessionDetails.base_layer) || sessionDetails.base_layer == 'None') this.selectedIndex = 0
        else {
          switch (sessionDetails.credits) {
            case "OSM":
            case "HERE-BASE":
            case "HERE-AERIAL":
            case "HERE-TRAFFIC":
              this.selectedIndex = +BASE_MAP_INDICES[sessionDetails.credits];
              break;
            default:
              this.selectedIndex = 1;
              break;
          }
        }
      })
    );
  }
  close(event?) {
    if (event) {
      this.dialogRef.close(this.selectedIndex);
    }
    else {
      this.dialogRef.close(-1);
    }
  }
  setSelectedIndex(index) {
    this.selectedIndex = index;
  }
  ngOnDestroy() {
    if(this.subscriptionArr.length) {
      this.subscriptionArr.forEach(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }
}
