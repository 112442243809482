import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { ADVANCED_TOOL_TYPE, LAYER_ACTIVE_ICONS, LAYER_INACTIVE_ICONS } from "src/app/app.constants";
import { GetAdvancedToolsSearchResultStartAction, GetAdvancedToolsSearchResultSuccessAction, GetLayerAttributesStartAction, SessionManagementTypes } from "src/app/store/actions/session-management.actions";
import { layerAttributesSelector } from "src/app/store/selectors/session-management.selector";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";
import { SessionService } from "src/app/_services/session.service";

@Component({
  selector: "app-distance-to-nearest-facility",
  templateUrl: "./distance-to-nearest-facility.component.html",
  styleUrls: ["./distance-to-nearest-facility.component.scss"]
})

export class DistanceToNearestFacilityDialog {
  destinationLayersList: Array<any> = [];
  originLayersList: Array<any> = [];
  selectedOriginLayer: any = null;
  selectedDestinationLayer: any = null;
  sessionId: string = null;
  layerActiveIcons = LAYER_ACTIVE_ICONS;
  layerInactiveIcons = LAYER_INACTIVE_ICONS;
  destinationNameAttributeList: Array<string> = [];
  selectedDestinationNameAttribute: string = null;
  units: Array<any> = [
    { key: "Meters", value: 0 },
    { key: "Feet", value: 1 },
    { key: "Miles", value: 2 },
    { key: "Kilometers", value: 3 },
    { key: "Layer Unit", value: 4 }
  ];
  selectedUnit: number = null;
  generateNetworkLines: boolean = false;
  selectedToolIndex: number = null;
  subscriptionArr: Array<Subscription> = [];
  constructor(
    private dialogRef: MatDialogRef<DistanceToNearestFacilityDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl,
    private _store: Store<any>,
    private actions: Actions
  ) {}

  ngOnInit() {
    this.selectedToolIndex = this.data.selectedToolIndex;
    this.sessionId = this.sessionService.sessionDetails ? this.sessionService.sessionDetails._id : null;
    this.destinationLayersList = this.sessionService.sessionLayers.filter((layer: any) => (layer.show && (layer.layer_type === 1)));
    this.originLayersList = this.sessionService.sessionLayers.filter((layer: any) => (layer.show && (layer.layer_type === 1) && (['point', 'multipoint'].includes(layer.type))));
    this.subscriptionArr.push(
      this._store.select(layerAttributesSelector).subscribe((attributes: Array<any>) => this.destinationNameAttributeList = attributes)
    );
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        tap((action: GetAdvancedToolsSearchResultSuccessAction) => {
          this.navigationService.setShowLayerTable(true);
          this.sessionService.setShowAdvancedToolsSelectionData(true);
          this.dialogRef.close();
        })
      ).subscribe()
    );
  }

  setOriginLayer(event) {
    this.selectedOriginLayer = event.value;
  }

  setDestinationLayer(event) {
    this.selectedDestinationNameAttribute = null;
    this.destinationNameAttributeList = [];
    this.selectedDestinationLayer = event.value;
    this._store.dispatch(new GetLayerAttributesStartAction(this.selectedDestinationLayer.parent_layer_id));
  }

  setDestinationAttribute(event) {
    this.selectedDestinationNameAttribute = event.value;
  }

  setUnit(event) {
    this.selectedUnit = event.value;
  }

  setGenerateNetworkLines(event) {
    this.generateNetworkLines = event.checked;
  }

  apply() {
    const payload = {
      input_layer_1:{
        layer_id: this.selectedOriginLayer.parent_layer_id,
        type: this.selectedOriginLayer.type
      },
      input_layer_2: {
        layer_id: this.selectedDestinationLayer.parent_layer_id,
        type: this.selectedDestinationLayer.type
      },
      input_params:{
        "outputField": this.selectedDestinationNameAttribute,
        "units": this.selectedUnit,
        "executionType": this.generateNetworkLines ? 'line' : 'point'
      },
      feature_type: 'nearest-hub',
      selection_type: "all",
      query: {limit: 100, page: 1}
    }
    this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    this.navigationService.setShowLayerTable(true);
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  enableApply() {
    return this.selectedDestinationLayer && Object.keys(this.selectedDestinationLayer).length && this.selectedOriginLayer && Object.keys(this.selectedOriginLayer).length && (this.selectedUnit > -1) && this.selectedDestinationNameAttribute;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }
}