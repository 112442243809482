import { Component, Inject, ViewChild, ElementRef, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { maxSizeAllowed } from 'src/app/app.constants';
import { Storeservice } from 'src/app/_services/store.services';
import { tap, catchError } from 'rxjs/operators';
import connProp from "src/assets/connection.properties.json";
import { ShowLoaderAction, HideLoaderAction } from 'src/app/store/actions/loader.actions';
import { Store } from '@ngrx/store';
import { API } from 'src/app/_services';
import { saveAs } from 'file-saver';




@Component({
    selector: 'upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss']
})

@Injectable()
export class UploadDialogComponent {
    connprops = connProp;
    [x: string]: any;
    fileUpoad : boolean =false;
    fileName: string ='';
    showUploadDialog: boolean =true;
    file;
    allUsers: any[] =[];
    invalidUsers: any[] =[];
    validUsers: any[] =[];
    // invalidUsers: any[] =[];
    // validUsers: any[] =[];
    @ViewChild('myInput') myInput : ElementRef;


    constructor(
        public dialogRef: MatDialogRef<UploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toaster: ToastrService,
        public storeService: Storeservice,
        public _store : Store<any>,
        public apiService: API
    ) {
        dialogRef.disableClose =true;
    }

    ngOnInit() {

    }
    cancelCross() {
        this.myInput.nativeElement.value =''
        this.fileUpoad =false;
        this.file ='';
        
    }
     fileChange(event) {
        if (!event.target.files) return;
        const fileList: FileList = event.target.files;
        const file: File = fileList[0];
        if( (file !== null) && ((file.type == 'text/csv') || (file.type == 'application/vnd.ms-excel') || (file.type === 'application/msword') || (file.type == ''))) {
            if(file.size <= maxSizeAllowed.csv) {
                this.file =file
                this.fileName =file.name.split('.').shift();
                this.fileUpoad =true;
            } else {
                this.toaster.warning('Max Size Exceeded')
            }
        } else {
     
            this.toaster.error('Invalid File Format');
        }   
        
    }
    uploadClicked() {
        if(!this.fileUpoad) return
        let formData =new FormData();
         formData.set('upfile', this.file)
         this._store.dispatch(new ShowLoaderAction());
         this.apiService.getFormDataToken().subscribe((resp: any) => {
             if(resp && resp.success) {
                 formData.append('csrf_token', resp.data.csrf_token);
                 this.storeService.bulkInviteUsers(formData).pipe(tap((resp: any) => {
                    this._store.dispatch(new HideLoaderAction());
                    if(resp.success) {
                        this.storeService.uploadedCsvFile = resp;
                        this.showUploadDialog =false;
                        this.fileName =resp.fileName || '';
                        this.invalidUsers = [...resp.invalidUsers].filter((user) => {
                            user['uploaded'] =false;
                            return user
                        })
                        this.validUsers =[...resp.vaidUsers].filter((user) => {
                            user['uploaded'] =true
                            return user
                        })
                        this.allUsers =[...this.invalidUsers,...this.validUsers];
                    }
                })).subscribe((res) => {},
                err => {
                    this._store.dispatch(new HideLoaderAction())
                })
             }

         })
    

    }

    doneClicked() {
     
        this.dialogRef.close({invalidUsers: this.invalidUsers.length, resp: true})

    }
    cancelClicked() {
        this.dialogRef.close({invalidUsers : this.invalidUsers.length, resp: true});
    }
    downloadCsv() {
        this.apiService.getFile(this.connprops.downloadCsv , true , false).subscribe(((resp: any) => {
            if(resp) {
                let blob = new Blob([resp], { type: `text/plain;charset=utf-8` });
                saveAs(blob, 'sample_users.csv');   
                this.toaster.success('File downloaded successfully')
            }
        }));
    }

}