import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  Input,
  OnChanges
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from '@angular/material/dialog';
import { LayerService, API } from '../../_services';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import {
  ShowLoaderAction,
  HideLoaderAction
} from 'src/app/store/actions/loader.actions';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { SessionService } from 'src/app/_services/session.service';
import { RasterBandDialogComponent } from '../raster-band/raster-band-dialog.component';
import { Storeservice } from 'src/app/_services/store.services';
import { GetVectorLayersStartAction, GetProjectionSystemsStartAction, GetTabularDataStartAction, GetYourDataLayersListStartAction } from 'src/app/store/actions/data-management.actions';
import { projectionsSelector } from 'src/app/store/selectors/data-management.selector';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material';
import { fromEvent, of } from 'rxjs';
import { acceptableVectorFormats, acceptableRasterFormats, acceptableRasterFileNamesWithoutType, acceptableVectorFileNamesWithoutType, acceptableTabularFormats, acceptableTabularFileNamesWithoutType } from '../../app.constants'
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  // @Input() data1;
  public fileName = 'No file uploaded';
  public fileSelect;
  public uploadFileType;
  projectionFile: File = null;
  fileUpoad = false;
  spinnerText = 'Uploading data';

  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild('myProjectionInput') myProjectionVariable: ElementRef;
  confirmDialog: any;
  fileSize: number;
  fileType: string;
  typeOfFile: string = '';
  dataObj: any = {};
  type: string;
  isUploadActive: boolean = false;
  projectionSelected: boolean = false;
  showProjectionSelection: boolean = false;
  showProjectionUpload: boolean = false;
  projectionFileUploaded: boolean = false;
  showDataSetSelection: boolean = false;
  showGeometrySelection: boolean = false;
  geometryColumnSelected: boolean = false;
  dataSetSelectedType: string = null;
  selectedProjection = null;
  projectionFileName: string = null;
  page: number = 1;
  totalPages: number = null;
  projections: Array<any> = [];
  myControl = new FormControl();
  gFieldValue = new FormControl();
  searchText = '';
  removable: boolean = true;
  @ViewChild('auto') projectionsAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  fileInfo: any;
  count: number = 0;
  aceptableVectorFormats = acceptableVectorFormats;
  acceptableRasterFormats = acceptableRasterFormats;
  acceptableVectorFilesWithoutType = acceptableVectorFileNamesWithoutType;
  acceptableRasterFilesWithoutType = acceptableRasterFileNamesWithoutType;
  dataSetTypes = ['Point', 'LineString', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon'];
  csvColumns: Array<string> = [];
  option: string = null;
  alertText: string = null;
  supportedFormats: string = null;
  accept: string = null;
  note: string = null;
  constructor(
    public dialogRef: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private layerService: LayerService,
    private toaster: ToastrService,
    private sessionService: SessionService,
    private _store: Store<any>,
    private dialog: MatDialog,
    public storeService: Storeservice,
    private apiService: API
  ) {
    this.uploadFileType = this.sessionService.uploadFileType;
  }

  ngOnInit() {
    this.page = 0;
    this.initializeOptions(this.data);
    this._store.select(projectionsSelector).subscribe((pros: any) => {
      this.projections = pros.data;
      this.totalPages = pros.totalPages;
    })
    this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.searchText = value;
      // let payload = DEFAULT_PROJECTION_PAYLOAD;
      // payload.search = value;
      // this._store.dispatch(new GetProjectionSystemsStartAction(payload));
      this.loadProjections(true);
    })
    this.sessionService.projectionCheck.subscribe((resp) => {
      this._store.dispatch(new HideLoaderAction())
      if (resp) {
        this.showProjectionSelection = false;
        this.isUploadActive = true;
      } else {
        this.showProjectionSelection = true;
        this.isUploadActive = this.projectionSelected ? true : false;

      }
    })
  }

  initializeOptions(option) {
    switch (option) {
      case 1:
        this.option = 'vector';
        this.alertText = 'Upload Vector Layer';
        this.supportedFormats = '(Supported formats : .zip, .kml, .kmz, .dxf, .csv, .json, .geojson) Shapefiles to be uploaded as .zip folders';
        this.accept = '.zip, .kml, .kmz, .dxf, .json, .csv';
        this.note = '';
        break;
      case 2:
        this.option = 'raster'
        this.alertText = 'Upload Raster Layer'
        this.supportedFormats = '(Supported formats : .tiff,.ecw)'
        this.accept = '.tiff, .tif, .ecw'
        this.note = 'Note :- ECW processing takes a bit longer than TIFF files'
        break;
      case 8:
        this.option = 'tabular'
        this.alertText = 'Upload Tabular Data'
        this.supportedFormats = '(Supported formats : .xls,.xlsx,.csv)'
        this.accept = '.xls, .xlsx, .csv'
        this.note = ''
        break;
      default:
        this.option = ''
        this.alertText = 'Upload Data'
        this.supportedFormats = ''
        this.accept = ''
        this.note = ''
    }
  }

  selectType(event) {
    const lTypeObj = {
      vector: 1,
      raster: 2,
      tabular: 8
    }
    this.initializeOptions(lTypeObj[event.value]);
    this.sessionService.uploadFileType = event.value;
    this.isUploadActive = false;
    this.showProjectionSelection = false;
    this.showProjectionUpload = false;
    this.showDataSetSelection = false;
    this.showGeometrySelection = false;
    this.reset();
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    const file: File = fileList[0];
    if (fileList.length > 0) {
      if (this.option === 'raster') {
        if (file.type) {
          if (![...acceptableRasterFormats].includes(file.type)) {
            this.toaster.warning('Please upload valid Raster file ')
            return
          }
        } else {
          if (!([...acceptableRasterFileNamesWithoutType].includes(file.name.split('.').pop()))) {
            this.toaster.warning('Please Upload valid Raster file');
            return
          }
        }
      } else if (this.option === 'vector') {
        if (file.type) {
          if (![...acceptableVectorFormats].includes(file.type)) {
            this.toaster.warning('Please upload valid Vector file ')
            return
          }
        } else {
          if (!([...acceptableVectorFileNamesWithoutType].includes(file.name.split('.').pop()))) {
            this.toaster.warning('Please upload valid Vector file ')
            return
          }
        }
      }
      else {
        if (file.type) {
          if (![...acceptableTabularFormats].includes(file.type)) {
            this.toaster.warning('Please upload valid Tabular file ')
            return
          }
        } else {
          if (!([...acceptableTabularFileNamesWithoutType].includes(file.name.split('.').pop()))) {
            this.toaster.warning('Please upload valid Tabular file ')
            return
          }
        }
      }
      this.gFieldValue.setValue(null);
      let byteSize = fileList[0].size;
      if (this.aceptableVectorFormats.includes(file.type) && (byteSize / 1048576) > 1024) {
        // this.dialogRef.close('false');
        this.layerService.uploadPayloadSubject.next({value:'upload',status:false})
        this.dialog.open(InfoDialogComponent, {
          hasBackdrop: true,
          role: 'dialog',
          data: {
            alertText: 'Maximum  size available to upload in your current plan is 1GB. Contact us to upgrade.'
          }
        })
        this.isUploadActive = false;
        return;
      }

      this.fileSize = file.size;
      this.fileType = file.type;
      if (this.fileType == 'application/octet-stream') { // this is needed to handle ECW type files, DONT remove it.
        alert('Invalid File upload');
        this.reset();
        this.isUploadActive = false;
        this.showProjectionSelection = false;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        return;
      }
      if (this.fileType === '') {
        let ext = file.name.split('.').pop();
        if (ext && ext === 'ecw') {
          this.fileType = 'application/octet-stream';
        } else if (ext && ext === 'kml') {
          this.fileType = 'application/vnd.google-earth.kml+xml';
        } else if (ext && ext === 'kmz') {
          this.fileType = 'application/vnd.google-earth.kmz';
        } else if (ext && ext === 'dxf') {
          this.fileType = 'application/dxf'
        } else if ((ext && ext === 'geojson') || (ext && ext === 'json')) {
          this.fileType = 'application/geojson'
        } else if (ext && ext === 'csv') {
          this.fileType = 'text/csv'
        } else if (ext && ext === 'xls') {
          this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        } else if (ext && ext === 'xlsx') {
          this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      }
      if (this.fileType === 'application/zip' || this.fileType === 'application/x-zip' || this.fileType === 'application/x-zip-compressed') {

        this.typeOfFile = 'vectorFile';
        this.type = 'vector';
        this.showProjectionSelection = false;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        this.isUploadActive = true;
      } else if (this.fileType === "image/tif" || this.fileType === "image/tiff" || this.fileType === "image/x-tif" || this.fileType === "image/x-tif-compressed" || this.fileType == "application/octet-stream") {

        this.typeOfFile = 'rasterFile';
        this.type = 'raster';
        this.showProjectionSelection = false;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        this.isUploadActive = true;
      } else if (this.fileType === "application/vnd.google-earth.kml+xml") {
        this.typeOfFile = 'kmlFile';
        this.type = 'kml';
        this.showProjectionSelection = false;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.isUploadActive = true;
      } else if (this.fileType === "application/vnd.google-earth.kmz") {
        this.typeOfFile = 'kmzFile';
        this.type = 'kmz';
        this.showProjectionSelection = false;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        this.isUploadActive = true;
      } else if (['image/vnd.dxf', "application/dxf"].includes(this.fileType)) {
        this.typeOfFile = 'dxfFile';
        this.type = 'dxf';
        this.showProjectionSelection = true;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        this.myControl.setValue("");
        this.loadProjections(true);
        this.isUploadActive = this.projectionSelected ? true : false;
      } else if (['image/vnd.geojson', "application/geojson", "application/json", "application/geo+json"].includes(this.fileType)) {
        this.typeOfFile = 'geojsonFile';
        this.type = 'geojson';
        this._store.dispatch(new ShowLoaderAction())
        this.sessionService.projectionInFile(file)
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        this.myControl.setValue("");
        this.loadProjections(true);
      } else if (["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(this.fileType)) {
        if (this.option === 'tabular') {
          this.typeOfFile = 'tabularFile';
          this.type = "tabular";
          this.showProjectionSelection = false;
          this.showProjectionUpload = false;
          this.showDataSetSelection = false;
          this.dataSetSelectedType = null;
          this.showGeometrySelection = false;
          this.isUploadActive = true;
        }
        else {
          this.typeOfFile = 'csvFile';
          this.type = "csv";
          this.showProjectionSelection = true;
          this.showProjectionUpload = true;
          this.showDataSetSelection = false;
          this.dataSetSelectedType = null;
          this.showGeometrySelection = false;
          this.myControl.setValue("");
          this.loadProjections(true);
          this.isUploadActive = (this.projectionSelected || this.projectionFileUploaded) && this.geometryColumnSelected && !!this.dataSetSelectedType;
          let reader = new FileReader();
          reader.readAsText(event.target.files[0]);
          reader.onload = () => {
            let csvData = reader.result;
            let csvHeaders = (<string>csvData).split(/\r\n|\n/)[0];
            this.csvColumns = this.getHeaderArray(csvHeaders).map(headertxt => headertxt.replace(/"/g, ""));
          };
        }
      } else {
        alert('Invalid File upload');
        this.reset();
        this.showProjectionSelection = false;
        this.showProjectionUpload = false;
        this.showDataSetSelection = false;
        this.showGeometrySelection = false;
        this.isUploadActive = false;
        return;
      }
      this.fileUpoad = true;
      this.fileSelect = file;
      this.fileName = file.name;
    }
  }

  loadProjections(init?) {
    let payload = DEFAULT_PROJECTION_PAYLOAD;
    if (init) {
      this.page = 1;
      payload.page = this.page;
      payload.search = this.searchText;
      this._store.dispatch(new GetProjectionSystemsStartAction(payload));
    } else {
      this.page += 1;
      if (this.page <= this.totalPages) {
        payload.page = this.page;
        payload.search = this.searchText;
        this._store.dispatch(new GetProjectionSystemsStartAction(payload));
      }
    }
  }

  gFieldChanged(event) {
    this.gFieldValue.setValue(event.value);
    this.geometryColumnSelected = true;
    this.isUploadActive = true;
  }

  // xFiledChanged(event) { }

  // yFiledChanged(event) { }

  autocompleteScroll() {
    setTimeout(() => {
      if (
        this.projectionsAutocompleteRef &&
        this.autocompleteTrigger &&
        this.projectionsAutocompleteRef.panel
      ) {
        fromEvent(this.projectionsAutocompleteRef.panel.nativeElement, 'scroll')
          .pipe(
            map(x => this.projectionsAutocompleteRef.panel.nativeElement.scrollTop),
            takeUntil(this.autocompleteTrigger.panelClosingActions)
          )
          .subscribe(x => {
            const scrollTop = this.projectionsAutocompleteRef.panel.nativeElement
              .scrollTop;
            const scrollHeight = this.projectionsAutocompleteRef.panel.nativeElement
              .scrollHeight;
            const elementHeight = this.projectionsAutocompleteRef.panel.nativeElement
              .clientHeight;
            const atBottom = scrollHeight === (scrollTop + elementHeight);
            if (scrollTop && scrollHeight && elementHeight && atBottom) {
              this.loadProjections();
            }
          });
      }
    });
  }

  setSelectedProjection(event) {
    const selectedOption = event.option.value
    this.selectedProjection = selectedOption._id;
    this.myControl.setValue(`${selectedOption.epsg} : ${selectedOption.name}`);
    if (this.typeOfFile === "csvFile") {
      this.isUploadActive = false;
      this.showDataSetSelection = true;
      this.projectionSelected = true;
      this.showProjectionUpload = true;
      this.showProjectionSelection = true;
      this.projectionFileUploaded = false;
      this.projectionFile = null;
      this.projectionFileName = null;
      this.dataSetSelectedType = null;
      this.showDataSetSelection = true;
      this.gFieldValue.setValue(null);
      this.showGeometrySelection = false;
    } else {
      this.isUploadActive = true;
    }
  }

  cancelCross() {
    this.fileUpoad = false;
    this.reset();
    this.fileSelect = '';
    this.typeOfFile = '';
    this.isUploadActive = false;
  }
  create_UUID() {
    let date = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // tslint:disable-next-line:no-bitwise
      const r = (date + Math.random() * 16) % 16 | 0;
      date = Math.floor(date / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
  reset() {
    this.myProjectionVariable.nativeElement.value = '';
    this.myInputVariable.nativeElement.value = '';
    this.fileName = '';
    this.fileType = '';
    this.showDataSetSelection = false;
    this.projectionSelected = null;
    this.showProjectionUpload = false;
    this.showProjectionSelection = false;
    this.projectionFileUploaded = false;
    this.projectionFile = null;
    this.projectionFileName = null;
    this.dataSetSelectedType = null;
    this.showDataSetSelection = false;
    this.gFieldValue.setValue(null);
    this.showGeometrySelection = false;
    this.fileUpoad = false;
  }
  uploadClicked() {
    if (!this.isUploadActive){
      return;
    }
    ++this.count;
    if (this.count > 1) {
      return;
    }
    if ((this.sessionService.uploadFileType === this.type) || (["kml", "kmz", "dxf", "csv", "json", "geojson","raster", "tabular"].includes(this.type))) {
      if (this.fileSelect && (["vectorFile", "rasterFile", "kmlFile", "kmzFile", "dxfFile", "csvFile", "geojsonFile","tabularFile"].includes(this.typeOfFile))) {
        this.sessionService.setLoadingText('');
        this._store.dispatch(new ShowLoaderAction());
        if (this.typeOfFile == "vectorFile") {
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'shapefile' };
        } else if (this.typeOfFile === "kmlFile") {
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'kmlfile' };
        } else if (this.typeOfFile === "kmzFile") {
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'kmzfile' };
        } else if (this.typeOfFile === "dxfFile") {
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'dxffile' };
        } else if (this.typeOfFile === "geojsonFile") {
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'geojsonfile' };
        } else if (this.typeOfFile === "csvFile") {
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'csvfile' };
        } else {
          if (this.typeOfFile !== 'rasterFile') {
            this.sessionService.setLoadingText('Uploading file and this may take few minutes');
          }
          else {
            this.sessionService.setLoadingText('Fetching raster band information, please wait');

          }
          this.dataObj = { file_name: this.fileName, file_type: this.fileType, type: 'rasterfile' }
        }

        const uploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'shapefile',
          file_size: this.fileSize
        };
        const kmlUploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'kmlfiles',
          file_size: this.fileSize
        }
        const kmzUploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'kmzfiles',
          file_size: this.fileSize
        }
        const dxfUploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'dxffiles',
          file_size: this.fileSize,
          projection_id: this.selectedProjection
        }
        let geojsonUploadedData: any = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'geojson',
          file_size: this.fileSize,
        }
        if (this.showProjectionSelection) { // add only when projection box is opened
          geojsonUploadedData['projection_id'] = this.selectedProjection
        }

        const csvUploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'csvfiles',
          file_size: this.fileSize,
          projection_id: this.selectedProjection
        }
        const rasterUploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'rasterfiles',
          file_size: this.fileSize
        }

        const tabularUploadedData = {
          originalname: this.fileName,
          filename: this.dataObj.file_name,
          type: 'tabularfiles',
          file_size: this.fileSize
        }

        let formData = new FormData();
        switch (this.typeOfFile) {
          case "vectorFile":
            formData.set('type', uploadedData.type);
            break;
          case "kmlFile":
            formData.set('type', kmlUploadedData.type);
            break;
          case "kmzFile":
            formData.set('type', kmzUploadedData.type);
            break;
          case "dxfFile":
            formData.set('type', dxfUploadedData.type);
            formData.set('projection_id', dxfUploadedData.projection_id);
            break;
          case "geojsonFile":
            formData.set('type', geojsonUploadedData.type);
            if (this.showProjectionSelection) {
              formData.set('projection_id', geojsonUploadedData.projection_id);
            }
            break;
          case "csvFile":
            formData.set('type', csvUploadedData.type);
            formData.set('shape', this.dataSetSelectedType);
            formData.set('projection_id', csvUploadedData.projection_id);
            formData.set('projection', this.projectionFile);
            formData.set('geometry', this.gFieldValue.value);
            break;
          case "tabularFile":
            formData.set('type', tabularUploadedData.type);

        }
        formData.set('upfile', this.fileSelect)
        if (["vectorFile", "kmlFile", "kmzFile", "dxfFile", "geojsonFile", "csvFile"].includes(this.typeOfFile)) {
          this.apiService.getFormDataToken('fileUpload').subscribe((resp: any) => {
            if (resp && resp.success) {
              formData.append('csrf_token', resp.data.csrf_token);
              this.layerService.uploadLayer(this.typeOfFile, formData).subscribe(
                (resp: any) => {
                  this._store.dispatch(new HideLoaderAction());
                  this.sessionService.setLoadingText('');
                  if (resp && resp.success) {
                    // this.dialogRef.close({
                    //   value: 'upload',
                    //   status: true
                    // })
                    this.layerService.uploadPayloadSubject.next({value:'vectorUpload',status:true})
                  }
                },
                errUpload => {
                  this.sessionService.setLoadingText('');
                  this._store.dispatch(new HideLoaderAction());
                  this.count = 0;
                  if (
                    errUpload.error &&
                    errUpload.error.error &&
                    errUpload.error.error.length > 0
                  ) {
                    const errMessage = errUpload.error.error[0].message;
                    if (errMessage) {
                      this.toaster.error(errMessage);
                    } else {
                      this.toaster.error(
                        'Please upload valid shapes file'
                      );
                    }
                  } else {
                    this.toaster.error(
                      'Unable to upload shape file, please check the zip file and try  again'
                    );
                  }
                }
              );

            } else {
              return of({})
            }
          }, err => {
            return of(err)
          })

        }
        else if (this.typeOfFile === 'tabularFile') {
          let formdata = new FormData();
          formdata.set('file', this.fileSelect);
          formdata.set('name', tabularUploadedData.originalname);
          this.apiService.getFormDataToken('fileUpload').subscribe((resp: any) => {
            if (resp && resp.data) {
              formdata.append('csrf_token', resp.data.csrf_token);
            }
            this.uploadTabularLayer(formdata);
        })
        }
        else if (this.typeOfFile == "rasterFile") {
          let formdata = new FormData();
          formdata.set('upfile', this.fileSelect);
          formdata.set('type', rasterUploadedData.type);
          this.apiService.getFormDataToken('fileUpload').subscribe((resp: any) => {
            if (resp && resp.data) {
              formdata.append('csrf_token', resp.data.csrf_token);
              this.storeService.getRasterInfo({ rasterData: formdata }).subscribe((resp: any) => {
                if (resp && resp.success) {
                  this.fileInfo = resp.data.fileInfo
                  this.sessionService.setLoadingText('');
                  if ((resp.data.bands_count || 0) >= 3) {
                    // this.dialogRef.close();
                    this.layerService.uploadPayloadSubject.next({value:'rasterUpload', status:false})
                    const rasterDialogRef = this.dialog.open(RasterBandDialogComponent, {
                      data: {
                        rasterData: rasterUploadedData,
                        bandsCount: resp.data.bands_count,
                        fileInfo: Object.assign({}, this.fileInfo)
                      }
                    });
                    rasterDialogRef.afterClosed().subscribe((resp) => {
                      if (resp && resp.value === 'upload') {
                        this._store.dispatch(new ShowLoaderAction());
                        this.uploadRasterLayer({ ...this.fileInfo });
                        this._store.dispatch(new GetVectorLayersStartAction({ search: '' }));
                      }
                    })
                  }
                  else {
                    this.sessionService.setLoadingText('Uploading  Layer and it may take few minutes');
                    this.uploadRasterLayer({ ...this.fileInfo });

                  }
                }
              },
                errUpload => {
                  this.sessionService.setLoadingText('');
                  this._store.dispatch(new HideLoaderAction());
                  if (
                    errUpload.error &&
                    errUpload.error.error &&
                    errUpload.error.error.length > 0
                  ) {
                    const errMessage = errUpload.error.error[0].message;
                    if (errMessage) {
                      this.toaster.error(errMessage);
                    } else {
                      this.toaster.error(
                        'Please upload valid shapes file'
                      );
                    }
                  } else {
                    this.toaster.error(
                      'Unable to upload shape file, please check the raster file and try  again'
                    );
                  }
                })
            }
            return of({})

          })


        }
      }
    }
    else {
      alert('Please upload valid shape file to upload');
    }
  }


  uploadRasterLayer(rasterObj) {
    this.layerService.uploadRasterLayer(rasterObj).subscribe((resp: any) => {
      if (resp && resp.success) {
        this.sessionService.setLoadingText('');
        this._store.dispatch(new HideLoaderAction());
        // this.toaster.info('layer uploaded successfully');
        // this.dialogRef.close({ value: 'upload' });
        this.layerService.uploadPayloadSubject.next({value:'rasterUpload',status:true})

      }
    },
      errUpload => {
        this.sessionService.setLoadingText('');
        this._store.dispatch(new HideLoaderAction());
        this.dialog
        if (
          errUpload.error &&
          errUpload.error.error &&
          errUpload.error.error.length > 0
        ) {
          const errMessage = errUpload.error.error[0].message;
          if (errMessage) {
            this.toaster.error(errMessage);
          } else {
            this.toaster.error(
              'Please upload valid shapes file'
            );
          }
        } else {
          this.toaster.error(
            'Unable to upload shape file, please check the raster file and try  again'
          );
        }

      })
  }

  uploadTabularLayer(tabularObj) {
    this.layerService.uploadTabularLayer(tabularObj).subscribe((resp: any) => {
      if (resp && resp.success) {
        this.sessionService.setLoadingText('');
        this._store.dispatch(new HideLoaderAction());
        // this.toaster.info('layer uploaded successfully');
        // this.dialogRef.close({ value: 'tabularUpload' });
        this.layerService.uploadPayloadSubject.next({value:'tabularUpload',status:true})
      }
    },
      errUpload => {
        this.sessionService.setLoadingText('');
        this._store.dispatch(new HideLoaderAction());
        this.dialog
        if (
          errUpload.error &&
          errUpload.error.error &&
          errUpload.error.error.length > 0
        ) {
          const errMessage = errUpload.error.error[0].message;
          if (errMessage) {
            this.toaster.error(errMessage);
          } 
        } 
      })
  }


  projectionUploaded(event) {
    this.showDataSetSelection = true;
    this.projectionSelected = null;
    this.showProjectionUpload = false;
    this.myControl.setValue("");
    if (event.target.files && event.target.files.length) {
      this.projectionFile = event.target.files[0];
      this.projectionFileName = event.target.files[0].name;
      this.projectionFileUploaded = true;
      this.dataSetSelectedType = null;
      this.showDataSetSelection = true;
      this.gFieldValue.setValue(null);
      this.showGeometrySelection = false;
    }
  }

  removeProjectionFile() {
    this.showDataSetSelection = false;
    this.projectionSelected = null;
    this.showProjectionUpload = true;
    this.showProjectionSelection = true;
    this.projectionFileUploaded = false;
    this.projectionFile = null;
    this.projectionFileName = null;
    this.dataSetSelectedType = null;
    this.showDataSetSelection = false;
    this.gFieldValue.setValue(null);
    this.showGeometrySelection = false;
  }

  getHeaderArray(csvHeaders: any) {
    let headers = (<string>csvHeaders).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  setSelectedDataSet(dataset) {
    this.dataSetSelectedType = dataset;
    this.showGeometrySelection = true;
    this.isUploadActive=true
  }

  cancel(){
    // this.layerService.uploadPayloadSubject.next({status:false})
    this.dialogRef.close();
  }
}

export const DEFAULT_PROJECTION_PAYLOAD = {
  search: "",
  limit: 20,
  page: 1
}
