import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { FormControl, Validators } from '@angular/forms';
import { Observable, Subject, of, Subscription } from 'rxjs';
import { map, startWith, takeUntil, tap, filter } from 'rxjs/operators';
import { SaveCollectedDataRowDetailsStartAction, SessionManagementTypes } from 'src/app/store/actions/session-management.actions';
import { NgxSpinnerService } from "ngx-spinner";
import { LayerService, API } from 'src/app/_services';
import { InfoDialogComponent } from 'src/app/dialogs/info-dialog/info-dialog.component';
import { TableDialogComponent } from 'src/app/dialogs/table-dialog/table-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/_services/session.service';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';
import { buildingDataSelector } from 'src/app/store/selectors/data-approval-management.selector';
import { GetLayerTableDataStartAction, UpdateBuildingDataAction, GetBuildingsDataStartAction, UpdatePremiseDataAction, GetLayerPremiseTableDataStartAction } from '../../store/actions/data-approval-management.actions'
import * as _ from 'underscore';
import { cloneDeep } from 'lodash'
import { ObjectPickerDialogComponent } from '../object-picker-dialog/object-picker-dialog.component';
export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MMMM-YYYY",
  },
  display: {
    dateInput: "DD-MMMM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-data-approval-edit-dialog',
  templateUrl: './data-approval-edit-dialog.component.html',
  styleUrls: ['./data-approval-edit-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class DataApprovalEditDialogComponent implements OnDestroy {
  formTemplateWidgets: Array<form_template_widget> = [];
  originalRowData: any;
  ratingRadio: Array<any> = [
    { value: 'Star', text: 'Star' },
    { value: 'Emoji', text: 'Emoji' },
    { value: 'Progress Bar', text: 'Progress Bar' }
  ]
  starRatings: Array<string> = ["Terrible", "Bad", "Good", "Very Good", "Great"];
  emojiRatingImages: Array<string> = ["angry_2x", "sad@2x", "happy_5_@2x", "happy_8_@2x", "happy_6_@2x"];
  emojiRatingClasses: Array<string> = ["ocol", "ocl2", "ocl3", "ocol4", "ocol5"];
  myControl = new FormControl();
  addedFiles: Array<any> = [];
  addedFileId: number = 0;
  uploadedFilesInfo: Array<any> = [];
  totalFileCount: number = 0;
  uploadedFileCount: number = 0;
  uploadStatus = true;
  allowed_image_types = ['jpeg', 'png', 'jpg'];
  allowed_audio_types = ['x-wav', 'wav', 'wave', 'mp3'];
  allowed_video_types = ['mp4', 'mkv', 'vlc'];
  allowed_doc_types = ['msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'vnd.ms-powerpoint', 'vnd.openxmlformats-officedocument.presentationml.presentation', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'pdf', 'zip'];
  allowed_text_types = ['plain', 'csv'];
  result: boolean;
  inputDisable = true;
  uploadStatusMessage = "";
  stopper$: Subject<any> = new Subject();
  closeEditWindow = false;
  invalidNumber: boolean;
  date: FormControl;
  firstIndex: boolean;
  conditionsWidgetsObj: any = {};
  widgetConditionsEvaluationObj: any = {};
  textLength: boolean = false;
  infoDialog: any;
  calculationwidgetOperandObject: any
  operandObject: any;
  arr: any = [];
  numberArray: any = [];
  numberObject: any = {};
  filesToUpload: any = [];
  exceedMaxValue = false;
  exceedMinValue = false;
  buildingData: string;
  buildingId: any;
  type: any;
  serverResp: any = [];
  singleImageRotationDegree: {};
  subscription: Subscription
  constructor(public dialogRef: MatDialogRef<DataApprovalEditDialogComponent>,
    private sessionService: SessionService,
    private layerService: LayerService,
    private DataApprovalManagementService: DataApprovalManagementService,
    private _store: Store<any>,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private _actions$: Actions,
    private apiService: API,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // this._store.dispatch(new GetBuildingsDataStartAction(this.data.id));
    this.type = this.data.type
    // console.log(this.data,"type")
    this.subscription = this.DataApprovalManagementService.getBuildingsData(this.data.id).pipe(takeUntil(this.stopper$)).subscribe((formData: any) => {
      this.spinner.hide();
      let data = formData.data
      if (data.length != 0) {
        // console.log(data,"data")
        this.serverResp = cloneDeep(data);
        this.originalRowData = data;
        this.formTemplateWidgets = [];
        this.addedFiles = [];
        this.addedFileId = 0;
        this.uploadStatusMessage = "";
        this.closeEditWindow = false;
        this.conditionsWidgetsObj = {};
        this.widgetConditionsEvaluationObj = {};
        let collectedData = data[0].dataCollection.collected_data;
        let disabledIds: any[] = [];
        // console.log(collectedData,"data")
        if (data[0].formTemplate.form_template_widgets && data[0].formTemplate.form_template_widgets.length) {
          let allWidgetIdLabelMap: any = {};
          let hiddenWidgetIds = {};
          _.each(data[0].hiddenWigetIds, wid => hiddenWidgetIds[wid] = true);
          data[0].formTemplate.form_template_widgets.map(widget => allWidgetIdLabelMap[widget.id] = widget.label);
          data[0].formTemplate.form_template_widgets.forEach(element => {
            if (hiddenWidgetIds[element.id] == true) {
              element.disabled = true; // disable field if non editable
            }
            if (element.options_obj && element.options_obj.options) {
              element.options_obj.options.forEach((option: any) => {
                if (option.url) {
                  option.url = option.url.includes(data[0].formTemplate.upload_file_base_path) ?
                    option.url : `${data[0].formTemplate.upload_file_base_path}${data[0].formTemplate.upload_file_path}/${option.url}`
                }
                if (['multiple_choice', 'multiple_image_choice'].includes(element.form_widget_type)) {
                  if (data[0].dataCollection.collected_data && data[0].dataCollection.collected_data[element.id]
                    && data[0].dataCollection.collected_data[element.id].includes(option.id)) {
                    option.checked = true;
                  }
                  else option.checked = false;
                }
                if (['single_choice', 'single_image_choice'].includes(element.form_widget_type)) {
                  if (data[0].dataCollection.collected_data &&
                    data[0].dataCollection.collected_data[element.id] === option.id) {
                    option.checked = true;
                  } else {
                    option.checked = false;
                  }
                }
              });
            }
            let value: any = null;
            let files: any = null;
            let annotatedImage: any = null;
            let file_name: string = null;
            let idValueMap: any = {};
            let autoFillWidgets: Array<any> = [];
            let degree: number | number[];
            if (!this.checkIfEmpty(data[0].dataCollection.collected_data[element.id] && typeof data[0].dataCollection.collected_data == "object")) {
              value = data[0].dataCollection.collected_data[element.id]
            } else {
              value = ''
            }
            switch (element.form_widget_type) {
              case 'text':
                if (element.code === 'BLID') {
                  element.disabled = true;
                }
                break;
              case "signature":
              case "single_image":
              case "audio":
              case "video":
              case "document":
                const val = data[0].dataCollection.collected_data &&
                  data[0].dataCollection.collected_data[element.id] ?
                  data[0].dataCollection.collected_data[element.id] : null;

                if (val) {
                  value = `${data[0].formTemplate.upload_file_base_path}${data[0].formTemplate.upload_file_path}/${val}`;
                  file_name = val;
                }
                degree = 0; // degree of image rotation;

                break;
              case "mobile":
                if (element.with_isd) {
                  value = data[0].dataCollection.collected_data &&
                    data[0].dataCollection.collected_data[element.id] ?
                    data[0].dataCollection.collected_data[element.id].split("-") : [0, 0];
                  if (value.length == 1) {
                    value = [0, ...value];
                  }
                } else {
                  value = data[0].dataCollection.collected_data &&
                    data[0].dataCollection.collected_data[element.id] ?
                    data[0].dataCollection.collected_data[element.id].split("-") : [0];
                }
                break;

              case "multiple_images":
                files = [];
                degree = [];
                value = (data[0].dataCollection.collected_data &&
                  data[0].dataCollection.collected_data[element.id] &&
                  data[0].dataCollection.collected_data[element.id].length) ?
                  data[0].dataCollection.collected_data[element.id].map(val => {
                    files.push(val);
                    return `${data[0].formTemplate.upload_file_base_path}${data[0].formTemplate.upload_file_path}/${val}`
                  }) : [];

                if (value.length) {
                  (degree = []).length = value.length; degree.fill(0);
                }
                break;
              case "boolean_choice":
                value = (data[0].dataCollection.collected_data &&
                  data[0].dataCollection.collected_data[element.id] ? true : false)
                break;
              case "feature_detection":
                if (data[0].dataCollection.collected_data[element.id] != undefined && data[0].dataCollection.collected_data[element.id].rawImage != "") {
                  annotatedImage = `${data[0].formTemplate.upload_file_base_path}${data[0].formTemplate.upload_file_path}/${data[0].dataCollection.collected_data[element.id].annotatedImage}`

                  value = data[0].dataCollection.collected_data[element.id]
                }
                break;
              case "single_choice_auto_suggest":
                idValueMap[element.id] = collectedData[element.id] || null;
                //disabledIds.push(...Object.keys(element.auto_fill_data));
              // autoSuggestValues = element.options_obj.options.map(option => option.value);
              // element.options_obj.options.map(option => idValueMap[option.id] = option.value);
              // this.myControl.setValue(idValueMap[value]);
              // if (element.auto_fill) {
              //   const widgetIds = Object.keys(element.options_obj.options[0].auto_fill_data);
              //   widgetIds.map(id => autoFillWidgets.push(allWidgetIdLabelMap[id]));
              // }

              default:

            }

            let min_value: any = null
            let max_value: any = null

            if (element.properties) {
              max_value = element.properties.max_value ? element.properties.max_value : null
            } else {
              max_value = element.max_value
            }
            if (element.properties) {
              min_value = element.properties.min_value ? element.properties.min_value : null
            } else {
              min_value = element.min_value
            }

            // console.log("value: ", value);
            const widgetProps: any = {
              is_editted: false,
              is_editable: true,
              is_valid: true,
              show_value: true,
              code: element.code || '',
              form_widget_type: element.form_widget_type || '',
              icon: element.icon || '',
              id: element.id,
              label: element.label || '',
              options: (element.options_obj && element.options_obj.options) ? element.options_obj.options : [],
              conditions: (element.conditions && element.conditions.length) ? element.conditions : [],
              required: (element.required) ? element.required : false,
              with_isd: element.with_isd || false,
              value: value,
              operand_1: element.operand_1 ? data[0].formTemplate.form_template_widgets.find(widget => widget.id === element.operand_1).label : '',
              operand_2: element.operand_2 ? data[0].formTemplate.form_template_widgets.find(widget => widget.id === element.operand_2).label : '',
              operator: element.operator || '',
              rating_type: element.rating_type || '',
              max_value: max_value,
              min_value: min_value,
              max_date: (element.max_date) ? element.max_date : '',
              min_date: (element.min_date) ? element.min_date : '',
              steps: (element.properties && element.properties.steps) ? element.properties.steps : 1,
              // auto_suggest_unique_values: autoSuggestValues,
              auto_suggest_id_value_map: idValueMap,
              auto_fill_widgets: autoFillWidgets,
              file_name: file_name,
              disabled: element.disabled,
              annotatedImage: annotatedImage,
              // filteredOptions: idValueMap ? this.myControl.valueChanges.pipe(
              //   startWith(''),
              //   map(value => this._filter(value, autoSuggestValues))
              // ) : undefined,
              degree,
              disabledSingleChoiceAutoSuggestCondition: disabledIds.includes(element.id),
              files
            };
            this.formTemplateWidgets.push(widgetProps);
          });
          this.createConditinsWidgetsObj(data);
          this.createCalculationWidgetObject(data[0].formTemplate.form_template_widgets);
          this.createNumberObject(data[0].formTemplate.form_template_widgets);
          this.createWidgetConditionsEvaluationObj();
          this.setShowValueForWidgets();
        }
      }
    })
    this._actions$.pipe(
      ofType(SessionManagementTypes.saveCollectedDataRowDetailsSuccess),
      takeUntil(this.stopper$),
      tap((response: any) => {
        this.uploadStatusMessage = "";
        this.spinner.hide();
        if (this.closeEditWindow) this.sessionService.setDataFormEdit(false);
      })
    ).subscribe();




  }

  // check if required
  dataFormClose() {
    this.sessionService.setDataFormEdit(false)
  }
  dataFormOpen() {
    this.sessionService.setDataFormEdit(true)
  }

  setWidgetValue(event, widget) {
    // console.log("widgetType:: ", widget.form_widget_type);
    // console.log("event: ", event);
    // Check for widget validation...
    // If valid, set value for the widget
    // Then, check for conditions and findout what other widgets get affected due to the change...
    switch (widget.form_widget_type) {
      case "text":
        let trimmedtext = event.srcElement.value.trim();
        if (widget.required) {

          if (!trimmedtext.length) {

            widget.is_valid = false;
            this.textLength = false;
          }
          else {
            if (widget.max_value) {

              if (widget.max_value >= trimmedtext.length) {
                widget.is_valid = true;
                widget.value = trimmedtext;
                this.textLength = false;
              }
              else {
                widget.is_valid = false;
                this.textLength = true;
              }
            }
            else {
              widget.is_valid = true;
              widget.value = trimmedtext;
            }

          }
        }
        else {
          if (!trimmedtext.length) {

            widget.is_valid = true;
            widget.value = trimmedtext;
          }
          else {
            if (widget.max_value) {
              if (widget.max_value >= trimmedtext.length) {
                widget.is_valid = true;
                widget.value = trimmedtext;
                this.textLength = false;
              }
              else {
                widget.is_valid = false;
                this.textLength = true;
              }
            } else {
              widget.is_valid = true;
              widget.value = trimmedtext;
            }
          }


        }
        break;
      case "number":
        let trimmedNum: string = event.srcElement.value.trim();
        if (widget.required) {
          if (!trimmedNum.length) {
            widget.is_valid = false;
          }
          else {
            var pattern = /^-?[0-9\s]*$/;
            if (pattern.test(trimmedNum)) {
              let trimmednum: number = parseInt(trimmedNum);
              if (widget.max_value != null && widget.min_value != null) {

                if (trimmednum <= widget.max_value) {
                  if (trimmednum >= widget.min_value) {

                    widget.value = trimmednum;
                    widget.is_valid = true;
                  }
                  else {
                    this.invalidNumber = true;
                    widget.is_valid = false;
                    this.exceedMinValue = true
                    this.exceedMaxValue = false
                  }
                }
                else {
                  this.invalidNumber = true;
                  widget.is_valid = false;
                  this.exceedMaxValue = true
                  this.exceedMinValue = false
                }
              }
              else {
                if (widget.max_value != null) {
                  if (trimmednum <= widget.max_value) {
                    widget.value = trimmednum;
                    widget.is_valid = true;
                  } else {
                    this.invalidNumber = true;
                    widget.is_valid = false;
                    this.exceedMaxValue = true
                    this.exceedMinValue = false
                  }
                } else {
                  widget.value = trimmednum;
                  widget.is_valid = true;
                }
              }
            }
            else {
              // widget.is_valid = false;
              if (widget.label.includes("floor number")) {

                widget.is_valid = false
              } else {
                widget.value = trimmedNum;
                widget.is_valid = true
              }
            }
          }
        }
        else {
          if (!trimmedNum.length) {
            widget.is_valid = true;
            widget.value = +trimmedNum;
          }
          else {
            var pattern = /^-?[0-9\s]*$/;
            if (pattern.test(trimmedNum)) {
              let trimmednum: number = parseInt(trimmedNum);
              if (widget.max_value != null && widget.min_value != null) {
                if (trimmednum <= widget.max_value) {
                  if (trimmednum >= widget.min_value) {
                    widget.value = trimmednum;
                    widget.is_valid = true;
                  }
                  else {
                    this.invalidNumber = true;
                    widget.is_valid = false;
                    this.exceedMinValue = true
                    this.exceedMaxValue = false
                  }
                }
                else {
                  this.invalidNumber = true;
                  widget.is_valid = false;
                  this.exceedMaxValue = true
                  this.exceedMinValue = false
                }
              }
              else {
                if (widget.max_value != null) {
                  if (trimmednum <= widget.max_value) {
                    widget.value = trimmednum;
                    widget.is_valid = true;
                  } else {
                    this.invalidNumber = true;
                    widget.is_valid = false;
                    this.exceedMaxValue = true
                    this.exceedMinValue = false
                  }
                } else {
                  widget.value = trimmednum;
                  widget.is_valid = true;
                }
              }
            }
            else {
              //  if widget label includes floor number then dont allow decimal values 
              // widget.is_valid = false;
              let pattern = /^[1-9]\d*(\.\d+)?$/
              if (widget.label.includes("floor number")) {
                widget.is_valid = false
              } else if (pattern.test(trimmedNum)) {
                widget.value = trimmedNum;
                widget.is_valid = true
              } else {
                widget.value = trimmedNum;
                widget.is_valid = false
              }
            }
          }
        }
        let calculationArr = this.formTemplateWidgets.filter((obj) => obj.form_widget_type == 'calculation');
        let numberArr = this.formTemplateWidgets.filter((obj) => obj.form_widget_type == 'number');
        if (widget.is_valid) {
          if (this.numberObject[widget.id] != undefined) {
            let firstValue = 0;
            let secondValue = 0;
            this.numberObject[widget.id].forEach((calcId) => {
              let requiredCalculationObj: any = calculationArr.filter((obj) => obj.id === calcId);
              let operandObj = this.calculationwidgetOperandObject[calcId];
              numberArr.forEach((obj) => {
                if ((obj.id === operandObj.operand_1) && (obj.id === operandObj.operand_2)) {
                  firstValue = +obj.value;
                  secondValue = +obj.value

                }
                else {

                  if (obj.id === operandObj.operand_1) {
                    if (obj.is_valid) {
                      firstValue = +obj.value;
                    }
                  }
                  else if (obj.id === operandObj.operand_2) {
                    if (obj.is_valid) {
                      secondValue = +obj.value;
                    }
                  }
                }
              })
              if (operandObj.operator === '+') {
                requiredCalculationObj[0].value = firstValue + secondValue;
              }
              else if (operandObj.operator === '-') {
                requiredCalculationObj[0].value = firstValue - secondValue
              }
              else if (operandObj.operator === '-') {
                requiredCalculationObj[0].value = firstValue * secondValue;
              }
              else if (operandObj.operator === '/') {
                requiredCalculationObj[0].value = firstValue / secondValue;
              }
              else if (operandObj.operator === '%') {
                requiredCalculationObj[0].value = firstValue % secondValue;
              }
            })
          }
        }
        break;
      case "qr_bar_code":


        break;
      case "feature_detection":

        break;
      case "single_choice":
      case "single_image_choice":
        widget.value = event.value;
        widget.options.map(option => {
          if (option.id === event.source.value) option.checked = true;
          else option.checked = false;
        })
        break;
      case "multiple_choice":
      case "multiple_image_choice":
        if (Array.isArray(widget.value) && widget.value.length) {
          const index = widget.value.indexOf(event.source.value);
          if (event.source.checked) {
            if (!(index > -1)) {
              widget.value = [...widget.value, event.source.value];
            }
          } else {
            widget.value.splice(index, 1);
          }
        } else {
          if (event.source.checked) widget.value = [event.source.value]
        }
        widget.options.find(option => option.id === event.source.value).checked = event.source.checked;
        break;
      case "boolean_choice":
        console.log(event.value, "value");
        switch (event.value) {

          case 'true':
            widget.value = true;
            widget.is_valid = true
            break;
          case 'false':
            widget.is_valid = true;
            widget.value = false;
            break;
        }
        break;
      case "date":
        widget.value = this.formatDate(event.value)
        break;
      case "time":
      case "rating":
        widget.value = event;
        break;
      case "single_choice_auto_suggest":
        //doesn't enter into this case
        const ind = Object.values(widget.auto_suggest_id_value_map).indexOf(event.option.value);
        widget.value = Object.keys(widget.auto_suggest_id_value_map)[ind];
        break;
      case "mobile":
        widget.is_valid = false;
        this.firstIndex = widget.with_isd;
        let code = null;
        let phoneNumber = null;
        let codeValid = false;
        let phoneValid = false;
        let codeRegex = new RegExp("^[0-9]{1,5}$");
        let phoneRegex = new RegExp("^[0-9]{4,10}$");

        if (widget.value.length > 0) {
          if (widget.value.length > 1) {
            code = widget.value[0] ? widget.value[0].trim() : widget.value[0];
            phoneNumber = widget.value[1] ? widget.value[1].trim() : widget.value[1];
          } else if (widget.value.length == 1) {
            phoneNumber = widget.value[0] ? widget.value[0].trim() : widget.value[0];
          }
          codeValid = code && codeRegex.test(code);
          phoneValid = phoneNumber && phoneRegex.test(phoneNumber);
        }
        if (widget.required) {
          if (widget.with_isd && codeValid && phoneValid) {
            widget.is_valid = true;
            this.firstIndex = false;
          } else if (widget.with_isd && codeValid) {
            this.firstIndex = false;
          } else if (!widget.with_isd && phoneValid) {
            widget.is_valid = true;
            this.firstIndex = false;
          }
        } else {
          widget.is_valid = true;
          if (phoneNumber && phoneNumber.length > 0 && !phoneValid) {
            widget.is_valid = false;
            this.firstIndex = false;
          }
          if (code && code.length > 0 && !codeValid) {
            widget.is_valid = false;
            this.firstIndex = true;
          }
        }

        if (widget.with_isd) {
          widget.value[0] = code;
          widget.value[1] = phoneNumber;
        } else {
          widget.value = [phoneNumber];
        }
    }
    this.createWidgetConditionsEvaluationObj();
    this.setShowValueForWidgets(widget.id);
  }
  private _filter(value: string, options): string[] {
    if (!value) return options;
    const filterValue = value.toLowerCase();

    return options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  setShowValueForWidgets(widgetId?) {
    let targetWidgets = [];
    if (widgetId) {
      Object.keys(this.conditionsWidgetsObj).forEach(key => {
        if (this.conditionsWidgetsObj[key].indexOf(widgetId) > -1) {
          targetWidgets.push(key);
        }
      })
    } else {
      targetWidgets = Object.keys(this.conditionsWidgetsObj);
    }
    if (!targetWidgets.length) return;
    targetWidgets.forEach(id => {
      const widget = this.formTemplateWidgets.find(widget => widget.id === id);
      if (widget) {
        try {
          // console.log(widget);
          // console.log(this.widgetConditionsEvaluationObj[id]);
          widget.show_value = eval(this.widgetConditionsEvaluationObj[id]);
        } catch (error) {
          // console.log(error);
        }
      }
    })
  }

  createConditinsWidgetsObj(data) {
    data[0].formTemplate.form_template_widgets.forEach(widget => {
      if (widget.conditions && widget.conditions.length) {
        let dependentWidgets = [];
        widget.conditions.forEach(condition => {
          if (condition.operand_1) dependentWidgets.push(condition.operand_1);
        });
        this.conditionsWidgetsObj[widget.id] = dependentWidgets;
      }
    });
  }

  createCalculationWidgetObject(data) {
    this.calculationwidgetOperandObject = Object.assign([], data);
    let calculateWidgets: any = {};
    this.calculationwidgetOperandObject = this.calculationwidgetOperandObject.reduce((acc, curr: any) => {
      if (curr.form_widget_type == "calculation") {
        acc[curr.id] = { operand_1: curr.operand_1, operand_2: curr.operand_2, operator: curr.operator };
      }
      return acc
    }, calculateWidgets)
  }

  createNumberObject(data) {
    let numberArray = data.filter((obj) => obj.form_widget_type === 'number');
    let calArray = data.filter((obj) => obj.form_widget_type === 'calculation');
    numberArray.forEach((numObj) => {
      let numArray = [];
      calArray.forEach((calObj) => {
        if (calObj.operand_1 === numObj.id || calObj.operand_2 === numObj.id) {
          numArray.push(calObj.id);
          this.numberObject[numObj.id] = numArray;
        }
      })
    })
  }

  createWidgetConditionsEvaluationObj() {
    let widgetValueObj: any = {};
    const stringWidgets = ['text', 'static_text', 'qr_bar_code', 'single_choice', 'single_image_choice', 'date', 'audio', 'video', 'signature'];
    this.formTemplateWidgets.forEach(widget => {
      widgetValueObj[widget.id] = widget.value
      if (stringWidgets.indexOf(widget.form_widget_type) > -1) {
        widgetValueObj[widget.id] = `${widget.value}`;
      }
    });
    let widgetIdTypeObj: any = {};
    this.formTemplateWidgets.forEach(widget => widgetIdTypeObj[widget.id] = widget.form_widget_type);
    this.formTemplateWidgets.forEach(widget => {
      if (widget.conditions && widget.conditions.length) {
        let wcArray: Array<string> = [];
        widget.conditions.forEach(condition => {
          let operand1 = widgetValueObj[condition.operand_1], operand2 = condition.operand_2;
          if (isNaN(operand1)) {
            operand1 = `'${operand1}'`;
          }
          if (isNaN(operand2)) {
            operand2 = `'${operand2}'`;
          }
          if (condition.type) {
            switch (condition.type) {
              case 'and':
                wcArray.push(' && ');
                break;
              case 'or':
                wcArray.push(' || ');
                break;
            }
          }
          switch (condition.operator) {
            case 'is':
              wcArray.push(`((${operand1} !== '') && (${operand1} !== null) && (${operand1} !== undefined) && (${operand1} !== []))`);
              break;
            case 'in':
              wcArray.push(`((${operand1} === '') || (${operand1} === null) || (${operand1} === undefined) || (${operand1} === []))`);
              break;
            case 'between':
              break;
            case 'eq':
              if (typeof operand1 === 'string' || operand1 instanceof String) {
                if (operand1.includes(",")) {
                  wcArray.push(`(${operand1}.includes(${operand2}))`);
                }
                else {
                  wcArray.push(`(${operand1} === ${operand2})`);
                }
              }
              else {
                wcArray.push(`(${operand1} === ${operand2})`);
              }
              break;
            case 'ne':
              wcArray.push(`(${operand1} !== ${operand2})`);
              break;
            case 'lt':
              wcArray.push(`(${operand1} < ${operand2})`);
              break;
            case 'lte':
              wcArray.push(`(${operand1} <= ${operand2})`);
              break;
            case 'gt':
              wcArray.push(`(${operand1} > ${operand2})`);
              break;
            case 'gte':
              wcArray.push(`(${operand1} >= ${operand2})`);
              break;
            case 'after':
              break;
            case 'before':
              break;
            case 'contains':
              wcArray.push(`(${operand1}.includes(${operand2}))`);
              break;
          }
        });
        this.widgetConditionsEvaluationObj[widget.id] = wcArray.join('');
      }
    });
  }

  onDeleteClicked(widget, fileUrl) {
    const fileName = fileUrl.split("/").pop();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      role: 'dialog',
      width: '400px',
      data: {
        alertTitle: 'Confirm',
        confirmText: 'Yes',
        alertText: `Do you want to delete this file?`
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        const widgetId = widget.id;
        this.filesToUpload = this.filesToUpload.filter((fileInfo) => fileInfo.id !== widgetId)
        if (widget.form_widget_type === "multiple_images") {
          const indInVal = widget.value.indexOf(fileUrl);
          if (indInVal > -1) {
            widget.value.splice(indInVal, 1);
            widget.degree.splice(indInVal, 1);
            widget.files.splice(indInVal, 1);
          }
          if (this.originalRowData[0].dataCollection.collected_data &&
            this.originalRowData[0].dataCollection.collected_data[widgetId]) {
            const ind = this.originalRowData[0].dataCollection.collected_data[widgetId].indexOf(fileName);
            if (ind > -1) {
              this.originalRowData[0].dataCollection.collected_data[widgetId].splice(ind, 1);
            }
          }
        } else {
          delete this.originalRowData[0].dataCollection.collected_data[widgetId];
          delete widget.value;
          delete widget.degree;
          if (widget.file_name) {
            widget.file_name = null
          };
        }
      }
    })
  }

  onSave() {
    let widgetArray = this.formTemplateWidgets.filter((widget, i) => {
      if (!widget.is_valid) {

        return widget;
      }
    })

    let requiredArr = this.formTemplateWidgets.filter((widget) => {
      if (widget.required) {
        if (widget.form_widget_type == "number") {
          if (widget.value == null) {
            widget.is_valid = false;
            return widget;
          }
        } else {
          if (widget.value == " ") {
            widget.is_valid = false;
            return widget;
          }
        }

      }
    })

    if (widgetArray.length || requiredArr.length) {
      this.infoDialog = this.dialog.open(InfoDialogComponent, {
        hasBackdrop: true,
        data: {
          alertTitle: "Invalid Data",
          alertText: "please check the details that you've entered."
        }
      });
    }
    else {
      if (!this.originalRowData.collected_data) {
        this.originalRowData.collected_data = {};
      }

      // if image is rotated then rotate the image and save it
      // let imageRotationDegree = {};
      let collected_data_ids = this.originalRowData[0].dataCollection.collected_data && Object.keys(this.originalRowData[0].dataCollection.collected_data) ? Object.keys(this.originalRowData[0].dataCollection.collected_data) : [];
      this.formTemplateWidgets.filter((widget) => (widget.required || widget.show_value)).forEach(widget => {
        const index = collected_data_ids.indexOf(widget.id);
        if (index > -1) {
          switch (widget.form_widget_type) {
            case "mobile":
              let value = null;
              if (widget.value && widget.value.length > 0) {
                let code = null;
                let phoneNumber = null;
                if (widget.value.length > 1) {
                  code = widget.value[0];
                  phoneNumber = widget.value[1];
                } else if (widget.value.length == 1) {
                  phoneNumber = widget.value[0];
                }
                if (widget.with_isd) {
                  if (code && phoneNumber) {
                    value = code + '-' + phoneNumber;
                  } else if (phoneNumber) {
                    value = phoneNumber;
                  } else if (code) {
                    value = code + '-';
                  }
                } else if (phoneNumber) {
                  value = phoneNumber;
                } else if (code) {
                  value = code;
                }
              }
              this.originalRowData.collected_data[widget.id] = value;
              break;
            case "signature":
            case "single_image":
              // imageRotationDegree[widget.id] = widget.degree;
              let widgetId = widget.id
              let degree = widget.degree
              let obj = { [widgetId]: degree }
              this.singleImageRotationDegree = { ...obj, ...this.singleImageRotationDegree };
            // this.singleImageRotationDegree[widget.id] = widget.degree;
            case "audio":
            case "video":
            case "document":
              // if file changed send new uploaded file else send old file
              if (this.filesToUpload.length > 0) {
                this.filesToUpload.forEach((fileInfo) => {
                  if (widget.id === fileInfo.id) {
                    this.originalRowData.collected_data[widget.id] = fileInfo.file
                  } else {
                    if (!this.checkIfEmpty(this.originalRowData[0].dataCollection.collected_data[widget.id])) {
                      this.originalRowData.collected_data[widget.id] = this.originalRowData[0].dataCollection.collected_data[widget.id]
                    }

                  }
                })
              } else {
                if (!this.checkIfEmpty(this.originalRowData[0].dataCollection.collected_data[widget.id])) {
                  this.originalRowData.collected_data[widget.id] = this.originalRowData[0].dataCollection.collected_data[widget.id];
                }
              }
              break;
            case "multiple_images":
              if (widget.value) {
                this.originalRowData.collected_data[widget.id] = [];
                // imageRotationDegree[widget.id] = [];
                let widgetId = widget.id
                let degree = widget.degree
                let obj = { [widgetId]: degree }
                this.singleImageRotationDegree = { ...obj, ...this.singleImageRotationDegree };
                // this.singleImageRotationDegree[widget.id] = [];
                widget.value.filter((file, index) => {
                  if (!this.checkIfEmpty(file) && typeof file == "string") {
                    this.originalRowData.collected_data[widget.id].push(widget.files[index]);
                    // imageRotationDegree[widget.id].push(widget.degree[index])
                    this.singleImageRotationDegree[widget.id].push(widget.degree[index])
                  }
                })
              }
              // if (this.filesToUpload.length > 0) {
              //   this.filesToUpload.forEach((fileInfo) => {
              //     if (widget.id === fileInfo.id) {
              //       multipleImages.push(fileInfo.file)
              //     } else {
              //       if (!this.checkIfEmpty(this.originalRowData[0].dataCollection.collected_data[widget.id])) {
              //         multipleImages = this.originalRowData[0].dataCollection.collected_data[widget.id]
              //       }
              //     }
              //   })
              //   this.originalRowData.collected_data[widget.id] = multipleImages
              // } else {
              //   const files = this.originalRowData[0].dataCollection.collected_data[widget.id];
              //   if (files) {
              //     files.filter((file) => {
              //       if (!this.checkIfEmpty(file) && typeof file == "string") {
              //         this.originalRowData.collected_data[widget.id].push(file);
              //       }
              //     })
              //   }
              // }
              break;
            case "feature_detection":
              this.originalRowData.collected_data[widget.id] = widget.value
              break;
            case "date":
              if (widget.value != "") {
                this.originalRowData.collected_data[widget.id] = widget.value;
                break;
              }
            default:
              this.originalRowData.collected_data[widget.id] = widget.value;
          }
        } else {
          const widgetsToExclude = ["signature", "single_image", "audio", "video", "document", "multiple_images"];
          if (!(widgetsToExclude.indexOf(widget.form_widget_type) > -1)) {
            if (widget.form_widget_type === "mobile") {
              let value = null;
              if (widget.value && widget.value.length > 0) {
                let code = null;
                let phoneNumber = null;
                if (widget.value.length > 1) {
                  code = widget.value[0];
                  phoneNumber = widget.value[1];
                } else if (widget.value.length == 1) {
                  phoneNumber = widget.value[0];
                }
                if (widget.with_isd) {
                  if (code && phoneNumber) {
                    value = code + '-' + phoneNumber;
                  } else if (phoneNumber) {
                    value = phoneNumber;
                  } else if (code) {
                    value = code + '-';
                  }
                } else if (phoneNumber) {
                  value = phoneNumber;
                } else if (code) {
                  value = code;
                }
              }
              this.originalRowData.collected_data[widget.id] = value;
            } else {
              if (widget.form_widget_type == "date") {
                if (widget.value != "")
                  this.originalRowData.collected_data[widget.id] = widget.value;
              } else {
                this.originalRowData.collected_data[widget.id] = widget.value;
              }
            }
          } else {
            if (widget.form_widget_type == "multiple_images") {

              // let multipleImages = []
              // this.filesToUpload.forEach((fileInfo) => {
              //   if (widget.id === fileInfo.id)
              //     multipleImages.push(fileInfo.file)
              // })
              // this.originalRowData.collected_data[widget.id] = multipleImages;
              // imageRotationDegree[widget.id] = widget.degree;
              if (widget.value) {
                this.originalRowData.collected_data[widget.id] = [];
                // imageRotationDegree[widget.id] = [];
                let id = widget.id
                let obj = { [id]: '' }
                this.singleImageRotationDegree = { ...obj, ...this.singleImageRotationDegree };
                this.singleImageRotationDegree[widget.id] = [];
                widget.value.filter((file, index) => {
                  if (!this.checkIfEmpty(file) && typeof file == "string") {
                    this.originalRowData.collected_data[widget.id].push(widget.files[index]);
                    // imageRotationDegree[widget.id].push(widget.degree[index])
                    this.singleImageRotationDegree[widget.id].push(widget.degree[index])
                  }
                })
              }
            } else {
              if (this.filesToUpload.length > 0) {
                this.filesToUpload.forEach((fileInfo) => {
                  if (widget.id === fileInfo.id) {
                    this.originalRowData.collected_data[widget.id] = fileInfo.file
                  }
                  else {
                    if (!this.checkIfEmpty(this.originalRowData[0].dataCollection.collected_data[widget.id]))
                      this.originalRowData.collected_data[widget.id] = this.originalRowData[0].dataCollection.collected_data[widget.id];
                  }
                })
              } else {
                const files = this.originalRowData[0].dataCollection.collected_data[widget.id];
                if (files) {
                  files.filter((file) => {
                    if (!this.checkIfEmpty(file) && typeof file == "string") {
                      this.originalRowData.collected_data[widget.id].push(file);
                    }
                  })
                }
              }
            }
          }
        }
      })
      this.uploadStatusMessage = "Saving updated data...";
      let imageRotationDegree = { ...this.singleImageRotationDegree }
      let buildingDataObj = {
        data: this.originalRowData.collected_data,
        location: this.originalRowData[0].dataCollection.location.coordinates,
        imageRotationDegree
      }
      buildingDataObj.data = this.originalRowData.collected_data;
      let assessmentStatusObj = this.serverResp[0].formTemplate.form_template_widgets.find(({ label }) => label.toLowerCase() === 'ASSESSMENT STATUS'.toLowerCase());
      let assessment_status_changed: boolean = false;
      if (assessmentStatusObj && Object.keys(assessmentStatusObj).length) {
        let id = assessmentStatusObj.id;
        let optionId = this.serverResp[0].dataCollection.collected_data[id] || '' // value that was previously checked

        let previouslySelectedObj = assessmentStatusObj.options_obj.options.find(({ id }) => id == optionId) || { value: '' } // previously selectedalue currentChecked

        let currentCheckedId = this.originalRowData.collected_data[id] || ''
        let currentselectedObj = this.originalRowData[0].formTemplate.form_template_widgets.find(({ label }) => label.toLowerCase() === 'ASSESSMENT STATUS'.toLowerCase()).options_obj.options.find(({ id }) => id === currentCheckedId) || { value: '' }

        // if (currentselectedObj && currentselectedObj.value == 'Done' && previouslySelectedObj.value !== 'Done') {
        //   assessment_status_changed = true
        // }
        console.log(currentselectedObj,"curr")
        console.log(previouslySelectedObj,"prev")
        if (currentselectedObj && currentselectedObj.value.toLowerCase() == 'done' && previouslySelectedObj.value.toLowerCase() !== 'done') {
          assessment_status_changed = true
        }

      }
      buildingDataObj['assessment_status_changed'] = assessment_status_changed;
      let ignoreList = this.ignoreMandatory(buildingDataObj, this.formTemplateWidgets)
      buildingDataObj['ignore'] = ignoreList
      let formdata = this.DataApprovalManagementService.convertDataToFormData(buildingDataObj);

      this.apiService.getFormDataToken().subscribe((resp: any) => {
        if (resp && resp.data) {
          formdata.append('csrf_token', resp.data.csrf_token);
          if (this.data.selectedTab === 0) {
            this._store.dispatch(new UpdateBuildingDataAction(this.originalRowData[0].dataCollection._id, formdata))
          } else if (this.data.selectedTab === 1) {
            this._store.dispatch(new UpdatePremiseDataAction(this.originalRowData[0].dataCollection._id, formdata, { buildingIds: this.data.buildingsIds }))
          }
        }
        return of({})
      })
      this.dialogRef.close([]);


      // }
    }

  }


  sendDataToServer() {
    this.formTemplateWidgets.forEach(widget => {
      if (this.addedFiles.find(obj => obj.widget_id === widget.id)) {
        const obj = this.addedFiles.find(obj => obj.widget_id === widget.id);
        if (Array.isArray(obj.file_name)) {
          this.originalRowData.collected_data[widget.id] = (this.originalRowData.collected_data[widget.id] && this.originalRowData.collected_data[widget.id].length) ? this.originalRowData.collected_data[widget.id].concat(obj.file_name) : obj.file_name;
        } else {
          this.originalRowData.collected_data[widget.id] = obj.file_name;
        }
      }
    })
    this.uploadStatusMessage = "Saving updated data...";
    this.closeEditWindow = true;

    let buildingDataObj = {
      data: "",
      location: ""
    }
    buildingDataObj.data = this.originalRowData.collected_data
    buildingDataObj.location = this.originalRowData[0].dataCollection.location.coordinates
    this.dialogRef.close([]);
    if (this.data.type === 'building') {
      this._store.dispatch(new UpdateBuildingDataAction(this.originalRowData[0].dataCollection._id, buildingDataObj))
    }
    if (this.data.type === 'premise') {
      this._store.dispatch(new UpdatePremiseDataAction(this.originalRowData[0].dataCollection._id, buildingDataObj, { buildingIds: this.data.buildingsIds }))
    }
    // this.spinner.hide();
    // this._store.dispatch(new SaveCollectedDataRowDetailsStartAction(this.originalRowData._id, { data: this.originalRowData }));
  }

  showConfirmDialog(widget) {
    if (widget.form_widget_type !== "multiple_images") {
      const confDialog = this.dialog.open(ConfirmDialogComponent, {
        hasBackdrop: true,
        role: 'dialog',
        width: '400px',
        data: {
          alertTitle: 'Confirm',
          confirmText: 'Yes',
          alertText: `Uploading a new file will replace any existing file. Do you want to proceed?`
        }
      })
      confDialog.afterClosed().subscribe(response => {
        if (response) {
          if (widget.form_widget_type === "signature" || widget.form_widget_type === "single_image") {
            document.getElementById(`s-upload-${widget.id}`).click();
          } else if (widget.form_widget_type === "audio") {
            document.getElementById(`a-upload-${widget.id}`).click();
          } else if (widget.form_widget_type === "video") {
            document.getElementById(`v-upload-${widget.id}`).click();
          } else if (widget.form_widget_type === "document") {
            document.getElementById(`d-upload-${widget.id}`).click();
          }
        }
      })
    }
  }


  readFile(obj, onLoadCallback) {
    var reader = new FileReader();
    reader.readAsDataURL(obj.file);
    this.addInfoToFiles(obj.widget, obj.file, reader);
    reader.onload = onLoadCallback;
  }



  fileChange(event, widget) {

    let fileList: FileList = event.target.files;
    switch (widget.form_widget_type) {
      case "signature":
      case "single_image":
        if (fileList.length) {
          const file: File = fileList[0];
          const fileType = file.type;
          const re = /^((image+\/)+[a-zA-Z]{3,})$/;
          if (!re.test(fileType)) return;

          let widgetObj = {
            file: file,
            widget: widget
          }
          this.readFile(widgetObj, function (e) {
            widget.value = e.target.result;
            widget.degree = 0;
          });
        }
        break;
      case "document":
        if (fileList.length) {
          const file: File = fileList[0];
          const fileType = file.type;
          const allowedFileTypes = ["application/pdf", "application/txt", "application/xls", "application/xlsx", "application/doc", "application/docx", "application/ppt", "application/pptx", "text/plain"]
          if (!(allowedFileTypes.indexOf(fileType) > -1)) {
            this.toaster.warning('Please upload valid document')
            return
          };
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            widget.value = reader.result;
            widget.file_name = file.name;
            this.addInfoToFiles(widget, file, reader);
          }

        }
        break;
      case "multiple_images":
        if (fileList.length) {
          let validFiles = true;
          for (let i = 0; i < fileList.length; i++) {
            const re = /^((image+\/)+[a-zA-Z]{3,})$/;
            if (!re.test(fileList[i].type)) {
              validFiles = false; return;
            }
          }
          if (validFiles) {
            // new Array(fileList.length).fill(0).forEach((_, index) => {
            // })
            let aFiles = this.addedFiles[widget.id] || [];
            for (let i = 0; i < fileList.length; i++) {
              let reader = new FileReader();
              reader.readAsDataURL(fileList[i]);
              reader.onload = (_event) => {
                widget.value.push(reader.result);
                widget.degree.push(0);
                widget.files.push(fileList[i]);
                aFiles.push(fileList[i].name);
                if (aFiles.length) {
                  this.addInfoToFiles(widget, fileList[i], reader);
                };
              }
            }
          } else {
            return;
          }
        }
        break;
      case "audio":
        if (fileList.length) {
          const file: File = fileList[0];
          const fileType = file.type;
          const re = /^((audio+\/)+[a-zA-Z0-9]{3,})$/;
          if (!re.test(fileType)) return;
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            widget.value = reader.result;
            widget.file_name = file.name;
            this.addInfoToFiles(widget, file, reader);
          }
        }
        break;
      case "video":
        if (fileList.length > 0) {
          const file: File = fileList[0];
          const fileType = file.type;
          const re = /^((video+\/)+[a-zA-Z0-9]{3,})$/;
          if (!re.test(fileType)) return;
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (_event) => {
            widget.value = reader.result;
            widget.file_name = file.name;
            this.addInfoToFiles(widget, file, reader);
          }
        }
        break;
    }
  }


  addInfoToFiles(widget, file, reader) {
    if (widget.form_widget_type == 'multiple_images') {
      this.filesToUpload.push({
        id: widget.id,
        'file': file,
        'file_name': widget.file_name
      })
    } else {
      let fileInfoIndex = this.filesToUpload.findIndex((fileInfo) => fileInfo.id === widget.id);
      if (fileInfoIndex === -1) {
        this.filesToUpload.push({
          id: widget.id,
          'file': file,
          'file_name': widget.file_name
        })
      } else {
        this.filesToUpload.splice(fileInfoIndex, 1, { id: widget.id, 'file': file })

      }
    }
  }

  updateAddedFilesData(response) {
    console.log("addedFileData: ", this.addedFiles);
    console.log("response", response);
    this.addedFiles.forEach(obj => {
      if (Array.isArray(obj.file_name)) {
        obj.file_name = [];
      } else {
        obj.file_name = null;
      }
    })
    response.forEach(obj => {
      let addedFile = this.addedFiles.find(ele => ele.id === obj.id);
      if (addedFile) {
        if (Array.isArray(addedFile.file_name)) {
          addedFile.file_name.push(obj.file_name);
        } else {
          addedFile.file_name = obj.file_name;
        }
      }
    });
  }

  onImageZoomIn(data, type) {
    if (!data) return;
    const dialogRef = this.dialog.open(TableDialogComponent, {
      width: "500px",
      hasBackdrop: true,
      data: {
        type,
        data,
        // name: this.userdata.name,
        // alertTitle: "DELETE",
        // cancelText: "Cancel",
        // confirmText: "Delete"
      }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      this.result = result;
    });
  }

  previewImage(widget) {
    let data = widget.value;
    let degree = [];
    let type = "";
    let action = "";
    switch (widget.form_widget_type) {
      case "signature":
        type = "image";
        break;
      case "single_image":
        type = "single_image"
        action = "rotate";
        data = [widget.value];
        degree = [widget.degree];
        break
      case "multiple_images":
        type = "multi_image"
        action = "rotate";
        degree = [...widget.degree]
    }
    if (!data) return;
    const dialogRef = this.dialog.open(TableDialogComponent, {
      width: "500px",
      hasBackdrop: true,
      data: {
        type,
        data,
        action,
        degree
        // name: this.userdata.name,
        // alertTitle: "DELETE",
        // cancelText: "Cancel",
        // confirmText: "Delete"
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        switch (widget.form_widget_type) {
          case "single_image":
            widget.degree = result.degree;
            let widgetId = widget.id
            let degree = widget.degree
            let obj = { [widgetId]: degree }
            this.singleImageRotationDegree = { ...obj, ...this.singleImageRotationDegree };
            break;
          case "multiple_images":
            widget.degree = result.degree;
            widget.value.filter((file, index) => {
              if (!this.checkIfEmpty(file) && typeof file == "string") {
                let widgetId = widget.id
                let degree = widget.degree
                let obj = { [widgetId]: degree[index] }
                this.singleImageRotationDegree = { ...obj, ...this.singleImageRotationDegree };
              }
            })
            break;
        }
      }
    });
  }
  imageClicked(img, widget) {
    console.log(img);
  }
  ngOnDestroy() {
    this.stopper$.next();
    this.stopper$.complete();
    this.subscription.unsubscribe()
  }

  closeDataEditingDialog() {
    this.dialogRef.close([]);
  }

  checkIfEmpty(value) {
    if (value == undefined || value == null || /^\s*$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(date) {
    let d = date.date();
    let m = date.month() + 1;
    let y = date.year();
    d = d < 10 ? (`0${d}`) : d;
    m = m < 10 ? (`0${m}`) : m;
    return `${y}-${m}-${d}T00:00:00.000Z`;
  }

  ignoreMandatory(data, template) {
    let widgetsWithCondition = []
    let ignoreList = []
    template.forEach((widget) => {
      if (widget.conditions.length) {
        widgetsWithCondition.push(widget)
      }
    })
    widgetsWithCondition.forEach((widget) => {
      if (widget.required === true && widget.show_value === false) {
        let obj = {}
        obj['widget'] = widget.id
        obj['ignore'] = true
        ignoreList.push(obj)
      }
      else {
        let obj = {}
        obj['widget'] = widget.id
        obj['ignore'] = false
        ignoreList.push(obj)
      }
    })
    return ignoreList.filter(item => item.ignore === true).map(item => item.widget)
  }

}

export interface form_template_widget {
  is_editted: boolean,
  is_editable: boolean,
  is_valid: boolean,
  show_value: boolean,
  code: string,
  form_widget_type: string,
  icon: string,
  id: string,
  label: string,
  value: any,
  options: Array<any>,
  conditions: Array<any>,
  required: boolean,
  with_isd: boolean,
  operand_1: string,
  operand_2: string,
  operator: string,
  rating_type: string,
  max_value: number,
  min_value: number,
  max_date: string,
  min_date: string,
  steps: number,
  auto_suggest_unique_values: Array<any>,
  auto_suggest_id_value_map: any,
  filteredOptions: Observable<string[]>,
  auto_fill_widgets: Array<any>,
  file_name: string
  degree: number | number[]
  files: any[]
}

