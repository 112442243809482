import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Store } from '@ngrx/store';
import { GetUserDetailsStartAction, GetUsersProfileStartAction, GetOrgDetailsStartAction, userProfileactionTypes, GetOrgDetailsSuccessAction } from './store/actions/profile.action';
import { userDataSelector, userProfileSelector } from './store/selectors/profile.selector';
import { Storeservice } from './_services/store.services';
import { SocketService } from './_services/socket.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import _ from 'lodash';
import { API } from './_services';
import { SessionService } from './_services/session.service';
import { CheckValidSubscriptionStartAction, SubscriptionActions, CheckValidSubscriptionSuccessAction } from './store/actions/subscription.action';
import { Actions, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { GetIconsListStartAction, GetOSMTranserveSourcesStartAction, SessionManagementTypes, GetOSMTranserveSourcesSuccessAction } from './store/actions/session-management.actions';
import { environment as env } from '../environments/environment';
import { ProcessLayerDialogComponent } from './dialogs/process-layers-dialog/process-layer-dialog.component';
import * as PropertyTaxActions from './store/actions/property-tax.actions';
import { combineLatest, Observable } from 'rxjs';
import { uniqBy } from 'lodash';
import { isNumber } from 'util';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  orgId: string = "";
  currentLang: string = localStorage.getItem("locale") ? localStorage.getItem("locale") : "en";
  profileUser: any = {};
  chatIconVisibility: boolean = false;
  @ViewChild('notificationDiv') notificationDiv: ElementRef;
  badgeCount: any = 0;
  notificationDisplay: boolean = true;

  constructor(
    private translate: TranslateService,
    private _store: Store<any>,
    private actions: Actions,
    private storeService: Storeservice,
    public socketService: SocketService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private sessionService: SessionService) {
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', env.gaTrackingId, {
        'page_path': event.urlAfterRedirects
      })
    })
  }
  ngOnInit() {
    /* this language will be used as a fallback when a translation isn't found in the current language */
    this.translate.setDefaultLang('en');
    /* the lang to use */
    this.translate.use(this.currentLang);
    this._store.dispatch(new CheckValidSubscriptionStartAction());
    this._store.dispatch(new GetUsersProfileStartAction());
    this._store.select(userProfileSelector).subscribe((profile: any) => {
      if (!this.socketService.userId) {
        this.profileUser = profile;
        let res = profile;
        let token = localStorage.getItem('token');
        if (token && token.length) {
          if (profile.organisation_id && Object.keys(profile.organisation_id).length && profile.organisation_id._id) {
            this.orgId = profile.organisation_id._id;
            this.sessionService.organizationId = profile.organisation_id._id
            this._store.dispatch(new GetOrgDetailsStartAction(this.orgId));
          }
          if (res && Object.keys(res).length) {
            this.storeService.tokenChangeSubject({ val: true, userId: res._id, organizationId: res.organisation_id._id })
            this.socketService.subscriptionArray.push(
              this.socketService.getClientBadgeCount(res._id).subscribe((resp: any) => {
                this.socketService.unreadCountSubject.next(resp);
                this.socketService.unreadCount = resp.data.count;
              })
            );

            this.socketService.subscriptionArray.push(this.socketService.getServerLayerProcess(res._id).subscribe((resp: any) => {
              this.socketService.getServerLayerProcessObservable.next(resp);
              let index = this.socketService.globalNotificationList.findIndex((layer) => layer._id && layer._id === resp.data.layerId);
              if (index === -1) {
                this.socketService.globalNotificationList.unshift({ message: resp.message || ' under process', _id: resp.data.layerId, processing: false, updatedAt: resp.data.updatedAt || Date.now() })
              } else {
                this.socketService.globalNotificationList[index] = { message: resp.message || 'under process', _id: resp.data.layerId, processing: false, updatedAt: resp.data.updatedAt || Date.now() }
                if (resp.data.state && resp.data.state === 'completed' && resp.data.properties && isNumber(resp.data.properties.loadedBuildingCount)) {
                  this.socketService.globalNotificationList[index]['message'] = `Number of buildings uploaded are ${resp.data.properties.loadedBuildingCount} and total count of Building Footprints is ${resp.data.properties.totalBuildingCount}`
                }
              }
              if (resp && resp.data && resp.data.state === 'completed') {
                this.socketService.toasterService.success(`${resp.data.properties.name}` + ' has been processed  ')

                if (index === -1) {
                  this.socketService.globalNotificationList[0]['error'] = false;
                  this.socketService.globalNotificationList[0]['processing'] = false;
                  this.socketService.globalNotificationList[0]['properties'] = {};
                  if (resp.data.properties && resp.data.properties.sessionId) {
                    this.socketService.globalNotificationList[0]['properties']['sessionId'] = resp.data.properties.sessionId;
                    this.socketService.globalNotificationList[0]['properties']['sessionName'] = resp.data.properties.sessionName;
                  }
                  this.socketService.globalNotificationList[0]['properties']['name'] = resp.data.properties.name;

                } else {
                  this.socketService.globalNotificationList[index]['error'] = false;
                  this.socketService.globalNotificationList[index]['processing'] = false;
                  this.socketService.globalNotificationList[index]['properties'] = {};
                  if (resp.data.properties && resp.data.properties.sessionId) {
                    this.socketService.globalNotificationList[index]['properties']['sessionId'] = resp.data.properties.sessionId;
                    this.socketService.globalNotificationList[index]['properties']['sessionName'] = resp.data.properties.sessionName;
                  }
                  this.socketService.globalNotificationList[index]['properties']['name'] = resp.data.properties.name;
                }

              } else if (resp && resp.data && resp.data.state === 'processing') {

                if (index === -1) {
                  this.socketService.globalNotificationList[0]['error'] = false;
                  this.socketService.globalNotificationList[0]['processing'] = true;
                  this.socketService.globalNotificationList[0]['properties'] = {};
                  this.socketService.globalNotificationList[0]['properties']['name'] = resp.data.properties.name;

                } else {
                  this.socketService.globalNotificationList[index]['error'] = false;
                  this.socketService.globalNotificationList[index]['processing'] = true;
                  this.socketService.globalNotificationList[index]['properties'] = {};
                  this.socketService.globalNotificationList[index]['properties']['name'] = resp.data.properties.name;
                }

              } else if (resp && resp.data && resp.data.state === 'failed') {
                if (index === -1) {
                  this.socketService.globalNotificationList[0]['error'] = true;
                  this.socketService.globalNotificationList[0]['processing'] = false;
                  this.socketService.globalNotificationList[0]['message'] = resp.data.message || resp.message;
                  this.socketService.globalNotificationList[0]['properties'] = {};
                  this.socketService.globalNotificationList[0]['properties']['name'] = resp.data.properties.name;

                  // this.globalNotificationList.unshift({message: resp.message || '', _id: resp.data.layerId})
                } else {
                  this.socketService.globalNotificationList[index]['error'] = true;
                  this.socketService.globalNotificationList[index]['processing'] = false;
                  this.socketService.globalNotificationList[index]['message'] = resp.data.message || resp.message;
                  this.socketService.globalNotificationList[index]['properties'] = {};
                  this.socketService.globalNotificationList[index]['properties']['name'] = resp.data.properties.name;
                }
              }
              this.socketService.highLightBadge = true;

              this.socketService.globalNotificationList = uniqBy([...this.socketService.globalNotificationList], "_id");
              let processIndex = index
              if (index == -1) {
                processIndex = 0
              }
              let dupIndex = this.socketService.processingGlobalLayersList.findIndex(({ _id }) => _id === this.socketService.globalNotificationList[processIndex]._id);
              if (dupIndex === -1) {
                this.socketService.processingGlobalLayersList.unshift(this.socketService.globalNotificationList[processIndex])
              } else {
                this.socketService.processingGlobalLayersList[dupIndex] = this.socketService.globalNotificationList[processIndex]
              }
              this.socketService.getServerNotificationUnreadCount({ userId: res._id });
              this.socketService.globalNotificationList = uniqBy([...this.socketService.processingGlobalLayersList, ...this.socketService.globalNotificationList], '_id')
            }));

            this.socketService.subscriptionArray.push(this.socketService.getServerNotificationList(res._id).subscribe((resp: any) => {
              if (resp && resp.data.success) {
                let notificationList = [...resp.data.data.docs]
                this.socketService.getServerNotificationListObservable.next(notificationList.reduce((prev, curr) => {
                  if (curr.state === 'failed') {
                    curr.error = true;
                  } else if (curr.properties && isNumber(curr.properties.loadedBuildingCount)) {
                    curr.message = `- Number of buildings uploaded are ${curr.properties.loadedBuildingCount} and total count of Building Footprints is ${curr.properties.totalBuildingCount}`
                  }

                  return [...prev, curr]
                }, []))
                this.socketService.currentNotificationPageNumber = resp.data.data.page;
                this.socketService.totalNotificationPages = resp.data.data.pages;
                this.socketService.totalGlobalNotificationList = resp.data.data.total;
              }
            }));

            this.socketService.subscriptionArray.push(this.socketService.getExportedLayer(res._id).subscribe((resp: any) => {
              let index = this.socketService.globalNotificationList.findIndex((layer) => layer._id && layer._id === resp.data.notificationId);
              if (index == -1) {
                if (resp.data.state === 'completed') {
                  this.socketService.globalNotificationList.unshift({ message: ' is available to export', _id: resp.data.notificationId, properties: { name: resp.data.properties.name }, processing: false, updatedAt: resp.data.updatedAt || Date.now(), type: 'export' })
                  this.socketService.globalNotificationList[0]['error'] = false;
                  this.socketService.globalNotificationList[0]['processing'] = false;
                  this.socketService.globalNotificationList[0]['properties']['filePath'] = resp.data.properties.filePath;
                  this.socketService.globalNotificationList[0]['type'] = 'export';
                  this.toastr.success(`${resp.data.properties.name}` + ' is available to export')
                } else if (resp.data.state === 'processing') {
                  this.socketService.globalNotificationList.unshift({ message: ' is under process', _id: resp.data.notificationId, properties: { name: resp.data.properties.name }, processing: false, updatedAt: resp.data.updatedAt || Date.now(), type: 'export' })
                  this.socketService.globalNotificationList[0]['processing'] = true;
                } else if (resp.data.state === 'failed') {
                  this.socketService.globalNotificationList.unshift({ message: ' failed to export', _id: resp.data.notificationId, properties: { name: resp.data.properties.name }, processing: false, updatedAt: resp.data.updatedAt || Date.now(), type: 'export' })
                  this.socketService.globalNotificationList[0]['error'] = true;
                  this.socketService.globalNotificationList[0]['processing'] = false;
                  this.socketService.globalNotificationList[0]['message'] = resp.data.message;
                }
              } else {
                this.socketService.globalNotificationList[index] = { message: ' is available to export', _id: resp.data.notificationId, properties: { name: resp.data.properties.name }, processing: false, updatedAt: resp.data.updatedAt || Date.now(), type: 'export' }
                this.socketService.globalNotificationList[index]['error'] = false;
                this.socketService.globalNotificationList[index]['processing'] = false
                if (resp.data.state === 'completed') {
                  this.toastr.success(`${resp.data.properties.name}` + ' has been successfully exported')
                  this.toastr.success(`${resp.data.properties.name}` + ' is available to export') // this works
                   this.socketService.globalNotificationList[index]['properties']['filePath'] = resp.data.properties.filePath;
                } else if (resp.data.state === 'processing') {
                  this.socketService.globalNotificationList[index]['message'] = ' is under process '
                  this.socketService.globalNotificationList[index]['processing'] = true;
                } else if (resp.data.state === 'failed') {
                  this.socketService.globalNotificationList[index]['error'] = true;
                  this.socketService.globalNotificationList[index]['processing'] = false;
                  this.socketService.globalNotificationList[index]['message'] = resp.data.message;


                }
              }
              this.socketService.highLightBadge = true;

              this.socketService.globalNotificationList = uniqBy([...this.socketService.globalNotificationList], "_id");
              let processIndex = index;
              if (index == -1) {
                processIndex = 0;
              }
              let dupIndex = this.socketService.processingGlobalLayersList.findIndex(({ _id }) => _id === this.socketService.globalNotificationList[processIndex]._id);
              if (dupIndex === -1) {

                this.socketService.processingGlobalLayersList.unshift(this.socketService.globalNotificationList[processIndex])
              } else {
                this.socketService.processingGlobalLayersList[dupIndex] = this.socketService.globalNotificationList[processIndex]
              }
              this.socketService.getServerNotificationUnreadCount({ userId: res._id });
              this.socketService.globalNotificationList = uniqBy([...this.socketService.processingGlobalLayersList, ...this.socketService.globalNotificationList], '_id')
            }));

            //checking when client-join-chat already initiated
            let recentChatList$: Observable<any> = this.socketService.getClientRecentChatUsersList(res._id);
            let orgList$: Observable<any> = this.socketService.getClientOrganizationList(res.organisation_id._id)
            combineLatest(recentChatList$, orgList$).subscribe(([recentChatList, orgList]) => {
              if ((orgList) && (orgList.success) && (recentChatList) && (recentChatList.success)) {
                if (orgList.data.length) {
                  this.socketService.loggedInUser = [...orgList.data].find(({ _id }) => _id === res._id);
                  this.socketService.loggedInUserSubject.next([...orgList.data].find(({ _id }) => _id === this.socketService.userId));
                  if (this.socketService.selectedUser && this.socketService.selectedUser._id) {
                    this.socketService.selectedUser = [...orgList.data].find(({ _id }) => this.socketService.selectedUser._id === _id)
                    this.socketService.selectedUserSubject.next(this.socketService.selectedUser)
                  }
                  this.socketService.organizationList = [...orgList.data].filter(({ _id }) => _id !== this.socketService.userId);
                }
                if (recentChatList.length) {
                  this.socketService.recentChatList = recentChatList.data.filter(({ _id }) => _id != this.socketService.userId)
                }
                if (this.socketService.autoSuggestedUser && this.socketService.autoSuggestedUser._id) {
                  for (let i = 0; i < recentChatList.data.length; i++) {
                    if (this.socketService.autoSuggestedUser._id == recentChatList.data[i]._id) {
                      this.socketService.autoSuggestedUser = recentChatList.data[i];
                      break
                    }
                  }
                }

                let recentChatUsersList = (Object.keys(this.socketService.autoSuggestedUser).length) ? this.socketService.segregateUsersListWithOrgList(uniqBy([this.socketService.autoSuggestedUser, ...recentChatList.data], '_id'), this.socketService.organizationList) : this.socketService.segregateUsersListWithOrgList(recentChatList.data, this.socketService.organizationList);

                this.socketService.recentChatUsersSubject.next(recentChatUsersList)
              }
            });



            this.socketService.subscriptionArray.push(
              this.socketService.listenClientClearAllNotification(res._id).subscribe((resp: any) => {
                if (resp && resp.success) {
                  this.socketService.globalNotificationList = [];
                  this.socketService.processingGlobalLayersList = [];
                  this.socketService.getServerNotificationListObservable.next([]);
                  this.socketService.updateNotificationReadCount({ userId: res._id });
                  this.socketService.currentNotificationPageNumber = 1;
                }
              }));
            this.socketService.subscriptionArray.push(
              this.socketService.listenClientDeleteNotification(res._id).subscribe((resp: any) => {
                if (resp && resp.success) {
                  let deletedId = resp.notificationId;
                  let index = this.socketService.globalNotificationList.findIndex((layer) => layer._id === deletedId);
                  this.socketService.globalNotificationList.splice(index, 1);
                  let processingIndex = this.socketService.processingGlobalLayersList.findIndex((processingLayer) => processingLayer._id == deletedId);
                  if (processingIndex !== -1) {
                    this.socketService.processingGlobalLayersList.splice(processingIndex, 1);
                  }
                  this.socketService.getServerNotificationListObservable.next(this.socketService.globalNotificationList);
                  this.socketService.updateNotificationReadCount({ userId: res._id });
                }
              })
            )
            this.socketService.subscriptionArray.push(this.socketService.getLoadBuildingsProcessingStatus(res._id).subscribe((resp: any) => {
              if (resp && resp.success) {
                this.socketService.webApiCall = true;
              
                this._store.dispatch(new PropertyTaxActions.GetPropertyTaxData({ nonBlocking: true }))
              }
            }))




            this.socketService.joinClientToChat(res._id)
            this.socketService.getNotificationsList({ userId: res._id, page: this.socketService.currentNotificationPageNumber, limit: 10 });
            // end of notification list
            this.socketService.getServerNotificationUnreadCount({ userId: res._id });
          }
           this._store.dispatch(new GetUsersProfileStartAction());
          this.storeService.getMapFonts();
          this._store.dispatch(new GetIconsListStartAction());
          this._store.dispatch(new GetOSMTranserveSourcesStartAction());
          this.socketService.establishSocketConnection();
        }
      }

    });


    window.addEventListener('beforeunload', (e) => {
      this.socketService.disconnectSocket({ userId: this.profileUser._id });
      this.socketService.socket.removeAllListeners();
      this.socketService.socket.disconnect();
      e.returnValue;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let url: any = this.activatedRoute.snapshot;
        let urlArray = url._routerState.url.split('/')
        const noneChatList = ['chat', 'preview-wms', 'preview', 'sign', 'subscription', 'form', '404', 'city-os-management', 'data-management', 'datalibrary-upload', 'new']
        this.chatIconVisibility = !(noneChatList.some(ele => urlArray.includes(ele)));
        const noneNotificationList = ['preview-wms', 'preview', 'sign', 'subscription', 'form', '404', 'new', 'city-os-management'];
        this.notificationDisplay = !(noneNotificationList.some(ele => urlArray.includes(ele)));
      }
    })

    this.actions.pipe(
      ofType(SubscriptionActions.checkValidSubscriptionSuccess),
      tap((response: CheckValidSubscriptionSuccessAction) => {
        const url = this.router.url;

        console.log("URL is "+url)
        const res = response.data;
        if (!res.is_subscription_valid) {
          if (res.error_code === 402) {
            switch (+res.key) {
              case 10019:
              case 10020:
                this.router.navigate(["./subscription/new-subscription"], { replaceUrl: true });
                return;
              case 10021:
              case 10022:
              case 10023:
              case 10024:
                this.router.navigate(["./subscription/upgrade"], { replaceUrl: true });
                return;
              case 10025:
                this.toastr.error(res.msg);
                this.router.navigate(["app-management/map-sessions"], { replaceUrl: true });
                return;
            }
          } else if (res.error_code === 405) {
            this.router.navigate(['/sign/signIn']);
            this.toastr.error(res.msg);
          }
        } else if (url === "/sign/signIn") {
          console.log("Routeddddd")
          this.router.navigate(["app-management/map-sessions"]);
        }
      })
    ).subscribe();

    this.actions.pipe(
      ofType(userProfileactionTypes.getOrgDetailsSuccess),
      tap((response: GetOrgDetailsSuccessAction) => {
        if (!response.response.is_default_user && response.response.subscription_plan && (response.response.subscription_plan.subs_status === "cancelled")) {
          // compare creent_end with new Date(new Date().toISOString()).getTime() and then redirect
          if ((response.response.subscription_plan.current_end * 1000) < new Date(new Date().toISOString()).getTime()) {
            if (response.response.subscription_plan.is_free_plan) {
              this.toastr.error("Your free tral has expired. Subscribe to a paid plan to be able to access the application.");
              this.router.navigate(["./subscription/new-subscription"], { replaceUrl: true });
            } else {
              this.toastr.error("Your subscription has expired. Upgrade subscription to be able to access the application.");
              this.router.navigate(["./subscription/upgrade"], { replaceUrl: true });
            }
          }
        }
      })
    ).subscribe();

    this.actions.pipe(
      ofType(SessionManagementTypes.getOSMTranserveSourcesSuccess),
      tap((action: GetOSMTranserveSourcesSuccessAction) => {
        if (Object.keys(action.response).length) {
          this.sessionService.osmTranserveSources = action.response;
        }
      })
    ).subscribe();
    this.socketService.unreadCountSubject.subscribe((resp: any) => {
      if (resp && resp.success) {
        this.badgeCount = resp.data.count;
        if (resp.data.count > 9) {
          this.badgeCount = "9+"
        }

        if (resp.data.count) {
          this.socketService.highLightBadge = true
        } else {
          this.socketService.highLightBadge = false;
        }
      }
    })

  }

  chatClicked() {
    this.dialog.closeAll();
    this.router.navigate(['./', 'app-management', 'chat'])
  }
  notificationIconClicked() {
    let dialogPosition = {
      top: this.notificationDiv.nativeElement.getBoundingClientRect().top,
      left: this.notificationDiv.nativeElement.getBoundingClientRect().left,
      bottom: this.notificationDiv.nativeElement.getBoundingClientRect().bottom,
      right: this.notificationDiv.nativeElement.getBoundingClientRect().right,
    }
    this.dialog.open(ProcessLayerDialogComponent, {
      width: '325px',
      panelClass: 'custo-sess',
      data: {
        position: dialogPosition,
        type: 'socketNotifications',
      }
    })

  }
}
