import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Storeservice } from "src/app/_services/store.services";
import {
  DataManagementActionTypes,
  GetAssignedUsersSurveyersDataStartAction,
  GetAssignedUsersSurveyersDataSuccessAction,
  GetAllDataCollectedLayersStartAction,
  GetAllDataCollectedLayersSuccessAction,
  GetSelectedTemplateDetailsStartAction,
  GetSelectedTemplateDetailsSuccessAction,
  GetAvailableFormWidgetsStartAction,
  GetAvailableFormWidgetsSuccessAction,
  CreateTemplateStartAction,
  CreateTemplateSuccessAction,
  AssignUsersSurveyersToFormStartAction,
  AssignUsersSurveyersToFormSuccessAction,
  UpdateTemplateStartAction,
  UpdateTemplateSuccessAction,
  DuplicateLayerStartAction,
  GetVectorLayersStartAction,
  GetVectorLayersSuccessAction,
  DeleteTemplateStartAction,
  ExportVectorLayerStartAction,
  ExportVectorLayerSuccessAction,
  CreateEmptyVectorLayerStartAction,
  CreateEmptyVectorLayerSuccessAction,
  ResetStateForFormsAction,
  GetProjectionSystemsStartAction,
  GetProjectionSystemsSuccessAction,
  ChangeStatusStartAction,
  ChangeStatusSuccessAction,
  DeleteTemplateSuccessAction,
  DataCollectedToVectorLayerStartAction,
  GetLayerById,
  GetLayerByIdSuccess,
  EditVectorlayerAttributes,
  GetAllDataLibraryCategoriesStartAction,
  GetAllDataLibraryCategoriesSuccessAction,
  GetDataLibraryCategoryDetailsStartAction,
  GetDataLibraryCategoryDetailsSuccessAction,
  GetDataLibrarySearchStartAction,
  GetDataLibrarySearchSuccessAction,
  AddSelectedDataLayerToSessionStartAction,
  AddSelectedDataLayerToSessionSuccessAction,
  GetVectorListInfoStartAction,
  GetVectorListInfoSuccessAction,
  GetDLAttributesStartAction,
  GetDLAttributesSuccessAction,
  GetTabularDLAttributesStartAction,
  GetTabularDLAttributesSuccessAction,
  JoinYourDataStartAction,
  JoinYourDataSuccessAction,
  JoinYourDataTabularStartAction,
  JoinYourDataTabularSuccessAction,
  SaveJoinResultAsLayerStartAction,
  SaveJoinResultAsLayerSuccessAction,
  SaveVTJoinResultAsLayerStartAction,
  SaveVTJoinResultAsLayerSuccessAction,
  AddJoinedLayerToNewSessionStartAction,
  AddJoinedLayerToNewSessionSuccessAction,
  GetTabularDataStartAction,
  GetTabularDataSuccessAction,
  DeleteTabularDataStartAction,
  DeleteTabularDataSuccessAction,
  GetDataLibraryLayerDetailsStartAction,
  GetDataLibraryLayerDetailsSuccessAction,
  GetGroupNamesStartAction,
  GetGroupNamesSuccessAction,
  GetGroupLayersStartAction,
  GetGroupLayersSuccessAction,
  GetYourDataLayersListStartAction,
  GetYourDataLayersListSuccessAction,
  ExportTabularDataStartAction,
  ExportTabularDataSuccessAction,
  UploadLibraryDataStartAction,
  UploadLibraryDataSuccessAction,
  GetLayersListStartAction,
  GetLayersListSuccessAction,
  GetLibraryAndYourDataLayersListStartAction,
  GetLibraryAndYourDataLayersListSuccessAction,
  GetDataLibraryDataStarAction,
  GetDataLibraryDataSuccessAction,
  GetSelectedGroupLayerIdsStartAction,
  GetSelectedGroupLayerIdsSuccessAction,
  CreateUpdateGroupStartAction,
  CreateUpdateGroupSuccessAction,
  GetLayerPreviewDataStartAction,
  GetLayerPreviewDataSuccessAction,
  UploadRasterDataStartAction,
  UploadRasterDataSUccessAction
} from "../actions/data-management.actions";
import { switchMap, map, catchError, tap } from "rxjs/operators";
import { of } from "rxjs";
import { LogErrorAction, LogFilureAction } from "../actions/user-management.actions";
import { Router, ActivatedRoute } from "@angular/router";
import { DCL_PAYLOAD, VL_PAYLOAD } from 'src/app/app-management/data-management/data-management-home/data-management-home.component';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { GetSurveyorsDetailsStart, GetProjectListStart } from '../actions/project-management.actions';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { SessionService } from 'src/app/_services/session.service';
import { LayerService } from "src/app/_services";
@Injectable()
export class DataManagementEffects {
  constructor(
    private actions$: Actions,
    private stores: Storeservice,
    private _router: Router,
    private _activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _store: Store<any>,
    private sessionService: SessionService,
    private layerService: LayerService
  ) { }

  @Effect()
  getUsersSurveyers$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getAssignedUsersSurveyersDataStart),
    switchMap((action: GetAssignedUsersSurveyersDataStartAction) => {
      return this.stores.getUserAndSurveyers(action.payload).pipe(
        map((response: any) => {
          return new GetAssignedUsersSurveyersDataSuccessAction(response.data);
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  getTemplates$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getAllDataCollectedLayersStart),
    switchMap((action: GetAllDataCollectedLayersStartAction) => {
      return this.stores.getAllTemplates(action.payload, action.status).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetAllDataCollectedLayersSuccessAction(response);
          }
          return of(new LogFilureAction("Failed to Fetch data."));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  newTemplate$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.createTemplateStart),
    switchMap((action: CreateTemplateStartAction) => {
      return this.stores.createUpdateTemplate(action.payload).pipe(
        map((response: any) => {
          this._router.navigate([
            "app-management/project/forms/",
            response.data[0].form_template_id,
            "assign-new"
          ]);
          return new CreateTemplateSuccessAction();
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  updateTemplate$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.updateTemplateStart),
    switchMap((action: UpdateTemplateStartAction) => {
      return this.stores.createUpdateTemplate(action.payload).pipe(
        map((_response: any) => {
          this._router.navigate([
            "app-management/project/forms"
          ]);
          return new UpdateTemplateSuccessAction();
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  templateDetails$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getSelectedTemplateDetailsStart),
    switchMap((action: GetSelectedTemplateDetailsStartAction) => {
      return this.stores.getTemplateDetails(action.paylod).pipe(
        map(
          (response: any) =>
            new GetSelectedTemplateDetailsSuccessAction(response.data)
        ),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  widgets$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getAvailableFormWidgetsStart),
    switchMap((_action: GetAvailableFormWidgetsStartAction) => {
      return this.stores.getWidgetsList().pipe(
        map(
          (response: any) =>
            new GetAvailableFormWidgetsSuccessAction(response.data)
        ),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  assignedUsers$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.assignUsersSurveyersToFormStart),
    switchMap((action: AssignUsersSurveyersToFormStartAction) => {
      return this.stores.assignUsersToTemplate(action.payload).pipe(
        map(
          (response: any) =>
            new AssignUsersSurveyersToFormSuccessAction(response.data)
        ),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  addDuplicate$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.DuplicateLayerStart),
    switchMap((action: DuplicateLayerStartAction) => {

      return this.stores.duplicateLayer(action.sessionId).pipe(
        map(
          (_response: any) => {
            this.toastr.success('Copy has been created successfully');

            return new GetAllDataCollectedLayersStartAction({search: '', page : null})
          }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  searchVectors$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getVectorLayersStart),
    switchMap((action: GetVectorLayersStartAction) => {
      return this.stores.searchVectorLayer(action.payload).pipe(
        map(
          (response: any) => new GetVectorLayersSuccessAction(response.data)
        ),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  deleteVector$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.deleteTemplateStart),
    switchMap((action: DeleteTemplateStartAction) => {
      return this.stores.deleteVectorLayer(action.payload).pipe(
        map((response: any) => {
          if (response.ok === 1) {
            this.toastr.success("Layer Deleted Successfully.")
            return new DeleteTemplateSuccessAction(response);
            // return new GetVectorLayersStartAction(VL_PAYLOAD);
          } else {
            return of(new LogFilureAction("Could not delete the session"));
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  exportedLayer$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.exportVectorLayerStart),
    switchMap((action: ExportVectorLayerStartAction) => {
      return this.stores.exportVectorLayer(action.payload).pipe(
        map((response: any) => {
          if (response) return new ExportVectorLayerSuccessAction(response);
          return of(new LogFilureAction("Could not export the session"));
        }),
        catchError((error: Error) => {
          this.toastr.error(error.message);
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  createLayer$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.createEmptyVectorLayerStart),
    switchMap((action: CreateEmptyVectorLayerStartAction) => {
      return this.stores.createEmptyVectorLayer(action.payload).pipe(
        map((response: any) => {

          console.log("May 12 comes here")
          if (response.success) {
            this.toastr.success("Layer created successfully");
            return new CreateEmptyVectorLayerSuccessAction(response);
          }
          this.toastr.error("Error in creating the layer. Please try again later.");
          return of(new LogFilureAction("Error in creating the layer. Please try again later."));
        }),
        catchError((error: Error) => {
          this.toastr.error(error.message);
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  projections$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getProjectionSystemsStart),
    switchMap((action: GetProjectionSystemsStartAction) => {
      return this.stores.getProjections(action.payload).pipe(
        map((response: any) => {
          if (response.success) return new GetProjectionSystemsSuccessAction(response.data);
          return of(new LogFilureAction("Could not fetch the projection data."));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
  @Effect()
  statusChange$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.statusChangeStartAction),
    switchMap((action: ChangeStatusStartAction) => {
      return this.stores.changeStatus(action.id, action.payload).pipe(
        map((resp: any) => {
          return (new ChangeStatusSuccessAction(resp))
        })
      )
    })
  )
  @Effect({ dispatch: false })
  dataColletedLayer$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.dataCollectedToVectorLayerStartAction),
    switchMap((action: DataCollectedToVectorLayerStartAction) => {
      return this.stores.dataCollectedToVectorLayer(action.obj, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.toastr.success('Form data converted to vector layer, shall be available for use after processing')
          }
        }),
        catchError((err: Error) => of(new LogErrorAction(err)))
      )
    })
  )

  @Effect()
  getLayerbyId$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getLayerById),
    switchMap((action: GetLayerById) => {
      return this.stores.getLayerById(action.id).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetLayerByIdSuccess(response);
          }
          // this.toastr.error("Error in creating the layer. Please try again later.");
          // return of(new LogFilureAction("Error in creating the layer. Please try again later."));
        }),
        catchError((error: Error) => {
          this.toastr.error(error.message);
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  editLayerAttribute$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.editLayerAttributes),
    switchMap((action: EditVectorlayerAttributes) => {
      return this.stores.editAttributes(action.id, action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            this.toastr.success("Attributes Edited Successfully");
            return new CreateEmptyVectorLayerSuccessAction(response);
          }
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  dLCategories$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getAllDataLibraryCategoriesStart),
    switchMap((action: GetAllDataLibraryCategoriesStartAction) => {
      return this.stores.getAllDLCategories().pipe(
        map((response: any) => {
          if(response.success) {
            return new GetAllDataLibraryCategoriesSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  dLCategoryDetails$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getDataLibraryCategoryDetailsStart),
    switchMap((action: GetDataLibraryCategoryDetailsStartAction) => {
      return this.stores.getDLCategoryDetails(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetDataLibraryCategoryDetailsSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  dLSearchRes$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getDataLibrarySearchStart),
    switchMap((action: GetDataLibrarySearchStartAction) => {
      return this.stores.searchDLLayersList(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetDataLibrarySearchSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  newSession$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.addSelectedDataLayerToSessionStart),
    switchMap((action: AddSelectedDataLayerToSessionStartAction) => {
      return this.stores.addSelectedLayerFromDLibrary(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            this.toastr.success("The selected Data-Library layers are scheduled for processing and will be added to the session after that.")
            return new AddSelectedDataLayerToSessionSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  vectorList$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getVectorListInfoStart),
    switchMap((action: GetVectorListInfoStartAction) => {
      return this.stores.searchVectorList(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetVectorListInfoSuccessAction(response);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  attributes$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getDLAttributesStart),
    switchMap((action: GetDLAttributesStartAction) => {
      return this.stores.getDLAttributes(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetDLAttributesSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  tabularAttributes$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getTabularDLAttributesStart),
    switchMap((action: GetTabularDLAttributesStartAction) => {
      return this.stores.getTabularDLAttributes(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetTabularDLAttributesSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  data$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.joinYourDataStart),
    switchMap((action: JoinYourDataStartAction) => {
      return this.sessionService.postTableJoin(action.payload).pipe(
        map((response: any) => {
          if(response.data && response.data.length) {
            return new JoinYourDataSuccessAction(response.data[0]);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  joinData$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.joinYourDataTabularStart),
    switchMap((action: JoinYourDataTabularStartAction) => {
      return this.sessionService.postVectorTableJoin(action.payload).pipe(
        map((response: any) => {
          if(response.data && response.data.length) {
            return new JoinYourDataTabularSuccessAction(response.data[0]);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  layer$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.saveJoinResultAsLayerStart),
    switchMap((action: SaveJoinResultAsLayerStartAction) => {
      return this.stores.saveResultAsLayer(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new SaveJoinResultAsLayerSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  vectorTabularLayer$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.saveVTJoinResultAsLayerStart),
    switchMap((action: SaveVTJoinResultAsLayerStartAction) => {
      return this.stores.saveVTResultAsLayer(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new SaveVTJoinResultAsLayerSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  newSessionWithLayer$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.addJoinedLayerToNewSessionStart),
    switchMap((action: AddJoinedLayerToNewSessionStartAction) => {
      return this.stores.addJoinedLayerToNewSession(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new AddJoinedLayerToNewSessionSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  tabularData$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getTabularDataStart),
    switchMap((action: GetTabularDataStartAction) => {
      return this.stores.getTabularDataList(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetTabularDataSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  layesList$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getLibraryAndYourDataLayersListStart),
    switchMap((action: GetLibraryAndYourDataLayersListStartAction) => {
      return this.stores.getLibraryNYourDataLayersList(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetLibraryAndYourDataLayersListSuccessAction(response);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  deleteTData$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.deleteTabularDataStart),
    switchMap((action: DeleteTabularDataStartAction) => {
      return this.stores.deleteTabularData(action.id).pipe(
        map((response: any) => {
          if(response.success) {
            this.toastr.success("Tabular data deleted successfully")
            return new DeleteTabularDataSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  layerData$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getDataLibraryLayerDetailsStart),
    switchMap((action: GetDataLibraryLayerDetailsStartAction) => {
      return this.stores.getDataLibraryLayerData(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetDataLibraryLayerDetailsSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  layerGroups$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getGroupNamesStart),
    switchMap((action: GetGroupNamesStartAction) => {
      return this.stores.getGroups(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetGroupNamesSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  groupLayers$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getGroupLayersStart),
    switchMap((action: GetGroupLayersStartAction) => {
      return this.stores.getGroupLayers(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetGroupLayersSuccessAction(response.data);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  Layers$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getYourDataLayersListStart),
    switchMap((action: GetYourDataLayersListStartAction) => {
      return this.stores.getLayersList(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new GetYourDataLayersListSuccessAction(response);
          }
          return new LogFilureAction("Please try again later");
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  exportTabLay$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.exportTabularDataStart),
    switchMap((action: ExportTabularDataStartAction) => {
      return this.stores.exportTabularData(action.payload).pipe(
        map((response: any) => {
          return new ExportTabularDataSuccessAction(response);
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  uploadLibData$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.uploadLibraryDataStart),
    switchMap((action: UploadLibraryDataStartAction) => {
      return this.stores.uploadLibraryData(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new UploadLibraryDataSuccessAction(response);
          }
          return of(new LogFilureAction("Error in uploading the data"));
        }),
        catchError((error: Error) => {
          return of(new LogErrorAction(error));
        })
      )
    })
  )

  @Effect()
  layersList$ =this.actions$.pipe(
    ofType(DataManagementActionTypes.layersListStart),
    switchMap((action: GetLayersListStartAction) => {
      return this.stores.getLayersList(action.payload).pipe(
        map((resp: any) => {
          if(resp && resp.success) {
            return new GetLayersListSuccessAction(resp)
          }
          return of (new LogFilureAction("Error while fetching data"))
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )
  
  @Effect()
  layerPreviewData$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getLayerPreviewDataStart),
    switchMap((action: GetLayerPreviewDataStartAction) => {
      return this.stores.getLayerPreview(action.payload).pipe(
        map((resp: any) => {
          if(resp && resp.success) {
            return new GetLayerPreviewDataSuccessAction(resp.data);
          }
          return of (new LogFilureAction("Error while fetching data"));
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err));
        })
      )
    })
  )

  @Effect()
  libraryLayers$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getDataLibraryDataStart),
    switchMap((action: GetDataLibraryDataStarAction) => {
      return this.stores.getLibraryLayersList(action.payload).pipe(
        map((resp: any) => {
          if(resp && resp.success) {
            return new GetDataLibraryDataSuccessAction(resp)
          }
          return of (new LogFilureAction("Error while fetching data"))
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  grpLayers$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.getSelectedGroupLayerIdsStart),
    switchMap((action: GetSelectedGroupLayerIdsStartAction) => {
      return this.stores.getGroupLayerIds(action.payload).pipe(
        map((resp: any) => {
          if(resp && resp.success) {
            return new GetSelectedGroupLayerIdsSuccessAction(resp.data)
          }
          return of (new LogFilureAction("Error while fetching data"))
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  crtEditGrp$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.createUpdateGroupStart),
    switchMap((action: CreateUpdateGroupStartAction) => {
      return this.stores.createGroup(action.payload).pipe(
        map((resp: any) => {
          if(resp && resp.success) {
            return new CreateUpdateGroupSuccessAction(resp)
          }
          return of (new LogFilureAction("Error while creating/updating group"))
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  @Effect()
  rasterUpload$ = this.actions$.pipe(
    ofType(DataManagementActionTypes.uploadRasterDataStart),
    switchMap((action: UploadRasterDataStartAction) => {
      return this.layerService.uploadRasterLayer(action.payload).pipe(
        map((resp: any) => {
          return new UploadRasterDataSUccessAction(resp);
        }),
        catchError((err: Error) => {
          return of(new LogErrorAction(err))
        })
      )
    })
  )

  // @Effect({dispatch: false})
  // resetFormsState$ =this.actions$.pipe(
  //   ofType(DataManagementActionTypes.resetStateStart),
  //   switchMap((_action: ResetStateForFormsAction) => {
  //      this._store.dispatch(new GetAllDataCollectedLayersStartAction({ search: '', page: 1 }))
  //      this._store.dispatch(new GetSurveyorsDetailsStart({ search :'', page: 1}))
  //      this._store.dispatch(new GetProjectListStart({ 'search': '', 'page': 1, "limit": 10 }));
  //     return of('');
  //     })
  // )

}

