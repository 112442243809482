import { Component, OnDestroy, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Storeservice } from 'src/app/_services/store.services';
import { Store } from '@ngrx/store';
import { HideLoaderAction, ShowLoaderAction } from 'src/app/store/actions/loader.actions';
import { FormBuilder } from '@angular/forms';
import { LayerService } from 'src/app/_services';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { GetVectorLayersStartAction, GetYourDataLayersListStartAction, UploadRasterDataStartAction } from 'src/app/store/actions/data-management.actions';
// import { VL_PAYLOAD } from 'src/app/app-management/dataManagement/data-management-home/data-management-home.component';
import { Options } from 'ng5-slider';

@Component({
  selector: 'raster-band',
  styleUrls: ['./raster-band-dialog.component.scss'],
  templateUrl: './raster-band-dialog.component.html'
})
export class RasterBandDialogComponent implements OnDestroy {
  rasterData: any;
  bandsCountArray: Array<number> = [];
  selectedBand: {
    'r': number,
    'g': number,
    'b': number
  } = { 'r': 0, 'g': 0, 'b': 0 }
  invalidBands: boolean = false;
  sliderOptions: Options = {
    floor: 1,
    ceil: 22
  }

  constructor(public dialogRef: MatDialogRef<RasterBandDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public storeservice: Storeservice, public _store: Store<any>,
    public layerService: LayerService, public sessionService: SessionService, private toaster: ToastrService,) {
    _store.dispatch(new HideLoaderAction());
    dialogRef.disableClose = true;
  }
  ngOnInit() {
    if (Object.keys(this.data).length) {
      this.rasterData = this.data.rasterData;
      this.bandsCountArray = new Array(this.data.bandsCount).fill(0).map((n, i) => i + 1);
    }
  }

  submitClicked() {
    let values: any = Object.values(this.selectedBand);
    let uniqueArr = values.filter((n, i) => values.indexOf(n) === i);
    let index = values.findIndex((val) => val === 0)
    if (uniqueArr.length === 3 && index === -1) {
      this.invalidBands = false;
      this.sessionService.setLoadingText('Uploading  Layer and it may take few minutes');
      // this._store.dispatch(new ShowLoaderAction());
      this.dialogRef.close({ type: 'upload' })
      this.uploadRasterLayer({ ...this.data.fileInfo, 'band_colors': this.selectedBand, })
    }
    else {
      //band must be unique
      this.toaster.warning('please select bands in valid format')
      this.invalidBands = true;
    }
  }
  optionSelected(type, event) {
    this.invalidBands = false;
    switch (type) {
      case 'r':
        this.selectedBand.r = parseInt(event.value)
        break;
      case 'g':
        this.selectedBand.g = parseInt(event.value);
        break;
      case 'b':
        this.selectedBand.b = parseInt(event.value);
        break;
    }
  }
  cancelClicked() {
    this.dialogRef.close({ type: 'upload' })
    this.uploadRasterLayer(this.data.fileInfo);
    // this._store.dispatch(new GetVectorLayersStartAction({ search: '' }));

  }
  uploadRasterLayer(rasterUploadData) {
    this._store.dispatch(new UploadRasterDataStartAction(rasterUploadData));
  }

  ngOnDestroy() {
  }

}