import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect, MatOption } from "@angular/material";
import { Store } from "@ngrx/store";
import { combineLatest, Subscription } from "rxjs";
import { GetAdvancedToolsSearchResultStartAction, GetAdvancedToolsSearchResultSuccessAction, GetLayerAttributesStartAction, SessionManagementTypes } from "src/app/store/actions/session-management.actions";
import { layerAttributesSelector, propertiesTypeSelector } from "src/app/store/selectors/session-management.selector";
import { cloneDeep } from "lodash";
import { ADVANCED_TOOL_TYPE } from "src/app/app.constants";
import { SessionService } from "src/app/_services/session.service";
import { Actions, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";

@Component({
  selector: "app-spatial-join-add",
  templateUrl: "./spatial-join-add.component.html",
  styleUrls: ["./spatial-join-add.component.scss"]
})

export class SpatialJoinAddDialog {

  @ViewChild('select',{}) select: MatSelect;
  
  searchAttribute: FormControl = new FormControl('');
  joinFields: FormControl = new FormControl('');
  summaryAttributes: Array<string> = [];
  filteredAttributes: Array<string> = [];
  selectedAttributes: Array<string> = [];
  panelOpenState = false;
  joinType: FormControl = new FormControl(null, [Validators.required]);
  joinTypes: Array<any> = [
    {key: "One to One", value: 2},
    {key: "One to Many", value: 0}
  ]
  joinFieldsList: Array<string> = [];
  subscriptionArr: Array<Subscription> = [];
  
  constructor(
    private dialogRef: MatDialogRef<SpatialJoinAddDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _store: Store<any>,
    private actions: Actions,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl
  ) { }

  ngOnInit() {
    console.log("DATA: ", this.data);
    if(this.data.joinLayer && this.data.joinLayer.parent_layer_id) {
      this._store.dispatch(new GetLayerAttributesStartAction(this.data.joinLayer.parent_layer_id));
    }
    this.subscriptionArr.push(
      this._store.select(layerAttributesSelector).subscribe((properties: Array<string>) => {
        this.summaryAttributes = cloneDeep(properties);
        this.filteredAttributes = cloneDeep(properties);
      })
    );
    this.subscriptionArr.push(
      this.searchAttribute.valueChanges.subscribe((val) => {
        if (val && val.trim().length) {
         this.filteredAttributes = this.summaryAttributes.filter((attr) => attr.toLowerCase().includes(val.trim().toLowerCase()))  
        } else {
          this.filteredAttributes = this.summaryAttributes
        }
      })
    );
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        tap((action: GetAdvancedToolsSearchResultSuccessAction) => {
          this.navigationService.setShowLayerTable(true);
          this.sessionService.setShowAdvancedToolsSelectionData(true);
          this.close(true);
        })
      ).subscribe()
    );
  }

  updateSelectedAttributes = (attribute) => {
    const index = this.selectedAttributes.findIndex(attr => attr === attribute);
    if(index === -1) {
      this.selectedAttributes.push(attribute);
    } else {
      this.selectedAttributes.splice(index, 1);
    }
  }

  enableApply = () => {
    return this.selectedAttributes.length && this.joinType.valid;
  }

  removeFilterChip(attribute){
    this.select.options.forEach((item: MatOption) => {
      if (item.selected && (item.value == attribute)) {
        item.deselect()
      }
    });
      this.updateSelectedAttributes(attribute)
  }

  apply = () => {
    const payload = {
      input_layer_1:{
        layer_id: this.data.inputLayer.parent_layer_id,
        type: this.data.inputLayer.type
      },
      input_layer_2: {
        layer_id: this.data.joinLayer.parent_layer_id,
        type: this.data.joinLayer.type
      },
      input_params:{
        relationType: this.data.relations,
        joinType: this.joinType.value,
        joinFields: this.selectedAttributes,
        summaryStatus: false,
        summaryStatistics: []
      },
      feature_type: ADVANCED_TOOL_TYPE[this.data.selectedToolIndex],
      selection_type: "all",
      query: {limit: 100, page: 1}
    }
    this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    this.navigationService.setShowLayerTable(true);
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  close = (value = false) => {
    this.dialogRef.close(value);
  }
}