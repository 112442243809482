import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { GetUsersStartAction } from 'src/app/store/actions/user-management.actions';
import { usersSelector } from 'src/app/store/selectors/data-management.selector';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { faImage, faImages } from '@fortawesome/free-solid-svg-icons';
import { FeatureDetectionDialogComponent } from '../feature-detection-dialog/feature-detection-dialog.component';
import { TableDialogComponent } from '../table-dialog/table-dialog.component';

@Component({
  selector: 'app-restore-building-dialog',
  templateUrl: './restore-building-dialog.component.html',
  styleUrls: ['./restore-building-dialog.component.scss']
})
export class RestoreBuildingDialogComponent implements OnInit {
  premises: any[] = [];
  faImage = faImage;
  faImages = faImages;
  allBuildingsChecked: boolean = false;
  displayedColumns: Array<string> = ["checkbox"];
  approvalStatusObjPremise = {
    1: "Pending",
    2: "Approved",
    3: "Rejected",
    4: "Resurvey"
  };
  dataSource: any[] = [];
  user: Array<any> = [];
  subscriptionArr: any[] = [];
  featureDetectionDialog: MatDialogRef<FeatureDetectionDialogComponent>
  allowed_image_types = ['jpeg', 'png', 'jpg'];
  allowed_audio_types = ['x-wav', 'wav', 'wave'];
  allowed_video_types = ['mp4'];
  allowed_doc_types = ['msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'vnd.ms-powerpoint', 'vnd.openxmlformats-officedocument.presentationml.presentation', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'pdf', 'zip'];
  allowed_text_types = ['plain', 'csv'];
  @ViewChild("tableContainer", { read: ElementRef }) tableContainer: ElementRef<any>;
  result: boolean;

  constructor(public dialogRef: MatDialogRef<RestoreBuildingDialogComponent>, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, public DAMService: DataApprovalManagementService,
    private _store: Store<any>
  ) { }

  ngOnInit() {
    let tableData = {
      page: 1,
      limit: 100,
      filter: 5,
      search: ""
    };
    this._store.dispatch(new GetUsersStartAction(tableData));
    this._store.select(usersSelector).subscribe((users: any) => {
      if (Object.keys(users) && users.data && users.data.length > 0) {
        this.user = [...users.data].filter(user => user.user_role === 5);
      }
    })
    let payload = {
      mode: this.DAMService.selectedTab + 1,
      id: this.data.id
    }
    this.DAMService.restore(payload).subscribe((resp: any) => {
      if (Object.keys(resp).length > 0 && resp.data && resp.data.data.length > 0) {
        this.premises = resp.data.data[0].length > 1 ? resp.data.data[0] : resp.data.data
        // console.log(this.premises)
        let attributes = Object.keys(this.premises[0].properties)
        this.displayedColumns = [...this.displayedColumns, ...attributes,]
        // console.log(attributes)
        this.premises.map(item => {
          item['flag'] = ""
          if (item.is_deleted) {
            item['flag'] = "deleted";
          } else {
            item['flag'] = this.checkIfDataIsMarked(item.cityOsMetaData);
          }
        })
        this.dataSource = this.premises
      }
    })
  }

  scrollRight() {
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft + 600), behavior: 'smooth' });
  }

  scrollLeft() {
    let scrollBy = 0;
    const reminderScroll = this.tableContainer.nativeElement.scrollLeft % 600
    if (reminderScroll % 600 === 0) {
      scrollBy = 600;
    } else {
      scrollBy = reminderScroll;
    }
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft - scrollBy), behavior: 'smooth' });
  }

  checkBuilding(checked: boolean, element: any) {
    if (element) {
      element.checked = checked;
      this.allBuildingsChecked = this.dataSource.every((eachData) => eachData.checked);
    }
    else {
      this.allBuildingsChecked = checked
      this.dataSource.map(item => {
        item.checked = checked
      })
    }
  }

  checkIfDataIsMarked(cityOsMetaData): string {

    if (cityOsMetaData && cityOsMetaData.propertyAssessment && cityOsMetaData.propertyAssessment.markedForEditGeom === true) {
      return 'geometryEdit'
    } // as geometry Edit is high priority checking this first
    // if (!cityOsMetaData || !cityOsMetaData.propertyAssessment) {

    //   return "";
    // }
    if (cityOsMetaData && cityOsMetaData.propertyAssessment && cityOsMetaData.propertyAssessment.markedForSplit == true) {
      return "split";
    }
    if (cityOsMetaData && cityOsMetaData.propertyAssessment && cityOsMetaData.propertyAssessment.markedForMerge == true) {

      return "merge";
    } else if (cityOsMetaData && cityOsMetaData.propertyAssessment && cityOsMetaData.propertyAssessment.markedForDelete == true) {

      return "delete";
    }
    return '';


  }

  onCancel() {
    this.dialogRef.close(false);
  }

  getCount() {
    return this.dataSource.filter(item => item.checked).length;
  }

  onSubmit() {
    let premisesId = this.dataSource.filter((item) => item.checked).map(item => item._id)
    let payload = {
      mode: 1,
      id: this.data.id,
      force: true,
      premises: premisesId.length > 0 ? premisesId : []
    }
    if (this.dataSource.length) {
      let text = premisesId.length > 0 ? 'Would you like to Restore building and selected premises?' : 'Would you like to Restore building without premises?'
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          alertTitle: 'Restore Confirmation',
          alertText: text
        }
      })
      dialogRef.afterClosed().subscribe(resp => {
        if (resp) {
          this.DAMService.restore(payload).subscribe((response: any) => {
            if (response.success) {
              this.dialogRef.close(response);
            }
          })
        }
      })
    }
    else {
      this.DAMService.restore(payload).subscribe((response: any) => {
        if (response.success) {
          this.dialogRef.close(response);
        }
      })
    }

  }

  isALink(data) {
    // console.log(data)
    if (data && (data.toString().startsWith("https") || data.toString().startsWith("http"))) {
      // console.log(data)
      return true
    } else {
      return false
    }
  }
  previewData(data, element) {
    if (this.isALink(data)) {
      // check if image contains annotated image
      if (data.split(',')[0].split('.').splice(2)[0].includes("[annotatedImage]")) {
        // this._store.dispatch(new GetCollectedDataRowDetailsStartAction(element.id));
        this.featureDetectionDialog = this.dialog.open(FeatureDetectionDialogComponent, {
          width: "800px",
          hasBackdrop: true,
          data: { id: element.id }
        });
      } else {
        const ext = data.split(',')[0].split('.').splice(-1)[0].toLowerCase();
        let type = "";
        if (this.allowed_image_types.indexOf(ext) > -1) {
          if (data.split(',').length > 1) {
            type = FILE_TYPES[1];
            data = data.split(",");
          } else {
            type = FILE_TYPES[0];
          }
        } else if (this.allowed_audio_types.indexOf(ext) > -1) {
          type = FILE_TYPES[2]
        } else if (this.allowed_video_types.indexOf(ext) > -1) {
          type = FILE_TYPES[3]
        } else if (this.allowed_doc_types.indexOf(ext) > -1) {
          type = FILE_TYPES[4]
        } else {
          type = FILE_TYPES[4]
        }
        if (!type) return;
        if (type === FILE_TYPES[4]) {
          window.open(data, '_blank');
          return;
        }
        const dialogRef = this.dialog.open(TableDialogComponent, {
          width: "500px",
          hasBackdrop: true,
          data: {
            type,
            data,
          }
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          this.result = result;
        });
      }

    }

  }
  // onSkip(){
  //   let payload = {
  //     mode: 1,
  //     id: this.data.id,
  //     force: true,
  //     premises:[]
  //   }
  //   this.DAMService.restore(payload).subscribe((response: any) => {
  //     if (response.success) {
  //       this.dialogRef.close(response);
  //     }
  //   })
  // }
}
export const FILE_TYPES = ["image", "multi_image", "audio", "video", "document"];