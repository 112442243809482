import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';
import { Store } from '@ngrx/store';
import { HideLoaderAction } from 'src/app/store/actions/loader.actions';
import { cityosAdminTablePremiseSelector, propertyTaxEntryLogsSelector } from 'src/app/store/selectors/property-tax.selector';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';
import * as PropertyTaxActions from '../../store/actions/property-tax.actions';



@Component({
    selector: 'view-data',
    styleUrls: ['./view-data.component.scss'],
    templateUrl: './view-data.component.html'
})

export class ViewDataDialog implements OnDestroy {
    surveyorName: string = '';
    createdDate: string;
    geometryCreatedDate: string;
    updatedDate: string;
    approvedDate: string;
    resurveyDate: string;
    firstApprovedDate: string;
    modifiedActionText: string = '';
    date: any = ''
    name: string = ''
    approvalText: string = '';
    subscriptionArr: any[] = [];
    BID: String = '';
    PID: number = 0;
    logsArr: any[] = [];
    status: string = 'approve';
    deletedDate: string;
    restoredDate: string;
    isPremise: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<any>,
        public _store: Store<any>,
        public DAMService: DataApprovalManagementService

    ) {

    }
    ngOnDestroy() {
        this.subscriptionArr.map((eachSub) => eachSub.unsubscribe())
    }

    ngOnInit() {
        if (Object.keys(this.data.element).includes('BUILDING DB ID')) {
            this.isPremise = true
        }
        else {
            this.isPremise = false
        }
        this.subscriptionArr.push(this._store.select(propertyTaxEntryLogsSelector).subscribe((res: any) => {
            if (res && Object.keys(res).length) {
                this.logsArr = res.logs
                // console.log("logs", this.logsArr)
            }

        }))

        if (this.data.type === 'admin-table-data') {
            this.subscriptionArr.push(
                this._store.select(cityosAdminTablePremiseSelector).subscribe((resp: any) => {
                    if (resp && resp.data && resp.data.length) {
                        this.PID = resp.data.filter((eachData) => eachData.cityOsMetaData && eachData.cityOsMetaData.propertyAssessment && eachData.cityOsMetaData.propertyAssessment.markedForEditGeom).length;
                        this.BID = resp.data[0].properties['BID'];
                        this._store.dispatch(new HideLoaderAction())
                    } else {
                        this._store.dispatch(new HideLoaderAction())

                    }
                }, err => {
                    this._store.dispatch(new HideLoaderAction())

                })
            )
            let params = this.data.apiData;
            let payload = this.data.payload;
            this.status = this.getStringForApproveStatus(this.data.approvalStatus);
            this._store.dispatch(new PropertyTaxActions.GetAdminPremiseDataStarAction(params, payload));

        } else {
            this.surveyorName = this.data.element.surveyor_info ? this.data.element.surveyor_info.name : this.data.element.surveyorName ? this.data.element.surveyorName : '';
            this.createdDate = this.data.element.createdAt;
            this.geometryCreatedDate = this.createdDate || '';
            this.updatedDate = this.data.element.updatedAt || '';
            this.deletedDate = this.data.element.approval_deletion_date ? this.data.element.approval_deletion_date : '';
            this.restoredDate = this.data.element.restore_date ? this.data.element.restore_date : '';
            this.approvedDate = (this.data.element.re_approved_date) ? this.data.element.re_approved_date : '' || '';
            this.resurveyDate = (this.data.element.resurvey_date) ? this.data.element.resurvey_date : '' || '';
            this.firstApprovedDate = (this.data.element.approvedAt) ? this.data.element.approvedAt : '' || '';
            this.modifiedActionText = this.convertDataToText(this.data.element || {});
            this._store.dispatch(new PropertyTaxActions.GetPropertyTaxEntryLogsStartAction(this.data.element._id)); // action for getting entry logs

        }

    }
    convertDataToText(eachLog) {
        // if (!element.user_info) return ''
        // this.date = element.admin_analyst_last_modify.date;
        // this.name = element.user_info.name + `(${this.convertNumberToRole(element.user_info.user_role)})`;
        // this.approvalText = `${this.convertApprovalStatusToText(element.admin_analyst_last_modify.action)}`;
        return `recently modified status as `
    }


    close() {
        this.dialogRef.close();

    }
    convertApprovalStatusToText(number) {
        switch (number) {

            case 1:
                return 'Pending'
            case 2:
                return 'Approved'
            case 3:
                return 'Rejected'
            case 4:
                return 'Resurvey'
            case 5:
                return 'Edited'
            case 6:
                return 'Edit Geometry'
            default: ''
        }

    }
    convertNumberToRole(number) {
        switch (number) {
            case 1:
                return 'Admin'
            case 3:
                return 'Analyst'
            case 5:
                return 'Surveyor'
            default:
                ''
        }
    }
    buttonClicked() {

        if (this.data.type === 'admin-table-data') {
            this.dialogRef.close(true)
        } else {
            this.dialogRef.close();
        }
    }

    getStringForApproveStatus(approvalStatus) {

        switch (approvalStatus) {
            case 2:
                return 'approve'
            case 3:
                return 'reject'
            case 4:
                return 'resurvey'

        }
    }

    generateName(log) {

        console.log("Log is "+JSON.stringify(log))

        let name;
         name = log.logEntryName

         if(name==null){
            name = log.user ? log.user.name : "NA"

         }

      //  name = log.user ? log.user.name : "NA"
        return name
    }

    generateMSG(log) {
        switch (log.action) {
            case 'Geometry Created Date':
                return `(${this.convertNumberToRole(log.role)}) created the geometry`
            case 'Geometry Edited':
                return `(${this.convertNumberToRole(log.role)}) edited the geometry`
            case 'Data Collection Date':
                return `(${this.convertNumberToRole(log.role)}) collected the data`
            case 'Assessment status changed to Pending':
                return `(${this.convertNumberToRole(log.role)}) changed the Assessment status to Pending`
            case 'Assessment status changed to Done':
                return `(${this.convertNumberToRole(log.role)}) changed the Assessment status to Done`
            case 'Building Splits':
                return `(${this.convertNumberToRole(log.role)}) Splitted buildiing`
            case 'Building form modified':
                return `(${this.convertNumberToRole(log.role)}) Updated the building`
            case 'Building ID/Premises deleted':
                if (this.isPremise) {
                    return `(${this.convertNumberToRole(log.role)}) Deleted premise`
                }
                else {
                    return `(${this.convertNumberToRole(log.role)}) Deleted building`
                }
            case "Building Split - Approved":
                return `(${this.convertNumberToRole(log.role)}) Approved Splitted building`
            case "Geometry Approved on":
                return `(${this.convertNumberToRole(log.role)}) Approved the geometry`
            case "Geometry Rejected on":
                return `(${this.convertNumberToRole(log.role)}) Rejected the geometry`
            default:
                return `(${this.convertNumberToRole(log.role)}) ${log.action}`
        }
    }


}