import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';

import { PropertyTaxService } from './../../_services/property-tax.service';
import { UserWardAssignmentService } from './../../_services/user-ward-assignment.service';
import { ToastrService } from 'ngx-toastr';

import { Router, ActivatedRoute } from "@angular/router";

import { APIResponse, APIError } from '../../models/api-response'



import {
    PropertyTaxActionTypes,
    GetPropertyTaxData, GetPropertyTaxDataFailed, GetPropertyTaxDataSuccess,
    AddEditPropertyTaxData, AddEditPropertyTaxDataFailed, AddEditPropertyTaxDataSuccess,
    EditPropertyTaxData, EditPropertyTaxDataFailed, EditPropertyTaxDataSuccess,
    ListUsers, ListUsersSuccess,
    ListWards, ListWardsSuccess,
    UserWardAssignRevokeSuccess,
    UserWardAssignAndRevokeSuccess,
    CheckPropertyTaxAccess, CheckPropertyTaxAccessSuccess,
    Failed,
    GetPremiseFloorCollectedDataStartAction,
    GetPremiseFloorCollectedDataSuccessAction, SurveyorTierListViewStartAction, SurveyorTierViewListSuccessAction, EditGeometryStartAction, EditGeometrySuccessAction, AdminPanelDataStartAction, AdminPanelDataSuccessAction, ApproveGeometryStartAction, ApproveGeometrySuccessAction, GetFilteredDataStartAction, GetFilteredDataSuccessAction, GetBuildingSortStartAction, GetPremiseTableSortStartAction, GetAdminTableSortStartAction, GetCityOsAttributesSuccessAction, GetCityosLayerAttributesStartAction, GetAdminPremiseDataStarAction, GetAdminPremiseDataSuccessAction, GetPropertyTaxFormsStartAction, GetPropertyTaxFormsSuccessAction, GetPropertyTaxEntryLogsStartAction, GetPropertyTaxEntryLogsSuccessAction
} from './../actions/property-tax.actions';

import { PropertyTax } from './../../models/property-tax';
import { LogErrorAction } from '../actions/user-management.actions';
import { HttpErrorResponse } from '@angular/common/http';

import { UserWardAssignmentsBody } from "../../models/user-ward-assignments"
import { Action } from 'rxjs/internal/scheduler/Action';
import { UrbanLocalBodiesService } from 'src/app/app-management/cityos/urban-local-bodies/urban-local-bodies.service';
import { Store } from '@ngrx/store';
import { GetLayerPremiseTableDataStartAction, GetLayerTableDataStartAction } from '../actions/data-approval-management.actions';
import { LayerService } from 'src/app/_services';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';
import { HideLoaderAction } from '../actions/loader.actions';

@Injectable()
export class PropertyTaxEffects {

    constructor(
        private actions: Actions,
        private propertyTaxService: PropertyTaxService,
        private userWardAssignmentService: UserWardAssignmentService,
        private toaster: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private urbanLocalBodyService: UrbanLocalBodiesService,
        private _store: Store<any>,
        private layerService: LayerService,
        private DAMService: DataApprovalManagementService
    ) { }

    @Effect()
    getPropertyTaxData = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA),
        switchMap((action: GetPropertyTaxData) => {
            return this.propertyTaxService.getPropertyTaxData(action.payload).pipe(
                map((result: any) => {
                    if (result.success && result.data && result.data.length) {
                        return new GetPropertyTaxDataSuccess(result.data[0]);
                    }
                }),
                catchError((error: Error) => of(new GetPropertyTaxDataFailed(error)))
            )
        })
    ) //end of effect

    @Effect({ dispatch: false })
    getPropertyTaxDataFail: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA_FAILED),
        tap((action) => {
            try {
                let apiError: any = action.payload.error;
                let errors: { code: number, message: string }[] = apiError.error;
                if (errors && errors.length && errors[0] && errors[0].message) {
                    return this.toaster.error(errors[0].message);
                }
            } catch (error) {
                this.toaster.error("Property Tax Data is not initialised.");
            }
        })
    );


    @Effect({ dispatch: false })
    getPropertyTaxDataSuccess: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA_SUCCESS),
        tap((result) => {
            // this.toaster.success("Property tax data was fetched successfully.");
        })
    );

    @Effect()
    initializePropertyTaxData = this.actions.pipe(
        ofType(PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA),
        switchMap((action: AddEditPropertyTaxData) => {
            return this.propertyTaxService.initiatePropertyTaxData(action.payload).pipe(
                map((result: any) => {
                    if (result.success && result.data && result.data.length) {
                        return new AddEditPropertyTaxDataSuccess(result.data[0]);
                    }

                }),
                catchError((error: Error) => of(new AddEditPropertyTaxDataFailed(error)))
            );
        })
    );


    @Effect({ dispatch: false })
    addEditPropertyTaxDataFail: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA_FAILED),
        tap((action) => {
            let error: any = action.payload[0]
            this.toaster.error(error.message);
        })
    );


    @Effect({ dispatch: false })
    addEditPropertyTaxDataSuccess: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA_SUCCESS),
        tap((result) => {
            this.toaster.success("Property tax was initialised successfully.");
        })
    );

    @Effect()
    editPropertyTaxData = this.actions.pipe(
        ofType(PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA),
        switchMap((action: EditPropertyTaxData) => {
            let body: any = Object.assign({}, action.payload);
            let param: any = {
                id: body._id
            };
            delete body._id;
            return this.propertyTaxService.updatePropertyTaxData(body, param).pipe(
                map((result: any) => {
                    if (result.success && result.data && result.data.length) {
                        return new EditPropertyTaxDataSuccess(result.data[0]);
                    }
                }),
                catchError((error: Error) => of(new EditPropertyTaxDataFailed(error)))
            );
        })
    );


    @Effect({ dispatch: false })
    editPropertyTaxDataFail: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA_FAILED),
        tap((action) => {
            let error: any = action.payload[0]
            this.toaster.error(error.message);
        })
    );


    @Effect({ dispatch: false })
    editPropertyTaxDataSuccess: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA_SUCCESS),
        tap((result) => {
            this.toaster.success("Property tax was edited successfully.");
            new GetPropertyTaxData();
        })
    );

    @Effect()
    listUsers = this.actions.pipe(
        ofType(PropertyTaxActionTypes.LIST_USERS),
        switchMap((action: ListUsers) => {
            return this.userWardAssignmentService.getList(
                action.payload.params,
                action.payload.queryParams
            ).pipe(
                map((result: any) => {
                    // if (result.success && result.data && result.data.length) {
                    if (result.success && result.data) {
                        return new ListUsersSuccess(result.data, result.count);
                    }
                }),
                catchError((error: HttpErrorResponse) => of(new Failed(error)))
            )
        })
    ) //end of effect

    @Effect({ dispatch: false })
    listUsersSuccess: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.LIST_USERS_SUCCESS),
        tap((result) => {
            // this.toaster.success("Property tax data was fetched successfully.");
        })
    );

    @Effect()
    listWards = this.actions.pipe(
        ofType(PropertyTaxActionTypes.LIST_WARDS),
        switchMap((action: ListWards) => {
            return this.userWardAssignmentService.getList(
                action.payload.params,
                action.payload.queryParams
            ).pipe(
                map((result: any) => {
                    // if (result.success && result.data && result.data.length) {
                    if (result.success && result.data) {
                        return new ListWardsSuccess(
                            {
                                wards: result.data,
                                count: result.count,
                                userData: action.userData
                            });

                    }
                }),
                catchError((error: HttpErrorResponse) => of(new Failed(error)))
            )
        })
    ) //end of effect

    @Effect({ dispatch: false })
    listWardsSuccess: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.LIST_WARDS_SUCCESS),
        tap((result) => {
            // this.toaster.success("Property tax data was fetched successfully.");
        })
    );


    @Effect({ dispatch: false })
    failed: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.FAILED),
        tap((action) => {
            let httpErrorResponse: HttpErrorResponse = action.payload;
            let apiError: APIResponse = httpErrorResponse.error;
            let errors: APIError[] = apiError.error;
            if (errors.length && errors[0].message) {
                this.toaster.error(errors[0].message);
            }
            // else {
            //     this.toaster.error("Error");
            // }
        })
    );

    @Effect()
    userWardAssignRevoke = this.actions.pipe(
        ofType(PropertyTaxActionTypes.USER_WARD_ASSIGN_REVOKE),
        switchMap((action: EditPropertyTaxData) => {
            return this.userWardAssignmentService.userWardAssignRevoke(action.payload).pipe(
                map((result: any) => {
                    if (result.success && result.data && result.data.length) {
                        return new UserWardAssignRevokeSuccess(result.data[0]);
                    }
                }),
                catchError((error: HttpErrorResponse) => of(new Failed(error)))
            );
        })
    );


    @Effect({ dispatch: false })
    userWardAssignRevokeSuccess: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.USER_WARD_ASSIGN_REVOKE_SUCCESS),
        tap((result) => {
            this.toaster.success("user - wards assignment is completed successfully.");
            this.router.navigate(['/app-management/cityos/survey-management'], { relativeTo: this.activatedRoute });
        })
    );

    @Effect()
    userWardAssignAndRevoke$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.USER_WARD_ASSIGN_AND_REVOKE),
        switchMap(
            (
                action:
                    {
                        type: string,
                        payload: {
                            assign: UserWardAssignmentsBody,
                            revoke: UserWardAssignmentsBody
                        }
                    }) => {

                return forkJoin(
                    this.userWardAssignmentService.userWardAssignRevoke(action.payload.assign),
                    this.userWardAssignmentService.userWardAssignRevoke(action.payload.revoke)
                ).pipe(
                    map((result: [APIResponse, APIResponse]) => {
                        if (result.length == 2) {
                            return new UserWardAssignRevokeSuccess({});
                        }
                    }),
                    catchError((error: HttpErrorResponse) => of(new Failed(error)))
                )
            })
    );



    @Effect()
    checkAccessPropertyTax$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.CHECK_ACCESS_PROPERTY_TAX),
        switchMap((action: CheckPropertyTaxAccess) => {
            return this.propertyTaxService.checkAccessPropertyTax().pipe(
                map((result: any) => {
                    // if (result.success && result.data && result.data.length) {
                    if (result.success && result.data && result.data[0] && result.data[0].cityOs) {
                        return new CheckPropertyTaxAccessSuccess(true);
                    }
                    return new CheckPropertyTaxAccessSuccess(false);
                }),
                catchError((error: HttpErrorResponse) => of(new CheckPropertyTaxAccessSuccess(false)))
            )
        })
    ) //end of effect

    @Effect({ dispatch: false })
    checkAccessPropertyTaxSuccess$: Observable<any> = this.actions.pipe(
        ofType(PropertyTaxActionTypes.CHECK_ACCESS_PROPERTY_TAX_SUCCESS),
        tap((result: boolean) => {
        })
    );

    @Effect()
    floorValues = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PREMISE_FLOOR_COLLECTED_DATA_START),
        switchMap((action: GetPremiseFloorCollectedDataStartAction) => {
            return this.propertyTaxService.getFloorTableData(action.layerId).pipe(
                map((response: any) => {
                    return new GetPremiseFloorCollectedDataSuccessAction(response.data);
                }),
                catchError((error: Error) => of(new LogErrorAction(error)))
            )
        })
    )
    @Effect()
    tiewViewList$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_SURVEYOR_TIER_VIEW_LIST_START_ACTION),
        switchMap((action: SurveyorTierListViewStartAction) => {
            return this.urbanLocalBodyService.getSelectedTierList().pipe(
                map((resp: any) => {
                    return new SurveyorTierViewListSuccessAction(resp)
                }),
                catchError((error: Error) => of(new LogErrorAction(error)))
            )
        }))

    @Effect()
    editGeometry$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.EDIT_GEOMETRY_START_ACTION),
        switchMap((action: EditGeometryStartAction) => {
            return this.propertyTaxService.editGeometry(action.id, action.payload).pipe(
                map((resp: any) => {
                    return new EditGeometrySuccessAction(resp)
                }),
                catchError((err: Error) => of(new LogErrorAction(err)))
            )
        })
    )
    @Effect()
    getPanelData$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_ADMIN_PANEL_DATA_START_ACTION),
        switchMap((action: AdminPanelDataStartAction) => {
            return this.propertyTaxService.getAdminDataData(action.payload).pipe(
                map((resp: any) => {
                    return new AdminPanelDataSuccessAction(resp)
                }),
                catchError((err: Error) => of(new LogErrorAction(err)))
            )
        })
    )
    @Effect()
    approveDataByAdmin$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.APPROVE_GEOMETRY_START_ACTION),
        switchMap((action: ApproveGeometryStartAction) => {
            return this.propertyTaxService.approveDataByAdmin(action.payload).pipe(
                map((resp: any) => {
                    return new ApproveGeometrySuccessAction(resp)
                }),
                catchError((err: Error) => of(new LogErrorAction(err)))
            )
        })
    )

    @Effect()
    filteredData$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_FILTERED_DATA_START_ACTION),
        switchMap((action: GetFilteredDataStartAction) => {
            return this.urbanLocalBodyService.getFilteredData(action.tierNumber, action.payload).pipe(
                map((resp: any) => {
                    return new GetFilteredDataSuccessAction({...resp, selectedTierNumber:action.tierNumber})
                }),
                catchError((err: any) => of(new LogErrorAction(err)))
            )
        })
    )

    @Effect({ dispatch: false })
    buildingSort$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_BUILDING_TABLE__SORT_START_ACTION),
        map((action: GetBuildingSortStartAction) => {
            this._store.dispatch(new GetLayerTableDataStartAction(action.payload))
        }),
        catchError((err: any) => of(new LogErrorAction(err)))

    )

    @Effect({ dispatch: false })
    premiseSort$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PREMISE_TABLE_SORT_START_ACTION),
        map((action: GetPremiseTableSortStartAction) => {
            this._store.dispatch(new GetLayerPremiseTableDataStartAction(action.params, action.payload))
        }),
        catchError((err: any) => of(new LogErrorAction(err)))

    )

    @Effect({ dispatch: false })
    adminSort$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_ADMIN_TABLE_SORT_START_ACTION),
        map((action: GetAdminTableSortStartAction) => {
            this._store.dispatch(new GetLayerTableDataStartAction(action.payload))
        }),
        catchError((err: any) => of(new LogErrorAction(err)))
    )
    @Effect()
    cityosLayerttributes$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_CITY_OS_ATTRIBUTE_START_ACTION),
        switchMap((action: GetCityosLayerAttributesStartAction) => {
            return this.layerService.getLayerAttributes(action.layerId).pipe(map((resp) => {
                return new GetCityOsAttributesSuccessAction({ attributes: resp, index: action.index })
            }), retry(1),
                catchError((err: any) => of(new LogErrorAction(err)))
            )

        })
    )
    @Effect()
    propertyTaxPremise$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_ADMIN_PANEL_PREMISE_DATA_START_ACTION),
        switchMap((action: GetAdminPremiseDataStarAction) => {
            return this.DAMService.getLayerPremiseTableData(action.params, action.payload).pipe(map((resp: any) => {
                if (resp && resp.success) {

                    return new GetAdminPremiseDataSuccessAction(resp.data)
                } else {
                    if (resp.error.length > 0) {
                        this._store.dispatch(new HideLoaderAction())
                        this.toaster.error(resp.error[0].message)
                    }
                }
            }),
                catchError((err: Error) => {
                    this._store.dispatch(new HideLoaderAction())
                    return of(new LogErrorAction(err))
                }))
        })
    )

    @Effect()
    propertyTaxForms$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PROPERTY_TAX_FORMS_START_ACTION),
        switchMap((action: GetPropertyTaxFormsStartAction) => {
            return this.DAMService.getPropertyTaxForms().pipe(map((resp: any) => {
                if (resp && resp.success) {
                    return new GetPropertyTaxFormsSuccessAction(resp.data)
                }
            }),
                catchError((err: Error) => of(new LogErrorAction(err)))
            )
        })
    )

    @Effect()
    propertyTaxEntryLogs$ = this.actions.pipe(
        ofType(PropertyTaxActionTypes.GET_PROPERTY_TAX_ENTRY_LOGS_START_ACTION),
        switchMap((action: GetPropertyTaxEntryLogsStartAction) => {
            return this.DAMService.getPropertyTaxEntryLogs(action.id).pipe(map((resp: any) => {
                if (resp && resp.success) {
                    return new GetPropertyTaxEntryLogsSuccessAction(resp.data)
                }
            }),
            catchError((err: Error) => of(new LogErrorAction(err)) )
            )
        })
    )


}

