import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
    styleUrls: ['./snap-dialog.component.scss'],
    templateUrl: './snap-dialog.component.html',
    selector: 'snap-dialog'
})

export class SnapDialogComponent {
    selectedMode: any = null;
    availableModes : any[]= ['Vertex', 'Segment', 'Vertex and Segment']
    constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    closeDialog(event) {
        if (event) {
            this.dialogRef.close(this.selectedMode)
        } else {
            this.dialogRef.close('')
        }
    }
}