import gradient from 'gradient-color';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';
export class Utilities {

  constructor() { }

  getSymbologyOption(type, color) {
    let options: any = {
      maxZoom: 20,
      tolerance: 3,
      debug: 0,
      style: {
        fillColor: color,
        color,
        opacity: 0.5,
        weight: 2,
        fillOpacity: 0.5
      }
    };

    if (type === 'polygon' || type === 'multipolygon') {
      options = {
        maxZoom: 20,
        tolerance: 3,
        debug: 0,
        style: {
          fillColor: color,
          color,
          opacity: 0.5,
          weight: 2,
          fillOpacity: 0.01
        }
      };
    } else {
      options = {
        maxZoom: 20,
        tolerance: 3,
        debug: 0,
        style: {
          fillColor: color,
          color,
          opacity: 0,
          weight: 0,
          fillOpacity: 0
        }
      };
    }

    return options;
  }

  cleanupRichText(richText) {
    return richText.replace(/rgb\((.+?)\)/ig, (_, rgb) => {
      return '#' + rgb.split(',')
        .map(str => parseInt(str, 10).toString(16).padStart(2, '0'))
        .join('');
    });
  }

  getGradientColors(colorA, colorB, count) {
    const colors = gradient([
      colorA,
      colorB
    ], count);
    const hexColors = colors.map(color => this.cleanupRichText(color));
    return hexColors;
  }

  getYearFromApoch(val) {
    return +val * 1000;
  }
  setCurrencyformat(val: number, currency: string) {
    switch (currency) {
      case 'INR':
      
        return (val.toLocaleString('en-IN'))
      case 'USD':
        return val.toLocaleString('en-US')

    }
  }

 genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1500,
  excludedStatusCodes = []
}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => console.log('We are done!'))
  );
};


}
