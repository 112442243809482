import { Injectable } from "@angular/core";
import { API } from "./API.service";
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import connProp from '../../assets/connection.properties.json';
import { Subject, Observable, BehaviorSubject, fromEvent, of, Subscription } from 'rxjs';
import { GEOMETRY_TYPES, MODE_OBJ } from '../app.constants';
import { SessionService } from './session.service';
import { cloneDeep } from 'lodash'
import { feature } from '@turf/turf';
import { debounceTime, retry, switchMap, takeLast, throttleTime } from 'rxjs/operators';
import { FormArray } from '@angular/forms';
import { UserWardDataService } from "../app-management/cityos/survey-management/user-ward-data.service";

@Injectable()
export class DataApprovalManagementService {
  map = null;
  bounds: any = null;
  private showMap = new Subject<boolean>();
  showMap$ = this.showMap.asObservable();
  public resizeMap = new Subject<boolean>();
  resizeMap$ = this.resizeMap.asObservable();
  connProps: any = connProp;
  _url = `${this.connProps.propertyTaxTableData}`;
  url = `${this.connProps.exportPropertyTaxData}`;
  mapboxDrawObj: any = {};
  editGeometryStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  invalidError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  editGeometryInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  snappingStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedLayer: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedLayerId: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedLayerType: BehaviorSubject<string> = new BehaviorSubject(null);
  editedGeometryCoordinates: BehaviorSubject<any> = new BehaviorSubject([]);
  editedGeometryType: BehaviorSubject<any> = new BehaviorSubject("");
  selectedFeature: any = null;
  snappableCoordinate: any = null;
  newDrawObj: any;
  layers: any[] = [];
  surveyorList: any[] = [];
  selectedAOILayer: BehaviorSubject<any> = new BehaviorSubject({});
  selectedModeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  drawObjCoords: any = []
  verticesOfNearestFeature: any;
  selectedMode: any;
  segmentClickSubscription: any;
  vertexAndSegmentClickSubscription: any;
  graphicData: any = {};
  recentlyEdittedGeometry: Subject<any> = new Subject();
  reloadStatusWhenEdited: Subject<any> = new Subject();
  urbanLocalBodyResp: BehaviorSubject<any> = new BehaviorSubject({});
  drawModeActive: boolean;
  subscriptionArr: any
  layerCheckOnHover: string = '';
  buildingRawData: any = {};
  mergeFeatureIndex: number = -1;
  returnedCoordObj: { coordArr: any[]; index: number; } = {
    coordArr: [],
    index: -1
  }
  vertexAndSegmentDblClickSubscription: any;
  draggedPointInFeatureObject: any = {};
  mousemoveSubscription: Subscription;
  updateSubscription: Subscription;
  geoJsonData: {};
  lineStringFeature: any;
  linePointCLickSubscription: any;
  lineMeasureGeoJsonData: any = {};
  lineMeasurePointGeoJsonData: any = [];
  mouseDblClickSubscription: any;
  createSubscription: any;
  appliedFilter = false;

  polygonMeasureGeoJsonData: any = {}
  polygonFeature: any = {};
  polygonPointGeoJsonData: any = [];
  polygonLineStringFeature: any = {}
  showPopupSubject: Subject<any> = new Subject();
  updatedFeature: { id: string; type: string; properties: {}; geometry: { type: any; coordinates: any; }; };
  updatedFeatureSubject: Subject<any> = new Subject();
  dragFeatureSubject: Subject<any> = new Subject();
  snapEnabled: boolean = false;
  surveyorCollectedStatus: boolean = true;
  tierFormArray: FormArray;
  filterSavingFormat: any = {};
  closeTable$: Subject<any> = new Subject();
  selectedSurveyor: any = {};
  buildingFilters: {
    startDate: string,
    endDate: string,
    date: string,
    assessmentDate: string,
    surveyor: string,
    ward: string,
    approvalStatus: number,

  } = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: "",
      assessmentDate: "",
    };
  premiseFloor: boolean = true;

  filterPremise: {
    floor: string
    premiseFloor: boolean
    totalFloor: any[]
  } = {
      floor: "",
      premiseFloor: this.premiseFloor,
      totalFloor: []
    }

  filterdate: {
    startDate: string,
    endDate: string,
    surveyor: string,
    ward: string,
    approvalStatus: number,
    date: string,
    first_assessment_startDate: String,
    first_assessment_endDate: String,

    last_assessment_startDate: String,
    last_assessment_endDate: String,

    sync_startDate: String,
    sync_endDate: String
  } = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: "",
      first_assessment_startDate: '',
      first_assessment_endDate: '',
      last_assessment_startDate: "",
      last_assessment_endDate: "",
      sync_startDate:'',
      sync_endDate:''
    }
  selectedTab: number = 0;
  searchedBuildingValue: string = '';
  searchedPremiseValue: string = '';
  searchedAdminValue: string = '';
  currentBuildingPageNumber: number = 1;
  currentPremisePageNumber: number = 1;
  currentAdminPageNumber: number = 1;
  buildingIds: any[] = [];
  premiseIds: any[] = [];
  adminBuildingIds: any[] = [];
  selectedBuildingOption: number = 10;
  selectedPremiseOption: number = 10;
  selectedAdminPanelOption: number = 10;
  selectedSortObject: any = {};
  selectedSortObjectArray: any[] = [];
  buildingTableExactSearch: boolean = false;
  premiseTableExactSearch: boolean = false;
  adminTableExactSearch: boolean = false;

  buildingDataField: any = {}
  premiseTableDataField: any = {};
  adminBuildingFilters: {
    startDate: string,
    endDate: string,
    surveyor: string,
    ward: string,
    approvalStatus: number,

  } = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,

    };
  adminFilterDate: {
    startDate: string,
    endDate: string,
    surveyor: string,
    ward: string,
    approvalStatus: number,
    date: string
  } = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: ""
    }
  adminBuildingDataField: any = {};
  adminTierFormArray: FormArray;
  datamanagementSelectedLayer: BehaviorSubject<any> = new BehaviorSubject({});
  tableDataChange: BehaviorSubject<any> = new BehaviorSubject(true);
  buildingIdsWithoutGeometryEdit: any[] =[];
  layersToBeQueried: any[] =[]
  constructor(
    private apiService: API,
    private sessionService: SessionService
  ) { }

  initCtrl(map, bounds) {
    this.map = map;
    this.bounds = bounds;
  }

  getLayerTableData(payload) {

    const subURL = this._url + 'buildings'
    return this.apiService.getNew(subURL, true, false, payload);
  }

  postLayerTableData(payload) {

    let reqObj = {
      subURL: this._url + 'fiter-wardwise',
      datum: payload
    }

    const subURL = this._url + 'fiter-wardwise'
    return this.apiService.post(reqObj, true,'');
  }

  getBuildingsData(layerEntryId) {
    const subURL = this._url + "?layerEntryId=" + layerEntryId;
    return this.apiService.get(subURL, true, false)
  }

  updateBuildingData(params, payload) {

    console.log("Test")

    const subURL = this._url + 'building/' + params
    return this.apiService.putNew({ subURL, datum: payload }, true)
  }

  updatePremiseData(params, payload) {
    const subURL = this._url +  'premise/' + params
    return this.apiService.putNew({ subURL, datum: payload }, true)
  }

  getLayerPremiseTableData(params, payload) {
    // let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //const subURL = this._url +`premises?approvalStatus=${params.approvalStatus}&limit=${params.limit}&page=${params.page}`
    // const subURL = this._url + `premises?${queryString}`
    delete params['totalFloor'];
    let filteredParams = Object.keys(params).reduce((prev, curr) => {
      if ([undefined, null].includes(params[curr])) return prev
      prev[curr] = params[curr]
      return prev
    }, {})
    let reqObj = {
      subURL: `${this._url}` + 'premises',
      datum: payload
    }
    return this.apiService.post(reqObj, true, '', filteredParams);
  }

  approvePremise(payload) {
    const subURL = this._url + 'premises'
    return this.apiService.patch({ subURL, datum: payload }, true);
  }

  approveBuilding(payload) {
    const subURL = this._url + 'buildings'
    return this.apiService.patch({ subURL, datum: payload }, true);
  }

  restore(payload){
    let reqObj = {
      subURL: `${this.connProps.propertyTaxRestore}${payload.mode}/${payload.id}`,
      datum: payload
    }
    return this.apiService.post(reqObj, true, '');
    const subURL =  `${this.connProps.propertyTaxRestore}${payload.mode}/${payload.id[0]}`
    return this.apiService.post(subURL,true)
  }

  exportData(payload) {

    console.log("TEST 123"+JSON.stringify(payload))

   
    const subURL = this.url + '1';
    let reqObj = {};
    reqObj['subURL'] = subURL;
    reqObj['datum'] = {}



    if(payload.wardData==true){
      console.log("Testing XYZ"+payload.wardIds)
      reqObj['datum'] = { wardIds: payload.wardIds }
      payload.type = "xlsx"
    }



    if (payload.buildingId) {

      if(payload.wardIds){
        reqObj['datum'] = { wardIds: payload.wardIds }
      }else{
        if(payload.buildingId.length>0){
          reqObj['datum'] = { buildingIds: payload.buildingId,selected:true }
        
        }else{
          reqObj['datum'] = { buildingIds: payload.buildingId,selected:false }
        }
    

      }

   
      delete payload['buildingId'];
    }
    reqObj['datum']['timeZone'] = payload.timeZone;
    
    delete payload['timeZone'];

    if(payload.wardData==true){
      reqObj['subURL'] = subURL;
      console.log("Testing March 1")
      return this.apiService.postNew(reqObj,true,payload,false,{})
      
    }else{
    return this.apiService.postNew(reqObj, true, payload, false, { });
    }
  }
  exportPremiseData(params, payload) {
    // let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log("Comes here 1");
    const subURL = this._url + `premises`;
    payload['is_deleted'] = false;
    let reqObj = {};
    reqObj['datum'] = {}
    reqObj['subURL'] = subURL;

    if(payload.wardIds){
      reqObj['datum'] = { wardIds: payload.wardIds,export:true}
    }else{

    if (payload.buildingIds) {

      if(payload.buildingIds.length>0){
      
        params.selected=true;

      }else{
       
        params.selected = false;

      }

  

      reqObj['datum'] = { buildingIds: payload.buildingIds || [], export: payload.export, 'export_type': payload.export_type };
      
    } else {
      reqObj['datum'] = { export: payload.export, 'export_type': payload.export_type }
    }
    if (payload.premiseIds) {
     // reqObj['datum']['premiseIds'] = payload.premiseIds;

      if(payload.premiseIds.length>0){
        reqObj['datum']['premiseIds'] = payload.premiseIds;
        reqObj['datum']['selected'] = true;
        params.selected=true;

      }else{
        reqObj['datum']['premiseIds'] = payload.premiseIds;
        reqObj['datum']['selected'] = false;
        params.selected = false;

      }
    }
  }
    reqObj['datum']['timeZone'] = payload.timeZone;
    reqObj['datum']['is_deleted'] = payload.is_deleted;
    // reqObj['datum']['showData'] = payload.showData;
    params['showData'] = payload.showData;
    params['selected'] = true;
    delete payload['showData'];
    delete payload['timeZone'];
    params['selected'] = true;
    console.log("Params"+JSON.stringify(params))
    
    return this.apiService.postNew(reqObj, true, params, false, {  });
  }

  shareData(data: boolean) {
    this.showMap.next(data);
  }

  shareMapData(data: boolean) {
    this.resizeMap.next(data);
  }
  convertDataToFormData(obj: any, form?: null, namespace?: null) {

    console.log("OBJJJ"+obj)

    let fd: any = form || new FormData();
    let formKey;
    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property] !== null && obj[property] !== undefined) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          if (property == "data") {
            for (let key in obj[property]) {
              if (obj[property][key] instanceof File) {
                fd.append("data" + "[" + `${key}` + "]", obj[property][key]);
              } else {
                if (Array.isArray(obj[property][key])) {
                  obj[property][key].forEach((element, index) => {
                    if (element instanceof File) {
                      fd.append("data" + "[" + `${key}` + "]", element)
                      // fd.append("data" + "[" + `${key}` + "]" + "[" + `${index}` + "]", element)
                    } else {
                      fd.append("data" + "[" + `${key}` + "]" + "[" + `${index}` + "]", element)
                    }
                  });
                } else {
                  // for feature detection widget
                  if (typeof obj[property][key] === 'object' && obj[property][key] != null) {
                    Object.keys(obj[property][key]).forEach(element => {
                      if (Array.isArray(obj[property][key][element])) {
                        obj[property][key][element].forEach((ele, i) => {
                          Object.keys(ele).forEach((value) => {
                            if (Array.isArray(obj[property][key][element][i][value])) {
                              obj[property][key][element][i][value].forEach((val, j) => {
                                fd.append("data" + "[" + `${key}` + "]" + "[" + `${element}` + "]" + "[" + `${i}` + "]" + "[" + `${value}` + "]" + "[" + `${j}` + "]", obj[property][key][element][i][value][j])
                              });

                            } else {
                              fd.append("data" + "[" + `${key}` + "]" + "[" + `${element}` + "]" + "[" + `${i}` + "]" + "[" + `${value}` + "]", obj[property][key][element][i][value])
                            }

                          });
                        });
                      } else {
                        fd.append("data" + "[" + `${key}` + "]" + "[" + `${element}` + "]", obj[property][key][element])
                      }
                    });
                  } else {
                    fd.append("data" + "[" + `${key}` + "]", obj[property][key])
                  }

                }
              }
            }

          }
          else {
            this.convertDataToFormData(obj[property], fd, formKey);
          }

        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }


    console.log("FDDD"+JSON.stringify(fd))
    return fd;
  }

  showEditableFeature(data?) {
    if (!data) return;
    try {
      if (this.mapboxDrawObj && Object.keys(this.mapboxDrawObj).length) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Removing Draw object. ", error);
    }

    if (!(data.type || data.coordinates)) {
      this.removeFeature('feature-draw-id');
      this.selectedFeature = null;
    } else {
      try {
        this.removeFeature('feature-draw-id');
      } catch (error) {
        console.log("cannot remove feature, because it does not exist on map");
      }
      this.mapboxDrawObj = new MapboxDraw({
        displayControlsDefault: false,
      });
      try {
        this.map.addControl(this.mapboxDrawObj);
      } catch (error) {
        console.log("Could not add control because it already exists. ", error);
      }
      this.selectedFeature = data;
      this.mapboxDrawObj.changeMode(MODE_OBJ[data.type]);
      this.drawObjCoords = data.coordinates;
      let featureCollection = {
        "type": "FeatureCollection",
        "features": [{
          id: 'feature-draw-id',
          type: 'Feature',
          properties: {},
          geometry: { type: GEOMETRY_TYPES[data.type], coordinates: data.coordinates }
        }]

      }
      // let feature = {

      // };
      this.mapboxDrawObj.add(featureCollection);

      try {
        this.mapboxDrawObj.changeMode('direct_select', { featureId: 'feature-draw-id' });
        this.snapPolygon(null);
      } catch (error) {
        console.log("error: ", error);
      }
      fromEvent(this.map, 'draw.update').subscribe((e: any) => {
        this.updateCoordinates(e, 'snap')
      })
    }
  }
  updateCoordinates(e, type) {
    let event = cloneDeep(e)
    if (!event.features.length || e.features[0].geometry.type === 'Point') return;
    if (type !== 'measure') {

      this.editedGeometryType.next(event.features[0].geometry.type);
    }
    if (event.features && event.features[0].geometry.type === 'MultiPolygon') {
      this.draggedPointInFeatureObject = this.indexOfFeatureWhichIsDragged(e);
    }
    if (this.snappableCoordinate && this.snappableCoordinate.length) {
      let coordlen: number = 0;
      let coordinates = []
      let ind: number = -1;
      if (event.features[0].geometry.type === 'MultiPolygon') {

        event.features[0].geometry.coordinates[this.draggedPointInFeatureObject.arrayIndexChanged][0][this.draggedPointInFeatureObject.innerArrayIndexChanged] = this.snappableCoordinate;
        coordinates = event.features[0].geometry.coordinates[this.draggedPointInFeatureObject.arrayIndexChanged][0]
        if (this.draggedPointInFeatureObject.innerArrayIndexChanged == 0) event.features[0].geometry.coordinates[this.draggedPointInFeatureObject.arrayIndexChanged][0][coordinates.length - 1] = this.snappableCoordinate;
        coordlen = coordinates.length;
        ind = this.draggedPointInFeatureObject.arrayIndexChanged
      } else if (event.features[0].geometry.type === 'Polygon') {
        ind = this.getNewCoordinateAddedToFeature(e.features[0].geometry.coordinates[0]);
      } else if (event.features[0].geometry.type === "LineString") {
        ind = this.getNewCoordinateAddedToFeature(e.features[0].geometry.coordinates)
      }

      if (ind !== -1) {
        if (event.features[0].geometry.type == 'Polygon') {
          coordinates = event.features[0].geometry.coordinates[0];
          coordlen = event.features[0].geometry.coordinates[0].length;
        } else if (event.features[0].geometry.type === 'LineString') {
          coordinates = event.features[0].geometry.coordinates;
          coordlen = event.features[0].geometry.coordinates.length;
        }
        this.replacePlottedFeature(ind, coordlen, coordinates); // add the feature with snappable coordinate
      } else {
        this.selectedFeature.coordinates = event.features[0].geometry.coordinates;
      }

    } else {
      this.editedGeometryCoordinates.next(e.features[0].geometry.coordinates);
      this.selectedFeature.coordinates = e.features[0].geometry.coordinates;
      this.setEditedGeometryCoordinates(e.features[0].geometry.coordinates);
    }
    this.dragFeatureSubject.next(this.mapboxDrawObj.getAll().features[0])
  }

  replacePlottedFeature(ind, coordlen, coordinates) {
    let source = this.map.getSource("mapbox-gl-draw-hot");
    if (source._data && source._data.features && source._data.features.length) {
      let features = source._data.features;
      features.forEach(feature => {
        if (["Polygon"].includes(feature.geometry.type)) {

          feature.geometry.coordinates[0][ind] = this.snappableCoordinate;
          if (ind === 0) feature.geometry.coordinates[0][coordlen - 1] = this.snappableCoordinate;
        }
        if (['LineString'].includes(feature.geometry.type)) {
          feature.geometry.coordinates[ind] = this.snappableCoordinate;
        }
      });
      let updatedFeature: any = {};
      if (features[0].geometry.type === 'MultiPolygon') {
        let coords = features[0].geometry.coordinates;
        coords[this.draggedPointInFeatureObject.arrayIndexChanged][0] = coordinates; // passing changed coords in here
        updatedFeature = {
          id: 'feature-draw-id',
          type: 'Feature',
          properties: {},
          geometry: { type: GEOMETRY_TYPES[this.selectedFeature.type], coordinates: coords }
        }

      } else {

        updatedFeature = {
          id: 'feature-draw-id',
          type: 'Feature',
          properties: {},
          geometry: { type: GEOMETRY_TYPES[this.selectedFeature.type], coordinates: features[0].geometry.coordinates }
        }
      }
      this.addNewDrawObjfeature(updatedFeature)
      this.setEditedGeometryCoordinates(updatedFeature.geometry.coordinates);
      this.selectedFeature.coordinates = updatedFeature.geometry.coordinates;
      this.editedGeometryCoordinates.next(updatedFeature.geometry.coordinates);
      // after adding everything
    }
  }

  getNewCoordinateAddedToFeature(newCoords: any) {
    let featureCoordinates = [];
    if (this.selectedFeature.type === 'MultiPolygon') {
      featureCoordinates = this.selectedFeature.coordinates[0][0]

      // featureCoordinates = this.selectedFeature.coordinates.map((coordArr) => coordArr[0])[0]
    } else if (this.selectedFeature.type === 'Polygon') {
      featureCoordinates = this.selectedFeature.coordinates[0];
    } else if (this.selectedFeature.type = 'LineString') {
      featureCoordinates = this.selectedFeature.coordinates;
    }
    let ind = null;
    ind = newCoords.findIndex((coord, i) => ((coord[0] !== featureCoordinates[i][0]) || (coord[1] !== featureCoordinates[i][1])));
    // check whether only one point or every point is changed;
    if (ind == 0) { // if it's zero check the next array if it's changed then whole feature is dragged
      let wholeFeatureDragged = false;
      for (let i = 1; i < newCoords.length - 1; i++) {
        if ((newCoords[i][0] !== featureCoordinates[i][0]) || (newCoords[i][1] !== featureCoordinates[i][1])) {
          wholeFeatureDragged = true
          break;
        }
      }
      if (wholeFeatureDragged) {
        return -1
      } else {
        return ind
      }
    } else {
      return ind
    }
  }

  snapPolygon(mode) {
    let queryFeatures: any = [];
    if (this.mousemoveSubscription) this.mousemoveSubscription.unsubscribe();
    this.mousemoveSubscription = fromEvent(this.map, 'mousemove').pipe(debounceTime(150)).subscribe((e: any) => {
      if (!(this.snappingStatus.getValue() && this.editGeometryInProgress.getValue())) return;
      let layer = {}
      if (this.selectedMode === 'Vertex') {

        layer = {
          'id': 'gl-draw-point-static',
          'type': 'circle',
          'paint': {
            'circle-radius': 10,
            'circle-color': 'blue',
            "circle-opacity": 0.2,
            "circle-stroke-color": 'blue',
            "circle-stroke-width": 1
          },
          'source': 'hoveredSource'
        }
      } else if (this.selectedMode === 'Segment') {
        layer = {
          'id': 'gl-draw-point-static',
          "type": 'symbol',
          'layout': {
            'icon-image': 'cross',
            'icon-size': 2
          },
          "paint": {
            'icon-color': 'blue',
            'icon-opacity': 1
          },
          'source': 'hoveredSource'
        }
      } else {
        layer = {
          'id': 'gl-draw-point-static',
          "type": 'symbol',
          'layout': {
            'icon-image': 'cross',
            'icon-size': 2
          },
          "paint": {
            'icon-color': 'blue',
            'icon-opacity': 1
          },
          'source': 'hoveredSource'
        }
      }
      let geoJson = {
        "type": 'FeatureCollection',
        "features": []
      };
      if (this.snapEnabled) {
        if (!this.map.getSource('hoveredSource')) {
          this.map.addSource('hoveredSource', {
            "type": 'geojson',
            data: geoJson,
            tolerance: 3
          })
        }
      }


      if (this.snapEnabled) {
        queryFeatures = this.getQueryFeatures(e);
        if (!this.map.getLayer('gl-draw-point-static')) {
          this.map.addLayer(layer)
        }
      }

      // let coordinates = queryFeatures.map((eachFeature) => eachFeature.geometry['coordinates'])[0]
      if (queryFeatures && queryFeatures.length) {
        let featureCollection: any = {};
        // for polygon 
        featureCollection = {
          type: 'FeatureCollection',
          features: queryFeatures.reduce((prev, curr) => {
            let obj: any = {};
            obj.type = 'Feature';
            obj.geometry = curr.geometry;
            return [...prev, obj]
          }, [])
        }
        let closestVertexFeature: any;
        if (this.selectedMode === 'Vertex') {
          let explode = turf.explode(featureCollection)
          closestVertexFeature = turf.nearest(turf.point([e.lngLat.lng, e.lngLat.lat]), explode);
        } else if (this.selectedMode === 'Segment') {

          let feature = featureCollection.features[0]
          this.verticesOfNearestFeature = feature.geometry.coordinates;
          let featureData = turf.polygonToLine(feature);
          closestVertexFeature = turf.nearestPointOnLine(featureData, turf.point([e.lngLat.lng, e.lngLat.lat]));
          if (this.segmentClickSubscription) this.segmentClickSubscription.unsubscribe();
          this.segmentClickSubscription = fromEvent(this.map, 'click').subscribe((e) => {
            this.snapCoordinatesOnClick(closestVertexFeature)
          })

        } else if (this.selectedMode === 'Vertex and Segment') {
          //  vertex and segment goes here

          let verticesOfFeatures: any = this.getVerticesOfFeature(featureCollection)
          let feature = featureCollection.features[0];
          this.verticesOfNearestFeature = feature.geometry.coordinates;
          let featureData: any = turf.polygonToLine(feature); // converts polygon or multipolygon feature to line feature
          let multipleFeaturesToSingleFeature = this.getMultipolygonOrPolygonToSingleFeature(featureData);
          closestVertexFeature = this.getNearestPointOnLine(multipleFeaturesToSingleFeature, e);
          if (mode === null) {
            if (this.vertexAndSegmentClickSubscription) this.vertexAndSegmentClickSubscription.unsubscribe();
            this.vertexAndSegmentClickSubscription = fromEvent(this.map, 'click').subscribe((e) => {
              this.snapCoordinatesOnClick(closestVertexFeature);
            });
          }

          let closestPoint = closestVertexFeature.geometry.coordinates;

          let checkWhetherVertex = this.getWhetherVertexOrSegmentIsClosestFeature(verticesOfFeatures, closestPoint)

          this.addRespectiveLayerForHoveredSource(checkWhetherVertex, closestVertexFeature, 'snapTool') // adding layer in this method
        }


      } else {
        if (this.map.getLayer('gl-draw-point-static')) {
          this.map.removeLayer('gl-draw-point-static')
        }
        this.snappableCoordinate = null;
      }
      this.dragFeatureSubject.next(this.mapboxDrawObj.getAll().features[0])
    })


  }


  removeFeature(id) {
    if (!id && !Object.keys(this.mapboxDrawObj).length) return
    try {
      this.mapboxDrawObj.delete(id);
    } catch (error) {
      console.log("Error removing feature. ", error);
    }
  }

  removeDrawObjects() {
    try {
      if (this.mapboxDrawObj) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Error removing draw object mapboxDrawObj. ", error);
    }
  }

  removeSnappingIndecator() {
    if (this.map.getSource("hoveredSource")) {
      if (this.map.getLayer('gl-draw-point-static')) this.map.removeLayer("gl-draw-point-static");
      this.map.removeSource("hoveredSource");
    }
  }

  setEditGeometryStatus(val: boolean) {
    this.editGeometryStatus.next(val);
  }

  setInvalidError(val: boolean) {
    this.invalidError.next(val);
  }

  getEditGeometryStatus(): Observable<boolean> {
    return this.editGeometryStatus.asObservable();
  }

  getInvalidError(): Observable<boolean> {
    return this.invalidError.asObservable();
  }

  setSnappingStatus(val: boolean) {
    this.snappingStatus.next(val);
  }

  getSnappingStatus(): Observable<boolean> {
    return this.snappingStatus.asObservable();
  }

  setSelectedLayerId(val: string) {
    this.selectedLayerId.next(val);
  }

  getSelectedLayerId(): Observable<string> {
    return this.selectedLayerId.asObservable();
  }

  setSelectedLayerType(val: string) {
    this.selectedLayerType.next(val);
  }

  getSelectedLayerType(): Observable<string> {
    return this.selectedLayerType.asObservable();
  }

  setEditGeometryInProgress(val: boolean) {
    this.editGeometryInProgress.next(val);
  }

  getEditGeometryInProgress(): Observable<boolean> {
    return this.editGeometryInProgress.asObservable();
  }

  setEditedGeometryCoordinates(val: any) {
    this.editedGeometryCoordinates.next(val);
  }

  getEditedGeometryCoordinates(): Observable<any> {
    return this.editedGeometryCoordinates.asObservable();
  }

  setEditedGeometryType(val: string) {
    this.editedGeometryType.next(val);
  }

  getEditedGeometryType(): Observable<string> {
    return this.editedGeometryType.asObservable();
  }

  getTableDataChange(): Observable<string> {
    return this.tableDataChange.asObservable();
  }

  setSelectedLayer(val: any) {
    this.datamanagementSelectedLayer.next(val);
  }

  getSelectedLayer(): Observable<any> {
    return this.datamanagementSelectedLayer.asObservable();
  }

  setSelectedMode(val) {
    this.selectedMode = val;
    this.selectedModeSubject.next(val);
  }

  getSelectedMode(): Observable<any> {
    return this.selectedModeSubject
  }
  addNewDrawObjfeature(feature, type = 'snappable') {
    this.mapboxDrawObj.deleteAll(); //deletes all the features 
    if (type === 'addition') {

      if (feature.geometry.type === 'MultiPolygon') {
        // feature.geometry.coordinates = [[feature.geometry.coordinates]]

      } else if (feature.geometry.type === 'Polygon') {
        feature.geometry.coordinates = [feature.geometry.coordinates];
      }
    }
    this.mapboxDrawObj.add(feature);
    this.mapboxDrawObj.changeMode('direct_select', { featureId: 'feature-draw-id' });

  }

  snapCoordinatesOnClick(closestVertexFeature) {
    closestVertexFeature = this.snapEnabled ? closestVertexFeature : {};
    if (closestVertexFeature && Object.keys(closestVertexFeature).length) {
      let drawnFeature: any;
      let edittedFeature: any = {};
      if (this.mapboxDrawObj) {
        drawnFeature = this.mapboxDrawObj.get('feature-draw-id');
      }
      let coordinates = [];
      if (drawnFeature && drawnFeature.geometry.type === 'MultiPolygon') {
        // check which polygon got clicked
        let coordinateArr = drawnFeature.geometry.coordinates;
        // drawnFeature.geometry.coordinates.forEach((coordinateArr) => {
        this.returnedCoordObj = this.getRequiredCoordinateArr(coordinateArr, closestVertexFeature);
        coordinates = this.returnedCoordObj.coordArr[0]

      } else {
        coordinates = drawnFeature.geometry.coordinates[0];
      }
      for (let i = 0; i < coordinates.length - 1; i++) {
        // first get two points
        let firstPointFeature = turf.point(coordinates[i]);
        let secondPointFeature = turf.point(coordinates[i + 1]);
        let distanceBetweenVertices = turf.distance(firstPointFeature, secondPointFeature, { units: 'miles' });

        let closestFirstPointDistance = turf.distance(firstPointFeature, closestVertexFeature, { units: 'miles' });
        let closestSecondPointDistance = turf.distance(closestVertexFeature, secondPointFeature, { units: 'miles' });
        if (this.sessionService.toDecimalString(distanceBetweenVertices, 5) === (this.sessionService.toDecimalString((closestFirstPointDistance + closestSecondPointDistance), 5))) {
          coordinates.splice(i + 1, 0, closestVertexFeature.geometry.coordinates);
          break;
        }

      }
      if (drawnFeature.geometry.type === 'MultiPolygon') {
        let coordsArr = [...drawnFeature.geometry.coordinates];
        coordsArr.splice(this.returnedCoordObj.index, 1, this.returnedCoordObj.coordArr)
        edittedFeature = {
          id: 'feature-draw-id',
          type: 'Feature',
          properties: {},
          geometry: { type: GEOMETRY_TYPES[this.selectedFeature.type], coordinates: coordsArr }
        }

      } else {
        edittedFeature = {
          id: 'feature-draw-id',
          type: 'Feature',
          properties: {},
          geometry: { type: GEOMETRY_TYPES[this.selectedFeature.type], coordinates: coordinates }
        }
      }
      this.addNewDrawObjfeature(edittedFeature, 'addition');
      this.setEditedGeometryCoordinates(edittedFeature.geometry.coordinates);

      // if (drawnFeature && drawnFeature.geometry.type === 'MultiPolygon') {
      //   this.selectedFeature.coordinates = [...edittedFeature.geometry.coordinates]
      // } else {
      //   this.selectedFeature.coordinates = [coordinates]
      // }
      this.selectedFeature.coordinates = [...edittedFeature.geometry.coordinates]
    }

  }
  setRecentlyEdittedGeometry(editedData) {
    this.recentlyEdittedGeometry.next(editedData)
  }
  getRecentlyEdittedGeometry(): Observable<any> {
    return this.recentlyEdittedGeometry.asObservable();
  }


  getRequiredCoordinateArr(coordinateArr, closestVertexFeature) {
    let coordArrWithClickedPoint = [];
    let index: number = 0
    for (let coordArrIndex = 0; coordArrIndex < coordinateArr.length; coordArrIndex++) {
      for (let i = 0; i < coordinateArr[coordArrIndex][0].length - 1; i++) {
        let firstPointFeature = coordinateArr[coordArrIndex][0][i];
        let secondPointFeature = coordinateArr[coordArrIndex][0][i + 1];
        let distanceBetweenVertices = turf.distance(firstPointFeature, secondPointFeature, { units: 'miles' });
        let closestFirstPointDistance = turf.distance(firstPointFeature, closestVertexFeature, { units: 'miles' });
        let closestSecondPointDistance = turf.distance(closestVertexFeature, secondPointFeature, { units: 'miles' });
        if (this.sessionService.toDecimalString(distanceBetweenVertices, 5) === (this.sessionService.toDecimalString((closestFirstPointDistance + closestSecondPointDistance), 5))) {
          coordArrWithClickedPoint = coordinateArr[coordArrIndex]
          index = coordArrIndex
          break;
        }
      }
      if (coordArrWithClickedPoint.length) {
        break;
      }
    }
    return { coordArr: coordArrWithClickedPoint, index }

  }
  indexOfFeatureWhichIsDragged(event) {

    // use draw.selectionchange  for checking required mode
    let notUpdatedCoordinates = this.selectedFeature.coordinates
    let updatedCoordinates = event.features[0].geometry.coordinates;
    let arrayIndexChanged: number = -1;
    let innerArrayIndexChanged: number = -1;
    let count: number = 0;
    for (let i = 0; i < notUpdatedCoordinates.length; i++) {
      let firstArrayTobeCompared = notUpdatedCoordinates[i];
      let secondArrayToBeCompared = updatedCoordinates[i];
      for (let notUpdatedArrayIndex = 0; notUpdatedArrayIndex < firstArrayTobeCompared[0].length - 1; notUpdatedArrayIndex++) {
        if ((firstArrayTobeCompared[0][notUpdatedArrayIndex][0] !== secondArrayToBeCompared[0][notUpdatedArrayIndex][0]) && (firstArrayTobeCompared[0][notUpdatedArrayIndex][1] !== secondArrayToBeCompared[0][notUpdatedArrayIndex][1])) {
          count++;
          if (count > 1) { // if count is greater than one whole feature is dragged
            arrayIndexChanged = -1;
            innerArrayIndexChanged = -1;
            break;
          } else {
            arrayIndexChanged = i;
            innerArrayIndexChanged = notUpdatedArrayIndex; // dragged point index in feature is here
          }

        }
      }
      if (arrayIndexChanged !== -1) {
        break;
      }

    }
    return { arrayIndexChanged, innerArrayIndexChanged }

  }


  drawRespectiveMode(type) {
    let queryFeatures = [];
    let closestVertexFeature: any = {};
    this.removeSubscribtionsAndBufferData();
    if (this.mapboxDrawObj && Object.keys(this.mapboxDrawObj).length) {
      try {
        this.map.removeControl(this.mapboxDrawObj)
      } catch {
        console.log('unable to remove drawObject')
      }
      if (this.vertexAndSegmentClickSubscription) this.vertexAndSegmentClickSubscription.unsubscribe();
    }
    let layers = this.map.getStyle().layers.filter(({ id }) => id.includes('measure')).map(({ id }) => id);
    [...layers, 'gl-draw-point-static'].forEach(layer => {
      if (this.map.getLayer(layer)) {

        this.map.removeLayer(layer)
      }
    });
    ['lineMeasureSource', 'polygonMeasureSource'].forEach((source) => {
      if (this.map.getSource(source)) {
        this.map.removeSource(source)
      }
    })

    if (type) {
      this.drawModeActive = true;
      this.mouseDblClickSubscription = fromEvent(this.map, 'dblclick').subscribe((e: any) => {
        let doubleClick = this.dblClickonMap.bind(this);
        doubleClick(type, e);
        this.showPopupSubject.next(true);
        this.updatedFeatureSubject.next(this.updatedFeature)
      })
      if (this.vertexAndSegmentClickSubscription) this.vertexAndSegmentClickSubscription.unsubscribe();
      this.vertexAndSegmentClickSubscription = fromEvent(this.map, 'click').subscribe((e) => {
        let clicked;
        let iTimeOut;
        if (clicked) {
          clicked = false;
          clearTimeout(iTimeOut);
        } else {
          clicked = true;
          iTimeOut = setTimeout(() => {
            this.snapCoordinatesOnClickForMeasureTool(e, closestVertexFeature, type, 'click')
          }, 200)
        }
      });
      if (this.mousemoveSubscription) this.mousemoveSubscription.unsubscribe();
      this.mousemoveSubscription = fromEvent(this.map, 'mousemove').pipe(debounceTime(50)).subscribe((e: any) => { // changing source whenevr any place is hovered
        let geoJson = { // this geojson is for point hovering
          "type": 'FeatureCollection',
          "features": []
        };
        if (this.snapEnabled) {

          if (!this.map.getSource('hoveredSource')) {
            this.map.addSource('hoveredSource', {
              "type": 'geojson',
              data: geoJson,
              tolerance: 3
            })
          }
          queryFeatures = this.getQueryFeatures(e);
        }
        if (queryFeatures && queryFeatures.length) {

          let featureCollection: any = {};
          // for polygon 
          featureCollection = {
            type: 'FeatureCollection',
            features: queryFeatures.reduce((prev, curr) => {
              let obj: any = {};
              obj.type = 'Feature';
              obj.geometry = curr.geometry;
              return [...prev, obj]
            }, [])
          }
          let verticesOfFeatures = this.getVerticesOfFeature(featureCollection);
          let feature = featureCollection.features[0];
          this.verticesOfNearestFeature = feature.geometry.coordinates;
          let featureData: any = turf.polygonToLine(feature);
          let multipleFeaturesToSingleFeature = this.getMultipolygonOrPolygonToSingleFeature(featureData);
          closestVertexFeature = this.getNearestPointOnLine(multipleFeaturesToSingleFeature, e);
          this.snapCoordinatesOnClickForMeasureTool.apply(this, [e, closestVertexFeature, type, 'mousemove'])

          let closestPoint = closestVertexFeature.geometry.coordinates;

          let checkWhetherVertex = this.getWhetherVertexOrSegmentIsClosestFeature(verticesOfFeatures, closestPoint);

          this.addRespectiveLayerForHoveredSource(checkWhetherVertex, closestVertexFeature, 'measuretool') // adding layer in this method

        } else {
          closestVertexFeature = {};
          if (this.snapEnabled && this.map.getSource('hoveredSource')) {
            this.map.getSource('hoveredSource').setData(geoJson);
          }
          this.snapCoordinatesOnClickForMeasureTool(e, {}, type, 'mousemove')
        }
      })




      if (type === 'lineMeasure') {
        this.lineMeasureGeoJsonData = {   // maintaing array of features in line_string
          "type": "FeatureCollection",
          "features": []
        }
        this.lineStringFeature = {
          "type": "Feature",
          "geometry": {
            "type": "LineString",
            "coordinates": []
          },
          "properties": {
            "id": String(new Date().getTime())
          }
        };
        if (!this.map.getSource('lineMeasureSource')) {
          this.map.addSource('lineMeasureSource', {
            type: 'geojson',
            data: this.lineMeasureGeoJsonData
          })
        }


        if (!this.map.getLayer('measure-lines-text')) {
          this.map.addLayer({
            id: 'measure-lines-text',
            type: 'symbol',
            source: 'lineMeasureSource',
            layout: {
              'text-field': ['get', 'distance'],
              'text-font': ['literal', ['Open Sans Regular', "Open Sans Regular"]],
              'text-anchor': 'left',
              'text-size': 9,
              'text-offset': [2.5, -1.5]
            },
            paint: {
              'text-color': '#000',
              'text-opacity': 1
            }
          })
        }

      } else if (type === 'polygonMeasure') {
        // polygon addition goes here
        this.polygonMeasureGeoJsonData = {   // maintaing array of features in line_string
          "type": "FeatureCollection",
          "features": []
        }
        this.polygonFeature = {
          "type": "Feature",
          "geometry": {
            "type": "Polygon",
            "coordinates": []
          },
          "properties": {
            "id": String(new Date().getTime())
          }
        };

        this.polygonLineStringFeature = {

          "type": 'Feature',
          "geometry": {
            "type": 'LineString',
            "coordinates": []

          },
          "properties": {
            "id": String(new Date().getTime())
          }
        };

        if (!this.map.getSource('polygonMeasureSource')) {
          this.map.addSource('polygonMeasureSource', {
            type: 'geojson',
            data: this.polygonMeasureGeoJsonData
          })
        }

      }
      // after adding necessary sources and geo-json data in it adding layers
      if (!this.map.getLayer('measure-draw-line')) {
        this.map.addLayer({
          'id': 'measure-draw-line',
          'type': 'line',
          "source": (type == 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource',

          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 2
          }
        })
      }
      if (!this.map.getLayer('measure-points')) {
        this.map.addLayer({
          id: 'measure-points',
          type: 'circle',
          source: (type == 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource',
          paint: {
            'circle-radius': 5,
            'circle-color': '#FFF'
          },
          filter: ['in', '$type', 'Point']
        });
      }
      if (!this.map.getLayer('measure-inner-points')) {
        this.map.addLayer({
          id: 'measure-inner-points',
          type: 'circle',
          "source": (type === 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource',
          "filter": ['in', '$type', 'Point'],
          "paint": {
            "circle-radius": 3,
            "circle-color": "#D20C0C",
          }
        })
      }
      if (!this.map.getLayer('measure-polygon-fill')) {
        this.map.addLayer({
          "id": "measure-polygon-fill",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          "source": (type === 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource',
          "paint": {
            "fill-color": "#D20C0C",
            "fill-outline-color": "#D20C0C",
            "fill-opacity": 0.1
          }

        })
      }

      if (!this.map.getLayer('measure-polygon-stroke-active')) {

        this.map.addLayer({
          "id": "measure-polygon-stroke-active",
          "type": "line",
          "source": (type === 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource',
          "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 2
          }

        })
      }

      if (!this.map.getLayer('measure-polygon-stroke-static')) {

        this.map.addLayer({
          "id": "measure-polygon-stroke-static",
          "type": "line",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
          "source": (type === 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource',
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#000",
            "line-width": 3
          }
        })
      }




    } else {
      this.drawModeActive = false;
    }

  }
  dblClickonMap(type, e) {
    this.mouseDblClickSubscription.unsubscribe(); //only need once double clicked
    if (type) {
      this.map.doubleClickZoom.disable();
      if (this.updateSubscription) this.updateSubscription.unsubscribe();
      if (this.createSubscription) this.createSubscription.unsubscribe();
      if (this.mousemoveSubscription) this.mousemoveSubscription.unsubscribe();
      let Source = (type === 'lineMeasure') ? 'lineMeasureSource' : 'polygonMeasureSource'
      let layersTobeRemoved = this.map.getStyle().layers.filter(({ source }) => source === Source).map(({ id }) => id);
      let requiredModeSource = this.map.getStyle().sources[Source].data.features.find(({ geometry }) => geometry.type === ((type === 'lineMeasure') ? 'LineString' : 'Polygon'));
      layersTobeRemoved.forEach((layerId, i) => {
        this.map.removeLayer(layerId);
        if (i === layersTobeRemoved.length - 1) {
          this.map.removeSource(Source);
        }
      })
      this.updatedFeature = {
        id: 'feature-draw-id',
        type: 'Feature',
        properties: {},
        geometry: { type: GEOMETRY_TYPES[requiredModeSource.geometry.type], coordinates: requiredModeSource.geometry.coordinates }
      }
      if (Object.keys(this.mapboxDrawObj).length) {
        try {
          this.mapboxDrawObj.deleteAll();
        } catch {
          console.log('no feature there to delete')
        }
      }
      this.mapboxDrawObj = new MapboxDraw({
        displayControlsDefault: false
      })
      try {
        this.map.addControl(this.mapboxDrawObj);
      } catch (error) {
        console.log("Could not add control because it already exists. ", error);
      }
      this.mapboxDrawObj.add(this.updatedFeature);
      this.mapboxDrawObj.changeMode('direct_select', { featureId: 'feature-draw-id' });

      this.selectedMode = 'Vertex and Segment';

      if (this.map.getSource('hoveredSource')) this.map.getSource('hoveredSource').setData({
        type: 'FeatureCollection',
        features: []
      })
      this.updateSubscription = fromEvent(this.map, 'draw.update').subscribe((e: any) => {
        this.updateCoordinates(e, 'measure');
      })
      switch (type) {
        case 'lineMeasure':
          let obj = {
            type: 'LineString',
            coordinates: this.updatedFeature.geometry.coordinates
          }
          this.selectedFeature = cloneDeep(obj);
          this.snapPolygon('measure');
          break;

        case 'polygonMeasure':
          let pObj = {
            type: 'Polygon',
            coordinates: this.updatedFeature.geometry.coordinates
          }
          this.selectedFeature = cloneDeep(pObj);
          this.snapPolygon('measure');
          break;
      }
    }
  }


  getQueryFeatures(e) {
    let bBox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5]
    ]
    return this.map.queryRenderedFeatures(bBox, {
      layers: [...this.layersToBeQueried]
    });

  }

  // vertices of each feature collected into single form

  getVerticesOfFeature(featureCollection) {

    return featureCollection.features.reduce((prev, curr) => {
      if (curr.geometry.type === 'Polygon') {
        prev.push(curr.geometry.coordinates[0])
      } else if (curr.geometry.type === 'MultiPolygon') {
        let multiPolygonCoordinates = [];
        multiPolygonCoordinates = curr.geometry.coordinates.map((coordArr) => coordArr[0])
        prev.push(...multiPolygonCoordinates)
      }
      return prev
    }, [])

  }

  // convert multipolygon to single feature of line string

  getMultipolygonOrPolygonToSingleFeature(featureData) {

    let multipleFeaturesToSingleFeature: any = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: []
      }
    }
    if (featureData.type === 'FeatureCollection') {
      multipleFeaturesToSingleFeature.geometry.coordinates = featureData.features.reduce((prev, curr) => {
        return [...prev, ...curr.geometry.coordinates]
      }, [])
    } else {
      multipleFeaturesToSingleFeature = featureData
    }

    return multipleFeaturesToSingleFeature

  }

  getNearestPointOnLine(multipleFeaturesToSingleFeature, e) {
    return turf.nearestPointOnLine(multipleFeaturesToSingleFeature, turf.point([e.lngLat.lng, e.lngLat.lat]));
  }

  getWhetherVertexOrSegmentIsClosestFeature(verticesOfFeatures, closestPoint) {
    let checkWhetherVertex: boolean = false
    for (let eachArrayIndex = 0; eachArrayIndex < verticesOfFeatures.length; eachArrayIndex++) {
      for (let innerArrayIndex = 0; innerArrayIndex < verticesOfFeatures[eachArrayIndex].length; innerArrayIndex++) {
        if ((verticesOfFeatures[eachArrayIndex][innerArrayIndex][0] === closestPoint[0]) && (verticesOfFeatures[eachArrayIndex][innerArrayIndex][1] === closestPoint[1])) {
          checkWhetherVertex = true;
          break;
        }
      }
      if (checkWhetherVertex) {
        break;
      }
    }
    return checkWhetherVertex

  }

  addRespectiveLayerForHoveredSource(checkWhetherVertex, closestVertexFeature, type) {
    let nearestFeaturePoint: mapboxgl.LngLat;

    let layerCheckOnHover: string = ''
    if (checkWhetherVertex) {
      layerCheckOnHover = 'vertex'
    } else {
      layerCheckOnHover = 'segment'
    }
    if (this.layerCheckOnHover !== layerCheckOnHover) {

      if (this.map.getLayer('gl-draw-point-static')) {   // need to do this condition only when mode chanages
        this.map.removeLayer('gl-draw-point-static')
      }
      switch (layerCheckOnHover) {
        case 'vertex':
          this.map.addLayer(VERTEXLAYER);
          this.layerCheckOnHover = 'vertex'
          break;
        case 'segment':
          this.map.addLayer(SEGMENTLAYER);
          this.layerCheckOnHover = 'segment'
          break;

      }

    }
    if (closestVertexFeature && Object.keys(closestVertexFeature).length && this.map.getSource('hoveredSource')) {
      nearestFeaturePoint = closestVertexFeature.geometry.coordinates
      this.map.getSource('hoveredSource').setData(closestVertexFeature);
      this.snappableCoordinate = nearestFeaturePoint;
    }

  }

  snapCoordinatesOnClickForMeasureTool(e, closestVertexFeature: any = {}, type, mouseEvent) {
    closestVertexFeature = (this.snapEnabled) ? closestVertexFeature : {};
    if (!this.map.getLayer('measure-points')) return // this is for checking double click
    let features = [];
    if (mouseEvent === 'click') {

      features = this.map.queryRenderedFeatures(e.point, { layers: ['measure-points'] });
    }

    if (features.length) { // removes feature when clicked on it
      var id = features[0].properties.id;
      let coordinatesToBeRemoved = [];
      if (type === 'lineMeasure' && this.map.getSource('lineMeasureSource')) {
        this.lineMeasureGeoJsonData.features = this.lineMeasureGeoJsonData.features.filter(function (point) {
          if (point.properties.id === id) {
            coordinatesToBeRemoved = point.geometry.coordinates;
          }
          return point.properties.id !== id;
        });
        let indexOfCoordinateToBeRemoved = this.lineMeasureGeoJsonData.features.findIndex(eachFeature => eachFeature.geometry.type === "LineString");
        let coordsOfLineString = this.lineMeasureGeoJsonData.features[indexOfCoordinateToBeRemoved].geometry.coordinates;
        coordsOfLineString = [...coordsOfLineString].filter((eachCoord) => ((eachCoord[0] !== coordinatesToBeRemoved[0]) && eachCoord[1] !== coordinatesToBeRemoved[1]));
        this.lineMeasureGeoJsonData.features[indexOfCoordinateToBeRemoved].geometry.coordinates = [...coordsOfLineString];
        this.map.getSource('lineMeasureSource').setData(this.lineMeasureGeoJsonData);

      } else if (type === 'polygonMeasure') {
        this.polygonMeasureGeoJsonData.features = this.polygonMeasureGeoJsonData.features.filter((point) => {
          if (point.properties.id === id) {
            coordinatesToBeRemoved = point.geometry.coordinates;
          }
          return point.properties.id !== id
        })
        let lineIndexOfCoordinateToBeRemoved = this.polygonMeasureGeoJsonData.features.findIndex(eachFeature => eachFeature.geometry.type === "LineString");
        let polygonIndexOfCoordinateToBeRemoved = this.polygonMeasureGeoJsonData.features.findIndex(eachfeature => eachfeature.geometry.type === 'Polygon');
        if (lineIndexOfCoordinateToBeRemoved !== -1) {

          let coordsOfLineString = this.polygonMeasureGeoJsonData.features[lineIndexOfCoordinateToBeRemoved].geometry.coordinates;
          coordsOfLineString = [...coordsOfLineString].filter((eachCoord) => ((eachCoord[0] !== coordinatesToBeRemoved[0]) && eachCoord[1] !== coordinatesToBeRemoved[1]));
          this.polygonMeasureGeoJsonData.features[lineIndexOfCoordinateToBeRemoved].geometry.coordinates = [...coordsOfLineString];
        }
        if (polygonIndexOfCoordinateToBeRemoved !== -1 && this.map.getSource('polygonMeasureSource')) {

          let coordsOfPolygon = this.polygonMeasureGeoJsonData.features[polygonIndexOfCoordinateToBeRemoved].geometry.coordinates[0];
          let coordToBeRemovedIndex = [...coordsOfPolygon].findIndex((eachCoord) => (eachCoord[0] === coordinatesToBeRemoved[0] && eachCoord[1] === coordinatesToBeRemoved[1]));
          if (coordToBeRemovedIndex === 0) {
            coordsOfPolygon.splice(0, 1);
            coordsOfPolygon.splice(coordsOfPolygon.length - 1, 1);
            coordsOfPolygon.push(coordsOfPolygon[0]);
          } else {
            coordsOfPolygon.splice(coordToBeRemovedIndex, 1);
          }
          this.polygonMeasureGeoJsonData.features[polygonIndexOfCoordinateToBeRemoved].geometry.coordinates = [coordsOfPolygon];
          this.map.getSource('polygonMeasureSource').setData(this.polygonMeasureGeoJsonData)
        }
      }
      // remove the existing point in line_string also that part is pending
    } else {
      let ID = String(new Date().getTime())
      let pointFeature = {
        "type": 'Feature',
        "geometry": {
          "type": "Point",
          "coordinates": [e.lngLat.lng, e.lngLat.lat]
        },
        "properties": {
          "id": ID,
          "distance": ''
        }
      }
      if (this.snapEnabled) {
        pointFeature.geometry.coordinates = Object.keys(closestVertexFeature).length ? closestVertexFeature.geometry.coordinates : [e.lngLat.lng, e.lngLat.lat];
      }
      if (type === 'lineMeasure') {
        if (mouseEvent === 'click') {
          this.lineMeasurePointGeoJsonData.push(pointFeature);
          // this.lineMeasurePointGeoJsonData.forEach((point, index) => {  // this case is only need when there is showing of line measure in kilometers
          //   point.properties.distance = '';
          //   if (index > 0) {
          //     const lastPoint = this.lineMeasurePointGeoJsonData[index - 1];
          //     const distance = turf.distance(lastPoint.geometry.coordinates, point.geometry.coordinates)
          //     if (distance > 0) {
          //       const finalVal = this.sessionService.toDecimalString(distance, 3);
          //       const finalStr = finalVal + ' KM';
          //       point.properties.distance = finalStr;
          //     }
          //   }
          // })
        }
      } else if (type === 'polygonMeasure') {

        if (mouseEvent === 'click') {
          this.polygonPointGeoJsonData.push(pointFeature);
        }

      }

      if (mouseEvent === 'click') {
        if (type === 'lineMeasure') {

          this.lineStringFeature.geometry.coordinates.splice(this.lineStringFeature.geometry.coordinates.length - 1, 1, pointFeature.geometry.coordinates, pointFeature.geometry.coordinates);

        } else if (type === 'polygonMeasure') {
          if (!this.polygonPointGeoJsonData.length) return // no need of checking if there is no point
          if (this.polygonPointGeoJsonData.length === 1) {
            let coords = pointFeature.geometry.coordinates;
            this.polygonLineStringFeature.geometry.coordinates = [coords, coords]
          } else if (this.polygonPointGeoJsonData.length == 2) {
            let firstPoint = this.polygonPointGeoJsonData[0].geometry.coordinates;
            let secondPoint = this.polygonPointGeoJsonData[1].geometry.coordinates;
            this.polygonLineStringFeature.geometry.coordinates.splice(this.polygonLineStringFeature.geometry.coordinates.length - 1, 1, pointFeature.geometry.coordinates);

            this.polygonFeature.geometry.coordinates[0] = [firstPoint, secondPoint, secondPoint, firstPoint];
          } else {
            this.polygonLineStringFeature = null;
            this.polygonFeature.geometry.coordinates[0].splice(this.polygonFeature.geometry.coordinates[0].length - 2, 1, pointFeature.geometry.coordinates, pointFeature.geometry.coordinates)
          }
        }
      } else { // hovered case here we need to splice the last value
        if (type === 'lineMeasure') {
          this.lineStringFeature.geometry.coordinates.splice(this.lineStringFeature.geometry.coordinates.length - 1, 1, pointFeature.geometry.coordinates)
        } else if (type === 'polygonMeasure') {
          if (!this.polygonPointGeoJsonData.length) return // no need of checking if ther eis no point
          // if point coords length is one
          if (this.polygonPointGeoJsonData.length === 1) {

            this.polygonLineStringFeature.geometry.coordinates.splice(this.polygonLineStringFeature.geometry.coordinates.length - 1, 1, pointFeature.geometry.coordinates);
          } else if (this.polygonPointGeoJsonData.length >= 2) {
            if (this.polygonPointGeoJsonData.length === 2) {
              this.polygonLineStringFeature.geometry.coordinates.splice(this.polygonLineStringFeature.geometry.coordinates.length - 1, 1, pointFeature.geometry.coordinates);
            }
            this.polygonFeature.geometry.coordinates[0].splice(this.polygonFeature.geometry.coordinates[0].length - 2, 1, pointFeature.geometry.coordinates);
          }
        }
      }
      let features = (type === 'lineMeasure') ? this.lineMeasureGeoJsonData.features : this.polygonMeasureGeoJsonData.features;
      if (type === 'lineMeasure') {
        let lineStringIndex = features.findIndex((eachFeature) => eachFeature.geometry.type === 'LineString');
        if (this.lineMeasureGeoJsonData.features && this.lineMeasureGeoJsonData.features.length) {

          if (lineStringIndex == -1) {
            this.lineMeasureGeoJsonData.features.push(this.lineStringFeature);
          } else {
            this.lineMeasureGeoJsonData.features[lineStringIndex].geometry.coordinates = this.lineStringFeature.geometry.coordinates;
          }
        }
        if (mouseEvent === 'click') {
          this.lineMeasureGeoJsonData.features.push(pointFeature);
        }
      } else if (type === 'polygonMeasure') {
        if (mouseEvent === 'click') {
          this.polygonMeasureGeoJsonData.features.push(pointFeature);
        }

        let polygonFeatureIndex: number = -1;
        let polygonLinefeatureIndex: number = -1;
        polygonFeatureIndex = this.polygonMeasureGeoJsonData.features.findIndex((eachFeature) => eachFeature.geometry.type === 'Polygon');
        polygonLinefeatureIndex = this.polygonMeasureGeoJsonData.features.findIndex((eachFeature) => eachFeature.geometry.type === 'LineString')
        if (polygonFeatureIndex !== -1) {
          if (this.polygonFeature.geometry.coordinates[0] && this.polygonFeature.geometry.coordinates[0].length) {
            this.polygonMeasureGeoJsonData.features[polygonFeatureIndex].geometry.coordinates = this.polygonFeature.geometry.coordinates;
          }
        } else {
          // check whether there are polygon points in feature
          if (this.polygonFeature.geometry.coordinates[0] && this.polygonFeature.geometry.coordinates[0].length) {
            this.polygonMeasureGeoJsonData.features.push(this.polygonFeature);
          }
        }
        if (polygonLinefeatureIndex !== -1) {
          if (this.polygonLineStringFeature && this.polygonLineStringFeature.geometry.coordinates.length) {
            this.polygonMeasureGeoJsonData.features[polygonLinefeatureIndex].geometry.coordinates = this.polygonLineStringFeature.geometry.coordinates;
          }
        } else {
          if (this.polygonLineStringFeature && this.polygonLineStringFeature.geometry.coordinates.length) {

            this.polygonMeasureGeoJsonData.features.push(this.polygonLineStringFeature);
          }
        }
        if (this.polygonPointGeoJsonData.length > 2) {
          this.polygonMeasureGeoJsonData.features = [...this.polygonMeasureGeoJsonData.features].filter((eachFeature) => eachFeature.geometry.type !== 'LineString')
        }
      }
      if (type === 'lineMeasure') {
        if (this.map.getSource('lineMeasureSource')) {
          this.map.getSource('lineMeasureSource').setData(this.lineMeasureGeoJsonData);
        }
      } else {
        if (this.map.getSource('polygonMeasureSource')) {
          this.map.getSource('polygonMeasureSource').setData(this.polygonMeasureGeoJsonData)
        }

      }

    }
  }
  removeSubscribtionsAndBufferData() {
    this.polygonLineStringFeature = {};
    this.polygonPointGeoJsonData = []
    this.polygonMeasureGeoJsonData = {}
    this.lineMeasureGeoJsonData = {};
    this.lineMeasurePointGeoJsonData = []
    this.lineStringFeature = null;
    this.layerCheckOnHover = null;
    this.showPopupSubject.next(false);
    if (this.mousemoveSubscription) this.mousemoveSubscription.unsubscribe();
    if (this.mouseDblClickSubscription) this.mousemoveSubscription.unsubscribe();
    if (this.vertexAndSegmentClickSubscription) this.vertexAndSegmentClickSubscription.unsubscribe();
  }

  resetDataManagementTableValues() {
    this.selectedTab = 0;
    this.searchedBuildingValue = '';
    this.searchedPremiseValue = '';
    this.searchedAdminValue = '';
    this.currentBuildingPageNumber = 1;
    this.currentPremisePageNumber = 1;
    this.currentAdminPageNumber = 1;
    this.buildingIds = [];
    this.premiseIds = [];
    this.adminBuildingIds = [];
    this.selectedSortObject = {};
    this.selectedSortObjectArray = [];
    this.selectedBuildingOption = 10;
    this.selectedPremiseOption = 10;
    this.selectedAdminPanelOption = 10;
    this.tableDataChange.next(true);
    this.buildingFilters = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: "",
      assessmentDate: "",
    }
    this.filterPremise = {
      floor: "",
      premiseFloor: this.premiseFloor,
      totalFloor: []
    }
    this.adminBuildingFilters = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,

    }
    this.adminFilterDate = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: ""
    }
    this.filterdate = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: "",
      first_assessment_startDate:'',
      first_assessment_endDate:'',
      last_assessment_startDate:'',
      last_assessment_endDate:'',
      sync_endDate:'',
      sync_startDate:''
    }
    this.buildingDataField = '';
    this.adminBuildingDataField = '';
    this.tierFormArray = new FormArray([]);
    this.buildingIdsWithoutGeometryEdit =[];
    this.layersToBeQueried =[];
  }
  getPropertyTaxForms() {
    let subURL = this.connProps.propertyTaxForms;
    return this.apiService.getNew(subURL, true, false)
  }
  getPropertyTaxEntryLogs(id) {
    let subURL = `${this.connProps.propertyTaxLogs}${id}`
    return this.apiService.getNew(subURL, true, false);
  }
  getBuildingData(parentId) {
  let subURL= `${this.connProps.geometryData}${parentId}`;
  return this.apiService.getNew(subURL, true, false);
  }

}

export const VERTEXLAYER = {
  'id': 'gl-draw-point-static',
  'type': 'circle',
  'paint': {
    'circle-radius': 10,
    'circle-color': 'blue',
    "circle-opacity": 0.2,
    "circle-stroke-color": 'blue',
    "circle-stroke-width": 1
  },
  'source': 'hoveredSource'
}
export const SEGMENTLAYER = {
  'id': 'gl-draw-point-static',
  "type": 'symbol',
  'layout': {
    'icon-image': 'cross',
    'icon-size': 2
  },
  "paint": {
    'icon-color': 'blue',
    'icon-opacity': 1
  },
  'source': 'hoveredSource'
}

