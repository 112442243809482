import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'clip-dialog',
  styleUrls: ['./clip-dialog.component.scss'],
  templateUrl: './clip-dialog.component.html'
})

export class ClipDialogComponent implements OnInit {
  rasterFile = new FormControl();
  polygonCheck: boolean = false;
  clipPxValCheck: boolean = false;
  clipBndSlCheck: boolean = false;
  selectedPolygonLayer = new FormControl();
  pxValue = new FormControl();
  bndSlValue = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

  }

  cancelClicked() {
    this.dialogRef.close();
  }

  confirmButtonClicked() {
    
  }
}