import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-custom-advanced-label",
  templateUrl: "custom-label.component.html",
  styleUrls: ["./custom-label.component.scss"]
})

export class CustomAdvancedLabel implements OnInit {
  constructor(

  ) {

  }

  ngOnInit() { }
}