export const operater_array = ["(", ")", "+", "-", "*", "/", "%"]
export const operater_priority = {
  "/": 1,
  "*": 1,
  "%": 1,
  "+": 2,
  "-": 2
}
const digit_reg_ex = /^\d+$/;
export const float_reg_exp = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;

export const infixToPostfix = (input) => {
  var element, index, op_arr, res_arr, temp_array;
  res_arr = [];
  op_arr = [];
  index = 0;
  while (index < input.length) {
    element = input[index];
    // If an element is an operator
    if (operater_array.indexOf(element.toLowerCase()) >= 0) {
      element = element.toLowerCase();
      if (element !== ")") {

        // if priority if incoming operater is less than operater in array
        while (operater_priority[op_arr[op_arr.length - 1]] < operater_priority[element] && op_arr.length > 0) {
          res_arr.push(op_arr.pop());
        }
        op_arr.push(element);
        index++;
        continue;
      }
      // pop operater till matching opening bracket is found
      while (op_arr[op_arr.length - 1] !== "(" && op_arr.length > 0) {
        res_arr.push(op_arr[op_arr.length - 1]);
        op_arr.pop();
      }
      op_arr.pop();
      index++;
      continue;
    }
    temp_array = [input[index - 1]];

    // An array was detected
    if (temp_array.length > 1) {
      res_arr.pop();
      res_arr.push(temp_array);
      continue;
    }
    res_arr.push(element);
    ++index;
  }
  while (op_arr.length > 0) {
    res_arr.push(op_arr.pop());
  }
  return res_arr;
}

export const createObjectFromArray = (resArr, propertyTypes, prefix) => {
  var element, final_res_array, i, index, len, expression_result_object, ref, result;
  final_res_array = [];
  expression_result_object = {};
  if(resArr.length > 1) {
    for (let i = 0; i < resArr.length; i++) {
  
      element = resArr[i];
      if ((typeof element === "string") && (!(ref = operater_array.indexOf(element.toLowerCase()) >= 0)) || (typeof element !== "string")) {
        final_res_array.push(element);
        continue;
      }
      result = evaluateObject(element, final_res_array, propertyTypes, prefix);
      if (!result) {
        return void 0;
      } else {
        final_res_array.push(...result);
      }
    }
  } else {
    return getStyleObjectToAccessData(resArr[0], propertyTypes, prefix);
  }
  return result;
}

export const getPostFixExpressionObject = (expressionString, prefix) => {
  let expressionArray = expressionString.match(/\(|\)|is null|is not null|not in|not :|between|! between|not between|"[^"]*"|'[^']*'|[-+]?[0-9]+\.[0-9]+([eE][-+]?[0-9]+)?|\^?\w+\$?|\w+|[-+]?\d+(.\d+)?|\d+|!:|<>|<:|>:|<|>|:|!|and|or|like|in|,|exists|\+|-|\/|\*/gi);
  let postfixExpArray = infixToPostfix(expressionArray);
  if (!postfixExpArray === null) {
    return false;
  }
  return postfixExpArray;
}

export const createArithmeticOperatorExp = (operater, operand_1, operand_2, propertyTypes, prefix) => {
  let query_result_object: any = null;
  let operand_1_type: string = null;
  let operand_2_type: string = null;
  if (float_reg_exp.test(operand_1)) {
    operand_1_type = "Number";
    operand_1 = parseFloat(operand_1);
  } else if (!(operand_1.includes("this"))) {
    if(propertyTypes[operand_1] === "Number") {
      operand_1_type = "Number";
      operand_1 = ['to-number', ['get', prefix + operand_1]];
    } else if (propertyTypes[operand_1] === "String") {
      operand_1_type = "String";
      operand_1 = ['to-string', ['get', + prefix + operand_1]];
    } else {
      operand_1 = prefix + operand_1;
    }
  }
  if (float_reg_exp.test(operand_2)) {
    operand_2_type = "Number";
    operand_2 = parseFloat(operand_2);
  } else if (!(operand_2.includes("this"))) {
    if(propertyTypes[operand_2] === "Number") {
      operand_2_type = "Number";
      operand_2 = ['to-number', ['get', prefix + operand_2]];
    } else if (propertyTypes[operand_2] === "String") {
      operand_2_type = "String";
      operand_2 = ['to-string', ['get', prefix + operand_2]];
    } else {
      operand_2 = prefix + operand_2;
    }
  }

  if (operand_1_type === "Number" && operand_2_type === "Number") {
    switch (operater) {
      case "+":
        query_result_object = ["+", operand_1, operand_2];
        break;
      case "-":
        query_result_object = ["-", operand_1, operand_2];
        break;
      case "*":
        query_result_object = ["*", operand_1, operand_2];
        break;
      case "/":
        query_result_object = ["/", operand_1, operand_2];
        break;
      case "%":
        query_result_object = ["%", operand_1, operand_2];
        break;
    }
  }
  // else {
  //   query_result_object = operand_1 + operater + operand_2;
  // }
  return query_result_object;
}

const getStyleObjectToAccessData = (property, propertyTypes, prefix) => {
  if(propertyTypes[property] === "Number") {
    property = ['to-number', ['get', prefix + property]];
  } else if (propertyTypes[property] === "String") {
    property = ['to-string', ['get', prefix + property]];
  } else {
    property = prefix + property;
  }
  return property;
}

const evaluateObject = (operator, final_res_array, propertyTypes, prefix) => {
  let operand_1, operand_2;
  let result: any = null;
  operand_2 = final_res_array.pop(); // Right hand side
  if (operand_2 === void 0) {
    return false;
  }
  operand_1 = final_res_array.pop(); // Left hand side
  if (typeof operator === "string") {
    switch (operator.toLowerCase()) {
      case "+":
        if (operand_1 === void 0) {
          return false;
        }
        result = createArithmeticOperatorExp("+", operand_1, operand_2, propertyTypes, prefix);
        break;
      case "-":
        if (operand_1 === void 0) {
          return false;
        }
        result = createArithmeticOperatorExp("-", operand_1, operand_2, propertyTypes, prefix);
        break;
      case "*":
        if (operand_1 === void 0) {
          return false;
        }
        result = createArithmeticOperatorExp("*", operand_1, operand_2, propertyTypes, prefix);
        break;
      case "/":
        if (operand_1 === void 0) {
          return false;
        }
        result = createArithmeticOperatorExp("/", operand_1, operand_2, propertyTypes, prefix);
        break;
      case "%":
        if (operand_1 === void 0) {
          return false;
        }
        result = createArithmeticOperatorExp("%", operand_1, operand_2, propertyTypes, prefix);
        break;
    }
  }
  return result;
}

