import { Component, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { DeleteSessionsDialogComponent } from '../delete-sessions-dialog/delete-sessions-dialog.component';
import { getProcessingLayerStatus, getProcessedLayerSelector, layerDeleteSelector, clearAllNotificationSelector } from 'src/app/store/selectors/session-management.selector';
import _ from 'lodash';
import { ProcessingFileStartAction, GetSessionDetailsStartAction, GetProcessedListStartAction, ClearAllNotificationStartAction } from 'src/app/store/actions/session-management.actions';
import { Subject, Subscription, combineLatest, Observable } from 'rxjs';
import { SessionService } from 'src/app/_services/session.service';
import { Storeservice } from 'src/app/_services/store.services';
import { Router } from '@angular/router';
import { API } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { SocketService } from 'src/app/_services/socket.service';
import { userProfileSelector } from 'src/app/store/selectors/profile.selector';
import { takeUntil } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, stagger } from '@angular/animations';
import { uniqBy } from 'lodash';
import { saveAs } from 'file-saver';
// import { DatePipe } from '@angular/common';

@Component({
  selector: 'process-layer-dialog',
  templateUrl: './process-layer-dialog.component.html',
  styleUrls: ['./process-layer-dialog.component.scss'],
  animations: [
    trigger("listAnimation", [
      transition("* => *", [ // this is for every state changes
        // each time the binding value changes
        query(
          ":leave",
          [stagger(100, [animate("0.4s", style({ opacity: 0 }))])],
          { optional: true }
        )
      ])
    ]),


  ]
})


export class ProcessLayerDialogComponent implements OnDestroy, AfterViewInit {
  sessionId: string = '';
  processingLayers: any[] = [];
  deletedLayerId: any = '';
  calledImplicit: boolean = false;
  processingLayersWithoutErrorLength: number = 0
  stopper$: Subject<any> = new Subject();
  subscriptionArr: Subscription[] = [];
  timeout;
  downloadTimeout: any = '';
  layerUnderProcessingCount: number = 0;
  deleteButtonClicked: boolean;
  toasterTimeout;
  downloadedPath: any[] = ['vector_path', 'preview_path', 'raster_path'];
  showPointer: boolean = false;
  socketUnderProcess: boolean = false;
  userData: any = {};
  unreadCount: number = 0;
  // -------------
  // today: Date = new Date();
  // pipe = new DatePipe('en-US');
  // todayWithPipe = null;
  // --------------
  clearAllClicked: boolean = false;
  constructor(public dialogRef: MatDialogRef<ProcessLayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _store: Store<any>,
    public sessionService: SessionService,
    public dialog: MatDialog,
    public storeService: Storeservice,
    public router: Router,
    private apiService: API,
    private toaster: ToastrService,
    private socketService: SocketService,
  ) {
  }
  ngOnDestroy() {
    this.stopper$.next();
    this.subscriptionArr.forEach((sub) => sub.unsubscribe());
    clearTimeout(this.timeout);
    this.socketService.updateNotificationReadCount({ userId: this.userData.id }); // for clearing count
    this.socketService.highLightBadge = false;

  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    // this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
    if (this.data.type === 'mapSessionNotifications') {
      this.sessionId = this.data.sessionId;
      this._store.select(layerDeleteSelector).pipe(takeUntil(this.stopper$)).subscribe((resp) => {
        if (resp && resp.layer_id && this.deletedLayerId) {
          this.sessionService.processingLayersTobeDislayedInDialog = [...this.sessionService.processingLayersTobeDislayedInDialog].filter((layer) => layer._id !== this.deletedLayerId);
          this.toaster.success('layer deleted successfully');
          this.socketService.getClientProcessingLayers({ sessionId: this.sessionService.sessionId, userId: this.data.loggedInUserId });
        }
      })
    }
    else if (this.data.type === 'socketNotifications') {
      this.subscriptionArr.push(this.socketService.getServerNotificationListObservable.subscribe((resp: any) => {
        setTimeout(() => {
          this.socketUnderProcess = false;
          if (this.socketService.currentNotificationPageNumber == 1) {
            this.socketService.globalNotificationList = resp;
            this.socketService.globalNotificationList = uniqBy([...this.socketService.processingGlobalLayersList, ...this.socketService.globalNotificationList], '_id')
          } else {
            this.socketService.globalNotificationList.push(...resp)
            this.socketService.globalNotificationList = uniqBy([...this.socketService.globalNotificationList], '_id');
          }
        }, 500);

      }));
      this.subscriptionArr.push(this.socketService.unreadCountSubject.subscribe((resp) => {
        if (resp && resp.success) {
          this.unreadCount = resp.data.count;
        }
      }))
    }
    else if (this.data.type === 'socketNotificationsmap') {

      this.subscriptionArr.push(this.socketService.getServerNotificationListObservable.subscribe((resp: any) => {
        // if(this.socketService.globalNotificationList.length === this.socketService.totalGlobalNotificationList) return
        setTimeout(() => {
          this.socketUnderProcess = false;
          if (this.socketService.currentNotificationPageNumber == 1) {
            this.socketService.globalNotificationList = resp;
            this.socketService.globalNotificationList = uniqBy([...this.socketService.processingGlobalLayersList, ...this.socketService.globalNotificationList], '_id')
          } else {
            this.socketService.globalNotificationList.push(...uniqBy([...resp], '_id'));
          }
        }, 500);

      }));
      this.subscriptionArr.push(this.socketService.unreadCountSubject.subscribe((resp) => {
        if (resp && resp.success) {
          this.unreadCount = resp.data.count;
        }
      }))
    }
   
    this.socketService.getServerNotificationUnreadCount({ userId: this.userData.id }); // for getting count
    this.subscriptionArr.push(this._store.select(clearAllNotificationSelector).subscribe((resp) => {
      if (resp && Object.keys(resp).length && this.clearAllClicked) {
        this.socketService.globalNotificationList = [];
        this.toaster.success('Successfully cleared all the layers')
      }
    }))
  
  
  
  
  }

  ngAfterViewInit() {
    ////-----for the map session----////
    if (this.data.type === 'mapSessionNotifications') {
      const rightMostPos = window.innerWidth - Number(this.data.position.left - 186.5);
      this.dialogRef.updatePosition({ top: `${this.data.position.top + 4}px`, right: `${rightMostPos - 180}px` });
      setTimeout(() => this.showPointer = true, 100)

    }
    else if (this.data.type === 'socketNotificationsmap') {
      const rightMostPos = window.innerWidth - Number(this.data.position.left - 186.5);
      this.dialogRef.updatePosition({ top: `${this.data.position.top - 139}px`, right: `${rightMostPos - 378}px` });
      setTimeout(() => this.showPointer = true, 100)
    }
      
    else if (this.data.type === 'socketNotifications') {
      const rightMostPos = window.innerWidth - Number(this.data.position.left - 186.5);
      this.dialogRef.updatePosition({ top: `${this.data.position.top - 15}px`, right: `${rightMostPos - 350}px` });
      setTimeout(() => this.showPointer = true, 100)

    }

    // ---- for the Notification --//


  }

  deletedClicked(_id, layer, type) {
   
    this.deletedLayerId = layer._id;
    if (type === 'session-notifications') {
      const dialogRef = this.dialog.open(DeleteSessionsDialogComponent, {
        width: "800px",
        hasBackdrop: true,
        data: {
          title: `DELETE ${(layer.layer_type == 1) ? 'VECTOR' : 'RASTER'} LAYER`,
          alertTitle: "DELETE",
          cancelText: "CANCEL",
          confirmText: "DELETE",
          id: layer._id,
          type: "processing_layer",
          text: `Are you sure you want to delete the  ${(layer.layer_type == 1) ? 'VECTOR' : 'RASTER'} layer`,
          sessionId: this.sessionId
        }
      })
      dialogRef.afterClosed().subscribe((resp: any) => {
        if (resp && resp !== '') {
          if (resp.value === 'DELETE') {
            this.deleteButtonClicked = true;
if (!layer._id) {
              this.toaster.warning('layer-id not found')
            }

          }
        }
      })
    } else {
const dialogRef = this.dialog.open(DeleteSessionsDialogComponent, {
        width: '800px',
        hasBackdrop: true,
        data: {
          title: `Delete ${layer.properties.name}`,
          alertTitle: 'DELETE',
          cancelText: 'CANCEL',
          id: layer._id,
          type: 'global-notification',
          text: `Are you sure you want to delete ${layer.properties.name}  from notification list ? `,
          userId: this.userData.id
        }
      })
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp && resp.value && resp.value === "DELETE") {
          
          this.socketService.clientDeleteNotification(this.userData.id, layer._id);
          this.socketService.clearFileStorageSingleV3(_id) //calls for dispatch action for v3 api 
          this.socketService.clearFileStorageSingle(_id)
          }
      })
    }
  }


  onClearClick() {
    // const subUrl = `/sessions/layers/clear/${this.sessionId}`;
    // this.apiService.getNew(subUrl, true, false).subscribe((resp) => {
    //   this.processingLayers = [];
    //   this.toaster.success('cleared all  processed Layers history')
    //   this.dialogRef.close('')
    // })
    this.clearAllClicked = true;
    this.socketService.globalNotificationList = [];
    this.socketService.getServerNotificationListObservable.next([]);
    this.socketService.processingGlobalLayersList = [];
    this.socketService.updateNotificationReadCount({ userId: this.userData.id }); // for clearing count
    this.socketService.clientClearAllNotification(this.userData.id);
    this.socketService.clearFileStorage()
    this.socketService.clearFileStorageV3()       //calls for dispatch action for v3 api 
  }
  downloadClicked(layer, type) {
    if (type === 'session-notifications') {
      if (layer.vector_path && layer.preview_path) {
        let arr = [layer.vector_path, layer.preview_path]
        arr.forEach((link) => {
          window.open(link)
        })
      } else if (layer.raster_path) {
        window.open(layer.raster_path);
      } else if (layer.geo_path) {
        window.open(layer.geo_path)
      }

    } else if (type === 'global-notifications') {
      if (layer.properties.filePath.includes('json')) {
        this.apiService.getNew(layer.properties.filePath, true, true).subscribe((resp: any) => {
          let blob = new Blob([JSON.stringify(resp)], { type: 'geojson;charset=utf-8`' });
          let fileName = ((layer.properties.name.includes('json')) || (layer.properties.name.includes('geojson')))  ? layer.properties.name.replace('geojson', 'json') : layer.properties.name +'.json'
          saveAs(blob,fileName);
        })
      } else {
        window.open(layer.properties.filePath);
      }

    }

  }
  layerInSessionClicked(sessionId) {

    this.router.navigate([`../home/${sessionId}`])
    this.dialog.closeAll()
  }
  onScroll(event) {
    if (this.socketUnderProcess) return;
    const tableViewHeight = event.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = event.target.scrollHeight; // length of all table
    const scrollLocation = event.target.scrollTop; // how far user scrolled
    const buffer = 10;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit && !this.socketUnderProcess) {
      if (this.socketService.currentNotificationPageNumber >= this.socketService.totalNotificationPages) return;
      this.socketUnderProcess = true;
      this.socketService.getNotificationsList({ userId: this.userData.id, page: ++this.socketService.currentNotificationPageNumber, limit: 10 });
    }

  }
}