import { Component, ElementRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LegendCtrl, TEXT_LABEL_ATTRIBUTE } from "src/app/_services/mapLegendsCtrl.service";
import { SessionService } from "src/app/_services/session.service";
import { createObjectFromArray, getPostFixExpressionObject, operater_array, operater_priority, float_reg_exp } from "../expression-validator";
import { cloneDeep } from 'lodash';
import { FormControl } from "@angular/forms";
import { FIELD_LABEL } from "../advanced-label-dialog.componrnt";
import { LayerService } from "src/app/_services";
import { Store } from "@ngrx/store";
import { propertiesTypeSelector } from "src/app/store/selectors/session-management.selector";
import { Subscription } from "rxjs";

@Component({
  selector: "app-expression-dialog",
  templateUrl: "./expression-dialog.component.html",
  styleUrls: ["./expression-dialog.component.scss"]
})

export class ExpressionDialogComponent {
  cursorStartPosition: number = null;
  cursorEndPosition: number = null;
  outputExpression: string = null;
  resetExpressionTo: string = null;
  selectedAttribute: string = null;
  mapExpression: Array<any> = [];
  hasError: boolean = false;
  evaluationStarted: boolean = false;
  isValidExpression: boolean = false;
  validationInput: FormControl = new FormControl('');
  filteredAttributes: Array<string> = [];
  searchInput: FormControl = new FormControl("");
  serchSelected : boolean = false;
  selectedLayer: any = null;
  subscriptionArr: Array<Subscription> = [];
  arrributeTypes: any = null;
  constructor(
    private dialogRef: MatDialogRef<ExpressionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private legendService: LegendCtrl,
    private sessionService: SessionService,
    private layerService: LayerService,
    private _store: Store<any>
  ) {

  }
  ngOnInit() {
    this.filteredAttributes = cloneDeep(this.data.layerAttributes);
    this.selectedLayer = this.sessionService.selectedLayers[0];
    const styleType = this.selectedLayer.style_type;
    const applied_text_style = this.selectedLayer[TEXT_LABEL_ATTRIBUTE[styleType]];
    const applied_style = applied_text_style.classes.find(cls => cls.class_id === applied_text_style.selected_class_id);
    if(applied_style) {
      this.outputExpression = applied_style.expression;
      this.cursorEndPosition = null;
      this.cursorStartPosition = null;
    } else {
      this.outputExpression = '';
    }
    this.resetExpressionTo = this.outputExpression;

    this.searchInput.valueChanges.subscribe((value) => {
      if (value) {
        this.serchSelected = true ;
      }else{
        this.serchSelected = false;
      }
      this.filteredAttributes = this.data.layerAttributes.filter((attr: string) => attr.toLocaleLowerCase().includes(value.toLowerCase()));
    })
    this.subscriptionArr.push(
      this._store.select(propertiesTypeSelector).subscribe((result: any) => {
        if(Object.keys(result).length) {
          this.arrributeTypes = result;
        }
      })
    );
  }

  onClick(event) {
    this.cursorStartPosition = event.target.selectionStart;
    this.cursorEndPosition = event.target.selectionEnd
  }
 

  onBlur(event) {
    this.cursorStartPosition = null;
    this.cursorEndPosition = null;
  }

  replaceString(startIndex, endIndex, selectedQuery) {
    return this.outputExpression.toString().substring(0, startIndex) + selectedQuery + this.outputExpression.toString().substring(endIndex + 1, this.outputExpression.length - 1)
  }

  updateExpression = value => {
    let concatQuery = ''
    if (this.cursorStartPosition !== null && this.cursorEndPosition !== null) {

      concatQuery = this.replaceString(this.cursorStartPosition, this.cursorEndPosition, value)
    } else {
      if(this.outputExpression && this.outputExpression.trim()) {
        concatQuery = `${this.outputExpression} & " " & ${value} `;
      } else {
        concatQuery = `${value} `;
      }
    }
    this.cursorEndPosition = null;
    this.cursorStartPosition = null;
    return concatQuery;
  }

  setClickedAttribute = value => {
    this.selectedAttribute = value;
  }
  crossClicked(){
    this.serchSelected = !this.serchSelected; 
    this.searchInput.setValue('');
  }
  setOutputExpression = value => {
    this.outputExpression = value;
    this.cursorEndPosition = null;
    this.cursorStartPosition = null;
    this.isValidExpression = false;
    this.evaluationStarted = false;
    this.validationInput.setValue(null);
  }

  appendAttribute = () => {
    if(!this.selectedAttribute) return;
    this.outputExpression = this.updateExpression(`[${this.selectedAttribute}]`);
    this.selectedAttribute = null;
    this.isValidExpression = false;
    this.evaluationStarted = false;
    this.validationInput.setValue(null);
  }

  setError = () => {
    this.hasError = true;
    this.validationInput.setValue('Invalid Expression');
  }

  validateExpression = () => {
    this.evaluationStarted = true;
    let mapBoxExpression: Array<any> = [];
    if(!this.outputExpression) {
      this.setError();
      return;
    }
    const subExpressions = this.outputExpression.trim().split(' & ');
    for (const expr in subExpressions) {
      if (Object.prototype.hasOwnProperty.call(subExpressions, expr)) {
        const element = subExpressions[expr].trim();
        const eleLen = element.length;
        if(!eleLen) {
          this.setError();
          return;
        }
        if(!(element[0] === '\"' || element[eleLen - 1] === '\"')) {
          const postFixElementArray = getPostFixExpressionObject(element, "");
          if(postFixElementArray.length) {
            const mapboxExpressionObj = createObjectFromArray(postFixElementArray, this.data.layerAttributeTypes, "");
            if(!mapboxExpressionObj) {
              this.setError();
              return;
            }
            if(mapBoxExpression.length) {
              if(Array.isArray(mapboxExpressionObj)) {
                mapBoxExpression.push(mapboxExpressionObj);
              }
            } else {
              mapBoxExpression.push(mapboxExpressionObj);
            }
          } else {
            this.setError();
            return;
          }
        } else if(element[0] === '\"' && element[eleLen - 1] === '\"'){
          const strArr = element.split("\"");
          strArr.splice(strArr.findIndex(ele => ele === "\""), 1);
          if(mapBoxExpression) {
            mapBoxExpression.push(strArr.join(""));
          } else {
            mapBoxExpression = [strArr.join("")];
          }
        } else {
          this.setError();
          return;
        }
      }
    }
    this.hasError = false;
    this.isValidExpression = true;
    this.mapExpression = mapBoxExpression;
    if(this.selectedLayer.group_name === "transerve_osm") {
      this.validationInput.setValue('Valid Expression');
      return;
    }
    this.layerService.getFirstFeatureDetails(this.selectedLayer.parent_layer_id).subscribe((response: any) => {
      if(response.success) {
        const data = response.data;
        if(!data.hasOwnProperty('properties')) {
          this.setError();
          return;
        }
        let previewTxtArr = [];
        for (const expr in subExpressions) {
          if (Object.prototype.hasOwnProperty.call(subExpressions, expr)) {
            const element = subExpressions[expr].trim();
            const eleLen = element.length;
            if(eleLen) {
              if(!(element[0] === '\"' || element[eleLen - 1] === '\"')) {
                const postFixElementArray = getPostFixExpressionObject(element, "");
                if(postFixElementArray.length) {
                  const result = this.calculateSubExpressionValue(postFixElementArray, this.data.layerAttributeTypes, "", data.properties);
                  previewTxtArr.push(result);
                } else {
                  previewTxtArr.push("");
                }
              } else if(element[0] === '\"' && element[eleLen - 1] === '\"'){
                previewTxtArr.push(element.replace(/\"/g, ""));
              } else {
                previewTxtArr.push("");
              }
            }
          }
        }
        this.validationInput.setValue(previewTxtArr.join(''));
      } else {
        this.validationInput.setValue('Valid Expression');
      }
    })
  }

  calculateSubExpressionValue = (resArr, propertyTypes, prefix, data) => {
    var element, final_res_array, i, index, len, expression_result_object, ref, result;
    final_res_array = [];
    expression_result_object = {};
    if(resArr.length > 1) {
      for (let i = 0; i < resArr.length; i++) {
    
        element = resArr[i];
        if ((typeof element === "string") && (!(ref = operater_array.indexOf(element.toLowerCase()) >= 0)) || (typeof element !== "string")) {
          final_res_array.push((data.hasOwnProperty(element) && data[element]) || element);
          continue;
        }
        result = this.evaluateObject(element, final_res_array, propertyTypes, prefix, data);
        if (!result) {
          return void 0;
        } else {
          final_res_array.push(result);
        }
      }
    } else {
      return ((data.hasOwnProperty(resArr[0]) && data[resArr[0]]) || resArr[0]);
    }
    return final_res_array[0];
  }

  evaluateObject = (operator, final_res_array, propertyTypes, prefix, data) => {
    let operand_1, operand_2;
    let result: any = null;
    operand_2 = final_res_array.pop(); // Right hand side
    if (operand_2 === void 0) {
      return false;
    }
    if (float_reg_exp.test(operand_2)) {
      operand_2 = parseFloat(operand_2);
    } else if(data.hasOwnProperty(operand_2) && float_reg_exp.test(data[operand_2])) {
      operand_2 = data[operand_2]
    } else return false;

    operand_1 = final_res_array.pop(); // Left hand side
    if (operand_1 === void 0) {
      return false;
    }
    if (float_reg_exp.test(operand_1)) {
      operand_1 = parseFloat(operand_1);
    } else if(data.hasOwnProperty(operand_1) && float_reg_exp.test(data[operand_1])) {
      operand_1 = data[operand_1]
    } else return false;

    if (typeof operator === "string") {
      switch (operator.toLowerCase()) {
        case "+":
          if (operand_1 === void 0) {
            return false;
          }
          result = operand_1 + operand_2;
          break;
        case "-":
          if (operand_1 === void 0) {
            return false;
          }
          result = operand_1 - operand_2;
          break;
        case "*":
          if (operand_1 === void 0) {
            return false;
          }
          result = operand_1 * operand_2;
          break;
        case "/":
          if (operand_1 === void 0) {
            return false;
          }
          result = operand_1 / operand_2;
          break;
        case "%":
          if (operand_1 === void 0) {
            return false;
          }
          result = operand_1 % operand_2;
          break;
      }
    }
    return result;
  }

  resetExpression = () => {
    this.outputExpression = null;
    this.selectedAttribute = null;
    this.hasError = false;
    this.evaluationStarted = false;
    this.isValidExpression = false;
    this.validationInput.setValue(null);
    this.outputExpression = this.resetExpressionTo;
    this.cursorStartPosition = null;
    this.cursorEndPosition = null;
  }

  applyExpression() {
    if(!this.isValidExpression) return;
    let fieldLabel = cloneDeep(FIELD_LABEL);
    fieldLabel.classes[0] = {
      class_id: 1,
      expression: this.outputExpression,
      is_default: true,
      map_expression: this.mapExpression,
      map_query: [],
      name: "default",
      query: ""
    }
    this.sessionService.selectedLayers[0][TEXT_LABEL_ATTRIBUTE[this.selectedLayer.style_type]] = fieldLabel;
    this.sessionService.sendLayerDetailsToServer();
    this.legendService.applyLabel(this.selectedLayer, this.mapExpression);
    this.dialogRef.close(this.outputExpression);
  }
  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    if(this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }
}
