import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { Store } from "@ngrx/store";
import { UNIQ_VALUE_FOR_EXPRESSION } from "src/app/app.constants";
import { GetLayerAttributesStartAction } from "src/app/store/actions/session-management.actions";
import { layerAttributeInfoSelector, layerAttributesSelector, propertiesTypeSelector } from "src/app/store/selectors/session-management.selector";
import { SessionService } from "src/app/_services/session.service";
import { ExpressionDialogComponent } from "../expression-dialog/expression-dialog.component";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-default-advanced-label",
  templateUrl: "default-label.component.html",
  styleUrls: ["./default-label.component.scss"]
})

export class DefaultAdvancedLabel {
  selectedLayer: any = null;
  selectedLayerId: string = null;
  layerAttributes: Array<any> = [];
  layerAttributeTypes: any = null;
  selectedAttribute: string = null;
  uniqueValueForExpression: string = UNIQ_VALUE_FOR_EXPRESSION;
  @Input() selectedTextStyleValue;
  @Output() selectedMapStyling: EventEmitter<any> = new EventEmitter();
  constructor(
    private _store: Store<any>,
    private dialog: MatDialog,
    private sessionService: SessionService,
  ) {

  }

  ngOnInit() {
    if(this.sessionService.selectedLayers.length) this.selectedLayer = this.sessionService.selectedLayers[0];
    if(this.selectedLayer) this.selectedLayerId = this.selectedLayer.parent_layer_id;
    this.selectedAttribute = this.selectedTextStyleValue;
    this._store.dispatch(new GetLayerAttributesStartAction(this.selectedLayerId));
    this._store.select(layerAttributesSelector).subscribe((attributes: Array<any>) => {
      this.layerAttributes = attributes;
    });
    this._store.select(propertiesTypeSelector).subscribe((attributes: any) => {
      this.layerAttributeTypes = attributes;
    })
  }

  openExpression() {
    const dialog = this.dialog.open(ExpressionDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      width: "500px",
      data: {
        selectedLayer: this.selectedLayer,
        layerAttributes: this.layerAttributes,
        layerAttributeTypes: this.layerAttributeTypes
      }
    });
    dialog.afterClosed().subscribe((response: any) => {
      if(!response) {
        this.selectedMapStyling.emit(cloneDeep(EMIT_OBJ));
        return;
      }
      console.log("RESPONSE: ", response);
      const res = response.trim();
      if(res.startsWith('[') && res.endsWith(']') && !res.includes('&')) {
        const attr = res.replace('[', '').replace("]", '');
        console.log("Attribute: ", attr);
        if(this.layerAttributes.includes(attr)) {
          this.selectedAttribute = attr;
          let payload: any = cloneDeep(EMIT_OBJ);
          payload.status = true;
          payload.value = "expression";
          payload.map_expression = [['get', attr]]
          this.selectedMapStyling.emit(payload);
        } else {
          this.selectedAttribute = null;
          this.selectedMapStyling.emit(cloneDeep(EMIT_OBJ));
        }
      } else {
        this.selectedAttribute = UNIQ_VALUE_FOR_EXPRESSION;
        let payload: any = cloneDeep(EMIT_OBJ);
        payload.status = true;
        payload.value = "expression";
        payload.map_expression = []
        this.selectedMapStyling.emit(payload);
      }
    })
  }

  setSelectedAttribute = value => {
    this.selectedAttribute = value;
    let payload: any = cloneDeep(EMIT_OBJ);
    payload.status = true;
    payload.value = value;
    payload.map_expression = [['get', value]]
    this.selectedMapStyling.emit(payload);
  }
}

const EMIT_OBJ = {
  status: false,
  value: '',
  map_expression: ''
}