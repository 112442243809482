import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { GetLayerPreviewDataStartAction } from 'src/app/store/actions/data-management.actions';
import { yourDataLayerPreviewSelector } from 'src/app/store/selectors/data-management.selector';
import { SessionService } from 'src/app/_services/session.service';

@Component({
  selector: 'app-your-data-preview',
  templateUrl: './your-data-preview.component.html',
  styleUrls: ['./your-data-preview.component.scss']
})
export class  YourDataPreviewComponent  {
  previewData: any;
  layerId: string = null;
  layerName: string = null;
  layerType: string = null;
  displayedColumns: Array<string> = [];
  attributesNameAndType: any;
  dataSource: Array<any> = [];
  displayedColumnsType: any = null;
  @ViewChild("tableContainer", { read: ElementRef }) tableContainer: ElementRef<any>;

  constructor(
    public dialogRef: MatDialogRef<YourDataPreviewComponent>,
    private _store: Store<any>,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      if(this.data.type  ="mapSession") {
        
        this.layerId =this.data.id;
      } else {
        
        this.layerId = this.data.layer._id;
      }
      this.layerName = this.data.name || null;
      this.layerType = this.data.layer_type || null;
      this._store.dispatch(new GetLayerPreviewDataStartAction(this.layerId));
      this._store.select(yourDataLayerPreviewSelector).subscribe((resp: any) => {
        this.previewData = resp;
        this.previewData.bbox = this.previewData.bbox.reduce((acc, cur) => {
          return [...acc, this.sessionService.toDecimalString(cur, 5)];
        }, [])
        let data = []
        for (let i = 0; i < resp.sampleData.length; i++) {
          data.push(resp.sampleData[i])
        }
        this.dataSource = data;
        this.attributesNameAndType = resp.attribute_type;
        this.displayedColumns = Object.keys(resp.attribute_type);
      })
    }
  }

  scrollRight() {
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft + 600), behavior: 'smooth' });
  }

  scrollLeft() {
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft - 600), behavior: 'smooth' });
  }

  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}
