import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { SessionService } from 'src/app/_services/session.service';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'visibility-coverage-dialog',
  templateUrl: './visibility-coverage-dialog.component.html',
  styleUrls: ['./visibility-coverage-dialog.component.scss']
})

export class VisibilityCoverageDialogComponent {
  rasterFile: FormControl = new FormControl();
  visibilityRadius: FormControl = new FormControl(null, [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/)]);
  radiusUnit = "meter";
  observerHeight: FormControl = new FormControl(2, [Validators.pattern(/^(\d*\.)?\d+$/)]);
  enableAction: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toasterService: ToastrService
  ) { 
    dialogRef.disableClose = true;

  }

  selectPointsButtonClicked() {
    if(!this.isFormValid()) return;
    if (this.rasterFile && this.rasterFile.value && this.rasterFile.value.parent_layer_id) {
      this.sessionService.setAdvancedToolsGraphicsSelectionActive(true);
      this.navigationService.showGeometry();
      const rad = this.radiusUnit === "meter" ? +this.visibilityRadius.value : +this.visibilityRadius.value * 1000;
      const rData = {
        layerId: this.rasterFile.value.parent_layer_id,
        sessionId: this.data.sessionId,
        visibilityRadius: rad,
        observerHeight: +this.observerHeight.value || 2
      }
      this.sessionService.setSelectedRasterToolData(rData);
      this.dialogRef.close();
    } else {
      this.toasterService.info('please select atlease one layer to select points')
    }
  }

  checkFormValid() {
    this.enableAction = this.isFormValid();
  }

  cancelClicked() {
    this.sessionService.selectedRasterToolIndex = null;
    this.dialogRef.close();
    this.sessionService.setDefaultRasterToolIndex.next(null);

  }

  isFormValid() {
    return !!this.rasterFile.value && this.visibilityRadius.valid && this.observerHeight.valid;
  }
}