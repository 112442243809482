import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataManagementActionTypes, SaveJoinResultAsLayerStartAction, SaveVTJoinResultAsLayerStartAction } from 'src/app/store/actions/data-management.actions';
import { joinDataAttributesSelector } from 'src/app/store/selectors/data-management.selector';
import { SessionService } from 'src/app/_services/session.service';
import { JoinDataSuccessDialog } from '../join-data-success-dialog/join-data-success-dialog.component';
import _ from 'lodash';

@Component({
  selector: 'app-layer-name-dialog',
  templateUrl: './joined-layer-name-dialog.component.html',
  styleUrls: ['./joined-layer-name-dialog.component.scss']
})
export class JoinedLayerNameDialog implements OnInit {
  subscriptionsArray: Array<Subscription> = [];
  fileName: FormControl = new FormControl(null, [Validators.required, this.noWhitespaceValidator]);
  searchDataSource1: FormControl = new FormControl(null);
  searchDataSource2: FormControl = new FormControl(null);
  searchString1: string = null;
  searchString2: string = null;
  payload: any = null;
  joinedLayerId: string = null;
  displayedColumns1: string[] = ['select', 'name'];
  dataSource1 = new MatTableDataSource(null);
  selection1 = new SelectionModel(true, []);
  displayedColumns2: string[] = ['select', 'name'];
  dataSource2 = new MatTableDataSource(null);
  selection2 = new SelectionModel(true, []);
  layerAttributes: Array<any> = [];
  libraryAttributesInfo: any = null;
  layerAttributesInfo: any = null;
  libraryAttributes: Array<any> = [];
  sheetName: string = null;
  layer1Info: any = null;
  layer2Info: any = null;
  layerTagImages = {
    1: "assets/images/vector-tag.svg",
    2: "assets/images/tabular-tag.svg", // dirty fix. Should be updated later
    8: "assets/images/tabular-tag.svg"
  }
  constructor(
    public dialogRef: MatDialogRef<JoinedLayerNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private sessionService: SessionService,
    private _store: Store<any>,
    private _actions: Actions,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscriptionsArray.push(
      this.sessionService.getJoinDataRequest().subscribe((data: any) => {
        if (data) {
          this.payload = data;
          this.fileName.setValue(`${this.payload.name2}_${this.payload.name1}`);
        }
      })
    );
    this.subscriptionsArray.push(
      this._actions.pipe(
        ofType(DataManagementActionTypes.saveVTJoinResultAsLayerSuccess),
        map((response: any) => {
          if (response) {
            this.joinedLayerId = response.response.id;
            const data = {
              layerId: this.joinedLayerId
            }
            const nameLayerDialog = this.dialog.open(JoinDataSuccessDialog, {
              maxWidth: 800,
              hasBackdrop: true,
              disableClose: true,
              data
            })
            nameLayerDialog.afterClosed().subscribe(_ => { });
            this.close(false);
          }
        })
      ).subscribe()
    );
    this.subscriptionsArray.push(
      this._actions.pipe(
        ofType(DataManagementActionTypes.saveJoinResultAsLayerSuccess),
        map((response: any) => {
          if (response) {
            this.joinedLayerId = response.response.id;
            const data = {
              layerId: this.joinedLayerId
            }
            const nameLayerDialog = this.dialog.open(JoinDataSuccessDialog, {
              maxWidth: 800,
              hasBackdrop: true,
              disableClose: true,
              data
            })
            nameLayerDialog.afterClosed().subscribe(_ => { });
            this.close(false);
          }
        })
      ).subscribe()
    );

    this.subscriptionsArray.push(
      this._store.select(joinDataAttributesSelector).subscribe((data: any) => {
        if (data.libraryInfo.length) {
          let libraryInfo = _.cloneDeep(data.libraryInfo);
          if (this.payload.feature_type === 'tabular-vector') {
            this.libraryAttributesInfo = libraryInfo.reduce((acc, cur) => {
              acc[cur.sheet_name] = cur.attribute_type;
              return acc;
            }, {})
            this.libraryAttributes = this.libraryAttributesInfo[this.sessionService.selectedTabularDataSheetName];
          } else {
            this.libraryAttributes = libraryInfo;
          }
          this.addDefaultSelectionForDS1();
        }
        if (data.layerAttributes.length) {
          let layerInfo = _.cloneDeep(data.layerAttributes);
          if (this.payload.feature_type === 'vector-tabular') {
            this.layerAttributesInfo = layerInfo.reduce((acc, cur) => {
              acc[cur.sheet_name] = cur.attribute_type;
              return acc;
            }, {})
            this.layerAttributes = this.layerAttributesInfo[this.sessionService.selectedTabularDataSheetName];
          } else {
            if (layerInfo[0].hasOwnProperty('sheet_name')) {
              if (!layerInfo[0].sheet_name) {
                this.layerAttributes = layerInfo[0].attribute_type;
              }
            } else {
              this.layerAttributes = layerInfo;
            }
          }
          this.addDefaultSelectionForDS2();
        }
      })
    )

    this.subscriptionsArray.push(
      this.searchDataSource1.valueChanges.pipe(
        debounceTime(300), distinctUntilChanged()
      ).subscribe(value => {
        this.searchString1 = value.trim().toLowerCase();
        this.addDefaultSelectionForDS1();
      })
    )
    this.subscriptionsArray.push(
      this.searchDataSource2.valueChanges.pipe(
        debounceTime(300), distinctUntilChanged()
      ).subscribe(value => {
        this.searchString2 = value.trim().toLowerCase();
        this.addDefaultSelectionForDS2();
      })
    )
  }

  addDefaultSelectionForDS1() {
    let attrs: Array<any> = [];
    if (this.searchString1) {
      attrs = this.libraryAttributes.filter(attr => attr.name.toLowerCase().includes(this.searchString1));
    } else {
      attrs = this.libraryAttributes;
    }
    const ind = attrs.findIndex((attr: any) => attr.name === this.payload.input_layer_2.attribute_name);
    if (ind > -1) {
      const primarySelected = attrs[ind];
      attrs.splice(ind, 1);
      attrs.unshift(primarySelected);
      this.dataSource1.data = attrs;
      this.selection1.select(this.dataSource1.data[0]);
    } else {
      this.dataSource1.data = attrs;
    }
    if (this.ref && !(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  addDefaultSelectionForDS2() {
    let attrs: Array<any> = [];
    if (this.searchString2) {
      attrs = this.layerAttributes.filter(attr => attr.name.toLowerCase().includes(this.searchString2));
    } else {
      attrs = this.layerAttributes;
    }
    const ind = attrs.findIndex((attr: any) => attr.name === this.payload.input_layer_1.attribute_name);
    if (ind > -1) {
      const primarySelected = attrs[ind];
      attrs.splice(ind, 1);
      attrs.unshift(primarySelected);
      this.dataSource2.data = attrs;
      this.selection2.select(this.dataSource2.data[0]);
    } else {
      this.dataSource2.data = attrs;
    }
    if (this.ref && !(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  isAllSelected1() {
    const selectedItemNames = this.selection1.selected.length ? this.selection1.selected.map(sel => sel.name) : [];
    const dataSourceNames = this.dataSource1.data.length ? this.dataSource1.data.map((d: any) => d.name) : [];
    return dataSourceNames.every(name => selectedItemNames.includes(name));
  }

  masterToggle1() {
    this.isAllSelected1() ?
      this.selection1.clear() :
      this.dataSource1.data.forEach(row => this.selection1.select(row));
    this.addDefaultSelectionForDS1();
  }

  isAllSelected2() {
    const selectedItemNames = this.selection2.selected.length ? this.selection2.selected.map(sel => sel.name) : [];
    const dataSourceNames = this.dataSource2.data.length ? this.dataSource2.data.map((d: any) => d.name) : [];
    return dataSourceNames.every(name => selectedItemNames.includes(name));
  }

  masterToggle2() {
    this.isAllSelected2() ?
      this.selection2.clear() :
      this.dataSource2.data.forEach(row => this.selection2.select(row));
    this.addDefaultSelectionForDS2();
  }

  toggleSelection1(row) {
    this.selection1.toggle(row);
    this.addDefaultSelectionForDS1();
  }

  toggleSelection2(row) {
    this.selection2.toggle(row);
    this.addDefaultSelectionForDS2();
  }

  createLayer() {
    if (!this.fileName.valid) return;
    this.payload.add_to_session = false;
    this.payload.name = this.fileName.value;
    this.payload.input_layer_1.attributes_select = this.selection2.selected;
    this.payload.input_layer_2.attributes_select = this.selection1.selected;
    this._store.dispatch(new SaveVTJoinResultAsLayerStartAction(this.payload));
  }

  close(flag) {
    this.dialogRef.close();
  }

  isInvalid() {
    return this.fileName.invalid;
  }
  public noWhitespaceValidator(control: FormControl) {
    if (!control.value) return null;
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnDestroy() {
    if (this.subscriptionsArray.length) {
      this.subscriptionsArray.map(sub => sub.unsubscribe());
      this.subscriptionsArray = [];
    }
  }
}