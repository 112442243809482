import { Component, ElementRef, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { DL_CATEGORY_DETAILS_LOAD } from '../app-management/data-management/data-library/data-library.component';
import { DataManagementActionTypes, GetAllDataLibraryCategoriesStartAction, GetDataLibraryCategoryDetailsStartAction, UploadLibraryDataStartAction } from '../store/actions/data-management.actions';
import { dataLibraryCategories, dLCategoryDetails } from '../store/selectors/data-management.selector';
import { LayerService } from '../_services/layer.service';
import { SocketService } from 'src/app/_services/socket.service';
import { LibraryUploadAuthenticationStartAction, LogOutSessionAction, SessionManagementTypes } from 'src/app/store/actions/session-management.actions';
import { userProfileSelector } from '../store/selectors/profile.selector';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { acceptableVectorFormats, acceptableRasterFormats, acceptableTabularFormats } from '../../app/app.constants';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../_services/session.service';
import { API } from '../_services';


@Component({
  selector: 'datalibrary-upload',
  templateUrl: './datalibrary-upload.component.html',
  styleUrls: ['./datalibrary-upload.component.scss']
})
export class datalibraryupload {
  types = ['vector', 'tabular', 'raster'];
  subtypes = ['point', 'polygon','polyline','multipolygon','multilinestring','linestring','multipoint'];
  dataCategories: Array<any> = null;
  isTokenPresent: any;
  userData: any = null;
  userProfileLogo: string = "assets/images/profile_2.svg";
  category: FormControl = new FormControl('', Validators.required);
  region: FormControl = new FormControl('', Validators.required);
  type: FormControl = new FormControl('', Validators.required);
  subtype: FormControl = new FormControl('');
  description: FormControl = new FormControl('', Validators.required);
  name: FormControl = new FormControl('', [Validators.required, this.noWhitespaceValidator]);
  tag: FormControl = new FormControl('');
  tags: Array<string> = [];
  showForm: boolean = false;
  allowedFileFormats = {
    vector: "zip,geojson,json",
    tabular: "csv,xls,xlsx",
    raster: "tiff"
  }
  acceptedFileTypes = {
    // vector: acceptableVectorFormats,
    vector: ["application/zip", 'application/x-zip', 'application/zip', 'application/x-zip-compressed', "image/vnd.json", "image/vnd.geojson"],
    raster: acceptableRasterFormats,
    tabular: acceptableTabularFormats
  }
  selectedFile: File;
  @ViewChild('myfile') myFile: ElementRef;
  constructor(private _store: Store<any>,
    private router: Router,
    private apiService: API,
    private socketService: SocketService,
    private actions: Actions,
    private toastr: ToastrService,
    private sessionService: SessionService
  ) {
    this._store.dispatch(new LibraryUploadAuthenticationStartAction());
  }

  ngOnInit() {
    this._store.dispatch(new GetAllDataLibraryCategoriesStartAction());
    this._store.dispatch(new GetDataLibraryCategoryDetailsStartAction(DL_CATEGORY_DETAILS_LOAD));
    this._store.select(dataLibraryCategories).subscribe(categories => {
      this.dataCategories = _.cloneDeep(categories);
      const allInd = this.dataCategories.findIndex((cat, i) => cat.type === 'All');
      if(allInd > -1) this.dataCategories.splice(allInd, 1);
    })
    this._store.select(userProfileSelector).subscribe((data: any) => {
      if(Object.keys(data).length) {
        this.userData = data;
        this.userProfileLogo = this.userData.profilePic || "assets/images/profile_2.svg";
      }
    })
    this.actions.pipe(
      ofType(SessionManagementTypes.libraryUploadAuthenticationSuccess),
      tap((response: any) => {
        if(response.response.data && response.response.success) this.showForm = true;
      })
    ).subscribe();
    this.actions.pipe(
      ofType(DataManagementActionTypes.uploadLibraryDataSuccess),
      tap((response: any) => {
        if(response.response.success) {
          this.toastr.success('Library data uploaded successfully');
          this.onCancelClicked();
        }
      })
    ).subscribe();
  }
  //   backNavigationClicked() {
  //     this.isTokenPresent  = (localStorage.getItem('token') ? true : false)
  //     if(this.isTokenPresent) {
  //       this.router.navigate(['./app-management'])
  //     } else {
  //       this.router.navigate(['./sign'])
  //     }

  //   }
  logOut() {
    localStorage.clear();
    this.router.navigate(["sign"], {replaceUrl: true});
    // this.socketService.disconnectSocket({ userId: this.userData._id });
    this.socketService.socket.removeAllListeners();
    this.socketService.socket.disconnect();
    this._store.dispatch(new LogOutSessionAction());
    this.socketService.globalNotificationList = [];
    this.socketService.getServerNotificationListObservable.next([]);
    this.socketService.processingGlobalLayersList = [];
  }

  goToHome() {
    this.sessionService.setActiveDataTabIndex(1);
    this.router.navigate(["/app-management/data-management"], {replaceUrl: true});
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const selectedFileType = this.type.value;
      if(selectedFileType) {
        const acceptedExtTypes = this.allowedFileFormats[selectedFileType].split(",");
        const accFileTypes = this.acceptedFileTypes[selectedFileType];
        if(file.type) {
          if(!accFileTypes.includes(file.type)) {
            this.toastr.warning(`Please upload valid ${selectedFileType} file`);
            this.selectedFile = null;
            return;
          }
        } else {
          if(!acceptedExtTypes.includes(file.name.split('.').pop())) {
            this.toastr.warning(`Please upload valid ${selectedFileType} file`);
            this.selectedFile = null;
            return;
          }
        }
        this.selectedFile = file;
      }
    }
  }

  onCancelClicked() {
    this.category.reset();
    this.region.reset();
    this.type.reset();
    this.description.reset();
    this.name.reset();
    this.tags = [];
    this.tag.reset();
    this.selectedFile = null;
    this.myFile.nativeElement.value = null;
  }

  addTag() {
    const tagVal = this.tag.value;
    if(tagVal) {
      this.tags.push(tagVal);
    }
    this.tag.setValue("");
  }

  removeTag(ind) {
    this.tags.splice(ind, 1);
  }

  onSaveClicked() {
    if(!this.isFormValid()) return;
    const payload = {
      main_category: this.category.value,
      sub_category: this.region.value,
      type: this.type.value,
      sub_type: this.type.value === 'vector' ? this.subtype.value : '',
      description: this.description.value,
      name: this.name.value,
      tags: this.tags,
      file: this.selectedFile
    }
    let formData = this.sessionService.toFormData(payload);
    // this.layerService.datalibraryUpload(obj).subscribe((resp) => {
    // })
    this.apiService.getFormDataToken('fileUpload').subscribe((resp: any) => {
      if (resp && resp.success) {
        formData.append('csrf_token', resp.data.csrf_token);
        this._store.dispatch(new UploadLibraryDataStartAction(formData));
      }
    })
  }

  isFormValid() {
    if(
      this.category.invalid || this.region.invalid ||
      this.type.invalid || this.subtype.invalid ||
      this.description.invalid || this.name.invalid
    ) return false;
    if(!this.selectedFile) return false;
    return true;
  }

  public noWhitespaceValidator(control: FormControl) {
    if (!control.value) return null;
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
