
import { Component, ElementRef, ViewChild } from "@angular/core";
import { Store } from '@ngrx/store';
import { GetAllDataLibraryCategoriesStartAction, GetDataLibraryCategoryDetailsStartAction, GetDataLibrarySearchStartAction, DataManagementActionTypes, AddSelectedDataLayerToSessionStartAction } from 'src/app/store/actions/data-management.actions';
import { FormControl } from '@angular/forms';
import { dataLibraryCategories, dLCategoryDetails, dLLayers } from 'src/app/store/selectors/data-management.selector';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DataLibraryLayerDetailsDialog } from 'src/app/dialogs/data-library/data-library-layer-details-dialog/data-library-layer-details-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { map, debounceTime, distinctUntilChanged, throttle } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataLibraryFilterDialog } from 'src/app/dialogs/data-library/data-library-filter-dialog/data-library-filter-dialog.component';
import _ from 'lodash';
import { LayerSelectionDialog } from 'src/app/dialogs/data-library/layer-selection-dialog/layer-selection-dialog.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'data-library',
  styleUrls: ['./data-library.component.scss'],
  templateUrl: './data-library.component.html'

})

export class DataLibraryComponent {
  searchField: FormControl = new FormControl('');
  dTypeField: FormControl = new FormControl('');
  regionField: FormControl = new FormControl('');
  sortField: FormControl = new FormControl(null);
  selectedRegion: string = null;
  selectedRegionChips: Array<string> = [];
  selectedDType: string = null;
  selectedDTypeChips: Array<string> = [];
  selectedCType: string = null;
  serchSelected : boolean = false;
  selectedCTypeChips: Array<string> = [];
  selectedSortValue: string = null;
  selectedFromValue: string = null;
  selectedToValue: string = null;
  searchValue: string = "";
  dataCategories: Array<any> = null;
  categoryDetails: any = null;
  regionOptions: Array<any> = null;
  dataTypeOptions: Array<any> = null;
  subscriptionArray: Array<Subscription> = [];
  page: number = 0;
  totalPages: number = 0;
  totalLayers: number = 0;
  layers: Array<any> = null;
  lastUpdated: number = null;
  @ViewChild('regions') regionsRef: ElementRef;
  @ViewChild('dataTypes') dataTypesRef: ElementRef;
  @ViewChild('dataList') dataListRef: ElementRef;
  isListView: boolean=false;
  fetchingDataInProgress: boolean = false;
  selectedDataType:boolean=false
  sortOptions = [
    {key: 'name', display_text: 'Name'},
   
    {key: 'name-asc', display_text: 'A - Z'},
    {key: 'name-desc', display_text: 'Z - A'},
    {key: 'size-asc', display_text: 'Size : Low to High'},
    {key: 'size-desc', display_text: 'Size : High to Low'},
    {key: 'last_updated', display_text: 'Recently Updated'},
  ]
  vectorOptions=[
    {key: 'point', display_text: 'Point'},
    {key: 'polygon', display_text: 'Polygon'},
    {key: 'polyline', display_text: 'Polyline'}
  ]
 defaultIcons = {
    'vector': 'assets/images/vectorbg.svg',
    'tabular': 'assets/images/tabularbg.svg',
    'raster': 'assets/images/imageunavailable.svg'
  }
  iconType = {
    "point": "assets/images/1icon.svg",
    "polyline": "assets/images/2icon.svg",
    "polygon": "assets/images/3icon.svg",
    "multipolygon": "assets/images/3icon.svg",
    "multipolyline": "assets/images/2icon.svg",
    "linestring": "assets/images/2icon.svg",
    "multilinestring": "assets/images/2icon.svg",
  }
  constructor(
    private _store: Store<any>,
    private dialog: MatDialog,
    private _actions: Actions,
    private router: Router,
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this._store.dispatch(new GetAllDataLibraryCategoriesStartAction());
    this._store.dispatch(new GetDataLibraryCategoryDetailsStartAction(DL_CATEGORY_DETAILS_LOAD));
    this._store.dispatch(new GetDataLibrarySearchStartAction(DL_SEARCH_LOAD));
    this.subscriptionArray.push(
      this._store.select(dataLibraryCategories).subscribe(cats => {
        this.dataCategories = _.cloneDeep(cats);
        this.removeCategorySelections();
      })
    );
    this.subscriptionArray.push(
      this._store.select(dLCategoryDetails).subscribe(cDetails => {
        this.categoryDetails = _.cloneDeep(cDetails);
        this.regionOptions = this.categoryDetails.regions;
        this.regionOptions.map(opt => opt.checked = false);
        const rTotal = this.regionOptions.reduce((acc, curr) => {
          return acc += curr.total ? +curr.total : 0;
        }, 0)
        this.regionOptions.unshift({category: "All", total: rTotal, checked: false});
        if(this.selectedRegionChips.length) {
          this.regionOptions.map(opt => {
            if(this.selectedRegionChips.includes(opt.category)) {
              opt.checked = true;
            }
          })
        } else {
          this.regionOptions[0].checked = true;
        }

        this.dataTypeOptions = this.categoryDetails.dataTypes;
        this.dataTypeOptions.map(opt => opt.checked = false);
        const dTotal = this.dataTypeOptions.reduce((acc, curr) => {
          return acc += curr.total ? +curr.total : 0;
        }, 0)
        this.dataTypeOptions.unshift({category: "All", total: dTotal, checked: false});
        if(this.selectedDTypeChips.length) {
          this.dataTypeOptions.map(opt => {
            if(this.selectedDTypeChips.includes(opt.category)) {
              opt.checked = true;
            }
          })
        } else {
          this.dataTypeOptions[0].checked = true;
        }
      })
    );
    this.subscriptionArray.push(
      this._store.select(dLLayers).subscribe((dllData: any) => {
        this.fetchingDataInProgress = false;
        if(Object.keys(dllData).length) {
          let data = _.cloneDeep(dllData);
          this.page = data.page;
          this.totalPages = data.totalPages;
          this.totalLayers = data.totalLayers ? data.totalLayers : 0;
          this.layers = data.layers;
          this.lastUpdated = data.lastUpdated;
          this.layers.map(layer => {
            if(layer._source) {
              layer._source.is_new = this.isNewLayer(layer._source.last_updated);
              layer._source.tags_on_card = [];
              layer._source.tags_on_overflow = [];
              layer._source.tag_length = layer._source.tags.length;
              layer._source.truncated_description = (layer._source.description && layer._source.description.length > 80) ? `${layer._source.description.substring(0, 76)}...` : (layer._source.description || "");
              layer._source.tags.map((tg, ind) => {
                (ind > 2) ? layer._source.tags_on_overflow.push(tg) : layer._source.tags_on_card.push(tg);
              })
              layer._source.overflow_tags_length = layer._source.tags_on_overflow.length;
              layer._source.preview_image = layer._source.preview_image || this.defaultIcons[layer._source.type];
              layer._source.sub_type = this.iconType[layer._source.sub_type];
            }
          })
          if (!this.selectedSortValue) this.sortField.setValue(null);
        } else {
          this.page = 0;
          this.totalPages = 0;
          this.layers = [];
          this.totalLayers = 0;
          this.lastUpdated = null;
        }
      })
    );
    this.subscriptionArray.push(
      this.searchField.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(val => {   
      if (val) {
     this.serchSelected = true ;
      }else{
      this.serchSelected = false;
       }
        this.selectedSortValue = null;
        this.searchValue = val;
        this.selectedRegion = null;
        this.selectedRegionChips = [];
        this.selectedDType = null;
        this.selectedDTypeChips = [];
        this.removeCategorySelections();
        this.searchWithModifiedParams();
        this.dataListRef.nativeElement.scrollTop = 0;
      })
    );
    this.subscriptionArray.push(
      this.sortField.valueChanges.subscribe(val => {
        this.selectedSortValue = val;
        if(this.selectedSortValue) {
          this.searchWithModifiedParams();
          this.dataListRef.nativeElement.scrollTop = 0;
        }
      })
    );
    // this.subscriptionArray.push(
    //   this.dTypeField.valueChanges.subscribe(val => {
    //     this.selectedDType = val;
    //     this.searchWithModifiedParams();
    //   })
    // );
    this.subscriptionArray.push(
      this._actions.pipe(
        ofType(DataManagementActionTypes.addSelectedDataLayerToSessionSuccess),
        map((response: any) => {
          if(response && response.response && response.response._id) {
            this.toastr.success("New session created with selected layer successfully");
            this.router.navigate(["/home", response.response._id]);
          } else {
            this.toastr.success("Selected layer added to MY DATA successfully");
            this.sessionService.setActiveDataTabIndex(0);
          }
        })
      ).subscribe()
    );
  }

  selectCategory(event, category) {
    const checked = category.checked;
    let categories = this.dataCategories.map(cat => cat.type);
    if(category.type === 'All') {
      if(!event.target.checked) {
        event.target.checked = true;
        return;
      }
      this.dataCategories.map(opt => opt.checked = false);
      this.dataCategories[categories.lastIndexOf("All")].checked = true;
    } else {
      if(this.dataCategories[categories.lastIndexOf("All")].checked) this.dataCategories[categories.lastIndexOf("All")].checked = false;
      category.checked = !checked;
    }
    if(!this.dataCategories.filter(cat => cat.checked).length) this.removeCategorySelections();
    const selectedCategoryOptions = this.dataCategories.filter(opt => opt.checked).map(op => op.type).join(",")
    this.selectedCType = (selectedCategoryOptions === "All" ? null : selectedCategoryOptions);
    
    let payload = DL_CATEGORY_DETAILS_LOAD;
    payload.category = this.selectedCType;
    this._store.dispatch(new GetDataLibraryCategoryDetailsStartAction(payload));
    let searchLoad = _.cloneDeep(DL_SEARCH_LOAD);
    searchLoad.category = this.selectedCType;
    this.clearAllFilter();
    this.fetchingDataInProgress = true;
    this._store.dispatch(new GetDataLibrarySearchStartAction(searchLoad));
    this.selectedCTypeChips  = (this.selectedCType ? this.selectedCType.split(",") : [])
    this.dataListRef.nativeElement.scrollTop = 0;
  }

  searchWithModifiedParams(loadMore = false) {
    let payload: any = _.cloneDeep(DL_SEARCH_LOAD);
    if(loadMore) {
      payload.limit=40
      payload.page = this.page + 1;
    }
    if(!this.selectedCType) {
      payload.search = this.searchValue;
      payload.category = null;
    } else {
      payload.search = "";
      payload.category = this.selectedCType;
    }
    if(this.selectedSortValue==='size-asc' || this.selectedSortValue==='size-desc'){
      payload.sort = "size";
    }
    else{
      payload.sort = this.selectedSortValue;
    }
    if(this.selectedSortValue==='name-asc' || this.selectedSortValue==='name-desc'){
      payload.sort = "name";
    }
    else{
      payload.sort = this.selectedSortValue;
    }
    if(this.selectedSortValue==='point'){
      payload.type = "point";
    }
    else{
      payload.sort = this.selectedSortValue;
    }

    if(this.selectedSortValue==='polygon'){
      payload.type = "polygon";
    }
    else{
      payload.sort = this.selectedSortValue;
    }
    if(this.selectedSortValue==='polyline'){
      payload.type = "polyline";
    }
    else{
      payload.sort = this.selectedSortValue;
    }
    payload.region = this.selectedRegion;
    payload.data_type = this.selectedDType;
    payload.from = this.selectedFromValue;
    payload.to = this.selectedToValue;
    payload.order = null;
    if(this.selectedSortValue === 'size-asc') {
      payload.order = 'asc'
    } else if(this.selectedSortValue==='size-desc'){
      payload.order = 'desc'
    }
    if(this.selectedSortValue === 'name-asc') {
      payload.order = 'asc'
    } else if(this.selectedSortValue==='name-desc'){
      payload.order = 'desc'
    }
    this.fetchingDataInProgress = true;
    this._store.dispatch(new GetDataLibrarySearchStartAction(payload));
    if(!loadMore) {
      let catPayload = DL_CATEGORY_DETAILS_LOAD;
      catPayload.category = this.selectedCType;
      this._store.dispatch(new GetDataLibraryCategoryDetailsStartAction(catPayload));
    }
  }

  addToYourData(layer) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        alertTitle: 'Add To My Data',
        alertText: 'Are you sure you want to create a layer and add it to your data list?'
      },
      disableClose: true
    })
    confirmDialog.afterClosed().subscribe(res => {
      if(!res) return;
      const payload = {layerId: [layer._source.layer_id], add_to_session: false}
      this._store.dispatch(new AddSelectedDataLayerToSessionStartAction(payload));
    })
  }

  showDLLayerDetails(layer) {
    const DLLDetailsDialog = this.dialog.open(DataLibraryLayerDetailsDialog, {
      hasBackdrop: true,
      width: '900px',
      data: layer._source
    })
    DLLDetailsDialog.afterClosed().subscribe(_ => {});
  }

  onScroll(event) {
    if(this.fetchingDataInProgress) return;
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;
    const buffer = 0;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit) {
      setTimeout(() => {
        this.loadMore();
      }, 100);
    }
  }

  loadMore() {
    if (this.page >= this.totalPages) return;
    this.searchWithModifiedParams(true);
  }

  removeFilterChip(ind, type) {
    switch(type) {
      case "Categories":
        const cat = this.selectedCTypeChips[ind];
        this.dataCategories.map(category => {if(category.type === cat) category.checked = false});
        this.selectedCTypeChips.splice(ind, 1);
        if(this.selectedCTypeChips.length) this.selectedCType = this.selectedCTypeChips.join(",")
        else this.selectedCType = null;
        if(!this.selectedCTypeChips.length) this.removeCategorySelections();
        break;
      case "Regions":
        this.selectedRegionChips.splice(ind, 1);
        if(this.selectedRegionChips.length) this.selectedRegion = this.selectedRegionChips.join(",")
        else this.selectedRegion = null;
        break;
      case "Data Types":
        this.selectedDTypeChips.splice(ind, 1);
        if(this.selectedDTypeChips.length) this.selectedDType = this.selectedDTypeChips.join(",")
        else this.selectedDType = null;
      break;
    }
    this.searchWithModifiedParams();
    this.dataListRef.nativeElement.scrollTop = 0;
  }

  removeCategorySelections() {
    if(!this.dataCategories.length) return;
    this.dataCategories.map(cat => cat.checked = false);
    this.dataCategories[0].checked = true;
    this.selectedCType = null;
    this.selectedCTypeChips = [];
  }

  clearFiltersAndRefreshData() {
    this.clearAllFilter();
    this.clearCategoryFilters();
    this.searchWithModifiedParams();
    this.dataListRef.nativeElement.scrollTop = 0;
  }
  
  clearAllFilter() {
    this.selectedRegion = null;
    this.selectedRegionChips = [];
    this.selectedDType = null;
    this.selectedDTypeChips = [];
    this.selectedSortValue = null;
  }

  clearCategoryFilters() {
    this.selectedCType = null;
    this.selectedCTypeChips = [];
    this.dataCategories.map(opt => opt.checked = false);
    this.dataCategories[0].checked = true;
  }

  crossClicked(){
    this.serchSelected = !this.serchSelected; 
    this.searchField.setValue('');
  }

  viewChange(view){
    if(view==='list'){
      this.isListView=true;
    }
    else if(view==='grid'){
      this.isListView=false;
    }
  }

  checkUncheckOption(event, option, type) {
    let categories: Array<any> = null;
    const checked = option.checked;
    if(type === 'dataTypes') {
      categories = this.dataTypeOptions.map(opt => opt.category);
      if(option.category === 'All') {
        this.dataTypeOptions.map(opt => opt.checked = false);
        this.dataTypeOptions[categories.lastIndexOf("All")].checked = !checked;
      } else {
        if(this.dataTypeOptions[categories.lastIndexOf("All")].checked) this.dataTypeOptions[categories.lastIndexOf("All")].checked = false;
        option.checked = !checked;
      }
      const selectedDataTypeOptions = this.dataTypeOptions.filter(opt => opt.checked).map(op => op.category).join(",")
      this.selectedDType = (selectedDataTypeOptions === "All" ? null : selectedDataTypeOptions);
      this.selectedDTypeChips = (this.selectedDType ? this.selectedDType.split(",") : []) 
      } else if(type === 'regions') {
      categories = this.regionOptions.map(opt => opt.category);
      if(option.category === 'All') {
        this.regionOptions.map(opt => opt.checked = false);
        this.regionOptions[categories.lastIndexOf("All")].checked = !checked;
      } else {
        if(this.regionOptions[categories.lastIndexOf("All")].checked) this.regionOptions[categories.lastIndexOf("All")].checked = false;
        option.checked = !checked;
      }
      const selectedRegionOptions = this.regionOptions.filter(opt => opt.checked).map(op => op.category).join(",")
      this.selectedRegion = (selectedRegionOptions === "All" ? null : selectedRegionOptions);
      this.selectedRegionChips = (this.selectedRegion ? this.selectedRegion.split(",") : []);
    }
    this.searchWithModifiedParams();
    this.dataListRef.nativeElement.scrollTop = 0;
    if(option.category==='vector'){   //to add point,polygon,polyline dropdown for vector
      if(option.checked){
        this.selectedDataType=true
      }else{
        this.selectedDataType=false
      }
    }
    
}

  isNewLayer(lLUTime) {
    const timeBefore7Days = ['local', 'dev', 'uat', 'cityos'].includes(environment.envName) ? (new Date().getTime() - (86400000 * 2)) : (new Date().getTime() - (86400000 * 7));
    const layerUpdatedTime = new Date(lLUTime).getTime();
    if(layerUpdatedTime > timeBefore7Days) {
      return true;
    }
    if(this.lastUpdated && (layerUpdatedTime > this.lastUpdated)) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if(this.subscriptionArray.length) {
      this.subscriptionArray.map(sub => sub.unsubscribe());
      this.subscriptionArray = [];
    }
  }
}

export const DL_CATEGORY_DETAILS_LOAD = {
  category: null
}

export const DL_SEARCH_LOAD = {
  page: 1,
  limit: 20,
  search: "",
  sort: null,
  region: null,
  data_type: null,
  from: null,
  to: null,
  category: null,
}