import { UserProfileActions, userProfileactionTypes } from '../actions/profile.action';

export interface ProfileUser {
  _id: string
  name: string,
  email: string,
  password: string,
  contact: string,
  orgDetails: string;
  organisation_id: any
}

export interface State {
  user: ProfileUser;
  userDetails: any;
  updatedDetails: any;
  orgDetails: any;
  subscriptionDetails: any,
  regeneratedToken: any,
}

export const initialState: State = {
  user: null,
  userDetails: {},
  updatedDetails: {},
  orgDetails: {},
  subscriptionDetails: {},
  regeneratedToken: {},
};

export function profileReducer(state = initialState, action: UserProfileActions): State {
  switch (action.type) {
    case userProfileactionTypes.getUsersProfileSuccess:
      return { ...state, user: action.data };
    case userProfileactionTypes.getUserDataSuccess:
      return { ...state, userDetails: action.response }
    case userProfileactionTypes.getChangePasswordSuccess:
      return { ...state, userDetails: action.response }
    case userProfileactionTypes.getUserUpdateSuccess:
      return { ...state, updatedDetails: action.response }
    case userProfileactionTypes.setOrgdetailsSuccessAction:
      return { ...state, orgDetails: action.response }
    case userProfileactionTypes.getOrgDetailsSuccess:
      return { ...state, orgDetails: action.response }
    case userProfileactionTypes.getProfileSubscriptionDetailsSuccess:
      return { ...state, subscriptionDetails: action.response }
    case userProfileactionTypes.getRegeneratedTokenSuccess:
      return { ...state, regeneratedToken: action.response }
  }
  return state;
}
