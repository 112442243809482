import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';

@Injectable()
export class AutoUnsubscribeService implements OnDestroy {

    public subscriptions = new Subscription();

    constructor() { }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}