import { User } from './user-management.reducer';
import { DataManagementActions, DataManagementActionTypes, GetAllDataCollectedLayersSuccessAction } from '../actions/data-management.actions';

export interface Template {

}

export interface Widget {
  form_widget_type: string,
  label: string,
  icon: string,
  required: boolean,
  add_image: boolean,
  show_on_map: boolean,
  properties: Array<any>,
  allowed_formats: Array<string>,
  allow_multiple: boolean,
  condition_operators: Array<string>
}

export interface Projections {
  data: Array<any>,
  totalPages: number,
  currentPage: number
}

export interface LibraryData {
  categories: Array<any>,
  categoryDetails: {
    regions: Array<any>
    dataTypes: Array<any>
  },
  data: {
    page: number,
    totalPages: number,
    layers: Array<any>,
    totalLayers: number,
    lastUpdated: number
  }
}

export interface VectorList {
  data: {
    page: number,
    totalPages: number,
    totalResults: number,
    layers: Array<any>
  }
}

export interface Attribute {
  name: string,
  type: string
}

export interface JoinDataAttributes {
  layerAttributes: Array<Attribute>,
  libraryInfo: Array<any>,
  fileName: string
}

export interface TabularData {
  page: number,
  totalPages: number,
  totalResults: number,
  data: Array<any>
}

export interface DataDetails {
  attributes: Array<any>,
  data: Array<any>
}

export interface State {
  templates: any,
  users: Array<User>,
  widgets: Array<Widget>,
  activeTemplate: any,
  vectorLayers: Array<any>
  projections: Projections,
  statusChange: any,
  deleteTemplate: any
  layerInfo: any,
  libraryData: LibraryData,
  vectorList: VectorList,
  joinDataAttributes: JoinDataAttributes,
  tabularData: TabularData,
  dataDetails: DataDetails,
  groupNames: Array<any>,
  groupLayers: TabularData,
  yourDataLayersList: VectorList,
  layersList: any,
  joinLayersList: any,
  layerPreview: {
    attribute_type: any,
    encoding: string,
    projection: string,
    bbox: Array<any>,
    featureCount: number,
    name: string,
    sampleData: Array<any>,
    createdAt:string,
  },
  GroupLayerIds: Array<string>,
  dataPreviewLayerDetails: any
}
export const initialState: State = {
  templates: {},
  users: [],
  widgets: [],
  activeTemplate: {},
  vectorLayers: [],
  projections: {
    data: [],
    totalPages: 0,
    currentPage: 0
  },
  statusChange: {},
  deleteTemplate: {},
  layerInfo: {},
  libraryData: {
    categories: [],
    categoryDetails: {
      regions: [],
      dataTypes: []
    },
    data: {
      page: 0,
      totalPages: 0,
      layers: [],
      totalLayers: 0,
      lastUpdated: null
    }
  },
  vectorList: {
    data: {
      page: 0,
      totalPages: 0,
      totalResults: 0,
      layers: []
    }
  },
  joinDataAttributes: {
    layerAttributes: [],
    libraryInfo: [],
    fileName: null
  },
  tabularData: {
    page: 0,
    totalPages: 0,
    totalResults: 0,
    data: []
  },
  dataDetails: {
    attributes: [],
    data: []
  },
  groupNames: [],
  groupLayers: {
    page: 0,
    totalPages: 0,
    totalResults: 0,
    data: []
  },
  yourDataLayersList: {
    data: {
      page: 0,
      totalPages: 0,
      totalResults: 0,
      layers: []
    }
  },
  layersList : {},
  joinLayersList: {
    page: 0,
    totalPages: 0,
    totalResults: 0,
    data: []
  },
  layerPreview: {
    attribute_type: {},
    encoding: "",
    projection: "",
    bbox: [],
    featureCount: 0,
    name: "",
    sampleData: [],
    createdAt:""
  },
  GroupLayerIds: [],
  dataPreviewLayerDetails: {}

}
export function dataReducer(state = initialState, action: DataManagementActions): State {
  switch (action.type) {
    case DataManagementActionTypes.getAvailableFormWidgetsSuccess:
      return { ...state, widgets: action.data };
    case DataManagementActionTypes.getAllDataCollectedLayersSuccess:
      let Data = (action as GetAllDataCollectedLayersSuccessAction).data.data;
      let page = parseInt(action.data.page);
      let nullCheck =['null', null, undefined, 'undefined', NaN, 'NAN']
      if ((page === 1) || (nullCheck.includes(page))) {
        state.templates.data = []
      }
      return { ...state, templates: { 'data': [...state.templates.data || [], ...Data], 'page': parseInt(action.data.page), 'pages': parseInt(action.data.pages) } }
    case DataManagementActionTypes.getAssignedUsersSurveyersDataSuccess:
      return {
        ...state,
        users: action.data
      }
    case DataManagementActionTypes.getSelectedTemplateDetailsSuccess:
      const data = action.data;
      data[0].form_template_widgets.forEach((widget, widgetIndex) => {
        const conditionCheck = (widget || {}).conditions;
        if (conditionCheck) {
          let conditions = widget.conditions.map(condition => {
            const relatedWidget = data[0].form_template_widgets.find(widget => widget.id == condition.operand_1);
            return {
              ...condition,
              operand_1_form_template_type: relatedWidget.form_widget_type
            }
          });
          data[0].form_template_widgets[widgetIndex].conditions = conditions;
        }
      });
      return { ...state, activeTemplate: data }
    case DataManagementActionTypes.getVectorLayersSuccess:
      return { ...state, vectorLayers: action.data };
    case DataManagementActionTypes.resetStateStart:
      return {
        ...state, templates: {
          'data': [],
          'page': 1,
          'pages': 1

        }
      }
    case DataManagementActionTypes.getProjectionSystemsSuccess:
      if (+action.response.page === 1) {
        return {
          ...state,
          projections: {
            data: action.response.docs,
            totalPages: +action.response.pages,
            currentPage: +action.response.page
          }
        };
      } else {
        return {
          ...state,
          projections: {
            data: [...state.projections.data, ...action.response.docs],
            totalPages: +action.response.pages,
            currentPage: +action.response.page
          }
        }
      }
    case DataManagementActionTypes.statusChangeSuccessAction:
      return { ...state, statusChange: action.response }
    case DataManagementActionTypes.deleteTemplateSuccess:
      return { ...state, deleteTemplate: action.response }
    case DataManagementActionTypes.getLayerByIdSuccess:
      return { ...state, layerInfo: action.response }
    case DataManagementActionTypes.getAllDataLibraryCategoriesSuccess:
      return { ...state,
        libraryData: {
          categories: action.response,
          categoryDetails: {
            regions: state.libraryData.categoryDetails.regions,
            dataTypes: state.libraryData.categoryDetails.dataTypes
          },
          data: {
            page: state.libraryData.data.page,
            totalPages: state.libraryData.data.totalPages,
            layers: state.libraryData.data.layers,
            totalLayers: state.libraryData.data.totalLayers,
            lastUpdated: state.libraryData.data.lastUpdated,
          }
        }
      }
    case DataManagementActionTypes.getDataLibraryCategoryDetailsSuccess:
      return {
        ...state,
        libraryData: {
          categories: state.libraryData.categories,
          categoryDetails: {
            regions: action.response.subCategoryDetails,
            dataTypes: action.response.dataTypesDetails
          },
          data: {
            page: state.libraryData.data.page,
            totalPages: state.libraryData.data.totalPages,
            layers: state.libraryData.data.layers,
            totalLayers: state.libraryData.data.totalLayers,
            lastUpdated: state.libraryData.data.lastUpdated
          }
        }
      }
    case DataManagementActionTypes.getDataLibrarySearchSuccess:
      if(action.response.page === 1) {
        return {
          ...state,
          libraryData: {
            categories: state.libraryData.categories,
            categoryDetails: {
              regions: state.libraryData.categoryDetails.regions,
              dataTypes: state.libraryData.categoryDetails.dataTypes
            },
            data: {
              page: action.response.page,
              totalPages: action.response.pages,
              layers: action.response.data,
              totalLayers: action.response.total,
              lastUpdated: action.response.library_last_updated
            }
          }
        }
      } else {
        return {
          ...state,
          libraryData: {
            categories: state.libraryData.categories,
            categoryDetails: {
              regions: state.libraryData.categoryDetails.regions,
              dataTypes: state.libraryData.categoryDetails.dataTypes
            },
            data: {
              page: action.response.page,
              totalPages: action.response.pages,
              layers: [...state.libraryData.data.layers, ...action.response.data],
              totalLayers: action.response.total,
              lastUpdated: action.response.library_last_updated
            }
          }
        }
      }
    case DataManagementActionTypes.getVectorListInfoSuccess:
      if(+action.response.page === 1) {
        return {
          ...state,
          vectorList: {
            data: {
              page: +action.response.page,
              totalPages: action.response.pages,
              layers: action.response.data,
              totalResults: action.response.count
            }
          }
        }
      } else {
        return {
          ...state,
          vectorList: {
            data: {
              page: +action.response.page,
              totalPages: action.response.pages,
              layers: [...state.vectorList.data.layers, ...action.response.data],
              totalResults: action.response.count
            }
          }
        }
      }
    case DataManagementActionTypes.getDLAttributesSuccess:
        return {
          ...state,
          joinDataAttributes: {
            layerAttributes: action.response.attributeNames.length ? action.response.attributeNames :[],
            libraryInfo: action.response.columnNames.length ? action.response.columnNames : [],
            // sheetName: action.response.columnNames.length ? action.response.columnNames[0].SheetName : "",
            // columns: action.response.columnNames.length ? action.response.columnNames[0].attribute_type : [],
            // attributes: action.response.attributeNames.length ? action.response.attributeNames :[],
            fileName: action.response.fileName || ""
          }
        }
    case DataManagementActionTypes.getTabularDLAttributesSuccess:
      return {
        ...state,
        joinDataAttributes: {
          libraryInfo: action.response.libraryData.attributeTypes.length ? action.response.libraryData.attributeTypes :[],
          layerAttributes: action.response.layerData.attributeTypes.length ? action.response.layerData.attributeTypes : [],
          fileName: action.response.fileName || ""
        }
      }
    case DataManagementActionTypes.getTabularDataSuccess:
      if(+action.response.page === 1) {
        return {
          ...state,
          tabularData: {
            page: +action.response.page,
            totalPages: action.response.pages,
            data: action.response.data,
            totalResults: action.response.count
          }
        }
      } else {
        return {
          ...state,
          tabularData: {
            page: +action.response.page,
            totalPages: action.response.pages,
            data: [...state.tabularData.data, ...action.response.data],
            totalResults: action.response.count
          }
        }
      }
    case DataManagementActionTypes.getLibraryAndYourDataLayersListSuccess:
      if(+action.response.page === 1) {
        return {
          ...state,
          joinLayersList: {
            page: +action.response.page,
            totalPages: action.response.pages,
            data: action.response.data,
            totalResults: action.response.count
          }
        }
      } else {
        return {
          ...state,
          joinLayersList: {
            page: +action.response.page,
            totalPages: action.response.pages,
            data: [...state.joinLayersList.data, ...action.response.data],
            totalResults: action.response.count
          }
        }
      }
    case DataManagementActionTypes.getDataLibraryDataSuccess:
      if(+action.resp.page === 1) {
        return {
          ...state,
          joinLayersList: {
            page: +action.resp.page,
            totalPages: action.resp.pages,
            data: action.resp.data,
            totalResults: action.resp.count
          }
        }
      } else {
        return {
          ...state,
          joinLayersList: {
            page: +action.resp.page,
            totalPages: action.resp.pages,
            data: [...state.joinLayersList.data, ...action.resp.data],
            totalResults: action.resp.count
          }
        }
      }
    case DataManagementActionTypes.getDataLibraryLayerDetailsSuccess:
    
      return {
        ...state,
        dataDetails: {
          attributes: action.response.attributeNames || [],
          data: action.response.list || [],
        },
        dataPreviewLayerDetails: action.response
      }
    case DataManagementActionTypes.getGroupNamesSuccess:
      return {
        ...state,
        groupNames: action.response || []
      }
    case DataManagementActionTypes.getGroupLayersSuccess:
      if(+action.response.page === 1) {
        return {
          ...state,
          groupLayers: {
            page: +action.response.page,
            totalPages: +action.response.pages,
            data: action.response.docs,
            totalResults: +action.response.count
          }
        }
      } else {
        return {
          ...state,
          groupLayers: {
            page: +action.response.page,
            totalPages: +action.response.pages,
            data: [...state.groupLayers.data, ...action.response.docs],
            totalResults: +action.response.count
          }
        }
      }
    case DataManagementActionTypes.getYourDataLayersListSuccess:
      if(+action.response.page === 1) {
        return {
          ...state,
          yourDataLayersList: {
            data: {
              page: +action.response.page,
              totalPages: action.response.pages,
              layers: action.response.data,
              totalResults: action.response.total
            }
          }
        }
      } else {
        return {
          ...state,
          yourDataLayersList: {
            data: {
              page: +action.response.page,
              totalPages: action.response.pages,
              layers: [...state.yourDataLayersList.data.layers, ...action.response.data],
              totalResults: action.response.count
            }
          }
        }
      }
      case DataManagementActionTypes.layersListSuccess:
        if(action.resp.page ===1) {
          return {
            ...state,
            layersList: {
              page :action.resp.page,
              totalPages: action.resp.pages,
              layers: [...action.resp.data],
              totalResults: action.resp.count
            }

          }
        } else {
          return {
            ...state,
            layersList : {
              page :action.resp.page,
              totalPages: action.resp.pages,
              layers: [...state.layersList.layers,...action.resp.data],
              totalResults: action.resp.count
            }
          }
        }
      case DataManagementActionTypes.getLayerPreviewDataSuccess:
        let attributes = action.response.attribute_type;
        const att_types = {
          String: 'abc',
          Number: '123',
          Date: 'dd-mm-yy',
          Document: 'doc'
        }
        Object.keys(attributes).forEach((key => {attributes[key] = att_types[attributes[key]]}));
        return {
          ...state,
          layerPreview: {
            attribute_type: attributes,
            encoding: action.response.encoding,
            projection: action.response.projection,
            bbox: action.response.bbox,
            featureCount: action.response.featureCount,
            name: action.response.name,
            sampleData: action.response.sampleData,
            createdAt:action.response.createdAt
          }
        }
      case DataManagementActionTypes.getSelectedGroupLayerIdsSuccess:
        return {...state, GroupLayerIds: action.response}
  }
  return state;
}
