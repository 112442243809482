import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { SubscriptionActions, GetSubscriptionPlansStartAction, GetSubscriptionPlansSuccessAction, GetNewSubscriptionStartAction, GetNewSubscriptionSuccessAction, AuthorizePaymentStartAction, AuthorizePaymentSuccessAction, GetPaymentRequiredStatusStartAction, GetPaymentRequiredStatusSuccessAction, UpgradeSubscriptionPlanStartAction, UpgradeSubscriptionPlanSuccessAction, CheckValidSubscriptionStartAction, CheckValidSubscriptionSuccessAction, GetInvoicesStartAction, GetInvoicesSuccessAction, CancelSubscriptionStart, CancelSubscriptionSuccess, SendEmailStartAction, SendEmailSuccessAction, OptFreeSuccessAction, OptFreeStartAction } from '../actions/subscription.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/_services/subscription.service';
import { of, Subscription } from 'rxjs';
import { LogErrorAction, LogFilureAction } from '../actions/user-management.actions';
import { GetProfileSubscriptionDetails } from '../actions/profile.action'
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private subscriptionService: SubscriptionService,
    private _router: Router,
    private toaster: ToastrService
  ) { }

  @Effect()
  $plans = this.actions$.pipe(
    ofType(SubscriptionActions.getSubscriptionPlansStart),
    switchMap((action: GetSubscriptionPlansStartAction) => {
      return this.subscriptionService.getSubscriptionPlans().pipe(
        map((response: any) => {
          if (response.success) {
            return new GetSubscriptionPlansSuccessAction(response);
          }
          return of(new LogFilureAction("Error fetching subscription plans"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $sendEmailSA = this.actions$.pipe(
    ofType(SubscriptionActions.sendEmailStartAction),
    switchMap((action: SendEmailStartAction) => {
      return this.subscriptionService.sendEmail(action.planId).pipe(
        map((response: any) => {
          if (response.success) {
            return new SendEmailSuccessAction(response);
          }
          return of(new LogFilureAction("Error fetching subscription plans"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $optFree = this.actions$.pipe(
    ofType(SubscriptionActions.optFreePlanStartAction),
    switchMap((action: OptFreeStartAction) => {
      return this.subscriptionService.optFreePlan(action.planId).pipe(
        map((response: any) => {
          if (response.success) {
            return new OptFreeSuccessAction(response);
          }
          return of(new LogFilureAction("Error fetching subscription plans"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $newSubscripion = this.actions$.pipe(
    ofType(SubscriptionActions.getNewSubscriptionStart),
    switchMap((action: GetNewSubscriptionStartAction) => {
      return this.subscriptionService.subscribeToNewPlan(action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetNewSubscriptionSuccessAction(response);
          }
          return of(new LogFilureAction("Error Subscribing to selected plan"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $payment = this.actions$.pipe(
    ofType(SubscriptionActions.authorizePaymentStart),
    switchMap((action: AuthorizePaymentStartAction) => {
      return this.subscriptionService.authorizeSubscriptionPayment(action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            return new AuthorizePaymentSuccessAction(response);
          }
          return of(new LogFilureAction("Could not authorize the payment"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $cancelSubscription = this.actions$.pipe(
    ofType(SubscriptionActions.cancelSubscriptionStart),
    switchMap((action: CancelSubscriptionStart) => {
      return this.subscriptionService.cancelSubscription(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            this.toaster.success('Active Plan Has Been Cancelled Successfully.')
            return new CancelSubscriptionSuccess(response.data);
          }
          return of(new LogFilureAction("Could not cancel the subscription. Please try again later!"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $paymentRequired = this.actions$.pipe(
    ofType(SubscriptionActions.getPaymentRequiredStatusStart),
    switchMap((action: GetPaymentRequiredStatusStartAction) => {
      return this.subscriptionService.isPaymentRequired().pipe(
        map((response: any) => {
          if(response.success) {
            return new GetPaymentRequiredStatusSuccessAction(response.data);
          }
          return of(new LogFilureAction("Could not fetch the information. Try again later"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $upgradeSubscription = this.actions$.pipe(
    ofType(SubscriptionActions.upgradeSubscriptionPlanStart),
    switchMap((action: UpgradeSubscriptionPlanStartAction) => {
      return this.subscriptionService.upgradeSubscriptionPlan(action.payload).pipe(
        map((response: any) => {
          if(response.success) {
            return new UpgradeSubscriptionPlanSuccessAction(response.data);
          }
          return of(new LogFilureAction("Could not process the request. Try again later"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  $isValidSubscription = this.actions$.pipe(
    ofType(SubscriptionActions.checkValidSubscriptionStart),
    switchMap((action: CheckValidSubscriptionStartAction) => {
      return this.subscriptionService.hasValidSubscription().pipe(
        map((response: any) => {
          if(response.success) {
            return new CheckValidSubscriptionSuccessAction(response.data);
          }
          return of(new LogFilureAction("Could not fetch the data. Please try again later"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect() 
  $getInvoices = this.actions$.pipe(
    ofType(SubscriptionActions.getInvoicesStartAction),
    switchMap((action: GetInvoicesStartAction) => {
      return this.subscriptionService.getAllInvoices().pipe(
        map((response: any) => {
          if(response && response.success) {
            return new GetInvoicesSuccessAction(response)
          }
          return of(new LogFilureAction('unable to get invoices'))
        })
      )
    })
  )
}