import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { QUERY_OPERATORS, QUERY_OPERATORS_STRING_TYPE } from "src/app/app.constants";
import { GetUniquePropertyValuesStartAction } from "src/app/store/actions/session-management.actions";
import { layerAttributesSelector, propertiesTypeSelector, uniquePropertyValuesSelector } from "src/app/store/selectors/session-management.selector";
import { SessionService } from "src/app/_services/session.service";

@Component({
  selector: "app-query-skeleton",
  templateUrl: "./query-skeleton.component.html",
  styleUrls: ["./query-skeleton.component.scss"]
})

export class QuerySkeletonComponent implements OnInit {
  queryOperators = QUERY_OPERATORS;
  queryOperatorsStringType = QUERY_OPERATORS_STRING_TYPE;
  operators: Array<string> = [];
  layerAttributes$: Observable<any>;
  layerAttributeTypes$: Observable<any>;
  selectedAttribute: any = null;
  layerValues$: Observable<any>;
  filterValue: any = null;
  filterAttribute: any = null;
  selectedUniqueValue: any = null;
  @Input() activeLayer: any = null;
  @Output() updateOutputQuery: EventEmitter<any> = new EventEmitter();
  constructor(
    private _store: Store<any>,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.operators = this.queryOperators;
    this.layerAttributes$ = this._store.select(layerAttributesSelector);
    this.layerAttributeTypes$ = this._store.select(propertiesTypeSelector);
    this.layerValues$ = this._store.select(uniquePropertyValuesSelector);
    this.sessionService.getClearSelectionInQueryDialog().subscribe((val: boolean) => {
      if(val) {
        this.clearAllSelection();
      }
    }) 
    const attributeObj = JSON.parse(sessionStorage.getItem('attribute')); 
    if(attributeObj) {
      if (attributeObj.type === 'Number') {
        this.operators = this.queryOperators;
      } else if (attributeObj.type === 'String') {
        this.operators = this.queryOperatorsStringType;
      }
      this.selectedAttribute = attributeObj.attribute
    }
  }

  onAttributeSelection(attribute, type) {
    if (type === 'Number') {
      this.operators = this.queryOperators;
    } else if (type === 'String') {
      this.operators = this.queryOperatorsStringType;
    }
    this.selectedAttribute = attribute
    const attributeobj = {
      attribute: attribute,
      type: type
    }
    sessionStorage.setItem('attribute',JSON.stringify(attributeobj));
    this.updateOutputQuery.emit(attribute.split(" ").join(""));
  }

  getLayerUniqueValues() {
    this._store.dispatch(new GetUniquePropertyValuesStartAction(
      this.activeLayer.parent_layer_id,
      { property: this.selectedAttribute }
    ));
  }

  onLayerValueSelection(value) {
    this.selectedUniqueValue = value;
    if (typeof (value) == 'string') {
      value = '"' + value + '"';
    }
    // if (isNaN(value)) {
    //   value = '"' + value + '"';
    // }
    this.updateOutputQuery.emit(value);
  }

  addOperator(operator) {
    this.updateOutputQuery.emit(operator);
  }

  clearAllSelection() {
    // this.clearAllSelection = null;
    this.filterValue = null;
    this.selectedUniqueValue = null;
    // this.filterAttribute = null;
  }
}