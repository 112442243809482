import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogContent } from "@angular/material";
import { FormControl } from '@angular/forms';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { Router , ActivatedRoute} from '@angular/router';
import { GetSessionDetailsStartAction} from 'src/app/store/actions/session-management.actions';
import { HideLoaderAction } from 'src/app/store/actions/loader.actions'; 
import { SocketService } from 'src/app/_services/socket.service';
import { ProcessLayerDialogComponent } from '../process-layers-dialog/process-layer-dialog.component';
import { Store } from '@ngrx/store';
import { userProfileSelector } from 'src/app/store/selectors/profile.selector';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
import { BookmarkDialogComponent } from '../bookmark-dialog/bookmark-dialog.component';
import { publishDialogComponent } from '../publish/publish-dialog.component';
import { environment as env } from "../../../environments/environment";
@Component({
  selector: 'map-session-dashboard-dialog',
  templateUrl: './map-session-dashboard-dialog.component.html',
  styleUrls: ['./map-session-dashboard-dialog.component.scss']
})


export class MapDashboardDialogComponent implements OnDestroy {
  userProfileLogo: string = "assets/images/profile_2.svg";
  @ViewChild('bookmark') bookmark: ElementRef
  @ViewChild('notificationDiv') notificationDiv: ElementRef;
  badgeCount: any = 0;
  chatCount: any = 0;
  userData: any = null;
  advancedToolsGraphicsSelectionActive = false;
  // notificationDisplay: boolean = true;
  // chatIconVisibility: boolean = false;\
  subscriptionArr: any[] = [];
  isProductionEnv: boolean = true;
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    public socketService: SocketService,
    public router: Router,
    private _store: Store<any>,
    public sessionService: SessionService,
    public navCtrlService: NavigationCtrl,
    private activatedRoute: ActivatedRoute,
  ) { }
  ngOnDestroy() {
    this.subscriptionArr.map((eachSub) => eachSub.unsubscribe())
    this.subscriptionArr = [];
  }

  ngOnInit() {
    if (["local", "dev", "uat", "cityos"].includes(env.envName)) {
      this.isProductionEnv = false;
    }
    const rightMostPos = window.innerWidth - Number(this.data.position.left - 186.5);
    this.dialogRef.updatePosition({ top: `${this.data.position.top + (this.data.notifications ? 47 : 39)}px`, right: `${rightMostPos - 223}px` });

    this.subscriptionArr.push(this.socketService.unreadCountSubject.subscribe((resp: any) => {
      if (resp && resp.success) {
        this.badgeCount = resp.data.count;
        if (resp.data.count > 9) {
          this.badgeCount = "9+"
        }
      }
    }))

    // this.socketService.unreadCount.subscribe((resp: any) => {
    //     if (resp && resp.success) {
    //         this.chatCount = resp.data.count;
    //         if (resp.data.count > 9) {
    //             this.badgeCount = "9+"
    //         }

    //         if (resp.data.count) {
    //             this.socketService.highLightBadge = true
    //         } else {
    //             this.socketService.highLightBadge = false;
    //         }
    //     }
    // })

    // this._store.select(userProfileSelector).subscribe((data: any) => {
    //   if (Object.keys(data.url).length) {
    //     this.userData = data.url;
    //     this.userProfileLogo = this.userData.profilePic || "assets/images/profile_2.svg";
    //   }
    // })
    // use  
  }

  goToDashboard() {
    this.router.navigate(['app-management']);
    this.dialog.closeAll();
  }

  publish() {
    if (this.advancedToolsGraphicsSelectionActive) return;
    this.sessionService.setAdvancedToolsActive(false);
    this.sessionService.setAdvancedToolsGraphicsSelectionActive(false);
    this.navCtrlService.setIdentifyActiveValue(false);
    const dialogRef = this.dialog.open(publishDialogComponent, {
      width: '800px',
      panelClass: 'BoxPublish',
      hasBackdrop: true,
      // data: {
      //   name: this.userdata.name,
      //   alertTitle: "DELETE",
      //   cancelText: "Cancel",
      //   confirmText: "Delete"
      // }
      data: {
        showUrlByDefault: this.sessionService.showUrlByDefault
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'unpublished') {
        this.sessionService.showUrlByDefault = false;
        this._store.dispatch(new HideLoaderAction());
      } else if (result === 'published') {
        this.sessionService.showUrlByDefault = true;
        this._store.dispatch(new GetSessionDetailsStartAction(this.sessionService.sessionId));
      } else {
        this.sessionService.showUrlByDefault = false;
      }
      // this.result = result;
      // if (result) {
      //   let id = this.userdata._id;
      //   const subURL = `${this.connprops.deleteUser}/${id}`;
      //   this._store.dispatch(new DeleteUserStartAction(subURL));
      // }
    });
  }

  exportMap() {
    this.dialog.closeAll();
    this.navCtrlService.setShowLayerTable(false);
    this.sessionService.setShowEdittedFeatures(false);
    this.navCtrlService.setIdentifyActiveValue(false);
    setTimeout(() => {
      // console.log(this.router.url, "url")
      this.router.navigate(["../home", this.sessionService.sessionId, 'export']);
      // this.router.navigate(["../export"], { relativeTo: this.activatedRoute });
    }, 300);
  }

  bookmarkClicked() {
    this.dialog.closeAll();
    this.navCtrlService.setIdentifyActiveValue(false);
    let dialogPosition = {
      top: this.bookmark.nativeElement.getBoundingClientRect().top,
      left: this.bookmark.nativeElement.getBoundingClientRect().left,
      bottom: this.bookmark.nativeElement.getBoundingClientRect().bottom,
      right: this.bookmark.nativeElement.getBoundingClientRect().right
    }
    let bookmarkedData = this.data.mapData.filter(({ sessionId }) => sessionId === this.sessionService.sessionId);
    const dialogRef = this.dialog.open(BookmarkDialogComponent, {
      // width: '500px',
      // height: '500px',
      panelClass: 'custombox',

      data: {
        bookmarkedData: { ...bookmarkedData[0] },
        center: this.data.center,
        position: dialogPosition

      }
    })
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.sessionService.sendLayerDetailsToServer();
      }
    })
  }
  toggleClicked(event) {
    // this.activatedStatus =event.checked;
    this.dialogRef.close(event.checked)
  }

  chatClicked() {
    this.dialog.closeAll();
    this.router.navigate(['./', 'app-management', 'chat'])
  }
  proFile() {
    this.dialog.closeAll();
    this.router.navigate(['./', 'app-management', 'profile'])
  }
  logout() {
    this.dialogRef.close(false)
    this.navCtrlService.logoutClicked();
  }
  notificationIconClicked() {
    this.dialog.closeAll();

    let dialogPosition = {
      top: this.notificationDiv.nativeElement.getBoundingClientRect().top,
      left: this.notificationDiv.nativeElement.getBoundingClientRect().left,
      bottom: this.notificationDiv.nativeElement.getBoundingClientRect().bottom,
      right: this.notificationDiv.nativeElement.getBoundingClientRect().right,
    }
    this.dialog.open(ProcessLayerDialogComponent, {
      width: '325px',
      panelClass: 'custo-sess',
      data: {
        position: dialogPosition,
        type: 'socketNotificationsmap',
      }
    })

  }

}