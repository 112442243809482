import { ToastrService } from 'ngx-toastr';
import { Injectable } from "@angular/core";
import { CanActivate, Router, CanActivateChild } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private toster: ToastrService) { }

  canActivate() {
    return this.shouldActivate();
  }
  canActivateChild() {
    return this.shouldActivate();
  }
  
  shouldActivate() {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const splittedToken = authToken.split('.');
      const tokenExp = JSON.parse(atob(splittedToken[1])).exp;
      if ((tokenExp * 1000) < Date.now()) {
        this.toster.error('Session expired, please login to continue');
        localStorage.clear();
        this.router.navigate(["sign/signIn"]);
        return false;
      }
      return true;
    } else {
      this.router.navigate(["sign/signIn"]);
    }
    return false;
  }
}