import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CityOsPropertyTaxService} from '../../_services/city-os-property-tax.service';
import {  propertyTaxTypes,getPropertyTaxDataStartAction,
          getPropertyTaxDataSuccessAction,getWaterSupplyDataStartAction,
          getWaterSupplyDataSuccessAction,getSlumDemographicsDataSuccessAction,
          getSlumDataStartAction,getSlumDemographicsDataStartAction,
          getSlumDataSuccessAction,
          getRatioChartDataStartAction,
          getRatioChartDataSuccessAction,
          getSlumSanitationDataStartAction,
          getSlumSanitationDataSuccessAction} from '../actions/city-os-property-tax.actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { LogErrorAction, LogFilureAction } from '../actions/user-management.actions';
import { of } from 'rxjs';
@Injectable()
export class CityOsPropertyTaxEffects {
  constructor(
    private actions$: Actions,
    private CityOsPropertyTaxService: CityOsPropertyTaxService
  ) { }
  
  //for propertyTax module
  @Effect()
  
  propertyTaxData$ = this.actions$.pipe(
    ofType(propertyTaxTypes.getPropertyTaxDataStart),
    switchMap((action: getPropertyTaxDataStartAction) => {
     
      return this.CityOsPropertyTaxService.getPropertyTaxData().pipe(
        map((response: any) => {
          if (response.success) {
            return new getPropertyTaxDataSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch property tax data"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    }) 
  );
   //for watersupply module

  @Effect()
  waterSupplyData$ = this.actions$.pipe(
    ofType(propertyTaxTypes.getWaterSupplyDataStart),
    switchMap((action: getWaterSupplyDataStartAction) => {
     
      return this.CityOsPropertyTaxService.getWaterSupplyData().pipe(
        map((response: any) => {
          if (response.success) {
            return new getWaterSupplyDataSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch water supply tax data"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    }) 
  );
    //for slum module

  @Effect()
  slumData$ = this.actions$.pipe(
    ofType(propertyTaxTypes.getSlumDataStart),
    switchMap((action: getSlumDataStartAction) => {
     
      return this.CityOsPropertyTaxService.getSlumData().pipe(
        map((response: any) => {
          if (response.success) {
            return new getSlumDataSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch slum data"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    }) 
  );

//slum demographics chart of slum module
@Effect()
slumDemographicsData$ = this.actions$.pipe(
  ofType(propertyTaxTypes.getSlumDemographicsDataStart),
  switchMap((action: getSlumDemographicsDataStartAction) => {
   
    return this.CityOsPropertyTaxService.getSlumDemographicsData().pipe(
      map((response: any) => {
        if (response.success) {
          return new getSlumDemographicsDataSuccessAction(response.data);
        }
        return of(new LogFilureAction("Unable to fetch slum demographics data"))
      }),
      catchError((error: Error) => of(new LogErrorAction(error)))
    )
  }) 
);

@Effect()
ratioChartData$ = this.actions$.pipe(
  ofType(propertyTaxTypes.getRatioChartDataStart),
  switchMap((action: getRatioChartDataStartAction) => {
   
    return this.CityOsPropertyTaxService.getRatioChartData().pipe(
      map((response: any) => {
        if (response.success) {
          return new getRatioChartDataSuccessAction(response.data);
        }
        return of(new LogFilureAction("Unable to fetch ratio chart data"))
      }),
      catchError((error: Error) => of(new LogErrorAction(error)))
    )
  }) 
);

@Effect()
slumSanitationData$ = this.actions$.pipe(
  ofType(propertyTaxTypes.getSlumSanitationDataStart),
  switchMap((action: getSlumSanitationDataStartAction) => {
   
    return this.CityOsPropertyTaxService.getSlumSanitationData().pipe(
      map((response: any) => {
        if (response.success) {
          return new getSlumSanitationDataSuccessAction(response.data);
        }
        return of(new LogFilureAction("Unable to fetch slum sanitation  data"))
      }),
      catchError((error: Error) => of(new LogErrorAction(error)))
    )
  }) 
);
}
