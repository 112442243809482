import { Component, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocomplete, MatAutocompleteTrigger, MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { GetProjectionSystemsStartAction } from 'src/app/store/actions/data-management.actions';
import { projectionsSelector } from 'src/app/store/selectors/data-management.selector';
import { FormControl, Validators } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil, map, distinctUntilChanged, debounceTime, retryWhen, delayWhen, catchError, tap } from 'rxjs/operators';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { acceptableFilesFormatsInVectorTools } from './../../app.constants'
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { API } from 'src/app/_services';
import { Utilities } from '../../_helpers/util'
import { ClearDataAction, GetAdvancedToolsSearchResultStartAction, SessionManagementTypes, ExportAsGeoTiffStartAction } from 'src/app/store/actions/session-management.actions';
import { Options } from 'ng5-slider';
import { tableJoinStatusSelector } from 'src/app/store/selectors/session-management.selector';
import { LegendCtrl } from 'src/app/_services/mapLegendsCtrl.service';
import { Actions, ofType } from '@ngrx/effects';



@Component({
    selector: 'common-dialog',
    styleUrls: ['./common-dialog.component.scss'],
    templateUrl: './common-dialog.component.html'
})

export class CommonDialogComponent implements OnDestroy {
    @ViewChild('auto') projectionsAutocompleteRef: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

    projectionsCalled: boolean = false;
    projections: any[] = [];
    pageNumber: number = 1;
    totalPages: number = 1;
    searchProjection: FormControl = new FormControl('', Validators.required);
    loadProjectionsTimeOut: any;
    searchedProjection: string = '';
    autoCompleteTimeout: any;
    fullExtent: boolean = false;
    transparentBackground: boolean = false
    selectedProjection: any = {};
    selectedLayer: any;
    placeholder: string = ''
    fileNameControl: FormControl = new FormControl('');
    acceptableFileFormatsToUpload = acceptableFilesFormatsInVectorTools;
    fileUploadSubject: Subject<any> = new Subject()
    uploadedFile: any;
    tableDataValidResponse: boolean = false;
    layerSelectionControl: FormControl = new FormControl()
    cancelText: string = "CANCEL";
    attributeNames: Array<any> = [];
    columnNames: Array<any> = [];
    joinTypes: Array<any> = [
        { type: 'one', text: 'Perform a one-to-one to get singularly matched layer entries' },
        // { type: 'all', text: 'Perform a one-to-many to get singularity matched layer entries' }
    ]
    joinTypeControl: FormControl = new FormControl('');
    joinDataControl: FormControl = new FormControl('');
    joinVectorControl: FormControl = new FormControl('');
    selectedJoinType: string = '';
    discardRecordsChecked: boolean = false;
    tableJoinTypeOptionChanged = false;
    secondSlide: boolean = false;
    filteredColumnNames: any[];
    timeStampFile: string = '';
    thirdSlide: boolean = false
    matchedData: number = 0;
    mismatchedData: number = 0;
    startTime: number;
    endTime: number;
    confirmButtonText: string = ''
    minDifference: number = 0;
    rasterLayers: any = []
    validateScreen: boolean = true;
    firstFieldRasterValue: FormControl = new FormControl('', Validators.required);
    secondFieldRasterValue: FormControl = new FormControl('', Validators.required);
    bandSelectionControl: FormControl = new FormControl('', Validators.required);
    bands = [];
    sliderOptions: Options = {
        floor: 10,
        ceil: 30,
        step: 5,
        showTicks: true,
        showTicksValues: true,
        stepsArray: []
    }
    smoothenEffect: boolean = true;
    smoothEffectName: string = 'Yes'
    thresholdValue: number = 15;
    smoothEffectOptions = [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
    ]
    payloadObj: { feature_type: string; input_layer_1: { layer_id: any; type: any; }; selection_type: string; attribute_name: any; column_name: any; filename: string; discard: boolean; };
    tableData: any;
    heightPixelFormControl: FormControl = new FormControl('50', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/), Validators.maxLength(5)]);
    widthPixelFormControl: FormControl = new FormControl('50', [Validators.required, Validators.pattern(/^(\d*\.)?\d+$/), Validators.maxLength(5)]);
    subscriptionArray: Subscription[] = []
    prevName: any;
    rasterName: FormControl = new FormControl('output', [Validators.required, Validators.pattern("[a-zA-Z0-9_]*")]);
    exportName: FormControl = new FormControl('export_map', [Validators.required, Validators.pattern("[a-zA-Z0-9_]*"), Validators.maxLength(15)])

    constructor(public dialogRef: MatDialogRef<CommonDialogComponent>, private toasterService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<any>, private _utils: Utilities,
        public sessionService: SessionService, private dialog: MatDialog, private apiService: API,
        private legendService: LegendCtrl, private actions: Actions
    ) {
        dialogRef.disableClose = true;
        this._store.select(projectionsSelector).subscribe((resp: any) => {
            if (resp && Object.keys(resp).length && this.projectionsCalled) {
                this.projections = resp.data;
                this.pageNumber = +resp.currentPage;
                this.totalPages = +resp.totalPages;
                this.projectionsCalled = false
            }
        })
        this.searchProjection.valueChanges.pipe( debounceTime(500), distinctUntilChanged(),).subscribe((val) => {
            this.searchedProjection = val.trim()
            this.loadProjections({
                page: 1,
                limit: 20,
                search: val.trim()
            })
        })
        this.confirmButtonText = this.data.buttonText;
    }
    ngOnDestroy() {
        this.fileUploadSubject.next();
        this.subscriptionArray.map((sub) => sub.unsubscribe())
    }

    ngOnInit() {
        let payload: any = {}
        if (this.data.type == 'exportMap') {
            payload.page = 1;
            payload.search = '',
                payload.limit = 10;
            this.loadProjections(payload)
        } else if (this.data.type === "bandDifference") {
            this.placeholder = this.data.rasterLayers.length ? 'please select a layer to extract' : 'No Layers to extract';
            this.rasterLayers = [...this.data.rasterLayers];
        } else if (this.data.type === 'extractWaterBodies') {
            this.rasterLayers = [...this.data.rasterLayers];
            this.placeholder = this.data.rasterLayers.length ? 'please select a layer to extract' : 'No Layers to extract';
        }
        this.sessionService.errorInBandExtraction.subscribe((resp) => {
            this.bands = [];
        })
        this._store.select(tableJoinStatusSelector).subscribe((tableData: any) => {
            if (tableData && Object.keys(tableData).length && !this.thirdSlide && this.secondSlide) {
                this.endTime = Math.floor(Date.now());
                this.matchedData = tableData.matchCount;
                this.mismatchedData = tableData.misMatch;
                this.thirdSlide = true;
                this.secondSlide = false;
                this.cancelText = 'CANCEL';
                this.confirmButtonText = "SHOW RESULTS"
                let diff = this.endTime - this.startTime;
                this.minDifference = (Math.floor(diff / 60));
                this.minDifference = +(this.minDifference / 1000).toFixed(3);
            }
        })
        this.subscriptionArray.push(
        )
        this.actions.pipe(
            ofType(SessionManagementTypes.exportAsGeoTiffSuccess),
            tap((resp: any) => {
                if (resp && resp.response && resp.response.success) {
                    this.toasterService.success('Exported layer successfully. Please wait for few minutes to see the exported layer on processing logs.');
                    // this.sessionService.openProcessingDialogSubject.next(true)    
                    this.dialogRef.close()
                }
            })
        ).subscribe()

    }
    getLabelName() {
        switch (this.data.type) {
            case 'exportMap':
                return 'Select Projection';
            default: 'nothing'
        }
    }

    autocompleteScroll() {
        if (this.pageNumber >= this.totalPages) return

        this.loadProjectionsTimeOut = setTimeout(() => {
            if (
                this.projectionsAutocompleteRef &&
                this.autocompleteTrigger &&
                this.projectionsAutocompleteRef.panel
            ) {
                fromEvent(this.projectionsAutocompleteRef.panel.nativeElement, 'scroll')
                    .pipe(
                        map(x => this.projectionsAutocompleteRef.panel.nativeElement.scrollTop),
                        takeUntil(this.autocompleteTrigger.panelClosingActions)
                    )
                    .subscribe(x => {
                        const scrollTop = this.projectionsAutocompleteRef.panel.nativeElement
                            .scrollTop;
                        const scrollHeight = this.projectionsAutocompleteRef.panel.nativeElement
                            .scrollHeight;
                        const elementHeight = this.projectionsAutocompleteRef.panel.nativeElement
                            .clientHeight;
                        const atBottom = scrollHeight <= (scrollTop + elementHeight + 10);
                        if (scrollTop && scrollHeight && elementHeight && atBottom && !this.projectionsCalled) {
                            this.loadProjections({
                                page: ++this.pageNumber,
                                limit: 20,
                                search: this.searchedProjection
                            });
                        }
                    });
            }
        });
    }
    autoCompleteOpened() {
        clearTimeout(this.autoCompleteTimeout)
        this.autoCompleteTimeout = setTimeout(() => {
            this.autocompleteScroll()
        }, 100)
    }
    projectionSelected(projection) {
        this.selectedProjection = projection
    }

    loadProjections(payload) {
        this.projectionsCalled = true;
        this._store.dispatch(new GetProjectionSystemsStartAction(payload))
    }
    confirmButtonClicked() {
        switch (this.data.type) {
            case 'exportMap':
                if (this.selectedProjection._id && this.heightPixelFormControl.valid && this.widthPixelFormControl.valid && this.exportName.valid) {
                    let payloadObj = {
                        projection: this.selectedProjection._id,
                        'transparent-background': !this.transparentBackground,
                        height: +this.heightPixelFormControl.value,
                        width: +this.widthPixelFormControl.value,
                        zoom: +this.data.zoom,
                        name: this.exportName.value
                    }
                    if (!this.fullExtent) {
                        let bounds = this.legendService.map.getBounds()
                        const southWest = bounds.getSouthWest();
                        const northEast = bounds.getNorthEast();
                        payloadObj['extent'] = [southWest.lng, southWest.lat, northEast.lng, northEast.lat]
                    } else {
                        payloadObj['extent'] = [-180, -90, 180, 90]
                    }
                    this.apiService.getFormDataToken().subscribe((resp: any) => {
                        if (resp && resp.data.csrf_token) {
                            let convertedFormData = this.sessionService.convertToformWithoutDataPrefix({ ...payloadObj, 'csrf_token': resp.data.csrf_token });
                            this._store.dispatch(new ExportAsGeoTiffStartAction(convertedFormData));
                        }

                    })


                } else {
                    this.toasterService.info('Please enter valid data')
                }
                break;
            case 'bandDifference':
                if (this.validateScreen) {
                    if ((this.firstFieldRasterValue.value._id) && (this.secondFieldRasterValue.value._id) && (+this.bandSelectionControl.value)) {

                        this.validateRasterBands().subscribe((resp: any) => {
                            if (resp && resp.success) {
                                this.validateScreen = false;
                                this.confirmButtonText = "SUBMIT"
                            }
                        })
                    } else {
                        this.toasterService.info('Please select all the fields')
                    }

                } else if (!this.validateScreen && this.rasterName.valid) {
                    this.changeDetection().subscribe((resp: any) => {
                        if (resp && resp.success) {
                            this.dialogRef.close(true)
                        }
                    })
                }

                break;
            case 'table_join':
                if (this.secondSlide && !this.thirdSlide) {
                    if (this.joinVectorControl.value.type && this.joinDataControl.value.type && this.joinTypeControl.value.type) {
                        this.startTime = Math.floor(Date.now());
                        this._store.dispatch(new ClearDataAction({}));
                        this.sessionService.tableJoinClicked = true;
                        this.makeApiCallForTableJoin()
                    } else {
                        this.toasterService.info('Please fill all the fields');
                    }

                } else if (!this.secondSlide && this.thirdSlide) {
                    let data = {
                        checked: true,
                        type: "table_join",
                        selectedLayerId: this.selectedLayer.parent_layer_id,
                        payload: this.payloadObj,
                        tableData: this.tableData
                    }
                    this.sessionService.showDataTable.next(data);
                    this.sessionService.sendPayloadForLayerTable = data;
                    this.sessionService.setShowTempLayerData(true);
                    setTimeout(() => this.sessionService.setShowTableJoinData(true), 100);

                    this.dialogRef.close()
                } else {
                    if (this.selectedLayer && this.selectedLayer.parent_layer_id && this.fileNameControl.value.length) {
                        if (!this.tableJoinTypeOptionChanged) {
                            this.cancelText = "GO BACK";
                            this.tableDataValidResponse = true;
                            this.attributeNames = [...this.attributeNames];
                            this.columnNames = [...this.columnNames];
                            this.filteredColumnNames = [...this.columnNames];

                        } else {
                            this.resetAllValues()

                            this.apiService.getFormDataToken().subscribe((resp: any) => {
                                if ((resp) && (resp.data) && (resp.data.csrf_token)) {
                                    let formData = new FormData();
                                    formData.append('csrf_token', resp.data.csrf_token);
                                    formData.append('upfile', this.uploadedFile);
                                    formData.append('layerId', this.selectedLayer.parent_layer_id)
                                    let datum = formData;
                                    this.sessionService.extractDataWebService(datum).pipe(takeUntil(this.fileUploadSubject), retryWhen(this._utils.genericRetryStrategy({
                                        scalingDuration: 1500,
                                        excludedStatusCodes: [502, 500]
                                    }))).subscribe((resp: any) => {
                                        if (resp && (Object.keys(resp).length) && resp.success) {
                                            this.tableDataValidResponse = true;
                                            this.cancelText = "GO BACK";
                                            this.attributeNames = resp.data.attributeNames;
                                            this.columnNames = resp.data.columnNames;
                                            this.filteredColumnNames = [...this.columnNames];
                                            this.timeStampFile = resp.data.fileInfo.filename || ''
                                        }
                                    })
                                }
                            })
                        }
                        this.secondSlide = true;
                    } else {
                        this.toasterService.info('Please select a layer and Csv to submit')
                    }
                }

                break;
            case 'extractWaterBodies':
                if (this.selectedLayer && this.selectedLayer.parent_layer_id) {
                    this.sessionService.extractWaterBodies({
                        layerId: this.selectedLayer.parent_layer_id,
                        sessionId: this.sessionService.sessionId
                    }).subscribe((resp: any) => {
                        if (resp && resp.success) {
                            this.dialogRef.close(true)
                        }
                    })
                } else {
                    this.toasterService.info('Please select a layer to extract')
                }
                break;
        }
    }
    checkboxChange(event, type, position?) {
        switch (type) {
            case 'extent':
                if (position) {
                    this.fullExtent = event.checked;
                    this.data.extent.currentExtent = !this.fullExtent
                } else {
                    this.data.extent.currentExtent = event.checked;
                    this.fullExtent = !this.data.extent.currentExtent;
                }
                break;
            case 'transparentBackground':
                if (position) {
                    this.transparentBackground = event.checked;
                    this.data.extent.transparentBackground = !this.transparentBackground
                } else {
                    this.data.extent.transparentBackground = event.checked;
                    this.transparentBackground = !this.data.extent.transparentBackground
                }
                break;
        }
    }

    cancelClicked() {
        if ((this.data.type = "table_join") && this.tableDataValidResponse && !this.thirdSlide) {
            if (this.cancelText === 'GO BACK') {
                this.tableJoinTypeOptionChanged = false;
                this.joinVectorControl.reset()
                this.joinDataControl.reset()
                this.joinTypeControl.reset()
                this.discardRecordsChecked=false;
            }
            this.tableDataValidResponse = false;
            this.secondSlide = false
            this.cancelText = 'CANCEL'
        } else {
            this.dialogRef.close();
        }
    }

    layerSelected(event, type = null) {
        if (!type) {
            if (this.data.type === 'table_join') {
                this.tableJoinTypeOptionChanged = true

            }
            this.selectedLayer = event.value
        } else {
            switch (type) {
                case 'firstField':

                    this.firstFieldRasterValue.setValue(event.value)
                    if (this.secondFieldRasterValue.value && this.secondFieldRasterValue.value._id && this.secondFieldRasterValue.value._id === event.value._id) {
                        this.secondFieldRasterValue.setValue({});
                    }
                    this.sessionService.getRasterBands(event.value.parent_layer_id).subscribe((resp: any) => {
                        if (resp && resp.success) {
                            this.bands = [].constructor(+resp.bands_count).fill(0).map((val, i) => i + 1)
                        } else {
                            this.toasterService.info('no bands found')
                        }
                    })
                    break;
                case 'secondField':
                    this.secondFieldRasterValue.setValue(event.value);
                    if (this.firstFieldRasterValue.value && this.firstFieldRasterValue.value._id && this.firstFieldRasterValue.value._id === event.value._id) {
                        this.firstFieldRasterValue.setValue({});
                    }
                    break;

            }
        }
    }
    fileChange(event) {
        let fileType = event.target.files[0].type
        this.uploadedFile = event.target.files[0];
        if ((this.acceptableFileFormatsToUpload.includes(fileType)) && ((event.target.files[0].name.includes('csv')) || (event.target.files[0].name.includes('xls')) || (event.target.files[0].name.includes('xlsx')))) {
            this.fileNameControl.setValue(event.target.files[0].name)
            if (this.data.type === 'table_join') {
                this.tableJoinTypeOptionChanged = true
            }

        } else {
            this.dialog.open(InfoDialogComponent, {
                data: {
                    alertTitle: 'Invalid File',
                    alertText: 'only (.csv,.xls,.xlsx) formats are allowed, please re-upload a valid file'
                }
            })
        }
    }
    compareFn(o1: any, o2: any) {
        return o1 && o2 && (o1.id === o2.id);
    }
    joinVectorFunc(o1: any, o2: any) {
        return o1 && o2 && o1.name === o2.name
    }
    joinDataFunc(o1: any, o2: any) {
        return o1 && o2 && (o1.name === o2.name)
    }

    joinTypeFunc(o1: any, o2: any) {
        return o1 && o2 && o1.type && o2.type
    }
    rasterFieldCmp(o1: any, o2: any) {
        return (o1 && o2 && (o1._id === o2._id))
    }
    checkUnCheckClicked(event) {
        // discardRecordsClicked
        this.discardRecordsChecked = event.checked;
    }
    optionSelected(event, type) {
        switch (type) {
            case 'join-vector-layer':
                this.filteredColumnNames = [...this.columnNames].filter(({ type }) => type === event.value.type);
                if (!this.filteredColumnNames.length) {
                    this.toasterService.info('Please select different column');
                    this.joinDataControl.setValue({});
                    this.joinVectorControl.setValue({})
                }
                break;
            case 'join-data-table':
                if (!this.joinVectorControl.value && !this.joinVectorControl.value.type) {
                    this.toasterService.info('Please select data in Vector layer')
                    this.joinDataControl.setValue({})
                }
                break;
            case 'join-type':
                this.selectedJoinType = event.value.type;
                break;
            case 'bandDifference':
                this.bandSelectionControl.setValue(+(event.value));
                break;
        }
    }
    makeApiCallForTableJoin() {
        this.payloadObj = {
            'feature_type': 'table-join',
            "input_layer_1": {
                "layer_id": this.selectedLayer.parent_layer_id,
                "type": this.selectedLayer.type
            },
            "selection_type": 'all',
            "attribute_name": this.joinVectorControl.value.name,
            "column_name": this.joinDataControl.value.name,
            "filename": this.timeStampFile,
            "discard": this.discardRecordsChecked
        }
        this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(this.payloadObj))


    }
    validateRasterBands() {
        let obj = {
            "beforeLayerId": this.firstFieldRasterValue.value.parent_layer_id,
            "afterLayerId": this.secondFieldRasterValue.value.parent_layer_id,
            sessionId: this.sessionService.sessionId,
            band: +this.bandSelectionControl.value
        }
        return this.sessionService.validateRasterBands(obj)
    }
    onSmoothenChange(event) {
        this.smoothenEffect = event.value.value;
        this.smoothEffectName = event.value.name;

    }
    onChange(event) {
        this.thresholdValue = event.value
    }
    changeDetection() {
        let data = {
            "beforeLayerId": this.firstFieldRasterValue.value.parent_layer_id,
            "afterLayerId": this.secondFieldRasterValue.value.parent_layer_id,
            "sessionId": this.sessionService.sessionId,
            "threshold": this.thresholdValue,
            "bandNumber": +this.bandSelectionControl.value,
            "name": this.rasterName.value || "output",
            "smooth": this.smoothenEffect
        }
        return this.sessionService.postChangeDetection(data)
    }
    resetAllValues() {
        this.filteredColumnNames = [];
        this.columnNames = [];
        this.tableDataValidResponse = false;
        this.discardRecordsChecked = false;
        this.joinVectorControl.setValue({});
        this.joinDataControl.setValue({});
        this.joinTypeControl.setValue({})
    }
    onKeyDown() {
        console.log(this.widthPixelFormControl, "here")
    }
}