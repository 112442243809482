import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { environment } from "../../../environments/environment";
import * as fromUser from "./user-management.reducer";
import * as fromData from "./data-management.reducer";
import * as fromProfile from "./profile.reducer";
import * as fromDashboard from "./dashboard.reducer";
import * as fromSession from "./session-management.reducer";
import * as fromLoader from "./loader.reducer";
import * as fromPropertyTax from "./property-tax.reducer";
import * as fromDataApprovalManagement from "./data-approval-management.reducer";
import * as projectManagement from'./project-management.reducer';
import * as frompropertyTaxData from './city-os-property-tax.reducer';
import * as surveyAnalytics from './survey-analytics.reducer';
import * as fromSubscption from './subscription.reducer';
import { SessionManagementTypes } from '../actions/session-management.actions';

export interface State {
  users: fromUser.State;
  data: fromData.State;
  loader: fromLoader.State;
  router: RouterReducerState;
  profile: fromProfile.State;
  sessions: fromSession.State;
  dashboard: fromDashboard.State;
  propertyTax: fromPropertyTax.State;
  projects: projectManagement.State
  dataApproval: fromDataApprovalManagement.State;
  cityOsPropertyTax:frompropertyTaxData.State;
  subscriptions: fromSubscption.State;
  surveyAnalytics: surveyAnalytics.State;

}

export const reducers: ActionReducerMap<State> = {
  users: fromUser.usersReducer,
  data: fromData.dataReducer,
  loader: fromLoader.reducer,
  router: routerReducer,
  profile: fromProfile.profileReducer,
  sessions: fromSession.sessionsReducer,
  dashboard: fromDashboard.dashboardReducer,
  propertyTax: fromPropertyTax.PropertyTaxReducer,
  projects: projectManagement.projectManagementReducer,
  dataApproval: fromDataApprovalManagement.reducer,
  cityOsPropertyTax:frompropertyTaxData.cityOsPropertyTaxReducer,
  subscriptions: fromSubscption.subscriptionReducer,
  surveyAnalytics: surveyAnalytics.surveyAnalyticsReducer
};

export function localStoreateSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [],
    rehydrate: true,
  })(reducer);
}

export function clearState (reducer) {
  return function (state, action) {
    if (action.type === SessionManagementTypes.logOutSession) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
const skipActions = ["[Loader]"];
const shouldSkip = action =>
  skipActions.some(sAction => action.type.indexOf(sAction) !== -1);
// Temporary logger written as store dev tools in chrome 70 isn't working
export function logger(reducer) {
  return function newReducer(state, action) {
    const nextState = reducer(state, action);
    const skip = "Loader";
    if (!shouldSkip(action)) {
      // console.group(action.type);
      // console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, state);
      // console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
      // console.log(`%c next state`, `color: #4CAF50; font-weight: bold`, nextState);
      // console.groupEnd();
    }
    return nextState;
  };
}

export const metaReducers: MetaReducer<State>[] = environment.production
  ? [localStoreateSyncReducer, clearState]
  : [localStoreateSyncReducer, clearState, logger];
