import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
  LogErrorAction,
  
} from "../actions/user-management.actions";
import { mergeMap, map, catchError, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { Storeservice } from "src/app/_services/store.services";
import { Router, ActivatedRoute } from "@angular/router";
import { dashboardactionTypes, GetDashboardSuccessAction, GetDashboardStartAction } from '../actions/dashboard.actions';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private stores: Storeservice,
    private _router: Router,
    private toaster: ToastrService,
  ) { }


@Effect()
$dashboardData = this.actions$.pipe(
  ofType(dashboardactionTypes.getDashboardStart),
  switchMap((action: GetDashboardStartAction) => {
    return this.stores.getDashboardDetails().pipe(
      map((result: any) => {
        if (result.success) {
          return new GetDashboardSuccessAction(result.data);
        }
      }),
      catchError((error: Error) => of(new LogErrorAction(error)))
    )
  })
)
}