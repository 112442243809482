import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';






@Injectable()

export class ProjectManagementService {
  selectedFormsInProject: any[] = [];
  selectedParentFormInProject: any[] = [];
  selectedChildFormsInProject: any[] = [];
  parentChildRelationArray: any[] = []
  selectedParenFormIdInProject: any[] = [];
  previouslySelectedParentForm: any[] = [];
  skipClicked: string = '';
  name: any = '';
  description: any = '';



  childFormManuallyChanged: boolean = false;
  parentManuallyChanged: boolean = false;
  selectedSurveyers: any[] = []
  changedImplicitly: boolean = false;
  projectId: any = '';
  surveyersChangedImplicitly: boolean = false
  nameAndDescriptionChanged: boolean =false;;
  clearAllValues() {
    this.selectedFormsInProject = [];
    this.selectedParentFormInProject = []
    this.selectedChildFormsInProject = [];
    this.parentChildRelationArray = []
    this.previouslySelectedParentForm = [];

    this.name = '';
    this.description = '';
    this.skipClicked = '';
    this.childFormManuallyChanged = false;
    this.parentManuallyChanged = false;
    this.changedImplicitly = false;
    this.projectId = '';
    this.surveyersChangedImplicitly =false;
    this.nameAndDescriptionChanged = false;
  }

}