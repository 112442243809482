import { Component, OnInit,Inject } from '@angular/core';

import { FormControl } from '@angular/forms'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from "@angular/material";
@Component({
  selector: 'app-building-approval-warning',
  templateUrl: './building-approval-warning.component.html',
  styleUrls: ['./building-approval-warning.component.scss']
})
export class BuildingApprovalWarningComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BuildingApprovalWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {

  }
  apply() {
    
    this.close(true);
  }

  close(flag) {

    if(flag) {
      const result =flag
      this.dialogRef.close(result);
    } else this.dialogRef.close();
  }


}
