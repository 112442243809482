import { ToastrService } from 'ngx-toastr';
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SignUpAuthGuard implements CanActivate {
  constructor(private router: Router, private toster: ToastrService) { }

  canActivate() {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const splittedToken = authToken.split('.');
      const tokenExp = JSON.parse(atob(splittedToken[1])).exp;
      if ((tokenExp * 1000) > Date.now()) {
        // localStorage.clear();
        this.router.navigate(["/app-management"]);
        return false;
      }
      return true;

    }
    return true;
  }
}