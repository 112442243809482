import { Component, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Storeservice } from 'src/app/_services/store.services';
import moment from "moment-mini-ts"
import { ToastrService } from 'ngx-toastr';




@Component({
    selector: 'export-dialog',
    templateUrl: './export-dialog.component.html',
    styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent {
    form: FormGroup;
    currentDate: Date;
    fromDate: FormControl;
    toDate: FormControl;


    constructor(public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public storeService: Storeservice, public fb: FormBuilder, public toasterService: ToastrService) {
        this.form = this.fb.group({
            'email': ['', [Validators.pattern('[A-Za-z0-9.+_%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'), Validators.required]]

        })
        this.fromDate = new FormControl(new Date())
        this.toDate = new FormControl(new Date())
        this.fromDate.disable();
        this.toDate.disable();
        this.currentDate = new Date();
        this.dialogRef.disableClose = true;
        this.toDate.valueChanges.subscribe((val) => {
            let toDate = new Date(val).getTime();
            let fromDate = new Date(this.fromDate.value).getTime();
            if (fromDate >= toDate) {
                this.fromDate.setValue(null)
            }
        })


    }

    cancelClicked() {
        this.dialogRef.close(false)
    }

    sendClicked() {
        if (this.form.valid) {
            if (this.fromDate.value !== null) {
                let startDate = this.fromDate.value;
                let endDate = this.toDate.value;
                endDate = moment(this.toDate.value).endOf('day').toISOString();
                startDate = moment(startDate).startOf('day').toISOString();
                let email = this.form.controls.email.value;
                this.storeService.exportChat({ startDate, endDate, email, roomId: this.data.roomId }).subscribe((response: any) => {
                    if (response && (response.success)) {
                        this.dialogRef.close(true)
                    }
                })
            } else {
                this.toasterService.error('please enter valid date formats')
            }
        } else {
            this.form.controls.email.markAsTouched();
        }

    }

}