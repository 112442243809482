import { Injectable } from "@angular/core";
import { API } from './API.service';
import { SessionService } from './session.service';
import { Store } from '@ngrx/store';
import { activeSessionSelector } from '../store/selectors/session-management.selector';
import { Subscription } from 'rxjs';
import { LegendCtrl } from './mapLegendsCtrl.service';
import { environment as env } from 'src/environments/environment';
import { LayerService } from './layer.service';
import * as mapboxgl from 'mapbox-gl';
import { HideLoaderAction, ShowLoaderAction } from '../store/actions/loader.actions';
import { ToastrService } from 'ngx-toastr';
import { UploadStyleForSessionPublishStartAction } from '../store/actions/session-management.actions';
import dataFile from '../../assets/style1.json';
import { BASE_MAP_LAYERS, BASE_MAP_SOURCES } from '../app.constants';


@Injectable()
export class SessionPublishService {
  sessionPublishJSON = dataFile;
  layers: Array<any> = [];
  sources: any = {};
  sessionDetails: any;
  sessionId: string = null;
  mapType: string = null;
  publishType: string = null;
  subscriptionArr: Array<Subscription> = [];
  layerDetails: Array<any> = [];
  baseLayer: any;
  pbfPath = `${env.pbfPath}`;
  tOsmPbfPath = `${env.tOsmPbfPath}`;
  rasterPbfPath = `${env.rasterPbfPath}`;
  triggeredFromHome = false;
  typeOfAddSession:string=""
  selectedSessionLayers: any = [];
  wfsPublishDetails: { showMapLegend: boolean; searchBar: boolean; customMinZoomRange: number; customMaxZoomRange: number; attributeDisplayOnHover: boolean; zoomRangesDefault: boolean; };
  constructor(
    private api: API,
    private sessionService: SessionService,
    private legendService: LegendCtrl,
    private layerService: LayerService,
    private toastr: ToastrService,
    private _store: Store<any>
  ) { }
  ngOnInitService() {
    if(["uat", "cityos"].includes(env.envName)) {
      this.pbfPath = `${this.sessionService.currentHost}${env.pbfPath}`;
      this.tOsmPbfPath = `${this.sessionService.currentHost}${env.tOsmPbfPath}`;
      this.rasterPbfPath = `${this.sessionService.currentHost}${env.rasterPbfPath}`;
    }
    this.layers = [];
    this.sources = {};
    this.subscriptionArr.push(
      this._store.select(activeSessionSelector).subscribe(sessionDetails => {
        if (this.sessionId = sessionDetails._id) {
          this.sessionDetails = sessionDetails;
          this.layerDetails = sessionDetails.orders;
          this.baseLayer = sessionDetails.base_layer;
        }
      })
    )
  }
  publishSession(sessionId: string, publishType: string, fromHome?: boolean, mapType?: string, layers?: any) {
    this.selectedSessionLayers = layers;
    this.sessionId = sessionId;
    if (mapType) this.mapType = mapType || 'WMS';
    this.publishType = publishType;
    if (fromHome) this.triggeredFromHome = fromHome;
    switch (publishType) {
      case 'private':
        console.log("Private")
        this.makePrivatePublish();
        break;
      case 'public':
        console.log("Public")

        this.makePublicPublish();
        break;
    }
  }

  makePrivatePublish() {
    const payload = this.preparePayload();
    this.uploadPublishedDetails(payload);
  }

  makePublicPublish() {
    this.setLayersStyle();
    this.sessionPublishJSON.layers = this.layers.reverse();
    this.setBaseLayer();
    this.setBackgroundLayerStyle();
    this.setSources();
    this.sessionPublishJSON.sources = this.sources;
    this.setMapZoom();
    this.setMapCenter();
    this.deleteStyleElements();
    this.uploadSessions();
  }

  setBackgroundLayerStyle() {
    const background_color = this.sessionDetails.background_color;
    const background_opacity = this.sessionDetails.background_opacity;
    this.layers.unshift(
      {
        id: 'background',
        type: "background",
        layout: {},
        paint: {
          "background-color": background_color,
          "background-opacity": +background_opacity
        }
      }
    )
  }

  deleteStyleElements() {
    delete this.sessionPublishJSON['created'];
    delete this.sessionPublishJSON['id'];
    delete this.sessionPublishJSON['modified'];
    delete this.sessionPublishJSON['owner'];
    delete this.sessionPublishJSON['visibility'];
    delete this.sessionPublishJSON['draft'];
  }
  setLayersStyle() {
    if (this.layerDetails.length) {
      this.layerDetails.forEach((layer: any) => {
        if(!this.layerService.filterGraduatedLayers(layer.layer_id)) return
        if ((layer.layer_id.show) && (layer.layer_id.layer_type !== 4)) {
          switch (layer.layer_id.layer_type) {
            case 1:
              this.setLayerStyleForVectorLayer(layer.layer_id);
              break;
            case 2:
            case 5:
              this.setLayerStyleForRasterLayer(layer.layer_id);
              break;
            case 3:
            case 4:
              this.setLayerStyleForDataCollectedLayer(layer.layer_id);
              break;
            case 6:
              this.setLayerStyleForOSMLayer(layer.layer_id);
          }
        }
      })
    }
  }
  setLayerStyleForVectorLayer(layerObj) {
    if(layerObj.layer_type === 4) return // not accepting temporary for publish
    switch (layerObj.type.toLowerCase()) {
      case 'point':
        this.setLayerStyleForVectorPointLayer(layerObj);
        break;
      case 'polygon':
      case 'multipolygon':
        this.setLayerStyleForVectorPolygonLayer(layerObj);
        break;
      case 'polyline':
      case 'multipolyline':
      case 'multilinestring':
      case 'linestring':
        this.setLayerStyleForVectorPolylineLayer(layerObj);
        break;
    }
  }
  setLayerStyleForVectorPointLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);
    const iconObj = this.legendService.getPointSymbolStylesObj(layerObj);
    if (this.mapType === 'WMS') {
      iconObj.layout['text-font'] = ['Open Sans Regular']
    }
    let data: any;
    let bubbleTextData: any;
    let heatMapTextData: any = null;
    let clusterCountData: any = null;
    let unClusteredData: any = null;
    if (layerObj.style_type === 4) {
      data = {
        id: layerObj.id,
        type: 'circle',
        source: layer_id,
        paint: this.legendService.filterBubblePaintProps(iconObj.paint, 'circle'),
        layout: { visibility: layerObj.show ? 'visible' : 'none' },
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      }
      if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
      bubbleTextData = {
        id: layerObj.id + 1,
        type: 'symbol',
        source: layer_id,
        paint: this.legendService.filterBubblePaintProps(iconObj.paint, 'text'),
        layout: iconObj.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) bubbleTextData['filter'] = layerObj.filter_query;
    } else if (layerObj.style_type === 5) {
      data = {
        id: layerObj.id,
        type: 'heatmap',
        source: layer_id,
        paint: this.legendService.filterHeatMapPaintProps(iconObj.paint, 'heatmap'),
        layout: { visibility: layerObj.show ? 'visible' : 'none' },
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
      heatMapTextData = {
        id: layerObj.id + 1,
        type: 'symbol',
        source: layer_id,
        paint: this.legendService.filterHeatMapPaintProps(iconObj.paint, 'text'),
        layout: iconObj.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) heatMapTextData['filter'] = layerObj.filter_query;
    } else if (layerObj.style_type === 7) {
      data = {
        id: layerObj.id,
        type: 'circle',
        source: layer_id,
        paint: this.legendService.filterBubblePaintProps(iconObj.paint, 'circle'),
        layout: { visibility: layerObj.show ? 'visible' : 'none' },
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
      clusterCountData = {
        id: layerObj.id + 1,
        type: 'symbol',
        source: layer_id,
        paint: this.legendService.filterBubblePaintProps(iconObj.paint, 'text'),
        layout: iconObj.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) clusterCountData['filter'] = layerObj.filter_query;
      unClusteredData = {
        id: layerObj.id + 2,
        type: 'circle',
        source: layer_id,
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 10,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        },
        layout: {},
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) unClusteredData['filter'] = layerObj.filter_query;
    } else {
      data = {
        id: layerObj.id,
        type: 'symbol',
        source: layer_id,
        paint: iconObj.paint,
        layout: iconObj.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': layer_id
      };
      if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
    }
    this.layers.push(data);
    if (bubbleTextData) {
      this.layers.push(bubbleTextData);
    }
    if (heatMapTextData) {
      this.layers.push(heatMapTextData);
    }
    if (clusterCountData) {
      this.layers.push({ ...clusterCountData, filter: ['has', 'point_count'] })
    }
    if (unClusteredData) {
      this.layers.push({ ...unClusteredData, filter: ['!', ['has', 'point_count']] })
    }
  }
  setLayerStyleForVectorPolygonLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);
    const paintObj = this.legendService.getPolygonFillStylesObj(layerObj);
    const polyLineObj = this.legendService.getPolylineStylesObj(layerObj);
    let pData: any = {
      id: layerObj.id + 1,
      type: 'line',
      source: layer_id,
      paint: polyLineObj.paint,
      layout: polyLineObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${layer_id}`
    };
    if(layerObj.filter_query.length) pData['filter'] = layerObj.filter_query;
    this.layers.push(pData)


    if (layerObj.fixed_field_label || layerObj.category_label || (layerObj.graduated_label && layerObj.show_text_style)) {
      const textStyle: any = this.legendService.getTextStylesForLayer(layerObj, !this.sessionService.showUnApprovedData);
      if (this.mapType === 'WMS') {
        textStyle.layout['text-font'] = ['Open Sans Regular']
      }
      let fData = {
        id: layerObj.id + 2,
        type: 'symbol',
        source: layer_id,
        paint: textStyle.paint,
        layout: textStyle.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${layer_id}`
      };
      if(layerObj.filter_query.length) fData['filter'] = layerObj.filter_query;
      this.layers.push(fData);
    }

    let mainData: any = {
      id: layerObj.id,
      type: 'fill',
      source: layer_id,
      paint: paintObj.paint,
      layout: paintObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${layer_id}`
    };
    if(layerObj.filter_query.length) mainData['filter'] = layerObj.filter_query;
    this.layers.push(mainData);
  }
  setLayerStyleForVectorPolylineLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);
    const polyLineObj = this.legendService.getPolylineStylesObj(layerObj);
    if (layerObj.fixed_field_label || layerObj.category_label || (layerObj.graduated_label && layerObj.show_text_style)) {
      const textStyle: any = this.legendService.getTextStylesForLayer(layerObj, !this.sessionService.showUnApprovedData);
      if (this.mapType === 'WMS') {
        textStyle.layout['text-font'] = ['Open Sans Regular']
      }
      let fData: any = {
        id: layerObj.id + 1,
        type: 'symbol',
        source: layer_id,
        paint: textStyle.paint,
        layout: textStyle.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${layer_id}`
      };
      if(layerObj.filter_query.length) fData['filter'] = layerObj.filter_query;
      this.layers.push(fData);
    }
    let data: any = {
      id: layerObj.id,
      type: 'line',
      source: layer_id,
      paint: polyLineObj.paint,
      layout: polyLineObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${layer_id}`
    };
    if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
    this.layers.push(data);
  }
  setLayerStyleForOSMLayer(layerObj) {
    switch (layerObj.type.toLowerCase()) {
      case 'point':
        this.setLayerStyleForOSMPointLayer(layerObj);
        break;
      case 'polygon':
      case 'multipolygon':
        this.setLayerStyleForOSMPolygonLayer(layerObj);
        break;
      case 'polyline':
      case 'multipolyline':
      case 'multilinestring':
      case 'linestring':
        this.setLayerStyleForOSMPolylineLayer(layerObj);
        break;
    }
  }
  setLayerStyleForOSMPointLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);
    const iconObj = this.legendService.getPointSymbolStylesObj(layerObj);
    if (this.mapType === 'WMS') {
      iconObj.layout['text-font'] = ['Open Sans Regular']
    }
    let data: any = {
      id: layerObj.id,
      type: 'symbol',
      source: layerObj.source_layer,
      paint: iconObj.paint,
      layout: iconObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': layerObj.source_layer
    };
    if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
    this.layers.push(data);
  }
  setLayerStyleForOSMPolygonLayer(layerObj) {
    const source = layerObj.source_layer;
    const paintObj = this.legendService.getPolygonFillStylesObj(layerObj);
    const polyLineObj = this.legendService.getPolylineStylesObj(layerObj);
    let pData: any = {
      id: layerObj.id + 1,
      type: 'line',
      source: source,
      paint: polyLineObj.paint,
      layout: polyLineObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${source}`
    };
    if(layerObj.filter_query.length) pData['filter'] = layerObj.filter_query;
    this.layers.push(pData)

    if (layerObj.fixed_field_label || layerObj.category_label || (layerObj.graduated_label && layerObj.show_text_style)) {
      const textStyle: any = this.legendService.getTextStylesForLayer(layerObj, !this.sessionService.showUnApprovedData);
      if (this.mapType === 'WMS') {
        textStyle.layout['text-font'] = ['Open Sans Regular']
      }
      let fData = {
        id: layerObj.id + 2,
        type: 'symbol',
        source: source,
        paint: textStyle.paint,
        layout: textStyle.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${source}`
      };
      if(layerObj.filter_query.length) fData['filter'] = layerObj.filter_query;
      this.layers.push(fData);
    }

    let mainData: any = {
      id: layerObj.id,
      type: 'fill',
      source: source,
      paint: paintObj.paint,
      layout: paintObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${source}`
    };
    if(layerObj.filter_query.length) mainData['filter'] = layerObj.filter_query;
    this.layers.push(mainData);
  }
  setLayerStyleForOSMPolylineLayer(layerObj) {
    const source = layerObj.source_layer;
    const polyLineObj = this.legendService.getPolylineStylesObj(layerObj);
    if (layerObj.fixed_field_label || layerObj.category_label || (layerObj.graduated_label && layerObj.show_text_style)) {
      const textStyle: any = this.legendService.getTextStylesForLayer(layerObj, !this.sessionService.showUnApprovedData);
      if (this.mapType === 'WMS') {
        textStyle.layout['text-font'] = ['Open Sans Regular']
      }
      let fData: any = {
        id: layerObj.id + 1,
        type: 'symbol',
        source: source,
        paint: textStyle.paint,
        layout: textStyle.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${source}`
      };
      if(layerObj.filter_query.length) fData['filter'] = layerObj.filter_query;
      this.layers.push(fData);
    }
    let data: any = {
      id: layerObj.id,
      type: 'line',
      source: source,
      paint: polyLineObj.paint,
      layout: polyLineObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${source}`
    };
    if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
    this.layers.push(data);
  }
  setLayerStyleForRasterLayer(layerObj) {
    let data: any = {
      "id": layerObj.id,
      "type": "raster",
      "source": layerObj.parent_layer_id,
      layout: { visibility: layerObj.show ? 'visible' : 'none' },
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
    }
    this.layers.push(data);
  }
  setLayerStyleForDataCollectedLayer(layerObj) {
    switch (layerObj.type.toLowerCase()) {
      case 'point':
        this.setLayerStyleForDataCollectedPointLayer(layerObj);
        break;
      case 'polygon':
      case 'multipolygon':
        this.setLayerStyleForDataCollectedPolygonLayer(layerObj);
        break;
      case 'polyline':
      case 'multipolyline':
      case 'multilinestring':
      case 'linestring':
        this.setLayerStyleForDataCollectedPolylineLayer(layerObj);
        break;
    }
  }
  setLayerStyleForDataCollectedPointLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);
    const iconObj = this.legendService.getPointSymbolStylesObj(layerObj, !this.sessionService.showUnApprovedData);
    if (this.mapType === 'WMS') {
      iconObj.layout['text-font'] = ['Open Sans Regular']
    }
    let data = {
      id: layerObj.id,
      type: 'symbol',
      source: layer_id,
      paint: iconObj.paint,
      layout: iconObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': layer_id
    };
    if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
    this.layers.push(data);

  }
  setLayerStyleForDataCollectedPolygonLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);

    if (layerObj.fixed_field_label || layerObj.category_label) {
      const textStyle = this.legendService.getTextStylesForLayer(layerObj, !this.sessionService.showUnApprovedData);
      if (this.mapType === 'WMS') {
        textStyle.layout['text-font'] = ['Open Sans Regular']
      }

      let fData = {
        id: layerObj.id + 2,
        type: 'symbol',
        source: layer_id,
        paint: textStyle.paint,
        layout: textStyle.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${layer_id}`
      };
      if(layerObj.filter_query.length) fData['filter'] = layerObj.filter_query;
      this.layers.push(fData);
    }
    let polyLineObj: any = {};
    if ((layerObj.layer_type === 3) && (layerObj.style_type == 2)) {
      polyLineObj = this.legendService.getDataCollectedPolylineCategoryStyle(layerObj, !this.sessionService.showUnApprovedData);
    } else {

      polyLineObj = this.legendService.getPolylineStylesObj(layerObj, !this.sessionService.showUnApprovedData);
      let pData: any = {
        id: layerObj.id + 1,
        type: 'line',
        source: layer_id,
        paint: polyLineObj.paint,
        layout: polyLineObj.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${layer_id}`
      };
      if(layerObj.filter_query.length) pData['filter'] = layerObj.filter_query;
      this.layers.push(pData)
    }
    const paintObj = this.legendService.getPolygonFillStylesObj(layerObj, !this.sessionService.showUnApprovedData);
    let mainData: any = {
      id: layerObj.id,
      type: 'fill',
      source: layer_id,
      paint: paintObj.paint,
      layout: paintObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${layer_id}`
    };
    if(layerObj.filter_query.length) mainData['filter'] = layerObj.filter_query;
    this.layers.push(mainData);
  }
  setLayerStyleForDataCollectedPolylineLayer(layerObj) {
    const layer_id = this.getLayerId(layerObj);
    if (layerObj.fixed_field_label || layerObj.category_label) {
      const textStyle: any = this.legendService.getTextStylesForLayer(layerObj, !this.sessionService.showUnApprovedData);
      if (this.mapType === 'WMS') {
        textStyle.layout['text-font'] = ['Open Sans Regular']
      }
      let fData = {
        id: layerObj.id + 1,
        type: 'symbol',
        source: layer_id,
        paint: textStyle.paint,
        layout: textStyle.layout,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom,
        'source-layer': `${layer_id}`
      };
      if(layerObj.filter_query.length) fData['filter'] = layerObj.filter_query;
      this.layers.push(fData);
    }
    let polyLineObj: any = {};
    if (layerObj.style_type === 1) {
      polyLineObj = this.legendService.getDataCollectedPolylineFixedStyle(layerObj, !this.sessionService.showUnApprovedData);
    } else if (layerObj.style_type === 2) {
      polyLineObj = this.legendService.getDataCollectedPolylineCategoryStyle(layerObj, !this.sessionService.showUnApprovedData)
    } else {
      polyLineObj = this.legendService.getPolylineStylesObj(layerObj, !this.sessionService.showUnApprovedData)
    }
    let data: any = {
      id: layerObj.id,
      type: 'line',
      source: layer_id,
      paint: polyLineObj.paint,
      layout: polyLineObj.layout,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom,
      'source-layer': `${layer_id}`
    };
    if(layerObj.filter_query.length) data['filter'] = layerObj.filter_query;
    this.layers.push(data);

  }
  setSources() {
    if (this.layerDetails.length) {
      this.layerDetails.forEach(layer => {
        if (layer.layer_id.show) {
          switch (layer.layer_id.layer_type) {
            case 1:
              this.setSourcesForVectorLayer(layer.layer_id);
              break;
            case 2:
              this.setSourcesForRasterLayer(layer.layer_id);
              break;
            case 3:
            case 4:
              this.setSourcesForDataCollectedLayer(layer.layer_id);
              break;
            case 5:
              this.setSourcesForWMSLayer(layer.layer_id);
              break;
            case 6:
              this.setSourcesForOSMLayer(layer.layer_id);
          }
        }
      })
    }
  }
  setSourcesForVectorLayer(layerObj) {
    let layer_id = layerObj.parent_layer_id;
    let tileObj: Array<string> = [];
    if ((layerObj.fixed_field_label && layerObj.style_type === 1) || (layerObj.category_label && layerObj.style_type === 2) ||
      layerObj.style_type === 3 || ((layerObj.style_type === 4) && layerObj.bubble_label) ||
      ((layerObj.style_type === 5) && layerObj.heat_map_label) ||
      ((layerObj.style_type === 6) && layerObj.dotted_display_label)
    ) {
      // layer_id = layerObj.parent_layer_id;
      tileObj = [`${this.pbfPath}/${layerObj.parent_layer_id}/1/{z}/{x}/{y}.pbf`];
    } else if (layerObj.style_type === 7) {
      // this.setSourcesForDataCollectedLayer(layerObj);
    } else {
      // layer_id = `${layerObj.parent_layer_id}_no_prop`
      // tileObj = [`${this.pbfPath}/${layerObj.parent_layer_id}/0/{z}/{x}/{y}.pbf`];
      tileObj = [`${this.pbfPath}/${layerObj.parent_layer_id}/1/{z}/{x}/{y}.pbf`];
    }
    this.sources[layer_id] = {
      type: 'vector',
      tiles: tileObj,
      minzoom: layerObj.min_tile_zoom || 1,
      maxzoom: layerObj.tile_zoom || 3
    };
  }
  setSourcesForRasterLayer(layerObj) {
    const layer_id = layerObj.parent_layer_id;
    const tileObj = [`${this.rasterPbfPath}/${layerObj.parent_layer_id}/{z}/{x}/{y}.png`];
    this.sources[layer_id] = {
      type: 'raster',
      tiles: tileObj,
      minzoom: layerObj.min_tile_zoom || 1,
      maxzoom: layerObj.tile_zoom || 3,
      scheme: 'tms'
    };
  }
  setSourcesForWMSLayer(layerObj) {
    const layer_id = layerObj.parent_layer_id;
    this.sources[layer_id] = {
      type: 'raster',
      minzoom: layerObj.min_tile_zoom || 1,
      maxzoom: layerObj.tile_zoom || 5,
      tiles: [
        layerObj.layer_url
      ],
      tileSize: 256
    }
  }
  setSourcesForOSMLayer(layerObj) {
    let source = layerObj.source_layer;
    let tileObj: Array<string> = [`${this.tOsmPbfPath}/${layerObj.parent_layer_id}/{z}/{x}/{y}.pbf`];
    this.sources[source] = {
      type: 'vector',
      tiles: tileObj,
      minzoom: layerObj.min_tile_zoom || 1,
      maxzoom: layerObj.tile_zoom || 3
    };
  }
  setSourcesForDataCollectedLayer(layerObj) {
    let layer_id: string;
    let tileObj: Array<string> = [];
    if ((layerObj.fixed_field_label && layerObj.style_type === 1) || (layerObj.category_label && layerObj.style_type === 2)) {
      layer_id = layerObj.parent_layer_id;
      tileObj = [`${this.pbfPath}/${layerObj.parent_layer_id}/1/{z}/{x}/{y}.pbf`];
    } else {
      // Needed to remove no_prop because, the data collected layer publish in which we need to plot unapproved data needs the properties to set the sent styles.
      layer_id = `${layerObj.parent_layer_id}`;
      tileObj = [`${this.pbfPath}/${layerObj.parent_layer_id}/1/{z}/{x}/{y}.pbf`];
    }
    this.sources[layer_id] = {
      type: 'vector',
      tiles: tileObj,
      maxzoom: layerObj.tile_zoom || 3,
      minzoom: layerObj.min_tile_zoom || 1,
    }
  }
  setMapZoom() {
    if (this.sessionDetails.zoom) this.sessionPublishJSON.zoom = this.sessionDetails.zoom;
  }
  setMapCenter() {
    if (this.sessionDetails.center) this.sessionPublishJSON.center = this.sessionDetails.center;
  }

  getLayerId(layerObj) {
    return layerObj.parent_layer_id;
    // switch (layerObj.layer_type) {
    //   case 1:
    //     if ((layerObj.fixed_field_label && layerObj.style_type === 1) || (layerObj.category_label && layerObj.style_type === 2) ||
    //       (layerObj.style_type === 3) || ((layerObj.style_type === 4) && layerObj.bubble_label) ||
    //       ((layerObj.style_type === 5) && layerObj.heat_map_label) ||
    //       (layerObj.style_type === 6 && layerObj.dotted_display_label)
    //     ) {
    //       return layerObj.parent_layer_id;
    //     } else {
    //       return `${layerObj.parent_layer_id}_no_prop`;
    //     }
    //   case 3:
    //   case 4:
    //     // if ((layerObj.fixed_field_label && layerObj.style_type === 1) || (layerObj.category_label && layerObj.style_type === 2) || layerObj.style_type === 3) {
    //     //   return layerObj.parent_layer_id;
    //     // } else {
    //     //   return `${layerObj.parent_layer_id}_no_prop`;
    //     // } ** Removed because, the data collected layer publish in which we need to plot unapproved data needs the properties to set the sent styles.
    //     return layerObj.parent_layer_id;
    //   case 2:
    //     return `${layerObj.parent_layer_id}_no_prop`;
    // }
  }

  setBaseLayer() {
    const baseLayer = this.sessionDetails.base_layer;
    if ((baseLayer) && (baseLayer !== 'None')) {
      this.sources[BASE_MAP_SOURCES[this.sessionService.setBaseLayer]] = this.legendService.getSourceProps()
      this.layers.unshift({
        'id': BASE_MAP_LAYERS[this.sessionService.setBaseLayer],
        'type': 'raster',
        'source': BASE_MAP_SOURCES[this.sessionService.setBaseLayer],
        'paint': {},
        'source-layer': BASE_MAP_SOURCES[this.sessionService.setBaseLayer],
        'layout': {}
      });
    }
  }

  uploadSessions() {
    // const file_name = 'style' + new Date().getTime() + '.json';
    const file_type = 'application/json'
    const dataObj = { file_type, type: 'sharejson', publish_type: this.publishType };
    const uploadedData = this.preparePayload();
    if (uploadedData.service_type === 'WFS') {
      uploadedData['wfs_publish_details'] = this.wfsPublishDetails;
      uploadedData['layers_list'] = Object.assign([], this.sessionService.sessionLayers.filter(layer => layer.show));

    }
    this.uploadPublishedDetails(uploadedData);

  }

  preparePayload(response_file_name?, file_name?) {
    switch (this.publishType) {
      case 'public':
        let bBox = [];
        const baseLayer = this.sessionDetails.base_layer;
        const wmsLayers = this.layerDetails.filter(layer => layer.layer_id.layer_type === 5) || [];
        if ((baseLayer && baseLayer !== 'None') || wmsLayers.length) {
          bBox = [-180,
          -85.0511,
            180,
            85.0511]
        } else {
          let bounds = this.getBoundingBoxOfAllLayers();
          if (Object.keys(bounds).length) {
            bBox[0] = bounds._sw.lng;
            bBox[1] = bounds._sw.lat;
            bBox[2] = bounds._ne.lng;
            bBox[3] = bounds._ne.lat;
          }
        }
        return {
          originalname: file_name,
          filename: response_file_name,
          type: 'sharejson',
          publish_type: this.publishType,
          style: this.sessionPublishJSON,
          bounds: bBox,
          service_type: this.mapType
        };
      case 'private':
        return {
          originalname: '',
          filename: '',
          type: 'sharejson',
          publish_type: this.publishType
        }
    }
  }

  uploadPublishedDetails(payload) {
    if (this.publishType === 'public') {

      if (this.mapType === 'WMS') {
        payload.style['glyphs'] = "https://sgp1.digitaloceanspaces.com/tos-fileserver/fonts/{fontstack}/{range}.pbf"
      } else {
        payload.style['glyphs'] = "https://tosapi.transerve.com/tos-fileserver/fonts/{fontstack}/{range}.pbf"
      }
    }
    this._store.dispatch(new UploadStyleForSessionPublishStartAction(this.sessionId, { ...payload, ...this.selectedSessionLayers }));
  }
  getBoundingBoxOfAllLayers() {
    let newBounds: any = new mapboxgl.LngLatBounds();;
    this.layerDetails.forEach((lDetails, i) => {
      const layer = lDetails.layer_id;
      if (!layer.show) {
        if (this.layerDetails.length == i + 1) {
          return newBounds;
        }
        return;
      }
      if (layer.bounding_box && layer.bounding_box.length > 0) {
        const sw = new mapboxgl.LngLat(layer.bounding_box[0], layer.bounding_box[1]);
        const ne = new mapboxgl.LngLat(layer.bounding_box[2], layer.bounding_box[3]);
        const layerBounds = new mapboxgl.LngLatBounds(sw, ne);
        newBounds.extend(layerBounds);
        if (this.layerDetails.length == i + 1) {
          return newBounds;
        }
      }
    });
    return newBounds;
  }
}

export interface SessionPublishJson {
  sources: any,
  layers: Array<any>,
  zoom: number,
  center: Array<number>
}