import { Injectable, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { API } from "./API.service";
import connProp from 'src/assets/connection.properties.json';
import { Observable, Subject, of, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UpdateSessionStartAction } from '../store/actions/session-management.actions';
import * as turf from '@turf/turf';
import { InputParams } from '../dialogs/advanced-tools-dialog/input-params.model';
import { Router, NavigationEnd } from '@angular/router';
import { LayerService } from './layer.service';
import { LayerStyle } from 'src/app/app-home/edit-style/edit-style.model';
import { LayerTextStyle } from '../app-home/app-style/fixed-style/text-style/text-style.model';
import { MatDialog } from '@angular/material';
import { orgDetailsSelector } from '../store/selectors/profile.selector';
import { SubscriptionUpgradeDialogComponent } from '../dialogs/subscription/subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { cloneDeep } from 'lodash';
import { environment as env } from "../../environments/environment";
import { sourceObject, ploatLayerType, paintObject, layoutObject, MINOFFSETVALUEALLOWED, MAXOFFSETVALUEALLOWED, BUBBLE_ICON_STYLE, BUBBLE_TEXT_STYLE, CLUSTERED_ICON_STYLE, CLUSTERED_TEXT_STYLE, DOTTED_ICON_STYLE, DOTTED_TEXT_STYLE, HEAT_MAP_ICON_STYLE, HEAT_MAP_TEXT_STYLE } from '../app.constants';
import { SocketService } from './socket.service';
import { activeSessionSelector } from '../store/selectors/session-management.selector';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { round } from '@turf/turf';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  projectionCheck: Subject<any> = new Subject<any>();
  connProps: any = connProp;
  oldColor:any;
  sessionDetails: any;
  sessionLayers = [];
  sessionId;
  sessionChanged = false;
  selectedLayers = [];
  currentSelectedLayer;
  previousSelectedLayer;
  parentLayerId;
  selectedGeometryLayerId: string = '';
  selectedGraphicsLayerType: string = '';
  selectedCoordinates: string = '';
  selectedGeometryType: string = '';
  selectedQueryLayerId: string = '';
  selectedQueryLayerType: string = '';
  addNewGeometrySelected = false;
  silentlaoder = false;
  loadMore = true;
  private subject: Subject<any> = new Subject<any>();
  sessions: Array<any>;
  query: any;
  showQueryData: boolean = false;
  showGraphicsLayerData = false;
  undoEdit = false;
  fitBounds = false;
  showDataFormEdit = false;
  uploadFileType = '';
  loadingText = '';
  showCollectedOrAttribute = '';
  advancedToolsSearchPayload: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedVectorDataIdObserver: BehaviorSubject<string> = new BehaviorSubject("");
  selectedLayersObserver: BehaviorSubject<any[]> = new BehaviorSubject([]);
  @Output() zoomRangeDeletedObserver: EventEmitter<any> = new EventEmitter();
  @Output() showQueryDataObserver: EventEmitter<any> = new EventEmitter();
  @Output() showGraphicsLayerDataObserver: EventEmitter<any> = new EventEmitter();
  @Output() undoEditObvserver: EventEmitter<any> = new EventEmitter();
  @Output() silentLoaderObserver: EventEmitter<any> = new EventEmitter();
  @Output() loadMoreObserver: EventEmitter<any> = new EventEmitter();
  @Output() showDataFormEditObserver: EventEmitter<any> = new EventEmitter();
  @Output() tickStatusObserver: EventEmitter<any> = new EventEmitter();
  showEdittedFeaturesObserver: BehaviorSubject<any> = new BehaviorSubject(false);
  showCollectedOrAttributeEditObserver: BehaviorSubject<any> = new BehaviorSubject("");
  showEdittedFeatureComparator: BehaviorSubject<boolean> = new BehaviorSubject(false);
  approvalStatusChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  exportedFileNameObserver: BehaviorSubject<string[]> = new BehaviorSubject(["", ""]);
  isGraphicsSearchActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedGraphicsLayer: BehaviorSubject<any> = new BehaviorSubject({});
  editVectorAttribute: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showPublishToaster: BehaviorSubject<boolean> = new BehaviorSubject(true);
  addNewGeometryActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  advancedToolsGraphicsSelectionActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showAdvancedToolsSelectionData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  firstSelectedAdvancedToolLayer: BehaviorSubject<string> = new BehaviorSubject("");
  secondSelectedAdvancedToolLayer: BehaviorSubject<string> = new BehaviorSubject("");
  selectedAdvancedToolType: BehaviorSubject<number> = new BehaviorSubject(null);
  selectedAdvancedToolChoiceType: BehaviorSubject<string> = new BehaviorSubject("");
  temporaryLayerId: BehaviorSubject<string> = new BehaviorSubject("");
  selectedAdvancedToolGeometryType: BehaviorSubject<string> = new BehaviorSubject("");
  showTempLayerData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  advancedToolsActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resetAdvancedToolsTypeSelection: BehaviorSubject<boolean> = new BehaviorSubject(false);
  advancedToolsDrawnCoordinates: BehaviorSubject<string> = new BehaviorSubject("");
  isToolsTabActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedLayersInTools: BehaviorSubject<string[]> = new BehaviorSubject([]);
  hideNewLayer: BehaviorSubject<boolean> = new BehaviorSubject(false);
  paeObject: BehaviorSubject<any> = new BehaviorSubject({});
  multiGraphicsLayerCreationActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  filedLabelChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isShowSimilarSearchPopUp: BehaviorSubject<boolean> = new BehaviorSubject(false);
  expandedToolsTab: BehaviorSubject<string> = new BehaviorSubject(null);
  activeDataTabIndex: BehaviorSubject<number> = new BehaviorSubject(parseInt(localStorage.getItem('tabLocation'), 10) || 0);
  activeSessionLayoutTabIndex: BehaviorSubject<number> = new BehaviorSubject(parseInt(localStorage.getItem('sessionLayoutTab'), 10) || 1);
  sessionsListScrollToTop: BehaviorSubject<boolean> = new BehaviorSubject(false);
  clearSelectionInQueryDialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  networkAnalysisData: BehaviorSubject<any> = new BehaviorSubject(null);
  networkAnalysisActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSwipeOpened: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isWarningEnabledForSimilaritySearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  additionalInputs: BehaviorSubject<InputParams> = new BehaviorSubject({
    difference: null,
    radius: null,
    steps: null,
    distance: null,
    units: null,
    tolerance: null,
    maxEdge: null,
    concavity: null,
    propertyName: null,
    highQuality: null,
    mutate: null,
    cellSize: null,
    gridType: null,
    property: null,
    weight: null,
    breaks: null,
    cellSide: null,
    extent: null
  });
  selectedGeometryIndex: any;
  measureActive: boolean;
  activeLayer: any;
  activeLayerName: string = null;
  showUrlByDefault: boolean = false;
  finalObjInUpdateCall: {};
  setBaseLayer: string = '';
  // wfsApi: string = "";
  // wfsUrl: string = '';
  // wmsUrl: string = '';
  // wmtsUrl: string = "";
  bookmarks: Array<any> = [];
  panOnMapEventEmitter: EventEmitter<any> = new EventEmitter();
  bookmarkedData: any = '';
  showUnApprovedData: boolean = false;
  searchedString: string = '';
  menuOpened: boolean = false;
  renameClicked: boolean = false;
  private previousUrl: string;
  private currentUrl: string;
  public currentHost: string;
  propertyTypeSubject: BehaviorSubject<string> = new BehaviorSubject('Approved');
  selectedPropertyType: string = '';
  setCategoryApproveStatus: BehaviorSubject<any> = new BehaviorSubject('Approved');
  osmTranserveSources: Array<any> = [];
  editClicked: Subject<any> = new Subject();
  editStyleTabChange: Subject<any> = new Subject();
  showZoomRangeSubject: Subject<any> = new Subject();
  showTextZoomSubject: Subject<any> = new Subject();
  modifyTextStyleList: Subject<any> = new Subject();
  layerCategories: Subject<any> = new Subject();
  layerTextStyleCopy: any = {};
  laterIconStyleCopy: any = {};
  iconSizeValidation: boolean = false;
  textStyleList: any = [];
  iconStyleList: any = [];

  approvedIconStyle: any = [];
  unApprovedIconStyle: any = [];
  symbolUpdated: boolean = false;
  textErrorValidation: boolean = false;
  layerIconStyle: LayerStyle = {
    type: 2,
    iconColor: '#2883e9',
    iconImage: 'airport.png',
    iconSize: 1,
    minZoomLevel: 0,
    maxZoomLevel: 22,
    opacity: 1,
    borderColor: '#000000',
    lineEnd: '',
    lineJoin: '',
    categoryKey: '',
    categoryValue: '',
    is_fall_back: false,
    borderOpacity: 0
  };
  layerTextStyle: LayerTextStyle = {
    textColor: '',
    textFont: 'Open Sans',
    textSize: 10,
    textFontWeight: 'Regular',
    letterSpacing: 0,
    textPadding: 0,
    textTransform: '',
    textOpacity: 100,
    textAlignment: 'left',
    maxZoomLevel: 0,
    minZoomLevel: 0,
    categoryKey: '',
    categoryValue: '',
    type: 2,
    is_fall_back: false,
  };
  graduatedAttributes: Subject<any> = new Subject();
  iconsList: BehaviorSubject<any> = new BehaviorSubject([])
  organizationId: any = '';
  mapFonts: BehaviorSubject<any> = new BehaviorSubject([])
  vectorLayerGrouped: boolean = false;
  rasterLayerGrouped: boolean = false;
  editIconClickedSubject: Subject<any> = new Subject();
  orgDetails: any = null;
  map: any;
  afterMap:any;
  showDataTable: Subject<any> = new Subject();
  // openProcessingDialogSubject: Subject<any> = new Subject();
  errorInBandExtraction: Subject<any> = new Subject();
  sendPayloadForLayerTable: any = {}
  selectedRasterToolIndex: number = null;
  selectedRasterToolData: BehaviorSubject<any> = new BehaviorSubject(null);
  setDefaultRasterToolIndex: Subject<any> = new Subject();
  setDetaultVectorToolsIndex: Subject<any> = new Subject();
  @Output() tableJoinObserver: EventEmitter<any> = new EventEmitter()
  tableJoinClicked: boolean = false
  processingLayersTobeDislayedInDialog: any[] = [];
  permanantProcessingLayersSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  processingLayersSubject: BehaviorSubject<any> = new BehaviorSubject([])
  processingLayersWithoutError: any[] = [];
  joinDataRequest: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedTabularDataSheetName: string = null;
  gradualStyleChangedManually: Subject<any> = new Subject();
  categoryExpandStatus: Subject<any> = new Subject();
  resetButtonClicked: boolean =false;
  classificationToBeCopied: boolean =false;
  manual_delete: boolean =false;
  attribute_changed: boolean =false;
  gradualColorChangedManually: Subject<any> = new Subject();
  similarSearchData: BehaviorSubject<any> = new BehaviorSubject([])
  similarSearchTab: BehaviorSubject<number> = new BehaviorSubject(null);
  similarSearchAttributes: BehaviorSubject<any> = new BehaviorSubject([]);
  warningMessage:any;
  dataInsightsPosition: BehaviorSubject<string> = new BehaviorSubject('map');
  sampleObj = [{
      success: true, 
      attribute_type: {
        name : "String",
        STATE : "String",
        DistrctID : "String",
        SOFT_AREA : "String",
      },
      data:[
        { name: "Nicobars", 
          STATE: "Andaman & Nicobar Islands", 
          DistrctID: "35638", 
          SOFT_AREA: "1673.18"
        },
        { name: "Nicobars1", 
          STATE: "Andaman & Nicobar Islands", 
          DistrctID: "35638", 
          SOFT_AREA: "1673.18"
        },
        { 
          name: "Nicobars2", 
          STATE: "Andaman & Nicobar Islands", 
          DistrctID: "35638", 
          SOFT_AREA: "1673.18"
        },{ name: "Nicobars3", 
        STATE: "Andaman & Nicobar Islands", 
        DistrctID: "35638", 
        SOFT_AREA: "1673.18"
      },
      { name: "Nicobars4", 
        STATE: "Andaman & Nicobar Islands", 
        DistrctID: "35638", 
        SOFT_AREA: "1673.18"
      },
      { 
        name: "Nicobars5", 
        STATE: "Andaman & Nicobar Islands", 
        DistrctID: "35638", 
        SOFT_AREA: "1673.18"
      }
      ],
      count:'10,000', 
      page: 1,
      pages: 200
    }];
  dataInsightsLayersObj:BehaviorSubject<any> = new BehaviorSubject(null);
  dataInsightsTabPosition: BehaviorSubject<number> = new BehaviorSubject(null);
  setAttributesArray: BehaviorSubject<any> = new BehaviorSubject([]);

  moveLayerToSessionSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private router: Router,
    private apiService: API,
    private _store: Store<any>,
    private layerService: LayerService,
    private dialog: MatDialog,
    public socketService: SocketService,
    private toaster: ToastrService,
    private loc: Location
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        const angularRoute = this.loc.path();
        const url = window.location.href;
        this.currentHost = url.replace(angularRoute, '');
      };
    });

    this._store.select(orgDetailsSelector).subscribe((org: any) => {
      this.orgDetails = org;
    })

  }
  public getPreviousUrl() {
    return this.previousUrl;
  }
  resetSessionDetails() {
    this.sessionDetails = { name: '' };
    this.sessionLayers = [];
    this.selectedGeometryIndex = null
    this.selectedGeometryLayerId = '';
    this.selectedGraphicsLayerType = '';
    this.selectedGeometryType = '';
    this.selectedCoordinates = '';
    this.selectedQueryLayerId = '';
    this.selectedQueryLayerType = '';
    this.silentlaoder = false;
    this.showCollectedOrAttribute = '';
    this.onUpdate();
  }

  copyLayerWithIds(layerIDsObj) {
    return this.apiService.postNew({
      subURL: `/sessions/copy-layer/${this.sessionId}`,
      datum: { layer_ids: layerIDsObj }
    }, true).pipe(map(response => {
      this.updateSessionDetailsWithResponse(response);
    }, error => {
      alert('Failed to add layers');
    }));

  }
  updateSessionDetailsWithResponse(response) {
    console.log("Updating Session Details"+JSON.stringify(response))
    if (Object.keys(response).length) {
      this.sessionDetails = response;
      this.sessionId = response._id;
      if (this.sessionDetails.orders) {
        this.sessionLayers = this.sessionDetails.orders.map(sessiondata => sessiondata.layer_id);
        if (this.sessionLayers.length && this.selectedLayers.length) {
          this.sessionLayers.forEach(layer => { if (layer.id === this.selectedLayers[0].id) this.selectedLayers = [layer] });
          this.setSelectedLayers(this.selectedLayers);
        }
      } else {
        this.sessionLayers = [];
      }
      this.onUpdate();
    }
  }
  setMapBackground() {
    setTimeout(() => {
      if(this.sessionDetails && this.map) {
        const bgColor = this.sessionDetails.background_color;
        const opac = +this.sessionDetails.background_opacity;
        if(!(bgColor && opac)) return;
        this.map.setPaintProperty("background", 'background-color', bgColor);
        this.map.setPaintProperty("background", 'background-opacity', opac);
      }

      if(this.sessionDetails && this.afterMap) {
        const bgColor = this.sessionDetails.background_color;
        const opac = +this.sessionDetails.background_opacity;
        if(!(bgColor && opac)) return;
        this.afterMap.setPaintProperty("background", 'background-color', bgColor);
        this.afterMap.setPaintProperty("background", 'background-opacity', opac);
      }
    }, 100);
  }
  updateSession(data) {
    console.log("April 27"+JSON.stringify(data))
    this._store.dispatch(new UpdateSessionStartAction(data, this.sessionId));
    this.showPublishToaster.next(false);
  }
  sendLayerDetailsToServer() {




    
    const finalLayer = {};
    this.sessionLayers.forEach(layer => {
      let Obj: any = {
        style: { color: layer.color },
        group_name: layer.group_name,
        show: layer.show,
      }


      console.log("Session Layers April 28"+JSON.stringify(this.sessionLayers))




      if ((this.selectedLayers.length || 0) > 0 && layer._id === this.selectedLayers[0].id) {
        Obj.icon_style = this.selectedLayers[0].icon_style;
        Obj.text_style = this.selectedLayers[0].text_style;
        Obj.category_icon_style = this.selectedLayers[0].category_icon_style;
        Obj.category_text_style = this.selectedLayers[0].category_text_style;
        Obj.graduated_icon_style = this.selectedLayers[0].graduated_icon_style;
        Obj.graduated_text_style = this.selectedLayers[0].graduated_text_style;
        Obj.heat_map_style = this.selectedLayers[0].heat_map_style || {};
        Obj.heat_map_style.bubble_icon_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.bubble_icon_style :  [BUBBLE_ICON_STYLE];
        Obj.heat_map_style.bubble_text_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.bubble_text_style : [BUBBLE_TEXT_STYLE];
        Obj.heat_map_style.heat_map_icon_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.heat_map_icon_style :  [HEAT_MAP_ICON_STYLE];
        Obj.heat_map_style.heat_map_text_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.heat_map_text_style : [HEAT_MAP_TEXT_STYLE];
        Obj.heat_map_style.clustered_icon_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.clustered_icon_style :  [CLUSTERED_ICON_STYLE];
        Obj.heat_map_style.clustered_text_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.clustered_text_style :  [CLUSTERED_TEXT_STYLE];
        Obj.heat_map_style.dotted_icon_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.dotted_icon_style : [DOTTED_ICON_STYLE];
        Obj.heat_map_style.dotted_text_style = this.selectedLayers[0].heat_map_style ? this.selectedLayers[0].heat_map_style.dotted_text_style : [DOTTED_TEXT_STYLE];
        Obj.name = this.selectedLayers[0].name;
        Obj.min_zoom = this.selectedLayers[0].min_zoom;
        Obj.max_zoom = this.selectedLayers[0].max_zoom;
        Obj.field_label = this.selectedLayers[0].field_label;
        Obj.category_label = this.selectedLayers[0].category_label;
        Obj.bubble_label = this.selectedLayers[0].bubble_label;
        Obj.bubble_display_text_label = this.selectedLayers[0].bubble_display_text_label;
        Obj.heat_map_label = this.selectedLayers[0].heat_map_label;
        Obj.heat_map_display_label = this.selectedLayers[0].heat_map_display_label;
        Obj.clustered_label = this.selectedLayers[0].clustered_label;
        Obj.clustered_display_label = this.selectedLayers[0].clustered_display_label;
        Obj.dotted_label = this.selectedLayers[0].dotted_label;
        Obj.dotted_display_label = this.selectedLayers[0].dotted_display_label;
        Obj.style_type = this.selectedLayers[0].style_type;
        Obj.show_text_style = this.selectedLayers[0].show_text_style;
        Obj.graduated_label = this.selectedLayers[0].graduated_label;
        Obj.graduated_class = this.selectedLayers[0].graduated_class;
        Obj.classification_method = this.selectedLayers[0].classification_method;
        Obj.classification_style = this.selectedLayers[0].classification_style;
        Obj.category_display_label = this.selectedLayers[0].category_display_label;
        Obj.graduated_display_label = this.selectedLayers[0].graduated_display_label;
        Obj.symbol = this.selectedLayers[0].symbol;
        Obj.filter_query = this.selectedLayers[0].filter_query;
        Obj.fixed_field_label = this.selectedLayers[0].fixed_field_label;
        Obj.category_field_label = this.selectedLayers[0].category_field_label;
        Obj.graduated_field_label = this.selectedLayers[0]. graduated_field_label;
        Obj.bubble_field_label = this.selectedLayers[0]. bubble_field_label;
        Obj.heat_map_field_label = this.selectedLayers[0]. heat_map_field_label;
        Obj.dotted_field_label = this.selectedLayers[0]. dotted_field_label;
        Obj.clustered_field_label = this.selectedLayers[0]. clustered_field_label;
        if(Obj.style_type === 3) {
          if(this.manual_delete) {

            Obj.maunal_delete = this.manual_delete;
            this.manual_delete =false;
          }
          if(this.classificationToBeCopied && Obj.classification_style['manual']) {
            Obj.classification_copy = 'manual';
            this.classificationToBeCopied =false;
          }
          if(this.resetButtonClicked) {
            Obj.classification_reset = 'manual'
            this.resetButtonClicked =false;
          }
        }
      } else {
        //TODO: fix duplicate field based stylings
        Obj = {...Obj,...layer}
        Obj.heat_map_style = layer.heat_map_style || {};
        Obj.heat_map_style.bubble_icon_style = layer.heat_map_style ? layer.heat_map_style.bubble_icon_style : [BUBBLE_ICON_STYLE];
        Obj.heat_map_style.bubble_text_style = layer.heat_map_style ? layer.heat_map_style.bubble_text_style : [BUBBLE_TEXT_STYLE];
        Obj.heat_map_style.heat_map_icon_style = layer.heat_map_style ? layer.heat_map_style.heat_map_icon_style : [HEAT_MAP_ICON_STYLE];
        Obj.heat_map_style.heat_map_text_style = layer.heat_map_style ? layer.heat_map_style.heat_map_text_style : [HEAT_MAP_TEXT_STYLE];
        Obj.heat_map_style.clustered_icon_style = layer.heat_map_style ? layer.heat_map_style.clustered_icon_style : [CLUSTERED_ICON_STYLE];
        Obj.heat_map_style.clustered_text_style = layer.heat_map_style ? layer.heat_map_style.clustered_text_style : [CLUSTERED_TEXT_STYLE];
        Obj.heat_map_style.dotted_icon_style = layer.heat_map_style ? layer.heat_map_style.dotted_icon_style : [DOTTED_ICON_STYLE];
        Obj.heat_map_style.dotted_text_style = layer.heat_map_style ? layer.heat_map_style.dotted_text_style : [DOTTED_TEXT_STYLE];
        // Obj.icon_style = layer.icon_style;
        // Obj.text_style = layer.text_style;
        // Obj.category_icon_style = layer.category_icon_style;
        // Obj.category_text_style = layer.category_text_style;
        // Obj.graduated_icon_style = layer.graduated_icon_style;
        // Obj.graduated_text_style = layer.graduated_text_style;
        // Obj.name = layer.name;
        // Obj.min_zoom = layer.min_zoom;
        // Obj.max_zoom = layer.max_zoom;
        // Obj.field_label = layer.field_label;
        // Obj.category_label = layer.category_label;
        // Obj.bubble_label = layer.bubble_label;
        // Obj.bubble_display_text_label = layer.bubble_display_text_label;
        // Obj.heat_map_label = layer.heat_map_label;
        // Obj.heat_map_display_label = layer.heat_map_display_label;
        // Obj.clustered_label = layer.clustered_label;
        // Obj.clustered_display_label = layer.clustered_display_label;
        // Obj.dotted_label = layer.dotted_label;
        // Obj.dotted_display_label = layer.dotted_display_label;
        // Obj.style_type = layer.style_type;
        // Obj.show_text_style = layer.show_text_style;
        // Obj.graduated_label = layer.graduated_label;
        // Obj.graduated_class = layer.graduated_class;
        // Obj.classification_method = layer.classification_method;
        // Obj.classification_style = layer.classification_style;
        // Obj.category_display_label = layer.category_display_label;
        // Obj.graduated_display_label = layer.graduated_display_label;
      }
      const id = `${layer._id}`;
      finalLayer[id] = Obj;
    });
    const order = this.sessionLayers.map(layer => layer._id);
    this.finalObjInUpdateCall = finalLayer;
    const finalObj = { name: this.sessionDetails.name, layer_ids: finalLayer, order, is_edited: true, bookmarks: this.bookmarks || [] };
    console.log("Layer Details "+JSON.stringify(finalObj))
    this._store.dispatch(new UpdateSessionStartAction(finalObj, this.sessionId));
    this.showPublishToaster.next(true);
  }

  getBoundingBox(type, coordinates) {
    let value: any = [];
    let geometryType = type.toLowerCase();
    if (geometryType == "point") {
      value = turf.point(coordinates);
    }
    else if (geometryType == "polygon") {
      value = turf.polygon(coordinates);
    }
    else if (geometryType == "linestring") {
      value = turf.lineString(coordinates);
    }
    else if (geometryType == "multipolygon") {
      value = turf.multiPolygon(coordinates);
    }
    else if (geometryType == "multilinestring") {
      value = turf.multiLineString(coordinates);
    }
    return value;
  }

  navigateAfterCheck(type, afterCloseRoute) {
    if (
      this.orgDetails &&
      !this.orgDetails.is_default_user &&
      Object.keys(this.orgDetails).length &&
      this.orgDetails.subscription_plan &&
      this.orgDetails.subscription_plan.plan &&
      Object.keys(this.orgDetails.subscription_plan.plan).length
    ) {
      if (this.dialog.openDialogs.length) return;
      const typeAttrs: any = {
        forms: "createForms",
        users: "user",
        projects: "createForms",
        tempPermLayer: "saveAnalytics",
        spatialAnalytics: "spatialAnalytics",
        spatialData: "spatialData"
      }
      const messages: any = {
        forms: "Please upgrade the subscription plan to be able to add or edit data-forms",
        users: "Please upgrade the subscription plan to be able to add or edit users",
        projects: "Please upgrade the subscription plan to be able to add or edit projects",
        tempPermLayer: "Please upgrade the subscription plan to be able to add spatial data as temporary/permanent layers",
        spatialAnalytics: "Please upgrade the subscription plan to be able to do spatial data operations",
        spatialData: "Please upgrade the subscription plan to be able to upload or create new layers"
      }
      if (!this.orgDetails.subscription_plan.plan[typeAttrs[type]]) {
        const dialogRef = this.dialog.open(SubscriptionUpgradeDialogComponent, {
          width: '800px',
          hasBackdrop: true,
          data: {
            message: messages[type],
            title: "Upgrade Plan",
          }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            if(this.dialog.openDialogs.length) this.dialog.closeAll();
            if (this.orgDetails.subscription_plan.plan.is_free_plan) {
              this.router.navigate(["/subscription/new-subscription"]);
            } else {
              this.router.navigate(["/subscription/upgrade"]);
            }
            return;
          } else {
            if (afterCloseRoute) this.router.navigate([afterCloseRoute]);
            else if (type === "spatialAnalytics") {
              this.setExpandedToolsTab(null);
            }
            return;
          }
        })
      }
    }
  }

  getSessionUpdate(): Observable<any> {
    return this.subject.asObservable();
  }
  onUpdate() {
    this.subject.next('refreshData');
  }

  getSelectedLayers() {

    return this.selectedLayersObserver.asObservable();
  }

  setSelectedLayers(selectedLayer: Array<any>) {
    this.selectedLayers = selectedLayer;
    this.selectedLayersObserver.next(selectedLayer);
    // return this.selectedLayersObserver;
  }
  getShowPublishToaster() {
    return this.showPublishToaster.asObservable();
  }
  setShowQueryData(status) {
    this.showQueryData = status;
    this.showQueryDataObserver.emit(status);
    return this.showQueryDataObserver;
  }

  getShowQueryData() {
    return this.showQueryDataObserver;
  }

  setShowGraphicsLayerData(status) {
    this.showGraphicsLayerData = status;
    this.showGraphicsLayerDataObserver.emit(status);
    return this.showGraphicsLayerDataObserver;
  }

  getShowGraphicsLayerData() {
    return this.showGraphicsLayerDataObserver;
  }

  setShowEdittedFeatures(status) {
    this.showEdittedFeaturesObserver.next(status);
  }

  getShowEdittedFeatures(): Observable<boolean> {
    return this.showEdittedFeaturesObserver.asObservable();
  }

  setShowTempLayerData(status) {
    this.showTempLayerData.next(status);
  }

  getShowTempLayerData(): Observable<boolean> {
    return this.showTempLayerData.asObservable();
  }

  getZoomRangeDeleteStatus() {
    return this.zoomRangeDeletedObserver;
  }

  setZoomRangeDeleteStatus() {
    this.zoomRangeDeletedObserver.emit(true);
    return this.zoomRangeDeletedObserver;
  }

  getUndoEdit() {
    return this.undoEditObvserver;
  }

  setUndoEdit(value) {
    this.undoEdit = value;
    this.undoEditObvserver.emit(value);
  }

  getSilentLoader() {
    return this.silentLoaderObserver;
  }

  setSilentLoader(value) {
    this.silentlaoder = value;
    this.silentLoaderObserver.emit(value);
  }

  getLoadMore() {
    return this.loadMoreObserver;
  }

  setLoadMore(value) {
    this.loadMore = value;
    this.loadMoreObserver.emit(value);
  }

  setDataFormEdit(status) {
    this.showDataFormEdit = status;
    this.showDataFormEditObserver.emit(status);
    return this.showDataFormEditObserver;
  }
  getDataFormEdit() {
    return this.showDataFormEditObserver;
  }
  setCollectedOrAttributeEdit(collectedOrAttribute) {
    this.showCollectedOrAttributeEditObserver.next(collectedOrAttribute);
  }
  getCollectedOrAttributeEdit(): Observable<any> {
    return this.showCollectedOrAttributeEditObserver.asObservable();
  }

  setEditVectorAttribute(value) {
    this.editVectorAttribute.next(value);
  }

  getEditVectorAttribute(): Observable<boolean> {
    return this.editVectorAttribute.asObservable();
  }

  setExportedFileNameObserver(name: string, mimeType: string) {
    this.exportedFileNameObserver.next([name, mimeType]);
  }
  getExportedFileNameObserver(): Observable<string[]> {
    return this.exportedFileNameObserver.asObservable();
  }

  setSelectedVectorDataId(id) {
    this.selectedVectorDataIdObserver.next(id);
  }
  getSelectedVectorDataId(): Observable<string> {
    return this.selectedVectorDataIdObserver.asObservable();
  }

  setShowEdittedFeatureComparator(value) {
    this.showEdittedFeatureComparator.next(value);
  }

  getShowEdittedFeatureComparator(): Observable<boolean> {
    return this.showEdittedFeatureComparator.asObservable();
  }

  setApprovalStatusChanged(val) {
    this.approvalStatusChanged.next(val);
  }

  getApprovalStatusChanged(): Observable<boolean> {
    return this.approvalStatusChanged.asObservable();
  }

  setGraphicsSearchActive(val) {
    this.isGraphicsSearchActive.next(val);
  }

  getGraphicsSearchActive(): Observable<boolean> {
    return this.isGraphicsSearchActive.asObservable();
  }

  setSelectedGraphicsLayer(layer) {
    this.selectedGraphicsLayer.next(layer);
  }

  getSelectedGraphicsLayer(): Observable<any> {
    return this.selectedGraphicsLayer.asObservable();
  }

  getTickStatus() {
    return this.tickStatusObserver;
  }
  setTickStatus(value) {
    this.tickStatusObserver.emit(value);
  }

  getAddNewGeometryActive() {
    return this.addNewGeometryActive.asObservable();
  }

  setAddNewGeometryActive(value) {
    this.addNewGeometrySelected = value;
    this.addNewGeometryActive.next(value);
  }

  getAdvancedToolsGraphicsSelectionActive(): Observable<boolean> {
    return this.advancedToolsGraphicsSelectionActive.asObservable();
  }

  setAdvancedToolsGraphicsSelectionActive(value) {
    this.advancedToolsGraphicsSelectionActive.next(value);
  }

  getFirstSelectedAdvancedToolLayer(): Observable<string> {
    return this.firstSelectedAdvancedToolLayer.asObservable();
  }

  setFirstSelectedAdvancedToolLayer(layerId) {
    this.firstSelectedAdvancedToolLayer.next(layerId);
  }
  getSecondSelectedAdvancedToolLayer(): Observable<string> {
    return this.secondSelectedAdvancedToolLayer.asObservable();
  }

  setSecondSelectedAdvancedToolLayer(layerId) {
    this.secondSelectedAdvancedToolLayer.next(layerId);
  }

  getSelectedAdvancedToolType(): Observable<number> {
    return this.selectedAdvancedToolType.asObservable();
  }

  setSelectedAdvancedToolType(type) {
    this.selectedAdvancedToolType.next(type);
  }

  getSimilarSearchTab(): Observable<number> {
    return this.similarSearchTab.asObservable();
  }

  setSimilarSearchTab(type) {
    this.similarSearchTab.next(type);
  }

  getNetworkAnalysisActive(): Observable<boolean> {
    return this.networkAnalysisActive.asObservable();
  }

  setNetworkAnalysisActive(value) {
    this.networkAnalysisActive.next(value);
  }

  getIsSwipeOpened(): Observable<boolean> {
    return this.isSwipeOpened.asObservable();
  }

  setIsSwipeOpened(value) {
    this.isSwipeOpened.next(value);
  }

  getIsWarningEnabledForSimilaritySearch(): Observable<boolean> {
    return this.isWarningEnabledForSimilaritySearch.asObservable();
  }

  setIsWarningEnabledForSimilaritySearch(value) {
    this.isWarningEnabledForSimilaritySearch.next(value);
  }

  getShowAdvancedToolsSelectionData(): Observable<boolean> {
    return this.showAdvancedToolsSelectionData.asObservable();
  }

  setShowAdvancedToolsSelectionData(value) {
    this.showAdvancedToolsSelectionData.next(value);
  }

  getSelectedAdvancedToolChoiceType(): Observable<string> {
    return this.selectedAdvancedToolChoiceType.asObservable();
  }

  setSelectedAdvancedToolChoiceType(value) {
    this.selectedAdvancedToolChoiceType.next(value);
  }

  getTemporaryLayerId(): Observable<string> {
    return this.temporaryLayerId.asObservable();
  }

  setTemporaryLayerId(value) {
    this.temporaryLayerId.next(value);
  }

  getSelectedAdvancedToolGeometryType(): Observable<string> {
    return this.selectedAdvancedToolGeometryType.asObservable();
  }

  setSelectedAdvancedToolGeometryType(value) {
    this.selectedAdvancedToolGeometryType.next(value);
  }

  setAdvancedToolsActive(value) {
    this.advancedToolsActive.next(value);
  }

  getAdvancedToolsActive(): Observable<boolean> {
    return this.advancedToolsActive.asObservable();
  }

  setResetAdvancedToolsTypeSelection(value) {
    this.resetAdvancedToolsTypeSelection.next(value);
  }

  getResetAdvancedToolsTypeSelection(): Observable<boolean> {
    return this.resetAdvancedToolsTypeSelection.asObservable();
  }

  setAdvancedToolsDrawnCoordinates(coordinates) {
    this.advancedToolsDrawnCoordinates.next(coordinates);
  }

  getAdvancedToolsDrawnCoordinates(): Observable<string> {
    return this.advancedToolsDrawnCoordinates.asObservable();
  }

  setIsToolsTabActive(value) {
    this.isToolsTabActive.next(value);
  }

  getIsToolsTabActive(): Observable<boolean> {
    return this.isToolsTabActive.asObservable();
  }

  setSelectedLayersInTools(value) {
    this.selectedLayersInTools.next(value);
  }

  getSelectedLayersInTools(): Observable<string[]> {
    return this.selectedLayersInTools.asObservable();
  }

  setHideNewLayer(value) {
    this.hideNewLayer.next(value);
  }

  getHideNewLayer(): Observable<boolean> {
    return this.hideNewLayer.asObservable();
  }
  setAdvancedToolsSearchPayload(value) {
    this.advancedToolsSearchPayload.next(value);
  }

  getAdvancedToolsSearchPayload(): Observable<any> {
    return this.advancedToolsSearchPayload.asObservable();
  }

  getPAEObject(): Observable<any> {
    return this.paeObject.asObservable();
  }

  setPAEObject(value) {
    return this.paeObject.next(value);
  }
  setMultiGraphicsLayerCreationActive(value) {
    this.multiGraphicsLayerCreationActive.next(value);
  }

  getMultiGraphicsLayerCreationActive(): Observable<boolean> {
    return this.multiGraphicsLayerCreationActive.asObservable();
  }

  setNetworkAnalysisData(data) {
    this.networkAnalysisData.next(data);
  }

  getNetworkAnalysisData(): Observable<any> {
    return this.networkAnalysisData.asObservable();
  }

  setDataInsightsLayersObj(data) {
    this.dataInsightsLayersObj.next(data);
  }

  getDataInsightsLayersObj(): Observable<any> {
    return this.dataInsightsLayersObj.asObservable();
  }

  

  setAdditionalInputs(obj?: InputParams) {
    let data: InputParams = {
      difference: null,
      radius: null,
      steps: null,
      distance: null,
      units: null,
      tolerance: null,
      maxEdge: null,
      concavity: null,
      propertyName: null,
      highQuality: null,
      mutate: null,
      cellSize: null,
      gridType: null,
      property: null,
      weight: null,
      breaks: null,
      cellSide: null,
      extent: null
    }
    if (obj) {
      data = obj;
    }
    this.additionalInputs.next(data);
  }

  getAdditionalInputs(): Observable<any> {
    return this.additionalInputs.asObservable();
  }

  setLoadingText(value) {
    this.loadingText = value;
  }

  createBookmark(payload) {
    return this.apiService.post({ subURL: `${this.connProps.createBookmark}`, datum: payload }, true)
  }
  getBookmarkData(sessionId) {
    return this.apiService.get(`${this.connProps.getAllBookmarks}/${sessionId}`)
  }
  panOnMapObserver(bookmark) {
    this.panOnMapEventEmitter.emit(bookmark)
  }
  panOnMap() {
    return this.panOnMapEventEmitter;
  }
  setPropertyTypeSubject(value) {
    this.propertyTypeSubject.next(value)
  }
  getPropertyTypeSubject(): Observable<string> {
    return this.propertyTypeSubject.asObservable();
  }
  setFiledLabelChanged(value) {
    this.filedLabelChanged.next(value);
  }

  getFiledLabelChanged(): Observable<boolean> {
    return this.filedLabelChanged.asObservable();
  }

  setExpandedToolsTab(value: string) {
    this.expandedToolsTab.next(value);
  }

  getExpandedToolsTab(): Observable<string> {
    return this.expandedToolsTab.asObservable();
  } 

  setDataInsightsPosition(value: string) {
    this.dataInsightsPosition.next(value);
  }

  getDataInsightsTabPosition(): Observable<number> {
    return this.dataInsightsTabPosition.asObservable();
  } 

  setDataInsightsTabPosition(value: number) {
    this.dataInsightsTabPosition.next(value);
  }

  getSetAttributesArray(): Observable<any> {
    return this.setAttributesArray.asObservable();
  } 

  setSetAttributesArray(value: any) {
    this.setAttributesArray.next(value);
  }

  setMoveLayerToSessionSuccess(value:boolean){
    this.moveLayerToSessionSuccess.next(value);
  }
  getMoveLayerToSessionSuccess():Observable<boolean>{
    return this.moveLayerToSessionSuccess.asObservable();
  }

  getDataInsightsPosition(): Observable<string> {
    return this.dataInsightsPosition.asObservable();
  } 

  getCategoryApproveStatus(): Observable<any> {
    return this.setCategoryApproveStatus.asObservable();
  }
  getSelectedRasterToolData(): Observable<any> {
    return this.selectedRasterToolData.asObservable();
  }
  setSelectedRasterToolData(data) {
    this.selectedRasterToolData.next(data);
  }
  getSimilarSearchData(): Observable<any> {
    return this.similarSearchData.asObservable();
  }
  setSimilarSearchData(data) {
    this.similarSearchData.next(data);
  }
  getsimilarSearchAttributes(): Observable<any> {
    return this.similarSearchAttributes.asObservable();
  }
  setsimilarSearchAttributes(data) {
    this.similarSearchAttributes.next(data);
  }
  setisShowSimilarSearchPopUp(value) {
    this.isShowSimilarSearchPopUp.next(value);
  }

  getisShowSimilarSearchPopUp(): Observable<boolean> {
    return this.isShowSimilarSearchPopUp.asObservable();
  }
  convertToFormData(obj: any, form?: null, namespace?: null) {
    let fd: any = form || new FormData();
    let formKey;
    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property] !== null && obj[property] !== undefined) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = '[' + property + ']';
        }
        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());

        } else if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          if (property === 'collected_data') {
            for (let key in obj[property]) {
              if (obj[property][key] instanceof File) {
                fd.append('data' + formKey + '[' + `${key}` + ']',null);
                fd.append(key, obj[property][key])
              } else {
                if ((typeof obj[property][key] === 'object') && !(obj[property][key] instanceof File)) {
                  if (Array.isArray(obj[property][key])) {
                    let array: any[] = obj[property][key]
                    let includesFile: boolean = array.some((element) => element instanceof File)
                    if (includesFile) {
                      let onlyFiles = obj[property][key].filter((element) => element instanceof File)
                      for (let prop in onlyFiles) {
                        let format: any = onlyFiles;
                        if (format[prop] instanceof File) {
                          fd.append(`${key}` + '[' + `${prop}` + ']', format[prop])
                        }
                      }
                      let excludedFileArray = array.filter((element) => !(element instanceof File));
                      fd.append('data' + '[' + `${property}` + ']' + '[' + `${key}` + ']', excludedFileArray)

                    } else {
                      fd.append('data' + '[' + `${property}` + ']' + '[' + `${key}` + ']', obj[property][key])
                    }


                  } else {
                    if (typeof obj[property][key] === 'object' && obj[property][key] != null) {
                      Object.keys(obj[property][key]).forEach(element => {
                        if (Array.isArray(obj[property][key][element])) {
                          obj[property][key][element].forEach((ele, i) => {
                            Object.keys(ele).forEach((value) => {
                              if (Array.isArray(obj[property][key][element][i][value])) {
                                obj[property][key][element][i][value].forEach((val, j) => {
                                  fd.append("data" + "[" + `${property}` + "]" + "[" + `${key}` + "]" + "[" + `${element}` + "]" + "[" + `${i}` + "]" + "[" + `${value}` + "]" + "[" + `${j}` + "]", obj[property][key][element][i][value][j])
                                });

                              } else {
                                fd.append("data" + '[' + `${property}` + ']' + "[" + `${key}` + "]" + "[" + `${element}` + "]" + "[" + `${i}` + "]" + "[" + `${value}` + "]", obj[property][key][element][i][value])
                              }

                            });
                          });
                        } else {
                          fd.append("data" + '[' + `${property}` + ']' + "[" + `${key}` + "]" + "[" + `${element}` + "]", obj[property][key][element])
                        }
                      });
                    }
                  }
                } else {
                  fd.append('data' + '[' + `${property}` + ']' + '[' + `${[key]}` + ']', obj[property][key]);
                }
              }
            }
          } else {

            this.convertToFormData(obj[property], fd, formKey);
          }
        } else {
          // if it's a string or a File object
          fd.append('data' + formKey, obj[property]);
        }
      }
    }

    return fd;
  }
  convertToformWithoutDataPrefix(obj: any, form?: null, namespace?: null) {
    let fd: any = form || new FormData();
    let formKey;
    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property] !== null && obj[property] !== undefined) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property + ''
        }
        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          this.convertToformWithoutDataPrefix(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;

  }
  toFormData(obj, form = null, namespace = null) {
    let fd = form || new FormData();
    let formKey;

    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property] !== null && obj[property] !== undefined) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        if (
          typeof obj[property] === 'object' &&
          !(obj[property] instanceof File)
        ) {
          this.toFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;
  }
  setNewIconStyleList(iconStyleList, zoomDatumObj, layerStyle?) {

    return iconStyleList.map((iconStyleObj) => {
      if (iconStyleObj.is_fall_back) {
        iconStyleObj.iconColor = zoomDatumObj.iconColor;
        iconStyleObj.iconSize = zoomDatumObj.iconSize;
        iconStyleObj.iconImage = zoomDatumObj.iconImage;
        iconStyleObj.minZoomLevel = (layerStyle && Object.keys(layerStyle).length) ? layerStyle.minZoomLevel : zoomDatumObj.minZoomLevel;
        iconStyleObj.maxZoomLevel = (layerStyle && Object.keys(layerStyle).length) ? layerStyle.maxZoomLevel : zoomDatumObj.maxZoomLevel;
        iconStyleObj.opacity = zoomDatumObj.opacity;
        iconStyleObj.is_fall_back = true;
        return iconStyleObj;
      }
      return iconStyleObj;
    })
  }
  setNewTextStyleList(textStyleList, textZoomDatumObj, layerStyle?) {

    return textStyleList.map((textStyleObj) => {
      if (textStyleObj.is_fall_back) {
        textStyleObj.textSize = textZoomDatumObj.textSize;
        textStyleObj.textFontWeight = textZoomDatumObj.textFontWeight;
        textStyleObj.letterSpacing = textZoomDatumObj.letterSpacing;
        textStyleObj.textpadding = textZoomDatumObj.textPadding;
        textStyleObj.textTransform = textZoomDatumObj.textTransform;
        textStyleObj.textAlignment = textZoomDatumObj.textAlignment;
        textStyleObj.maxZoomLevel = ((layerStyle) && Object.keys(layerStyle).length) ? +layerStyle.maxZoomLevel : textZoomDatumObj.maxZoomLevel;
        textStyleObj.minZoomLevel = (layerStyle && Object.keys(layerStyle).length) ? +layerStyle.minZoomLevel : textZoomDatumObj.minZoomLevel;
        textStyleObj.textColor = textZoomDatumObj.textColor;
        textStyleObj.is_fall_back = true;

        if (textZoomDatumObj.textAnchor) {
          // no need for checking offset as anchor is proesent for point case and offet will be available only for point
          textStyleObj.textAnchor = textZoomDatumObj.textAnchor;
          textStyleObj.textOffsetX = textZoomDatumObj.textOffsetX;
          textStyleObj.textOffsetY = textZoomDatumObj.textOffsetY;
        }

        return textStyleObj

      }
      return textStyleObj
    })
  }
  setIconsList(icons) {
    return this.iconsList.next(icons)
  }
  getIconsList(): Observable<any> {
    return this.iconsList.asObservable()
  }



  fontWeight(fontWeight) {
    switch (fontWeight.trim()) {
      case 'Thin':
        return 100
      case 'Light':
        return 300;
      case 'Regular':
      case '':
        return 400;
      case 'Medium':
        return 500;
      case 'Semibold':
        return 600;
      case 'Bold':
        return 700;
      case 'Extra Bold':
        return 800
      case 'Black':
        return 900;
      default: 100

    }
  }
  setMapFonts(mapFonts) {

    this.mapFonts.next(mapFonts);
  }

  getMapFonts(): Observable<any> {
    return this.mapFonts.asObservable();
  }

  selectFontStyle(o1, o2) {
    return o1 && o2 && o1.trim() === o2.trim();

  }
  selectFontWeight(o1, o2) {
    return o1 && o2 && o1.fontWeightWords && o1.fontWeightWords.trim() == o2.trim()
  }

  toDecimalString(num, after_dec: number) {
    const regx = new RegExp(`^-?\\d+(?:\\.\\d{0,${after_dec}})?`)
    return num.toString().match(regx)[0]
  }
  extractWaterBodies(payload) {
    let apiPath = env.apiPath;
    if (env.production) {
      apiPath = apiPath.replace('v2', 'v3')
    }
    let data = {
      datum: {
        ...payload
      },
      subURL: `${this.connProps.extractWaterBodies}`
    }
    return this.apiService.post(data, true, apiPath)
  }

  extractDataWebService(payload) {

    let data = {
      subURL: `${this.connProps.extractTableJoin}`,
      datum: payload
    }

    return this.apiService.postNew(data, true)
  }
  postTableJoin(payload) {
    let payloadObj = {
      subURL: `${this.connProps.postTableJoin}`,
      datum: payload
    }
    return this.apiService.postNew(payloadObj, true)
  }
  postVectorTableJoin(payload) {
    let payloadObj = {
      subURL: `${this.connProps.postVectorTableJoin}`,
      datum: payload
    }
    return this.apiService.postNew(payloadObj, true)
  }
  validateRasterBands(payload) {
    let payloadObj = {
      subURL: `${this.connProps.validateRasterBands}`,
      datum: payload
    }
    let hostUrl = this.apiService.changeBaseUrl(env.apiPath);
    return this.apiService.post(payloadObj, true, hostUrl)
  }
  postChangeDetection(payload) {
    let payloadObj = {
      subURL: `${this.connProps.changeDetection}`,
      datum: payload
    }
    let hostUrl = this.apiService.changeBaseUrl(env.apiPath)
    return this.apiService.post(payloadObj, true, hostUrl)
  }
  getRasterBands(id) {

    let subURL = this.apiService.changeBaseUrl(`${env.apiPath}` + `${this.connProps.rasterBands}${id}`)
    return this.apiService.getNew(subURL, true, true)
  }
  setShowTableJoinData(status) {
    this.tableJoinObserver.emit(status)
  }
  getShowTableJoinData() {
    return this.tableJoinObserver
  }

  getJoinDataRequest(): Observable<any> {
    return this.joinDataRequest.asObservable();
  }

  setJoinDataRequest(data) {
    this.joinDataRequest.next(data);
  }

  getActiveDataTabIndex(): Observable<number> {
    return this.activeDataTabIndex.asObservable();
  }

  setActiveDataTabIndex(val) {
    localStorage.setItem('tabLocation', val);
    this.activeDataTabIndex.next(val);
  }

  getActiveSessionsLayoutIndex(): Observable<number> {
    return this.activeSessionLayoutTabIndex.asObservable();
  }

  setActiveSessionsLayoutIndex(val) {
    localStorage.setItem('sessionLayoutTab', val);
    this.activeSessionLayoutTabIndex.next(val);
  }

  getSessionsListScrollToTop(): Observable<boolean> {
    return this.sessionsListScrollToTop.asObservable();
  }

  setSessionsListScrollToTop(val) {
    this.sessionsListScrollToTop.next(val);
  }

  getClearSelectionInQueryDialog(): Observable<boolean> {
    return this.clearSelectionInQueryDialog.asObservable();
  }

  setClearSelectionInQueryDialog(val) {
    this.clearSelectionInQueryDialog.next(val);
  }
  
  plotPannedLayer(layerRawData, element) {
    let cordinatesObj = layerRawData.find(({ _id }) => _id === element.id);
    let source = `AdvancedToolSearchResults`
    let feature = {
      "type": "FeatureCollection",
      "features": []
    }
    feature.features.push(cordinatesObj)
    console.log(cordinatesObj)
    if (this.map.getSource(source)) {
      this.map.getSource(source).setData(feature);
    } else {
      let type = cordinatesObj.geometry.type.toLowerCase();
      this.map.addSource(source, {
        type: 'geojson',
        data: feature,
        tolerance: 3
      })
      this.map.addLayer({
        id: sourceObject[type],
        type: ploatLayerType[type],
        source: source,
        paint: paintObject[type],
        layout: layoutObject[type]
      });
      if ((type == 'polygon') || (type == "multipolygon")) {
        this.map.addLayer({
          id: sourceObject['polyline'],
          type: 'line',
          source: source,
          paint: paintObject.line,
          layout: layoutObject[type]
        });
      }
    }
  }


  checkValidationOnAddingLayers(allLayers) {
    let layersCount: number = 0
    layersCount = [...this.processingLayersWithoutError, ...allLayers].length;
    if (layersCount >= 20) {
      return false;
    } else {
      return true;
    }
  }
  openAlertDialog(alertTitle, alertText) {
    this.dialog.open(InfoDialogComponent, {
      data: {
        alertText: alertText,
        alertTitle: alertTitle
      }
    })
  }
  getHeaderArray(csvHeaders: any) {
    let headers = (<string>csvHeaders).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }
  projectionInFile(file) {
    let reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = () => {
      let csvData: any = reader.result;
      // if (Object.keys(JSON.parse(csvData)).includes("crs")) {
        // this.projectionCheck.next(true)
      // } else {
        this.projectionCheck.next(false)
      // }
    }
  }
  
  showWarningToasterForOffset(value: number) {
    if (value > 100) {
      this.toaster.warning(`Maximun value allowed is ${MAXOFFSETVALUEALLOWED}`)
    } else {
      this.toaster.warning(`Minimum value allowed is ${MINOFFSETVALUEALLOWED}`)
    }
  }
  
  commonValidationToaster(property) {
    switch(property) {
      case 'textOffset':
        this.toaster.warning('Please enter valid text offset value')
        break;
      default:
        this.toaster.warning('Please enter  valid data')
    }
  }

  getClassificationMethodName(classificationMethod) {
    switch (classificationMethod) {
      case 1:
        return 'equal_interval'
      case 2:
        return 'quantile'
      case 3:
        return 'natural_breaks'
      case 4:
        return 'standard_deviation'
      case 5:
        return 'pretty_breaks'
      case 6:
        return 'manual'
    }
  }
}


