import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AddJoinedLayerToNewSessionStartAction, DataManagementActionTypes } from 'src/app/store/actions/data-management.actions';
import { SessionService } from 'src/app/_services/session.service';
import { JoinedLayerNameDialog } from '../joined-layer-name-dialog/joined-layer-name-dialog.component';
import { UseInMapSessionDialogComponent } from '../use-in-map-session-dialog/use-in-map-session-dialog.component';

@Component({
  selector: 'app-join-data-success-dialog',
  templateUrl: './join-data-success-dialog.component.html',
  styleUrls: ['./join-data-success-dialog.component.scss']
})
export class JoinDataSuccessDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JoinDataSuccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private _store: Store<any>,
    private _actions: Actions,
    private router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this._actions.pipe(
      ofType(DataManagementActionTypes.addJoinedLayerToNewSessionSuccess),
      map((response: any) => {
        if(response.response && response.response._id) {
          this.router.navigate(["/home", response.response._id]);
          this.close(false);
        }
      })
    ).subscribe();
  }

  useInMapSession() {
    this.close(false);
    const UseInMapSessiondialog=this.dialog.open(UseInMapSessionDialogComponent,{
      hasBackdrop: true,
      width: '900px',
      data: {'layer_id': this.data.layerId, 'type':'joinedLayer'}
    })
    UseInMapSessiondialog.afterClosed().subscribe(_ => { });
  }
  
  // createMapSession() {
  //   this._store.dispatch(new AddJoinedLayerToNewSessionStartAction({layer_id: this.data.layerId}));
  // }

  close(flag) {
    this.sessionService.setActiveDataTabIndex(0);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}