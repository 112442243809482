


import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "checkDate",
})

export class DateCustomPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        console.log(value)

            return value


    }

    
}