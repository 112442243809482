import { Component, OnInit } from '@angular/core';
import { Store, select } from "@ngrx/store";
import { map, debounceTime } from "rxjs/operators";
import { loaderSelector } from '../store/reducers/loader.reducer';
import { SessionService } from '../_services/session.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {



  ngOnInit() {
  }
  public loader$;
  constructor(private _store: Store<any>, private session: SessionService) {
    this.loader$ = this._store.pipe(select(loaderSelector), debounceTime(300), map(loading => loading > 0));
  }
}
