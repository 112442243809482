import { Action } from '@ngrx/store'


export enum dataapprovalmanagementTypes {
  getLayerTableDataStart = "[BUILDING_TABLE] Get Building Table Data Start",
  getLayerTableDataSuccess = "[BUILDING_TABLE] Get Building Table Data Success",
  getLayerPremiseTableDataStart = "[PREMISE_TABLE] Get Premise Table Data Start",
  getLayerPremiseTableDataSuccess = "[PREMISE_TABLE] Get Premise Table Data Success",
  getLayerPremiseTableDataFailed = '[PREMISE_TABLE] Get Premise Table Data Failed',
  exportDataStart = "[BUILDING_TABLE] Export Building Data Start",
  exportDataSuccess = "[BUILDING_TABLE] Export Building Data Success",
  exportPremiseDataStart = "[PREMISE_TABLE] Export Premise Data Start",
  exportPremiseDataSuccess = "[PREMISE_TABLE] Export Premise Data Success",
  getBuildingDataStart = "[BUILDINGS DATA] Get Building Data On Id Start",
  getBuildingDataSuccess = "[BUILDING DATA] Get Building Data On Id Success",
  updateBuildingData = "[UPDATE BUILDING DATA] Update building Data Start",
  updateBuildingDataSuccess = "[UPDATE BUILDING DATA] Update building data success",
  updatePremiseData = "[UPDATE PREMISE DATA] Update premise data start",
  updatePremiseDataSuccess = "[UPDATE PREMISE DATA] Update premise data success",
  getSelectedFeatureDetailsStart = "[DATA_MANAGEMENT] Get Selected Feature Details Start",
  getSelectedFeatureDetailsSuccess = "[DATA_MANAGEMENT] Get Selected Feature Details Success",
  getPremiseDataStartAction = "[DATA_MANAGEMENT] Get Premise Data Start Action",
  getPremiseDataSuccessAction ="[DATA_MANAGEMENT] Get Premise Data Success Action"
}

export class GetLayerTableDataStartAction implements Action {
  readonly type = dataapprovalmanagementTypes.getLayerTableDataStart;
  constructor(public params: any) { }
}

export class GetLayerTableDataSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.getLayerTableDataSuccess;
  data;
  constructor(result: any) {
    this.data = result;
  }
}


export class GetLayerPremiseTableDataStartAction implements Action {
  readonly type = dataapprovalmanagementTypes.getLayerPremiseTableDataStart;
  constructor(public params: any, public payload: any) { }
}

//   export class GetLayerPremiseTableDataFailed implements Action {
//     readonly type = dataapprovalmanagementTypes.getLayerPremiseTableDataFailed;
//     constructor(public payload: any) {
//         this.payload = payload;
//     }
// }
export class GetLayerPremiseTableDataSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.getLayerPremiseTableDataSuccess;
  data;
  constructor(result: any) {
    this.data = result;
  }
}

export class GetLayerPremiseTableDataFailed implements Action {
  readonly type = dataapprovalmanagementTypes.getLayerPremiseTableDataFailed;
  constructor(public payload: any) {
    this.payload = payload;
  }
}

export class ExportBuildingDataStartAction implements Action {
  readonly type = dataapprovalmanagementTypes.exportDataStart;
  constructor(public payload: any) { }
}

export class ExportBuildingDataSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.exportDataSuccess;
  data;
  constructor(public result: any) {
    this.data = result;
  }

}


export class ExportPremisesDataStartAction implements Action {
  readonly type = dataapprovalmanagementTypes.exportPremiseDataStart;
  constructor( public params: any, public payload: any) { }
}


export class ExportPremisesDataSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.exportPremiseDataSuccess;
  data;
  constructor(public result: any) {
    this.data = result;
  }

}

export class GetBuildingsDataStartAction implements Action {
  readonly type = dataapprovalmanagementTypes.getBuildingDataStart
  constructor(public layer_entry_id: string) { }
}

export class GetBuildingDataSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.getBuildingDataSuccess;
  data;
  constructor(public result: any){
    this.data = result
  }
}

export class UpdateBuildingDataAction implements Action{
  readonly type = dataapprovalmanagementTypes.updateBuildingData;
  payload;  
  constructor(public building_id: string, data) {
    this.building_id = building_id,
    this.payload = data;
  }
}

export class UpdateBuildingDataSuccessAction implements Action{
  readonly type = dataapprovalmanagementTypes.updateBuildingDataSuccess;
  constructor(public response){ }
}

export class UpdatePremiseDataAction implements Action{
  readonly type = dataapprovalmanagementTypes.updatePremiseData;
  payload;
  constructor(public premise_id:string, data, public buildingsId: any){
    this.premise_id = premise_id,
    this.payload = data;
    this.buildingsId = buildingsId
  }
}

export class UpdatePremiseDataSuccessAction implements Action{
  readonly type = dataapprovalmanagementTypes.updatePremiseDataSuccess;
  constructor(public buildingsId: any, public edited_entry){
    this.buildingsId = buildingsId
  }
}

export class GetSelectedFeatureDetailsStartAction implements Action {
  readonly type = dataapprovalmanagementTypes.getSelectedFeatureDetailsStart;
  constructor(public payload: any) { }
}

export class GetSelectedFeatureDetailsSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.getSelectedFeatureDetailsSuccess;
  constructor(public response: any) { }
}
export class GetPremiseDataStartAction implements Action {
  readonly type =dataapprovalmanagementTypes.getPremiseDataStartAction;
  constructor(public payload: any, public arrInd: number) {}
}
export class GetPremiseDataSuccessAction implements Action {
  readonly type = dataapprovalmanagementTypes.getPremiseDataSuccessAction;
  constructor (public response: any) {}
}

export type DataapprovalmanagementActions = GetLayerTableDataSuccessAction
  | GetLayerPremiseTableDataSuccessAction
  | ExportBuildingDataSuccessAction
  | ExportPremisesDataSuccessAction
  | GetLayerPremiseTableDataFailed
  | GetBuildingsDataStartAction
  | GetBuildingDataSuccessAction
  | UpdateBuildingDataAction
  | UpdateBuildingDataSuccessAction
  | UpdatePremiseDataAction
  | UpdatePremiseDataSuccessAction
  | GetSelectedFeatureDetailsSuccessAction
  | GetPremiseDataSuccessAction

