import { Action} from '@ngrx/store';
import { PropertyTaxDataActions,propertyTaxTypes, getPropertyTaxDataSuccessAction } from '../actions/city-os-property-tax.actions';
import { isArray } from 'util';
import { Details,Charts} from '../../models/city-os-main-class';
export const cityOsPropertyTaxFeatureKey = 'cityOsPropertyTax';

export interface State {
  propertyTax: PropertyTaxData;
  watersupply:PropertyTaxData;
  slum:SlumData;
  slumDemographics:SlumDemographicsData;
  ratioChart:RatioChartData;
  slumSanitation : SlumSanitationData;
}
 export interface PropertyTaxData{
  _id : string;
  name: string;
  details?: Details[];
  chart?:Charts[];
 }
 
export interface SlumData{
 _id: string;
 name:string;
 details:Details[];
}

export interface SlumDemographicsData{
 1:{}
}
 export interface Value{
  id:number;
  value: string;
  male:number;
  female:number;
  total:number;
 }
export interface RatioChartData{
   label:string;
   value: Value[];
}

export interface SlumSanitationData{
  value: SlumValue[];
}
export interface SlumValue {
  FunctionalCommunityToilets: number;
  MaleOnlyToilets:number;
  FemaleOnlyToilets:number;
  HHWithIndividualToilets:number;
}
export const initialState: State = {
  propertyTax: null,
  watersupply:null,
  slum:null,
  slumDemographics: null,
  ratioChart:null,
  slumSanitation:null,
};
 
export function cityOsPropertyTaxReducer( state = initialState, action: PropertyTaxDataActions): State {
  
  switch (action.type) {
    case propertyTaxTypes.getPropertyTaxDataSuccess:
      return {...state, propertyTax: action.data};  
    case propertyTaxTypes.getWaterSupplyDataSuccess:
      return{...state , watersupply: action.data};
    case propertyTaxTypes.getSlumDataSuccess:
      return{...state , slum : action.data};
    case propertyTaxTypes.getSlumDemographicsDataSuccess:
      return{...state , slumDemographics : action.data} 
    case propertyTaxTypes.getRatioChartDataSuccess:
      return{...state,ratioChart:action.data}  
     case  propertyTaxTypes.getSlumSanitationDataSuccess:
       return{...state,slumSanitation:action.data }

  }
  return state;

 
}
