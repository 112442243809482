export class Pagination {
    limit: number;
    page: number;
    search?: string;
    filter?: string;
    sort?: string;

    constructor(
        limit: number = 20,
        page: number = 1,
        search?: string,
        filter?: string,
        sort?: string) {
        this.limit = limit;
        this.page = page;

        if (this.search) {
            this.search = search;
        }

        if (this.filter) {
            this.filter = filter;
        }

        if (this.sort) {
            this.sort = sort;
        }
    }

    // buildQueryString() {
    //     let subUrl: string = "";

    //     subUrl += this.params.join("/") + "?";
    // }
}
