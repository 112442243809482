import { UserManageMentActions, userManagementActionTypes } from '../actions/user-management.actions';

export interface User {
  username: string,
  email: string,
  user_role: string,
}

export interface State {
  count: number;
  data: Array<User>;
  page: number;
  selectedUser: User;
  roles: Array<any>,
  pages: any;
  addUpdateUser: any,
  surveyorsList: any
}

export const initialState: State = {
  count: 0,
  data: [],
  page: 1,
  selectedUser: null,
  roles: [],
  pages: 1,
  addUpdateUser : {},
  surveyorsList: []
};

export function usersReducer(state = initialState, action: UserManageMentActions): State {
  switch (action.type) {
    case userManagementActionTypes.getUsersSuccess:
      return { ...state, data: action.data.data, page: action.data.page, count: action.data.count , pages : action.data.pages};
    case userManagementActionTypes.getUserInfoSuccess:
      return { ...state, selectedUser: action.data };
    case userManagementActionTypes.getRolesSuccess:
      return { ...state, roles: action.data };
     case userManagementActionTypes.addUpdateUserSuccess:
        return {... state, addUpdateUser: action.response}
    case  userManagementActionTypes.getSurveyorsListSuccess:
      
        return{...state,surveyorsList : action.response }
        default:
          return state
  }
}
