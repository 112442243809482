import { Action } from '@ngrx/store';
import { PropertyTax } from '../../models/property-tax';
import { Pagination } from "../../models/pagination";
import { GetParam } from "../../models/user-ward-assignments";
import { User, Ward, UserWardAssignmentsBody } from '../../models/user-ward-assignments';
import { HttpErrorResponse } from '@angular/common/http';
import { Reset } from '@ngrx/store-devtools/src/actions';

export enum PropertyTaxActionTypes {
    GET_PROPERTY_TAX_DATA = '[PROPERTY-TAX] Get Property Tax Data',
    GET_PROPERTY_TAX_DATA_SUCCESS = '[PROPERTY-TAX] Get Property Tax Data Success',
    GET_PROPERTY_TAX_DATA_FAILED = '[PROPERTY-TAX] Get Property Tax Data Failed',
    ADD_EDIT_PROPERTY_TAX_DATA = '[PROPERTY-TAX] Initialise Property Tax Data',
    ADD_EDIT_PROPERTY_TAX_DATA_SUCCESS = '[PROPERTY-TAX] Initialise Property Tax Data Success',
    ADD_EDIT_PROPERTY_TAX_DATA_FAILED = '[PROPERTY-TAX] Initialise Property Tax Data Failed',
    EDIT_PROPERTY_TAX_DATA = '[PROPERTY-TAX] Edit Property Tax Data',
    EDIT_PROPERTY_TAX_DATA_SUCCESS = '[PROPERTY-TAX] Edit Property Tax Data Success',
    EDIT_PROPERTY_TAX_DATA_FAILED = '[PROPERTY-TAX] Edit Property Tax Data Failed',
    LIST_USERS = "[SURVEY-MANAGEMENT] List Users",
    LIST_USERS_SUCCESS = "[SURVEY-MANAGEMENT] List Users Success",
    LIST_WARDS = "[SURVEY-MANAGEMENT] List Wards",
    LIST_WARDS_SUCCESS = "[SURVEY-MANAGEMENT] List Wards Success",
    USER_WARD_ASSIGN_REVOKE = "[SURVEY-MANAGEMENT] Assign Or Revoke Users To Wards",
    USER_WARD_ASSIGN_REVOKE_SUCCESS = "[SURVEY-MANAGEMENT] Assign Or Revoke Users To Wards Success",
    USER_WARD_ASSIGN_AND_REVOKE = "[SURVEY-MANAGEMENT] Assign And Revoke Users To Wards",
    USER_WARD_ASSIGN_AND_REVOKE_SUCCESS = "[SURVEY-MANAGEMENT] Assign And Revoke Users To Wards Success",
    FAILED = "Failed",
    RESET_WARDS_AND_USERS = "[SURVEY-MANAGEMENT] Reset Wards And Users",
    CHECK_ACCESS_PROPERTY_TAX = '[PROPERTY-TAX] Check Access Property Tax',
    CHECK_ACCESS_PROPERTY_TAX_SUCCESS = '[PROPERTY-TAX] Check Access Property Tax Success',
    GET_PREMISE_FLOOR_COLLECTED_DATA_START = '[PROPERTY-TAX] Get Premise Floor Data Start',
    GET_PREMISE_FLOOR_COLLECTED_DATA_SUCCESS = '[PROPERTY-TAX] Get Premise Floor Data Success',
    GET_SURVEYOR_TIER_VIEW_LIST_START_ACTION = '[PROPERTY-TAX] Get Property Tax Start Action',
    GET_SURVEYOR_TIER_VIEW_LIST_SUCCESS_ACTION ='[PROPERTY-TAX] Get Property Tax Succes Action',
    EDIT_GEOMETRY_START_ACTION ='[DATA-MANAGEMENT] Edit Geometry Start Action',
    EDIT_GEOMETRY_SUCCESS_ACTION='[DATA-MANAGEMENT] Edit Geometry Success Action',
    GET_ADMIN_PANEL_DATA_START_ACTION ='[DATA-MANAGEMENT] Get Admin Panel Data Start Action',
    GET_ADMIN_PANEL_DATA_SUCCESS_ACTION ='[DATA_MANAGEMENT] Get Admin Panel Data Success Action',
    APPROVE_GEOMETRY_START_ACTION ='[DATA_MANAGEMENT] Approve Data Geometry Start Action',
    APPROVE_GEOMETRY_SUCCESS_ACTION ='[DATA_MANAGEMENT] Approve DataGeometrySuccess Action',
    GET_FILTERED_DATA_START_ACTION ='[DATA-MANAGEMENT] Get FilteredData Start Action',
    GET_FILTERED_DATA_SUCCES_ACTION ='[DATA-MANAGEMENT] Get FilteredData Success Action',
    GET_BUILDING_TABLE__SORT_START_ACTION ="[DATA_MANAGEMENT] Get Building Table Sort Start Action",
    GET_PREMISE_TABLE_SORT_START_ACTION ="[DATA_MANAGEMENT] Get Premise Table Sort Start Action",
    GET_ADMIN_TABLE_SORT_START_ACTION ="[DATA_MANAGEMENT] Get Admin Table Sort Start Action",
    GET_CITY_OS_ATTRIBUTE_START_ACTION ="[DATA_MANAGEMENT] Get Cityos Attribute Start Action",
    GET_CITY_OS_ATTRIBUTE_SUCCESS_ACTION ="[DATA_MANAGEMENT] Get CityOs Attribute Success Action",
    GET_ADMIN_PANEL_PREMISE_DATA_START_ACTION ="[DATA-MANAGEMENT] Get Admin panel premise data start action",
    GET_ADMIN_PANEL_PREMISE_DATA_SUCCESS_ACTION ="[DATA-MANAGEMENT] Get Admin panel premise data success action",
    GET_PROPERTY_TAX_FORMS_START_ACTION ="[DATA-MANAGEMENT] Get Property Tax Forms Start Action",
    GET_PROPERTY_TAX_FORMS_SUCCESS_ACTION ="[DATA-MANAGEMENT] Get Property Tax Forms Success Action",
    GET_PROPERTY_TAX_ENTRY_LOGS_START_ACTION = "[DATA-MANAGEMANT] Get Property Tax Entry Logs Start Action",
    GET_PROPERTY_TAX_ENTRY_LOGS_SUCCESS_ACTION ="[DATA_MANAGEMENT] Get Property Tax Entry Logs Success Action"
}

export class CheckPropertyTaxAccess implements Action {
    readonly type = PropertyTaxActionTypes.CHECK_ACCESS_PROPERTY_TAX;
    constructor() {
    }
}

export class CheckPropertyTaxAccessSuccess implements Action {
    readonly type = PropertyTaxActionTypes.CHECK_ACCESS_PROPERTY_TAX_SUCCESS;
    constructor(public payload: boolean) {
    }
}

export class GetPropertyTaxData implements Action {
    readonly type = PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA;
    constructor(public payload?: { nonBlocking: boolean }) {
    }
}

export class GetPropertyTaxDataSuccess implements Action {
    readonly type = PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA_SUCCESS;
    constructor(public payload: PropertyTax) {
    }
}

export class GetPropertyTaxDataFailed implements Action {
    readonly type = PropertyTaxActionTypes.GET_PROPERTY_TAX_DATA_FAILED;
    constructor(public payload: any) {
        this.payload = payload;
    }
}

export class AddEditPropertyTaxData implements Action {
    readonly type = PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA;
    constructor(public payload: PropertyTax) { }
}

export class AddEditPropertyTaxDataSuccess implements Action {
    readonly type = PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA_SUCCESS;
    constructor(public payload: PropertyTax) {
        this.payload = payload;
    }
}

export class AddEditPropertyTaxDataFailed implements Action {
    readonly type = PropertyTaxActionTypes.ADD_EDIT_PROPERTY_TAX_DATA_FAILED;
    constructor(public payload: any) {
        this.payload = payload.error.error;
    }
}

export class EditPropertyTaxData implements Action {
    readonly type = PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA;
    constructor(public payload: PropertyTax) { }
}

export class EditPropertyTaxDataSuccess implements Action {
    readonly type = PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA_SUCCESS;
    constructor(public payload: PropertyTax) {
        // this.payload = payload;
    }
}

export class EditPropertyTaxDataFailed implements Action {
    readonly type = PropertyTaxActionTypes.EDIT_PROPERTY_TAX_DATA_FAILED;
    constructor(public payload: any) {
        this.payload = payload.error.error;
    }
}

export class ListUsers implements Action {
    readonly type = PropertyTaxActionTypes.LIST_USERS;
    constructor(public payload: any) {
    }
}

export class ListUsersSuccess implements Action {
    readonly type = PropertyTaxActionTypes.LIST_USERS_SUCCESS;
    constructor(public payload: User[],public total:number) { }
}

export class ListWards implements Action {
    readonly type = PropertyTaxActionTypes.LIST_WARDS;
    constructor(public payload: GetParam, public userData ?) {
    }
}

export class ListWardsSuccess implements Action {
    readonly type = PropertyTaxActionTypes.LIST_WARDS_SUCCESS;
    constructor(public payload: { wards: Ward[], count: number, userData }) { }
}

export class Failed implements Action {
    readonly type = PropertyTaxActionTypes.FAILED;
    constructor(public payload: HttpErrorResponse) { }
}

export class UserWardAssignRevoke implements Action {
    readonly type = PropertyTaxActionTypes.USER_WARD_ASSIGN_REVOKE;
    constructor(public payload: UserWardAssignmentsBody) { }
}

export class UserWardAssignRevokeSuccess implements Action {
    readonly type = PropertyTaxActionTypes.USER_WARD_ASSIGN_REVOKE_SUCCESS;
    constructor(public payload: any) {
    }
}

export class UserWardAssignAndRevoke implements Action {
    readonly type = PropertyTaxActionTypes.USER_WARD_ASSIGN_AND_REVOKE;
    constructor(public payload: { assign: UserWardAssignmentsBody, revoke: UserWardAssignmentsBody }) { }
}

export class UserWardAssignAndRevokeSuccess implements Action {
    readonly type = PropertyTaxActionTypes.USER_WARD_ASSIGN_AND_REVOKE_SUCCESS;
    constructor(public payload: any) {
    }
}

export class ResetWardsAndUsers implements Action {
    readonly type = PropertyTaxActionTypes.RESET_WARDS_AND_USERS;
    constructor() {
    }
}

export class GetPremiseFloorCollectedDataStartAction implements Action {
  readonly type = PropertyTaxActionTypes.GET_PREMISE_FLOOR_COLLECTED_DATA_START;
  constructor(public layerId: string) { }
}

export class GetPremiseFloorCollectedDataSuccessAction implements Action {
  readonly type = PropertyTaxActionTypes.GET_PREMISE_FLOOR_COLLECTED_DATA_SUCCESS;
  data;
  constructor(result: any) { this.data = result }
}
export class SurveyorTierListViewStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_SURVEYOR_TIER_VIEW_LIST_START_ACTION;
    constructor(public tierNumber ,public payload: any) {}
}
export class SurveyorTierViewListSuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_SURVEYOR_TIER_VIEW_LIST_SUCCESS_ACTION;
    constructor(public resp: any) {}
}
export class EditGeometryStartAction implements Action { // action for send editted geometry
    readonly type = PropertyTaxActionTypes.EDIT_GEOMETRY_START_ACTION;
    constructor(public id: any, public payload: any ) {}
}
export class EditGeometrySuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.EDIT_GEOMETRY_SUCCESS_ACTION;
    constructor(public response: any) {}
}

export class  AdminPanelDataStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_ADMIN_PANEL_DATA_START_ACTION;
    constructor(public payload :any) {}
}
export class AdminPanelDataSuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_ADMIN_PANEL_DATA_SUCCESS_ACTION;
    constructor(public response: any) {}
}
export class ApproveGeometryStartAction implements Action {
    readonly type = PropertyTaxActionTypes.APPROVE_GEOMETRY_START_ACTION;
    constructor(public payload: any) { }
}
export class  ApproveGeometrySuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.APPROVE_GEOMETRY_SUCCESS_ACTION;
    constructor(public response: any) {}
}
export class GetFilteredDataStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_FILTERED_DATA_START_ACTION;
    constructor(public tierNumber: any, public payload: any) {}
}

export class GetFilteredDataSuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_FILTERED_DATA_SUCCES_ACTION;
    constructor(public response: any) {}
}
export class GetBuildingSortStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_BUILDING_TABLE__SORT_START_ACTION;
    constructor (public payload: any) {}
}
export class GetPremiseTableSortStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_PREMISE_TABLE_SORT_START_ACTION;
    constructor(public params : any, public payload: any) {}
}
export class GetAdminTableSortStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_ADMIN_TABLE_SORT_START_ACTION;
    constructor (public payload: any) {}
}
export class GetCityosLayerAttributesStartAction implements Action {
    
    readonly type =PropertyTaxActionTypes.GET_CITY_OS_ATTRIBUTE_START_ACTION;
    constructor (public layerId: any, public index : number) {}
}
export class GetCityOsAttributesSuccessAction implements Action {

    readonly type = PropertyTaxActionTypes.GET_CITY_OS_ATTRIBUTE_SUCCESS_ACTION;

    constructor(public response: any) {}
}
export class GetAdminPremiseDataStarAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_ADMIN_PANEL_PREMISE_DATA_START_ACTION;
    constructor(public params, public payload) {}
}
export class GetAdminPremiseDataSuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_ADMIN_PANEL_PREMISE_DATA_SUCCESS_ACTION;
    constructor (public response: any) {}
}
export class GetPropertyTaxFormsStartAction implements Action {
    readonly type =PropertyTaxActionTypes.GET_PROPERTY_TAX_FORMS_START_ACTION;
    constructor() {}
}
export class GetPropertyTaxFormsSuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_PROPERTY_TAX_FORMS_SUCCESS_ACTION;
    constructor(public response) {}
}
export class GetPropertyTaxEntryLogsStartAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_PROPERTY_TAX_ENTRY_LOGS_START_ACTION;
    constructor(public id) {}
}
export class GetPropertyTaxEntryLogsSuccessAction implements Action {
    readonly type = PropertyTaxActionTypes.GET_PROPERTY_TAX_ENTRY_LOGS_SUCCESS_ACTION;
    constructor (public response) {}
}


export type PropertyTaxActions = GetPropertyTaxData | GetPropertyTaxDataSuccess |
    GetPropertyTaxDataFailed | AddEditPropertyTaxData | AddEditPropertyTaxDataSuccess |
    AddEditPropertyTaxDataFailed | EditPropertyTaxData | EditPropertyTaxDataSuccess |
    EditPropertyTaxDataFailed |
    ListUsers | ListUsersSuccess |
    ListWards | ListWardsSuccess |
    UserWardAssignRevoke | UserWardAssignRevokeSuccess |
    UserWardAssignAndRevoke | UserWardAssignAndRevokeSuccess |
    ResetWardsAndUsers | GetPremiseFloorCollectedDataStartAction | GetPremiseFloorCollectedDataSuccessAction |
    CheckPropertyTaxAccess | CheckPropertyTaxAccessSuccess |
    Failed | SurveyorTierListViewStartAction | EditGeometrySuccessAction | AdminPanelDataSuccessAction | ApproveGeometrySuccessAction |
    GetFilteredDataSuccessAction | GetCityOsAttributesSuccessAction | GetAdminPremiseDataSuccessAction | GetPropertyTaxFormsSuccessAction | GetPropertyTaxEntryLogsSuccessAction
