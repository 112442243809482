import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription, combineLatest, Observable } from 'rxjs';
import { SessionService } from 'src/app/_services/session.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ExportSearchResultStartAction, SessionManagementTypes, ExportDataStartAction, ExportQuerySearchDataStartAction, ExportGraphicStartAction } from 'src/app/store/actions/session-management.actions';
import { ofType, Actions } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ADVANCED_TOOL_TYPE } from 'src/app/app.constants';
import { InputParams } from '../advanced-tools-dialog/input-params.model';
import { ExportBuildingDataStartAction, ExportPremisesDataStartAction } from 'src/app/store/actions/data-approval-management.actions';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';

@Component({
  selector: "app-data-export",
  templateUrl: "./data-export-dialog.component.html",
  styleUrls: ["./data-export-dialog.component.scss"]
})

export class DataExportDialogComponent {
  subscriptionArr: Array<Subscription> = [];
  selectedLayer1Id: string = null;
  selectedLayer2Id: string = null;
  selectedLayer1Type: string = null;
  selectedLayer2Type: string = null;
  selectedAdvancedToolType: number = null;
  selectedAdvancedToolChoiceType: string = null;
  sessionId: string = null;
  exportOptions: any;
  selectedIndex = null;
  selectedGeometryType: string = null;
  advancedToolsDrawActive = false;
  selectedCoordinates = "";
  advancedToolsSearchPayload: any = null;
  throwNote: boolean = false
  inputParams: InputParams = {
    difference: null,
    radius: null,
    steps: null,
    distance: null,
    units: null,
    tolerance: null,
    maxEdge: null,
    concavity: null,
    propertyName: null,
    highQuality: null,
    mutate: null,
    cellSize: null,
    gridType: null,
    property: null,
    weight: null,
    breaks: null,
    cellSide: null,
    extent: null
  };
  cityOsExportOptions: any[] = [
    // { text: 'Export All', value: 'all' },
    { text: 'Export Selected Data', value: 'export-selected-data' },
    { text: 'Export Filtered Data', value: 'export-filtered-data' }
  ];
  exportData: any = {}
  constructor(
    private dialogRef: MatDialogRef<DataExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService,
    private router: Router,
    private _store: Store<any>,
    private actions$: Actions,
    public DAMService: DataApprovalManagementService
  ) {
    // this.exportData = { 'value': 'all' }
  }

  ngOnInit() {
    this.selectedIndex = 0;
    this.sessionId = this.router.url.split("/").splice(-1)[0];
    this.subscriptionArr.push(
      this.sessionService.getFirstSelectedAdvancedToolLayer().subscribe((layer: any) => {
        this.selectedLayer1Id = layer ? layer.parent_layer_id : null;
        this.selectedLayer1Type = layer ? layer.type : null;
      })
    );
    this.subscriptionArr.push(
      this.sessionService.getSecondSelectedAdvancedToolLayer().subscribe((layer: any) => {
        this.selectedLayer2Id = layer ? layer.parent_layer_id : null;
        this.selectedLayer2Type = layer ? layer.type : null;
      })
    );
    this.subscriptionArr.push(
      this.sessionService.getSelectedAdvancedToolType().subscribe(type => this.selectedAdvancedToolType = type)
    );
    this.subscriptionArr.push(
      this.sessionService.getSelectedAdvancedToolChoiceType().subscribe(type => this.selectedAdvancedToolChoiceType = type)
    );
    this.subscriptionArr.push(
      this.sessionService.getSelectedAdvancedToolGeometryType().subscribe(val => this.selectedGeometryType = val)
    );
    this.subscriptionArr.push(
      this.sessionService.getAdvancedToolsSearchPayload().subscribe(value => this.advancedToolsSearchPayload = value)
    );
    this.subscriptionArr.push(
      this.actions$.pipe(
        ofType(SessionManagementTypes.exportSearchResultSuccess),
        map((response: any) => {
          this.dialogRef.close(false);
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this.actions$.pipe(
        ofType(SessionManagementTypes.exportDataSuccess),
        map((response: any) => {
          this.dialogRef.close(false);
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this.actions$.pipe(
        ofType(SessionManagementTypes.exportQuerySearchDataSuccess),
        map((response: any) => {
          this.dialogRef.close(false);
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this.sessionService.getAdditionalInputs().subscribe(params => this.inputParams = params)
    );
    this.subscriptionArr.push(
      this.actions$.pipe(
        ofType(SessionManagementTypes.exportGraphicSuccessAction),
        map((resp: any) => {
          this.dialogRef.close(false);
        })
      ).subscribe()
    )
    this.exportOptions = EXPORT_OPTIONS[this.data.dataType];
    let isAdvancedToolsSelected: Observable<any> = this.sessionService.getAdvancedToolsActive();
    let selectedAdvancedCoordinates: Observable<any> = this.sessionService.getAdvancedToolsDrawnCoordinates();
    combineLatest([isAdvancedToolsSelected, selectedAdvancedCoordinates]).subscribe(([graphicsActive, coordinates]) => {
      if (graphicsActive) {
        this.selectedCoordinates = coordinates;
      } else {
        this.selectedCoordinates = this.sessionService.selectedCoordinates;
      }
    })
    if (this.data.dataType === 'city_os') {
      if (this.data.selectedTab === 1) {
        // this.cityOsExportOptions.push({ 'text': 'Export Selected Building Premises', value: 'export-selected-building-premises' })
        // this.cityOsExportOptions[0].text = 'Export All Premise Data';
        // this.cityOsExportOptions[1].text = 'Export Selected Premise Data'
        // this.cityOsExportOptions.splice(2, 1, { text: 'Export Filtered Building Premises', value: 'export-filtered-data' })
        this.cityOsExportOptions = [
          {
            text: 'Export Selected Premise Data',
            value: "export-selected-data"
          },
          {
            text: 'Export Filtered Building Premises',
            value: "export-filtered-data"
          },
          {
            text: 'Export Selected Building Premises',
            value: 'export-selected-building-premises'
          },

        ]
      }
    }
  }

  setExportType(ind) {
    this.selectedIndex = ind;
  }

  onSubmit() {
    let data: any = {};
    const type = this.exportOptions.types[this.selectedIndex];
    if (this.data.dataType === "query_search") {
      this.sessionService.setExportedFileNameObserver(`${this.sessionService.activeLayerName}.${type}`, `${type}`);
      const payload = {
        query: { q: this.sessionService.query },
        layer_id: this.sessionService.activeLayer,
        export_type: type,
      }
      this._store.dispatch(new ExportQuerySearchDataStartAction(payload));
    } else if (this.data.dataType === 'graphic_selection') {
      this.sessionService.setExportedFileNameObserver(`${this.sessionService.activeLayerName}.${type}`, `${type}`);
      const payload = {
        "query": {
          "layer": this.sessionService.activeLayer.parent_layer_id,
          "coordinates": typeof (this.sessionService.selectedCoordinates) === 'string' ? this.sessionService.selectedCoordinates : JSON.stringify(this.sessionService.selectedCoordinates),
          "type": this.sessionService.selectedGeometryType,
        },
        "layer_id": this.sessionService.activeLayer.parent_layer_id,
        export_type: type

      }
      this._store.dispatch(new ExportGraphicStartAction(payload))

    } else if (this.data.dataType === 'table_join') {
      delete this.data['dataType'];
      let name = this.sessionService.sessionLayers.find(({ parent_layer_id }) => parent_layer_id === this.data.input_layer_1.layer_id).name;
      this.sessionService.setExportedFileNameObserver(`${name}.${type}`, `${type}`)
      this._store.dispatch(new ExportSearchResultStartAction({ ...this.data, export_type: type }))
    } else if (this.data.dataType === "advanced_search") {
      const name = `${ADVANCED_TOOL_TYPE[this.selectedAdvancedToolType]}`;
      this.sessionService.setExportedFileNameObserver(`${name}.${type}`, `${type}`);
      let feature_type = null;
      switch (this.selectedAdvancedToolType) {
        case 27:
        case 28:
        case 29:
        case 30:
          feature_type = 'grid';
          break;
        case 34:
          feature_type = 'regression';
          break;
        case 36:
          feature_type = 'nearest-hub';
          break;
        case 37:
          feature_type = 'spatial-join';
          break;
        default:
          feature_type = ADVANCED_TOOL_TYPE[this.selectedAdvancedToolType];
      }
      if (this.selectedAdvancedToolType === 33) {
        data = {
          ids: this.advancedToolsSearchPayload.ids,
          input_params: {},
          selection_type: null,
          query: null,
          session_id: this.sessionId,
          feature_type,
          export_type: type
        }
      } else if (this.selectedAdvancedToolType === 34) {
        data = {
          input_layer_1: this.advancedToolsSearchPayload.input_layer_1,
          input_layer_2: { layer_id: null, type: null },
          input_params: this.advancedToolsSearchPayload.input_params,
          selection_type: null,
          query: null,
          session_id: this.sessionId,
          feature_type,
          export_type: type
        }
      } else if (this.selectedAdvancedToolType === 36) {
        data = {
          input_layer_1: this.advancedToolsSearchPayload.input_layer_1,
          input_layer_2: this.advancedToolsSearchPayload.input_layer_2,
          input_params: this.advancedToolsSearchPayload.input_params,
          selection_type: null,
          query: null,
          session_id: this.sessionId,
          feature_type,
          export_type: type
        }
      } else if (this.selectedAdvancedToolType === 37) {
        data = {
          input_layer_1: this.advancedToolsSearchPayload.input_layer_1,
          input_layer_2: this.advancedToolsSearchPayload.input_layer_2,
          input_params: this.advancedToolsSearchPayload.input_params,
          selection_type: 'all',
          query: null,
          session_id: this.sessionId,
          feature_type,
          export_type: type
        }
      }else if(this.selectedAdvancedToolType === 38) {
        let similarSearchData;
          this.sessionService.getSimilarSearchData().subscribe(data => similarSearchData = data);
          console.log("SimilarSearchData",similarSearchData);
          
          data = {
            input_layer_1: similarSearchData[0].input_layer_1,
            input_layer_2: similarSearchData[1].input_layer_1,
            input_params: similarSearchData[0].input_params,
            selection_type_input : similarSearchData[0].selection_type,
            selection_type_target : similarSearchData[1].selection_type,
            feature_type:"similarity-search",
            query_input:similarSearchData[0].query,
            query_target:similarSearchData[0].query,
            session_id: this.sessionId,
            export_type: type,
          }
      }else if(this.selectedAdvancedToolType === 39) {
        data = {
          input_layer_1: this.advancedToolsSearchPayload.input_layer_1,
          input_layer_2: this.advancedToolsSearchPayload.input_layer_2,
          input_params: this.advancedToolsSearchPayload.input_params,
          selection_type: this.advancedToolsSearchPayload.selection_type,
          query: this.advancedToolsSearchPayload.query,
          session_id: this.sessionId,
          feature_type,
          export_type: type
        }
      }
      else {
switch (this.selectedAdvancedToolChoiceType) {
          case "query":
            let query: any = {
              q: this.sessionService.query
            }
            if ([6, 7, 8].includes(this.selectedAdvancedToolType)) query.layer_id = this.sessionService.activeLayer;
            data = {
              input_layer_1: {
                layer_id: this.selectedLayer1Id,
                type: this.selectedLayer1Type
              },
              input_layer_2: {
                layer_id: this.selectedLayer2Id,
                type: this.selectedLayer2Type
              },
              input_params: { ...this.inputParams },
              selection_type: "query",
              query,
              session_id: this.sessionId,
              feature_type,
              export_type: type
            }
            break;
          case "graphics":
            switch (this.selectedAdvancedToolType) {
              case 23:
                const coords = typeof (this.selectedCoordinates) === 'string' ? JSON.parse(this.selectedCoordinates) : this.selectedCoordinates;
                query = {
                  point1: JSON.stringify(coords[0]),
                  point2: JSON.stringify(coords[1]),
                  type: this.selectedGeometryType
                }
                break;
              default:
                query = {
                  coordinates: typeof (this.selectedCoordinates) === 'string' ? this.selectedCoordinates : JSON.stringify(this.selectedCoordinates),
                  type: this.selectedGeometryType
                }
            }
            data = {
              input_layer_1: {
                layer_id: this.selectedLayer1Id,
                type: this.selectedLayer1Type
              },
              input_layer_2: {
                layer_id: this.selectedLayer2Id,
                type: this.selectedLayer2Type
              },
              input_params: { ...this.inputParams },
              selection_type: "graphics",
              query,
              session_id: this.sessionId,
              feature_type,
              export_type: type
            }
            break;
          case "all":
            data = {
              input_layer_1: {
                layer_id: this.selectedLayer1Id,
                type: this.selectedLayer1Type
              },
              input_layer_2: {
                layer_id: this.selectedLayer2Id,
                type: this.selectedLayer2Type
              },
              input_params: { ...this.inputParams },
              selection_type: "all",
              query:null,
              session_id: this.sessionId,
              feature_type,
              export_type: type
            }
            break;
        }
      }
      this._store.dispatch(new ExportSearchResultStartAction(data));

    } else if (this.data.dataType === 'city_os') {
      let buildingApiData = {}
      const name = this.data.name || 'no Name';
      this.sessionService.setExportedFileNameObserver(`${name}.${type}`, `${type}`);
      if (this.data.selectedTab === 0 || this.data.selectedTab === 2) {
        let apiData = { ...this.data.apiData };
        let payload = this.data.payload;
        switch (this.exportData.value) {
          case 'all':
            apiData['approvalStatus'] = null;
            if (this.data.apiData['buildingId'] || this.data.apiData['buildingId'] === '') delete apiData['buildingId'];
            if (this.data.apiData['search'] || this.data.apiData['search'] === '') delete apiData['search'];
            if (this.data.apiData['startDate']) apiData['startDate'] = '';
            if (this.data.apiData['endDate']) apiData['endDate'] = '';
            if (this.data.apiData['date']) apiData['date'] = '';
            if (this.data.apiData['surveyor']) apiData['surveyor'] = '';
            if (this.data.apiData['assessmentDate']) apiData['assessmentDate'] = '';
            if (this.data.apiData['ward']) apiData['ward'] = '';
            if (this.data.apiData['limit'] || this.data.apiData['limit'] === '') delete apiData['limit'];
            if (Object.keys(this.data.apiData).includes('is_deleted')) {
              delete apiData['is_deleted']
            }
            delete apiData['page'];
            delete apiData['exactSearch']
            break;
          case 'export-selected-data':
            // if(this.data['selectedBuildingIds'].length>0){
            //   apiData['buildingId']=this.data['selectedBuildingIds']
            // }
            // else{
            //   apiData['buildingId']=[]
            // }
            if (this.data.apiData['search'] || this.data.apiData['search'] === '') delete apiData['search'];
            if (this.data.apiData['startDate'] || this.data.apiData['startDate'] === '') delete apiData['startDate'];
            if (this.data.apiData['endDate'] || this.data.apiData['endDate'] === '') delete apiData['endDate'];
            if (this.data.apiData['surveyor'] || this.data.apiData['surveyor'] === '') delete apiData['surveyor'];
            if (this.data.apiData['ward'] || this.data.apiData['ward'] === '') delete apiData['ward'];
            if (this.data.apiData['limit'] || this.data.apiData['limit'] === '') delete apiData['limit'];
            if (this.data.apiData['page']) delete apiData['page'];
            delete apiData['approvalStatus'];
            delete apiData['exactSearch'];
            apiData['selected_data'] = true;

            break;
          case 'export-filtered-data':
            if (this.data.apiData['buildingId'] || this.data.apiData['buildingId'] === '') delete apiData['buildingId'];
            if (this.data.apiData['search'] || this.data.apiData['search'] === '') delete apiData['search'];
            delete apiData['limit'];
            delete apiData['page'];
            delete apiData['exactSearch'];
            apiData['filter_data'] = true;
            break;
        }
        apiData['type'] = type;
        if (this.data.selectedTab === 2) {

          apiData['isEditGeom'] = true;
        }
        apiData['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        apiData['selected']=true;
        this._store.dispatch(new ExportBuildingDataStartAction(apiData));
        this.dialogRef.close(true)

      } else if (this.data.selectedTab === 1) {
        let payload = this.data.payload;
        let apiData = this.data.apiData;

        switch (this.exportData.value) {
          case 'all':
            delete apiData['floor'];
            delete apiData['buildingIds']
            delete payload['buildingIds']
            payload['export_type'] = "all";
            delete apiData['page'];
            delete apiData['limit'];
            delete apiData['search'];
            delete apiData['approvalStatus'];
            delete apiData['premiseFloor'];
            delete apiData['surveyor'];
            delete apiData['exactSearch']
            payload['showData'] = this.data.apiData['showData'];
            break;
          case 'export-selected-data':
            delete apiData['floor'];
            delete apiData['approvalStatus'];
            delete apiData['limit'];
            delete apiData['page'];
            delete apiData['search'];
            delete payload['buildingIds']
            delete apiData['surveyor'];
            delete apiData['exactSearch']
            apiData['']
            payload['selected']=true;
            apiData['selected_data'] = true;
            apiData['building'] = false;
            payload['export_type'] = "selected_premises";
            payload['premiseIds'] = this.data.premiseIds;
            payload['showData'] = this.data.apiData['showData']

            break;
          case 'export-filtered-data':
            delete apiData['limit'];
            delete apiData['page'];
            delete apiData['search'];
            delete payload['buildingIds'];
            payload['export_type'] = "building_premises";
            apiData['filter_data'] = true;
            apiData['building'] = true;
            delete apiData['exactSearch'];
            payload['showData'] = this.data.apiData['showData']

            // payload['buildingIds'] = this.data.buildingIds;
            if ([undefined, null, ''].includes(this.DAMService.buildingFilters['dateField'])) {

              delete this.DAMService.buildingFilters['dataField'];
            }
            let index = this.DAMService.selectedSortObjectArray.findIndex((obj) => obj.selectedTab === 1);
            let selectedSortObject = {};
            let sortObjParams = {};
            if (index !== -1) {
              selectedSortObject = this.DAMService.selectedSortObjectArray[index]
            }
            if (Object.keys(selectedSortObject).length) {
              sortObjParams['sort'] = selectedSortObject['sort'];
              sortObjParams['order'] = selectedSortObject['order']
            }
            let buildingFilters = { ...this.DAMService.buildingFilters };
            let startDate = '';
            let endDate = ''
            if (this.DAMService.buildingFilters['startDate']) {
              startDate = this.DAMService.buildingFilters['startDate']
            }
            if (this.DAMService.buildingFilters['endDate']) {
              endDate = this.DAMService.buildingFilters['endDate'];
            }
            delete buildingFilters['startDate'];
            delete buildingFilters['endDate'];
            buildingApiData = { ...DEFAULT_LAYER_PARAMS, ...buildingFilters, search: `${encodeURIComponent(this.DAMService.searchedBuildingValue.trim())}`, ...sortObjParams, exactSearch: this.DAMService.buildingTableExactSearch, buildingPremises: true, building:true,isExport: 2, filter_data:true,startDate, endDate };
            if (this.DAMService.buildingDataField && Object.keys(this.DAMService.buildingDataField).length) {
              buildingApiData['date'] = this.DAMService.buildingDataField.type;
            }
            buildingApiData['type'] = type;

            break;
          case 'export-selected-building-premises':
            delete apiData['page'];
            delete apiData['limit'];
            delete apiData['search'];
            delete apiData['approvalStatus'];
            delete apiData['premiseFloor'];
            delete apiData['surveyor'];
            delete apiData['exactSearch']
            payload['export_type'] = "building_premises";
            apiData['selected_data'] = true;
            apiData['building'] = true;
            payload['buildingIds'] = this.data.buildingIds;
            payload['showData'] = this.data.apiData['showData']
        }
        apiData['type'] = type;
        payload['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        delete buildingApiData['exactSearch'];
        delete buildingApiData['search'];
        if (this.exportData.value === 'export-filtered-data') {
          this._store.dispatch(new ExportPremisesDataStartAction(buildingApiData, payload))
          this.dialogRef.close(true)

        } else {
          this._store.dispatch(new ExportPremisesDataStartAction(apiData, payload));
          this.dialogRef.close(true)
        }
      }

    } else if (this.data.name && this.data.layer_id) {
      const name = this.data.name || "";
      this.sessionService.setExportedFileNameObserver(`${name}.${type}`, `${type}`);
      const payload = {
        "data_approval": this.data.data_approval,
        "layer_id": this.data.layer_id,
        "export_type": type,
        "selected":true,
      }
      // this.layerService
      this._store.dispatch(new ExportDataStartAction(payload));
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
  optionComparision(o1, o2) {

    return o1 && o2 && o1.value === o2.value

  }

  disableFn() {

    console.log("Export Data"+JSON.stringify(this.exportData))
    console.log("Data Type"+this.data.dataType)

    if(this.data.dataType!="city_os"){
      console.log("Not City Os")
      return true;
    }

    if (Object.keys(this.exportData).length) {
      if (this.exportData.value === 'export-filtered-data') {
        let filterObj = this.data.filterObj
        if (this.data.selectedTab === 1) {
          filterObj = this.DAMService.buildingFilters
          if (Object.keys(filterObj).length) {
            if ((filterObj.approvalStatus !== undefined && filterObj.approvalStatus !== null) || (filterObj.date !== undefined && filterObj.date !== "") || (filterObj.assessmentDate !== undefined && filterObj.assessmentDate !== "") ||
              (filterObj.startDate !== undefined && filterObj.startDate !== "") || (filterObj.endDate !== undefined && filterObj.endDate !== "") || (filterObj.surveyor !== undefined && filterObj.surveyor !== "") || (filterObj.ward !== undefined && filterObj.ward !== "")) {
              this.throwNote = false
              return true
            }
            else {
              this.throwNote = true
              return false
            }
          }
          else {
            this.throwNote = true
            return false
          }

        }
        if (this.data.selectedTab === 0 || this.data.selectedTab === 2) {
          if ((filterObj.approvalStatus !== undefined && filterObj.approvalStatus !== null) || (filterObj.date !== undefined && filterObj.date !== "") || (filterObj.assessmentDate !== undefined && filterObj.assessmentDate !== "") ||
            (filterObj.startDate !== undefined && filterObj.startDate !== "") || (filterObj.endDate !== undefined && filterObj.endDate !== "") || (filterObj.surveyor !== undefined && filterObj.surveyor !== "") || (filterObj.ward !== undefined && filterObj.ward !== "")) {
            this.throwNote = false
            return true
          }
          else {
            this.throwNote = true
            return false
          }
        }
      }
      else {
        this.throwNote = false
      }
      return true
    }
    else {
      return false
    }
  }

  ngOnDestroy() {
    if (this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }
}

export const EXPORT_OPTIONS = {
  data_collected: {
    types: ["kml", "shp", "geojson", "csv", "dxf"],
    display_types: ["KML", "SHP", "Geo JSON", "CSV", "DXF"]
  },
  advanced_search: {
    types: ["kml", "shp", "geojson", "csv", "dxf"],
    display_types: ["KML", "SHP", "Geo JSON", "CSV", "DXF"]
  },
  query_search: {
    types: ["kml", "shp", "geojson", "csv", "dxf"],
    display_types: ["KML", "SHP", "Geo JSON", "CSV", "DXF"]
  },
  graphic_selection: {
    types: ["kml", "shp", "geojson", "csv", "dxf"],
    display_types: ["KML", "SHP", "Geo JSON", "CSV", "DXF"]
  },
  map_export: {
    types: ['png', 'jpeg', 'pdf'],
    display_types: ['PNG', 'JPEG', 'PDF']
  },
  table_join: {
    types: ["kml", "shp", "geojson", "csv", "dxf"],
    display_types: ["KML", "SHP", "Geo JSON", "CSV", "DXF"]
  },
  city_os: {
    types: ['shp', 'kml', 'xlsx'],
    display_types: ['SHP', 'KML', 'XLSX']
  }
}
export const DEFAULT_LAYER_PARAMS = {
  approvalStatus: null,
  search: "",
  exactSearch: false
}