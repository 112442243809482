import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment as env } from "../../environments/environment";
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';
import connProp from '../../assets/connection.properties.json';

@Injectable()
export class API {
  private baseURL = `${env.apiPath}`;
  private connProp = connProp;
  constructor(private httpClient: HttpClient) { }

  get(subURL: string, isAuth?: boolean, skipBaseURL?: boolean, payload?: any) {
    this.baseURL = `${env.apiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    const options = { headers: this.getHeaders(authReqd, 'text/plain'), params: payload };
    if (skipBaseURL) {
      return this.httpClient.get(`${subURL}`, options);
    } else {
      return this.httpClient.get(`${this.baseURL}${subURL}`, options);
    }
  }

  post(reqObj, isAuth?: boolean, hostUrl?: string, payload?: any) {
    if (hostUrl && hostUrl.length > 0) {
      this.baseURL = hostUrl;
    } else {
      this.baseURL = `${env.apiPath}`;
    }
    const authReqd: boolean = isAuth ? isAuth : false;
    const subURL: string = reqObj.subURL;
    const datum: any = reqObj.datum || {};
    let contentType = 'application/json'
    if (datum instanceof FormData) {
      return this.httpClient.post(`${this.baseURL}${subURL}`, datum);
    } else {
      const options = { headers: this.getHeaders(authReqd, contentType), params: payload };

      return this.httpClient.post(`${this.baseURL}${subURL}`, datum, options)
      }
  }

  put(reqObj, isAuth?: boolean, hostUrl?: string, payload?: any) {
    if (hostUrl && hostUrl.length > 0) {
      this.baseURL = hostUrl;
    } else {
      this.baseURL = `${env.apiPath}`;
    }

    const authReqd: boolean = isAuth ? isAuth : false;
    const subURL: string = reqObj.subURL;
    const datum: any = reqObj.datum || {};
    const options = { headers: this.getHeaders(authReqd), params: payload };
    return this.httpClient.put(`${this.baseURL}${subURL}`, datum, options);


  }


  putNew(reqObj, isAuth?: boolean, hostUrl?: string, payload?: any) {
    if (hostUrl && hostUrl.length > 0) {
      this.baseURL = hostUrl;
    } else {
      this.baseURL = `${env.apiPath}`;
    }
    const datum: any = reqObj.datum || {};
    const subURL: string = reqObj.subURL;

    if (datum instanceof FormData) {
      return this.httpClient.put(`${this.baseURL}${subURL}`, datum);
    } else {
      return this.httpClient.put(`${this.baseURL}${subURL}`, datum);
    }
  }

  patch(reqObj, isAuth?: boolean, hostUrl?: string, payload?: any) {
    if (hostUrl && hostUrl.length > 0) {
      this.baseURL = hostUrl;
    } else {
      this.baseURL = `${env.apiPath}`;
    }
    const authReqd: boolean = isAuth ? isAuth : false;
    const subURL: string = reqObj.subURL;
    const datum: any = reqObj.datum || {};
    const options = { headers: this.getHeaders(authReqd), params: payload };
    return this.httpClient.patch(`${this.baseURL}${subURL}`, datum, options);
  }

  putFileToS3(reqUrl, fd) {

    return this.httpClient.put(reqUrl, fd);
  }

  putFileToS3WithType(reqUrl, fd, type) {
    if (!type) return this.putFileToS3(reqUrl, fd);
    let options = { headers: this.getHeaderForType(type) };
    return this.httpClient.put(reqUrl, fd, options);

  }
  file(subUrl: string, fileData) {
    const options = { headers: this.getFileHeaders(false) };
    this.baseURL = `${env.apiPath}`;
    const reqUrl = `${this.baseURL}${subUrl}`;
    return this.httpClient.post(reqUrl, fileData, options);
  }

  getNew(subURL: string, isAuth?: boolean, skipBaseURL?: boolean, payload?: any, fileExport = false, headerOptions?: object) {
   
    this.baseURL = `${env.sessionApiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    const options = fileExport ? { headers: this.getHeaderForExport(authReqd), params: payload } : { headers: this.getHeaders(authReqd), params: payload };
    if (skipBaseURL) {
      return this.httpClient.get(`${subURL}`, options);
    }

    else if (headerOptions) {
      return this.httpClient.get(`${this.baseURL}${subURL}`, { ...options, ...headerOptions });
    }
    else {
      return this.httpClient.get(`${this.baseURL}${subURL}`, options);
       
    
    }
  }
//To call v3 API for delete tools notifications 
  getNewV3(subURL: string, isAuth?: boolean, skipBaseURL?: boolean, payload?: any, fileExport = false, headerOptions?: object){
      var Burl= this.changeBaseUrl(env.apiPath)
    const authReqd: boolean = isAuth ? isAuth : false;
    const options = fileExport ? { headers: this.getHeaderForExport(authReqd), params: payload } : { headers: this.getHeaders(authReqd), params: payload };
    if (skipBaseURL) {
      return this.httpClient.get(`${subURL}`, options);
    }

    else if (headerOptions) {
      return this.httpClient.get(`${Burl}${subURL}`, { ...options, ...headerOptions });
    }
    else {
      return this.httpClient.get(`${Burl}${subURL}`, options);
       
    
    }
    


  }
  
  getNewTest(subURL: string, isAuth?: boolean, skipBaseURL?: boolean, payload?: any, fileExport = false, headerOptions?: object) {

    this.baseURL = `${env.sessionApiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    const options = fileExport ? { headers: this.getHeaderForExport(authReqd), params: payload } : { headers: this.getHeaders1(authReqd), params: payload };
    if (skipBaseURL) {
      return this.httpClient.get(`${subURL}`, options);
    }

    else if (headerOptions) {
      return this.httpClient.get(`${subURL}`, { ...options, ...headerOptions });
    }
    else {
      return this.httpClient.get(`${subURL}`, options);
    }
  }

  getFile(subURL: string, isAuth?: boolean, skipBaseURL?: boolean, payload?: any) {
    this.baseURL = `${env.sessionApiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    /**
     *   get(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe: 'response';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType: 'blob';
        withCredentials?: boolean;
    }): Observable<HttpResponse<Blob>>;
     */
    const options = {
      headers: this.getHeaderForExport(authReqd),
      params: payload,
    };
    // const options = {headers: this.getHeaderForExport(authReqd), params: payload,responseType:'blob'}
    if (skipBaseURL) {
      return this.httpClient.get(`${subURL}`, { ...options, responseType: 'blob' });
    } else {
      return this.httpClient.get(`${this.baseURL}${subURL}`, { ...options, responseType: 'blob' });
    }
  }

  postNew(reqObj, isAuth?: boolean, payload?: any, fileExport = false, headerOptions?: object) {

    this.baseURL = `${env.sessionApiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    const subURL: string = reqObj.subURL;
    const datum: any = reqObj.datum || {};
    if (datum instanceof FormData) {
      this.baseURL = `${env.apiPath}`;
      return this.httpClient.post(`${this.baseURL}${subURL}`, datum);
    } else {
      const options = fileExport ? { headers: this.getHeaderForExport(authReqd), params: payload } : { headers: this.getHeaders(authReqd), params: payload };
      if (headerOptions) {
        return this.httpClient.post(`${this.baseURL}${subURL}`, datum, { ...options, ...headerOptions });
      } else {
        return this.httpClient.post(`${this.baseURL}${subURL}`, datum, options);
      }
    }
  }

  postNewTest(reqObj, isAuth?: boolean, payload?: any, fileExport = false, headerOptions?: object) {

    this.baseURL = `${env.sessionApiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    const subURL: string = reqObj.subURL;
    const datum: any = reqObj.datum || {};
    if (datum instanceof FormData) {
      this.baseURL = `${env.apiPath}`;
      return this.httpClient.post(`${subURL}`, datum);
    } else {
      const options = fileExport ? { headers: this.getHeaderForExport1(authReqd), params: payload } : { headers: this.getHeaders1(authReqd), params: payload };
      if (headerOptions) {
        return this.httpClient.post(`${subURL}`, datum, { ...options, ...headerOptions });
      } else {
        return this.httpClient.post(`${subURL}`, datum, options);
      }
    }
  }
  postFile(subURL: string, isAuth?: boolean, skipBaseURL?: boolean, payload?: any) {
    const options = {
      headers: this.getHeaderForExport(isAuth),
    };
    let urlString = skipBaseURL ? `${subURL}` : `${this.baseURL}${subURL}`

    return this.httpClient.post(urlString, payload, { ...options, responseType: 'blob' });
  }
  postNewForm(reqObj, isAuth?: boolean) {
    this.baseURL = `${env.sessionApiPath}`;
    const authReqd: boolean = isAuth ? isAuth : false;
    const subURL: string = reqObj.subURL;
    const datum: any = reqObj.datum || {};
    const options = { headers: this.getFileHeaders(authReqd) };
    return this.httpClient.post(`${this.baseURL}${subURL}`, datum, options);
  }

  private getHeaders(isAuthNeeded: boolean = true, contentType?) {
    let headers = new HttpHeaders();
    const authToken: string = localStorage.getItem("token");
    if (isAuthNeeded && authToken) {
      headers = headers.append("Authorization", "Bearer " + authToken);
      headers = headers.append("token", "Bearer " + authToken);
    }
    headers = headers.append("Content-Type", contentType ? contentType : "application/json");
    // headers = headers.append("Content-Type", contentType ? contentType : "multipart/form-data");
    return headers;
  }

  private getHeaders1(isAuthNeeded: boolean = true, contentType?) {
    let headers = new HttpHeaders();
    const authToken: string = localStorage.getItem("token");
    if (isAuthNeeded && authToken) {
      headers = headers.append("Authorization", "Bearer " + authToken);
      headers = headers.append("token", "Bearer " + authToken);
    }
    // headers = headers.append("Content-Type", contentType ? contentType : "application/json");
    // headers = headers.append("Content-Type", contentType ? contentType : "multipart/form-data");
    return headers;
  }

  private getHeaderForType(type) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", type);
    headers = headers.append("x-amz-acl", 'public-read');
    return headers;
  }

  private getHeaderForExport(isAuthNeeded: boolean = true) {
    // private getHeaderForExport(isAuthNeeded: boolean = true, headerOptions?: object) {


    let headers = new HttpHeaders({
      'Accept': 'application/json, application/zip, application/dxf, application/kml',
      'Content-Type': 'application/json',
    });

    // if (headerOptions) {
    //   for (let key in headerOptions) {
    //     headers = headers.append(key, headerOptions[key]);
    //   }
    // }

    const authToken: string = localStorage.getItem("token");
    if (isAuthNeeded && authToken) {
      headers = headers.append("Authorization", "Bearer " + authToken);
      headers = headers.append("token", "Bearer " + authToken);
    }
    return headers;
  }

  private getHeaderForExport1(isAuthNeeded: boolean = true) {
    // private getHeaderForExport(isAuthNeeded: boolean = true, headerOptions?: object) {


    let headers = new HttpHeaders({
      // 'Accept': 'application/json, application/zip, application/dxf, application/kml',
      // 'Content-Type': 'application/json',
    });

    // if (headerOptions) {
    //   for (let key in headerOptions) {
    //     headers = headers.append(key, headerOptions[key]);
    //   }
    // }

    const authToken: string = localStorage.getItem("token");
    if (isAuthNeeded && authToken) {
      headers = headers.append("Authorization", "Bearer " + authToken);
      headers = headers.append("token", "Bearer " + authToken);
    }
    // headers = {}
    return headers;
  }

  private getFileHeaders(isAuthNeeded: boolean = true) {
    // let headers = this.getHeaders(isAuthNeeded);
    let headers = new HttpHeaders();
    const authToken: string = localStorage.getItem("token");
    headers = headers.append("Authorization", "Bearer " + authToken);
    headers = headers.append("token", "Bearer " + authToken);
    // headers = headers.set('Content-Type', 'multipart/form-data');
    return headers;
  }

  private getImageHeaders(isAuthNeeded: boolean = true) {
    // let headers = this.getHeaders(isAuthNeeded);
    let headers = new HttpHeaders();
    const authToken: string = localStorage.getItem("token");
    headers = headers.append("Authorization", "Bearer " + authToken);
    headers = headers.append("token", "Bearer " + authToken);
    headers = headers.set('Content-Type', 'multipart/form-data');
    return headers;
  }

  getProfilePic(url) {
    return this.httpClient.get(url, { responseType: 'blob' })
  }
  getImageFromServer(url): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(share())

  }
  getLogoOfOrganization(url) {
    return this.httpClient.get(url, { responseType: 'blob' })
  }
  getCsvData(url) {
    return this.httpClient.get(url, { responseType: 'text' })
  }

  getFormDataToken(type?: string) {
    let apiPath = `${env.apiPath}`
    if (type && type.length) {
      if (env.production) {
        apiPath = apiPath.replace('/v2', '/v3')
      }
    }
    return this.httpClient.get(`${apiPath}${this.connProp.getFormDataToken}`)
  }
  changeBaseUrl(baseUrl) {
    return (env.production) ? baseUrl.replace('v2', 'v3') : baseUrl;
  }

  regenerateToken(userId) {
    let url = `${env.apiPath}` + `/auth/regenerate_token/${userId}`
    return this.httpClient.post(url, {})
  }
}
