import { Action } from '@ngrx/store'

export enum userManagementActionTypes {
  getUsersStart = "[USER-MANAGEMENT] Get Users Start",
  getUsersSuccess = "[USER-MANAGEMENT] Get Users Success",
  getUserInfoStart = "[USER-MANAGEMENT] Get User Info Start",
  getUserInfoSuccess = "[USER-MANAGEMENT] Get User Info Success",
  // addUserStart = "[USER-MANAGEMENT] Add User Start",
  // addUserSuccess = "[USER-MANAGEMENT] Add User Success",
  // updateUserStart = "[USER-MANAGEMENT] Update User Start",
  // updateUserSuccess = "[USER-MANAGEMENT] Update User Success",
  addUpdateUserStart ="[USER-MANAGEMENT] add update user start",
  addUpdateUserSuccess="[USER-MANAGEMENT] add update user success",
  deleteUserStart = "[USER-MANAGEMENT] Delete User Start",
  deleteUserSuccess = "[USER-MANAGEMENT] Delete User Success",
  logError  = "[USER-MANAGEMENT] Log Errors",
  logFailure = "[USER-MANAGEMENT] Log Failure",
  getRolesStart = "[USER-MANAGEMENT] get roles start",
  getRolesSuccess = "[USER-MANAGEMENT] get roles success",
  getSurveyorsListSuccess ="[USER-MANAGEMENT] get surveyors list success"
}

export class LogErrorAction implements Action {
  readonly type = userManagementActionTypes.logError;
  constructor(public error: Error) {
   
  }
}

export class LogFilureAction implements Action {
  readonly type = userManagementActionTypes.logFailure;
  constructor(public error: string) {
   
  }
}


export class GetUsersStartAction implements Action {
  readonly type = userManagementActionTypes.getUsersStart;
  constructor(public data, public module ='tos') { }
}


export class GetUsersSuccessAction implements Action {
  readonly type = userManagementActionTypes.getUsersSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class GetUserInfoStartAction implements Action {
  readonly type = userManagementActionTypes.getUserInfoStart;
  constructor(public payload) { }
}

export class GetUserInfoSuccessAction implements Action {
  readonly type = userManagementActionTypes.getUserInfoSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}
export class AddUpdateUserStartAction implements Action {
  readonly type = userManagementActionTypes.addUpdateUserStart;
  constructor(public payload) {}
}
export class AddUpdateUserSuccessAction implements Action {
  readonly type = userManagementActionTypes.addUpdateUserSuccess;
  constructor(public response) {}
 }

export class DeleteUserStartAction implements Action {
  readonly type = userManagementActionTypes.deleteUserStart;
  subURL;
  constructor(data) {
    this.subURL = data;
  }
}

export class DeleteUserSuccessAction implements Action {
  readonly type = userManagementActionTypes.deleteUserSuccess;
  constructor() { }
}
export class GetRolesStartAction implements Action {
  readonly type = userManagementActionTypes.getRolesStart;
  constructor() { }
}



export class GetRolesSuccessAction implements Action {
  readonly type = userManagementActionTypes.getRolesSuccess;
  data;
  constructor(public response: any) {
    this.data = response;
  }
}

export class GetSurveyorsListSuccesAction implements Action {

  readonly type = userManagementActionTypes.getSurveyorsListSuccess;
  constructor(public response: any) {}
}

export type UserManageMentActions = GetUsersSuccessAction |AddUpdateUserSuccessAction
   | GetUserInfoSuccessAction | GetRolesSuccessAction | GetSurveyorsListSuccesAction