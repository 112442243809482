export const MAP_AUTH_KEY = {
  BING_MAP: 'AjkuUh7MlFKVVz_4eFbFxDffnwTqKuuv9O-Z1bSCcHeYf7BWDalUml2qhgPu8DP5'
};

export const STATE_ID = "5c3de287644aa0c5e9678133";
export const TALUKA_ID = "5c3de287644aa0c5e9678135";
export const DISTRICT_ID = "5c3de287644aa0c5e9678149";
export const DEFAULT_POINT_ICON = "circle-15.svg";
export const DEFAULT_POLYGON_ICON = "solid.png";
export const DEFAULT_ICON_COLOR = "#2883e9";
export const MAP_MIN_ZOOM = 0;
export const MAP_MAX_ZOOM = 22;
export const GEOJSONLEVEL = 14;
export const MAXOFFSETVALUEALLOWED = 100;
export const MINOFFSETVALUEALLOWED = 0;

export const CONDITION_OPERATORS = {
  "is": "Is answered",
  "in": "Is not answered",
  "eq": "Equals",
  "lt": "Less than",
  "gt": "Greater than",
  "lte": "Less than equals",
  "gte": "Greater than equals",
  "ne": "Not equals",
  "contains": "Contains",
  "between": "Between",
  "before": "Before",
  "after": "After"
};

export const CALCULATION_OPERATORS = ["+", "-", "*", "/", "%"];
export const PALLETE_OBJ = {
  PalleteHeaders: ['Pastel Palette', 'High Contrast', 'Shades'],
  palleteColors: [
    ["#000000", "#76AF49", "#E95A7D", "#FAB3A4", "#7FCDB5", "#FED98E", "#DF84BA", "#9A75B6"],
    ["#F48311", "#7ED321", "#D0633F", "#417505", "#5485B5", "#48DDAF", "#9013FE", "#E8A01A"],
    ["#BE0F10", "#AAB1B8", "#C01F96", "#FAB444", "#7FC222", "#7ED320", "#DF8555", "#B5D980"]
  ]
};

export const WIDGET_SHAPE_TIME_OPTIONS = [
  { id: 5, value: '5sec' },
  { id: 15, value: '15sec' },
  { id: 30, value: '30sec' },
  { id: 60, value: '1min' },
  { id: 300, value: '5min' },
];

export const WIDGET_SHAPE_DISTANCE_OPTIONS = [
  { id: 5, value: '5m' },
  { id: 10, value: '10m' },
  { id: 15, value: '15m' },
  { id: 20, value: '20m' },
  { id: 25, value: '25m' },
];

export const RELATION_TYPES_SEARCH_BY_LOCATION = [
  { key : 0 , value :  "intersect" },

  { key : 1 , value :  "contain" },

  { key : 2 , value :  "disjoint" },

  { key : 3 , value :  "equal" },

  { key : 4 , value :  "touch" },

  { key : 5 , value :  "overlap" },

  { key : 6 , value :  "are within" },

  { key : 7 , value :  "cross" },
]

export const QUERY_OPERATORS = [
  'AND', 'OR', 'NOT', 'IS', 'NULL',
  '+', '-', '*', '/', '(', ')', '=', '<>', '<',
  '>', '<=', '>=', '%', 'IN', '^', '!', ','
];

export const QUERY_OPERATORS_STRING_TYPE = [
  'LIKE', 'AND', 'OR', 'NOT', 'IS', 'NULL',
  '(', ')', '=', 'IN', '_', ','
]

export const LINE_SYMBOLS = ["solid", "dash", "dotted"];
export const ENTRY_STATUSES = {
  new: 1,
  edited: 2,
  deleted: 3
}

export const DATA_LAYER_TYPES = {
  data_collected_layer: 'collected_data',
  vector_layer: 'vector_data',
  advanced_search: 'advanced_search_data',
  query_search: 'query_search_data',
  graphic_selection: "selection_by_graphics",
  table_join: "table_join"
}

export const CLSSIFICATION_METHODS = [
  { key: "Equal Interval", value: 1 },
  { key: "Quantile", value: 2 },
  { key: "Natural Breaks (Jenks)", value: 3 },
  { key: "Standard Deviation", value: 4 },
  { key: "Pretty Breaks", value: 5 },
  { key: "Custom" ,value:6 }
]

export const LAYER_INACTIVE_ICONS = {
  "point": "assets/images/point_sample.svg",
  "polyline": "assets/images/Darkpolyline.svg",
  "polygon": "assets/images/Dankpolygon.svg",
  "multipolygon": "assets/images/Dankpolygon.svg",
  "multipolyline": "assets/images/Darkpolyline.svg",
  "linestring": "assets/images/Darkpolyline.svg",
  "multilinestring": "assets/images/Darkpolyline.svg",
  "raster": "assets/images/raster-icon.svg"
}

export const LAYER_ACTIVE_ICONS = {
  "point": "assets/images/point_sample.svg",
  "polyline": "assets/images/Darkpolyline.svg",
  "polygon": "assets/images/Dankpolygon.svg",
  "multipolygon": "assets/images/Dankpolygon.svg",
  "multipolyline": "assets/images/Darkpolyline.svg",
  "linestring": "assets/images/Darkpolyline.svg",
  "multilinestring": "assets/images/Darkpolyline.svg",
  "raster": "assets/images/raster-icon.svg"
}

export enum LAYER_STYLES {
  fixed_style = 1,
  categorized_style = 2,
  graduated_style = 3,
  bubble_style = 4,
  heatmap_style = 5,
  dotted_style = 6,
  clustered_style = 7,
  hex_grid_style = 8,
  advanced_style = 9
}

export enum ADVANCED_TOOL_TYPE {
  point_within_polygon = 1,
  voronoi = 2,
  centroid = 3,
  point_on_feature = 4,
  buffer = 5,
  difference = 6,
  intersect = 7,
  union = 8,
  bounding_box = 9,
  along = 10,
  concave = 11,
  convex = 12,
  dissolve = 13,
  line_offset = 14,
  simplify = 15,
  envelope = 16,
  center_of_mass = 17,
  interpolate = 18,
  isobands = 19,
  isolines = 20,
  tin = 21,
  clusters_k_means = 22,
  midpoint = 23,
  tesselate = 24,
  polygonize = 25,
  mask = 26,
  hex_grid = 27,
  point_grid = 28,
  square_grid = 29,
  triangle_grid = 30,
  center = 31,
  destination = 32,
  merge = 33,
  regression = 34,
  service_area = 35,
  distance_to_nearest_facility = 36,
  "spatial-join" = 37,
  "similarity-search" = 38,
  "search-by-location" = 39
}

export const ADVANCED_TOOLS_CATEGORICALLY = {
  spatial_analysis: [5, 17, 22, 6, 18, 7, 19, 20, 26, 4, 1, 21, 8, 2, 37], // to be added , 37
  geometry: [10, 9, 31, 3, 11, 12, 13, 16, 14, 33, 23, 25, 15, 24],
  grid: [27, 28, 29, 30],
  table_join: [],
  network_analysis: [35, 36], // to be added , 36
  statistical_analysis: [34,38]
}

export const ADVANCED_TOOLS_CATEGORIES = [
  {
    type: 'spatial_analysis',
    type_text: 'Spatial Analysis',
    active_icon: 'assets/images/place_1.svg',
    inactive_icon: 'assets/images/place.svg',
    black_icon: 'assets/images/place.svg'
  },
  {
    type: 'geometry',
    type_text: 'Geometry',
    active_icon: 'assets/images/geometry.svg',
    inactive_icon: 'assets/images/geometry_selected.svg',
    black_icon: 'assets/images/geometry_selected.svg'
  },
  {
    type: 'grid',
    type_text: 'Grid',
    active_icon: 'assets/images/hexagon_white.svg',
    inactive_icon: 'assets/images/grid.svg',
    black_icon: 'assets/images/grid.svg'
  },
  {
    type: 'table_join',
    type_text: 'Table Join',
    active_icon: 'assets/images/table_join_white.svg',
    inactive_icon: 'assets/images/table_join.svg',
    black_icon: 'assets/images/table_join.svg'
  },
  {
    type: 'network_analysis',
    type_text: 'Network Analysis',
    active_icon: 'assets/images/network-active.svg',
    inactive_icon: 'assets/images/network-inactive.svg',
    black_icon: 'assets/images/network-inactive.svg'
  },
  {
    type: 'statistical_analysis',
    type_text: 'Statistical Analysis',
    active_icon: 'assets/images/statistical-analysis-white.svg',
    inactive_icon: 'assets/images/statistical-analysis-black.svg',
    black_icon: 'assets/images/statistical-analysis-black.svg'
  }
]

export const SCALE_IMAGES = {
  '19596151_copy_2.png': '1.svg',
  '19596151_copy_3.png': '3.svg',
  '19596151_copy_4.png': '2.svg',
  '19596151_copy_5.png': '7.svg',
  '19596151_copy_6.png': '6.svg',
  '19596151_copy_7.png': '5.svg',
  '19596151_copy_8.png': '4.svg',
  '19596151_copy_9.png': '8.svg'
}
export const maxSizeAllowed = {
  csv: 1048576
}
export const POLYGON_STYLE_IMAGES = [
  {
    type: 'hollow',
    img: 'assets/images/hollow.svg',
  },
  {
    type: 'solid',
    img: 'assets/images/solid.png',
  },
  // {
  //   type : 'point',
  //   img : 'assets/images/points.png',
  // },
  // {
  //   type: 'dotted',
  //   img: '/assets/images/dotted.png'
  // }
]
export const dottedCase = {
  'fillColor': 'Fill color cannot be applied',
  'border': 'border cannot be applied',
  'width': 'widthCannot be applied'
}
export const hollowCase = {
  'fillColor': 'Fill color cannot be applied'

}
export const FORMDATE = 'HKBDHKUE76567LJNNJKP';

export const textFonts = [
  'Open Sans ',
  'PT Sans',
  'PT Sans Narrow',
  'Roboto',
  'Roboto Condensed',
  'Noto Sans',
  'Metropolis',
]

export const SUBSCRIPTION_LIMIT_HEADERS = {
  10040: "Maximum allowed map sessions reached",
  10041: "Maximum allowed projects reached",
  10042: "Maximum allowed analysts reached",
  10043: "Maximum allowed surveyors reached",
  10044: "Maximum API hits reached",
  10045: "Maximum allowed storage will exceed"
}

export const SUBSCRIPTION_ATTRS = {
  "Upload & share spatial data": "spatialData",
  "Create unlimited map sessions & map styles": "mapSessionsData",
  "Publish for web & mobile": "publishMap",
  "Create forms": "createForms",
  "Mobile based spatial data collection": "mobileSpatial", // being handled in BE
  "Merge collected data with spatial layers": "mergeCollectedData", // approval of collected data
  "Auto map update": "autoMapUpdate", // To be handled in BE (published dc layer- approved data needs to be updated)
  "White labelling": "whiteLabel", // transerve logo and organization label, spatialData: for uploading layers
  "Spatial analytics of layers": "spatialAnalytics", //advanced tools, query and , saveAnalytics: temp/perm layers, 
  "Save analytics to map session": "saveAnalytics",
  "Web apps": "propertyTax", //for city-os
  "Perpetual license": "perpetualLicense",
  "On-premise database": "clientDatabase"
};

export const RECAPTCHA_SITE_KEY = "6LfBQP0UAAAAAMtYUUBT1nEQbvnNoFtraQIwqSMX";
export const acceptableVectorFormats = ["image/vnd.dxf", "image/vnd.json", "image/vnd.geojson", "application/zip", "application/octet-stream", 'application/vnd.google-earth.kml+xml', 'application/vnd.google-earth.kmz', 'application/dxf', 'application/json', 'application/geojson', 'application/x-zip', 'application/zip', 'application/x-zip-compressed', 'text/csv', 'application/vnd.ms-excel', 'application/geo+json']
export const acceptableRasterFormats = ['application/octet-stream', 'image/tif', 'image/tiff', "image/x-tif", "image/x-tif-compressed", "application/octet-stream"]
export const acceptableTabularFormats = ['text/csv', 'application/xls', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
export const acceptableVectorFileNamesWithoutType = ['dxf', 'json', 'zip', 'kml', 'kmz', 'csv', 'geojson'];
export const acceptableRasterFileNamesWithoutType = ['tif', 'tiff', 'ecw']
export const acceptableTabularFileNamesWithoutType = ['xls', 'xlsx', 'csv']
export enum RASTER_TOOL_TYPE {
  extract_water_bodies = 1,
  band_difference = 2,
  clip = 3,
  slope = 4,
  compute_gradual_path = 5,
  visibility_coverage = 6,
  spatial_filter = 7
}

export const RASTER_TOOLS_CATEGORIES = [
  {
    type: 'resourcesat',
    show_text: 'Resourcesat',
    active_icon: 'resourcesat_white.svg',
    inactive_icon: 'resourcesat.svg',
    black_icon: 'resourcesat.svg'
  },
  // {
  //   type : 'data',
  //   show_text: 'Data',
  //   active_icon: 'data_white.svg',
  //   inactive_icon: 'data.svg',
  //   black_icon: 'data.svg'
  // },
  {
    type: 'analysis',
    show_text: 'Analysis',
    active_icon: 'analysis_white.svg',
    inactive_icon: 'analysis.svg',
    black_icon: 'analysis.svg'
  }
]

export const RASTER_TOOLS_CATEGORICALLY = {
  resourcesat: [1, 2],
  // data: [3],
  analysis: [5, 6, 7] //[4, 5, 6, 7]
}
export const acceptableFilesFormatsInVectorTools = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/msword', "file", '']


export const paintObject = {
  point: {
    'icon-color': '#3388FF',
    'icon-opacity': 1
  },
  polygon: {
    'fill-color': '#3388FF',
    'fill-opacity': 0.2,
    // 'fill-outline-color': '#5485B5'
  },
  multipolygon: {
    'fill-color': '#3388FF',
    'fill-opacity': 0.2,
    // 'fill-outline-color': '#22006b'
  },
  line: {
    'line-color': '#3388FF',
    'line-width': 5
  },
  polyline: {
    'line-color': '#3388FF',
    'line-width': 5
  },
  multipolyline: {
    'line-color': '#3388FF',
    'line-width': 5
  },
  linestring: {
    'line-color': '#3388FF',
    'line-width': 5
  },
  multilinestring: {
    'line-color': '#3388FF',
    'line-width': 5
  }
}
export const sourceObject = {
  point: 'graphic-points',
  polygon: 'graphic-polygons',
  multipolygon: 'graphic-polygons',
  polyline: 'graphic-polylines',
  multipolyline: 'graphic-polylines',
  linestring: 'graphic-polylines',
  multilinestring: 'graphic-polylines'
}
export const unapprovedLayersObject = {
  point: 'unapproved-points',
  polygon: 'unapproved-polygons',
  multipolygon: 'unapproved-polygons',
  polyline: 'unapproved-polylines',
  multipolyline: 'unapproved-polylines',
  linestring: 'unapproved-polylines',
  multilinestring: 'unapproved-polylines'
}
export const layoutObject = {
  point: {
    'icon-size': 1,
    'icon-image': "circle"
  },
  polygon: {},
  multipolygon: {},
  polyline: {},
  multipolyline: {},
  linestring: {},
  multilinestring: {}
}





export const ploatLayerType = {
  point: 'symbol',
  polygon: 'fill',
  multipolygon: 'fill',
  polyline: 'line',
  multipolyline: 'line',
  linestring: 'line',
  multilinestring: 'line'
}

export const GEOMETRY_TYPES = {
  Polygon: 'Polygon',
  MultiPolygon: 'MultiPolygon',
  Point: 'Point',
  LineString: 'LineString',
  MultiLineString: 'MultiLineString'
}

export const MODE_OBJ = {
  Polygon: 'draw_polygon',
  MultiPolygon: 'draw_polygon',
  Point: 'draw_point',
  Polyline: 'draw_line_string',
  LineString: 'draw_line_string',
  point: 'draw_point',
  polygon: 'draw_polygon',
  multipolygon: 'draw_polygon',
  multiPolygon: 'draw_polygon',
  polyline: "draw_line_string",
  linestring: "draw_line_string",
  lineString: "draw_line_string",
  MultiLineString: "draw_line_string",
  multilinestring: "draw_line_string"
}
export const layerTypes = {
  point: 'polygon',
  polygon: 'polygon',
  polyline: 'polygon',
  linestring: 'polygon',
  multipolygon: 'polygon',
  multipolyline: 'polygon',
  multilinestring: 'polygon'
};
export const StaticAlignmentImages = {
  'left': 'assets/images/Group_43_Copy_3.png',
  'center': 'assets/images/Group_43_Copy_2.png',
  'right': 'assets/images/Group_43_Copy.png'
}

export const StaticAnchorImages = {

  'top-left': 'assets/images/topleft_inactive.svg',
  'top': 'assets/images/top_inactive.svg',
  'top-right': 'assets/images/topright_inactive.svg',
  'left': 'assets/images/left_inactive.svg',
  'center': 'assets/images/center_inactive.svg',
  'right': 'assets/images/right_inactive.svg',
  'bottom-left': 'assets/images/bottomleft_inactive.svg',
  'bottom': 'assets/images/bottom_inactive.svg',
  'bottom-right': 'assets/images/bottomright_inactive.svg'

}
export const DefaultOffsetValuesTextAnchor = {

  'top-left': {
    "textOffsetX": 25,
    "textOffsetY": 25,
  },
  'top': {
    "textOffsetX": 0,
    "textOffsetY": 25,
  },
  'top-right': {
    "textOffsetX": 25,
    "textOffsetY": 25,
  },
  'left': {
    "textOffsetX": 25,
    "textOffsetY": 0,
  },
  'center': {
    "textOffsetX": 0,
    "textOffsetY": 0,
  },
  'right': {
    "textOffsetX": 25,
    "textOffsetY": 0,
  },
  'bottom-left': {
    "textOffsetX": 25,
    "textOffsetY": 25,
  },
  'bottom': {
    "textOffsetX": 25,
    "textOffsetY": 0,
  },
  'bottom-right': {
    "textOffsetX": 25,
    "textOffsetY": 25,
  },

}
export const accurateTextOffsetValues = {
  'top-left': {
    'textOffsetX': 1,
    'textOffsetY': 1
  },
  'top': {
    'textOffsetX': 1,
    'textOffsetY': 1
  },
  'top-right': {
    'textOffsetX': -1,
    'textOffsetY': 1
  },
  'left': {
    'textOffsetX': 1,
    'textOffsetY': 1
  },
  'center': {
    'textOffsetX': 1,
    'textOffsetY': 1
  },
  'right': {
    'textOffsetX': -1,
    'textOffsetY': 1
  },
  'bottom-left': {
    'textOffsetX': 1,
    'textOffsetY': -1
  },
  'bottom': {
    'textOffsetX': 1,
    'textOffsetY': -1
  },
  'bottom-right': {
    'textOffsetX': -1,
    'textOffsetY': -1
  },

}

export enum DATA_MANAGEMENT_TABS {
  "YOUR DATA" = 0,
  "DATA LIBRARY" = 1
}

export enum BASE_MAP_INDICES {
  "None" = 0,
  "OSM" = 1,
  "HERE-BASE" = 2,
  "HERE-AERIAL" = 3,
  "HERE-TRAFFIC" = 4
}

export const BASE_MAP_SOURCES = {
  "OSM": "base-layer-raster",
  "HERE-BASE": "here-base-raster",
  "HERE-AERIAL": "here-aerial-raster",
  "HERE-TRAFFIC": "here-traffic-raster"
}

export const BASE_MAP_LAYERS = {
  "OSM": "wms-base-layer",
  "HERE-BASE": "here-base-layer",
  "HERE-AERIAL": "here-aerial-layer",
  "HERE-TRAFFIC": "here-traffic-layer"
}

export const APIKEY_HERE = "wmmtNwHPu4g6l8GZnkg8Rnz0mBRG5YCc-comaYDYlrU";
export const APIKEY_PRECISELY = "hfdTsyrz2F5yWaFNcSSY1FP8NFHXbUaW";

export const BASE_MAP_TILES = {
  "OSM": `https://tile-a.openstreetmap.fr/hot/{z}/{x}/{y}.png`,
  "HERE-BASE": `https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apikey=${APIKEY_HERE}`,
  "HERE-AERIAL": `https://api.precisely.com/maps/v1/tile/osm/{z}/{x}/{y}.png?api_key=${APIKEY_PRECISELY}&theme=iron`,
  "HERE-TRAFFIC": `https://1.traffic.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apikey=${APIKEY_HERE}`
}

export enum L_TYPE_OBJ {
  "vector" = 1,
  "raster" = 2,
  "tabular" = 8
}

export const LAYER_TAG_IMAGES = {
  1: "assets/images/vector-tag.svg",
  2: "assets/images/tabular-tag.svg", // dirty fix. Should be updated later
  8: "assets/images/tabular-tag.svg"
}

export const GROUP_LIST_PARAMS = {
  search: "",
  layerType: 1
}

export const GROUP_LAYERS_LIST_PARAMS = {
  search: "",
  groupName: "",
  layerType: 1,
  page: 1,
  limit: 5,
  asc: false
}

export const LIBRARY_SEARCH_PARAMS = {
  search: "",
  page: 1,
  limit: 10,
  layerType: null
}

export const SELECTED_LAYER_TYPE_FOR_LIBRARY = {
  vector: 1,
  tabular: 2
}

export const BASE_LAYER_URLS = {
  "OSM": "https://tile-a.openstreetmap.fr/hot/{z}/{x}/{y}.png",
  "HERE-BASE": `https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apikey=${APIKEY_HERE}`,
  "HERE-AERIAL": `https://1.traffic.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apikey=${APIKEY_HERE}`,
  "HERE-TRAFFIC": `https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apikey=${APIKEY_HERE}`
}
export const BUBBLE_ICON_STYLE = {
  "iconColor": "#FF0000",
  "minIconSize": 5,
  "maxIconSize": 15,
  "noOfBreaks": 2,
  "iconImage": "circle",
  "minZoomLevel": 0,
  "maxZoomLevel": 22,
  "opacity": 0.2,
  "borderOpacity": 1,
  "id": 1,
  "borderColor": "#FF0000",
  "is_fall_back": true
}

export const BUBBLE_TEXT_STYLE = {
  "textFont": "Open Sans",
  "textSize": 10,
  "textFontWeight": "Regular",
  "letterSpacing": 1,
  "textPadding": 1,
  "textTransform": "none",
  "textOpacity": 1,
  "textAlignment": "left",
  "maxZoomLevel": 22,
  "minZoomLevel": 0,
  "textColor": "#000000",
  "id": 1,
  "is_fall_back": true,
  "textAnchor": "top",
  "textOffsetX": 0,
  "textOffsetY": 20
}
export const HEAT_MAP_ICON_STYLE = {
  "iconColorFrom": "#FFFFFF",
  "iconColorTo": "#FF0000",
  "minIconSize": 5,
  "maxIconSize": 30,
  "minZoomLevel": 0,
  "maxZoomLevel": 22,
  "minIconWeight": 0,
  "maxIconWeight": 1,
  "opacity": 1,
  "borderOpacity": 1,
  "minIconIntensity": 0,
  "maxIconIntensity": 1,
  "id": 1,
  "is_fall_back": true
}


export const HEAT_MAP_TEXT_STYLE = {
  "textFont": "Open Sans",
  "textSize": 10,
  "textFontWeight": "Regular",
  "letterSpacing": 1,
  "textPadding": 1,
  "textTransform": "none",
  "textOpacity": 1,
  "textAlignment": "left",
  "maxZoomLevel": 22,
  "minZoomLevel": 0,
  "textColor": "#000000",
  "id": 1,
  "is_fall_back": true,
  "textAnchor": "top",
  "textOffsetX": 0,
  "textOffsetY": 20
}

export const DOTTED_ICON_STYLE = {
  "iconColor": "#FF0000",
  "iconImage": "circle",
  "iconSize": 1,
  "maxZoomLevel": 22,
  "minZoomLevel": 0,
  "opacity": 1,
  "borderOpacity": 1,
  "id": 1,
  "is_fall_back": true
}

export const DOTTED_TEXT_STYLE = {
  "textFont": "Open Sans",
  "textSize": 10,
  "textFontWeight": "Regular",
  "letterSpacing": 1,
  "textPadding": 1,
  "textTransform": "none",
  "textOpacity": 1,
  "textAlignment": "left",
  "maxZoomLevel": 22,
  "minZoomLevel": 0,
  "textColor": "#000000",
  "id": 1,
  "is_fall_back": true,
  "textAnchor": "top",
  "textOffsetX": 0,
  "textOffsetY": 20
}

export const CLUSTERED_ICON_STYLE = {
  "iconColorFrom": "#FFFFFF",
  "iconColorTo": "#FF0000",
  "minIconSize": 5,
  "maxIconSize": 30,
  "maxZoomLevel": 22,
  "minZoomLevel": 0,
  "opacity": 1,
  "borderOpacity": 1,
  "clusterStops": [],
  "id": 1,
  "is_fall_back": true
}

export const CLUSTERED_TEXT_STYLE = {
  "textFont": "Open Sans",
  "textSize": 10,
  "textFontWeight": "Regular",
  "letterSpacing": 1,
  "textPadding": 1,
  "textTransform": "none",
  "textOpacity": 1,
  "textAlignment": "left",
  "maxZoomLevel": 22,
  "minZoomLevel": 0,
  "textColor": "#000000",
  "id": 1,
  "is_fall_back": true,
  "textAnchor": "top",
  "textOffsetX": 0,
  "textOffsetY": 20
}


export const POLYGON_APPROVED_ICON_STYLE = {

  borderColor: "#7A0070",
  borderOpacity: 1,
  iconColor: "#501010",
  iconImage: null,
  iconSize: 1,
  id: 1,
  is_approved: true,
  is_fall_back: true,
  maxZoomLevel: 22,
  minZoomLevel: 0,
  opacity: 0.33,
  polygonStyle: "solid"
}

export const POLYGON_UNAPPROVED_ICON_STYLE = {

  borderColor: "#000000",
  borderOpacity: 1,
  iconColor: "#00FFFF",
  iconImage: null,
  iconSize: 1,
  id: 1,
  is_approved: false,
  is_fall_back: true,
  maxZoomLevel: 22,
  minZoomLevel: 0,
  opacity: 0.33
}

export const POLYGON_DEFAULT_STYLE = {
  borderColor: "#252525",
  borderOpacity: 1,
  iconColor: "#00FFFF",
  iconImage: null,
  iconSize: 1,
  id: 1,
  is_fall_back: true,
  maxZoomLevel: 22,
  minZoomLevel: 0,
  opacity: 0.33,
  polygonStyle: "solid"
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
}

export enum MAP_SESSIONS_LAYOUT_ACTIONS {
  openSession = 1,
  createSession = 2,
  deleteSession = 3,
  makeSessionPrivate = 4,
  copySession = 5,
  shareSession = 6,
  showAssignedUsersList = 7,
  assignProject = 8,
  onTableScroll = 9,
  openBookmarks = 10
}

export const UNIQ_VALUE_FOR_EXPRESSION = "7s4%#@8hY12Q$gfr@139*&^d3enmbue*7w$!@5";
