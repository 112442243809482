import { Injectable } from "@angular/core";

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      event_action: eventAction,
      event_value: eventValue
    })
  }
}