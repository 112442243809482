import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['./identify.component.scss']
})
export class IdentifyComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {
 
  }

}
