import { Injectable } from '@angular/core';
import { API } from "./API.service";
import connProp from '../../assets/connection.properties.json';

import { Pagination } from '../models/pagination';

@Injectable({
  providedIn: 'root'
})
export class UserWardAssignmentService {

  connProps: any = connProp;
  _url = `${this.connProps.userWardAssignemts}`;

  constructor(
    private apiService: API,
  ) { }

  //get users list
  getList(
    params: Array<String>,
    queryParams: Pagination
  ) {
    let url: string = this._url + params.join("/");
    if(queryParams && Object.keys(queryParams).length>0){
      url+="?";
    }
    for (let key in queryParams) {
      url += key + "=" + queryParams[key] + "&";
    }
    return this.apiService.get(url, true, false);
  }

  //update urban local body data
  userWardAssignRevoke(payload) {
    return this.apiService.patch({ subURL: this._url, datum: payload }, true);
  }
}
