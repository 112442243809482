import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
  userManagementActionTypes,
  LogErrorAction,
  GetUsersStartAction,
  GetUsersSuccessAction,
  DeleteUserSuccessAction,
  DeleteUserStartAction,
  GetUserInfoSuccessAction,
  GetUserInfoStartAction,
  GetRolesStartAction,
  GetRolesSuccessAction,
  AddUpdateUserStartAction,
  AddUpdateUserSuccessAction, GetSurveyorsListSuccesAction
} from "../actions/user-management.actions";
import { mergeMap, map, catchError, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { Storeservice } from "src/app/_services/store.services";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable()
export class UserManagementEffects {
  constructor(
    private actions$: Actions,
    private storeService: Storeservice,
    private _router: Router,
  ) { }

  @Effect()
  $user = this.actions$.pipe(
    ofType(userManagementActionTypes.getUsersStart),
    switchMap((action: GetUsersStartAction) => {
      return this.storeService.getUsersData(action.data).pipe(
        map((result: any) => {
          if(action.module == 'tos') {

            return new GetUsersSuccessAction(result);
          } else {
            return new GetSurveyorsListSuccesAction(result);
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  $userData = this.actions$.pipe(
    ofType(userManagementActionTypes.getUserInfoStart),
    switchMap((action: GetUserInfoStartAction) => {
      return this.storeService.getUserDetails(action.payload).pipe(
        map((result: any) => {
          if (result.success) {
            return new GetUserInfoSuccessAction(result.data);
          }
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
  
  @Effect()
  $addUpdateUser = this.actions$.pipe(
    ofType(userManagementActionTypes.addUpdateUserStart),
    switchMap((action : AddUpdateUserStartAction) => {
      return this.storeService.addUpdateUser(action.payload).pipe(
        map((resp:any ) => {
          return new AddUpdateUserSuccessAction(resp)
        }),
        catchError((err: Error) => of(new LogErrorAction(err)))
      )
    })
  ) 

  @Effect()
  $deleteUser = this.actions$.pipe(
    ofType(userManagementActionTypes.deleteUserStart),
    switchMap((action: DeleteUserStartAction) => {
      return this.storeService.deleteUser(action.subURL).pipe(
        map((result: any) => {
          this._router.navigate(["app-management/user-management"]);
          return new DeleteUserSuccessAction();
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      );
    })
  );

  @Effect()
  $usersListAfterDelete = this.actions$.pipe(
    ofType(userManagementActionTypes.deleteUserSuccess),
    map((action: DeleteUserSuccessAction) => {
      return new GetUsersStartAction(GetUsersPayload);
    })
  );

  @Effect()
  $rolesAfterClicked = this.actions$.pipe(
    ofType(userManagementActionTypes.getRolesStart),
    switchMap((action: GetRolesStartAction) => {
      return this.storeService.getRoles().pipe(
        map((result: any) => {
          return new GetRolesSuccessAction(result.data);
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )

    })
  )

}

export const GetUsersPayload = {
  limit: 20
};


