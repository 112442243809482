import { Component, OnInit ,Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from "@angular/material";
import { SessionService } from 'src/app/_services/session.service';
import _ from "lodash";
import { Store } from '@ngrx/store';
import { GetDataLibraryLayerDetailsStartAction } from 'src/app/store/actions/data-management.actions';
import { dataPreviewLayerSelector, layerPreviewDataSelector } from 'src/app/store/selectors/data-management.selector';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-data-library-preview',
  templateUrl: './data-library-preview-dialog.component.html',
  styleUrls: ['./data-library-preview-dialog.component.scss']
})
export class DataLibraryPreviewDialog implements OnDestroy {
  layerId: string = null;
  layerName: string = null;
  layerType: string = null;
  sheetNames: Array<string> = null;
  selectedSheetName: string = null;
  displayedColumns: Array<string> = [];
  attributesNameAndType: Array<any> = [];
  dataSource: Array<any> = [];
  previewData :any;
  displayedColumnsType: any = null;
  subscribtionArr: Array<Subscription> =[];
  @ViewChild("tableContainer", { read: ElementRef }) tableContainer: ElementRef<any>;
  constructor(
    public dialogRef: MatDialogRef<DataLibraryPreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService,
    private _store: Store<any>
  ) { }
  ngOnInit() {
    if(this.data.layer && this.data.layer._source) {
      this.layerId = this.data.layer._source.layer_id;
      this.layerName = this.data.layer._source.name || null;
      this.layerType = this.data.layer._source.type || null;
      const sheets = JSON.parse(this.data.layer._source.attribute_types);
      if(sheets.length) {
        this.sheetNames = sheets.filter(sheet => sheet.sheet_name).map(sh => sh.sheet_name) || [];
      }
      let payload = _.cloneDeep(DEFAULT_PREVIEW_PAYLOAD);
      payload.layerId = this.layerId;
      if(!this.sheetNames.length) {
        this._store.dispatch(new GetDataLibraryLayerDetailsStartAction(payload));
      } else if(this.sheetNames.length) {
        this.selectedSheetName = this.sheetNames[0];
        payload.sheetName = this.selectedSheetName;
        this._store.dispatch(new GetDataLibraryLayerDetailsStartAction(payload));
      }
    }
  this.subscribtionArr.push(this._store.select(layerPreviewDataSelector).subscribe((res: any) => {
      if(res.attributes && res.attributes.length && res.data && res.data.length) {
        this.attributesNameAndType = res.attributes;
        this.displayedColumns = res.attributes.map(attr => attr.name);
        this.displayedColumnsType = this.attributesNameAndType.reduce((acc, curr) => {acc[curr.name] = curr.type; return acc}, {});
        this.dataSource = res.data;
      }
    }))
    this.subscribtionArr.push(this._store.select(dataPreviewLayerSelector).subscribe((res: any) => {
      if(res && Object.keys(res).length) {
        this.previewData = res;
        console.log(this.previewData)
        this.previewData.bounding_box = this.previewData.bounding_box.reduce((acc, cur) => {
          return [...acc, this.sessionService.toDecimalString(cur, 5)];
        }, [])
        let data = []
        // for (let i = 0; i < res.sampleData.length; i++) {
        //   data.push(res.sampleData[i])
        // }
      }
    }))
  }

  setSelectedSheet(event) {
    this.selectedSheetName = event.value;
    const payload = _.cloneDeep(DEFAULT_PREVIEW_PAYLOAD);
    payload.layerId = this.layerId;
    payload.sheetName = this.selectedSheetName;
    this._store.dispatch(new GetDataLibraryLayerDetailsStartAction(payload));
    this.tableContainer.nativeElement.scrollTop = 0;
  }

  scrollRight() {
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft + 600), behavior: 'smooth' });
  }

  scrollLeft() {
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft - 600), behavior: 'smooth' });
  }

  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}



const DEFAULT_PREVIEW_PAYLOAD = {
  layerId: null,
  sheetName: null
}
