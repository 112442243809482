import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/data-approval-management.reducer';

export const dataApprovalManagementFeatureSelector = createFeatureSelector<State>("dataApproval");


export const layerTableDataSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerTableData.data || [])
  )
  
  export const totalLayerPagesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerTableData.totalPages || 0)
  )
  
  export const totalRecordsSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerTableData.totalRecords || 0)
  )

  export const layerTableAttributesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerTableData.templateProperties || [])
  )
  
 

  export const layerAttributesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerAttributes || [])
  )


  


  export const layerPremiseTableDataSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerPremiseTableData.data || [])
  )
  
  export const totalLayerPremisePagesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerPremiseTableData.totalPages || 0)
  )
  
  export const totalPremiseRecordsSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerPremiseTableData.totalRecords || 0)
  )
  
  export const layerPremiseTableAttributesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerPremiseTableData.templateProperties || [])
  )

  export const premiseFloorNumberSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerPremiseTableData.floorNumbers || [])
  )
  
  export const layerPremiseAttributesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.layerPremiseAttributes || [])
  )

  export const buildingDataSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.buildingsData || [])
  )

  export const buildingFeatures = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.featureDetails || [])
  )

  export const featureSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.features || [])
  )

  export const premisesSelector = createSelector(
    dataApprovalManagementFeatureSelector,
    state => (state.premises || [])
  )
