import { Output, EventEmitter } from "@angular/core";
export class SharedService {
    mapLayers: Array<any>;
    activeLegends: Array<any> = [];
    stateBoundaryLayer: any;
    districtBoundaryLayer: any;
    talukaBoundaryLayer: any;
    ExportLegends: Array<any> = [];

    @Output() mapLayersObserver: EventEmitter<any> = new EventEmitter();
    constructor() {}

    getMapLayers() {
        return this.mapLayersObserver;
    }

    setMapLayers(layers: Array<any>) {
        this.mapLayers = layers;
        this.mapLayersObserver.emit(layers);
        return this.mapLayersObserver;
    }
}
