import {Action} from '@ngrx/store'




export enum ProjectManagementActionTypes {
    GET_PROJECT_LIST_START = "[project management] Get Project -List  Start",
    GET_PROJECT_LIST_SUCCESS = "[project management] Get Project-list  Success",
    ADD_PROJECT_START ="[project management] Add project start",
    ADD_PROJECT_SUCCESS ="[project management] Add project success",
    UPDATE_PROJECT_START ="[project management] Update project start",
    UPDATE_PROJECT_SUCCESS ="[project management] Update project success",
    GET_PROJECT_DETAILS_START ="[project management] Get Project Details start",
    GET_PROJECT_DETAILS_SUCCESS ="[project management] Get project details success",
    GET_surveyor_DETAILS_START ="[project management] get seuveyors start",
    GET_surveyors_DETAILS_SUCCESS ="[Project managment] get surveyors success",
    SAVE_PARENT_FORM ="[Project management] save parent form",
    RemovEprojectDetail ="[project managemenet] reove project Detail"
  }


  export class GetProjectListStart implements Action{
      readonly type: ProjectManagementActionTypes =ProjectManagementActionTypes.GET_PROJECT_LIST_START;
      constructor(public  payload) {

      }
  }
  export class GetProjectListSuccess implements Action{
      readonly type :ProjectManagementActionTypes =ProjectManagementActionTypes.GET_PROJECT_LIST_SUCCESS;
      constructor(public response) {  }
  }
  export  class AddProjectStart implements Action {
      readonly type: ProjectManagementActionTypes= ProjectManagementActionTypes.ADD_PROJECT_START;
      constructor(public payload) {}
  }
  export class AddProjectSuccess implements Action {
      readonly type: ProjectManagementActionTypes =ProjectManagementActionTypes.ADD_PROJECT_SUCCESS;
      constructor(public response) {}
  }
  export class Updateprojectstart implements Action {
      readonly  type : ProjectManagementActionTypes =ProjectManagementActionTypes.UPDATE_PROJECT_START;
      constructor(public payload, public id: string) {}
  }
  export class UpdateProjectSuccess implements Action {
      readonly type : ProjectManagementActionTypes =ProjectManagementActionTypes.UPDATE_PROJECT_SUCCESS;
      constructor(public response) {}
  }
  export class GetProjectDetailsstart implements Action {
    readonly type : ProjectManagementActionTypes =ProjectManagementActionTypes.GET_PROJECT_DETAILS_START;
    constructor(public id) {}
} 
export class GetProjectDetailsSuccess implements Action {
    readonly type: ProjectManagementActionTypes =ProjectManagementActionTypes.GET_PROJECT_DETAILS_SUCCESS;
    constructor(public response) {}
}
export class GetSurveyorsDetailsStart implements Action {
    readonly type: ProjectManagementActionTypes =ProjectManagementActionTypes.GET_surveyor_DETAILS_START;
    constructor(public payload) {}
}
export class GetSurveyorsDetailsSuccess implements Action {
    readonly type: ProjectManagementActionTypes =ProjectManagementActionTypes.GET_surveyors_DETAILS_SUCCESS
    constructor(public response) {}
}
export class SaveParentForm implements Action {
    readonly type : ProjectManagementActionTypes =ProjectManagementActionTypes.SAVE_PARENT_FORM;
    constructor(public response?) {}
}
export class RemoveProjectDetailAction implements Action {
    readonly  type :ProjectManagementActionTypes =ProjectManagementActionTypes.RemovEprojectDetail;
    constructor (public response ?) {}
}

  export type ProjectManagementSuccesActions = GetProjectListSuccess | AddProjectSuccess | GetProjectDetailsSuccess |  GetSurveyorsDetailsSuccess | SaveParentForm | RemoveProjectDetailAction