
import { Component, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter, Inject } from '@angular/core'
import { Subject, combineLatest, Observable, fromEvent } from 'rxjs'
import { ActivatedRoute } from '@angular/router';
import { ResetStateForFormsAction } from 'src/app/store/actions/data-management.actions';
import { Store } from '@ngrx/store';
import { templatesSelector } from 'src/app/store/selectors/data-management.selector';
import { takeUntil, tap, buffer, throttleTime, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { projectDetailSelector, projectListSelector, surveyorsListSelector } from 'src/app/store/selectors/project-management.selector';
import { GetProjectDetailsstart, GetProjectListStart, GetSurveyorsDetailsStart } from 'src/app/store/actions/project-management.actions';
import { Location } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Storeservice } from 'src/app/_services/store.services';
import _ from "lodash";


@Component({
    selector: 'select-forms-surveyor',
    styleUrls: ['./select-forms-surveyors.component.scss'],
    templateUrl: './select-forms-surveyors.component.html',
})
export class SelectFormsSurveyorsDialogComponent implements OnDestroy {
    stopper$: Subject<any> = new Subject();
    label: string = '';
    formType: string = '';
    serchSelected : boolean = false;
    allDataCheck: Boolean = false;
    id: string = '';
    buttonText: string = 'ADD FORM(S)'
    currentPageNumber: number = 1;
    totalPages: number = 0;
    searchString: string = '';
    searchInput: FormControl = new FormControl('')
    searchInput$: Observable<any>;
    scrolled: boolean = false;
    allList: any[] = [];
    _selectedList: Array<any> = []
    _selectedListIds: Array<any> = [];
    allListToDisplay: Array<any> = [];

    constructor(private _store: Store<any>, public location: Location,
        public dialogRef: MatDialogRef<SelectFormsSurveyorsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, public storeService: Storeservice) {
        dialogRef.disableClose = true;

    }
    ngOnInit() {
        if (Object.keys(this.data.formData).length) {

            this._selectedList = (this.data.formData);
            this._selectedListIds = this._selectedList.map((list: any) => list._id);

        }

        if (this.data.formType === 'surveyors') {
            this.label = "SELECT SURVEYOR(S)";
            this.formType = 'surveyors'
            this.buttonText = 'ADD SURVEYOR(S)';

            this._store.dispatch(new GetSurveyorsDetailsStart({ search: '', page: 1 }))
        } else {
            this.label = "SELECT PROJECT(S) TO ASSIGN";
            this.formType = 'projects';
            this._store.dispatch(new GetProjectListStart({ 'search': '', 'page': 1 }));
            this.buttonText = "ASSIGN PROJECT (S)";
        }



        const allSurveyerSelector$: Observable<any> = this._store.select(surveyorsListSelector)

        const projectListSelector$: Observable<any> = this._store.select(projectListSelector);


        combineLatest(allSurveyerSelector$, projectListSelector$).pipe(takeUntil(this.stopper$)).subscribe(([allSurveyerSelectorResponse, projectListSelectorResponse]) => {
            switch (this.data.formType) {
                case 'surveyors':
                    this.currentPageNumber = 0
                    if (Object.keys(allSurveyerSelectorResponse).length) {
                        this.allList = _.cloneDeep(allSurveyerSelectorResponse.Docs);
                        this.currentPageNumber = parseInt(allSurveyerSelectorResponse.page);
                        this.totalPages = parseInt(allSurveyerSelectorResponse.totalPages);
                        let copyOfAllList = _.cloneDeep(allSurveyerSelectorResponse.Docs);
                        this.displayData(copyOfAllList);
                    }
                    break;
                case 'projects':
                    this.currentPageNumber = 0;
                    if (Object.keys(projectListSelectorResponse).length) {
                        this.currentPageNumber = projectListSelectorResponse.page;
                        this.totalPages = +projectListSelectorResponse.totalPages;
                        this.allList = _.cloneDeep(projectListSelectorResponse.docs)
                        let copyOfAllList = _.cloneDeep(projectListSelectorResponse.docs);
                        this.displayData(copyOfAllList)
                    }
                    break;
            }
        })
        this.searchInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
            if(val){
                this.serchSelected = true;
              }
              else{
                this.serchSelected = false;
              }
            this.searchString = val.trim();
            this.allDataCheck = false

            if (this.formType === 'projects') {

                this._store.dispatch(new GetProjectListStart({ search: val.trim(), 'page': 1 }))
            } else if (this.formType == 'surveyors') {

                this._store.dispatch(new GetSurveyorsDetailsStart({ 'search': this.searchString, 'page': 1 }))
            }
        })
    }
    checkUncheck(event, index) {
        this.allListToDisplay[index].checked = event.checked;
        if (event.checked) {
            this._selectedListIds.push(this.allListToDisplay[index]._id);
            this._selectedList.push(this.allListToDisplay[index]);
        }
        else {
            let Index = this._selectedListIds.findIndex((id) => id === this.allListToDisplay[index]._id);
            if (Index !== -1) {
                this._selectedListIds.splice(Index, 1);
                this._selectedList.splice(Index, 1);
            }
        }

        this.allDataCheck = (this.allListToDisplay.length) ? this.allListToDisplay.every((eachList) => (eachList.checked === true)) : false


    }
    selectAll(event) {
        this.allDataCheck = event.checked;

        if (event.checked) {
            if (!this.searchString.length) {

                this.allListToDisplay = this.allListToDisplay.filter((eachList, index) => {
                    eachList.checked = true;
                    return eachList
                })
                this._selectedList = [...this.allListToDisplay];
                this._selectedListIds = this.allListToDisplay.map(({ _id }) => _id);

            }
            else {
                this.allListToDisplay.forEach((eachList, index) => {
                    if (!this._selectedListIds.includes(eachList._id)) {
                        this.allListToDisplay[index].checked = true;
                        this._selectedList.push(eachList)

                    }

                })

            }
        }
        else {

            if (!this.searchString.length) {

                this.allListToDisplay = this.allListToDisplay.map((eachList) => {
                    eachList['checked'] = false;
                    return eachList
                })
                this._selectedList = [];
            }
            else {
                this.allListToDisplay = this.allListToDisplay.map((eachList) => {
                    eachList['checked'] = false;
                    return eachList

                })

                this.allListToDisplay.forEach((form) => {
                    if (this._selectedListIds.includes(form._id)) {
                        let Index = this._selectedList.findIndex((Form: any) => Form._id === form._id);
                        if (Index !== -1) {

                            this._selectedList.splice(Index, 1);
                        }
                    }
                })

            }

        }
        this._selectedListIds = [...this._selectedList.map((eachList: any) => eachList._id)]
    }
    cancelClicked() {

        this.dialogRef.close('');
    }

    addFormClicked() {
        this._selectedList = this._selectedList.map((eachList) => {
            delete eachList['checked'];
            return eachList
        })
        this.dialogRef.close([...this._selectedList]);

    }
    onScroll(event) {
        this.scrolled = true;
        const tableViewHeight = event.target.offsetHeight; // viewport: ~500px
        const tableScrollHeight = event.target.scrollHeight; // length of all table
        const scrollLocation = event.target.scrollTop; // how far user scrolled
        // If the user has scrolled within 200px of the bottom, add more data
        const buffer = 30;
        const limit = tableScrollHeight - tableViewHeight - buffer;
        if (scrollLocation >= limit) {
            if (this.currentPageNumber < this.totalPages) {
                this.currentPageNumber++;
                if (this.formType == 'projects') {
                    this._store.dispatch(new GetProjectListStart({ 'search': this.searchString, 'page': this.currentPageNumber, 'limit': 10 }));

                } else if (this.formType === 'surveyors') {

                    this._store.dispatch(new GetSurveyorsDetailsStart({ 'search': this.searchString, 'page': this.currentPageNumber }))
                }
            }

        }
    }


    displayData(data) {
        if (data === undefined) return
        this.allListToDisplay = data.map((form) => {
            let checked: boolean = false;
            if (this.scrolled) {

                if (this.allDataCheck) {

                    checked = true
                }
                else {
                    checked = this._selectedListIds.includes(form._id);
                }
            }
            else {
                checked = this._selectedListIds.includes(form._id);
            }
            return ({ ...form, checked })
        });


        if (this.scrolled && this.allDataCheck) {

            this.allList.forEach((eachList) => {
                let index = this._selectedListIds.findIndex(id => id === eachList._id)
                if (index === -1) {
                    this._selectedListIds.push(eachList._id);
                    this._selectedList.push(eachList)
                }
            })
        }
        this.allListToDisplay.forEach((eachList) => {
            if (eachList.checked) {
                let index = this._selectedListIds.findIndex(index => index === eachList._id);
                if (index === -1) {
                    this._selectedList.push(eachList);
                    this._selectedListIds.push(eachList._id)
                }
            }
        });
        this.handleAllDataCheck();
        this._selectedListIds = this._selectedList.map((eachList) => eachList._id)
    }
    crossClicked(){
        this.serchSelected = !this.serchSelected; 
        this.searchInput.setValue('');
      }
    handleAllDataCheck() {

        if (this.allList.length) {
            this.allDataCheck = this.allListToDisplay.every((form) => form.checked === true);
        } else {
            this.allDataCheck = false;
        }

    }







    ngOnDestroy() {
        this.stopper$.next();
        this.stopper$.complete();
    }
}