import { Component, OnInit ,Inject, ElementRef, ViewChild} from '@angular/core';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GetDataLibraryDataStarAction, GetGroupLayersStartAction, GetGroupNamesStartAction, GetVectorListInfoStartAction } from 'src/app/store/actions/data-management.actions';
import { groupLayersSelector, groupNamesSelector, joinLayersListSelector, vLayersListSelector } from 'src/app/store/selectors/data-management.selector';
import { FieldSelectionDialog } from '../field-selection-dialog/field-selection-dialog.component';
import {
  GROUP_LAYERS_LIST_PARAMS, GROUP_LIST_PARAMS,
  LAYER_ACTIVE_ICONS, LIBRARY_SEARCH_PARAMS,
  SELECTED_LAYER_TYPE_FOR_LIBRARY
} from 'src/app/app.constants';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash';
import { LayerService } from 'src/app/_services';
@Component({
  selector: 'app-data-layer-selection',
  templateUrl: './layer-selection-dialog.component.html',
  styleUrls: ['./layer-selection-dialog.component.scss']
})
export class LayerSelectionDialog implements OnInit {
  subscriptionArray: Array<Subscription> = [];
  page: number = 0;
  totalPages: number = 0;
  internalPage: number = 0;
  totalInternalPages: number = 0;
  dataList: Array<any> = [];
  groupList: Array<any> = [];
  groupLayersList: Array<any> = [];
  searchField: FormControl = new FormControl("");
  searchValue: string = "";
  selectedLayer: any = null;
  layerIcons = LAYER_ACTIVE_ICONS;
  hasError: boolean = false;
  showDataFrom: string = 'your-data';
  @ViewChild('lList') lListRef: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<LayerSelectionDialog>,
    private dialog: MatDialog,
    private _store: Store<any>,
    private layerservice: LayerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.getGroupAndLayers();
    this.subscriptionArray.push(
      this._store.select(groupNamesSelector).subscribe((data: Array<any>) => {
        this.groupList = data;
      })
    );
    this.subscriptionArray.push(
      this._store.select(vLayersListSelector).subscribe((vList: any) => {
        if(vList.layers.length) {
          this.page = vList.page;
          this.totalPages = vList.totalPages;
          this.dataList = vList.layers;
        } else {
          this.page = 0;
          this.totalPages = 0;
          this.dataList = [];
        }
      })
    );
    this.subscriptionArray.push(
      this._store.select(groupLayersSelector).subscribe((gList: any) => {
        if(gList.data.length) {
          this.internalPage = gList.page;
          this.totalInternalPages = gList.totalPages;
          this.groupLayersList = gList.data;
        } else {
          this.internalPage = 0;
          this.totalInternalPages = 0;
          this.groupLayersList = [];
        }
      })
    );
    this.subscriptionArray.push(
      this._store.select(joinLayersListSelector).subscribe((vList: any) => {
        if(vList.data.length) {
          this.page = vList.page;
          this.totalPages = vList.totalPages;
          this.dataList = vList.data;
        } else {
          this.page = 0;
          this.totalPages = 0;
          this.dataList = [];
        }
      })
    )
    this.subscriptionArray.push(
      this.searchField.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(val => {
        this.searchValue = val.trim();
        this.getGroupAndLayers();
      })
    )
  }

  getGroupAndLayers() {
    if(this.showDataFrom === 'your-data') {
      this.getGroups();
    }
    this.getLayers();
  }

  getGroups() {
    let params = _.cloneDeep(GROUP_LIST_PARAMS);
    params.search = this.searchValue;
    this._store.dispatch(new GetGroupNamesStartAction(params));
  }

  getLayers(loadMore = false) {
    if(this.showDataFrom === 'your-data') {
      let payload = _.cloneDeep(LAYERS_LIST_SEARCH_PARAMS);
      payload.search = this.searchValue;
      if(loadMore) payload.page = this.page + 1;
      this._store.dispatch(new GetVectorListInfoStartAction(payload));
    } else {
      let params = _.cloneDeep(LIBRARY_SEARCH_PARAMS);
      params.layerType = SELECTED_LAYER_TYPE_FOR_LIBRARY['vector'];
      params.search = this.searchValue;
      if(loadMore) params.page = this.page + 1;
      this._store.dispatch(new GetDataLibraryDataStarAction(params));
    }
  }

  radioSelectionChanged(event) {
    this.showDataFrom = event.value;
    if(this.showDataFrom === 'data-library') {
      this.groupList = [];
      this.groupLayersList = [];
      this.internalPage = 0;
      this.totalInternalPages = 0;
    }
    this.lListRef.nativeElement.scrollTop = 0;
    if(this.searchValue) {
      this.searchField.setValue("");
    } else {
      this.getGroupAndLayers();
    }
  }

  getGroupLayers(groupName, loadMore = false) {
    let params = _.cloneDeep(GROUP_LAYERS_LIST_PARAMS);
    params.search = this.searchValue;
    params.groupName = groupName;
    if(loadMore) params.page = this.internalPage + 1;
    this._store.dispatch(new GetGroupLayersStartAction(params))
  }

  openAccordion(groupName){
    this.getGroupLayers(groupName);
  }

  setSelectedLayer(data) {
    this.selectedLayer = data;
    this.hasError = false;
  }

  openFieldSelectionDialog() {
    if(!this.selectedLayer) {
      this.hasError = true;
      return;
    }
    const data = {
      selectedLayer: this.selectedLayer,
      selectedLibrary: this.data,
      dataType: this.showDataFrom === 'data-library' ? 'library': 'your-data',
      type: 'tabular-vector'
    }
    const filedSelectionDialog = this.dialog.open(FieldSelectionDialog, {
      hasBackdrop: true,
      width: '800px',
      disableClose: true,
      data
    })
    filedSelectionDialog.afterClosed().subscribe(_ => {});
    this.close(false);
  }

  onInternalScroll(event, groupName) {
    if (this.shouldLoadMore(event)) {
      setTimeout(() => {
        this.loadMoreGroupLayers(groupName);
      }, 200);
    }
  }

  loadMoreGroupLayers(groupName) {
    if (this.internalPage >= this.totalInternalPages) return;
    this.getGroupLayers(groupName, true);
  }

  onScroll(event) {
    if (this.shouldLoadMore(event)) {
      setTimeout(() => {
        this.loadMore();
      }, 200);
    }
  }

  shouldLoadMore(event) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;
    const buffer = 0;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    return scrollLocation >= limit;
  }

  loadMore() {
    if (this.page >= this.totalPages) return;
    this.getLayers(true);
  }

  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.subscriptionArray.length) {
      this.subscriptionArray.map(sub => sub.unsubscribe());
      this.subscriptionArray = [];
    }
  }
}

export const LAYERS_LIST_SEARCH_PARAMS = {
  search: "",
  page : 1,
  limit :10
}

