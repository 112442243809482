import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogContent } from '@angular/material';
import { Store } from '@ngrx/store';
import { DataManagementActionTypes, DeleteTabularDataStartAction, DeleteTemplateStartAction } from 'src/app/store/actions/data-management.actions';
import { DeleteSessionStartAction, DeleteProcessingStartAction, DeleteNotificationStartAction, SessionManagementTypes } from 'src/app/store/actions/session-management.actions';
import { deleteSessionSelector, notificationDeleteSelector } from 'src/app/store/selectors/session-management.selector';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SessionService } from 'src/app/_services/session.service';
import { SocketService } from 'src/app/_services/socket.service';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-delete-sessions-dialog',
  templateUrl: './delete-sessions-dialog.component.html',
  styleUrls: ['./delete-sessions-dialog.component.scss']
})
export class DeleteSessionsDialogComponent implements OnInit, OnDestroy{
  deleteButtonClicked: boolean = false;
  subscriptionArr: any[] =[];
  constructor(public dialogRef: MatDialogRef<DeleteSessionsDialogComponent>,
    private _store: Store<any>,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) public data: any, public sessionService: SessionService, public socketService: SocketService
  ) { }
  ngOnDestroy() {
    this.subscriptionArr.map(sub => sub.unsubscribe());
    this.subscriptionArr =null;
  }

  ngOnInit() {
    this.subscriptionArr.push(this._store.select(deleteSessionSelector).pipe(tap((resp) => {
      if (resp && (resp.status == 'success') && (this.deleteButtonClicked)) {
        this.dialogRef.close({ value: 'DELETE' })
      }
    })).subscribe());
    
   this.subscriptionArr.push( this._store.select(notificationDeleteSelector).subscribe((resp) => {
      if(resp && Object.keys(resp).length && this.deleteButtonClicked) {
        this.dialogRef.close({value: 'DELETE'});
        this.deleteButtonClicked =true;
      }
    }))

    this.subscriptionArr.push(
      this.actions$.pipe(
        ofType(
          DataManagementActionTypes.deleteTemplateSuccess,
          SessionManagementTypes.deleteSessionSuccess,
          SessionManagementTypes.processingLayerDeleteSuccess,
          DataManagementActionTypes.deleteTabularDataSuccess
        ), map((response: any) => {
          if (this.data.type === "vector_layer") {
            this.dialogRef.close({ value: "DELETE", type: this.data.title });
          } else {
            this.dialogRef.close({value: 'DELETE'});
          }
        })
      ).subscribe()
    );
  }
  close(event) {
    if (event) {
      if (this.data.type === "vector_layer") {
        this._store.dispatch(new DeleteTemplateStartAction(this.data.id));
      } else if (this.data.type === "map_session") {
        this.deleteButtonClicked = true;
        this._store.dispatch(new DeleteSessionStartAction(this.data.id))
      } else if (this.data.type === 'processing_layer') {
        this._store.dispatch(new DeleteProcessingStartAction({ session_id: this.data.sessionId, layer_id: this.data.id }))
      } else if (this.data.type === 'tabular_data') {
        this._store.dispatch(new DeleteTabularDataStartAction(this.data.id));
      } else if(this.data.type === 'global-notification') {
        this.deleteButtonClicked =true;
        this.dialogRef.close({ value: 'DELETE' });
      }
    }
    else {
      this.dialogRef.close({ value: "CANCEL" });
    }
  }
}