import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'slope-dialog',
  styleUrls: ['./slope-dialog.component.scss'],
  templateUrl: './slope-dialog.component.html'
})

export class SlopeDialogComponent implements OnInit {
  rasterFile = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  confirmButtonClicked() {

  }
}