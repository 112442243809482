import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ADVANCED_TOOL_TYPE, LAYER_ACTIVE_ICONS, LAYER_INACTIVE_ICONS } from "src/app/app.constants";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";
import { SessionService } from "src/app/_services/session.service";
import { cloneDeep } from "lodash";
import { Store } from "@ngrx/store";
import { GetAdvancedToolsSearchResultStartAction, GetAdvancedToolsSearchResultSuccessAction, GetLayerAttributesStartAction, SessionManagementTypes } from "src/app/store/actions/session-management.actions";
import { combineLatest, Subscription } from "rxjs";
import { layerAttributesSelector, propertiesTypeSelector, regressionDataSelector } from "src/app/store/selectors/session-management.selector";
import { Actions, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { RegressionSummaryDialog } from "../regression-summary-dialog/regression-summary-dialog.component";

@Component({
  selector: 'app-regression-ols-dialog',
  templateUrl: './regression-ols-dialog.component.html',
  styleUrls: ['./regression-ols-dialog.component.scss']
})

export class RegressionOLSDialog {
  layersList: Array<any> = [];
  selectedLayer: any = null;
  subscriptionArr: Array<Subscription> = [];
  dependentAttributesList: Array<string> = [];
  explanatoryAttributesList: Array<string> = [];
  filteredDepAttrList: Array<string> = [];
  filteredExpAttrList: Array<string> = [];
  selectedDependentAttribute: string = null;
  selectedExplanatoryAttributes: Array<string> = [];
  selectedToolIndex: number = null;
  layerActiveIcons = LAYER_ACTIVE_ICONS;
  layerInactiveIcons = LAYER_INACTIVE_ICONS;
  hasDependentAttrSelectionErr: boolean = false;
  hasExplanatoryAttrSelectionErr: boolean = false;
  layerSelectionCtrl: FormControl = new FormControl(null, [Validators.required]);
  sessionId: string = null;
  showRegressionSummary: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<RegressionOLSDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl,
    private _store: Store<any>,
    private actions: Actions,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedToolIndex = this.data.selectedToolIndex;
    this.layersList = this.sessionService.sessionLayers.filter((layer: any) => [1].includes(layer.layer_type) && layer.show);
    this.sessionId = this.sessionService.sessionId;
    let attrs$ = this._store.select(layerAttributesSelector);
    let attrsType$ = this._store.select(propertiesTypeSelector);
    this.subscriptionArr.push(
      combineLatest(attrs$, attrsType$).pipe().subscribe(([properties, propertyTypes]) => {
        let attributesList: Array<string> = [];
        attributesList = properties.filter(property => propertyTypes[property] === "Number");
        // properties.map(property => {
        //   if(propertyTypes[property] === "Number") {
        //     attributesList.push({name: property, type: propertyTypes[property], selected: false})
        //   }
        // })
        this.dependentAttributesList = cloneDeep(attributesList);
        this.explanatoryAttributesList = cloneDeep(attributesList);
        this.filteredDepAttrList = this.dependentAttributesList;
        if(this.selectedDependentAttribute) {
          let expList = cloneDeep(this.explanatoryAttributesList);
          expList.splice(this.explanatoryAttributesList.findIndex(att => att === this.selectedDependentAttribute), 1);
          this.filteredExpAttrList = expList;
        } else {
          this.filteredExpAttrList = this.explanatoryAttributesList;
        }
      })
    );
    this.subscriptionArr.push(
      this.layerSelectionCtrl.valueChanges.subscribe((value: any) => {
        this.selectedLayer = value;
        this._store.dispatch(new GetLayerAttributesStartAction(this.selectedLayer.parent_layer_id));
        this.selectedDependentAttribute = null;
        this.selectedExplanatoryAttributes = [];
      })
    );
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        tap((action: GetAdvancedToolsSearchResultSuccessAction) => {
          this.navigationService.setShowLayerTable(false);
          // this.navigationService.setShowLayerTable(true);
          // this.sessionService.setShowAdvancedToolsSelectionData(true);
          // this.dialogRef.close();
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this._store.select(regressionDataSelector).subscribe((data: any) => {
        this.showRegressionSummary = Object.keys(data.summary).length && data.table.length;
        if(this.showRegressionSummary) {
          this.showSummary();
        }
      })
    );
  }

  showSummary() {
    const summaryDialog = this.dialog.open(RegressionSummaryDialog, {
      width: `800px`,
      hasBackdrop: true,
      disableClose: true,
      data: {}
    });
    summaryDialog.afterClosed().subscribe((res) => {
      if(!res) this.dialogRef.close();
    })
  }

  selectDependentAttr(attr) {
    if(this.selectedDependentAttribute === attr) this.selectedDependentAttribute = null;
    else this.selectedDependentAttribute = attr;
    if(this.selectedDependentAttribute) {
      let expList = cloneDeep(this.explanatoryAttributesList);
      expList.splice(this.explanatoryAttributesList.findIndex(att => att === this.selectedDependentAttribute), 1);
      this.filteredExpAttrList = expList;
      this.selectedExplanatoryAttributes.splice(this.selectedExplanatoryAttributes.findIndex(att => att === this.selectedDependentAttribute), 1);
    }
  }

  selectExplanatoryAttr(attr) {
    if(this.selectedExplanatoryAttributes.includes(attr)) {
      this.selectedExplanatoryAttributes.splice(this.selectedExplanatoryAttributes.findIndex(attribute => attribute === attr), 1);
    } else {
      this.selectedExplanatoryAttributes.push(attr);
    }
  }

  apply() {
    const payload = {
      input_layer_1:{
        layer_id: this.selectedLayer.parent_layer_id,
        type: this.selectedLayer.type
      },
      input_params:{
        "dependentVariable": this.selectedDependentAttribute,
        "explanatoryVariable": [...this.selectedExplanatoryAttributes]
      },
      feature_type: ADVANCED_TOOL_TYPE[this.selectedToolIndex],
      selection_type: "all",
      query: {limit: 100, page: 1}
    }
    this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  enableRun() {
    return this.selectedLayer && Object.keys(this.selectedLayer).length && this.selectedDependentAttribute && this.selectedExplanatoryAttributes.length
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }
}