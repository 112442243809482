import { API } from "src/app/_services";

import { Injectable } from "@angular/core";
import connProp from "src/assets/connection.properties.json";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from "../../environments/environment";
import { share } from 'rxjs/operators';
import { SessionService } from './session.service';
import { cloneDeep } from 'lodash'
import { APIKEY_HERE, RASTER_TOOL_TYPE } from '../app.constants';
import { Store } from '@ngrx/store';
import { SocketService } from './socket.service';
import { ActivatedRoute } from "@angular/router";

import {CopyLayerToSectionRequest, MoveLayerToSectionRequest} from '../store/modals/copy-layer-to-section';
@Injectable()
export class Storeservice {
  isValid: boolean = false;
  setNote: string = '';
  uploadedCsvFile: any;
  userId: string = '';
  newMessages: number = 0;
  getProfilePic() {
    throw new Error("Method not implemented.");
  }
  email;
  username: any;
  password: any;
  emaiIdInForgotPassword: any;
  obj: any;
  connprops = connProp;
  userdataSource: Array<any> = [];
  user_role: any;
  user_role_id: any;
  profilePic: BehaviorSubject<string> = new BehaviorSubject("");


  constructor(private api: API, public http: HttpClient, public sessionService: SessionService,
    public _store: Store<any>, public socketService: SocketService,private activatedRouter : ActivatedRoute) { }
  ngOnInit() { }
  dataFetch(obj1, obj2?: any) {
    this.email = obj1.email;
    this.username = obj1.name;
    this.password = obj1.password;
  }

  dataFetchFromForgotPassword(obj) {
    this.emaiIdInForgotPassword = obj.email;
  }

  getUsersData(tableData?: any) {
    const suburl = this.connprops.getUsers;
    return this.api.getNew(suburl, true, false, tableData);
  }

  getUserDetails(suburl: string) {
    return this.api.getNew(suburl, true);
  }

  deleteUser(suburl: string) {
    return this.api.getNew(suburl, true);
  }

  addUpdateUser(payload: any) {
    return this.api.postNew(payload, true);
  }
  profileUpdateUser(payload: any) {
    return this.api.postNew(payload, true);
  }
  changeUserPassword(payload: any) {
    return this.api.postNew(payload, true)
  }

  clearFileStorage(payload:any){
    const suburl = '/notifications/clear?webCall=true'
    console.log("May 22 test")
    return this.api.getNew(suburl,true)
  }

  //function to call v3 api to delete all notifications
  clearFileStorageV3(payload:any){
    const suburl = '/notifications/clear?webCall=true'
    return this.api.getNewV3(suburl,true)
  }

  clearFileStorageSingle(payload:any){
    const suburl = '/notifications/delete/'+payload+'?webCall=true'
    return this.api.getNew(suburl,true)
  }

  //function to call v3 api to delete single notification
 ClearFileStorageActionSingleV3(payload:any){
  const suburl = '/notifications/delete/'+payload+'?webCall=true'
    return this.api.getNewV3(suburl,true)
  }



  getUserAndSurveyers(template_id: string) {
    const suburl = `${this.connprops.getUsersAndSurveyers}/${template_id}`;
    return this.api.getNew(suburl, true);
  }

  getAllTemplates(payload: any, status: boolean = false) {
    if (status) {
      return this.api.getNew(this.connprops.getAllTemplates, true, false, { ...payload, status: true });
    } else {
      return this.api.getNew(this.connprops.getAllTemplates, true, false, payload);

    }
  }

  getTemplateDetails(template_id: string) {
    const suburl = `${this.connprops.getTemplateDetails}${template_id}?urlData=true`;
    return this.api.getNew(suburl, true);
  }

  getWidgetsList() {
    return this.api.getNew(this.connprops.getWidgetsList, true);
    // return of(widgets);
  }

  createUpdateTemplate(payload: any) {
    return this.api.postNewForm(payload, true);
  }

  assignUsersToTemplate(payload: any) {
    return this.api.postNew(payload, true);
  }

  /* All Session Related Api Request To Start From Here */

  getSessions(payload) {
    const suburl = this.connprops.getSessions;
    return this.api.getNew(suburl, true, false, payload);
  }

  getSessionDetails(session_id: string) {
    const suburl = `${this.connprops.getSessionDetails}${session_id}`;
    return this.api.getNew(suburl, true);
  }

  createSession() {
    const suburl = this.connprops.createSession;
    return this.api.getNew(suburl, true, false);
  }

  updateSession(session_id: string, datum: any) {
    console.trace()
    console.log("April 27"+JSON.stringify(datum))
    const suburl = `${this.connprops.updateSession}${session_id}`;
    const payload = {
      subURL: suburl,
      datum
    }
    return this.api.postNew(payload, true, { nonBlocking: true });
  }

  addLayers(payload: any) {
    return this.api.postNew(payload, true);
  }

  copyLayer(session_id: string, datum: any) {
    const suburl = `${this.connprops.copylayer}${session_id}`;
    const payload = {
      subURL: suburl,
      datum
    }
    return this.api.postNew(payload, true);
  }

  deleteSession(session_id: string) {
    const suburl = `${this.connprops.deleteSession}${session_id}`;
    return this.api.getNew(suburl, true);
  }
  deleteVectorLayer(_id: string) {
    const suburl = `${this.connprops.deleteVectorLayer}${_id}`;
    return this.api.getNew(suburl, true);
  }

  copySession(session_id: string) {
    const suburl = `${this.connprops.copySession}${session_id}`;
    return this.api.getNew(suburl, true);
  }

  privatizeSession(session_id: string) {
    const suburl = `${this.connprops.makePrivateSession}${session_id}`;
    return this.api.getNew(suburl, true);
  }

  getAllUsers(session_id: string) {
    const suburl = `${this.connprops.allUsersList}${session_id}`;
    return this.api.getNew(suburl, true);
  }

  getAllSurveyors() {
    return this.api.getNew(`${this.connprops.allSurveyorsList}`, true, false);
  }

  shareSession(session_id: string, datum: any) {
    const suburl = `${this.connprops.shareSession}${session_id}`;
    const payload = {
      subURL: suburl,
      datum
    }
    return this.api.postNew(payload, true);
  }

  getAssignedUsers(session_id: string) {
    const suburl = `${this.connprops.assignedUsers}${session_id}`;
    return this.api.getNew(suburl, true);
  }

  getAssignedSurveyors(session_id: string) {
    const suburl = `${this.connprops.assignedSurveyors}${session_id}`;
    return this.api.getNew(suburl, true);
  }

  /* All Session Related Api Request To End Here */



  getRoles() {
    const suburl = `${this.connprops.getRoles}`;
    return this.api.getNew(suburl, true);
  }
  getProfileDetails() {

   console.log("Test"+window.location.href.toString());
   if(window.location.href.toString().includes("/preview")==false){
    const suburl = `${this.connprops.getProfileDetails}`;
    return this.api.getNew(suburl, true, false);
   }
   
  }
  getDashboardDetails() {
    const suburl = `${this.connprops.getDashboard}`;
    return this.api.getNew(suburl, true, false);
  }

  searchVectorLayer(data) {
    const suburl = `${this.connprops.layers}`;
    return this.api.getNew(suburl, true, false, data);
  }

  duplicateLayer(_id: string) {
    const suburl = `${this.connprops.duplicateLayer}/${_id}`;
    return this.api.getNew(suburl, true);
  }
  searchDataColectionLayer(data) {
    const suburl = `${this.connprops.searchDataCollectionLayer}`;
    return this.api.getNew(suburl, true, false, data);
  }
  getUrl(sessionId) {
    const suburl = `${this.connprops.getUrl}${sessionId}`;
    return this.api.getNew(suburl, true);
  }
  getUserRoleDetails() {
    const suburl = `${this.connprops.userDetails}`;
    return this.api.getNew(suburl, true);
  }

  exportVectorLayer(payload) {
    const suburl = `${this.connprops.exportVectorLayer}`;
    const data = {
      subURL: suburl,
      datum: payload
    }
    // return this.api.postNew(data, true, false, true);
    return this.api.postFile(suburl, true, false, payload);
  }

  createEmptyVectorLayer(payload) {
    const suburl = `${this.connprops.createVectorLayer}`;
    const data = {
      subURL: suburl,
      datum: payload
    }
    return this.api.postNew(data, true);
  }

  getAdvancedToolsSearchResult(payload) {
    const toolType = payload.feature_type;
    let subURL: string = null;
    let hostURL = env.apiPath;
    switch (toolType) {
      case "point_within_polygon":
        subURL = `${this.connprops.pointsWithinPolygon}`;
        break;
      case "voronoi":
        subURL = `${this.connprops.voronoi}`;
        break;
      case "centroid":
        subURL = `${this.connprops.centroid}`;
        break;
      case "point_on_feature":
        subURL = `${this.connprops.pointOnFeature}`;
        break;
      case "buffer":
        subURL = `${this.connprops.buffer}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case "difference":
        subURL = `${this.connprops.difference}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case "intersect":
        subURL = `${this.connprops.intersect}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case "union":
        subURL = `${this.connprops.union}`;
        hostURL = env.apiPath.replace('/v2', '/v3')
        break;
      case "bounding_box":
        subURL = `${this.connprops.boundingBox}`;
        break;
      case "along":
        subURL = `${this.connprops.along}`;
        break;
      case "concave":
        subURL = `${this.connprops.concave}`;
        break;
      case "convex":
        subURL = `${this.connprops.convex}`;
        break;
      case "dissolve":
        subURL = `${this.connprops.dissolve}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case "line_offset":
        subURL = `${this.connprops.lineOffset}`;
        break;
      case "simplify":
        subURL = `${this.connprops.simplify}`;
        break;
      case "envelope":
        subURL = `${this.connprops.envelope}`;
        break;
      case "center_of_mass":
        subURL = `${this.connprops.centerOfMass}`;
        break;
      case "interpolate":
        subURL = `${this.connprops.interpolate}`;
        break;
      case "isobands":
        subURL = `${this.connprops.isobands}`;
        break;
      case "isolines":
        subURL = `${this.connprops.isolines}`;
        break;
      case "tin":
        subURL = `${this.connprops.tin}`;
        break;
      case "clusters_k_means":
        subURL = `${this.connprops.clustersKmeans}`;
        break;
      case "midpoint":
        subURL = `${this.connprops.midpoint}`;
        break;
      case "tesselate":
        subURL = `${this.connprops.tesselate}`;
        break;
      case "polygonize":
        subURL = `${this.connprops.polygonize}`;
        break;
      case "mask":
        subURL = `${this.connprops.mask}`;
        break;
      case "center":
        subURL = `${this.connprops.center}`;
        break;
      case "point_grid":
      case "triangle_grid":
      case "square_grid":
      case "hex_grid":
        subURL = `${this.connprops.grid}`;
        break;
      case "merge":
        subURL = `${this.connprops.merge}`;
        break;
      case 'table-join':
        subURL = `${this.connprops.postTableJoin}`;
        break;
      case 'regression':
        subURL = `${this.connprops.regression}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case 'nearest-hub':
        subURL = `${this.connprops.nearestHub}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case 'spatial-join':
        subURL = `${this.connprops.spatialJoin}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case 'similarity-search':
        subURL = `${this.connprops.similaritySearch}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
      case 'search-by-location':
        subURL = `${this.connprops.selectByLoc}`;
        hostURL = this.api.changeBaseUrl(env.apiPath);
        break;
    }
       const data = {
      subURL,
      datum: payload
    }
    return this.api.post(data, true, hostURL);
  }

  getRasterToolResult(payload) {
    let subURL: string = null;
    const hostURL = this.api.changeBaseUrl(env.apiPath);
    switch (this.sessionService.selectedRasterToolIndex) {
      case RASTER_TOOL_TYPE.compute_gradual_path:
        subURL = `${this.connprops.gradualPath}`;
        break;
      case RASTER_TOOL_TYPE.visibility_coverage:
        subURL = `${this.connprops.visibilityCoverage}`;
        break;
      case RASTER_TOOL_TYPE.spatial_filter:
        subURL = `${this.connprops.spatialFilter}`;
        break;
    }
    const data = {
      subURL,
      datum: payload
    }
    return this.api.post(data, true, hostURL);
  }

  getRasterInfo(data) {
    let reqObj = {
      subURL: `${this.connprops.rasterlayerInfo}`,
      datum: data.rasterData
    }
    return this.api.post({ subURL: reqObj.subURL, datum: reqObj.datum }, true, env.rasterUploadInfoApiPath)
  }

  setProfilePic(image) {
    this.profilePic.next(image);

  }
  getProfilePicOberserver() {
    return this.profilePic
  }

  getProjectList(payload) {
    const subURL = `${this.connprops.getProjectList}`;
    return this.api.get(subURL, true, false, payload)
  }
  getProjectDetails(id: string) {
    const subURL = `${this.connprops.getProjectDetails}/${id}`
    // const subURL =`${this.connprops.editProject}/${id}`;
    return this.api.get(subURL, true, false);
  }
  addProject(payload) {
    const subURL = `${this.connprops.createProject}`;
    const data = {
      subURL,
      datum: payload

    }
    return this.api.postNew(data, true);
  }
  updateProject(payload, id) {
    const subURL = `${this.connprops.updateProject}/${id}`;
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true);
  }
  getSurveyersList(payload) {
    return this.api.getNew(`${this.connprops.surveyersList}`, true, false, { search: payload.search, 'page': payload.page });
  }
  getUserOrgDetails(orgId) {
    return this.api.getNew(`${this.connprops.organizationDetails}${orgId}`, true, false, {}).pipe(share());
  }
  postOrganizationDetails(payload, id) {
    const subURL = `${this.connprops.postOrganizationDetails}/${id}`
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true, false, payload);
  }
  // getOrgDetails() {
  //   return this.api.getNew (`${this.connprops.organizationDetails}`,true, false, {})
  // }
  addUrlAsLayer(payload) {
    const subURL = `${this.connprops.postOrgdetails}`;
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true, false);
  }

  getProjections(payload) {
    return this.api.getNew(`${this.connprops.projectionSystems}`, true, false, payload);
  }
  createGroup(payload) {
    const subURL = `${this.connprops.createGroup}`
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true, false)
  }

  deleteGroup(payload) {
    const subURL = `${this.connprops.deleteGroup}`
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true, false)
  }
  getAssignedprojectsToSession(sessionId) {
    return this.api.getNew(`${this.connprops.getAssignedProjects}/${sessionId}`, true, false, {})
  }
  updateProjectSToSession(sessionId, payload) {
    const subURL = `${this.connprops.updateSessionProjects}/${sessionId}`
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true, false);
  }
  searchUser(payload) {
    const subUrl = `${this.connprops.chatUserList}`
    return this.api.getNew(subUrl, true, false, payload);
  }
  exportChat(payload) {
    const subURL = `${this.connprops.exportChat}`;
    const data = {
      subURL,
      datum: payload
    }
    return this.api.postNew(data, true, false);
  }
  bulkInviteUsers(file) {
    const subURL = `${this.connprops.bulkInviteusers}`;
    const data = {
      subURL,
      datum: file
    }
    return this.api.postNew(data, true);
  }
  getRejectedUsersFile(fileName) {
    const suburl = `${this.connprops.getuploadedFile}`
    return this.api.getFile(suburl, true, false, { fileName: fileName });
  }

  sendDataURL(reqObj) {
    const subURL = `${this.connprops.sendDataURL}`
    const data = {
      subURL,
      datum: reqObj
    }
    return this.api.postNew(data, true);
  }

  changeStatus(formId, obj) {
    const subURL = `${this.connprops.statusChange}/${formId}`
    const data = {
      subURL,
      datum: obj
    }
    return this.api.postNew(data, true)
  }
  dataCollectedToVectorLayer(payload, params) {
    const subURL = `${this.connprops.createFormLayer}${payload.formId}`
    const data = {
      subURL,
      datum: { name: payload.name, ...params }
    }
    return this.api.postNew(data, true)
  }

  getLayerById(id) {
    const URL = `/layers/${id}/details`
    return this.api.getNew(URL, true, false)
  }

  editAttributes(id, payload) {
    const URL = `/layers/${id}/attributes`
    let finalPayload = { datum: payload, subURL: URL }
    return this.api.put(finalPayload, true)
  }
  getMapFonts() {
    const subURL = `${this.connprops.mapFonts}`
    this.api.getNew(subURL, true).subscribe((resp: any) => {
      let mapFontArray: any[] = cloneDeep(resp.data).reduce((prev, curr) => {
        if (curr.name && curr.weight) {
          let obj: any = {};
          obj.fontFamily = curr.name.trim();
          obj.fontWeightWords = curr.weight.trim() + ' ' + curr.style.trim()
          obj.fontStyle = curr.style;
          obj.googleSupport = curr.support;
          obj.fontWeight = this.sessionService.fontWeight(curr.weight);
          prev.push(obj);
        }
        return prev
      }, []);
      this.sessionService.setMapFonts(mapFontArray)
    })
  }
  getProcessedLayerList(sessionId) {
    const subURL = `${this.connprops.getProcessedList}/${sessionId}`;
    return this.api.getNew(subURL, true)
  }

  getProfileSubscriptionDetails() {
    const subURL = `${this.connprops.profileSubscriptionDetails}`
    return this.api.getNew(subURL, true)
  }

  uploadModelFile(payload: any) {
    const subURL = `${this.connprops.uploadModelFile}`
    let finalPayload = { datum: payload, subURL: subURL }
    return this.api.postNewForm(finalPayload, true);
  }

  getPreTrainedModels() {
    const subUrl = '/pre-tained-feature-detection-model'
    return this.api.getNew(subUrl, true)
  }

  getSearchedMapResult(searchParam, payload,isHereMap=false) {
    // if(isHereMap){
    //   return this.getSearchedHEREMapResult()
    // }
    const subUrl = `/property-search?search=${searchParam}&page=1&limit=20`
    let finalPayload = { datum: payload, subURL: subUrl }
    return this.api.postNew(finalPayload, true)
  }
  getSearchedHEREMapResult(searchParam, payload) {
    const subUrl = `https://autocomplete.search.hereapi.com/v1/autocomplete?apikey=${APIKEY_HERE}&q=${searchParam}`
    let finalPayload = { datum: payload, subURL: subUrl }
    return this.api.getNewTest(subUrl, false)
  }
  getPlaceDetails(id, index) {
    const subUrl = `/property-search/${id}?index=${index}`
    return this.api.getNew(subUrl, true)
  }

  getPlaceHEREDetails(id, index) {
    const subUrl = `https://lookup.search.hereapi.com/v1/lookup?apikey=${APIKEY_HERE}&id=${id}`
    return this.api.getNewTest(subUrl, false)
  }
  logoutuser() {
    const subUrl = `${this.connprops.logout}`
    return this.api.postNew({ subURL: subUrl }, true)
  }
  postPublishedSearchMapResults(searchValue: any, payload: any) {
    const subUrl = `${this.connprops.postPropertySearch}?search=${searchValue}`
    let payloadObj = { datum: payload, subURL: subUrl }
    return this.api.postNew(payloadObj, true)
  }
  getPublishedPlaceDetails(id, index) {
    const subURL = `${this.connprops.getPropertySearchResults}${id}?index=${index}`
    return this.api.getNew(subURL, true)
  }
  showHideLegendLayer(payload, token) {
    let datum = {
      layer_id: payload.layerId,
      show_legend: payload.show_legend
    }
    const subURL = `${this.connprops.showHideLegendLayers}${payload.sessionId}?access_token=${token}`
    return this.api.postNew({ datum, subURL }, true)
  }
  deleteProcessingLayer(payload) {
    let datum = {
      session_id: payload.session_id,
      layer_id: payload.layer_id
    }
    const subURL = `${this.connprops.deleteProcessingLayer}`
    return this.api.postNew({ datum, subURL })
  }

  exportAsGeoTiff(formDataPayload) {
    let datum = formDataPayload
    const hostURL = this.api.changeBaseUrl(env.apiPath);
    const subURL = `${this.connprops.exportAsGeotiff}${this.sessionService.sessionId}`
    let payload = {
      subURL: subURL,
      datum: datum
    }
    return this.api.post(payload, true, hostURL)
  }

  getAllDLCategories() {
    const subURL = `${this.connprops.DLCategories}`
    return this.api.getNew(subURL, true);
  }

  getDLCategoryDetails(payload) {
    const subURL = `${this.connprops.DLCategoryDetails}`;
    return this.api.getNew(subURL, true, false, payload);
  }

  searchDLLayersList(payload) {
    const subURL = `${this.connprops.DLSearch}`;
    return this.api.getNew(subURL, true, false, payload)
  }

  addSelectedLayerFromDLibrary(payload) {
    const hostURL = this.api.changeBaseUrl(env.apiPath);
    const subURL = `${this.connprops.addLayerFromDLibrary}`;
    const data = {
      subURL,
      datum: payload
    }
    return this.api.post(data, true, hostURL);
  }

  searchVectorList(payload) {
    const subURL = `${this.connprops.VectorList}`;
    return this.api.getNew(subURL, true, false, payload)
  }

  getDLAttributes(payload) {
    const subURL = `${this.connprops.DLAttributes}`
    return this.api.getNew(subURL, true, false, payload);
  }

  getTabularDLAttributes(payload) {
    const subURL = `${this.connprops.DLTabularAttributes}`
    return this.api.getNew(subURL, true, false, payload);
  }

  saveResultAsLayer(payload) {
    const subURL = `${this.connprops.SaveJoinedData}`;
    return this.api.postNew({ datum: payload, subURL }, true);
  }

  saveVTResultAsLayer(payload) {
    const subURL = `${this.connprops.SaveVTJoinedData}`;
    return this.api.postNew({ datum: payload, subURL }, true);
  }

  addJoinedLayerToNewSession(payload) {
    const subURL = `${this.connprops.CreateNewSessionWithJoinedLayer}`;
    return this.api.postNew({ datum: payload, subURL }, true);
  }

  getTabularDataList(payload) {
    const subURL = `${this.connprops.TabularData}`;
    return this.api.getNew(subURL, true, false, payload);
  }
  getLibraryNYourDataLayersList(payload) {
    const subURL = `${this.connprops.LibraryNYourDataLayersList}`;
    return this.api.getNew(subURL, true, false, payload);
  }

  deleteTabularData(id) {
    const subURL = `${this.connprops.DeleteTabularData}${id}`;
    return this.api.postNew({ subURL }, true);
  }

  getDataLibraryLayerData(payload) {
    const subURL = `${this.connprops.DataLibarayLayerData}`;
    return this.api.getNew(subURL, true, false, payload);
  }

  getGroups(payload) {
    let path = `${this.connprops.GetGroups}`;
    return this.api.getNew(path, true, false, payload);
  }
  getGroupLayers(options) {
    let path = `${this.connprops.GetGroupLayers}`;
    return this.api.getNew(path, true, false, options);
  }

  getLayersList(payload) {
    let path = `${this.connprops.LayersList}`;
    return this.api.getNew(path, true, false, payload);
  }
  getLibraryLayersList(payload) {
    let path = `${this.connprops.DataLibraryLayerNames}`;
    return this.api.getNew(path, true, false, payload);
  }

  getGroupLayerIds(payload) {
    let path = `${this.connprops.GroupLayerIds}`;
    return this.api.getNew(path, true, false, payload);
  }

  exportTabularData(layer_id) {
    let subURL = `${this.connprops.ExportTabular}${layer_id}`;
    return this.api.getNew(subURL, true, false, null, true, { responseType: 'blob', observe: "response" });
  }

  validateUserForLibUpload() {
    let subURL = `${this.connprops.ValidateDataUploadCredentials}`;
    return this.api.getNew(subURL, true);
  }

  uploadLibraryData(payload) {
    let subURL = `${this.connprops.datalibraryUpload}`;
    return this.api.postNew({ datum: payload, subURL }, true)
  }
  deleteNotification(payload) {
    let subURL = `${this.connprops.deleteNotification}${payload.id}`;
    return this.api.getNew(subURL, true, false);
  }
  clearAllNotifications() {
    let subURL = this.connprops.clearAllNotifications;
    return this.api.getNew(subURL, true, false);
  }
  getLayerPreview(layerId) {
    const subURL = `${this.connprops.layerPreview}${layerId}`
    return this.api.getNew(subURL, true)
  }
  getClassificationMethodRanges(payload) {
    let subURL = this.connprops.classificationMethodArray;
    return this.api.postNew({ datum: payload, subURL }, true)
  }
  getDCLayerData(payload) {
    let subURL = `${this.connprops.DCDataSearch}${payload.layer_id}`;
    return this.api.postNew({ datum: payload.body, subURL }, true, payload.query);
  }

  approveDCCollectedData(reqPayload) {
    let subURL = `${this.connprops.DCLayerDataApproval}${reqPayload.layerId}`;
    return this.api.postNew({ datum: reqPayload.payload, subURL }, true);
  }

  tokenChangeSubject(resp) {

    if (resp.val) {
      //change listeners to newly created userId
      this.socketService.userId = resp.userId;
      this.socketService.organizationId =resp.organizationId;

    } else {
      //remove all listeners
      this.socketService.removesocketListeners()
    }
  }
  getLayerDetails(layerId) {
    let subURL = `${this.connprops.InfoLayers}${layerId}`;
    return this.api.getNew(subURL, true, false)
  }

  getSimilarSearchAction(payload){
    let subURL = `${this.connprops.similaritySearch}`;
    // let baseUrl = "https://tosdev.transerve.live/api/v3"
    // return this.http.post(`${baseUrl}${subURL}`, payload);
    return this.api.postNew({ subURL: subURL, datum: payload }, true);
  }

  setSimilarSearchAction(payload){
    let subURL = `${this.connprops.selectByLoc}`;
    // let baseUrl = "https://tosdev.transerve.live/api/v3"
    // return this.http.post(`${baseUrl}${subURL}`, payload);
    return this.api.postNew({ subURL: subURL, datum: payload }, true);
  }

  getDataInsightsLayerData(payload){
    
    const subUrl = `/layers/layer-details/${payload.layerId}/${payload.sessionId}?page=${payload.page}&limit=${payload.limit}`
    return this.api.getNew(subUrl, true)
    
  }

  getDataInsightsData(payload){
    if(payload){
      let attributrs;
      if(payload.attrs){
        attributrs = payload.attrs.join();
      }
      if(attributrs){
        const subUrl = `/layers/new-data-insights/${payload.layerId}/${payload.sessionId}?attributes=${attributrs}`
        return this.api.getNew(subUrl, true)
      }else{
        const subUrl = `/layers/new-data-insights/${payload.layerId}/${payload.sessionId}`
        return this.api.getNew(subUrl, true)
      }
    
  }
  }

  /**
   * API for copy layer to section
   */
   copyLayerFromSection(request: CopyLayerToSectionRequest) {
    let datum = request.data;
    const suburl = `${this.connprops.copyLayerFromSection}${request.layer_id}`;
    const payload = {
      subURL: suburl,
      datum
    }
    return this.api.postNew(payload, true);
  }

  
  /**
   * API for move layer to section
   */
   moveLayerFromSection(request: MoveLayerToSectionRequest) {
    let datum = request.data;
    const suburl = `${this.connprops.moveLayerFromSection}${request.layer_id}`;
    const payload = {
      subURL: suburl,
      datum
    }
    return this.api.postNew(payload, true);
  }
}