import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectManagementActionTypes, GetProjectListStart, GetProjectListSuccess, AddProjectSuccess, Updateprojectstart, UpdateProjectSuccess,  GetProjectDetailsSuccess, GetProjectDetailsstart, GetSurveyorsDetailsStart, GetSurveyorsDetailsSuccess, AddProjectStart } from '../actions/project-management.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { API } from 'src/app/_services';
import { Storeservice } from 'src/app/_services/store.services';
import { of } from 'rxjs';
import { LogErrorAction, AddUpdateUserStartAction } from '../actions/user-management.actions';
import { GetSurveyorsListSuccessAction } from '../actions/session-management.actions';




@Injectable()
export class ProjectManagementEffects {

    constructor(
        private actions$: Actions,
        private _router: Router,
        private toaster: ToastrService,
        private storeService: Storeservice
    ) { }


    @Effect()
    getProjectList$ = this.actions$.pipe(
        ofType(ProjectManagementActionTypes.GET_PROJECT_LIST_START),
        switchMap((action: GetProjectListStart) => {
            return this.storeService.getProjectList(action.payload).pipe(
                map((resp: any) => {
                   if(resp.success) {

                       return  (new GetProjectListSuccess(resp.data))
                   }

                }),
                catchError(err => of(new LogErrorAction(err)))
            )
        })
    )
    @Effect()
    addProject$ =this.actions$.pipe(
        ofType(ProjectManagementActionTypes.ADD_PROJECT_START),
        switchMap((action :AddProjectStart) => {
            return this.storeService.addProject(action.payload).pipe(
                map((resp: any) => {
                    // if(resp.status) {
                    // }
                    return (new AddProjectSuccess(resp))
                }),
                catchError(err => of(new LogErrorAction(err)))
            )
        })
    )

    @Effect()
    updateProject$ =this.actions$.pipe(
        ofType(ProjectManagementActionTypes.UPDATE_PROJECT_START),
        switchMap((action: Updateprojectstart) => {
            return this.storeService.updateProject(action.payload, action.id).pipe(
                map((resp: any) => {
                    return (new UpdateProjectSuccess(resp))
                }),
                catchError(err => of(new LogErrorAction(err)))
            )
        })

    )

    @Effect()
    getProjectDetails$ =this.actions$.pipe(
        ofType(ProjectManagementActionTypes.GET_PROJECT_DETAILS_START),
        switchMap((action: GetProjectDetailsstart) => {
            return this.storeService.getProjectDetails(action.id).pipe(
                map((resp: any) => {
                    return (new GetProjectDetailsSuccess(resp))
                }),
                catchError(err => of(new LogErrorAction(err)))
            )
        })
    )
    @Effect()
    getSurveyorsList$ =this.actions$.pipe(
        ofType(ProjectManagementActionTypes.GET_surveyor_DETAILS_START),
        switchMap((action: GetSurveyorsDetailsStart) => {
            return this.storeService.getSurveyersList(action.payload).pipe(
                map((resp: any) => {
                    return new GetSurveyorsDetailsSuccess(resp)
                }),
                catchError(err => of(new LogErrorAction(err)))

            )
        })
    )



}