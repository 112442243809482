import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JoinedLayerNameDialog } from '../joined-layer-name-dialog/joined-layer-name-dialog.component';

@Component({
  selector: 'app-join-result-dialog',
  templateUrl: './join-result-dialog.component.html',
  styleUrls: ['./join-result-dialog.component.scss']
})
export class JoinResultDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<JoinResultDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    
  }

  saveData() {
    const data = {
      l_name: "abc_xyz"
    };
    const nameLayerDialog = this.dialog.open(JoinedLayerNameDialog, {
      maxWidth: 800,
      hasBackdrop: true,
      disableClose: true,
      data
    })
    nameLayerDialog.afterClosed().subscribe(_ => {});
    this.close(false);
  }

  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}