import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from "@angular/material";
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { premiseFloorNumberSelector } from 'src/app/store/selectors/data-approval-management.selector';
import { GetUsersStartAction } from 'src/app/store/actions/user-management.actions';
import { surveyorListSelector } from 'src/app/store/selectors/user-management.selector';
import { uniqBy } from 'lodash'
import { debounceTime } from 'rxjs/operators';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';
@Component({
  selector: 'app-premise-filter-dialog',
  templateUrl: './premise-filter-dialog.component.html',
  styleUrls: ['./premise-filter-dialog.component.scss']
})
export class PremiseFilterDialogComponent {
  floor: FormControl = new FormControl("");
  activeLayer: any = null;
  selectedAttribute: string = '';
  floorValues = [];
  floorCount: any
  totalFloor: any[];
  premiseFloor: boolean;
  searchText: string = '';
  surveyor: FormControl = new FormControl('')
  surveyorsList: any[] = [];
  totalPages: number = 0;
  currentPageNumber: number = 1;
  selectedSurveyorChiplist: any[] = [];
  subscriptionArr: Subscription[] = [];
  @ViewChild('surveyorInput') surveyorInput: ElementRef<HTMLInputElement>;
  dataFields: any[] = [{ text: 'Created At', type: 'createdAt' }, { text: 'Updated At', type: 'updatedAt' }];
  dataFieldControl: FormControl = new FormControl('');
  oldFloorValue: any = null;
  oldDataField: any = null
  oldSelectedSurveyorChiplist: any[] = null;
  oldSelectedSurveyor: any = null;
  clearAll: boolean = false;
  noFilterApplied: boolean = true;
  constructor(
    private _store: Store<any>,
    public dialogRef: MatDialogRef<PremiseFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public scroll: ScrollDispatcher,
    public DAMService: DataApprovalManagementService
  ) { }


  ngOnInit() {
    // get premiseFloorNumberSelector selector value only first time 
    // if (!this.data.premiseFloor) {
    //   this._store.select(premiseFloorNumberSelector).subscribe(floorValues => {
    //     this.totalFloor = []
    //     this.premiseFloor = true;
    //     this.totalFloor.push(...floorValues)
    //   })
    // }
    this._store.select(premiseFloorNumberSelector).subscribe(floorValues => {
      this.totalFloor = []
      this.premiseFloor = true;
      this.totalFloor.push(...floorValues)
      this.totalFloor = this.totalFloor.sort()
    })
    if ((Object.keys(this.data).length===0)|| (this.data.floor === '' && this.data.surveyor===undefined)) {
      this.noFilterApplied = true
    }
    else {
      this.noFilterApplied = false
    }
    // take total floor number from modal data
    // if (this.totalFloor === undefined) {
    //   this.totalFloor = this.data.totalFloor
    // }
    if (this.data.totalFloor && this.data.totalFloor.length) {
      this.totalFloor = this.data.totalFloor
    }
    this.floor.setValue(this.data.floor);
    let tableData = {
      search: this.surveyor.value.trim()
    };
    if (this.DAMService.premiseTableDataField && Object.keys(this.DAMService.premiseTableDataField).length) {
      this.dataFieldControl.setValue(this.DAMService.premiseTableDataField);
    }
    //this._store.dispatch(new PropertyTaxActions.ResetWardsAndUsers());
    this._store.dispatch(new GetUsersStartAction(tableData, 'city-os'));

    this.subscriptionArr.push(this._store.select(surveyorListSelector).subscribe((response: any) => {
      if (response.success) {
        this.currentPageNumber = +response.page;
        this.totalPages = response.pages;
        let filteredSurveyors = response.data.filter(user => user.user_role === 5)
        if (+response.page === 1) {
          this.surveyorsList = filteredSurveyors
        } else {
          this.surveyorsList = [...this.surveyorsList, ...filteredSurveyors]
        }
        this.surveyorsList = uniqBy([...this.surveyorsList], 'id');
      }

    }))

    this.surveyor.valueChanges.pipe(debounceTime(300)).subscribe((surveyor) => {
      if (!surveyor) return
      let tableData = {
        search: surveyor.trim()
      };
      this._store.dispatch(new GetUsersStartAction(tableData, 'city-os'));


    })
    if (this.DAMService.surveyorList.length) {
      this.selectedSurveyorChiplist = this.DAMService.surveyorList
    }
    if (this.DAMService.selectedSurveyor && Object.keys(this.DAMService.selectedSurveyor).length) {
      this.selectedSurveyorChiplist = [this.DAMService.selectedSurveyor.name];
    }
  }

  resetFilters() {
    this.clearAll = true
    if (this.floor.value !== "") {
      this.oldFloorValue = this.floor.value
    }
    if (this.selectedSurveyorChiplist.length) {
      this.oldSelectedSurveyorChiplist = this.selectedSurveyorChiplist
      // this.DAMService.surveyorList = this.selectedSurveyorChiplist
    }
    if (Object.keys(this.DAMService.selectedSurveyor).length) {
      this.oldSelectedSurveyor = this.DAMService.selectedSurveyor
    }
    if (this.dataFieldControl.value !== '') {
      this.oldDataField = this.dataFieldControl.value
    }
    this.floor.setValue("");
    this.selectedSurveyorChiplist = [];
    this.DAMService.selectedSurveyor = {};
    this.dataFieldControl.setValue({})
  }



  applyFilters() {
    let filterPremise: any = {};


    if (this.floor.value != "") {
      filterPremise.floor = this.floor.value
      filterPremise.premiseFloor = true
      filterPremise.totalFloor = this.totalFloor;
      filterPremise.dataField = this.dataFieldControl.value;

    }
    filterPremise.surveyor = this.DAMService.selectedSurveyor.id;
    this.DAMService.premiseTableDataField = this.dataFieldControl.value;
    this.dialogRef.close(filterPremise);
  }

  closeFilters(flag) {
    if ( this.clearAll && !this.noFilterApplied) {
      if (this.oldFloorValue) {
        this.floor.setValue(this.oldFloorValue);
      }
      if (this.oldSelectedSurveyorChiplist && this.oldSelectedSurveyorChiplist.length) {
        this.selectedSurveyorChiplist = this.oldSelectedSurveyorChiplist;
      }
      if (this.oldSelectedSurveyor) {
        this.DAMService.selectedSurveyor = this.oldSelectedSurveyor;
      }
      if (this.oldDataField) {
        this.dataFieldControl.setValue(this.oldDataField)
      }
    }
    else if(!this.clearAll && this.noFilterApplied){
      this.DAMService.selectedSurveyor={}
    }
    this.dialogRef.close(null);
  }

  onSurveyorSelection(event) {
    this.DAMService.selectedSurveyor = event.option.value;
    this.selectedSurveyorChiplist = [event.option.value.name];
    this.surveyorInput.nativeElement.value = '';
    this.surveyor.setValue(null);
  }

  remove(eachChip) {
    this.selectedSurveyorChiplist = [];
    this.DAMService.surveyorList = []
    this.DAMService.selectedSurveyor = {};
  }

  dataFieldComparision(o1: any, o2: any) {
    return (o1 && o2 && o1.type === o2.type)

  }

}
