import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect, MatOption } from "@angular/material";
import { Store } from "@ngrx/store";
import { combineLatest, Subscription } from "rxjs";
import { layerAttributesSelector, propertiesTypeSelector } from "src/app/store/selectors/session-management.selector";
import { cloneDeep } from "lodash";
import { FormControl } from "@angular/forms";
import { GetAdvancedToolsSearchResultStartAction, GetAdvancedToolsSearchResultSuccessAction, GetLayerAttributesStartAction, SessionManagementTypes } from "src/app/store/actions/session-management.actions";
import { ADVANCED_TOOL_TYPE } from "src/app/app.constants";
import { SessionService } from "src/app/_services/session.service";
import { Actions, ofType } from "@ngrx/effects";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-spatial-join-summary",
  templateUrl: "./spatial-join-summary.component.html",
  styleUrls: ["./spatial-join-summary.component.scss"]
})

export class SpatialJoinSummaryDialog {

  @ViewChild('select',{}) select: MatSelect;
  @ViewChild('selection2',{}) selection2: MatSelect;

  
  searchAttribute: FormControl = new FormControl('');
  searchField: FormControl = new FormControl('');
  summaryAttributes: Array<string> = [];
  filteredAttributes: Array<string> = [];
  panelOpenState = false;
  selectedAttributes: Array<string> = [];
  subscriptionArr: Array<Subscription> = [];
  attributesWithType: Array<any> = [];
  summaryOptions: Array<any> = [
    {key: 'Count', value: 0},
    {key: 'Sum', value: 5},
    {key: 'Mean', value: 6},
    {key: 'Unique', value: 1},
    {key: 'Min', value: 2},
    {key: 'Max', value: 3},
    {key: 'Range', value: 4},
    {key: 'Median', value: 7},
    {key: 'Standard Deviation', value: 8},
    {key: 'Minority', value: 9},
    {key: 'Majority', value: 10},
    {key: 'Q1', value: 11},
    {key: 'Q3', value: 12},
    {key: 'Iqr', value: 13},
    {key: 'Empty', value: 14},
    {key: 'Filled', value: 15},
    {key: 'Min_length', value: 16},
    {key: 'Max_length', value: 17},
    {key: 'Mean_length', value: 18}
  ]
  attributesSummaryObj: Array<AttributeSummary> = [];
  constructor(
    private dialogRef: MatDialogRef<SpatialJoinSummaryDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _store: Store<any>,
    private actions: Actions,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl
  ) { }

  ngOnInit() {
    if(this.data.joinLayer && this.data.joinLayer.parent_layer_id) {
      this._store.dispatch(new GetLayerAttributesStartAction(this.data.joinLayer.parent_layer_id));
    }
    let attrs$ = this._store.select(layerAttributesSelector);
    let attrsType$ = this._store.select(propertiesTypeSelector);
    this.subscriptionArr.push(
      combineLatest(attrs$, attrsType$).pipe().subscribe(([properties, propertyTypes]) => {
        this.summaryAttributes = cloneDeep(properties);
        this.filteredAttributes = cloneDeep(properties);
        this.attributesWithType = cloneDeep(propertyTypes);
      })
    );
    
    this.subscriptionArr.push(
      this.searchField.valueChanges.subscribe((val) => {
        if (val && val.trim().length) {
         this.filteredAttributes = this.summaryAttributes.filter((attr) => attr.toLowerCase().includes(val.trim().toLowerCase()))  
        } else {
          this.filteredAttributes = this.summaryAttributes
          console.log(this.summaryAttributes)
        }
      })
    );

    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        tap((action: GetAdvancedToolsSearchResultSuccessAction) => {
          this.navigationService.setShowLayerTable(true);
          this.sessionService.setShowAdvancedToolsSelectionData(true);
          this.close(true);
        })
      ).subscribe()
    );
  }

  updateSelectedAttributes = (attribute) => {
    const index = this.selectedAttributes.findIndex(attr => attr === attribute);
    if(index === -1) {
      this.selectedAttributes.push(attribute);
    } else {
      this.selectedAttributes.splice(index, 1);
    }
    this.updateAttributesSummaryObj();
  }

  removeFilterChip(attribute){
    this.select.options.forEach((item: MatOption) => {
      if (item.selected && (item.value == attribute)) {
        item.deselect()
      }
    });
    this.updateSelectedAttributes(attribute);
  }

  

  updateAttributesSummaryObj = () => {
    this.selectedAttributes.map(attr => {
      if(this.attributesSummaryObj.findIndex(obj => obj.attribute === attr) === -1) {
        this.attributesSummaryObj.push({
          attribute: attr,
          selectedSummaryOptions: [],
          selectedSummaryKeys: []
        })
      }
    })
    this.attributesSummaryObj.map((obj, i) => {
      if(this.selectedAttributes.findIndex(attr => attr === obj.attribute) === -1) {
        this.attributesSummaryObj.splice(i, 1);
      }
    })

    console.log("attributeSummaryObj: ", this.attributesSummaryObj);
  }

  updateSelectedSummaryObj = (obj, summaryOption) => {
    const index = obj.selectedSummaryOptions.findIndex(val => val === summaryOption.value);
    // if(index === -1) {
    //   obj.selectedSummaryOptions.push(summaryOption.value);
    //   // obj.selectedSummaryKeys.push(summaryOption.key);
    //   // return;
    // }else{
    //   obj.selectedSummaryOptions.splice(index, 1);
    // }
    obj.selectedSummaryKeys = this.summaryOptions.filter(option => (obj.selectedSummaryOptions).includes(option.value)).map(op => op.key);
  }

  removeBelowFilterChip(obj,key){
    let relations = {}
    relations = this.summaryOptions.filter(sr => (sr.key == key));
    const index = obj.selectedSummaryOptions.findIndex(val => val === relations[0].value);
    obj.selectedSummaryOptions.splice(index, 1);
    obj.selectedSummaryKeys = this.summaryOptions.filter(option => (obj.selectedSummaryOptions).includes(option.value)).map(op => op.key);
    // this.updateSelectedSummaryObj(obj,relations[0].value)
    // console.log(relations)
    this.selection2.options.forEach((item: MatOption) => {
      if (item.selected && (relations[0].value == item.value)) {
        item.deselect()
      }
    });
  }


  getOptions = (attr) => {
    const categoricalOptions = [1, 14, 15, 16, 17, 18]
    if(['String', 'string', 'STRING'].includes(this.attributesWithType[attr])) {
      return this.summaryOptions.filter(option => categoricalOptions.includes(option.value))
    }
    return this.summaryOptions;
  }

  enableApply = () => {
    return this.selectedAttributes.length && (this.attributesSummaryObj.filter(obj => obj.selectedSummaryOptions.length).length === this.attributesSummaryObj.length);
  }

  apply = () => {
    const payload = {
      input_layer_1:{
        layer_id: this.data.inputLayer.parent_layer_id,
        type: this.data.inputLayer.type
      },
      input_layer_2: {
        layer_id: this.data.joinLayer.parent_layer_id,
        type: this.data.joinLayer.type
      },
      input_params:{
        relationType: this.data.relations,
        joinType: null,
        joinFields: this.selectedAttributes,
        summaryStatus: true,
        summaryStatistics: this.getSummaryStatistics()
      },
      feature_type: ADVANCED_TOOL_TYPE[this.data.selectedToolIndex],
      selection_type: "all",
      query: {limit: 100, page: 1}
    }
    this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    this.navigationService.setShowLayerTable(true);
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  getSummaryStatistics = () => {
    let result = {};
    this.selectedAttributes.map(attr => {
      if(this.attributesSummaryObj.findIndex(obj => obj.attribute === attr) === -1) {
        result[attr] = [];
      } else {
        result[attr] = this.attributesSummaryObj.find(obj => obj.attribute === attr).selectedSummaryOptions;
      }
    })
    return result;
  }

  close = (value = false) => {
    this.dialogRef.close(value);
  }
}

interface AttributeSummary {
  attribute: string,
  selectedSummaryOptions: Array<number>,
  selectedSummaryKeys: Array<string>
}