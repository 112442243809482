import { Component,OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'info-contact-dialog',
  templateUrl: './info-contact-dialog.component.html',
  styleUrls: ['./info-contact-dialog.component.scss']
})
export class InfocontactDialogComponent implements OnInit {
  ngOnInit(): void {   
  }
  constructor(public dialogRef: MatDialogRef<InfocontactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     }
  cancelClicked() {
    this.dialogRef.close();
  }
  confirmButtonClicked() {
    
  }
}
  
