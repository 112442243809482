declare const L: any;
import 'leaflet';
import 'leaflet-zoombox';  // Import the leaflet-zoombox library

/**
 * Initialize the Zoombox Leaflet Control
 *  {L.Map} map
 */
export function initZoomBox(map) {
  const control = L.control.zoomBox({ modal: true });
  map.addControl(control);
  return control;
}
