import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component ({

    selector: 'bookmark-dialog',
    templateUrl: './bookmark-dialog.component.html',
    styleUrls: ['./bookmark-dialog.component.scss']
})
export class BookmarkDialogComponent {
    name: FormControl =new FormControl('');
    bookmarkArray: Array<any> =[];
    createNewBookmark: boolean= true;
    showError: boolean =false;
    bookmarkNames: any =[];
    constructor(public dialogRef: MatDialogRef<BookmarkDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any, public sessionservice: SessionService, public toaster: ToastrService, public router: Router) {
            this.dialogRef.disableClose =true;
        }

    ngOnInit() {
        const rightMostPos = window.innerWidth - Number(this.data.position.left -186.5);
        this.dialogRef.updatePosition({ top:`${this.data.position.top - 32 }px`, right: `${rightMostPos -380}px`});
        this.getAllBookmarks();
        this.name.valueChanges.subscribe((enteredString) => {
        this.showError =(!enteredString.trim().length)
        })
    }
    buttonClicked (resp){
        if(resp) {
            if(this.name.value.trim().length) {
                let enteredValue =this.name.value.trim();
                let index = this.bookmarkNames.findIndex((bookmarkName) => bookmarkName === enteredValue);
                if(index === -1) {

                    this.sessionservice.createBookmark({name: this.name.value.trim(), "zoom_level": this.data.bookmarkedData.zoom, "center": this.data.center.toArray(), "session_id": this.data.bookmarkedData.sessionId}).subscribe((resp: any) => {
                      if(resp.status === "success") {
                        this.toaster.success('Bookmark created successfully');
                        this.getAllBookmarks();
                        this.createNewBookmark =false;
                        this.dialogRef.disableClose =false;
                      }
                    })
                }
                else {
                    this.toaster.warning(`The name  '${this.name.value.trim()}' already exists`)
                }

            } else {
                this.showError =true;
            }
        }
        else {
            this.dialogRef.close(false)
        }

    }
    seeAllBookmarks(){
        this.dialogRef.disableClose =false;
        this.createNewBookmark =false;

    }
    existingBookmarkClicked(bookmark) {
        this.sessionservice.panOnMapObserver(bookmark);

    }
    getAllBookmarks() {
        this.sessionservice.getBookmarkData(this.data.bookmarkedData.sessionId).subscribe((resp: any) => {
            if(resp.status == "success") {
                this.bookmarkArray =resp.data || [];
                this.bookmarkNames =resp.data.map((bookmark) => bookmark.name)
            }
        })
    }
    allSessionsBookmarksClicked() {
        this.dialog.closeAll();
        // this.dialogRef.close();
        this.router.navigate(['../app-management/map-sessions/bookmarks'])
    }
}
