import { Injectable } from '@angular/core';
import { API } from "./API.service";
import connProp from '../../assets/connection.properties.json'

@Injectable({
  providedIn: 'root'
})
export class PropertyTaxService {

  connProps: any = connProp;
  _url = `${this.connProps.propertyTax}`;

  constructor(
    private apiService: API,
  ) { }

  //get urban local body data
  getPropertyTaxData(param?) {
    let url = this._url;
    if (param) {
      if (param.nonBlocking == true) {
        url += "?formDetails=true&nonBlocking=true"
      }
    } else {
      url+= "?formDetails=true"
    }
    return this.apiService.getNew(url, true, false, param);
  }

  //post urban local body data
  initiatePropertyTaxData(propertyTaxData) {
    return this.apiService.post({ subURL: this._url, datum: propertyTaxData }, true);
  }

  //update urban local body data
  updatePropertyTaxData(propertyTaxData, param) {
    let path_url = this._url + "/" + param.id;
    return this.apiService.put({ subURL: path_url, datum: propertyTaxData }, true);
  }

  checkAccessPropertyTax() {
    let path_url = this._url + "/check-access";
    return this.apiService.getNew(path_url, true, false, {});
  }

  getFloorTableData(layerId) {
    const subURL = this._url + '/collections/premises/floors/' + layerId;
    return this.apiService.getNew(subURL, true, false);
  }

  editGeometry(id, payload) {
    const subURL =  this.connProps.propertyTaxTableData + 'buildings/' + `${id}` + '/geometry';
    let obj = {
      subURL : subURL,
      datum: payload
    }

    return this.apiService.postNew(obj,true )
  }

  getAdminDataData(payload) {
    const subURL = this._url +'/collections/buildings';
    return this.apiService.get(subURL, true, false,payload )
  }
  approveDataByAdmin(payload) {
    const subURL = this._url + '/collections/buildings/geometry-edit';
    let reqObj = {
      subURL :subURL,
      datum: payload
    }
    return this.apiService.patch(reqObj, true)
  }

}
