import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
  dataapprovalmanagementTypes, GetLayerTableDataStartAction, GetLayerTableDataSuccessAction,
  GetLayerPremiseTableDataSuccessAction, GetLayerPremiseTableDataStartAction,
  ExportBuildingDataStartAction, ExportBuildingDataSuccessAction, ExportPremisesDataStartAction,
  ExportPremisesDataSuccessAction, GetLayerPremiseTableDataFailed, GetBuildingsDataStartAction,
  GetBuildingDataSuccessAction, UpdateBuildingDataSuccessAction, UpdateBuildingDataAction,
  UpdatePremiseDataAction, UpdatePremiseDataSuccessAction, GetSelectedFeatureDetailsStartAction,
  GetSelectedFeatureDetailsSuccessAction, GetPremiseDataStartAction, GetPremiseDataSuccessAction
} from '../actions/data-approval-management.actions'
import { from, throwError } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { API, LayerService } from 'src/app/_services';
import { DataApprovalManagementService } from '../../_services/data-approval-management.service'
import { LogErrorAction, LogFilureAction } from '../actions/user-management.actions';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { GetSelectedTemplateDetailsStartAction, GetSelectedTemplateDetailsSuccessAction } from '../actions/data-management.actions';
import { HideLoaderAction } from '../actions/loader.actions';

@Injectable()
export class DataapprovalmanagementEffects {
  constructor(
    private actions$: Actions,
    private layerService: LayerService,
    private DAMService: DataApprovalManagementService,
    private toaster: ToastrService
  ) { }


  @Effect()

  layerTableData$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.getLayerTableDataStart),
    switchMap((action: GetLayerTableDataStartAction) => {

      return this.DAMService.getLayerTableData(action.params).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetLayerTableDataSuccessAction(response.data);
          }
          else {
            if (response.error.lenght > 0) {
              this.toaster.error(response.error[0].message)
            }
          }
          return of(new LogFilureAction("Unable to fetch table data for the selected layer"))
        }),
        catchError((error: Error) => of(new GetLayerPremiseTableDataFailed(error)))
      )
    })
  )


  @Effect()
  layerPremiseTableData$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.getLayerPremiseTableDataStart),
    switchMap((action: GetLayerPremiseTableDataStartAction) => {
      return this.DAMService.getLayerPremiseTableData(action.params, action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetLayerPremiseTableDataSuccessAction(response.data);
          }
          else {
            if (response.error.lenght > 0) {
              this.toaster.error(response.error[0].message)
            }
          }
          return of(new LogFilureAction("Unable to fetch table data for the selected layer"))
        }),
        catchError((error: Error) => of(new GetLayerPremiseTableDataFailed(error)))
      )
    })
  )


  // @Effect({ dispatch: false })
  //   getPremiseDataFail: Observable<any> = this.actions$.pipe(
  //       ofType(dataapprovalmanagementTypes.getLayerPremiseTableDataFailed),
  //       tap((action) => {
  //           try {
  //               let apiError: any = action.payload.error;
  //               let errors: { code: number, message: string }[] = apiError.error;
  //               if (errors && errors.length && errors[0] && errors[0].message) {
  //                   return this.toaster.error(errors[0].message);
  //               }
  //           } catch (error) {
  //               this.toaster.error("building id missing.");
  //           }
  //       })
  //   );


  @Effect()
  exportCSV$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.exportDataStart),
    switchMap((action: ExportBuildingDataStartAction) => {
      return this.DAMService.exportData(action.payload).pipe(
        map((response: any) => {
          if (response) {
            return new ExportBuildingDataSuccessAction(response);
          }
          return of(new LogFilureAction("Could not export. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect({ dispatch: false })
  getPremiseDataFail: Observable<any> = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.getLayerPremiseTableDataFailed),
    tap((action) => {
      try {
        let apiError: any = action.payload.error;
        let errors: { code: number, message: string }[] = apiError.error;
        if (errors && errors.length && errors[0] && errors[0].message) {
          return this.toaster.error(errors[0].message);
        }
      } catch (error) {
        this.toaster.error("building id missing.");
      }
    })
  );


  @Effect()
  exportAsCSV$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.exportPremiseDataStart),
    switchMap((action: ExportPremisesDataStartAction) => {
      return this.DAMService.exportPremiseData(action.params, action.payload).pipe(
        map((response: any) => {
          if (response) {
            return new ExportPremisesDataSuccessAction(response);
          }
          return of(new LogFilureAction("Could not export. Please try again later"))
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  getBuildingsData$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.getBuildingDataStart),
    switchMap((action: GetBuildingsDataStartAction) => {
      return this.DAMService.getBuildingsData(action.layer_entry_id).pipe(
        map((response: any) => {
          if (response.success) {
            return new GetBuildingDataSuccessAction(response.data);
          }
          return of(new LogFilureAction("Unable to fetch row data at the moment"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  @Effect()
  updateBuildingData$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.updateBuildingData),
    switchMap((action: UpdateBuildingDataAction) => {
      return this.DAMService.updateBuildingData(action.building_id, action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            return new UpdateBuildingDataSuccessAction(response);
          }
          return of(new LogFilureAction("Unable to update building data"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )

  // @Effect()
  // $updatedBuildingList = this.actions$.pipe(
  //   ofType(dataapprovalmanagementTypes.updateBuildingDataSuccess),
  //   map((action: UpdateBuildingDataSuccessAction) => {
  //     // return new GetLayerTableDataStartAction(DEFAULT_LAYER_PARAMS);
  //   })
  // );

  @Effect()
  updatePremiseData$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.updatePremiseData),
    switchMap((action: UpdatePremiseDataAction) => {
      return this.DAMService.updatePremiseData(action.premise_id, action.payload).pipe(
        map((response: any) => {
          if (response.success) {
            return new UpdatePremiseDataSuccessAction(action.buildingsId, response.edited_entry);
          }
          return of(new LogFilureAction("Unable to update premise data"));
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )

    })
  );

  // @Effect()
  // $updatePremiseList = this.actions$.pipe(
  //   ofType(dataapprovalmanagementTypes.updatePremiseDataSuccess),
  //   map((action: UpdatePremiseDataSuccessAction) => {
  //     // return new GetLayerPremiseTableDataStartAction(DEFAULT_LAYER_PARAMS_PREMISE, action.buildingsId);
  //   })
  // );

  @Effect()
  featureDetails$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.getSelectedFeatureDetailsStart),
    switchMap((action: GetSelectedFeatureDetailsStartAction) => {
      return this.layerService.getSelectionGraphics(action.payload).pipe(
        map((response: any) => {
          return new GetSelectedFeatureDetailsSuccessAction(response.data[0]);
        }),
        catchError((error: Error) => of(new LogErrorAction(error)))
      )
    })
  )
  @Effect()
  premiseDetails$ = this.actions$.pipe(
    ofType(dataapprovalmanagementTypes.getPremiseDataStartAction),
    switchMap((action: GetPremiseDataStartAction) => {
      return this.layerService.getLayerGraphicsData(action.payload).pipe(
        map((response: any) => {
          if (response && Object.keys(response).length) {
            let arrInd = action.arrInd;
            return new GetPremiseDataSuccessAction({ ...response, arrInd })
          }
          new HideLoaderAction();

          return throwError("Unable to get premise data");
        }),
        catchError((error: Error) => {
          new HideLoaderAction();
          return of(new LogErrorAction(error))
        }
        )
      )
    })
  )

}



export const DEFAULT_LAYER_PARAMS = {
  limit: 20,
  page: 1,
  approvalStatus: null,
  search: ""
}

export const DEFAULT_LAYER_PARAMS_PREMISE = {
  limit: 20,
  page: 1,
  approvalStatus: null,

  search: ""
}