import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from '../reducers/data-management.reducer';

export const dataFeatureSelector = createFeatureSelector<State>("data");

export const widdgetsSelector = createSelector(
  dataFeatureSelector,
  state => (state.widgets || [])
)
export const usersSelector = createSelector(
  dataFeatureSelector,
  state => (state.users || [])
)

export const templatesSelector = createSelector(
  dataFeatureSelector,
  state => {
    return (state.templates || {})
  }
)

export const selectedTemplateSelector = createSelector(
  dataFeatureSelector,
  state => (state.activeTemplate || {})
)

export const vectorLayersSelector = createSelector(
  dataFeatureSelector,
  state => (state.vectorLayers || [])
)

export const projectionsSelector = createSelector(
  dataFeatureSelector,
  state => (state.projections || {})
)
export const statusChangeSelector = createSelector(
  dataFeatureSelector,
  state => (state.statusChange || {})
)
export const deleteTemplateSelector = createSelector(
  dataFeatureSelector,
  state => (state.deleteTemplate || {})
)

export const layerInfoSelector = createSelector(
  dataFeatureSelector,
  state => {
    return (state.layerInfo || {})
  }
)

export const dataLibraryCategories = createSelector(
  dataFeatureSelector,
  state => {
    return (state.libraryData.categories || [])
  }
)

export const dLCategoryDetails = createSelector(
  dataFeatureSelector,
  state => {
    return (state.libraryData.categoryDetails || {})
  }
)

export const dLLayers = createSelector(
  dataFeatureSelector,
  state => {
    return (state.libraryData.data || {})
  }
)

export const vLayersListSelector = createSelector (
  dataFeatureSelector,
  state => {
    return (state.vectorList.data || {})
  }
)

export const joinDataAttributesSelector = createSelector (
  dataFeatureSelector,
  state => {
    return (state.joinDataAttributes || {})
  }
)

export const tabularDataSelector = createSelector (
  dataFeatureSelector,
  state => {
    return (state.tabularData || {})
  }
)

export const joinLayersListSelector = createSelector (
  dataFeatureSelector,
  state => (state.joinLayersList || {})
)

export const layerPreviewDataSelector = createSelector (
  dataFeatureSelector,
  state => (state.dataDetails || {})
)

export const groupNamesSelector = createSelector (
  dataFeatureSelector,
  state => (state.groupNames || [])
)

export const groupLayersSelector = createSelector (
  dataFeatureSelector,
  state => (state.groupLayers || {})
)

export const yourDataLayersSelector = createSelector (
  dataFeatureSelector,
  state => (state.yourDataLayersList.data)
)

export const layersListSelector = createSelector (
  dataFeatureSelector,
  state => (state.layersList || {})
)

export const yourDataLayerPreviewSelector = createSelector (
  dataFeatureSelector,
  state => (state.layerPreview || {})
)

export const groupLayerIdsSelector = createSelector (
  dataFeatureSelector,
  state => (state.GroupLayerIds || [])
)
export const dataPreviewLayerSelector = createSelector (
  dataFeatureSelector,
  state => (state.dataPreviewLayerDetails || {})
)
