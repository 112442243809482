import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { HideLoaderAction, ShowLoaderAction } from 'src/app/store/actions/loader.actions';
import { LayerService } from 'src/app/_services';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {
  layerNameForm: FormGroup;
  oldGroupName: string;
  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _store: Store<any>,
    private layerService: LayerService
  ) { }

  ngOnInit() {
    this.layerNameForm = this.fb.group({
      name: ["", [Validators.required, this.noWhitespaceValidator]]
    })
    this.layerNameForm.patchValue({ name: this.data.layer.name })
    if (this.data.group) {
      this.oldGroupName = this.data.layer.name
    }
  }

  onSubmit() {
    if (this.layerNameForm.valid) {
      this._store.dispatch(new ShowLoaderAction());
      if(this.data.group){
        let obj = {
          layerType: this.data.type,
          oldGroupName: this.oldGroupName,
          newGroupName: this.layerNameForm.value.name 
        }
        this.layerService.renameGroup(obj).subscribe((resp:any) => {
          if (resp) {
            this._store.dispatch(new HideLoaderAction());
            this.dialogRef.close(true)
          }
        })
      } else{
        let obj = {
          _id: this.data.layer._id,
          name: this.layerNameForm.value.name,
          type: this.data.type
        }
        this.layerService.renameLayer(obj).subscribe((resp:any) => {
          if (resp) {
            let newLayer = {
              layer: this.data.layer,
              name: this.layerNameForm.value.name,
              type: this.data.type
            }
            this._store.dispatch(new HideLoaderAction());
            this.dialogRef.close(newLayer)
          }
        }, err => {
          this._store.dispatch(new HideLoaderAction());

        })
      }
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
  public noWhitespaceValidator (control: FormControl) {
    if (!control.value) return null;
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };

  }

}
