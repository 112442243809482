import { SessionManagementActions, SessionManagementTypes } from '../actions/session-management.actions';
import { isArray } from 'util';

export interface State {
  sessions: {
    page: number,
    pages: number,
    total: number,
    docs: Array<any>
  },
  layers: Array<any>,
  users: Array<any>,
  surveyors: Array<any>,
  activeSessionDetails: any,
  assignedUsers: Array<any>,
  assignedSurveyors: Array<any>,
  layerTableData: {
    layerId: string,
    totalPages: number,
    totalRecords: number,
    pageNumber: number,
    dataStatus: number,
    data: Array<any>,
    templateProperties: Array<string>,
    propertiesType: any,
    tableJoinData: any,
    regressionData: {
      summary: any,
      table: Array<any>
    }
  },
  myUnapprovedFeatures: Array<any>,
  layerAttributeInfo: any,
  rowDataDetails: any,
  layerAttributes: Array<any>,
  uniquePropertyValues: Array<any>,
  styleTypes: any,
  savedQueries: Array<any>,
  deleteSession: any,
  uploadFileStatus: any,
  processinglayers: any,
  updateIcon: any,
  getIcons: any,
  getProcessedList: any,
  placeDetails: object,
  deletedProcessingLayer: any,
  deleteNotification: any,
  clearAllNotification: any,
  classificationData: any,
  dcLayerData: {
    layerId: string,
    totalPages: number,
    totalRecords: number,
    pageNumber: number,
    dataStatus: number,
    data: Array<any>,
    templateProperties: Array<string>
  },
  layerDetails: any,
  dataInsightsLayerData: any;
  dataInsightsData: any;
  SearchedMapresults: Array<object>,
  SearchedHereMapresults: any;
}

export const initialState: State = {
  sessions: {
    page: 0,
    pages: 0,
    total: 0,
    docs: []
  },
  layers: [],
  users: [],
  surveyors: [],
  activeSessionDetails: {},
  assignedUsers: [],
  assignedSurveyors: [],
  layerTableData: {
    layerId: "",
    totalPages: 0,
    totalRecords: 0,
    pageNumber: 0,
    dataStatus: null,
    data: [],
    templateProperties: [],
    propertiesType: {},
    tableJoinData: {},
    regressionData: {
      summary: {},
      table: []
    }
  },
  dcLayerData: {
    layerId: "",
    totalPages: 0,
    totalRecords: 0,
    pageNumber: 0,
    dataStatus: null,
    data: [],
    templateProperties: []
  },
  myUnapprovedFeatures: [],
  layerAttributeInfo: {},
  rowDataDetails: {},
  layerAttributes: [],
  uniquePropertyValues: [],
  styleTypes: {},
  savedQueries: [],
  deleteSession: {},
  uploadFileStatus: {},
  processinglayers: [],
  updateIcon: {},
  getIcons: {},
  getProcessedList: {},
  placeDetails: {},
  deletedProcessingLayer: {},
  deleteNotification: {},
  clearAllNotification: {},
  classificationData: {},
  layerDetails: {},
  dataInsightsLayerData: {},
  dataInsightsData: {},
  SearchedMapresults: [],
  SearchedHereMapresults: {}
}

export function sessionsReducer(state = initialState, action: SessionManagementActions): State {
  switch (action.type) {
    case SessionManagementTypes.getSessionsSuccess:
      return { ...state, sessions: action.data };
    case SessionManagementTypes.getAllLayersInfoSuccess:
      return { ...state, layers: action.response };
    case SessionManagementTypes.getUsersListSuccesss:
      return { ...state, users: action.data };
    case SessionManagementTypes.getSurveyorsListSuccesss:
      return { ...state, surveyors: action.data };
    case SessionManagementTypes.getSessionDetailsSuccess:
      return { ...state, activeSessionDetails: action.data };
    case SessionManagementTypes.addLayersSuccess:
      console.log("entred into reducres")
      return { ...state, activeSessionDetails: action.data };
    case SessionManagementTypes.updateSessionSuccess:
      return { ...state, activeSessionDetails: action.data };
    case SessionManagementTypes.getAssignedUsersSuccess:
      return { ...state, assignedUsers: action.data };
    case SessionManagementTypes.getAssignedSurveyorsSuccess:
      return { ...state, assignedSurveyors: action.data };
    case SessionManagementTypes.getLayerAttributesSuccess:
      if (isArray(action.data)) {
        return {
          ...state, layerAttributes: action.data[0].all_attribues,
          layerTableData: {
            layerId: action.data[0]._id,
            totalPages: state.layerTableData.totalPages,
            totalRecords: state.layerTableData.totalRecords,
            pageNumber: state.layerTableData.pageNumber,
            dataStatus: state.layerTableData.dataStatus,
            data: state.layerTableData.data,
            templateProperties: state.layerTableData.templateProperties,
            propertiesType: action.data[0].attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      }
      return state;
    case SessionManagementTypes.getUniquePropertyValuesSuccess:
      return { ...state, uniquePropertyValues: action.data };
    case SessionManagementTypes.getStyleTypesSuccess:
      return { ...state, styleTypes: action.data };
    case SessionManagementTypes.getLayerTableDataSuccess:
      if (action.data.layer_id === state.layerTableData.layerId &&
        +action.data.page !== 1 &&
        action.data.is_approved === state.layerTableData.dataStatus
      ) {
        return {
          ...state, layerTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: [...state.layerTableData.data, ...action.data.docs],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      } else {
        return {
          ...state, layerTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: action.data.docs,
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        }
      }
    case SessionManagementTypes.getQueryTableDataSuccess:
      if (action.data.id === state.layerTableData.layerId &&
        +action.pageDetails.pageNumber !== 1
      ) {
        return {
          ...state, layerTableData: {
            layerId: action.data.id,
            totalPages: action.pageDetails.totalPages,
            totalRecords: action.pageDetails.totalRecords,
            pageNumber: +action.pageDetails.pageNumber,
            dataStatus: action.data.is_approved,
            data: [...state.layerTableData.data, ...action.data.geo_json.features],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        }
      }
      else {
        return {
          ...state, layerTableData: {
            layerId: action.data.id,
            totalPages: action.pageDetails.totalPages,
            totalRecords: action.pageDetails.totalRecords,
            pageNumber: +action.pageDetails.pageNumber,
            dataStatus: action.data.is_approved,
            data: action.data.geo_json.features,
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      }
    case SessionManagementTypes.getGraphicsLayerDataSuccess:
      if (action.data._id === state.layerTableData.layerId &&
        +action.data.page !== 1
      ) {
        return {
          ...state, layerTableData: {
            layerId: action.data._id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: [...state.layerTableData.data, ...action.data.geo_json.features],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      } else {
        return {
          ...state, layerTableData: {
            layerId: action.data._id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: (action.data.geo_json && action.data.geo_json.features) ? action.data.geo_json.features : [],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      }
    case SessionManagementTypes.getPendingFeaturesSuccess:
      if (action.data.layer_id === state.layerTableData.layerId &&
        +action.data.page !== 1
      ) {
        return {
          ...state, layerTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: [...state.layerTableData.data, ...action.data.docs],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      } else {
        return {
          ...state, layerTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: action.data.docs,
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      }
    case SessionManagementTypes.getCollectedDataRowDetailsSuccess:
      return { ...state, rowDataDetails: action.data };
    case SessionManagementTypes.getSavedQueriesSuccess:
      return { ...state, savedQueries: action.data };
    case SessionManagementTypes.saveCollectedDataRowDetailsSuccess:
      if (state.layerTableData.data.find(d => d._id === action.data._id)) {
        const rowDataIndex = state.layerTableData.data.findIndex(d => d._id === action.data._id);
        let layerData = Object.assign([], state.layerTableData.data);
        layerData[rowDataIndex] = action.data;
        return {
          ...state, layerTableData: {
            layerId: state.layerTableData.layerId,
            totalPages: state.layerTableData.totalPages,
            totalRecords: state.layerTableData.totalRecords,
            pageNumber: state.layerTableData.pageNumber,
            dataStatus: state.layerTableData.dataStatus,
            data: [...layerData],
            templateProperties: state.layerTableData.templateProperties,
            propertiesType: action.data.attribute_type,
            tableJoinData: {},
            regressionData: {
              summary: {},
              table: []
            }
          }
        };
      } else {
        return state;
      }
    case SessionManagementTypes.getAllUnapprovedFeatureEditsSuccess:
      return { ...state, myUnapprovedFeatures: action.data }
    case SessionManagementTypes.clearData:
      return {
        ...state, layerTableData: {
          layerId: state.layerTableData.layerId,
          totalPages: state.layerTableData.totalPages,
          totalRecords: state.layerTableData.totalRecords,
          pageNumber: state.layerTableData.pageNumber,
          dataStatus: state.layerTableData.dataStatus,
          data: [],
          templateProperties: state.layerTableData.templateProperties,
          propertiesType: state.layerTableData.propertiesType,
          tableJoinData: {},
          regressionData: {
            summary: {},
            table: []
          }
        }
      }
    case SessionManagementTypes.getLayerAttributeInfoSuccess:
      return { ...state, layerAttributeInfo: action.data };
    case SessionManagementTypes.getAdvancedToolsSearchResultSuccess:
      const data = (action.data.geo_json && action.data.geo_json.features) ? action.data.geo_json.features : [];
      return {
        ...state,
        layerTableData: {
          layerId: state.layerTableData.layerId,
          totalPages: action.pageDetails.totalPages,
          totalRecords: action.pageDetails.totalRecords,
          pageNumber: +action.pageDetails.pageNumber,
          dataStatus: state.layerTableData.dataStatus,
          data: (+action.pageDetails.pageNumber !== 1) ? [...state.layerTableData.data, ...data] : data,
          templateProperties: action.data.template_properties,
          propertiesType: action.data.attribute_type,
          tableJoinData: action.data.table_join || {},
          regressionData: {
            summary: action.data.summary ? filterSummary(action.data.summary) : {},
            table: action.data.summary && action.data.summary.table ? action.data.summary.table : []
          }
        }
      };
    case SessionManagementTypes.deleteSessionSuccess:
      return { ...state, deleteSession: action.response }
    case SessionManagementTypes.uploadFileSuccessAction:
      return { ...state, uploadFileStatus: action.response }
    case SessionManagementTypes.getProcessingFileSuccessAction:
      return { ...state, processinglayers: action.response }
    case SessionManagementTypes.updateIconSuccessAction:
      return { ...state, updateIcon: action.response }
    case SessionManagementTypes.getIconsListSuccessAction:
      return { ...state, getIcons: action.response }
    case SessionManagementTypes.getProcessedLayerListSuccessAction:
      return { ...state, getProcessedList: action.response }
    case SessionManagementTypes.getSearchedMapResultsSuccess:
      console.log(state,"state")
      return { ...state, SearchedMapresults: action.response }
    case SessionManagementTypes.getSearchedHereMapResultsSuccess:
      console.log(state,"state2")
      return { ...state, SearchedHereMapresults: action.response }
    case SessionManagementTypes.getPlaceDetailsSuccess:
      return { ...state, placeDetails: action.response }
    case SessionManagementTypes.processingLayerDeleteSuccess:
      return { ...state, deletedProcessingLayer: action.response }
    case SessionManagementTypes.deleteNotificationSuccess:
      return { ...state, deleteNotification: action.response }
    case SessionManagementTypes.clearAllNotificationSuccess:
      return { ...state, clearAllNotification: action.response }
    case SessionManagementTypes.getGraduatedClassificationDataSuccessAction:
      return { ...state, classificationData: action.response }
    case SessionManagementTypes.getDataCollectedLayerDataSuccess:
      const status = action.response.is_approved;
      return {
        ...state,
        dcLayerData: {
          layerId: action.response.layer_id,
          totalPages: action.response.pages,
          totalRecords: action.response.total,
          pageNumber: +action.response.page,
          dataStatus: status ? status : null,
          data: action.response.docs,
          templateProperties: action.response.template_properties
        }
      }
    case SessionManagementTypes.getLayerNameDetailsSuccess:
      return {
        ...state,
        layerDetails: action.response
      }
    case SessionManagementTypes.getDataInsightdLayerDataSuccess:
      return { ...state, dataInsightsLayerData: action.response }
    case SessionManagementTypes.getDataInsightSuccessAction:
      return { ...state, dataInsightsData: action.response }
    default:
      return state;
  }
}

const filterSummary = (summary) => {
  let obj = {};
  for (let key in summary) {
    if (key !== 'table') obj[key] = summary[key];
  }
  return obj;
}