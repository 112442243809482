import { Component, Inject, OnInit } from "@angular/core";
import { DateAdapter, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { Store } from "@ngrx/store";
import { cloneDeep } from 'lodash';
import { GetUsersStartAction } from "src/app/store/actions/user-management.actions";
import { usersSelector } from "src/app/store/selectors/user-management.selector";
import { MY_DATE_FORMATS } from "src/app/app.constants";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import moment from "moment-mini-ts";

@Component({
  selector: "dc-approval-filter-dialog",
  templateUrl: "./dc-approval-filter-dialog.component.html",
  styleUrls: ["./dc-approval-filter-dialog.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ]
})

export class DCApprovalFilterDialogComponent implements OnInit {
  tempData: any = {};
  today: Date;
  filterForm: FormGroup;
  selectedSurveyors: Array<any> = [];
  surveyors: Array<any> = [];
  serchSelected : boolean = false;
  dateAttr: any = null;
  fromDate: any;
  toDate: any;
  selectedStatus: string = null;
  filteredSurveyors: Observable<any[]>;
  subscriptionArray: Array<Subscription> = [];
  dateAttributeSelected: boolean = false;
  dateFieldOptions: Array<string> = ['createdAt', 'updatedAt'];
  statusOptions: Array<string> = ['all', 'approved', 'pending', 'rejected', 'resurvey'];
  removable: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<DCApprovalFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<any>,
    private _fb: FormBuilder
  ) {
    this.tempData = cloneDeep(this.data);
    this.today = new Date();
    this.filterForm = this._fb.group({
      surveyors: [[]],
      status: [null],
      dateField: [""],
      dateFrom: [null],
      dateTo: [null]
    })
  }

  ngOnInit() {
    const payload = {
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
      filter: 5,
      admin: true,
      search: ""
    };
    this._store.dispatch(new GetUsersStartAction(payload));
    this.subscriptionArray.push(
      this._store.select(usersSelector).subscribe((users: any) => {
        if (!Object.keys(users).length) return;
        this.surveyors = users.data.length ? users.data : [];
        this.setFormValues();
      })
    );
    this.filteredSurveyors = this.filterForm.get("surveyors").valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.subscriptionArray.push(
      this.filterForm.get("dateFrom").valueChanges.subscribe(value => this.fromDate = value ? moment(value).toISOString() : null)
    );
    this.subscriptionArray.push(
      this.filterForm.get("dateTo").valueChanges.subscribe(value => {
        value ? this.toDate = moment(value).endOf("day").toISOString() : null;
      })
    );
    this.subscriptionArray.push(
      this.filterForm.get("dateField").valueChanges.subscribe(value => {
        this.dateAttributeSelected = value ? true : false;
        this.dateAttr = value
})
    );
    this.subscriptionArray.push(
      this.filterForm.get("status").valueChanges.subscribe(value => this.selectedStatus = value)
    );
  }

  private _filter(value) {
    if(value){
      this.serchSelected = true;
    }
    else{
      this.serchSelected = false;
    }
    if (typeof (value) === "string") {
      const filterValue = value.toLowerCase();
      return this.surveyors.filter(option => option.name.toLowerCase().indexOf(filterValue) > -1);
    } else {
      return this.surveyors;
    }
  }

  crossClicked(){
    this.serchSelected = !this.serchSelected; 
    // this.filterForm.surveyors= [];
    this.filterForm.patchValue({'surveyors':  ""})
  }


  setFormValues() {
    if (this.data.surveyors.length) {
      this.data.surveyors.forEach(suv => {
        const surveyorData = this.surveyors.find(surveyor => surveyor._id === suv._id);
        if (surveyorData) {
          const selectedValues = this.selectedSurveyors.reduce((acc, surveyor) => {
            return [...acc, surveyor];
          }, []);
          const selectedSurveyor = selectedValues.find(value => value._id === surveyorData._id);
          if (!selectedSurveyor) {
            this.selectedSurveyors.push(surveyorData);
          }
        }
      });
    } else {
      this.selectedSurveyors = [];
    }
    this.dateAttr = this.data.dateAttribute ? this.data.dateAttribute : null;
    if (this.dateAttr) this.dateAttributeSelected = true;
    this.filterForm.get('dateField').setValue(this.dateAttr);
    this.fromDate = this.data.startDate ? this.data.startDate : null;
    this.filterForm.get("dateFrom").setValue(this.fromDate);
    this.toDate = this.data.endDate ? this.data.endDate : null;
    this.filterForm.get("dateTo").setValue(this.toDate);
    this.selectedStatus = this.setTargetValue(this.data.targetStatus);
    this.filterForm.get("status").setValue(this.selectedStatus);
  }

  surveyorSelected(event) {
    const data = event.option.value;
    if (data) {
      const selectedValues = this.selectedSurveyors.reduce((acc, user) => {
        return [...acc, user];
      }, []);
      const user = selectedValues.find(value => value._id === data._id);
      if (!user) {
        this.selectedSurveyors.push(data);
      }
      this.filterForm.get("surveyors").setValue("");
    }
  }

  remove(surveyor) {
    const selectedSurveyorIds = this.selectedSurveyors.reduce((acc, element) => {
      return [...acc, element._id];
    }, []);
    const index = selectedSurveyorIds.indexOf(surveyor._id);
    if (index >= 0) {
      this.selectedSurveyors.splice(index, 1);
    }
  }

  clear() {
    this.data.targetStatus = this.setTargetValue(null);
    this.data.dateAttribute = null;
    this.data.startDate = null;
    this.data.endDate = null;
    this.data.surveyors = []
    this.setFormValues();
  }

  apply() {
    let response = cloneDeep(RESPONSE);
    response.status = true;
    response.surveyors = this.selectedSurveyors;
    response.targetStatus = APPROVAL_STATUS_PAYLOAD[this.selectedStatus];
    response.dateAttribute = this.dateAttr;
    response.startDate = this.fromDate;
    response.endDate = this.toDate;
    this.dialogRef.close(response);
  }

  close() {
    let response = cloneDeep(RESPONSE);
    response.surveyors = this.tempData.surveyors;
    response.targetStatus = this.tempData.targetStatus;
    response.dateAttribute = this.tempData.dateAttribute;
    response.startDate = this.tempData.startDate;
    response.endDate = this.tempData.endDate;
    this.dialogRef.close(response);
  }

  ngOnDestroy() {
    if (this.subscriptionArray.length) {
      this.subscriptionArray.map(sub => sub.unsubscribe());
      this.subscriptionArray = [];
    }
  }

  setTargetValue(val) {
    switch (val) {

      case 1:
        return 'pending';
      case 2:
        return 'approved';
      case 3:
        return 'rejected';
      case 4:
        return 'resurvey';
      default:
        return 'all'
    }

  }

}

const RESPONSE = {
  status: false,
  surveyors: [],
  targetStatus: null,
  dateAttribute: null,
  startDate: null,
  endDate: null
}

const APPROVAL_STATUS_PAYLOAD: any = {
  all: null,
  pending: 1,
  approved: 2,
  rejected: 3,
  resurvey: 4
}