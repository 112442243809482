import {Action} from '@ngrx/store'

export enum surveyAnalyticsActionTypes {
  surveyAnalyticsStart = "[SURVEY-ANALYTICS] Get surveyor list",
  surveyAnalyticsSuccess = "[SURVEY-ANALYTICS] Get surveyor Success",
}




export class surveyAnalyticsStartAction implements Action {
  readonly type = surveyAnalyticsActionTypes.surveyAnalyticsStart;
  constructor(public params: any) { }
}


export class surveyAnalyticsSuccessAction implements Action {
  readonly type = surveyAnalyticsActionTypes.surveyAnalyticsSuccess;
  data;
  constructor(result: any) {
    this.data = result;
  }
}


export type SurveyAnalyticsActions = surveyAnalyticsStartAction | surveyAnalyticsSuccessAction;
