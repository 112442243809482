import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare let L: any;

@Component({
  selector: 'app-go-to-xy',
  templateUrl: './goToXy.component.html',
  styleUrls: ['./goToXy.component.scss']
})
export class GoToXyComponent implements OnInit {
  @Input() mapRef: any;
  form: FormGroup;
  goToType = 'dd';
  gotoXyPoint: any;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      ddLat: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}')
        ])
      ],
      ddLng: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}')
        ])
      ],
      xDeg: ['', Validators.compose([Validators.required])],
      xMin: ['', Validators.compose([Validators.required])],
      xSec: ['', Validators.compose([Validators.required])],
      yDeg: ['', Validators.compose([Validators.required])],
      yMin: ['', Validators.compose([Validators.required])],
      ySec: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.enableDisableValidators();
  }

  dmsEnableDisable(type: string) {
    switch (type) {
      case 'enable':
        this.form.get('xDeg').enable();
        this.form.get('xMin').enable();
        this.form.get('xSec').enable();
        this.form.get('yDeg').enable();
        this.form.get('yMin').enable();
        this.form.get('ySec').enable();
        this.form.get('ddLat').disable();
        this.form.get('ddLng').disable();
        break;
      case 'disable':
        this.form.get('xDeg').disable();
        this.form.get('xMin').disable();
        this.form.get('xSec').disable();
        this.form.get('yDeg').disable();
        this.form.get('yMin').disable();
        this.form.get('ySec').disable();
        this.form.get('ddLat').enable();
        this.form.get('ddLng').enable();
        break;
    }
  }

  goToXy() {
    if (this.form.valid) {
      const formData = this.form.value;
      this.resetGoto();
      if (this.goToType === 'dd') {
        this.plotPoint(formData.ddLat, formData.ddLng);
      } else {
        const lat = this.convertDmsToDD(
          formData.xDeg,
          formData.xMin,
          formData.xSec
        );
        const lang = this.convertDmsToDD(
          formData.yDeg,
          formData.yMin,
          formData.ySec
        );
        this.plotPoint(lat, lang);
      }
    }
  }

  enableDisableValidators() {
    if (this.goToType === 'dd') {
      this.dmsEnableDisable('disable');
    } else {
      this.dmsEnableDisable('enable');
    }
    this.resetGoto();
  }

  resetGoto() {
    if (this.gotoXyPoint !== undefined) {
      this.mapRef.removeLayer(this.gotoXyPoint);
    }
  }

  convertDmsToDD(deg, min, sec) {
    const dd = Number(deg) + Number(sec) / 3600 + Number(min) / 60;
    return dd;
  }

  plotPoint(lat, lang) {
    const latLng = [lat, lang];
    /* $rootScope.gotolatLng = [lang, lat]; */
    this.mapRef.setView(latLng, 15);

    this.gotoXyPoint = L.circleMarker(latLng, {
      color: '#ffeb3b',
      fillColor: '#ffeb3b',
      radius: 4,
      fillOpacity: 1,
      weight: 1
    }).addTo(this.mapRef);
    /* $rootScope.activateBuffer = false;
        $rootScope.gotoXyEnabled = true; */
  }
}
