import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
declare let L;
declare let topojson;
if (environment.production) {
  enableProdMode();
}

L.TopoJSON = L.GeoJSON.extend({
  addData: function (jsonData) {
      if (jsonData.type === "Topology") {
          for (let key in jsonData.objects) {
              let geojson = topojson.feature(jsonData, jsonData.objects[key]);
              L.GeoJSON.prototype.addData.call(this, geojson);
          }
      } else {
          L.GeoJSON.prototype.addData.call(this, jsonData);
      }
  }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
