import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { AssignSurveyorsStartAction, SessionManagementTypes } from 'src/app/store/actions/session-management.actions';
import { SessionPublishService } from 'src/app/_services/sessionPublish.service';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Storeservice } from 'src/app/_services/store.services';
import { ToastrService } from 'ngx-toastr';
import _ from 'lodash'

@Component({
  selector: "app-assign-surveyors-dialog",
  templateUrl: "./assign-surveyors-dialog.component.html",
  styleUrls: ["./assign-surveyors-dialog.component.scss"]
})
export class AssignSurveyorsDialogComponent implements OnInit {
  id = '';
  subscriptionArr: Array<Subscription> = []
  constructor(
    private sessionPublish: SessionPublishService,
    public dialogRef: MatDialogRef<AssignSurveyorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<any>,
    private router: Router,
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public storeService: Storeservice,
    public toaster: ToastrService
  ) {
    dialogRef.disableClose =true;
  }

  ngOnInit() {
    this.id = this.router.url.split("/").splice(-2)[0];
    this.subscriptionArr.push(
      this.actions$
        .pipe(
          ofType(SessionManagementTypes.uploadStyleForSessionPublishSuccess),
          tap((response) => this.assignProjects())
        )
        .subscribe()
      )
  }

  publishAndAssign() {
    this.sessionPublish.ngOnInitService();
    this.sessionPublish.publishSession(this.data.sessionId, 'public', false, 'WMS');
  }

  assignProjects() {
    const datum = {
      projects: _.uniq(this.data.selectedProjectIds)
    }
    this.storeService.updateProjectSToSession(this.data.sessionId, datum).subscribe((resp: any) => {
      if(resp && (resp !== null) && (resp !== undefined) && (resp.status)) {
        this.dialogRef.close();
        this.toaster.success('SuccessFully updated Session')
        this.router.navigate(["/app-management/map-sessions"]);
      }
    })
  
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef = null;
    if(this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe())
    }
    this.subscriptionArr = null;
  }
}
