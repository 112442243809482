import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FieldSelectionDialog } from '../field-selection-dialog/field-selection-dialog.component';
import { YourDataLayerSelectionDialogComponent } from '../your-data-layer-selection-dialog/your-data-layer-selection-dialog.component';

@Component({
  selector: 'app-vector-join-selection-dialog',
  templateUrl: './vector-join-selection-dialog.component.html',
  styleUrls: ['./vector-join-selection-dialog.component.scss']
})
export class VectorJoinSelectionDialogComponent implements OnInit {
  selectedType:string="tabular";
  constructor(    public dialogRef: MatDialogRef<VectorJoinSelectionDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService) { }

  ngOnInit() {

  }

  setType(event){
    this.selectedType=event.value;
  }

  close() {
    this.dialogRef.close();
  }

  nextClicked(){
    const joinDataDialog = this.dialog.open(YourDataLayerSelectionDialogComponent, {
      hasBackdrop: true,
      width: '800px',
      disableClose: true,
      data: {...this.data, selectedType: this.selectedType}
    })
    joinDataDialog.afterClosed().subscribe(_ => { });
    this.close()
  }
}
