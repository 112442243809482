import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from "ngx-spinner";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatTableModule } from '@angular/material';
import { rowDataDetailsSelector } from 'src/app/store/selectors/session-management.selector';
import { GetCollectedDataRowDetailsStartAction } from 'src/app/store/actions/session-management.actions';

@Component({
  selector: 'app-feature-detection-dialog',
  templateUrl: './feature-detection-dialog.component.html',
  styleUrls: ['./feature-detection-dialog.component.scss']
})
export class FeatureDetectionDialogComponent implements OnInit {
  collectedData = []
  filePath: string
  displayedColumns: Array<string> = ["name", "label", "percentage"];
  groupedObjects: any[] = [];
  title: string
  constructor(private _store: Store<any>,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FeatureDetectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public widgetData: any,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    // to position dialog modal on right
    const rightMostPos = window.innerWidth - 380;
    this.dialogRef.updatePosition({ top: `0px`, left: `${rightMostPos}px` });
    this._store.dispatch(new GetCollectedDataRowDetailsStartAction(this.widgetData.id));
    this._store.select(rowDataDetailsSelector).subscribe((data: any) => {
      this.groupedObjects = []
      if (data.form_template_widgets != undefined) {
        data.form_template_widgets.forEach(element => {
          if (element.form_widget_type == "feature_detection" && element.code == this.widgetData.code) {
            this.title = element.code
            if (data.collected_data[element.id] != undefined) {
              data.collected_data[element.id].statistics.forEach((detectedObject) => {
                let index = this.groupedObjects.findIndex(vectorLayer => vectorLayer.groupName === detectedObject.label);
                if (index === -1) {
                  this.groupedObjects.push({
                    groupName: detectedObject.label,
                    objectList: [detectedObject]
                  })
                } else {
                  this.groupedObjects[index]['objectList'].push(detectedObject);
                }
              })
              this.filePath = `${data.file_path}/${data.collected_data[element.id].annotatedImage}`
            }
            
          }

        });
      }
    })
  }

  closeFeatureDetectionDialog() {
    this.dialogRef.close([]);
  }

}
