import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject, Component } from '@angular/core';
import { EXPORT_OPTIONS } from '../data-export-dialog/data-export-dialog.component';

@Component({
  selector: "app-map-export",
  templateUrl: "../data-export-dialog/data-export-dialog.component.html",
  styleUrls: ["../data-export-dialog/data-export-dialog.component.scss"]
})

export class MapExportDialogComponent {
  selectedIndex: number = null;
  exportOptions: any = null;
  throwNote:boolean=false
  constructor(
    private dialogRef: MatDialogRef<MapExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.selectedIndex = 0;
    this.exportOptions = EXPORT_OPTIONS[this.data.dataType];
  }

  setExportType(ind) {
    this.selectedIndex = ind;
    const type = this.exportOptions.types[this.selectedIndex];
  }

  onSubmit() {
    const type = this.exportOptions.types[this.selectedIndex];
    this.dialogRef.close(type);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
  disableFn() {
    return true
  }
}