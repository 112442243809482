import { Injectable } from '@angular/core';
import { API } from "../../../_services/API.service";

import connProp from 'src/assets/connection.properties.json';



@Injectable({
	providedIn: 'root'
})
export class UrbanLocalBodiesService {
	connProps: any = connProp;
	wardArray: any[] =[];
	_url = `${this.connProps.urbanLocalBodies}`;
	constructor(
		private apiService: API,
	) { }


	//get urban local body data
	getUrbanLocalBody(param) {
		return this.apiService.getNew(this._url, true, false, param);
	}

	//post urban local body data
	createUrbanLocalBody(urbanLocalBodyData) { // not using this handling this case in updateUrbanLocalBody
		return this.apiService.post({ subURL: this._url, datum: urbanLocalBodyData }, true);
	}

	//update urban local body data
	updateUrbanLocalBody(urbanLocalBodyData, param) {
		let path_url = this._url
		if (param.id) {
			return this.apiService.put({ subURL: path_url, datum: urbanLocalBodyData }, true);
		} else {
			return this.apiService.post({ subURL: path_url, datum: urbanLocalBodyData }, true)
		}
	}
	getSelectedTierList() {
		let subURL = this.connProps.urbanLocalBodyWardsAssignment
		return this.apiService.getNew(subURL, true, false)
	}

	setRomanConvention(val) {
		switch (val) {
			case 0:
				return 'Tier I  Administrative Layer'
			case 1:
				return 'Tier II Administrative Layer'
			case 2:
				return 'Tier III Administrative Layer'
			case 3:
				return 'Tier IV Administrative Layer'
		}
	}

	getFilteredData(tierNumber, queryParams) {
		let subURL = this.connProps.urbanLocalBodies + '/tiers/' + tierNumber;
		return this.apiService.getNew(subURL, true, false, queryParams);
	}

}
