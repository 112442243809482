interface _selectedSession{
  id:string,
  checked:boolean
}
import { Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AddJoinedLayerToNewSessionStartAction, AddSelectedDataLayerToSessionStartAction } from 'src/app/store/actions/data-management.actions';
import { GetSessionsStartAction,
  CreateSessionStartAction,
  copyLayerFromSession,
  moveLayerFromSession } from 'src/app/store/actions/session-management.actions';
import { SESSION_PAYLOAD } from 'src/app/store/effects/session-management.effects';
import { allSessionsSelector } from 'src/app/store/selectors/session-management.selector';
import { GoogleAnalyticsService } from 'src/app/_services/google-analytics.service';
import { CopyLayerToSectionRequest, MoveLayerToSectionRequest,CopyLayerToSectionDataRequest, MoveLayerToSectionDataRequest} from '../../../store/modals/copy-layer-to-section';
import {InfoDialogComponent} from '../../../dialogs/info-dialog/info-dialog.component';
import { SessionService } from '../../../_services/session.service';
import { LegendCtrl } from 'src/app/_services/mapLegendsCtrl.service';
@Component({
  selector: 'app-use-in-map-session-dialog',
  templateUrl: './use-in-map-session-dialog.component.html',
  styleUrls: ['./use-in-map-session-dialog.component.scss']
})
export class UseInMapSessionDialogComponent implements OnInit {
  searchInput = new FormControl('');
  searchText = "";
  page: number = 1;
  totalPages = 1;
  stopper$: Subject<any> = new Subject();
  sessions: any[] = [];
 makeSessionCall: boolean = true;
  totalSessions = 0;
  selectedSession: any = null;
  hasError: boolean;
  customizedError: string;
  _selectedSessionArray: string[] = [];
  _selectedSessions=Array<_selectedSession>();

  constructor(public dialogRef: MatDialogRef<UseInMapSessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,
    private _store: Store<any>,
    private gaService: GoogleAnalyticsService,
    private _sessionService: SessionService,
    private legendService: LegendCtrl,) { }

  ngOnInit() {
    this._store.dispatch(new GetSessionsStartAction({
      "search": "",
      "page": 1,
      "limit": 10
    }));

    this._store.select(allSessionsSelector).pipe(takeUntil(this.stopper$)).subscribe((sessions: any) => {
      if (sessions) {

        this.page = parseInt(sessions.page)
      }
      if (parseInt(sessions.page) == 1) {
        this.sessions = [];
      }
      if (Object.keys(sessions).length) {
        this.makeSessionCall = true
        this.sessions.push(...sessions.docs);
        this.totalPages = sessions.pages;
        this.totalSessions = sessions.total;
      }
    });

    this.searchInput.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(text => {
      let payload = SESSION_PAYLOAD;
      this.searchText = `${encodeURIComponent(text.trim())}`;
      payload.search = this.searchText;
      this.page = payload.page;
      this._store.dispatch(new GetSessionsStartAction(payload));
    })


   
  }

  createNewSession() {
    if(this.data.type === 'copy' || this.data.type === 'move'){
      
        this.gaService.eventEmitter(`use_in_new_map_session`, "session", "click", `use_in_new_map_session`, 1);
        this.CopyAndMoveLayerToSectionAction(true);
    }else{
      this.gaService.eventEmitter(`use_in_new_map_session`, "data library", "click", `use_in_new_map_session`, 1);
      if (this.data.type === 'joinedLayer') {
        const layerId =this.data.layer_id;
        this._store.dispatch(new AddJoinedLayerToNewSessionStartAction({ layerId }));
      }
      else {
        const layerId = [this.data.layer_id];
        this._store.dispatch(new AddSelectedDataLayerToSessionStartAction({ layerId }));
      }
      this.close();
    }
  }

  onScroll(event) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;
    const buffer = 0;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit) {
      setTimeout(() => {
        this.loadMore();
      }, 200);
    }
  }

  loadMore() {

    if ((this.page >= this.totalPages) && (this.makeSessionCall)) return;
    this.makeSessionCall = false
    this.page++;
    let reqPayload = Object.assign({}, SESSION_PAYLOAD);
    reqPayload.page = this.page;
    reqPayload.search = this.searchText;
    this._store.dispatch(new GetSessionsStartAction(reqPayload));
  }

  

  selectButton() {
    if (!this.selectedSession) {
      this.setCustomizedError('Please select a session');
      return;
    }
   /**
    * selected session have maximum number of layer
    */
   
    if(this.data.type === 'copy' || this.data.type ==='move'){
      if(this.selectedSession._id === this.data.currentSession){
                this.setCustomizedError(`Not allowed to ${this.data.type} to the same session; choose another session`);
        return;
      }
      this.selectedSession.orders.length<20? this.CopyAndMoveLayerToSectionAction(false): this.displayCustomizedErrorPopup(`Layers cannot be further added to the selected session(s) ${this.selectedSession.name}. Please select another session or create a new one to move or copy this layer.`); return;
    }else{
      this.gaService.eventEmitter(`use_in_selected_map_session`, "data library", "click", `use_in_selected_map_session`, 1);
      // make-an-api-call-here-to-send-map-session
      if (this.data.type === 'joinedLayer') {
        this._store.dispatch(new AddJoinedLayerToNewSessionStartAction({ 'layerId': this.data.layer_id, 'sessionId': this.selectedSession._id }));
      }
      else {
        const layerId = [this.data.layer_id];
        this._store.dispatch(new AddSelectedDataLayerToSessionStartAction({ 'layerId': layerId, 'sessionId': this.selectedSession._id }));
      }
    }
  
  
    this.close()
  }

  CopyAndMoveLayerToSectionAction=(new_session:boolean)=>{
    if(this.data.type === 'copy'){
       let temp_data:CopyLayerToSectionDataRequest ={
        current_session_id:  this.data.currentSession,
        session_ids:new_session?null:this._selectedSessionArray,
        newSession:new_session
      };
      let request: CopyLayerToSectionRequest={
        layer_id:this.data.currentLayer,
        data:temp_data
      };
      this._store.dispatch(new copyLayerFromSession(request))
    }else{
       let temp_data: MoveLayerToSectionDataRequest={
        from_session_id: this.data.currentSession,
        to_session_id:new_session?null:[this.selectedSession._id],
        newSession: new_session
      };
      let request: MoveLayerToSectionRequest={
        layer_id:this.data.currentLayer,
        data:temp_data
      };
      this.legendService.map.removeLayer(this.data.currentLayer)
      this._store.dispatch(new moveLayerFromSession(request))
    }
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.stopper$.next();
    this.stopper$.complete();
  }

  setCustomizedError(message: string){
    this.hasError = true;
    this.customizedError = message;
  }


  displayCustomizedErrorPopup(message){
    const dialog = this.dialog.open(InfoDialogComponent, {
      hasBackdrop: true,
      data: {
        alertText: message
      }
    })
    dialog.afterClosed().subscribe(_ => {})
  }
  
  /**
   * function for check each checkbox
   */

  checkEachCheckBox(selectedSession:any,checked?:boolean){
    this._selectedSessionArray.includes(selectedSession._id)?this._selectedSessionArray = this._selectedSessionArray.filter(id=>id !== selectedSession._id):this._selectedSessionArray.push(selectedSession._id);
      this.selectedSession = selectedSession;
      this.hasError = false;
  }

  selectEachCheckBox(session){
    this.selectedSession = session;
    this.hasError = false;
  }
}
