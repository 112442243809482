import { Component, OnInit, Inject, ViewChild, ElementRef, ViewChildren } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from 'src/app/_services/session.service';
import { LAYER_ACTIVE_ICONS, LAYER_INACTIVE_ICONS, ADVANCED_TOOL_TYPE } from 'src/app/app.constants';
import { Store } from '@ngrx/store';
import { SessionManagementTypes, GetAdvancedToolsSearchResultSuccessAction, GetAdvancedToolsSearchResultStartAction, GetLayerAttributesStartAction, GetUniquePropertyValuesStartAction, GetLayerAttributesSuccessAction, GetUniquePropertyValuesSuccessAction } from 'src/app/store/actions/session-management.actions';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
import { InputParams } from './input-params.model';
import { layerAttributesSelector, propertiesTypeSelector, uniquePropertyValuesSelector } from 'src/app/store/selectors/session-management.selector';
@Component({
  selector: 'app-advanced-tools',
  templateUrl: "./advanced-tools-dialog.component.html",
  styleUrls: ["./advanced-tools-dialog.component.scss"]
})
export class AdvancedToolsDialogComponent implements OnInit {
  layerActiveIcons = LAYER_ACTIVE_ICONS;
  layerInactiveIcons = LAYER_INACTIVE_ICONS;
  layersList1: Array<any> = [];
  layersList2: Array<any> = [];
  selectedLayer1: any = null;
  selectedLayer2: any = null;
  selectedLayers: Array<any> = [];
  selectedLayerIds: Array<string> = [];
  activeLayerIds: Array<string> = [];
  selectedToolIndex: number = null;
  showGeometrySelectionOptions = false;
  showChoices = false;
  actionChoices = CHOICES;
  geometryOptions = GEOMETRY_OPTIONS;
  selectedChoice = null;
  subscriptionArr: Array<Subscription> = [];
  additionalInputObj: any = INPUT_PARAMS_OBJ;
  additionalInputs: Array<any>;
  additionalInputKeys: Array<string>;
  showAdditionalInputs = false;
  submitClicked: boolean = false;
  activateMergeBtn: boolean = false;
  inputParams: InputParams = {
    difference: null,
    radius: null,
    steps: null,
    distance: null,
    units: null,
tolerance: null,
    maxEdge: null,
    concavity: null,
    propertyName: null,
    highQuality: null,
    mutate: null,
    cellSize: null,
    gridType: null,
    property: null,
    weight: null,
    breaks: null,
    cellSide: null,
    extent: null
  }
  hasErrors = false;
  hasErrors_disdrop = false;
  hasErrors_test= false; 
  errorMessage: string = "";
  errorMessage_test: string = "";
  DISPLAY_HEADER = {
    27: {
      'display_header': 'SELECT AREA OF INTEREST'
    },
    28: {
      'display_header': 'SELECT AREA OF INTEREST'
    },
    29: {
      'display_header': 'SELECT AREA OF INTEREST'
    },
    30: {
      'display_header': 'SELECT AREA OF INTEREST'
    }
  }
  constructor(
    public dialogRef: MatDialogRef<AdvancedToolsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sessionService: SessionService,
    private navigationService: NavigationCtrl,
    private toastr: ToastrService,
    private _store: Store<any>,
    private actions: Actions
  ) {}
  ngOnInit() {
    this.selectedToolIndex = this.data.selectedToolIndex;
    this.additionalInputs = this.additionalInputObj[this.selectedToolIndex];
    switch(this.selectedToolIndex) {
      case 23:
        this.actionChoices = CHOICES.filter(choice => choice.index === 2);
        this.geometryOptions = GEOMETRY_OPTIONS.filter(option => option.type === 'point');
        this.showChoices = true;
        this.showAdditionalInputs = false;
        break;
      case 26:
        this.actionChoices = CHOICES.filter(choice => choice.index === 2);
        this.geometryOptions = GEOMETRY_OPTIONS.filter(option => option.type === 'polygon');
        this.showAdditionalInputs = (this.selectedLayer1 && (this.additionalInputs.length > 0));
        break;
      case 27:
      case 28:
      case 29:
      case 30:
        this.actionChoices = CHOICES.filter(choice => choice.index === 2);
        this.geometryOptions = GEOMETRY_OPTIONS.filter(option => option.type === 'polygon');
        this.showChoices = true;
        this.showAdditionalInputs = true;
        break;
      case 33:
        this.actionChoices = [];
        this.showChoices = false;
        this.showAdditionalInputs = false;
      default:
        this.actionChoices = CHOICES;
        this.geometryOptions = GEOMETRY_OPTIONS;
        this.showAdditionalInputs = (this.selectedLayer1 && (this.additionalInputs.length > 0));
        break;
    };
    this.additionalInputKeys = this.listInputKeys();
    const layersList1Types = this.getLayerTypeList(TILObject.find(obj => obj.index === this.selectedToolIndex).list1);
    const layersList2Types = this.getLayerTypeList(TILObject.find(obj => obj.index === this.selectedToolIndex).list2);
    // this.layersList1 = this.sessionService.sessionLayers.filter(layer => TypeSubTypeObject[(TILObject.find(obj => obj.index === this.selectedToolIndex).list1)].indexOf(layer.type.toLowerCase()) > -1);
    // this.layersList2 = this.sessionService.sessionLayers.filter(layer => TypeSubTypeObject[(TILObject.find(obj => obj.index === this.selectedToolIndex).list2)].indexOf(layer.type.toLowerCase()) > -1);
    this.layersList1 = this.sessionService.sessionLayers.filter(layer => (layer.show && ((layer.layer_type === 1) || (layer.layer_type === 3) || (layer.layer_type === 4)))).filter(layer => layersList1Types.indexOf(layer.type.toLowerCase()) > -1);
    this.layersList2 = this.sessionService.sessionLayers.filter(layer => (layer.show && ((layer.layer_type === 1) || (layer.layer_type === 3) || (layer.layer_type === 4)))).filter(layer => layersList2Types.indexOf(layer.type.toLowerCase()) > -1);
    this.activeLayerIds = this.layersList1.map(l => l.id);
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        tap((action: GetAdvancedToolsSearchResultSuccessAction) => {
          this.navigationService.setShowLayerTable(true);
          this.sessionService.setShowAdvancedToolsSelectionData(true);
          this.dialogRef.close(true);
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this._store.select(layerAttributesSelector).subscribe(properties => {
        if(this.selectedToolIndex === ADVANCED_TOOL_TYPE['dissolve']) {
          this.additionalInputs[0].propertyName = properties;
        }
      })
    );
    this.subscriptionArr.push(
      this._store.select(propertiesTypeSelector).subscribe(properties => {
        if([ADVANCED_TOOL_TYPE['interpolate'], ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines'], ADVANCED_TOOL_TYPE['tin']].includes(this.selectedToolIndex)) {
          const filteredProperties = Object.keys(properties).filter(property => properties[property] === 'Number');
          const propertyIndex = this.additionalInputs.findIndex((inputs, i) => {
            if(Object.keys(inputs)[0] === "property") return true;
          });
          if(!filteredProperties.length) this.inputParams['property'] = null;
          this.additionalInputs[+propertyIndex].property = filteredProperties;
        }
      })
    );
    this.subscriptionArr.push(
      this._store.select(uniquePropertyValuesSelector).subscribe(values => {
        if([ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines']].includes(this.selectedToolIndex)) {
          const breakIndex = this.additionalInputs.findIndex((inputs, i) => {
            if(Object.keys(inputs)[0] === "breaks") return true;
          });
          this.additionalInputs[+breakIndex].breaks = values.sort(function (a, b) { return a-b});
          if(!values.length) this.inputParams['breaks'] = null;
        }
      })
    );
  }
  
  listInputKeys() {
    if(!this.showAdditionalInputs) return [];
    const inputKeys = [];
    this.additionalInputs.forEach(element => {
      inputKeys.push(Object.keys(element)[0])
    });
    return inputKeys;
  }
  
  getLayerTypeList(arr) {
    if(!arr.length) return arr;
    let result = []
    arr.forEach(type => {
      result = result.concat(TypeSubTypeObject[type])
    });
    return result;
  }
  
  selectLayer1(sLayer) {
    this.selectedLayer1 = sLayer;
    this.sessionService.setFirstSelectedAdvancedToolLayer(sLayer);
    this.showAdditionalInputs = (this.selectedLayer1 && !!this.additionalInputs.length);
    this.additionalInputKeys = this.listInputKeys();
    if(![6, 7, 8].includes(this.selectedToolIndex)) this.layersList2 = this.layersList2.filter(layer => layer.id !== sLayer.id);
    this.showHideChoices();
    // if(this.selectedToolIndex === ADVANCED_TOOL_TYPE['dissolve']) {
    if([ADVANCED_TOOL_TYPE['dissolve'], ADVANCED_TOOL_TYPE['interpolate'], ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines'], ADVANCED_TOOL_TYPE['tin']].includes(this.selectedToolIndex)) {
      this._store.dispatch(new GetLayerAttributesSuccessAction([{all_attribues: [], attribute_type: {}}]));
      this._store.dispatch(new GetUniquePropertyValuesSuccessAction([]));
      this._store.dispatch(new GetLayerAttributesStartAction(sLayer.parent_layer_id));
    }
    if(this.showAdditionalInputs && [5,14, 27, 28, 29, 30].includes(this.selectedToolIndex)) {
      this.inputParams['units'] = "meters";
    }
    if(this.showAdditionalInputs && [6].includes(this.selectedToolIndex)) {
      this.inputParams['difference'] = "Difference";
    }
  }

  selectLayer2(sLayer) {
    this.selectedLayer2 = sLayer;
    this.sessionService.setSecondSelectedAdvancedToolLayer(sLayer);
    if(![6, 7, 8].includes(this.selectedToolIndex)) this.layersList1 = this.layersList1.filter(layer => layer.id !== sLayer.id);
    this.showHideChoices();
  }

  selectLayers(layer) {
    if(!this.activeLayerIds.includes(layer.id)) return;
    this.selectedLayerIds = this.selectedLayers.map(l => l.id);
    if (this.selectedLayerIds.includes(layer.id)) {
      const ind = this.selectedLayerIds.indexOf(layer.id);
      this.selectedLayerIds.splice(ind, 1);
      this.selectedLayers.splice(ind, 1);
    } else {
      if(this.selectedLayerIds.length === 3) return;
      this.selectedLayers.push(layer);
      this.selectedLayerIds.push(layer.id);
    }
    if (!this.selectedLayerIds.length) {
      this.activeLayerIds = this.layersList1.map(l => l.id);
    } else if (this.selectedLayerIds.length === 1) {
      const relatedLayers = RELATED_LAYER_TYPES[this.selectedLayers[0].type];
      this.activeLayerIds = this.layersList1.filter(lar => relatedLayers.includes(lar.type)).map(l => l.id);
    }
    if(this.selectedLayers.length > 1) {
      this.activateMergeBtn = true;
    } else {
      this.activateMergeBtn = false;
    }
  }

  showHideChoices(){
    // if(this.hasErrors) {
    //   this.showChoices = false;
    //   return;
    // }
    const layer1 = (TILObject.find(obj => obj.index === this.selectedToolIndex)).list1;
    const layer2 = (TILObject.find(obj => obj.index === this.selectedToolIndex)).list2;
    if(layer1.length && layer2.length) {
      if(this.selectedLayer1 && Object.keys(this.selectedLayer1).length && this.selectedLayer2 && Object.keys(this.selectedLayer2).length) this.showChoices = true;
      else this.showChoices = false;
      return; 
    } else if(layer1.length && !layer2.length) {
      if(Object.keys(this.selectedLayer1).length) this.showChoices = true;
      else this.showChoices = false;
      return;
    } else if(!layer1.length && layer2.length) {
      if(Object.keys(this.selectedLayer2).length) this.showChoices = true;
      else this.showChoices = false;
      return;
    }
  }

  setChoice(value) {
    this.submitClicked = true;
    this.validateInputs();
    if(this.hasErrors || this.hasErrors_test || this.hasErrors_disdrop) return;
    // const choiceKeys = Object.keys(CHOICES);
    const choiceKeys = CHOICES.map(choice => choice.index);
    this.selectedChoice = value;
    this.sessionService.setAdditionalInputs(this.inputParams);
    let selectedLayerIds = [];
    if(this.selectedLayer1) selectedLayerIds.push(this.selectedLayer1.parent_layer_id);
    if(this.selectedLayer2) selectedLayerIds.push(this.selectedLayer2.parent_layer_id);
    this.sessionService.setSelectedLayersInTools(selectedLayerIds);
    switch (+value) {
      case choiceKeys[0]:
        //open query dialog
        this.showGeometrySelectionOptions = false;
        this.sessionService.setSelectedAdvancedToolChoiceType('query');
        this.sessionService.setAdvancedToolsGraphicsSelectionActive(false);
        const data = {
          selectedLayer1: this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
          selectedLayer1Type: this.selectLayer1 ? this.selectedLayer1.type : null,
          selectedLayer2: this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null,
          selectedLayer2Type: this.selectedLayer2 ? this.selectedLayer2.type : null,
          type: 'query'
        }
        this.dialogRef.close(data);
        break;
      case choiceKeys[1]:
        //show draw options
        if(this.hasErrors) {
          this.showGeometrySelectionOptions = false;
        } else {
          this.showGeometrySelectionOptions = true;
        }
        this.sessionService.setSelectedAdvancedToolChoiceType('graphics');
        break;
      case choiceKeys[2]:
        //Directly make api call to get the fearures
        this.sendRequest();
        this.showGeometrySelectionOptions = false;
        this.sessionService.setSelectedAdvancedToolChoiceType('all');
        this.sessionService.setAdvancedToolsGraphicsSelectionActive(false);
        break;
      default:
        break;
    } 
  }

  mergeLayers() {
    if(this.selectedLayers.length <= 1) return;
    const payload = {
      ids: this.selectedLayers.map(layer => layer.parent_layer_id),
      feature_type: ADVANCED_TOOL_TYPE[this.selectedToolIndex],
      query: {
        limit: 100,
        page: 1
      }
    }
    this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    this.navigationService.setShowLayerTable(true);
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  geometrySelected(geometryType) {
    if(this.hasErrors) return;
    this.sessionService.selectedGeometryType = geometryType;
    this.sessionService.setSelectedAdvancedToolGeometryType(geometryType);
    switch (this.selectedToolIndex) {
      case 1:
      case 6:
      case 7:
      case 8:
        this.sessionService.selectedGeometryLayerId = this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null;
        this.sessionService.selectedGraphicsLayerType = this.selectedLayer2 ? this.selectedLayer2.type : null;
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 24:
      case 25:
      case 26:
      case 31:
        this.sessionService.selectedGeometryLayerId = this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null;
        this.sessionService.selectedGraphicsLayerType = this.selectedLayer1 ? this.selectedLayer1.type : null;
      default:
        break;
    }
    this.sessionService.setAdvancedToolsGraphicsSelectionActive(true);
    if(this.navigationService.pointPlotSubscription) {
      // this.navigationService.pointPlotSubscription.unsubscribe();
      this.navigationService.pointPlotSubscription = null;
      this.sessionService.setAdvancedToolsDrawnCoordinates("");
    }
    this.navigationService.showGeometry();
    const data = {
      selectedLayer1: this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
      selectedLayer2: this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null,
      type: 'graphics'
    }
    this.dialogRef.close(data);
  }

  sendRequest() {
    const payload = {
      input_layer_1:{
        layer_id: this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
        type: this.selectedLayer1 ? this.selectedLayer1.type : null
      },
      input_layer_2:{
        layer_id: this.selectedLayer2 ? this.selectedLayer2.parent_layer_id : null,
        type: this.selectedLayer2 ? this.selectedLayer2.type : null
      },
      input_params:{...this.inputParams},
      feature_type: ADVANCED_TOOL_TYPE[this.selectedToolIndex],
      selection_type: "all",
      query: {limit: 100, page: 1}
    }
    this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
    this.navigationService.setShowLayerTable(true);
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.sessionService.setAdvancedToolsSearchPayload(payload);
  }

  validateInputs() {
    if(!this.showAdditionalInputs) {
      this.hasErrors = false;
      this.errorMessage = "";
    } else {
      // if (this.hasErrors) return;
      for(let i = 0; i < this.additionalInputs.length; i++) {
        let input = this.additionalInputs[i];
        const value = (this.inputParams[this.additionalInputKeys[i]] ? this.inputParams[this.additionalInputKeys[i]].toString() : null) || (Array.isArray(this.additionalInputs[i][this.additionalInputKeys[i]]) ? null : this.additionalInputs[i][this.additionalInputKeys[i]]);
        if(input.mandatory) { //check if the input is mandatory or not.
          if(value && value.toString() && value.toString().length) {
            if (input.type === 'input') {
              switch(this.selectedToolIndex) {
                case 5: //can be of negative value
                if(this.inputParams.units ===  'degrees'){
                if(this.inputParams.radius >3 || this.inputParams.radius < 0.1) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors_test = true;
                    this.toastr.error("Kindly enter value in range 0.1 to 3");
                    return;
                  } else {
                    this.hasErrors_test = false;
                    this.errorMessage_test = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                }
                break;
                case 14:
                  if(!(/^-?[0-9]\d*(\.\d+)?$/.test(this.inputParams[this.additionalInputKeys[i]]))) {
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive or negative number`;
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                  }
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.distance >3 || this.inputParams.distance < 0.1){
                        this.inputParams[this.additionalInputKeys[i]] = null;
                        this.hasErrors_test = true;
                        this.toastr.error("Kindly enter value in range 0.1 to 3");
                        return;
                      } else {
                        this.hasErrors_test = false;
                        this.errorMessage_test = "";
                        this.inputParams[this.additionalInputKeys[i]] = +value;
                      }
                  }
                  break;
                case 10:
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.distance >3 || this.inputParams.distance < 0.1){
                      this.inputParams[this.additionalInputKeys[i]] = null;
                      this.hasErrors_test = true;
                      this.toastr.error("Kindly enter value in range 0.1 to 3");
                      return;
                    } else {
                      this.hasErrors_test = false;
                      this.errorMessage_test = "";
                      this.inputParams[this.additionalInputKeys[i]] = +value;
                    }
                  }
                break;
                case 11:
                case 12:
                case 18:
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.cellSize >3 || this.inputParams.cellSize < 0.1){
                        this.inputParams[this.additionalInputKeys[i]] = null;
                        this.hasErrors_test = true;
                        this.toastr.error("Kindly enter value in range 0.1 to 3");
                        return;
                      } else {
                        this.hasErrors_test = false;
                        this.errorMessage_test = "";
                        this.inputParams[this.additionalInputKeys[i]] = +value;
                      }
                  }
                  break;
                case 27:
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.cellSide >3 || this.inputParams.cellSide < 0.1){
                        this.inputParams[this.additionalInputKeys[i]] = null;
                        this.hasErrors_test = true;
                        this.toastr.error("Kindly enter value in range 0.1 to 3");
                        return;
                      } else {
                        this.hasErrors_test = false;
                        this.errorMessage_test = "";
                        this.inputParams[this.additionalInputKeys[i]] = +value;
                      }
                  }
                  break;
                case 28:
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.cellSide >3 || this.inputParams.cellSide < 0.1){
                      this.inputParams[this.additionalInputKeys[i]] = null;
                      this.hasErrors_test = true;
                      this.toastr.error("Kindly enter value in range 0.1 to 3");
                      return;
                    } else {
                      this.hasErrors_test = false;
                      this.errorMessage_test = "";
                      this.inputParams[this.additionalInputKeys[i]] = +value;
                    }
                  }
                  break;
                case 29:
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.cellSide >3 || this.inputParams.cellSide < 0.1){
                      this.inputParams[this.additionalInputKeys[i]] = null;
                      this.hasErrors_test = true;
                      this.toastr.error("Kindly enter value in range 0.1 to 3");
                      return;
                    } else {
                      this.hasErrors_test = false;
                      this.errorMessage_test = "";
                      this.inputParams[this.additionalInputKeys[i]] = +value;
                    }
                  }
                  break;
                case 30:
                  if(!(/^[0-9]\d*(\.\d+)?$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.toastr.error("Kindly enter value in range 0.1 to 3");
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  if(this.inputParams.units ===  'degrees'){
                    if(this.inputParams.cellSide >3 || this.inputParams.cellSide < 0.1){
                      this.inputParams[this.additionalInputKeys[i]] = null;
                      this.hasErrors_test = true;
                      this.errorMessage_test = `${this.additionalInputs[i].display_text} range is from 0.1 to 3`;
                      return;
                    } else {
                      this.hasErrors_test = false;
                      this.errorMessage_test = "";
                      this.inputParams[this.additionalInputKeys[i]] = +value;
                    }
                  }
                  break;
                case 15:
                  const iValue = (+value).toFixed(20);
                  if(!(/^[0-1]?(\.\d+)?$/.test(iValue)) || (+value > 1) || (+value < 0)) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} should be a decimal value from 0 to 1`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 22:
                  if(!(/^[0-9]*$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
              }
            } else if(input.type === 'dropdown' || input.type === 'multi_select' || input.type === 'switch') {
              this.hasErrors = false;
              this.errorMessage = "";
            }
          } else {
            this.hasErrors = true;
            this.errorMessage = `${this.additionalInputs[i].display_text} cannot be empty`;//this works
            return;
          }
        } else {
          if(value && value.toString() && value.toString().length) {
            // const value = this.inputParams[this.additionalInputKeys[i]];
            if (input.type === 'input') {
              switch(this.selectedToolIndex) {
                case 5: //can be of negative value
                case 14:
                  if(!(/^-?[0-9]\d*(\.\d+)?$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive or negative number`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 10:
                case 11:
                  if (this.inputParams.units == "degrees") {
                    if (!(/^[0.1-3]?(\.\d+)?$/.test(value))) {
                       this.inputParams[this.additionalInputKeys[i]] = null;
                       this.hasErrors_test = true;
                       //this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive or negative number`;
                       this.toastr.error("Kindly enter value in range 0.1 to 3");
                       return;
                    } else {
                       this.hasErrors = false;
                       this.errorMessage = "";
                       this.inputParams[this.additionalInputKeys[i]] = +value;
                    }
                 }
                 break;
                case 12:
                case 18:
                case 27:
                case 28:
                case 29:
                case 30:
                  if(!(/^[0-9]\d*(\.\d+)?$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 15:
                  const iValue = (+value).toFixed(20);
                  if(!(/^[0-1]?(\.\d+)?$/.test(iValue)) || (+value > 1) || (+value < 0)) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} should be a decimal value from 0 to 1`;
                    return;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
                  }
                  break;
                case 22:
                  if(!(/^[0-9]*$/.test(value))) {
                    this.inputParams[this.additionalInputKeys[i]] = null;
                    this.hasErrors = true;
                    this.errorMessage = `${this.additionalInputs[i].display_text} must contain only positive number`;
                  } else {
                    this.hasErrors = false;
                    this.errorMessage = "";
                    this.inputParams[this.additionalInputKeys[i]] = +value;
              }
                  break;
              }
            } else if(input.type === 'dropdown' || input.type === 'multi_select' || input.type === 'switch') {
              this.hasErrors = false;
              this.errorMessage = "";
            }
          } else {
            this.hasErrors = false;
            this.errorMessage = "";
          }
        }
      }
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  valueToggled(key, event) {
    this.inputParams[key] = event.checked;
    this.validateInputs();
    this.showHideChoices();
  }

  valueSelected(key, value) {
    this.inputParams[key] = value;
    if([ADVANCED_TOOL_TYPE['isobands'], ADVANCED_TOOL_TYPE['isolines']].includes(this.selectedToolIndex) && (key === 'property')) {
      this._store.dispatch(new GetUniquePropertyValuesStartAction(
        this.selectedLayer1 ? this.selectedLayer1.parent_layer_id : null,
        { property: value }
      ));
    }
    this.hasErrors_disdrop = false;
    this.validateInputs();
    this.showHideChoices();
  }

  valueAdded(event, key) {
    if(event.source.selected.length) {
      const selectedValues = event.source.selected.map(option => option.value);
      this.inputParams[key] = selectedValues;
    } else {
      this.inputParams[key] = null;
    }
    this.validateInputs();
    this.showHideChoices();
  }

  valueChanged(inputOptions, key, event) {
    const mandatory = inputOptions.mandatory;
    const inputVal = event.target.value;
    if(!mandatory && !inputVal && inputOptions.display_text != "Max Edge") {
      this.hasErrors = false;
      this.errorMessage = "";
    }
    else if (!mandatory && inputOptions.display_text == "Max Edge") {
      if (this.inputParams.units != null) {
         if (this.inputParams.units == "degrees") {
            if (+(inputVal) <= 0 || !(/^[0.1-3]?(\.\d+)?$/.test(inputVal))) {
               this.hasErrors_test = true;
               this.toastr.error("Kindly enter value in range 0.1 to 3");
            } else {
               this.hasErrors_test = false;
               this.errorMessage = "";
               this.inputParams.maxEdge = inputVal;
            }
         }else {
          if (+(inputVal) <= 0 || !(/^[0-9]?(\.\d+)?$/.test(inputVal))) {
             this.hasErrors_test = true;
             this.toastr.error("Kindly enter positive value");
          } else {
             this.hasErrors_test = false;
             this.errorMessage = "";
             this.inputParams.maxEdge = inputVal;
          }
       }
      } else {
         this.hasErrors_disdrop = true;
         this.toastr.error("Kindly select a Unit");
         this.inputParams.maxEdge = inputVal;
      }
   }
    else if(mandatory && !inputVal) {
      this.inputParams[key] = null;
      this.hasErrors = true;
      this.errorMessage = `${inputOptions.display_text} must contain value`;
    } else {
      switch(this.selectedToolIndex) {
        case 5: //can be of negative value
        case 14:
          if(!(/^-?[0-9]\d*(\.\d+)?$/.test(inputVal))) {
            this.inputParams[key] = null;
            this.hasErrors = true;
            this.errorMessage = `${inputOptions.display_text} must contain only positive or negative number`;
          } else {
            this.hasErrors = false;
            this.errorMessage = "";
            this.inputParams[key] = +inputVal;
          }
          break;
        case 10:
        case 11:
        case 12:
        case 18:
        case 27:
        case 28:
        case 29:
        case 30:
          if(!(/^[0-9]\d*(\.\d+)?$/.test(inputVal))) {
            this.inputParams[key] = null;
            this.hasErrors = true;
            this.errorMessage = `${inputOptions.display_text} must contain only positive number`;
          } else {
            this.hasErrors = false;
            this.errorMessage = "";
            this.inputParams[key] = +inputVal;
          }
          break;
        case 15:
          if(!(/^[0-1]?(\.\d+)?$/.test(inputVal)) || (+inputVal > 1) || (+inputVal < 0)) {
            this.inputParams[key] = null;
            this.hasErrors = true;
            this.errorMessage = `${inputOptions.display_text} should be a decimal value from 0 to 1`;
            return;
          } else {
            this.hasErrors = false;
            this.errorMessage = "";
            this.inputParams[key] = +inputVal;
          }
          break;
        case 22:
          if(!(/^[0-9]*$/.test(inputVal))) {
            this.inputParams[key] = null;
            this.hasErrors = true;
            this.errorMessage = `${inputOptions.display_text} must contain only positive number`;
          } else {
            this.hasErrors = false;
            this.errorMessage = "";
            this.inputParams[key] = +inputVal;
          }
          break;
      }
    }
    this.validateInputs();
    this.showHideChoices();
  }

  toProperCase(str) {
    return str[0].toUpperCase() + str.slice(1);
  }
  checkHeader(){
    return Object.keys(this.DISPLAY_HEADER).some(index => index === this.data.selectedToolIndex.toString())
  }
  // convertValueToKilometers(value: number) {
  //   let convertedNumber = value;
  //   switch(this.inputParams.units) {
  //     case 'yards':
  //       convertedNumber =value * 0.0009144
  //       break;
  //       case 'meters':
  //         convertedNumber =value * 0.001
  //         break;
  //         case 'radians':
  //           convertedNumber =value * 6378.1
  //                 break;
                  
  //   }
  //   return convertedNumber

  // }

  ngOnDestroy() {
    if(this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }

}

export const TILObject: Array<any> = [
  {index: 1, list1: ['point'], list2: ['polygon']}, //point within polygon
  {index: 2, list1: ['point'], list2: []}, // Voronoi
  {index: 3, list1: ['polygon'], list2: []}, // centroid
  {index: 4, list1: ['polygon'], list2: []}, // point on feature
  {index: 5, list1: ['point', 'polygon', 'polyline'], list2: []}, // buffer
  {index: 6, list1: ['polygon'], list2: ['polygon']}, //difference
  {index: 7, list1: ['polygon'], list2: ['polygon']}, // intersect
  {index: 8, list1: ['polygon'], list2: ['polygon']}, // union
  {index: 9, list1: ['point', 'polygon', 'polyline'], list2: []}, // bounding box
  {index: 10, list1: ['onlylinestring'], list2: []}, // along
  {index: 11, list1: ['point'], list2: []}, // concave
  {index: 12, list1: ['point'], list2: []}, // convex
  {index: 13, list1: ['onlypolygon'], list2: []}, // dissolve
  {index: 14, list1: ['linestring'], list2: []}, // line offset
  {index: 15, list1: ['point', 'polygon', 'polyline'], list2: []}, // simplify
  {index: 16, list1: ['point', 'polygon', 'polyline'], list2: []}, // envelope
  {index: 17, list1: ['polygon'], list2: []}, // center of mass
  {index: 18, list1: ['point'], list2: []}, // interpolate
  {index: 19, list1: ['point'], list2: []}, // isobands
  {index: 20, list1: ['point'], list2: []}, // isolines
  {index: 21, list1: ['point'], list2: []}, // tin
  {index: 22, list1: ['point'], list2: []}, // clusters kmeans
  {index: 23, list1: [], list2: []}, // midpoint
  {index: 24, list1: ['onlypolygon'], list2: []},        // tesselate
  {index: 25, list1: ['linestring'], list2: []},        // polygonize
  {index: 26, list1: ['polygon'], list2: []}, // mask
  {index: 27, list1: [], list2: []},  //  hexgrid
  {index: 28, list1: [], list2: []},  //  pointgrid
  {index: 29, list1: [], list2: []},  //  squaregrid
  {index: 30, list1: [], list2: []},  //  trianglegrid
  {index: 31, list1: ['point', 'polygon', 'polyline'], list2: []},  //  center
  {index: 33, list1: ['point', 'polygon', 'polyline'], list2: []}, // merge
  {index: 38, list1: ['point', 'polygon', 'polyline'], list2: []}, // Similar Search
]

export const INPUT_PARAMS_OBJ: any = {
  1: [], // point within polygon
  2: [], // Voronoi
  3: [], // centroid
  4: [], // point on feature
  5: [   // buffer, radius can be of negative value
    {units: ['meters', 'kilometers', 'yards', 'miles', 'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
    {radius: null, display_text: 'Buffer Radius', mandatory: true, type: 'input'},
    {steps: 64, display_text: 'Steps', mandatory: false, type: 'input'}
  ],
  6: [
    { difference: [ 'Difference', 'Symmetrical Difference' ], display_text: 'Difference Type', mandatory: true, type: 'dropdown'}, 
  ], // difference
  7: [], // intersect
  8: [], // union
  9: [], // bounding box
  10: [  // along
    {units: ['kilometers', 'miles', 'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
    {distance: null, display_text: 'Distance', mandatory: true, type: 'input'}
  ],
  11: [  // concave
    {units: [ 'kilometers', 'miles','degrees' ], display_text: 'Units', mandatory: false, type: 'dropdown'},
    {maxEdge: null, display_text: 'Max Edge', mandatory: false, type: 'input'}
  ],
  12: [  // convex
    {concavity: null, display_text: 'Concavity', mandatory: false, type: 'input'}
  ],
  13: [  // dissolve, mulitpolygon features are not supported
    {propertyName: [], display_text: 'Property Name', mandatory: false, type: 'dropdown'}
  ],
  14: [  // line offset, distance can be of negative value
    {units: [ 'inches', 'meters', 'kilometers', 'yards', 'miles' ,'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
    {distance: null, display_text: 'Distance', mandatory: true, type: 'input'}
  ],
  15: [  // simplify
    {tolerance: 1, display_text: 'Tolerance', mandatory: false, type: 'input'},
    {highQuality: false, display_text: 'High Quality', mandatory: false, type: 'switch'},
    {mutate: false, display_text: 'Mutate', mandatory: false, type: 'switch'}
  ],
  16: [],  // envelope
  17: [],  // center of mass
  18: [    // interpolate
    {units: [  'meters','kilometers','miles','degrees'], display_text: 'Units', mandatory: false, type: 'dropdown', toolTip_key: 'Select units of measurement for cell size'},
{cellSize: [], display_text: 'Cell Size', mandatory: true, type: 'input', toolTip_key: 'Input cell size to be used for creating interpolation grids'},
    {gridType: ['square', 'point', 'hex', 'triangle'], display_text: 'Grid Type', mandatory: true, type: 'dropdown', toolTip_key:'Select type of interpolation grid geometries' },
    {property: [], display_text: 'Interpolation Attribute', mandatory: true, type: 'dropdown', toolTip_key: 'Select a numeric attribute which will be used as the interpolation parameter'},
    {weight: 1, display_text: 'Weight', mandatory: false, type: 'input', toolTip_key:'A higher value increases the impact of the input parameters. Use the default value of 1, if unsure of the weight parameter' }
  ],
  19: [    // isobands
    {property: [], display_text: 'Select Interpolation Attribute', mandatory: true, type: 'dropdown', toolTip_key: 'Select a numeric attribute to use for interpolation'},
    {breaks: [], display_text: 'Choose Breaks/Intervals', mandatory: true, type: 'multi_select'}
  ],
  20: [   // isolines
    {property: [], display_text: 'Property', mandatory: true, type: 'dropdown'},
    {breaks: [], display_text: 'Breaks', mandatory: true, type: 'multi_select'}
  ],
  21: [    // tin
    {property: [], display_text: 'Property', mandatory: true, type: 'dropdown'}
  ],
  22: [   //  clusters kmeans
    {numberOfClusters: null, display_text: 'Number of Clusters', mandatory: true, type: 'input'},
    {mutate: false, display_text: 'Mutate', mandatory: false, type: 'switch'}
  ],
  23: [], //  midpoint
  24: [], //  tesselate
  25: [], //  polygonize
  26: [], //  mask
  27: [   //  hexgrid
    {units: ['meters', 'kilometers', 'yards', 'miles', 'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
{cellSide: null, display_text: 'Hexagon Edge Length', mandatory: true, type: 'input'},
  ],
  28: [   //  pointgrid
    {units: ['meters', 'kilometers','yards','miles', 'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
{cellSide: null, display_text: 'Point Distance', mandatory: true, type: 'input'},
  ],
  29: [   //  squaregrid
    {units: ['meters', 'kilometers','yards','miles', 'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
{cellSide: null, display_text: 'Square Edge Length', mandatory: true, type: 'input'},
  ],
  30: [   //  trianglegrid
    {units: ['meters', 'kilometers','yards','miles', 'degrees'], display_text: 'Units', mandatory: false, type: 'dropdown'},
{cellSide: null, display_text: 'Triangle Edge Length', mandatory: true, type: 'input'},
  ],
  31: [], //  center
  33: [], // merge,
  38:[]
}

export const TypeSubTypeObject: any = {
  point: ['point'],
  polygon: ['polygon', 'multipolygon'],
  onlypolygon: ['polygon'],
  polyline: ['polyline', 'multipolyline', 'linestring', 'multilinestring'],
  onlylinestring: ['linestring'],
  linestring: ['linestring', 'multilinestring']
}

// export const CHOICES: any = {
//   1: 'Query Builder',
//   2: 'Select By Graphics',
//   3: 'All Data'
// }

export const CHOICES = [
  {
    index: 1,
    display_text: 'Select By Data Queries',
    icon: "assets/images/que.svg",
    icon2: "assets/images/qu.svg"
  },
  {
    index: 2,
    display_text: 'Select By Graphics',
    icon: "assets/images/grapi.svg",
    icon2: "assets/images/graphics_icon_active.svg"
  },
  {
    index: 3,
    display_text: 'All Data',
    icon: "assets/images/all_data.svg",
    icon2: "assets/images/all_data_white.png"
  },
]
export const GEOMETRY_OPTIONS = [
  {
    type: 'point',
    display_text: 'Point Selection',
    icon: 'assets/images/Darkpoint.svg'
  },
  {
    type: 'polyline',
    display_text: 'Polyline Selection',
    icon: 'assets/images/Darkpolyline.svg'
  },
  {
    type: 'polygon',
    display_text: 'Polygon Selection',
    icon: 'assets/images/Dankpolygon.svg'
  }
]

export const RELATED_LAYER_TYPES = {
  'point': ['point', 'multipoint'],
  'multipoint': ['point', 'multipoint'],
  'polygon': ['polygon', 'multipolygon'],
  'multipolygon': ['polygon', 'multipolygon'],
  'polyline': ['polyline', 'linestring', 'multilinestring'],
  'linestring': ['polyline', 'linestring', 'multilinestring'],
  'multilinestring': ['polyline', 'linestring', 'multilinestring'],
}


