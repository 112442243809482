import { Action } from '@ngrx/store';


import {CopyLayerToSectionRequest, MoveLayerToSectionRequest} from '../modals/copy-layer-to-section';

export enum SessionManagementTypes {
  clearFileStorageAction = "[SESSION_MANAGEMENT] Clear File Storage",
  clearFileStorageActionV3 = "[SESSION_MANAGEMENT] Clear File Storage V3",
clearFileStorageSuccess = "[SESSION_MANAGEMENT] Clear File Success",
  clearFileStorageActionSingle="[SESSION_MANAGEMENT] Clear Single File Storage",
  clearFileStorageActionSingleV3="[SESSION_MANAGEMENT] Clear Single File Storage V3",
clearFileStorageSuccessSingle = "[SESSION_MANAGEMENT] Clear Single File Success",
  logOutSession = "[SESSION_MANAGEMENT] Log Out Session",
  createSessionStart = "[SESSION_MANAGEMENT] Create Session Start",
  CreateSessionSuccessAction = "[SESSION_MANAGEMENT] Create Session Success",
  addLayersStart = "[SESSION_MANAGEMENT] Add Layers Start",
  addLayersSuccess = "[SESSION_MANAGEMENT] Add Layers Success",
  updateSessionStart = "[SESSION_MANAGEMENT] Update Session Start",
  updateSessionSuccess = "[SESSION_MANAGEMENT] Update Session Success",
  copyLayerStart = "[SESSION_MANAGEMENT] Copy Layer Start",
  copyLayerSuccess = "[SESSION_MANAGEMENT] Copy Layer Success",
  getSessionDetailsStart = "[SESSION_MANAGEMENT] Get Session Details Start",
  getSessionDetailsSuccess = "[SESSION_MANAGEMENT] Get Session Details Success",
  getSessionsStart = "[SESSION_MANAGEMENT] Get Sessions Start",
  getSessionsSuccess = "[SESSION_MANAGEMENT] Get Sessions Success",
  deleteSessionStart = "[SESSION_MANAGEMENT] Delete Session Start",
  deleteSessionSuccess = "[SESSION_MANAGEMENT] Delete Session Success",
  copySessionStart = "[SESSION_MANAGEMENT] Copy Sessiion Start",
  copySessionSuccess = "[SESSION_MANAGEMENT] Copy Sessiion Success",
  privatizeSessionStart = "[SESSION_MANAGEMENT] Privatize Session Start",
  privatizeSessionSuccess = "[SESSION_MANAGEMENT] Privatize Session Success",
  assignUsersStart = "[SESSION_MANAGEMENT] Assign Users Start",
  assignUsersSuccess = "[SESSION_MANAGEMENT] Assign Users Success",
  assignSurveyorsStart = "[SESSION_MANAGEMENT] Assign Surveyors Start",
  assignSurveyorsSuccess = "[SESSION_MANAGEMENT] Assign Surveyors Success",
  getAssignedUsersStart = "[SESSION_MANAGEMENT] Get Assigned Users Start",
  getAssignedUsersSuccess = "[SESSION_MANAGEMENT] Get Assigned Users Success",
  getAssignedSurveyorsStart = "[SESSION_MANAGEMENT] Get Assigned Surveyors Start",
  getAssignedSurveyorsSuccess = "[SESSION_MANAGEMENT] Get Assigned Surveyors Success",
  getUsersListStart = "[SESSION_MANAGEMENT] Get Users List Start",
  getUsersListSuccesss = "[SESSION_MANAGEMENT] Get Users List Success",
  getSurveyorsListStart = "[SESSION_MANAGEMENT] Get Surveyors List Start",
  getSurveyorsListSuccesss = "[SESSION_MANAGEMENT] Get Surveyors List Success",
  getLayerTableDataStart = "[LAYER_TABLE] Get Layer Table Data Start",
  getLayerTableDataSuccess = "[LAYER_TABLE] Get Layer Table Data Success",
  approveLayerTableDataStart = "[LAYER_TABLE] Approve Layer Table Data Start",
  approveLayerTableDataSuccess = "[LAYER_TABLE] Approve Layer Table Data Success",
  getLayerAttributesStart = "[SESSION_MANAGEMENT] Get Layer Attributes Start",
  getLayerAttributesSuccess = "[SESSION_MANAGEMENT] Get Layer Attributes Success",
  getUniquePropertyValuesStart = "[SESSION_MANAGEMENT] Get Unique Property Values Start",
  getUniquePropertyValuesSuccess = "[SESSION_MANAGEMENT] Get Unique Property Values Success",
  getStyleTypesStart = "[SESSION_MANAGEMENT] Get Style Types Start",
  getStyleTypesSuccess = "[SESSION_MANAGEMENT] Get Style Types Success",
  getSavedQueriesStart = "[SESSION_MANAGEMENT] Get Saved Queries Start",
  getSavedQueriesSuccess = "[SESSION_MANAGEMENT] Get Saved Queries Success",
  getQueryTableDataStart = "[SESSION_MANAGEMENT] Get Query Table Data Start",
  getQueryTableDataSuccess = "[SESSION_MANAGEMENT] Get Query Table Data Success",
  getGraphicsLayerDataStart = "[SESSION_MANAGEMENT] Get Graphics Layer Data Start",
  getGraphicsLayerDataSuccess = "[SESSION_MANAGEMENT] Get Graphics Layer Data Success",
  getCollectedDataRowDetailsStart = "[SESSION_MANAGEMENT] Get Collected Data Row Details Start",
  getCollectedDataRowDetailsSuccess = "[SESSION_MANAGEMENT] Get Collected Data Row Details Success",
  saveCollectedDataRowDetailsStart = "[SESSION_MANAGEMENT] Save Collected Data Row Details Start",
  saveCollectedDataRowDetailsSuccess = "[SESSION_MANAGEMENT] Save Collected Data Row Details Success",
  sendDataForApprovalStart = "[SESSION_MANAGEMENT] Send Data For Approval Start",
  sendDataForApprovalSuccess = "[SESSION_MANAGEMENT] Send Data For Approval Success",
  addDataForApprovalStart = "[SESSION_MANAGEMENT] Add Data For Approval Start",
  addDataForApprovalSuccess = "[SESSION_MANAGEMENT] Add Data For Approval Success",
  getPendingFeaturesStart = "[SESSION_MANAGEMENT] Get Pending Features Start",
  getPendingFeaturesSuccess = "[SESSION_MANAGEMENT] Get Pending Features Success",
  approveRequestedFeatureEditStart = "[SESSION_MANAGEMENT] Approve Requested Feature Edit Start",
  approveRequestedFeatureEditSuccess = "[SESSION_MANAGEMENT] Approve Requested Feature Edit Success",
  uploadStyleForSessionPublishStart = "[SESSION_MANAGEMENT] Upload Style For Session Publish Start",
  uploadStyleForSessionPublishSuccess = "[SESSION_MANAGEMENT] Upload Style For Session Publish Success",
  getAllUnapprovedFeatureEditsStart = "[SESSION_MANAGEMENT] Get All Unapproved Features Start",
  getAllUnapprovedFeatureEditsSuccess = "[SESSION_MANAGEMENT] Get All Unapproved Features Success",
  clearData = "[SESSION_MANAGEMENT] Clear Data",
  exportDataStart = "[SESSION_MANAGEMENT] Export Data Start",
  exportDataSuccess = "[SESSION_MANAGEMENT] Export Data Success",
  exportQuerySearchDataStart = "[SESSION_MANAGEMENT] Export Query Search Data Start",
  exportQuerySearchDataSuccess = "[SESSION_MANAGEMENT] Export Query Search Data Success",
  getLayerAttributeInfoStart = "[SESSION_MANAGEMENT] Get Layer Attribute Info Start",
  getLayerAttributeInfoSuccess = "[SESSION_MANAGEMENT] Get Layer Attribute Info Success",
  getAdvancedToolsSearchResultStart = "[SESSION_MANAGEMENT] Get Advanced Tools Search Result Start",
  getAdvancedToolsSearchResultSuccess = "[SESSION_MANAGEMENT] Get Advanced Tools Search Result Success",
  createTemporaryLayerStart = "[SESSION_MANAGEMENT] Create Temporary Layer Start",
  createTemporaryLayerSuccess = "[SESSION_MANAGEMENT] Create Temporary Layer Success",
  createPermanentLayerStart = "[SESSION_MANAGEMENT] Create Permanent Layer Start",
  createPermanentLayerSuccess = "[SESSION_MANAGEMENT] Create Permanent Layer Success",
  exportSearchResultStart = "[SESSION_MANAGEMENT] Export Search Result Start",
  exportSearchResultSuccess = "[SESSION_MANAGEMENT] Export Search Result Success",
  exportGraphicStartAction = "[SESSION MANAGEMENT] Export graphic start ",
  exportGraphicSuccessAction = "[SESSION MANAGEMENT] Export graphics success",
  updateQueryStartAction = "[SESSION MANAGEMENT] update session start action",
  deleteQueryStartAction = "[SESSION MANAGEMENT] delete query start action",
  uploadFileStartAction = '[SESSION MANAGEMENT] upload file start action',
  uploadFileSuccessAction = '[SESSION MANAGEMENT] upload file success action',
  getProcessingFilesStartAction = '[Session Management] processing file start action',
  getProcessingFileSuccessAction = '[Session Management] processing file success action',
  updateIconStartAction = '[Session Management] update icon start',
  updateIconSuccessAction = '[Session Management] update icon success',
  getIconsListStartAction = '[Session Management] get icons list',
  getIconsListSuccessAction = '[Session Management] get icons list success',
  getOSMTranserveSourcesStart = "[SESSION MANAGEMENT] Get OSMTranserve Sources Start",
  getOSMTranserveSourcesSuccess = "[SESSION MANAGEMENT] Get OSMTranserve Sources Success",
  getProcessedLayerListStartAction = '[SESSION MANAGEMENT] Get Processed List Start Action',
  getProcessedLayerListSuccessAction = "[SESSION MANAGEMENT] Get Processed List Sucess Action",
  getSearchedMapResults = "[SESSION MANAGEMENT] Get Searched Map Results",
  getSearchedMapResultsSuccess = "[SESSION MANAGEMENT] Get Searched Map Results Success",
  getSearchedHereMapResults = "[SESSION MANAGEMENT] Get Searched Here Map Results",
  getSearchedHereMapResultsSuccess = "[SESSION MANAGEMENT] Get Searched Here Map Results Success",
  getPlaceDetails = "[SESSION MANAGEMENT] Get Place Details",
  getPlaceDetailsSuccess = "[SESSION MANAGEMENT] Get Place Details Success",
  getRasterToolProcessingStart = "[SESSION MANAGEMENT] Get Raster Tool Processing Start",
  getRasterToolProcessingSuccess = "[SESSION MANAGEMENT] Get Raster Tool Processing Success",
  processingLayerDeleteStart = "[SESSION MANAGEMENT] delete processing layer start action",
  processingLayerDeleteSuccess = "[SESSION MANAGEMENT] delete processing layer success action",
  exportAsGeoTiffStart = "[SESSION MANAGEMENT] Export as geo-tiff start",
  exportAsGeoTiffSuccess = "[SESSION MANAGEMENT] Export as geo-tiff success",
  libraryUploadAuthenticationStart = "[DATA-LIBRARY UPLOAD] Library Upload Authentication Start",
  libraryUploadAuthenticationSuccess = "[DATA-LIBRARY UPLOAD] Library Upload Authentication Success",
  deleteNotificationStart = "[SESSION-MANAGEMENT] Delete Notification Start Action",
  deleteNotificationSuccess = "[SESSION-MANAGEMENT] Delete Notification Success Action",
  clearAllNotificationStart = "[SESSION-MANAGEMENT] Clear Notification Start Action",
  clearAllNotificationSuccess = "[SESSION-MANAGEMENT] Clear Notification Success Action",
  getGraduatedClassificationDataStartAction = "[SESSION-MANAGEMENT] Get Graduated Classification Start Action",
  getGraduatedClassificationDataSuccessAction = "[SESSION-MANAGEMENT] Get Graduated Classification Success Action",
  getDataCollectedLayerDataStart = "[SESSION-MANAGEMENT] Get Data Collected Layer Data Start",
  getDataCollectedLayerDataSuccess = "[SESSION-MANAGEMENT] Get Data Collected Layer Data Success",
  DCLayerDataApprovalStart = "[SESSION-MANAGEMENt] DCLayer Data Approval Start Action",
  DCLayerDataApprovalSuccess = "[SESSION-MANAGEMENt] DCLayer Data Approval Success Action",
  getAllLayersInfoStart = "[SESSION-MANAGEMENT] Get All Layers Info Start Action",
  getAllLayersInfoSuccess = "[SESSION-MANAGEMENT] Get All Layers Info Success Action",
  getLayerNameDetailsStart = "[SESSION-MANAGEMENT] Get Session Name Start Action",
  getLayerNameDetailsSuccess = "[SESSION-MANAGEMENT] Get Session Name Success Action",
  createNetworkLayersStart = "[SESSION-MANAGEMENT] Create Network Layers Start",
  createNetworkLayersSuccess = "[SESSION-MANAGEMENT] Create Network Layers Success",
  getSimilaritySearchStart = "[SESSION-MANAGEMENT] Get Similaity Search Action",
  getSimilaritySearchSuccess = "[SESSION-MANAGEMENT] Get Similaity Search Action Success",
  getSelectByLocationStart = "[SESSION-MANAGEMENT] Get Select By Location Action ",
  getSelectByLocationSuccess = "[SESSION-MANAGEMENT] Get Select By Location Action Success",
  getDataInsightdLayerDataStart = "[SESSION-MANAGEMENT] Get Layer Data in Data Insights Action ",
  getDataInsightdLayerDataSuccess = "[SESSION-MANAGEMENT] Get Layer Data in Data Insights Success Action",
  getDataInsightStart = "[SESSION-MANAGEMENT] Get Graph in Data Insights Success Action",
  getDataInsightSuccess = "[SESSION-MANAGEMENT] Get Graph in Data Insights Success Action",
  getDataInsightStartAction = "getDataInsightStartAction",
  getDataInsightSuccessAction = "getDataInsightSuccessAction",
  copyLayerFromSession = "[SESSION-MANAGEMENT] Edit Style Component Copy",
  moveLayerFromSession = "[SESSION-MANAGEMENT] Edit Style Component Move",
  copyLayerFromSessionSuccess = "[SESSION-MANAGEMENT] Edit Style Component Copy Success",
  moveLayerFromSessionSuccess = "[SESSION-MANAGEMENT] Edit Style Component Move Success"
}

export class LogOutSessionAction implements Action {
  readonly type = SessionManagementTypes.logOutSession;
  constructor() { }
}


export class ClearFileStorageAction implements Action{
  readonly type = SessionManagementTypes.clearFileStorageAction;
  constructor(){}
}
//action created to delete all notification for v3 API
export class ClearFileStorageActionV3 implements Action{
  readonly type = SessionManagementTypes.clearFileStorageActionV3;
  constructor(){}
}

export class ClearFileStorageActionSingle implements Action{
  readonly type = SessionManagementTypes.clearFileStorageActionSingle;
  deleteId
  constructor(public data :any){
    this.deleteId=data;
  }
}
//action created to delete single notification for v3 API
export class ClearFileStorageActionSingleV3 implements Action{
  readonly type = SessionManagementTypes.clearFileStorageActionSingleV3;
  deleteId
  constructor(public data :any){
    this.deleteId=data;
  }
}


export class ClearFileStorageSuccess implements Action{
  readonly type = SessionManagementTypes.clearFileStorageSuccess;
  data
  constructor(public response :any){
    this.data = response
  }
}

export class ClearFileStorageSuccessSingle implements Action{
  readonly type = SessionManagementTypes.clearFileStorageSuccessSingle;
  data
  constructor(public response :any){
    this.data = response
  }
}

export class CreateSessionStartAction implements Action {
  readonly type = SessionManagementTypes.createSessionStart;
  constructor() { }
}

export class CreateSessionSuccessAction implements Action {
  readonly type = SessionManagementTypes.CreateSessionSuccessAction;
  data;
  constructor(public response: any) {
    this.data = response;
  }
}

export class AddLayersStartAction implements Action {
  readonly type = SessionManagementTypes.addLayersStart;
  constructor(public payload: any) { }
}

export class AddLayersSuccessAction implements Action {
  readonly type = SessionManagementTypes.addLayersSuccess;
  data;
  constructor(public result: any) {
    this.data = result;
  }
}

export class UpdateSessionStartAction implements Action {
  readonly type = SessionManagementTypes.updateSessionStart;
  constructor(public datum: any, public sessionId: string) { }
}

export class UpdateSessionSuccessAction implements Action {
  readonly type = SessionManagementTypes.updateSessionSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class CopyLayerStartAction implements Action {
  readonly type = SessionManagementTypes.copyLayerStart;
  constructor(public sessionId: string, public datum: any) { }
}

export class CopyLayerSuccessAction implements Action {
  readonly type = SessionManagementTypes.copyLayerSuccess;
  constructor() { }
}

export class GetSessionDetailsStartAction implements Action {
  readonly type = SessionManagementTypes.getSessionDetailsStart;
  constructor(public payload: any) { }
}

export class GetSessionDetailsSuccessAction implements Action {
  readonly type = SessionManagementTypes.getSessionDetailsSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetSessionsStartAction implements Action {
  readonly type = SessionManagementTypes.getSessionsStart;
  constructor(public payload: any) { }
}

export class GetSessionsSuccessAction implements Action {
  readonly type = SessionManagementTypes.getSessionsSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class DeleteSessionStartAction implements Action {
  readonly type = SessionManagementTypes.deleteSessionStart;
  constructor(public sessionId: string) { }
}

export class DeleteSessionSuccessAction implements Action {
  readonly type = SessionManagementTypes.deleteSessionSuccess;
  constructor(public response) { }
}

export class CopySessionStartAction implements Action {
  readonly type = SessionManagementTypes.copySessionStart;
  constructor(public sessionId: string) { }
}

export class CopySessionSuccessAction implements Action {
  readonly type = SessionManagementTypes.copySessionSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class PrivatizeSessionStartAction implements Action {
  readonly type = SessionManagementTypes.privatizeSessionStart;
  constructor(public sessionId: string, public sessionName: string) { }
}

export class PrivatizeSessionSuccessAction implements Action {
  readonly type = SessionManagementTypes.privatizeSessionSuccess;
  sessionName;
  constructor(name: string) {
    this.sessionName = name;
  }
}

export class AssignUsersStartAction implements Action {
  readonly type = SessionManagementTypes.assignUsersStart;
  constructor(public sessionId: string, public payload: any) { }
}

export class AssignUsersSuccessAction implements Action {
  readonly type = SessionManagementTypes.assignUsersSuccess;
  constructor() { }
}

export class AssignSurveyorsStartAction implements Action {
  readonly type = SessionManagementTypes.assignSurveyorsStart;
  constructor(public sessionId: string, public payload: any) { }
}

export class AssignSurveyorsSuccessAction implements Action {
  readonly type = SessionManagementTypes.assignSurveyorsSuccess;
  constructor(public response: any) { }
}

export class GetAssignedUsersStartAction implements Action {
  readonly type = SessionManagementTypes.getAssignedUsersStart;
  constructor(public sessionId: string) { }
}

export class GetAssignedUsersSuccessAction implements Action {
  readonly type = SessionManagementTypes.getAssignedUsersSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetAssignedSurveyorsStartAction implements Action {
  readonly type = SessionManagementTypes.getAssignedSurveyorsStart;
  constructor(public sessionId: string) { }
}

export class GetAssignedSurveyorsSuccessAction implements Action {
  readonly type = SessionManagementTypes.getAssignedSurveyorsSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetUsersListStartAction implements Action {
  readonly type = SessionManagementTypes.getUsersListStart;
  constructor(public payload: any) { }
}

export class GetUsersListSuccessAction implements Action {
  readonly type = SessionManagementTypes.getUsersListSuccesss;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetSurveyorsListStartAction implements Action {
  readonly type = SessionManagementTypes.getSurveyorsListStart;
  constructor() { }
}

export class GetSurveyorsListSuccessAction implements Action {
  readonly type = SessionManagementTypes.getSurveyorsListSuccesss;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetLayerTableDataStartAction implements Action {
  readonly type = SessionManagementTypes.getLayerTableDataStart;
  constructor(public layerId: string, public params: any) { }
}

export class GetLayerTableDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.getLayerTableDataSuccess;
  data;
  constructor(result: any) {
    this.data = result;
  }
}

export class ApproveLayerTableDataStartAction implements Action {
  readonly type = SessionManagementTypes.approveLayerTableDataStart;
  constructor(public layerEntryId: string, public params: any) { }
}

export class ApproveLayerTableDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.approveLayerTableDataSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetLayerAttributesStartAction implements Action {
  readonly type = SessionManagementTypes.getLayerAttributesStart;
  constructor(public layerId: string, public index ?: number) { }
}

export class GetLayerAttributesSuccessAction implements Action {
  readonly type = SessionManagementTypes.getLayerAttributesSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class GetUniquePropertyValuesStartAction implements Action {
  readonly type = SessionManagementTypes.getUniquePropertyValuesStart;
  constructor(public layerId: string, public payload: any) { }
}

export class GetUniquePropertyValuesSuccessAction implements Action {
  readonly type = SessionManagementTypes.getUniquePropertyValuesSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class GetStyleTypesStartAction implements Action {
  readonly type = SessionManagementTypes.getStyleTypesStart;
  constructor() { }
}

export class GetStyleTypesSuccessAction implements Action {
  readonly type = SessionManagementTypes.getStyleTypesSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class GetSavedQueriesStartAction implements Action {
  readonly type = SessionManagementTypes.getSavedQueriesStart;
  constructor() { }
}

export class GetSavedQueriesSuccessAction implements Action {
  readonly type = SessionManagementTypes.getSavedQueriesSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class GetQueryTableDataStartAction implements Action {
  readonly type = SessionManagementTypes.getQueryTableDataStart;
  constructor(public layerId: string, public query: string, public apiData: any) { }
}

export class GetQueryTableDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.getQueryTableDataSuccess;
  data;
  pageDetails;
  constructor(result: any) {
    this.data = result.data[0];
    this.pageDetails = {
      pageNumber: result.page,
      totalPages: result.pages,
      totalRecords: result.total
    }
  }
}

export class GetGraphicsLayerDataStartAction implements Action {
  readonly type = SessionManagementTypes.getGraphicsLayerDataStart;
  constructor(public payload: any) { }
}

export class GetGraphicsLayerDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.getGraphicsLayerDataSuccess;
  data;
  constructor(result: any) {
    this.data = result;
  }
}

export class ClearDataAction implements Action {
  readonly type = SessionManagementTypes.clearData;
  data;
  constructor(result: any) {
    this.data = result;
  }
}

export class GetCollectedDataRowDetailsStartAction implements Action {
  readonly type = SessionManagementTypes.getCollectedDataRowDetailsStart;
  constructor(public layer_entry_id: string) { }
}

export class GetCollectedDataRowDetailsSuccessAction implements Action {
  readonly type = SessionManagementTypes.getCollectedDataRowDetailsSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class SaveCollectedDataRowDetailsStartAction implements Action {
  readonly type = SessionManagementTypes.saveCollectedDataRowDetailsStart;
  constructor(public layer_entry_id: string, public payload: any) { }
}

export class SaveCollectedDataRowDetailsSuccessAction implements Action {
  readonly type = SessionManagementTypes.saveCollectedDataRowDetailsSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class SendDataForApprovalStartAction implements Action {
  readonly type = SessionManagementTypes.sendDataForApprovalStart;
  constructor(public layer_entry_id: string, public payload: any) { }
}

export class SendDataForApprovalSuccessAction implements Action {
  readonly type = SessionManagementTypes.sendDataForApprovalSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class AddDataForApprovalStartAction implements Action {
  readonly type = SessionManagementTypes.addDataForApprovalStart;
  constructor(public payload: any) { }
}

export class AddDataForApprovalSuccessAction implements Action {
  readonly type = SessionManagementTypes.addDataForApprovalSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class GetPendingFeaturesStartAction implements Action {
  readonly type = SessionManagementTypes.getPendingFeaturesStart;
  constructor(public layerId: string, public params: any) { }
}

export class GetPendingFeaturesSuccessAction implements Action {
  readonly type = SessionManagementTypes.getPendingFeaturesSuccess;
  data;
  constructor(result: any) { this.data = result; }
}

export class ApproveRequestedFeatureEditStartAction implements Action {
  readonly type = SessionManagementTypes.approveRequestedFeatureEditStart;
  constructor(public payload: any) { }
}

export class ApproveRequestedFeatureEditSuccessAction implements Action {
  readonly type = SessionManagementTypes.approveRequestedFeatureEditSuccess;
  data;
  constructor(public result: any) { this.data = result; }
}

export class UploadStyleForSessionPublishStartAction implements Action {
  readonly type = SessionManagementTypes.uploadStyleForSessionPublishStart;
  constructor(public sessionId: string, public payload: any) { }
}

export class UploadStyleForSessionPublishSuccessAction implements Action {
  readonly type = SessionManagementTypes.uploadStyleForSessionPublishSuccess;
  data;
  constructor(public result: any) { this.data = result; }
}

export class GetAllUnapprovedFeatureEditsStartAction implements Action {
  readonly type = SessionManagementTypes.getAllUnapprovedFeatureEditsStart;
  constructor(public layerID: string) { }
}

export class GetAllUnapprovedFeatureEditsSuccessAction implements Action {
  readonly type = SessionManagementTypes.getAllUnapprovedFeatureEditsSuccess;
  data;
  constructor(public result: any) { this.data = result; }
}

export class ExportDataStartAction implements Action {
  readonly type = SessionManagementTypes.exportDataStart;
  constructor(public payload: any) { }
}

export class ExportDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.exportDataSuccess;
  data;
  constructor(public result: any) { this.data = result; }
}

export class ExportQuerySearchDataStartAction implements Action {
  readonly type = SessionManagementTypes.exportQuerySearchDataStart;
  constructor(public payload: any) { }
}

export class ExportQuerySearchDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.exportQuerySearchDataSuccess;
  data;
  constructor(public result: any) { this.data = result; }
}

export class GetLayerAttributeInfoStartAction implements Action {
  readonly type = SessionManagementTypes.getLayerAttributeInfoStart;
  constructor(public layerID: string, public attr: string) { }
}

export class GetLayerAttributeInfoSuccessAction implements Action {
  readonly type = SessionManagementTypes.getLayerAttributeInfoSuccess;
  data;
  constructor(public result: any) {
    if (result.length) this.data = result[0]
    else this.data = {}
  }
}

export class GetAdvancedToolsSearchResultStartAction implements Action {
  readonly type = SessionManagementTypes.getAdvancedToolsSearchResultStart;
  constructor(public payload: any) { }
}

export class GetAdvancedToolsSearchResultSuccessAction implements Action {
  readonly type = SessionManagementTypes.getAdvancedToolsSearchResultSuccess;
  data;
  pageDetails;
  constructor(result: any) {
    this.data = result.data[0];
    this.pageDetails = {
      pageNumber: result.page,
      totalPages: result.pages,
      totalRecords: result.data[0].total // shows toatal data count
    }
  }
}

export class GetRasterToolProcessingStartAction implements Action {
  readonly type = SessionManagementTypes.getRasterToolProcessingStart;
  constructor(public payload: any) { }
}

export class GetRasterToolProcessingSuccessAction implements Action {
  readonly type = SessionManagementTypes.getRasterToolProcessingSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class CreateTemporaryLayerStartAction implements Action {
  readonly type = SessionManagementTypes.createTemporaryLayerStart;
  constructor(public payload: any) { }
}

export class CreateTemporaryLayerSuccessAction implements Action {
  readonly type = SessionManagementTypes.createTemporaryLayerSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class CreatePermanentLayerStartAction implements Action {
  readonly type = SessionManagementTypes.createPermanentLayerStart;
  constructor(public payload: any) { }
}

export class CreatePermanentLayerSuccessAction implements Action {
  readonly type = SessionManagementTypes.createPermanentLayerSuccess;
  data;
  constructor(result: any) { this.data = result }
}

export class ExportSearchResultStartAction implements Action {
  readonly type = SessionManagementTypes.exportSearchResultStart;
  constructor(public paylod: any) { }
}

export class ExportSearchResultSuccessAction implements Action {
  readonly type = SessionManagementTypes.exportSearchResultSuccess;
  data;
  constructor(public result: any) { this.data = result; }
}
export class ExportGraphicStartAction implements Action {
  readonly type = SessionManagementTypes.exportGraphicStartAction;
  constructor(public payload: any) { }
}
export class ExportGraphicSuccessAction implements Action {
  readonly type = SessionManagementTypes.exportGraphicSuccessAction;
  data;
  constructor(public response: any) {
    this.data = response;
  }
}
export class UpdateQueryStartAction implements Action {
  readonly type = SessionManagementTypes.updateQueryStartAction
  constructor(public id: any, public data: any) { }
}
export class DeleteQueryStartAction implements Action {
  readonly type = SessionManagementTypes.deleteQueryStartAction
  constructor(public id: any) { }
}
export class UploadFileStartAction implements Action {
  readonly type = SessionManagementTypes.uploadFileStartAction
  constructor(public formData: FormData, public id: any, public data: any) { }

}
export class UploadFileSuccessAction implements Action {
  readonly type = SessionManagementTypes.uploadFileSuccessAction;
  constructor(public response: any) { }
}
export class ProcessingFileStartAction implements Action {
  readonly type = SessionManagementTypes.getProcessingFilesStartAction;
  constructor(public sessionId: any) { }
}
export class ProcessingFileSuccessAction implements Action {
  readonly type = SessionManagementTypes.getProcessingFileSuccessAction;
  constructor(public response: any) { }
}
export class UpdateIconStartAction implements Action {
  readonly type = SessionManagementTypes.updateIconStartAction;
  constructor(public iconName: any, public sessionId: string) { }
}
export class UpdateIconSuccessAction implements Action {
  readonly type = SessionManagementTypes.updateIconSuccessAction;
  constructor(public response: any) { }
}

export class GetIconsListStartAction implements Action {
  readonly type = SessionManagementTypes.getIconsListStartAction;
  contructor() { }
}

export class GetIconsSuccessAction implements Action {
  readonly type = SessionManagementTypes.getIconsListSuccessAction;
  constructor(public response: any) { }
}

export class GetOSMTranserveSourcesStartAction implements Action {
  readonly type = SessionManagementTypes.getOSMTranserveSourcesStart;
  contructor() { }
}

export class GetOSMTranserveSourcesSuccessAction implements Action {
  readonly type = SessionManagementTypes.getOSMTranserveSourcesSuccess;
  constructor(public response: any) { }
}
export class GetProcessedListStartAction implements Action {
  readonly type = SessionManagementTypes.getProcessedLayerListStartAction;
  constructor(public url: any) {
  }
}
export class GetProcessingListSuccessAction implements Action {
  readonly type = SessionManagementTypes.getProcessedLayerListSuccessAction;
  constructor(public response) { }
}

export class GetSearchedMapResults implements Action {
  readonly type = SessionManagementTypes.getSearchedMapResults;
  constructor(public searchValue, public payload, public published) { }
}

export class GetSearchedMapResultsSuccess implements Action {
  readonly type = SessionManagementTypes.getSearchedMapResultsSuccess;
  constructor(public response, public published?) { }
}

export class GetSearchedHereMapResults implements Action {
  readonly type = SessionManagementTypes.getSearchedHereMapResults;
  constructor(public searchValue, public payload, public published) { }
}

export class GetSearchedHereMapResultsSuccess implements Action {
  readonly type = SessionManagementTypes.getSearchedHereMapResultsSuccess;
  constructor(public response, public published?) { }
}

export class GetPlaceDetails implements Action {
  readonly type = SessionManagementTypes.getPlaceDetails;
  constructor(public id, public index, public published) { }
}

export class GetPlaceDetailsSuccess implements Action {
  readonly type = SessionManagementTypes.getPlaceDetailsSuccess;
  constructor(public response) { }
}
export class DeleteProcessingStartAction implements Action {
  readonly type = SessionManagementTypes.processingLayerDeleteStart;
  constructor( public payload) {}
}
export class DeleteProcessingSuccessAction implements Action {
  readonly type = SessionManagementTypes.processingLayerDeleteSuccess;
  constructor(public response) {}  
}
export class ExportAsGeoTiffStartAction implements Action {
  readonly type = SessionManagementTypes.exportAsGeoTiffStart;
  constructor(public payload) { }
}
export class ExportAsGeoTiffSuccessAction implements Action {
  readonly type = SessionManagementTypes.exportAsGeoTiffSuccess;
  constructor(public response) {}
}

export class LibraryUploadAuthenticationStartAction implements Action {
  readonly type = SessionManagementTypes.libraryUploadAuthenticationStart;
  constructor() {}
}

export class LibraryUploadAuthenticationSuccessAction implements Action {
  readonly type = SessionManagementTypes.libraryUploadAuthenticationSuccess;
  constructor(public response) {}
}
export class DeleteNotificationStartAction implements Action {
  readonly type = SessionManagementTypes.deleteNotificationStart;
  constructor(public payload) {}
}
export class DeleteNotificationSuccessAction implements Action {
  readonly type = SessionManagementTypes.deleteNotificationSuccess;
  constructor (public response) {}
}
export class ClearAllNotificationStartAction implements Action {
  readonly type = SessionManagementTypes.clearAllNotificationStart;
  constructor( ) {}
}
export class ClearAllNotificationSuccessAction implements Action {
  readonly type = SessionManagementTypes.clearAllNotificationSuccess;
  constructor(public response) {}
}
export class GetClassificationDataStartAction implements Action {
  readonly type = SessionManagementTypes.getGraduatedClassificationDataStartAction;
  constructor(public payload) { }
}
export class GetClassificationDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.getGraduatedClassificationDataSuccessAction;
  constructor (public response) { }
}

export class GetDataCollectedLayerDataStartAction implements Action {
  readonly type = SessionManagementTypes.getDataCollectedLayerDataStart;
  constructor (public payload) { }
}

export class GetDataCollectedLayerDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.getDataCollectedLayerDataSuccess;
  constructor (public response) { }
}

export class DCLayerDataApprovalStartAction implements Action {
  readonly type = SessionManagementTypes.DCLayerDataApprovalStart;
  constructor(public payload) { }
}

export class DCLayerDataApprovalSuccessAction implements Action {
  readonly type = SessionManagementTypes.DCLayerDataApprovalSuccess;
  constructor(public response) { }
}

export class GetAllLayersInfoStartAction implements Action {
  readonly type = SessionManagementTypes.getAllLayersInfoStart;
  constructor(public payload) { }
}

export class GetAllLayersInfoSuccessAction implements Action {
  readonly type = SessionManagementTypes.getAllLayersInfoSuccess;
  constructor(public response) { }
}
export class GetLayerNameStartAction implements Action {
  readonly type = SessionManagementTypes.getLayerNameDetailsStart;
  constructor (public layerdId ) { }
}
export class GetLayerNameSuccessAction implements Action {
  readonly type = SessionManagementTypes.getLayerNameDetailsSuccess;
  constructor(public response) { }
}
export class CreateNetworkLayersStartAction implements Action {
  readonly type = SessionManagementTypes.createNetworkLayersStart;
  constructor(public payload) { }
}

export class CreateNetworkLayersSuccessAction implements Action {
  readonly type = SessionManagementTypes.createNetworkLayersSuccess;
  constructor(public resposne) { }
}

export class GetSimilaritySearchStartAction implements Action {
  readonly type = SessionManagementTypes.getSimilaritySearchStart;
  constructor(public payload: any) { }
}

export class GetSimilaritySearchSuccessAction implements Action {
  readonly type = SessionManagementTypes.getSimilaritySearchSuccess;
  data;
  pageDetails;
  constructor(result: any) {
    this.data = result.data[0];
    this.pageDetails = {
      pageNumber: result.page,
      totalPages: result.pages,
      totalRecords: result.total
    }
  }
}

export class GetSelectByLocationStartAction implements Action {
  readonly type = SessionManagementTypes.getSelectByLocationStart;
  constructor(public payload: any) { }
}

export class GetSelectByLocationSuccessAction implements Action {
  readonly type = SessionManagementTypes.getSelectByLocationSuccess;
  data;
  pageDetails;
  constructor(result: any) {
    this.data = result.data[0];
    this.pageDetails = {
      pageNumber: result.page,
      totalPages: result.pages,
      totalRecords: result.total
    }
  }
}

export class GetDataInsightdLayerDataStartAction implements Action {
  readonly type = SessionManagementTypes.getDataInsightdLayerDataStart;
  constructor(public payload: any) { }
}

export class GetDataInsightdLayerDataSuccessAction implements Action {
  readonly type = SessionManagementTypes.getDataInsightdLayerDataSuccess;
  constructor (public response) { } 
}

export class GetDataInsightStartAction implements Action {
  readonly type = SessionManagementTypes.getDataInsightStartAction;
  constructor(public payload: any) { }
}

export class GetDataInsightSuccessAction implements Action {
  readonly type = SessionManagementTypes.getDataInsightSuccessAction;
  constructor (public response) { } 
}

// Action for copy layer to selected section
export class copyLayerFromSession implements Action {
  readonly type = SessionManagementTypes.copyLayerFromSession;
  constructor( public data: CopyLayerToSectionRequest) { }
}
export class copyLayerFromSessionSuccess implements Action {
  readonly type = SessionManagementTypes.copyLayerFromSessionSuccess;
  constructor() { }
}
// Action for move the layer to selected section
export class moveLayerFromSession implements Action {
  readonly type = SessionManagementTypes.moveLayerFromSession;
  constructor(public data: MoveLayerToSectionRequest) { }
}

export class moveLayerFromSessionSuccess implements Action {
  readonly type = SessionManagementTypes.moveLayerFromSessionSuccess;
  constructor() { }
}

export type SessionManagementActions =
  LogOutSessionAction
  | GetSessionDetailsSuccessAction
  | GetSessionsSuccessAction
  | GetAssignedUsersSuccessAction
  | GetAssignedSurveyorsSuccessAction
  | GetUsersListSuccessAction
  | GetSurveyorsListSuccessAction
  | UpdateSessionSuccessAction
  | GetLayerTableDataSuccessAction
  | GetLayerAttributesSuccessAction
  | GetUniquePropertyValuesSuccessAction
  | GetStyleTypesSuccessAction
  | GetSavedQueriesSuccessAction
  | GetQueryTableDataStartAction
  | GetQueryTableDataSuccessAction
  | GetGraphicsLayerDataSuccessAction
  | AddLayersSuccessAction
  | GetCollectedDataRowDetailsSuccessAction
  | SaveCollectedDataRowDetailsSuccessAction
  | SendDataForApprovalSuccessAction
  | AddDataForApprovalSuccessAction
  | GetPendingFeaturesSuccessAction
  | GetAllUnapprovedFeatureEditsSuccessAction
  | ClearDataAction
  | GetLayerAttributeInfoSuccessAction
  | GetAdvancedToolsSearchResultSuccessAction
  | CreateTemporaryLayerSuccessAction
  | DeleteSessionSuccessAction
  | UploadFileSuccessAction
  | ProcessingFileSuccessAction
  | UpdateIconSuccessAction
  | GetIconsSuccessAction
  | GetOSMTranserveSourcesSuccessAction
  | GetProcessingListSuccessAction
  | GetSearchedMapResults
  | GetSearchedMapResultsSuccess
  | GetPlaceDetailsSuccess
  | GetRasterToolProcessingSuccessAction
  | DeleteProcessingSuccessAction
  | ExportAsGeoTiffSuccessAction
  | DeleteNotificationSuccessAction
  | ClearAllNotificationSuccessAction
  | GetClassificationDataSuccessAction
  | GetDataCollectedLayerDataSuccessAction
  | DCLayerDataApprovalSuccessAction
  | GetAllLayersInfoSuccessAction
  | GetLayerNameSuccessAction
  | GetDataInsightdLayerDataSuccessAction
  | GetDataInsightSuccessAction
  | GetSearchedHereMapResultsSuccess
 
