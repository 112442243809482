export const environment = {
production: true,
envName: 'prod',
 apiPath: "https://tosapi.transerve.com/api/v2",
 sessionApiPath: "https://tosapi.transerve.com/api/v2",
 pbfPath:"https://tosapi.transerve.com/tos-fileserver/protofiles",
 uploadPath: "https://tosapi.transerve.com/api/v3",
 tOsmPbfPath: "https://wms.transerve.com/data",
 rasterPbfPath: "https://tosapi.transerve.com/tos-fileserver/raster-tiles",
 rasterUploadInfoApiPath: "https://tosapi.transerve.com/api/v3",
 socketHost: 'https://tosapi.transerve.com',
 glyphPath: 'https://tosapi.transerve.com/tos-fileserver/fonts/{fontstack}/{range}.pbf',
 spritePath: 'https://tosapi.transerve.com/api/v2/img/sprite',
gaTrackingId: 'UA-195206145-2'
};
