import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/_services/subscription.service';
import { SUBSCRIPTION_ATTRS } from 'src/app/app.constants';
@Component({
  selector: 'app-plan-details-dialog',
  templateUrl: './plan-details-dialog.component.html',
  styleUrls: ['./plan-details-dialog.component.scss']
})
export class PlanDetailsDialogComponent implements OnInit {
  subscriptionArray: Array<Subscription> = [];
  plan: any = null;
  displayedColumns: Array<string> = ['FEATURE', 'PLAN'];
  
  planDetailAttrs: any = {
    "Users Per Organization": "user",
    "WMS/WFS API Hits Per Month": "hits",
    "Data Storage (Per Organization)": "storage",
    ...SUBSCRIPTION_ATTRS
    // "Feature Editing": "featureEditing",
    // "Mobile App Access - iOS and IPad OS Apps": "iosAppAccess",
    // "Mobile App Access - Android Apps": "androidAppAccess",
    // "Advanced Tools - Spatial Analysis": "advancedToolsSpecialAnalysis",
    // "Advanced Tools - Geometry": "advancedToolsGeometry",
    // "Advanced Tools - Grid": "advancedToolsGrid",
  };

  dataSource: Array<any> = [];
  constructor(
    public dialogRef: MatDialogRef<PlanDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.subscriptionArray.push(
      this.subscriptionService.getSelectedPlan().subscribe((p) => {
        this.plan = p;
        console.log(this.plan);
        if (Object.keys(this.plan).length) {
          Object.keys(this.planDetailAttrs).map(key => {
            this.dataSource.push([key, this.formattedPlanValue(this.planDetailAttrs[key])]);
          })
        }
      })
    );
  }

  close() {
    this.dialogRef.close();
  }

  formattedPlanValue(key) {
    const possibleUnlimitedValueKeys = ["user", "hits"];
    const possibleBooleanValueKeys = ["spatialData", "saveAnalytics", "mapSessionsData", "publishMap", "createForms", "mobileSpatial", "mergeCollectedData", "autoMapUpdate", "whiteLabel", "spatialAnalytics", "propertyTax", "perpetualLicense", "clientDatabase"];
    const possibleStorageValueKeys = ["storage"];
    if (possibleUnlimitedValueKeys.includes(key)) {
      return this.plan[key] === -1 ? `Unlimited` : `${this.plan[key]}`;
    } else if (possibleBooleanValueKeys.includes(key)) {
      return this.plan[key] ? true : `--`;
    // } else if (possibleStorageValueKeys.includes(key)) {
    //   return this.plan[key] < 1024 ? `${this.plan[key]} MB` :`${(+this.plan[key])/1024} GB`;
    } else {
      return `${this.plan[key]}`;
    }
  }

}