import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { regressionDataSelector } from "src/app/store/selectors/session-management.selector";
import { NavigationCtrl } from "src/app/_services/navigationCtrl.service";
import { SessionService } from "src/app/_services/session.service";
import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

@Component({
  selector: "app-regression-summary",
  templateUrl: "./regression-summary-dialog.component.html",
  styleUrls: ["./regression-summary-dialog.component.scss"]
})

export class RegressionSummaryDialog {
  subscriptionsArr: Array<Subscription> = [];
  regressionSummary: any = {};
  regressionSummaryKeys: Array<string> = [];
  regSummaryTable: Array<any> = [];
  regTableCols: Array<string> = [];
  buttonPosition: Position = {left: -500, top: -500};
  tableColsObj = TABLE_COLS_OBJ;
  summaryKeysObj = SUMMARY_OBJ;
  topLeftSummaryKeys = ["Dep. Variable", "No. Observations", "Df Residuals", "Df Model", "Covariance Type"];
  topRightSummaryKeys = ["R-squared", "Adj. R-squared", "F-statistic", "Prob (F-statistic)", "Log-Likelihood", "AIC", "BIC"];
  bottomLeftSummaryKeys = ["Omnibus", "Prob(Omnibus)", "Skew", "Kurtosis"];
  bottomRightSummaryKeys = ["Durbin-Watson", "Jarque-Bera (JB)", "Prob(JB)", "Cond. No."];
  showScrollBtns: boolean = false;
  @ViewChild("tableContainer", { read: ElementRef }) tableContainer: ElementRef<any>;
  @ViewChild("tableRef", { read: ElementRef }) tableRef: ElementRef<any>;
  @ViewChild("printElement") printElement: ElementRef<any>;
  // @ViewChild("tableContainer", { read: ElementRef }) tableWrapper: ElementRef<any>;
  constructor(
    private dialogRef: MatDialogRef<RegressionSummaryDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any, 
    private _store: Store<any>,
    private sessionService: SessionService,
    private navigationService: NavigationCtrl
  ) { }
  ngOnInit() {
    this.subscriptionsArr.push(
      this._store.select(regressionDataSelector).subscribe((data: any) => {
        this.regressionSummaryKeys = Object.keys(data.summary).length ? Object.keys(data.summary) : [];
        console.log("Regression"+this.regressionSummaryKeys)
        if(this.regressionSummaryKeys.length) {
          this.regressionSummary = data.summary;
        }
        if(data.table.length) {
          this.regSummaryTable = data.table;
          console.log("Summary Table"+JSON.stringify(this.regSummaryTable[0]))
          this.regTableCols = Object.keys(this.regSummaryTable[0]);
          this.regTableCols = ["variables", "Coefficient[a]", "StdError", "t-Statistic", "Probability[b]", "[0.025", "0.975]"]
          let temp = this.regSummaryTable[0]

        }
      })


      

    );
    
  }

  ngAfterViewChecked() {
    const tableRect = this.tableRef.nativeElement.getBoundingClientRect();
    const boundingRect = this.tableContainer.nativeElement.getBoundingClientRect();
    if(tableRect.width > boundingRect.width) {
      this.showScrollBtns = true;
      this.buttonPosition.top = boundingRect.top;
      this.buttonPosition.left = boundingRect.right - 56;
    } else {
      this.showScrollBtns = false;
    }
  }

  checkIfNumber(element){

    return isNaN(element)

    

  }

  scrollLeft = () => {
    let scrollBy = 0;
    const reminderScroll = this.tableContainer.nativeElement.scrollLeft % 200;
    if (reminderScroll % 200 === 0) {
      scrollBy = 200;
    } else {
      scrollBy = reminderScroll;
    }
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft - scrollBy), behavior: 'smooth' });
  }

  scrollRight = () => {
    this.tableContainer.nativeElement.scrollTo({ left: (this.tableContainer.nativeElement.scrollLeft + 200), behavior: 'smooth' });
  }

  close(val) {
    this.dialogRef.close(val);
  }

  proceed() {
    this.navigationService.setShowLayerTable(true);
    this.navigationService.plotLayerTableFeatures();
    this.sessionService.setShowAdvancedToolsSelectionData(true);
    this.dialogRef.close(false);
  }

  exportResult() {
    const pdf = new jsPDF('p', 'pt', 'a4');
    const contentHtml = this.printElement.nativeElement;
    pdf.addHTML(contentHtml, 30, 30, function() {
      pdf.save('web.pdf');
    });
  }

  ngOnDestroy() {
    if(this.subscriptionsArr.length) {
      this.subscriptionsArr.map(sub => sub.unsubscribe());
      this.subscriptionsArr = [];
    }
  }
}

interface Position {
	left: number;
	top: number;
}

const SUMMARY_OBJ = {
  "Dep. Variable": {
    "help_text": "",
    "font-weight": '500'
  },
  "No. Observations": {
    "help_text": "",
    "font-weight": '500'
  },
  "Df Residuals": {
    "help_text": "",
    "font-weight": '500'
  },
  "R-squared": {
    "help_text": "",
    "font-weight": '600'
  },
  "AIC": {
    "help_text": "",
    "font-weight": '500'
  },
  "Adj. R-squared": {
    "help_text": "Prediction accuracy and significance of the model.  The closer the value is to 1, the higher the accuracy of the model.",
    "font-weight": '600'
  },
  "BIC": {
    "help_text": "",
    "font-weight": '500'
  },
  "Cond. No.": {
    "help_text": "Statistical Significance of the explanatory variables in predicting the values of the dependent variable.",
    "font-weight": '600'
  },
  "Covariance Type": {
    "help_text": "",
    "font-weight": '500'
  },
  "Df Model": {
    "help_text": "",
    "font-weight": '500'
  },
  "Durbin-Watson": {
    "help_text": "Indicates autocorrelation in the residuals. Value close to 2.0 indicates that there is no autocorrelation detected in the sample.",
    "font-weight": '600'
  },
  "F-statistic": {
    "help_text": "Statistical significance of the explanatory variables in predicting the values of the dependent variable. Compares the produced linear model for the variables against a model that replaces the variables’ effect to 0. Prob (F-Statistic) uses this number to tell the accuracy of the null hypothesis, or whether it is accurate that the variables’ effect is 0",
    "font-weight": '500'
  },
  "Jarque-Bera (JB)": {
    "help_text": "Indicates whether or not the residuals are normally distributed to assess model bias. When the p-value for this test is smaller than 0.05, the residuals are not normally distributed indicating the model is biased.",
    "font-weight": '600'
  },
  "Kurtosis": {
    "help_text": "Kurtosis measures the peakiness of the data, or its concentration around 0 in a normal curve. Higher kurtosis implies fewer outliers.",
    "font-weight": '500'
  },
  "Log-Likelihood": {
    "help_text": "",
    "font-weight": '500'
  },
  "Omnibus": {
    "help_text": "describes the normallcy of the distribution of the residuals using skew and kurtosis as measurements. A 0 would indicate perfect normalcy.",
    "font-weight": '600'
  },
  "Prob (F-statistic)": {
    "help_text": "",
    "font-weight": '500'
  },
  "Prob(JB)": {
    "help_text": "",
    "font-weight": '500'
  },
  "Prob(Omnibus)": {
    "help_text": "statistical test measuring the probability that the residuals are normally distributed. A 1 would indicate perfectly normal distribution.",
    "font-weight": '600'
  },
  "Skew": {
    "help_text": "Skew is a measurement of symmetry in the data, with 0 being perfect symmetry. ",
    "font-weight": '500'
  }
}

const TABLE_COLS_OBJ = {
  "variables": {
    "display_text": "",
    "show_help_text": false,
    "help_text": ""
  },
  "Coefficient[a]": {
    "display_text": "Coefficient[a]",
    "show_help_text": true,
    "help_text": "Strength and type of relationship of the  associated dependent variable with the explanatory variables.1 unit change in the value of explanatory variable will consequently have a change of displayed units in the value of the dependent variable, keeping all other variables constant."
  },
  "StdError": {
    "display_text": "StdError",
    "show_help_text": true,
    "help_text": "Estimates the the standard deviation of the coefficient and is a measurement of the amount of variation in the coefficient throughout its data points."
  },
  "t-Statistic": {
    "display_text": "t-Statistic",
    "show_help_text": false,
    "help_text": ""
  },
  "Probability[b]": {
    "display_text": "Probability[b]",
    "show_help_text": true,
    "help_text": "Uses the t-statistic to produce the p value and is a measurement of how likely the coefficient is measured through the model by chance."
  },
  "[0.025": {
    "display_text": "[0.025",
    "show_help_text": false,
    "help_text": ""
  },
  "0.975]": {
    "display_text": "0.975]",
    "show_help_text": false,
    "help_text": ""
  }
}