import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-map-identify',
  templateUrl: './map-identify.component.html',
  styleUrls: ['./map-identify.component.scss'],
//   styles: [ `::ng-deep .mapid > .mat-expanded.mat-expansion-indicator:after {
//   color: white;
// }`]
})
export class MapIdentifyComponent implements OnInit {
  identifys=[];
  keysArr = [];
  propertyTypesArr = [];
  layerTypeImagesActive: any = {
    point: 'Oval_Copy_17.png',
    polygon: 'Polygon-Active.svg',
    multipolygon: 'Polygon-Active.svg',
    polyline: 'Path_Copy_4_1.png',
    linestring: 'Path_Copy_4_1.png',
    multilinestring: 'Path_Copy_4_1.png'
  };
  layerTypeImagesInactive: any = {
    point: 'point_sample.svg',
    polygon: 'polygon_sample.svg',
    multipolygon: 'polygon_sample.svg',
    polyline: 'polyline_sample.svg',
    linestring: 'polyline_sample.svg',
    multilinestring: 'polyline_sample.svg'
  };
  selectedIndex: number = null;
  @Input() identifyInput;
  constructor() { }
  ngOnInit() {
    // console.log(this.identifyInput,'response vlaue');
    this.identifys= this.identifyInput;
    this.identifys.forEach(identify => {
      if (identify.property_keys) {
        this.keysArr.push(identify.property_keys);
        this.propertyTypesArr.push(identify.property_keys.map(key => this.downloadLink(identify.properties[key])));
      } else {
        this.keysArr.push(Object.keys(identify.properties));
        this.propertyTypesArr.push(identify.property_keys.map(key => this.downloadLink(identify.properties[key])));
      }
    });
  }

  downloadLink(data, download?) {
    if (data && (data.toString().startsWith("https") || data.toString().startsWith("http"))) {
      if(download) {
        const links = data.split(',');
        links.forEach(link => {
          window.open(link, '_blank');
        });
        return;
      }
      return true;
    }
    return false;
  }
}
