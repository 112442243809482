import { Action } from '@ngrx/store'

export enum SubscriptionActions {
  getSubscriptionPlansStart = "[SUBSCRIPTION] Get Subscription Plans Start",
  getSubscriptionPlansSuccess = "[SUBSCRIPTION] Get Subscription Plans Success",
  getNewSubscriptionStart = "[SUBSCRIPTION] Get New Subscription Start",
  getNewSubscriptionSuccess = "[SUBSCRIPTION] Get New Subscription Success",
  authorizePaymentStart = "[SUBSCRIPTION] Authorize Payment Start",
  authorizePaymentSuccess = "[SUBSCRIPTION] Authorize Payment Success",
  cancelSubscriptionStart = "[SUBSCRIPTION] Cancel Subscription Start",
  cancelSubscriptionSuccess = "[SUBSCRIPTION] Cancel Subscription Success",
  getPaymentRequiredStatusStart = "[SUBSCRIPTION] Get Payment Required Status Start",
  getPaymentRequiredStatusSuccess = "[SUBSCRIPTION] Get Payment Required Status Success",
  upgradeSubscriptionPlanStart = "[SUBSCRIPTION] Upgrade Subscription Plan Start",
  upgradeSubscriptionPlanSuccess = "[SUBSCRIPTION] Upgrade Subscription Plan Success",
  checkValidSubscriptionStart = "[SUBSCRIPTION] Check Valid Subscription Start",
  checkValidSubscriptionSuccess = "[SUBSCRIPTION] Check Valid Subscription Success",
  getInvoicesStartAction ="[SUBSCRIPTION] get invoices start action",
  getInvoicesSuccessAction ="[SUBSCRIPTION] get invoices success action",
  sendEmailStartAction = "[SUBSCRIPTION] send email to super admin start action",
  sendEmailSuccessAction = "[SUBSCRIPTION] send email to super admin success action",
  optFreePlanStartAction = "[SUBSCRIPTION] opted free plan admin start action",
  optFreePlanSuccessAction = "[SUBSCRIPTION] opted free plan success action",
}

export class GetSubscriptionPlansStartAction implements Action {
  readonly type = SubscriptionActions.getSubscriptionPlansStart;
  constructor() { }
}

export class GetSubscriptionPlansSuccessAction implements Action {
  readonly type = SubscriptionActions.getSubscriptionPlansSuccess;
  public data: any = null;
  constructor(response: any) {
    this.data = response;
  }
}

export class GetNewSubscriptionStartAction implements Action {
  readonly type = SubscriptionActions.getNewSubscriptionStart;
  constructor(public payload: any) { }
}

export class GetNewSubscriptionSuccessAction implements Action {
  readonly type = SubscriptionActions.getNewSubscriptionSuccess;
  public data: any = null;
  constructor(response: any) {
    this.data = response;
  }
}

export class AuthorizePaymentStartAction implements Action {
  readonly type = SubscriptionActions.authorizePaymentStart;
  constructor(public payload: any) { }
}

export class AuthorizePaymentSuccessAction implements Action {
  readonly type = SubscriptionActions.authorizePaymentSuccess;
  public data: any = null;
  constructor(response: any) {
    this.data = response;
  }
}

export class CancelSubscriptionStart implements Action {
  readonly type = SubscriptionActions.cancelSubscriptionStart;
  constructor(public payload: any) { }
}

export class CancelSubscriptionSuccess implements Action {
  readonly type = SubscriptionActions.cancelSubscriptionSuccess;
  public data: any = null;
  constructor(resp) { this.data = resp;}
}

export class GetPaymentRequiredStatusStartAction implements Action {
  readonly type = SubscriptionActions.getPaymentRequiredStatusStart;
  constructor() { }
}

export class GetPaymentRequiredStatusSuccessAction implements Action {
  readonly type = SubscriptionActions.getPaymentRequiredStatusSuccess;
  public data: any = null;
  constructor(response: any) {
    this.data = response;
  }
}

export class UpgradeSubscriptionPlanStartAction implements Action {
  readonly type = SubscriptionActions.upgradeSubscriptionPlanStart;
  constructor(public payload: any) { }
}

export class UpgradeSubscriptionPlanSuccessAction implements Action {
  readonly type = SubscriptionActions.upgradeSubscriptionPlanSuccess;
  public data: any = null;
  constructor(response: any) {
    this.data = response;
  }
}

export class CheckValidSubscriptionStartAction implements Action {
  readonly type = SubscriptionActions.checkValidSubscriptionStart;
  constructor() { }
}

export class CheckValidSubscriptionSuccessAction implements Action {
  readonly type = SubscriptionActions.checkValidSubscriptionSuccess;
  public data: any = null;
  constructor(response: any) {
    this.data = response;
  }
}
export class GetInvoicesStartAction implements Action {
  readonly type = SubscriptionActions.getInvoicesStartAction;
  constructor() {}
}
export class GetInvoicesSuccessAction implements Action {
  readonly type = SubscriptionActions.getInvoicesSuccessAction;
  constructor(public response: any) {}
}
export class SendEmailStartAction implements Action {
  readonly type = SubscriptionActions.sendEmailStartAction;
  constructor(public planId: any) {}
}
export class SendEmailSuccessAction implements Action {
  readonly type = SubscriptionActions.sendEmailSuccessAction;
  constructor(public response: any) {}
}
export class OptFreeStartAction implements Action {
  readonly type = SubscriptionActions.optFreePlanStartAction;
  constructor(public planId: any) {}
}
export class OptFreeSuccessAction implements Action {
  readonly type = SubscriptionActions.optFreePlanSuccessAction;
  constructor(public response: any) {}
}
export type SubscriptionActionTypes = GetSubscriptionPlansStartAction |
  GetSubscriptionPlansSuccessAction | GetNewSubscriptionStartAction |
  GetNewSubscriptionSuccessAction | AuthorizePaymentStartAction |
  AuthorizePaymentSuccessAction | GetInvoicesSuccessAction | SendEmailSuccessAction | OptFreeSuccessAction