import { Component, OnInit, Input, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-assigned-dialog",
  templateUrl: "./assigned-dialog.component.html",
  styleUrls: ["./assigned-dialog.component.scss"]
})
export class AssignedDialogComponent implements OnInit {
  id = '';
  constructor(
    public dialogRef: MatDialogRef<AssignedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.id = this.router.url.split("/").splice(-2)[0];
  }
  viewAssignedUsers() {
    if(this.data.source === "project-management") {
      this.router.navigate(['app-management/project/forms', this.id ,'assigned-users']);
    } else if(this.data.source === "map-sessions") {
      this.router.navigate(['app-management/map-sessions', this.id ,'assigned-users']);
    }
    this.dialogRef.close(true);
  }
  viewTemplatesList() {
    if(this.data.source === "project-management") {
      this.router.navigate(['/app-management/project/forms']);
    } else if(this.data.source === "map-sessions") {
      this.router.navigate(['/app-management/map-sessions']);
    }
    this.dialogRef.close(true);
  }
  close(event) {
    if (event) {
      this.dialogRef.close(true);
    }
    else {
      this.dialogRef.close();
    }
  }
}
