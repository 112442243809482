import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class ToastsManager {
    constructor(public _toastr: ToastrService, private _translate: TranslateService){ }

    showSuccess(successMsg: string) {
        setTimeout(() => this._toastr.success(successMsg, 'Success!'));
    }

    showError(errorMsg: string) {
        setTimeout(() => this._toastr.error(errorMsg, 'Error!'));
    }
    
    showWarning(warnMsg: string) {
        setTimeout(() => this._toastr.warning(warnMsg, 'Warning!'));
    }

    showInfo(infoMsg: string) {
        setTimeout(() => this._toastr.info(infoMsg, 'Info!'));
    }

    _showToast(msg: string, toastType: string) {
        switch(toastType) {
            case "success":
                console.log("Comes here 1")
                this.showSuccess(msg);
                break;
            case "error":
                this.showError(msg);
                break;
            case "warn":
                this.showWarning(msg);
                break;
            case "info":
                this.showInfo(msg);
                break;
            default:
                this.showSuccess(msg);
                break;
        }
    }
    
    showToastMsg(msgKey: string, toastType: string) {
        this._translate.get(msgKey).subscribe((res: string) => {
            this._showToast(res, toastType);
        });
    }
}