import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SessionService } from 'src/app/_services/session.service';
import {
  AddLayersStartAction,
  GetLayerAttributesStartAction,
  GetSavedQueriesStartAction,
  GetUniquePropertyValuesStartAction,
  GetQueryTableDataStartAction,
  SessionManagementTypes,
  GetQueryTableDataSuccessAction,
  GetAdvancedToolsSearchResultStartAction,
  UpdateQueryStartAction,
  DeleteQueryStartAction,
} from 'src/app/store/actions/session-management.actions';
import { QUERY_OPERATORS, QUERY_OPERATORS_STRING_TYPE, ADVANCED_TOOL_TYPE } from "src/app/app.constants";
import {
  layerAttributesSelector,
  uniquePropertyValuesSelector,
  savedQueriesSelector,
  propertiesTypeSelector,
} from "src/app/store/selectors/session-management.selector";
import { LayerService } from "src/app/_services/layer.service";
import { getQueryObject } from "../query-validator";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteTrigger } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { QueryNameDialogComponent } from "../../query-name-dialog/query-name-dialog.component";
import { Actions, ofType } from '@ngrx/effects';
import { tap, map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { InputParams } from '../../advanced-tools-dialog/input-params.model';
import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
@Component({
  selector: 'app-query-dialog',
  templateUrl: './query-dialog.component.html',
  styleUrls: ['./query-dialog.component.scss']
})
export class QueryDialogComponent implements OnInit {
  queryOperators = QUERY_OPERATORS;
  queryOperatorsStringType = QUERY_OPERATORS_STRING_TYPE;
  operators;
  outputQuery: string = '';
  sessionLayers = [];
  sessionId = "";
  layerAttributes$;
  layerAttributeTypes$;
  layerValues$;
  savedQueries: any[] = [];
  activeLayer: any = null;
  limit: number = 20;
  currentPage: number = 1;
  selectedAttribute: string = '';
  selectedUniqueValue: string = '';
  filterAttribute;
  filterValue;
  matSelectPlaceholder = true;
  selectedPlaceholder = true;
  errorMessage: string = null;
  toggleActionButtons: boolean = false;
  invalidQueryString: boolean = false;
  layerTypeIcons = {
    "point": "assets/images/point_sample.svg",
    "polyline": "assets/images/Darkpolyline.svg",
    "polygon": "assets/images/Dankpolygon.svg",
    "multipolygon": "assets/images/Dankpolygon.svg",
    "multipolyline": "assets/images/Darkpolyline.svg",
    "linestring": "assets/images/Darkpolyline.svg",
    "multilinestring": "assets/images/Darkpolyline.svg"
  };
  queryNameDialog: MatDialogRef<QueryNameDialogComponent>;
  lSelectionDisabled = false;
  subscriptionArr: Array<Subscription> = [];
  inputParams: InputParams = {
    difference: null,
    radius: null,
    steps: null,
    distance: null,
    units: null,
    tolerance: null,
    maxEdge: null,
    concavity: null,
    propertyName: null,
    highQuality: null,
    mutate: null,
    cellSize: null,
    gridType: null,
    property: null,
    weight: null,
    breaks: null,
    cellSide: null,
    extent: null
  }
  querySearch: FormControl = new FormControl();
  serchSelected: boolean = false;
  @ViewChild('autoCompleteInput') autoCompleteInput: MatAutocompleteTrigger
  rawQueryData: any[] = [];
  queryClick: boolean = false;
  selectedQuery: any = {};
  autoCompletePanelOpen: boolean = false;
  optionHeader: string = '';
  fieldToBeSelected: string = '';
  layerAttributes: any[] = [];
  attributeTypes: any[] = [];
  selectedLayer: any;
  cursorStartPosition: number = null;
  cursorEndPosition: number = null;
  isEntered: boolean = false;
  layerAttributes1: any;
  isApplyingFilter: boolean;
  constructor(
    private sessionService: SessionService,
    private navigationService: NavigationCtrl,
    private _store: Store<any>,
    private router: Router,
    private api: LayerService,
    public dialogRef: MatDialogRef<QueryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private dialog: MatDialog,
    private actions: Actions
  ) {
    this.sessionService.setShowQueryData(false);
    this.sessionService.selectedGeometryLayerId = '';
    this.sessionService.selectedGraphicsLayerType = '';
    this.sessionService.selectedGeometryType = '';
    this.sessionService.selectedCoordinates = '';
    this.sessionService.selectedQueryLayerId = '';
    this.sessionService.selectedQueryLayerType = '';
  }

  buttonText: string = 'SAVE QUERY'

  ngOnInit() {
    this.operators = this.queryOperators;
    this.sessionLayers = this.sessionService.sessionLayers.filter(layer => layer.show && (layer.layer_type != 2) && (layer.layer_type != 5) && (layer.layer_type != 6))
    this.sessionId = this.router.url.split("/").splice(-1)[0];
    this.sessionService.query = null;
    this._store.dispatch(new GetSavedQueriesStartAction());
    this._store.select(savedQueriesSelector).pipe(tap((resp: any) => {
      this.rawQueryData = resp
      if (this.activeLayer === null) {
        this.savedQueries = [];
        this.optionHeader = "No queries available"
      } else {
        let layerId = this.activeLayer.parent_layer_id;
        this.savedQueries = resp.filter(({ layer_id }) => layer_id === layerId);
        this.optionHeader = "Please select query"
        if ((this.selectedLayer !== null) && (this.selectedLayer._id)) {

          this.savedQueries = this.rawQueryData.filter((query) => query.layer_id === this.selectedLayer.parent_layer_id);
        }
        this.outputQuery = '';
        this.selectedQuery = {};
        this.querySearch.setValue('');
        this.queryClick = false;
        this.selectedAttribute = ''
        if (this.savedQueries.length) {
          this.optionHeader = 'Please select query'
        } else {
          this.optionHeader = "No queries available"
        }


      }
    })).subscribe()
    if (this.data && Object.keys(this.data).length) {
      if (![6, 7, 8].includes(this.data.tool)) {
        this.setActiveLayer();
        this.getAllLayerDataById(this.activeLayer);
        this.lSelectionDisabled = true;
      } else {
        this.sessionLayers = this.sessionService.sessionLayers.filter(layer => layer.show && ((layer.parent_layer_id === this.data.selectedLayer1) || (layer.parent_layer_id === this.data.selectedLayer2)));
      }
    }
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getQueryTableDataSuccess),
        map((action: GetQueryTableDataSuccessAction) => {
          this.sessionService.setShowQueryData(true);
          this.dialogRef.close();
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this.actions.pipe(
        ofType(SessionManagementTypes.getAdvancedToolsSearchResultSuccess),
        map((action: any) => {
          // this.sessionService.setShowQueryData(true);
          this.sessionService.setShowAdvancedToolsSelectionData(true);
          this.dialogRef.close();
        })
      ).subscribe()
    );
    this.subscriptionArr.push(
      this.sessionService.getAdditionalInputs().subscribe(params => this.inputParams = params)
    );
    this.querySearch.valueChanges.subscribe((val) => {
      if (val) {
        this.serchSelected = true ;
      }else{
        this.serchSelected = false;
      }
      if (this.autoCompleteInput) {

        this.autoCompleteInput.closePanel()
      }

      if ((val !== undefined) && (val !== null) && (val.trim().length)) {
        this.savedQueries = this.savedQueries.filter((query) => query.query_name.toLowerCase().includes(val.toLowerCase()));

      } else {
        if (this.activeLayer !== null) {

          this.savedQueries = this.rawQueryData.filter(({ layer_id }) => layer_id === this.activeLayer.parent_layer_id);
        }
      }
      if (this.savedQueries.length) {
        this.optionHeader = "Please select query"

      } else {
        this.optionHeader = "No queries available"

      }
    })
  }

  setActiveLayer() {
    switch (this.data.tool) {
      case 1:
        if (this.data.selectedLayer2) this.activeLayer = this.sessionLayers.find(layer => layer.parent_layer_id === this.data.selectedLayer2);
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 24:
      case 25:
      case 31:
      case 38:
        if (this.data.selectedLayer1) this.activeLayer = this.sessionLayers.find(layer => layer.parent_layer_id === this.data.selectedLayer1);
        break;
      default:
        break;
    }
  }

  getAllLayerDataById(selectedLayer) {
    this.layerValues$ =null;
    this.selectedLayer = selectedLayer;

    this._store.dispatch(new GetSavedQueriesStartAction())

    this.clearAll();
    this.operators = this.queryOperators;
    this.matSelectPlaceholder = true;
    if (!(this.data && Object.keys(this.data).length)) {
      this.matSelectPlaceholder = false;
      this.activeLayer = selectedLayer;
      this.sessionService.selectedQueryLayerId = this.activeLayer.parent_layer_id;
      this.sessionService.selectedQueryLayerType = this.activeLayer.type;
    }
    else {
      if ([6, 7, 8].includes(this.data.tool)) this.activeLayer = selectedLayer;
    }
    this.sessionService.setSelectedLayersInTools([selectedLayer.parent_layer_id]);
    //this.sessionService.setSelectedLayers([selectedLayer]);
    this._store.dispatch(new GetLayerAttributesStartAction(selectedLayer.parent_layer_id));
    this.layerAttributes$ = this._store.select(layerAttributesSelector).pipe(tap((layerAttributes: any) => {
      this.layerAttributes = layerAttributes;
    }))
    this.layerAttributeTypes$ = this._store.select(propertiesTypeSelector).pipe(tap((attributeTypes) => {
      this.attributeTypes = attributeTypes;
    }))
  }

  onAttributeSelection(attribute, type) {
    if (type === 'Number') {
      this.operators = this.queryOperators;
    } else if (type === 'String') {
      this.operators = this.queryOperatorsStringType;
    }
    this.selectedAttribute = attribute

    this.outputQuery = this.queryValidator(attribute)
  }

  getLayerUniqueValues() {
    this._store.dispatch(new GetUniquePropertyValuesStartAction(
      this.activeLayer.parent_layer_id,
      { property: this.selectedAttribute }
    ));
    this.layerValues$ = this._store.select(uniquePropertyValuesSelector);
  }
  crossSearch(){
    this.serchSelected = !this.serchSelected; 
    this.querySearch.setValue('');
  }
  onLayerValueSelection(layerValue) {
    this.selectedUniqueValue = layerValue;
    if (typeof(layerValue) == 'string') {
      layerValue = '"' + layerValue + '"';
    }
    // if (isNaN(layerValue)) {
    //   layerValue = '"' + layerValue + '"';
    // }
 
    this.outputQuery = this.queryValidator(layerValue)
  }

  addOperator(operator) {

    
    this.outputQuery = this.queryValidator(operator);
  }

  onQuerySelection(selectedQuery) {
    this.selectedPlaceholder = false;
    const query = selectedQuery.split(':').join('=');
    this.outputQuery = query;
  }

  clearAll() {
    this.outputQuery = "";
    this.errorMessage = null;
    this.toggleActionButtons = false;
    this.invalidQueryString = false;
  }

  isQueryValid() {
    if (this.outputQuery.length == 0) {
      this.toast.error("Please input a valid Query and Try Again");
      return false;
    }
    let outputQuery = this.outputQuery.split("=").join(':');
    var validateQuery = getQueryObject(outputQuery, '');

    if (typeof (validateQuery) != 'object') {
      this.toast.error("Please input a valid Query and Try Again");
      return false;
    } else {
      return true;
    }
  }

  setInvalid(value) {
    if(value) {
      this.invalidQueryString = true;
      this.errorMessage = "*Please enter a valid Query";
      this.toggleActionButtons = false;
    } else {
      this.invalidQueryString = false;
      this.errorMessage = null;
      this.toggleActionButtons = true;
    }
  }

  validateQuery() {
    if (!this.outputQuery) {
      this.setInvalid(true);
      return false;
    }
    let outputQuery = this.outputQuery.split("=").join(':');
    let processedQuery = getQueryObject(outputQuery, '');

    if (typeof (processedQuery) != 'object') {
      this.setInvalid(true);
      return false;
    } else {
      this.setInvalid(false);
      const queryHistory = {
        activeLayer:this.activeLayer,
        sessionLayers:this.sessionLayers,
        outputQuery:this.outputQuery
      }
      // sessionStorage.setItem('queryHistory',JSON.stringify(queryHistory));
      return true;
    }
  }

  saveQuery() {
    if (this.isQueryValid()) {
      const outputQuery = this.outputQuery.split('=').join(':');
      let datum = {
        layer_id: this.activeLayer.parent_layer_id,
        query: outputQuery,
        "session_id": this.sessionId
      };

      /* launch dialog for query name */
      if (!this.queryClick) {

        let qNameDialog = this.dialog.open(QueryNameDialogComponent, {
          hasBackdrop: true,
          role: 'dialog',
          width: '760px',
          data: {
            queries: this.rawQueryData,
            selectedLayer: this.activeLayer
          },

        });
        qNameDialog.afterClosed().subscribe(result => {
          if ((result !== true) && (result.status)) {
            datum['query_name'] = result.queryName,
              this.createQuery(datum);
          }
        });
      } else {
        datum['query_name'] = this.selectedQuery.query_name,
          this._store.dispatch(new UpdateQueryStartAction(this.selectedQuery._id, datum))
        this.dialogRef.close();

      }

    }
  }
  createQuery(datum) {
    this.api.createQuery(datum).subscribe((response: any) => {
      if (response.success) {
        this.toast.success(`${response.data.query_name} saved successfully.`);
        this._store.dispatch(new GetSavedQueriesStartAction());
        this.dialogRef.close();
      } else {
        this.toast.error("Query save failed. Please retry!");
      }
    });

  }
  removeClicked() {

    this.outputQuery = '';
    this.selectedQuery = {};
    this.querySearch.setValue('');
    this.queryClick = false;
    this.selectedAttribute = ''
  }

  applyFilter(pageNumber: number = 1) {
    this.isApplyingFilter = true;
    if (this.isQueryValid()) {
      let outputQuery = this.outputQuery.split("=").join(':');
      /* to check if string has type number inside it (if number, then remove "" of the number) */
      let outputSplit: any = outputQuery.split('"');
      for (var b = 0; b < outputSplit.length; b++) {
        if (!isNaN(outputSplit[b]) && !isNaN(outputSplit[b][outputSplit[b].length - 1])) {
          if (outputSplit[b][0] > 0) {
            outputQuery = outputQuery.replace('"' + outputSplit[b] + '"', outputSplit[b]);
          }
        }
      }
      outputQuery = outputQuery.split('+').join('%2B');
      this.sessionService.query = outputQuery;
      this.sessionService.activeLayer = this.activeLayer.parent_layer_id;
      if (!this.data) {
        this.sessionService.activeLayerName = this.activeLayer.name;
        this.currentPage = pageNumber;
        this._store.dispatch(new GetQueryTableDataStartAction(
          this.sessionService.activeLayer,
          this.sessionService.query,
          {
            page: 1,
            limit: 40
          }
        ));
      } else {
        let query: any = {
          q: outputQuery,
          limit: 100,
          page: 1
        };
        if ([6, 7, 8].includes(this.data.tool)) {
          query.layer_id = this.activeLayer.parent_layer_id;
        }
        let payload: any = {
          input_layer_1: {
            layer_id: this.data.selectedLayer1,
            type: this.data.selectedLayer1Type
          },
          input_layer_2: {
            layer_id: this.data.selectedLayer2,
            type: this.data.selectedLayer2Type
          },
          input_params: { ...this.inputParams },
          feature_type: ADVANCED_TOOL_TYPE[this.data.tool],
          "selection_type": this.data.type,
          query
        };
        if(this.data && this.data.isSimilaritySearch){
          if(this.data.currentTabNo == 2){
            this.sessionService.getsimilarSearchAttributes().subscribe(data => {
            if(!this.isEntered)
          {
            console.log(data);
            this.layerAttributes1 = data
            this.isEntered = true;
          }
          });
        }
          let layerData;
          let testarray = [];
          testarray.push(payload);
          this.sessionService.getSimilarSearchData().subscribe(data => layerData = data);
          this.sessionService.setSimilarSearchData([...layerData,...testarray]);
          this.sessionService.setSimilarSearchTab(this.data.currentTabNo);
          this._store.dispatch(new GetLayerAttributesStartAction(payload.input_layer_1.layer_id));
          this._store.select(layerAttributesSelector).subscribe(attributes => {
            setTimeout(() => {
              if (this.isApplyingFilter) {
              if(this.data.currentTabNo == 1){
                console.log("In setting similar search data")
                this.isApplyingFilter = false;
                this.sessionService.setsimilarSearchAttributes(attributes);
                this.dialogRef.close(payload);
              }
              else{
                console.log(this.layerAttributes1)
                console.log(attributes)
                this.isApplyingFilter = false;
                attributes = attributes.map(item => item.toLowerCase())
                this.layerAttributes1 = this.layerAttributes1.filter(item => attributes.includes(item.toLowerCase()))
              // this.layerAttributes1 =  this.layerAttributes1.filter(item => attributes.includes(item))
              console.log("In setting similar search data2")
              if(this.layerAttributes1.length > 0){
                this.sessionService.setsimilarSearchAttributes(this.layerAttributes1);
                this.dialogRef.close(payload);
              }
              else{
                this.toast.warning('Attributes are not matched')
              }
            }
          }
            //     // if (!Object.keys(this.userData).length) return;
            //     // this.displayedColumns = columns;
            //   // }
            }, 200);
          });
        }else{
        this._store.dispatch(new GetAdvancedToolsSearchResultStartAction(payload));
        this.navigationService.setShowLayerTable(true);
        this.sessionService.setShowAdvancedToolsSelectionData(true);
        this.sessionService.setAdvancedToolsSearchPayload(payload);
        }
      }
      // this.sessionService.setShowQueryData(true);
      // this.dialogRef.close();
    }
  }

  close(event) {
    if (event) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close();
      this.sessionService.setResetAdvancedToolsTypeSelection(true);
    }
  }

  inputchange(selectConcatValue) {

  }
  queryClicked(query) {
    this.queryClick = true;
    this.selectedQuery = query
    this.outputQuery = '';
    this.outputQuery = query.query.split(':').join('=')
    this.fieldToBeSelected = query.query.split(':')[0];
    this.selectedAttribute = this.fieldToBeSelected.trim()
    let attributeType = this.attributeTypes[this.fieldToBeSelected.trim()]
    if (attributeType === 'Number') {
      this.operators = this.queryOperators;
    } else if (attributeType == 'String') {
      this.operators = this.queryOperatorsStringType;
    };
  }
  onFocus() {
    this.autoCompleteInput.openPanel()
  }
  deleteQueryClicked(query) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        alertTitle: 'DELETE SAVED QUERY',
        alertText: 'Would you like to remove saved query ?',
        confirmText: 'Delete'
      }
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp !== '' && resp) {
        this._store.dispatch(new DeleteQueryStartAction(query._id))
      }
    })
  }
  crossClicked(){
    this.filterAttribute = ''
  }
  crossValue(){
    this.filterValue = ''
  }
  outputQueryChange() {
    if (this.queryClick) {
      this.buttonText = 'SAVE CHANGES'
    } else {
      this.buttonText = ' SAVE QUERY'
    }
  }
  onClick(event) {
    this.cursorStartPosition = event.target.selectionStart;
    this.cursorEndPosition = event.target.selectionEnd
  }
  replaceString(startIndex, endIndex, selectedQuery) {

    return this.outputQuery.toString().substring(0, startIndex) + selectedQuery + this.outputQuery.toString().substring(endIndex + 1, this.outputQuery.length - 1)
  }
  queryValidator(value) {
    let concatQuery = ''
    if (this.cursorStartPosition !== null && this.cursorEndPosition !== null) {

      concatQuery = this.replaceString(this.cursorStartPosition, this.cursorEndPosition, value)
    } else {
      // remove after adding something
      concatQuery =this.outputQuery.concat(value, ' ');
    }
    this.cursorEndPosition = null;
    this.cursorStartPosition = null;
    return concatQuery;
  }
  ngOnDestroy() {
    if (this.subscriptionArr.length) {
      this.subscriptionArr.map(sub => sub.unsubscribe());
      this.subscriptionArr = [];
    }
  }
}
