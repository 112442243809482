import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Store } from "@ngrx/store";
import { GetLayerAttributesStartAction, GetUniquePropertyValuesSuccessAction } from "src/app/store/actions/session-management.actions";
import { SessionService } from "src/app/_services/session.service";
import { getQueryObject, mapBoxSpecificStyle } from "../query-validator";

@Component({
  selector: 'app-filter-data-to-display',
  templateUrl: './filter-data-to-display-dialog.component.html',
  styleUrls: ['./filter-data-to-display-dialog.component.scss']
})
export class FilterDataToDisplayDialogComponent implements OnInit {
  sessionLayers: Array<any> = [];
  activeLayer: any = {};
  lSelectionDisabled: boolean = true;
  outputQuery: string = '';
  cursorStartPosition: number = null;
  cursorEndPosition: number = null;
  invalidQueryString: boolean = false;
  errorMessage: string = null;
  toggleActionButtons: boolean = false;
  layerTypeIcons = {
    "point": "assets/images/point_sample.svg",
    "polyline": "assets/images/Darkpolyline.svg",
    "polygon": "assets/images/Dankpolygon.svg",
    "multipolygon": "assets/images/Dankpolygon.svg",
    "multipolyline": "assets/images/Darkpolyline.svg",
    "linestring": "assets/images/Darkpolyline.svg",
    "multilinestring": "assets/images/Darkpolyline.svg"
  };
  constructor(
    public dialogRef: MatDialogRef<FilterDataToDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<any>,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.sessionLayers = this.sessionService.sessionLayers.filter(layer => layer.show && layer.id === this.data.selectedLayer.id);
    this.setActiveLayer();
    this.getAllLayerDataById(this.activeLayer);
    const queryHistoryObj = JSON.parse(sessionStorage.getItem('queryHistory'));
    if(queryHistoryObj && (this.sessionLayers[0].name === queryHistoryObj.sessionLayers[0].name)) {
      this.activeLayer = queryHistoryObj.activeLayer;
      this.sessionLayers = queryHistoryObj.sessionLayers;
      this.outputQuery = queryHistoryObj.outputQuery;
    }
  }

  setActiveLayer() {
    if(this.sessionLayers.length) {
      this.activeLayer = this.sessionLayers.find(layer => layer.id === this.data.selectedLayer.id);
    }
  }

  getAllLayerDataById(selectedLayer) {
    this._store.dispatch(new GetLayerAttributesStartAction(selectedLayer.parent_layer_id));
  }

  onClick(event) {
    this.cursorStartPosition = event.target.selectionStart;
    this.cursorEndPosition = event.target.selectionEnd
  }

  updateOutputQuery(attribute) {
    this.outputQuery = this.queryValidator(attribute);
    this.cursorEndPosition = null;
    this.cursorStartPosition = null;
    this.toggleActionButtons = false;
    this.invalidQueryString = false;
  }

  setOutputQuery(value) {
    this.outputQuery = value;
    this.cursorEndPosition = null;
    this.cursorStartPosition = null;
    this.toggleActionButtons = false;
    this.invalidQueryString = false;
  }

  replaceString(startIndex, endIndex, selectedQuery) {
    return this.outputQuery.toString().substring(0, startIndex) + selectedQuery + this.outputQuery.toString().substring(endIndex + 1, this.outputQuery.length - 1)
  }

  queryValidator(value) {
    let concatQuery = ''
    if (this.cursorStartPosition !== null && this.cursorEndPosition !== null) {

      concatQuery = this.replaceString(this.cursorStartPosition, this.cursorEndPosition, value)
    } else {
      // remove after adding something
      concatQuery = this.outputQuery.concat(value, ' ');
    }
    this.cursorEndPosition = null;
    this.cursorStartPosition = null;
    return concatQuery;
  }

  clearAll() {
    this.outputQuery = "";
    this.errorMessage = null;
    this.toggleActionButtons = false;
    this.invalidQueryString = false;
    this.sessionService.setClearSelectionInQueryDialog(true);
    this._store.dispatch(new GetUniquePropertyValuesSuccessAction([]));
  }

  applyFilter() {
    if(!(this.outputQuery && this.validateQuery())) return;
    let outputQuery = this.outputQuery.split("=").join(':');
    let processedQuery = getQueryObject(outputQuery, '');

    console.log("ProcessedQuery: ", processedQuery);
    let mapBoxStyle = mapBoxSpecificStyle(processedQuery);
    console.log("mapboxStyle: ", mapBoxStyle);
    this.clearAll();
    this.dialogRef.close(mapBoxStyle);
  }

  validateQuery() {
    if (!this.outputQuery) {
      this.setInvalid(true);
      return false;
    }
    let outputQuery = this.outputQuery.split("=").join(':');
    let processedQuery = getQueryObject(outputQuery, '');

    if (typeof (processedQuery) != 'object') {
      this.setInvalid(true);
      return false;
    } else {
      this.setInvalid(false);
      const queryHistory = {
        activeLayer:this.activeLayer,
        sessionLayers:this.sessionLayers,
        outputQuery:this.outputQuery
      }
      sessionStorage.setItem('queryHistory',JSON.stringify(queryHistory));
      return true;
    }
  }

  setInvalid(value) {
    if(value) {
      this.invalidQueryString = true;
      this.errorMessage = "*Please enter a valid Query";
      this.toggleActionButtons = false;
    } else {
      this.invalidQueryString = false;
      this.errorMessage = null;
      this.toggleActionButtons = true;
    }
  }

  close(value) {
    this.clearAll();
    this.dialogRef.close(value);
  }
}
