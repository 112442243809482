import { Injectable } from "@angular/core";
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Observable, fromEvent, merge, combineLatest, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { userProfileSelector } from '../store/selectors/profile.selector';
import { map, takeUntil } from 'rxjs/operators';
import { GetUsersProfileStartAction } from '../store/actions/profile.action';
import { uniqBy } from 'lodash';
import { ClearFileStorageAction, ClearFileStorageActionSingleV3, ClearFileStorageActionV3 } from "../store/actions/session-management.actions";
import {ClearFileStorageActionSingle} from "../store/actions/session-management.actions"
 @Injectable()
export class SocketService {
    accessToken;
    socket;
    SOCKET_URL;
    observable;
    disconnected: boolean = false;
    organizationId: string = '';
    userId: string = '';
    organizationList: any[] = [];
    autoSuggestedUser: any = {};
    recentChatUsersSubject: BehaviorSubject<any> = new BehaviorSubject([])
    chatOfUsers: any[] = [];
    recentChatList: any[] = [];
    loggedInUserSubject: BehaviorSubject<any> = new BehaviorSubject({})
    loggedInUser: any = {};
    selectedUser: any = {};
    selectedUserSubject: BehaviorSubject<any> = new BehaviorSubject({});
    totalNotificationPages: number = 0;
    currentNotificationPageNumber: number = 1;
    getServerNotificationListObservable: BehaviorSubject<any> = new BehaviorSubject([]);
    getServerLayerProcessObservable: BehaviorSubject<any> = new BehaviorSubject({});
    globalNotificationList: any[] = [];
    totalGlobalNotificationList: number = 0;
    unreadCountSubject: BehaviorSubject<any> = new BehaviorSubject({});
    highLightBadge: boolean =false;
    processingGlobalLayersList: any[] = [];
    unreadCount: number = 0 ;
    chatHighlightBadge: boolean =false;
    webApiCall: boolean =false
    subscriptionArray: any[] =[];
    constructor(public _store: Store<any>, public toasterService: ToastrService) {
 

    }


    clearFileStorage(){
        this._store.dispatch(new ClearFileStorageAction)

    }
    //Function to call dispatch action to delete all tools notification for v3 api
    clearFileStorageV3(){ 
        this._store.dispatch(new ClearFileStorageActionV3 )

    }


    clearFileStorageSingle(id){
        this._store.dispatch(new ClearFileStorageActionSingle(id))
        

    }
    //Function to call dispatch action to delete single tools notification for v3 api
clearFileStorageSingleV3(id){
        this._store.dispatch(new ClearFileStorageActionSingleV3(id))
        

    }
    

    

    establishSocketConnection() {
        this.accessToken = localStorage.getItem('token');
        if (!this.accessToken) return
        this.SOCKET_URL = environment.socketHost;
        this.socket = io(this.SOCKET_URL, { query: { access_token: this.accessToken, transport: 'websocket' } });
    }
    joinClientToChat(userId) {
        this.socket.emit('client-join-chat', { userId: userId })
    }
    getClientRecentChatUsersList(userId) {
        return fromEvent(this.socket, userId)
    }
    getClientOrganizationList(orgId) {
        return fromEvent(this.socket, orgId)
    }
    segregateUsersListWithOrgList(usersList, orgUsersList) {
        let recentChatList = [];
        for (let index = 0; index < usersList.length; index++) {
            for (let orgIndex = 0; orgIndex < orgUsersList.length; orgIndex++) {
                if (orgUsersList[orgIndex]._id == usersList[index]._id) {
                    let currentUser = usersList[index];
                    currentUser.chat_activate = orgUsersList[orgIndex].chat_activate;
                    currentUser.online_status = orgUsersList[orgIndex].online_status;
                    currentUser.profilePic = orgUsersList[orgIndex].profilePic;
                    currentUser.unreadMessageInfo = usersList[index].unreadMessageInfo;
                    recentChatList.push(currentUser);
                    break;
                }
            }
        }
        return recentChatList
    }


    joinRoom(obj) {
        this.socket.emit('client-join', obj, (error) => {
            if (error && error.error) {
                this.toasterService.error('room not created')
            }
        })
    }
    getClientChatHistory(payload) {
        this.socket.emit('client-chat-payload', { page: payload.page, limit: payload.limit, userId: payload.userId, receiverId: payload.receiverId })
    }

    getChatHistory(): Observable<any> {
        return fromEvent(this.socket, 'server-chat-history')
    }
    setActivateStatus(payload) {
        this.socket.emit('client-switch-status', { userId: payload.userId, activate: payload.activate })
    }

    getRoomstatus() {
        return fromEvent(this.socket, 'server-room');
    }

    sendMessage(payload) {
        this.socket.emit('client-send-message', { message: payload.message, userId: payload.userId, to: payload.to, timeStamp: payload.timeStamp })
    }

    getServerMessage() {
        return fromEvent(this.socket, 'server-send-message')
    }
    disconnectSocket(payload) {
        this.socket.emit('client-logout', { userId: payload.userId });
        this.resetAllData();

    }
    sendMessageReadStatus(payload) {
        this.socket.emit('client-message-read', { userId: payload.userId, roomId: payload.roomId })
    }
    messageReadStatus() {
        return fromEvent(this.socket, 'server-user-read')
    }
    checkInternetConnection() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((res) => {
                res.next(navigator.onLine);
                res.complete();
            })
        )
    }
    getOrgListOnCreation(payload) {
        return this.socket.emit('client-get-user-list', { ...payload })
    }
    resetAllData() {
        // removing chat-data locally when log-out is clicked
        this.chatOfUsers = [];
        this.recentChatList = [];
        this.organizationList = [];
        this.loggedInUser = {};
        this.organizationId = '';
        this.userId = '';
        this.selectedUserSubject.next({})
        this.loggedInUserSubject.next({});
        this.recentChatUsersSubject.next([]);
        this.currentNotificationPageNumber =1;
        this.getServerNotificationListObservable.next([]);
        this.getServerLayerProcessObservable.next([]);
        this.globalNotificationList =[];
        this.totalNotificationPages =1
    }

    removesocketListeners () {
        this.subscriptionArray.map((eachSub) => eachSub.unsubscribe());
        this.userId =null;
        
    }

    // end of chat events


    // events for processing layers list( processing, processed) layers

    getClientProcessingLayers(payload) {
        this.socket.emit('client-process-list', { ...payload })
    }
    getServerProcessingLayers(sessionId) {
        return fromEvent(this.socket, `${sessionId}-processing`)
    }
    getServerProcessedLayers(sessionId) {
        return fromEvent(this.socket, `${sessionId}-processed`)
    }
    getClientNotificationList(payload) {
        this.socket.emit('client-notification-list', { ...payload })
    }
    getServerNotificationList(userId) {
        return fromEvent(this.socket, `${userId}-notification-list`)
    }
    getServerLayerProcess(userId) {
        return fromEvent(this.socket, `${userId}-layer-process`)
    }
    getNotificationsList(payload) {
        this.getClientNotificationList(payload)
    }

    getClientBadgeCount(userId) {
        return fromEvent(this.socket, `${userId}-notification-unread-count`);
    }

    getServerNotificationUnreadCount(payload) {
        this.socket.emit('client-notification-unread-count', {...payload});
    }

    updateNotificationReadCount(payload) {
        this.socket.emit('client-notification-update-unread', {...payload});
    }

    getExportedLayer(userId) {
        return fromEvent(this.socket, `${userId}-export-process`)
    }

    clientClearAllNotification(userId) {
        this.socket.emit('client-notification-clear', {userId})
    }

    clientDeleteNotification(userId, notificationId) {
        this.socket.emit('client-notification-delete', {userId, notificationId})
    }

    listenClientClearAllNotification(userId) {
        return fromEvent(this.socket, `${userId}-notification-clear`)
    }

    listenClientDeleteNotification (userId) {
        return fromEvent(this.socket, `${userId}-notification-delete`)
    }

    clientMessageCount(payload) {
        this.socket.emit('client-message-count', {userId: payload.userId})
    }

    getServerMessageCount () {
        return fromEvent(this.socket, 'server-message-count');
    }

    getLoadBuildingsProcessingStatus(userId) {
        return fromEvent(this.socket,`${userId}-load-buildings` )
    }




}