import { Injectable, Output, EventEmitter } from '@angular/core';
import { LayerService } from '../_services/index';
import { SharedService } from '../_helpers/shared';
import { environment as env } from '../../environments/environment';
declare let L: any;
import * as turf from '@turf/turf';
import 'leaflet-textpath';
import _ from 'lodash';
import { Subject, Observable, Subscriber, Subscription, BehaviorSubject, throwError } from 'rxjs';
import { SessionService } from './session.service';
import * as mapboxgl from 'mapbox-gl';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Utilities } from '../_helpers/util';
import { ToastrService } from 'ngx-toastr';
import { API } from './API.service';
import { layerTypes, accurateTextOffsetValues, BASE_MAP_LAYERS, BASE_MAP_SOURCES, BASE_MAP_TILES, BASE_LAYER_URLS, BUBBLE_ICON_STYLE, BUBBLE_TEXT_STYLE, POLYGON_DEFAULT_STYLE, POLYGON_APPROVED_ICON_STYLE, POLYGON_UNAPPROVED_ICON_STYLE } from '../app.constants';

@Injectable()
export class LegendCtrl {
  private pbfPath = `${env.pbfPath}`;
  private tOsmPbfPath = `${env.tOsmPbfPath}`;
  private raster_pbfPath = `${env.rasterPbfPath}`;
  geoJsonTHLevel = 14;
  colorArray: Array<string>;
  borderColorArray: Array<string>;
  map: any;
  bounds: any;
  flag = 0;
  userData: any;
  mapView: any;
  activeLayers: Array<any> = [];
  labelLayers: Array<any> = [];
  svgStringObjs = {};
  loadedLayerData = {};
  private loadingSubject = new Subject<any>();
  advancedToolsGraphicsSelectionActive = false;
  afterAddedAllkeys = [];
  iconStyleKey: any;
  layoutObjInTextStyle: {};
  paintObjInTextStyle: {};
  layerDataStatusObj = {
    Pending: 1,
    Approved: 2,
    Rejected: 3
  };
  sourceChanged: boolean;
  plotLayersCalled: boolean;
  allUpdatedLayers: any[];
  updatedLayerObj: any;
  @Output() fitToBoundObserver: EventEmitter<any> = new EventEmitter();
  fitBoundSubscriber: Subscription;
  silentLoaderSubscriber: Subscription;
  boundsOfAllLayers: BehaviorSubject<any> = new BehaviorSubject({});
  selectedLayersInTools: string[] = [];
  count: number = 0;
  attrCount: number = 0;
  credits: mapboxgl.AttributionControl;
  toolsActive: boolean = false;
  layersSubscriber: Subscription = null;
  selectedPolygonSymbol: string = 'solid';
  minMaxLayerValues: any = {};
  minDataValue: number = null;
  maxDataValue: number = null;
  filedLabelChanged: boolean = false;
  metaImageSubject: Subject<any> = new Subject();
  geoJsonData: any = null; // To reuse the geojson data fetched for duplicate layers.
  pendingGeoJsonLayers: any = null; //  To store the duplicate layers for which the geojson data fetching is under process.
  textOffsetValues = accurateTextOffsetValues;
  isSwipeEnable: boolean = false;
  constructor(
    private layerService: LayerService,
    private sharedService: SharedService,
    public sessionService: SessionService,
    private utilService: Utilities,
    public _store: Store<any>,
    private toastr: ToastrService,
    private apiService: API,
  ) { }

  initLegendCtrl(mapRef, boundsRef) {
    if (["uat", "cityos"].includes(env.envName)) {
      this.pbfPath = `${this.sessionService.currentHost}${env.pbfPath}`;
      this.raster_pbfPath = `${this.sessionService.currentHost}${env.rasterPbfPath}`;
    }
    this.map = mapRef;
    this.bounds = boundsRef;
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.mapView = JSON.parse(localStorage.getItem('mapView'));
    this.labelLayers = [];
    this.sessionService.measureActive = false;
    if (!this.fitBoundSubscriber) {
      this.fitBoundSubscriber = this.getFitToBound().subscribe(value => {
        if (value) {
          if (!this.hasUnplottedLayrs() && this.sessionService.fitBounds) {
            let interval = setInterval(e => {
              if (this.map._loaded && this.map.areTilesLoaded()) {
                this.setPreviewIcon();
                setTimeout(_ => {
                  this.showBoundBox();
                  setTimeout(_ => {
                    this.sessionService.fitBounds = false;
                    clearInterval(interval);
                  }, 1000);
                }, 500);
              }
            }, 1000);
          }
        }
      })
    }
    this.sessionService.getAdvancedToolsGraphicsSelectionActive().subscribe(value => this.advancedToolsGraphicsSelectionActive = value);
    if (!this.silentLoaderSubscriber) {
      this.silentLoaderSubscriber = this.sessionService.getSilentLoader().subscribe(value => {
        if (value) {
          let interval = setInterval(e => {
            if (this.getMapLayers().length && !this.hasUnplottedLayrs()) {

              const layerIds = this.sharedService.mapLayers.filter(layer => layer.show).map(layer => layer.id);
              this.reOrderLayers(layerIds.reverse());
              this.sessionService.setSilentLoader(false);
              clearInterval(interval);
            }
          }, 2000);
        }
      })
    }
    this.sessionService.getIsToolsTabActive().subscribe(value => this.toolsActive = value);
    this.sessionService.getSelectedLayersInTools().subscribe(layers => this.selectedLayersInTools = layers);
    this.sessionService.getFiledLabelChanged().subscribe(value => this.filedLabelChanged = value);
  }

  getMapLayers() {
    return this.map.getStyle().layers.filter((layer) => layer.id != 'wms-base-layer')
  }

  hasUnplottedLayrs() {
    const layerIds = this.map.getStyle().layers.filter((layer) => layer.id != 'wms-base-layer').map(layer => layer.id);
    const layers = this.sharedService.mapLayers.filter(layer => layer.show).filter(layer => this.layerInSBound(layer));
    let notPlottedLayers = layers.filter(layer => layerIds.indexOf(layer.id) === -1);
    let unPlottedLayersLength = notPlottedLayers.length;
    notPlottedLayers.forEach(layer => {
      if (layer.style_type === 3) {
        if (!(layer.graduated_label && layer.classification_method && layer.gradualted_class)) unPlottedLayersLength--;
      }
    })
    return unPlottedLayersLength;
  }

  getLoadingStatus = (): Observable<any> => {
    return this.loadingSubject.asObservable();
  };
  setBoundsOfAllLayers(newBounds) {
    this.boundsOfAllLayers.next(newBounds);
  }
  layerInSBound(layer) {
    const polygon = this.getScreenBounds();
    const lPolygon = this.getLayerBBox(layer);
    return lPolygon ? !(turf.booleanDisjoint(polygon, lPolygon)) : true;
  }
  getBoundingBoxOfAllLayers(): Observable<any> {
    this.sharedService.mapLayers.forEach((layer, i) => {
      if (!layer.show) {
        return;
      }
      if (layer.bounding_box && layer.bounding_box.length > 0) {
        const sw = new mapboxgl.LngLat(layer.bounding_box[0], layer.bounding_box[1]);
        const ne = new mapboxgl.LngLat(layer.bounding_box[2], layer.bounding_box[3]);
        const newBounds = new mapboxgl.LngLatBounds(sw, ne);
        if (this.sharedService.mapLayers.length == i + 1) {
          this.setBoundsOfAllLayers(newBounds)
        }
      }
    });
    return this.boundsOfAllLayers;
  }

  getFitToBound() {
    return this.fitToBoundObserver
  }

  setFitToBound(value) {
    this.fitToBoundObserver.emit(value);
  }

  getCurrentMapCenter() {
    if (!this.map) return
    const center = this.map.getCenter();
    return [center.lng, center.lat];
  }
  getCurrentMapZoom() {
    if (this.map) {

      return this.map.getZoom();
    }
  }
  getScreenBounds = () => {
    const bounds = this.map.getBounds();
    const southEast = bounds.getSouthEast();
    const northWest = bounds.getNorthWest();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    if (northWest.lng === northEast.lng && northWest.lat === northEast.lat) {
      return null;
    }
    const polygon = turf.polygon([
      [
        [northWest.lng, northWest.lat],
        [northEast.lng, northEast.lat],
        [southEast.lng, southEast.lat],
        [southWest.lng, southWest.lat],
        [northWest.lng, northWest.lat]
      ]
    ]);
    return polygon;
  };

  getLayerBBox = (layer) => {
    const bbox = layer.bounding_box || [];
    if (Array.isArray(bbox) && bbox.length && bbox.length === 4) {
      const southEast = { lat: bbox[1], lng: bbox[2] };
      const northWest = { lat: bbox[3], lng: bbox[0] };
      const northEast = { lat: bbox[1], lng: bbox[0] };
      const southWest = { lat: bbox[3], lng: bbox[2] };
      return turf.polygon([
        [
          [northWest.lng, northWest.lat],
          [northEast.lng, northEast.lat],
          [southEast.lng, southEast.lat],
          [southWest.lng, southWest.lat],
          [northWest.lng, northWest.lat]
        ]
      ]);
    }
    return null;
  }

  getScale() {
    return this.map ? this.map.getZoom().toFixed(1) : 4;
  }

  loadLabelsLayer(layerObj, index, totLayers, selectedLayersInTools) {
    if (!this.map) return;
    // console.log(layerObj)
    // console.log(index)
    // console.log(totLayers)
    // console.log(selectedLayersInTools)
    // console.log(layerObj)

    // set selectedLayersInTools. It will help us to identify which layers to be plotted using GeoJSON data
    this.selectedLayersInTools = selectedLayersInTools;
    this.removeLayerAndSource(layerObj);
    const source_id = this.getSourceId(layerObj);
    const layer_id = this.getLayerId(layerObj);
    this.updateLayerOrAddSource(layerObj, source_id, layer_id, index, totLayers);
    this.addNewLayers(layerObj, source_id, layer_id, index, totLayers);
  }

  removeLayerAndSource(layerObj) {
    // for layer_type: 1,6, check if the text styles are modified.
    // Nothing to check for GeoJSON layers. If a layer is selected in tools, it has to be removed.
    if ((layerObj.layer_type == 1 || layerObj.layer_type == 6) && this.needSourceUpdate(layerObj)) {
      this.removeSourceForLayer(layerObj);
    }
  }

  getSourceId(layerObj) {
    if (layerObj.layer_type === 6) {
      return layerObj.source_layer;
    } else {
      return layerObj.parent_layer_id;
    }
  }

  getLayerId(layerObj) {
    return layerObj.parent_layer_id;
  }

  updateLayerOrAddSource(layerObj, source_id, layer_id, index, totLayers) {
    if (!source_id || !layer_id) return
    if ((this.getCurrentMapZoom() >= this.geoJsonTHLevel) && layerObj.layer_type !== 6) return;
    if (this.selectedLayersInTools.indexOf(layer_id) >= 0) return; // if the layer is selected in tools tab, return
    if ([1, 6].includes(layerObj.layer_type)) {
      if (layerObj.layer_type === 1) {
        if (this.labelLayers['labelLayer' + layerObj.id]) {
          if (this.map.getLayer(layerObj.id)) {
            if (layerObj.style_type === 4) {
              if (this.map.getLayer(layerObj.id).type !== 'circle') {
                this.sessionService.setFiledLabelChanged(false);
                this.removeLayerById(layerObj.id, false);
                if (layerObj.bubble_label) {
                  this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.bubble_label }).subscribe((response: any) => {
                    if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                      delete this.minMaxLayerValues[layerObj.id];
                    }
                    if (response.count && response.data.filter(d => d).length) {
                      this.minMaxLayerValues[layerObj.id] = {
                        minDataValue: +response.min,
                        maxDataValue: +response.max
                      }
                    }
                    this.addLayerToMap(layerObj);
                  })
                } else {
                  this.addLayerToMap(layerObj);
                }
              } else if (this.filedLabelChanged && layerObj.bubble_label) {
                this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.bubble_label }).subscribe((response: any) => {
                  if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                    delete this.minMaxLayerValues[layerObj.id];
                  }
                  if (response.count && response.data.filter(d => d).length) {
                    this.minMaxLayerValues[layerObj.id] = {
                      minDataValue: +response.min,
                      maxDataValue: +response.max
                    }
                  }
                  this.updateLayerStyles(layerObj, index, totLayers);
                })
              } else {
                this.updateLayerStyles(layerObj, index, totLayers);
              }
            } else if (layerObj.style_type === 5) {
              if (this.map.getLayer(layerObj.id).type !== 'heatmap') {
                this.sessionService.setFiledLabelChanged(false);
                this.removeLayerById(layerObj.id, false);
                if (layerObj.heat_map_label) {
                  this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.heat_map_label }).subscribe((response: any) => {
                    if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                      delete this.minMaxLayerValues[layerObj.id];
                    }
                    if (response.count && response.data.filter(d => d).length) {
                      this.minMaxLayerValues[layerObj.id] = {
                        minDataValue: +response.min,
                        maxDataValue: +response.max
                      }
                    }
                    this.addLayerToMap(layerObj);
                  })
                } else {
                  this.addLayerToMap(layerObj);
                }
              } else if (this.filedLabelChanged && layerObj.heat_map_label) {
                this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.heat_map_label }).subscribe((response: any) => {
                  if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                    delete this.minMaxLayerValues[layerObj.id];
                  }
                  if (response.count && response.data.filter(d => d).length) {
                    this.minMaxLayerValues[layerObj.id] = {
                      minDataValue: +response.min,
                      maxDataValue: +response.max
                    }
                  }
                  this.updateLayerStyles(layerObj, index, totLayers);
                })
              } else {
                this.updateLayerStyles(layerObj, index, totLayers);
              }
            } else if (layerObj.style_type === 7) {
              if (
                (this.map.getLayer(layerObj.id).type !== 'circle') ||
                ((this.map.getLayer(layerObj.id).type === 'circle') && !(this.labelLayers['labelLayer' + layerObj.id + 2]))
              ) {
                this.removeLayerById(layerObj.id, false);
                this.addLayerToMap(layerObj);
              } else {
                this.updateLayerStyles(layerObj, index, totLayers);
              }
            } else {
              this.updateLayerStyles(layerObj, index, totLayers);
            }
          }
        } else {
          // if (this.isTextPropertySelected(layerObj)) {
          if (!this.map.getSource(source_id)) { // to handle duplicate layers
            if (layerObj.style_type === 7) {
              this.plotGeoJSONLayer(layerObj, source_id, layer_id, index, totLayers);
            } else {
              this.addSource(layerObj, source_id, layer_id, 'vector', true);
            }
          }
          // } else {
          //   const noPropsId = layer_id + '_no_prop';
          //   if (layerObj.style_type === 3) {
          //     if (layerObj.graduated_label && layerObj.classification_method && !layerObj.show_text_style) {
          //       if (!this.map.getSource(noPropsId)) { // to handle duplicate layers
          //         this.addSource(layerObj, layer_id, layer_id, 'vector', false);
          //       }
          //     }
          //   } else {
          //     if (!this.map.getSource(noPropsId)) { // to handle duplicate layers
          //       this.addSource(layerObj, noPropsId, layer_id, 'vector', false);
          //     }
          //   }
          // }
        }
      } else if (layerObj.layer_type === 6) {
        if (this.labelLayers['labelLayer' + layerObj.id]) {
          if (this.map.getLayer(layerObj.id)) {
            if (layerObj.style_type === 4) {
              if (
                (this.map.getLayer(layerObj.id).type !== 'circle') ||
                ((this.map.getLayer(layerObj.id).type === 'circle') && (this.labelLayers['labelLayer' + layerObj.id + 2]))
              ) {
                this.sessionService.setFiledLabelChanged(false);
                this.removeLayerById(layerObj.id, false);
                if (layerObj.bubble_label) {
                  this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.bubble_label }).subscribe((response: any) => {
                    if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                      delete this.minMaxLayerValues[layerObj.id];
                    }
                    if (response.count && response.data.filter(d => d).length) {
                      this.minMaxLayerValues[layerObj.id] = {
                        minDataValue: +response.min,
                        maxDataValue: +response.max
                      }
                    }
                    this.addLayerToMap(layerObj);
                  })
                } else {
                  this.addLayerToMap(layerObj);
                }
              } else if (this.filedLabelChanged && layerObj.bubble_label) {
                this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.bubble_label }).subscribe((response: any) => {
                  if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                    delete this.minMaxLayerValues[layerObj.id];
                  }
                  if (response.count && response.data.filter(d => d).length) {
                    this.minMaxLayerValues[layerObj.id] = {
                      minDataValue: +response.min,
                      maxDataValue: +response.max
                    }
                  }
                  this.updateLayerStyles(layerObj, index, totLayers);
                })
              } else {
                this.updateLayerStyles(layerObj, index, totLayers);
              }
            } else if (layerObj.style_type === 5) {
              if (this.map.getLayer(layerObj.id).type !== 'heatmap') {
                this.sessionService.setFiledLabelChanged(false);
                this.removeLayerById(layerObj.id, false);
                if (layerObj.heat_map_label) {
                  this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.heat_map_label }).subscribe((response: any) => {
                    if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                      delete this.minMaxLayerValues[layerObj.id];
                    }
                    if (response.count && response.data.filter(d => d).length) {
                      this.minMaxLayerValues[layerObj.id] = {
                        minDataValue: +response.min,
                        maxDataValue: +response.max
                      }
                    }
                    this.addLayerToMap(layerObj);
                  })
                } else {
                  this.addLayerToMap(layerObj);
                }
              } else if (this.filedLabelChanged && layerObj.heat_map_label) {
                this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.heat_map_label }).subscribe((response: any) => {
                  if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                    delete this.minMaxLayerValues[layerObj.id];
                  }
                  if (response.count && response.data.filter(d => d).length) {
                    this.minMaxLayerValues[layerObj.id] = {
                      minDataValue: +response.min,
                      maxDataValue: +response.max
                    }
                  }
                  this.updateLayerStyles(layerObj, index, totLayers);
                })
              } else {
                this.updateLayerStyles(layerObj, index, totLayers);
              }
            } else if (layerObj.style_type === 7) {
              if (
                (this.map.getLayer(layerObj.id).type !== 'circle') ||
                ((this.map.getLayer(layerObj.id).type === 'circle') && !(this.labelLayers['labelLayer' + layerObj.id + 2]))
              ) {
                this.removeLayerById(layerObj.id, false);
                this.addLayerToMap(layerObj);
              } else {
                this.updateLayerStyles(layerObj, index, totLayers);
              }
            } else {
              this.updateLayerStyles(layerObj, index, totLayers);
            }
          }
        } else {
          if (!this.map.getSource(layerObj.source_layer)) { // to handle duplicate layers
            this.addSource(layerObj, source_id, layer_id, 'vector', false);
          }
        }
      }
    }
  }

  addNewLayers(layerObj, source_id, layer_id, index, totLayers) {
    if ((this.getCurrentMapZoom() >= this.geoJsonTHLevel) && ![2, 5, 6].includes(layerObj.layer_type)) {
      this.plotGeoJSONLayer(layerObj, source_id, layer_id, index, totLayers);
    } else {
      if (layerObj.layer_type == 1) {
        if (this.selectedLayersInTools.indexOf(layer_id) >= 0) {
          this.plotGeoJSONLayer(layerObj, source_id, layer_id, index, totLayers);
        } else {
          if (this.labelLayers['labelLayer' + layerObj.id]) return;
          if (layerObj.style_type === 3) {
            if (layerObj.graduated_label && layerObj.classification_method) {
              this.addLayerToMap(layerObj);
            }
          } else if (layerObj.style_type === 4) {
            if (layerObj.bubble_label) {
              this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.bubble_label }).subscribe((response: any) => {
                if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                  delete this.minMaxLayerValues[layerObj.id];
                }
                if (response.count && response.data.filter(d => d).length) {
                  this.minMaxLayerValues[layerObj.id] = {
                    minDataValue: +response.min,
                    maxDataValue: +response.max
                  }
                }
                this.addLayerToMap(layerObj);
              })
            } else {
              this.addLayerToMap(layerObj);
            }
          } else if (layerObj.style_type === 5) {
            if (layerObj.heat_map_label) {
              this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.heat_map_label }).subscribe((response: any) => {
                if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                  delete this.minMaxLayerValues[layerObj.id];
                }
                if (response.count && response.data.filter(d => d).length) {
                  this.minMaxLayerValues[layerObj.id] = {
                    minDataValue: +response.min,
                    maxDataValue: +response.max
                  }
                }
                this.addLayerToMap(layerObj);
              })
            } else {
              this.addLayerToMap(layerObj);
            }
          } else if (layerObj.style_type === 7) {
            // this.addLayerToMap(layerObj);
            this.plotGeoJSONLayer(layerObj, source_id, layer_id, index, totLayers)
            return;
          } else {
            this.addLayerToMap(layerObj);
          }
          if (index === totLayers - 1) {
            this.renderLineMeasure();
          }
        }
      } else if (layerObj.layer_type == 3 || layerObj.layer_type === 4) {
        this.plotGeoJSONLayer(layerObj, source_id, layer_id, index, totLayers);
      } else if (layerObj.layer_type === 2 || layerObj.layer_type === 5) {
        if (!this.map.getSource(source_id)) { // to handle duplicate layers
          this.addSource(layerObj, source_id, layer_id, 'raster', false);
        }
        if (!this.labelLayers['labelLayer' + layerObj.id]) {
          this.labelLayers['labelLayer' + layerObj.id] = this.map.addLayer(
            {
              'id': layerObj.id,
              'type': 'raster',
              'source': source_id,
              layout: { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
              maxzoom: layerObj.max_zoom,
              minzoom: layerObj.min_zoom
            }
          );
        } else {
          this.map.setLayerZoomRange(layerObj.id, layerObj.min_zoom, layerObj.max_zoom);
          this.map.moveLayer(layerObj.id);
        }
        if (index === totLayers - 1) {
          this.renderLineMeasure();
          this.setFitToBound(true);
        }
      } else if (layerObj.layer_type === 6) {
        if (this.labelLayers['labelLayer' + layerObj.id]) return;
        if (layerObj.style_type === 4) {
          if (layerObj.bubble_label) {
            this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.bubble_label }).subscribe((response: any) => {
              if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                delete this.minMaxLayerValues[layerObj.id];
              }
              if (response.count && response.data.filter(d => d).length) {
                this.minMaxLayerValues[layerObj.id] = {
                  minDataValue: +response.min,
                  maxDataValue: +response.max
                }
              }
              this.addLayerToMap(layerObj);
            })
          } else {
            this.addLayerToMap(layerObj);
          }
        } else if (layerObj.style_type === 5) {
          if (layerObj.heat_map_label) {
            this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj.heat_map_label }).subscribe((response: any) => {
              if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
                delete this.minMaxLayerValues[layerObj.id];
              }
              if (response.count && response.data.filter(d => d).length) {
                this.minMaxLayerValues[layerObj.id] = {
                  minDataValue: +response.min,
                  maxDataValue: +response.max
                }
              }
              this.addLayerToMap(layerObj);
            })
          } else {
            this.addLayerToMap(layerObj);
          }
        } else if (layerObj.style_type === 7) {
          // this.addLayerToMap(layerObj);
          return;
        } else {
          this.addLayerToMap(layerObj);
        }
        if (index === totLayers - 1) {
          this.renderLineMeasure();
        }
      }
    }
  }

  plotGeoJSONLayer(layerObj, source_id, layer_id, index, totLayers) {
    const polygon = this.getScreenBounds();
    if (!polygon) {
      return;
    }
    if (!this.layerInSBound(layerObj)) return;
    if ([4, 5].includes(+layerObj.style_type)) {
      let prop = null;
      switch (+layerObj.style_type) {
        case 4:
          prop = "bubble_label"
          break;
        case 5:
          prop = "heat_map_label"
          break;
      }
      if (layerObj[prop]) {
        this.layerService.getLayerUniqueProperties(layerObj.parent_layer_id, { property: layerObj[prop] }).subscribe((response: any) => {
          if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
            delete this.minMaxLayerValues[layerObj.id];
          }
          if (response.count && response.data.filter(d => d).length) {
            this.minMaxLayerValues[layerObj.id] = {
              minDataValue: +response.min,
              maxDataValue: +response.max
            }
          }
        })
      }
    }
    if (this.geoJsonData && this.geoJsonData[layerObj.id]) {
      if (!this.labelLayers['labelLayer' + layerObj.id]) {
        this.addLayerToMap(layerObj);
      } else {
        if (this.map.getLayer(layerObj.id)) {
          this.updateLayerStyles(layerObj, index, totLayers);
        }
      }
    } else {
      if (this.pendingGeoJsonLayers && this.pendingGeoJsonLayers[layerObj.parent_layer_id] && this.pendingGeoJsonLayers[layerObj.parent_layer_id].pending) {
        this.pendingGeoJsonLayers[layerObj.parent_layer_id].objs = this.pendingGeoJsonLayers[layerObj.parent_layer_id].objs ? [...this.pendingGeoJsonLayers[layerObj.parent_layer_id].objs, layerObj] : [layerObj];
      } else {
        this.pendingGeoJsonLayers = this.pendingGeoJsonLayers || {};
        this.pendingGeoJsonLayers[layerObj.parent_layer_id] = {};
        this.pendingGeoJsonLayers[layerObj.parent_layer_id].objs = [layerObj];
        this.pendingGeoJsonLayers[layerObj.parent_layer_id].pending = true;

        const layerType = layerObj.type ? layerObj.type : 'polygon';
        const coordinates = polygon.geometry.coordinates[0];
        const coordData = JSON.stringify(coordinates);
        const dataObj = {
          layer: layer_id,
          type: layerTypes[layerType],
          coordinates: coordData,
          simplify: true,
          zoom_level: Math.floor(this.getScale()),
          session_layer: layerObj.layerId
        };
        this.layerService.getLayerGraphicsData(dataObj).pipe(first()).subscribe(
          (response: any) => {
            let geoJson = response.data[0].geo_json;
            geoJson.features.forEach(feature => {
              if (feature.properties) feature.properties['is_approved'] = feature.is_approved;
              else feature.properties = { is_approved: feature.is_approved };
            });
            this.geoJsonData = this.geoJsonData || {};
            this.geoJsonData[response.data[0].id] = Object.assign({}, geoJson);
            this.pendingGeoJsonLayers[response.data[0].id].pending = false;
            this.pendingGeoJsonLayers[response.data[0].id].objs.forEach((obj, ind) => {
              if (this.labelLayers['labelLayer' + obj.id]) {
                if (this.map.getSource(response.data[0].id)) {

                console.log("May 25 Code Breaks here"+(response.data[0].id))
                var temp = this.map.getSource(response.data[0].id)
                console.log("May 25 Get Source "+temp)
                  this.map.getSource(response.data[0].id).setData(geoJson);
                } else {
                  if (layerObj.style_type === 7) {
                    this.map.addSource(response.data[0].id, {
                      type: 'geojson',
                      data: geoJson,
                      cluster: true,
                      clusterRadius: 50
                    })
                  } else {
                    this.map.addSource(response.data[0].id, {
                      type: 'geojson',
                      data: geoJson,
                      tolerance: 0
                    })
                  }
                }
              } else {
                let source = this.map.getSource(response.data[0].id);
                if (!source) {
                  if (layerObj.style_type === 7) {
                    this.map.addSource(response.data[0].id, {
                      type: 'geojson',
                      data: geoJson,
                      cluster: true,
                      clusterRadius: 50
                    })
                  } else {
                    this.map.addSource(response.data[0].id, {
                      type: 'geojson',
                      data: geoJson,
                      tolerance: 0
                    })
                  }
                }
              }
              if (!this.labelLayers['labelLayer' + obj.id]) {
                this.addLayerToMap(obj);
              } else {
                if (this.map.getLayer(obj.id)) {
                  this.updateLayerStyles(obj, index, totLayers);
                }
              }
              // if (index === totLayers - 1) {
              //   this.renderLineMeasure();
              // }
            });
            this.pendingGeoJsonLayers[response.data[0].id].objs = [];
          },
          error => {
            this.loadingSubject.next(false);
          }
        )
      }
    }
  }

  isTextPropertySelected(layerObj) {
    return (
      (layerObj.fixed_field_label && layerObj.style_type === 1) ||
      (layerObj.category_label && layerObj.style_type === 2) ||
      (
        layerObj.graduated_label && layerObj.style_type === 3 &&
        layerObj.classification_method && layerObj.show_text_style
      ) ||
      (layerObj.style_type === 4) ||
      (layerObj.style_type === 5) ||
      (layerObj.style_type === 7) ||
      (layerObj.style_type === 6 && layerObj.dotted_display_label)
    );
  }

  addSource(layerObj, source_id, layer_id, type, prop?) {
    const layerType = layerObj.layer_type;
    switch (layerType) {
      case 1:
        if (prop) {
          this.map.addSource(source_id, {
            type: type,
            minzoom: layerObj.min_tile_zoom || 1,
            maxzoom: layerObj.tile_zoom || 3,
            tiles: [
              `${this.pbfPath}/${layer_id}/1/{z}/{x}/{y}.pbf`
            ]
          });
        } else {
          if (layerObj.style_type === 3) {
            this.map.addSource(source_id, {
              type: type,
              minzoom: layerObj.min_tile_zoom || 1,
              maxzoom: layerObj.tile_zoom || 3,
              tiles: [
                `${this.pbfPath}/${layer_id}/1/{z}/{x}/{y}.pbf`
              ]
            })
          } else {
            this.map.addSource(source_id, {
              type: type,
              minzoom: layerObj.min_tile_zoom || 1,
              maxzoom: layerObj.tile_zoom || 3,
              tiles: [
                // `${this.pbfPath}/${layer_id}/0/{z}/{x}/{y}.pbf`
                `${this.pbfPath}/${layer_id}/1/{z}/{x}/{y}.pbf`
              ]
            });
          }
        }
        break;
      case 2:
        this.map.addSource(source_id, {
          type: 'raster',
          minzoom: layerObj.min_tile_zoom || 1,
          maxzoom: layerObj.tile_zoom || 5,
          tiles: [
            `${this.raster_pbfPath}/${layer_id}/{z}/{x}/{y}.png`
          ],
          scheme: 'tms'
        });
        break;
      case 5:
        this.map.addSource(source_id, {
          type: 'raster',
          minzoom: layerObj.min_tile_zoom || 1,
          maxzoom: layerObj.tile_zoom || 5,
          tiles: [
            layerObj.layer_url
          ],
          tileSize: 256
        });
        break;
      case 6:
        this.map.addSource(source_id, {
          type: type,
          maxzoom: layerObj.tile_zoom || 3,
          minzoom: layerObj.min_tile_zoom || 1,
          tiles: [
            `${this.tOsmPbfPath}/${layer_id}/{z}/{x}/{y}.pbf`
          ]
        });
        break;
    }
  }

  needSourceUpdate(layerObj) {
    // if (!layerObj) return;
    // fetch the source by getSource(layer_id)
    // then check the source type and the layer type
    // if (edit is active and layer_id is selected and layer_type =1 ), and source type is not geojson, remove source
    // if (edit is active and layer_id is not selected and layer_type = 1) and source type is geojson, remove source
    // if (edit is not active and layer_type = 1) and source type is geojson, remove source
    if (layerObj.layer_type === 6) {
      return false;
    }
    let layer_id = layerObj.parent_layer_id;
    const styletype = layerObj.style_type;
    // const source_prop = this.map.getSource(layer_id);
    // const source_no_prop = this.map.getSource(layer_id + '_no_prop');
    // const source = source_prop || source_no_prop;
    const source = this.map.getSource(layer_id);
    if (this.getCurrentMapZoom() >= this.geoJsonTHLevel) {
      if (source && source.type !== 'geojson') {
        return true;
      }
      return this.styleTypeUpdated(layerObj);
    }
    if (source) {
      const sourceType = source.type;
      if (this.toolsActive && (this.selectedLayersInTools.indexOf(layer_id) >= 0) && (sourceType !== 'geojson') && ([1, 4, 5, 6, 7].includes(styletype))) {
        return true;
      }
      if (this.toolsActive && !(this.selectedLayersInTools.indexOf(layer_id) >= 0) && (sourceType === 'geojson') && (styletype === 1)) {
        return true;
      }
      if (!this.toolsActive && (sourceType === 'geojson') && ([1, 2, 3, 4, 5, 6].includes(styletype))) {
        return true;
      }
      if (this.toolsActive && (styletype === 1)) return false;
      return this.styleTypeUpdated(layerObj);
    } else {
      return false;
    }
  }

  styleTypeUpdated(layerObj) {
    if ([1, 2, 3].includes(layerObj.style_type)) {
      if (layerObj.type === "point" && this.labelLayers['labelLayer' + layerObj.id]) {
        const type = this.map.getLayer(layerObj.id) ? this.map.getLayer(layerObj.id).type : "symbol";
        if ((type === 'circle') || (type === 'heatmap')) return true;
      }
      return false;
    }
    if (layerObj.style_type === 4) {
      if (layerObj.type === "point" && this.labelLayers['labelLayer' + layerObj.id]) {
        const type = this.map.getLayer(layerObj.id) ? this.map.getLayer(layerObj.id).type : "symbol";
        if (type !== 'circle') return true;
        else if (this.labelLayers['labelLayer' + layerObj.id + 2]) return true; //  cluster layers have additional layer.
      }
      return false;
    }
    if (layerObj.style_type === 5) {
      if (layerObj.type === "point" && this.labelLayers['labelLayer' + layerObj.id]) {
        const type = this.map.getLayer(layerObj.id) ? this.map.getLayer(layerObj.id).type : "symbol";
        if (type !== 'heatmap') return true;
      }
      return false;
    }
    if (layerObj.style_type === 6) {
      if (layerObj.type === "point" && this.labelLayers['labelLayer' + layerObj.id]) {
        const type = this.map.getLayer(layerObj.id) ? this.map.getLayer(layerObj.id).type : "symbol";
        if ((type === 'circle') || (type === 'heatmap')) return true;
      }
    }
    if (layerObj.style_type === 7) {
      if (layerObj.type === "point" && this.labelLayers['labelLayer' + layerObj.id]) {
        const type = this.map.getLayer(layerObj.id) ? this.map.getLayer(layerObj.id).type : "symbol";
        if (type !== 'circle') return true;
        else if (!this.labelLayers['labelLayer' + layerObj.id + 2]) return true;
      }
      return false;
    }
    return false
  }

  filterBubblePaintProps(paintProps, type) {
    let textPaintProps;
    switch (type) {
      case 'circle':
        textPaintProps = ["circle-color", "circle-opacity", "circle-stroke-color", "circle-stroke-width", "circle-radius"];
        break;
      case 'text':
        textPaintProps = ['text-color', 'text-opacity', 'icon-opacity'];
        break;
    }
    let predicate = prop => textPaintProps.includes(prop);
    return Object.keys(paintProps)
      .filter(key => predicate(key))
      .reduce((res, key) => Object.assign(res, { [key]: paintProps[key] }), {});
  }

  filterHeatMapPaintProps(paintProps, type) {
    let textPaintProps;
    switch (type) {
      case 'heatmap':
        textPaintProps = ["heatmap-weight", "heatmap-intensity", "heatmap-color", "heatmap-radius", "heatmap-opacity"];
        break;
      case 'text':
        textPaintProps = ['text-color', 'text-opacity', 'icon-opacity'];
        break;
    }
    let predicate = prop => textPaintProps.includes(prop);
    return Object.keys(paintProps)
      .filter(key => predicate(key))
      .reduce((res, key) => Object.assign(res, { [key]: paintProps[key] }), {});
  }

  updateLayerStyles(layerObj, index, totalLayersCount) {
    let fallbackIconStyle: any = {}
    if (layerObj.style_type === 1) {

      fallbackIconStyle = layerObj.icon_style.find((obj) => obj.is_fall_back);
    } else if (layerObj.style_type === 2) {
      fallbackIconStyle = layerObj.category_icon_style.find((obj) => obj.is_fall_back);
    }
    this.plotLayersCalled = false;
    if (layerObj.type && layerObj.type == 'point') {
      const iconObj = this.getPointSymbolStylesObj(layerObj);
      // plot point
      let plotdata: any = null;
      let bubbleTextData: any = null;
      let heatMapTextData: any = null;
      let clusterCountData: any = null;
      if (layerObj.style_type === 4) {
        if (this.labelLayers['labelLayer' + layerObj.id]) {
          plotdata = this.plotLayer(
            layerObj,
            layerObj.id,
            this.filterBubblePaintProps(iconObj.paint, 'circle'),
            { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
            'circle'
          );
        }
        bubbleTextData = this.plotLayer(
          layerObj,
          layerObj.id + 1,
          this.filterBubblePaintProps(iconObj.paint, 'text'),
          iconObj.layout,
          'symbol'
        )
      } else if (layerObj.style_type === 5) {
        if (this.labelLayers['labelLayer' + layerObj.id]) {
          plotdata = this.plotLayer(
            layerObj,
            layerObj.id,
            this.filterHeatMapPaintProps(iconObj.paint, 'heatmap'),
            { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
            'heatmap'
          );
        }
        heatMapTextData = this.plotLayer(
          layerObj,
          layerObj.id + 1,
          this.filterHeatMapPaintProps(iconObj.paint, 'text'),
          iconObj.layout,
          'symbol'
        )
      } else if (layerObj.style_type === 7) {
        plotdata = this.plotLayer(
          layerObj,
          layerObj.id,
          this.filterBubblePaintProps(iconObj.paint, 'circle'),
          { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
          'circle'
        );
        clusterCountData = this.plotLayer(
          layerObj,
          layerObj.id + 1,
          this.filterBubblePaintProps(iconObj.paint, 'text'),
          iconObj.layout,
          'symbol'
        )
        // unClusteredData = this.plotLayer(
        //   layerObj,
        //   layerObj.id + 2,
        //   {
        //     'circle-color': '#11b4da',
        //     'circle-radius': 10,
        //     'circle-stroke-width': 1,
        //     'circle-stroke-color': '#fff'
        //   },
        //   {},
        //   'circle'
        // )
      } else {
        plotdata = this.plotLayer(
          layerObj,
          layerObj.id,
          iconObj.paint,
          iconObj.layout,
          'symbol'
        );
      }

      this.map.setLayerZoomRange(layerObj.id, plotdata.minzoom, plotdata.maxzoom);
      if (layerObj.style_type === 4) {
        if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
          this.map.setLayerZoomRange(layerObj.id + 1, bubbleTextData.minzoom, bubbleTextData.maxzoom);
        }
        this.map.setPaintProperty(layerObj.id, 'circle-color', plotdata.paint['circle-color']);
        this.map.setPaintProperty(layerObj.id, 'circle-opacity', plotdata.paint['circle-opacity']);
        this.map.setPaintProperty(layerObj.id, 'circle-stroke-color', plotdata.paint['circle-stroke-color']);
        this.map.setPaintProperty(layerObj.id, 'circle-radius', plotdata.paint['circle-radius']);
        if (bubbleTextData) {
          this.map.setPaintProperty(layerObj.id + 1, 'text-color', bubbleTextData.paint['text-color']);
          this.map.setPaintProperty(layerObj.id + 1, 'text-opacity', bubbleTextData.paint['text-opacity']);
          this.map.setPaintProperty(layerObj.id + 1, 'icon-opacity', bubbleTextData.paint['icon-opacity']);

          this.map.setLayoutProperty(layerObj.id + 1, 'text-field', bubbleTextData.layout['text-field']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-offset', bubbleTextData.layout['text-offset']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-size', bubbleTextData.layout['text-size']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-transform', bubbleTextData.layout['text-transform']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-justify', bubbleTextData.layout['text-justify']);
          this.map.setLayoutProperty(layerObj.id + 1, 'visibility', bubbleTextData.layout['visibility']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-letter-spacing', bubbleTextData.layout['text-letter-spacing'])
          this.map.setLayoutProperty(layerObj.id + 1, 'text-font', bubbleTextData.layout['text-font']);
        }
      } else if (layerObj.style_type === 5) {
        if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
          this.map.setLayerZoomRange(layerObj.id + 1, heatMapTextData.minzoom, heatMapTextData.maxzoom);
        }
        this.map.setPaintProperty(layerObj.id, 'heatmap-weight', plotdata.paint['heatmap-weight']);
        this.map.setPaintProperty(layerObj.id, 'heatmap-intensity', plotdata.paint['heatmap-intensity']);
        this.map.setPaintProperty(layerObj.id, 'heatmap-color', plotdata.paint['heatmap-color']);
        this.map.setPaintProperty(layerObj.id, 'heatmap-radius', plotdata.paint['heatmap-radius']);
        this.map.setPaintProperty(layerObj.id, 'heatmap-opacity', plotdata.paint['heatmap-opacity']);
        if (heatMapTextData) {
          this.map.setPaintProperty(layerObj.id + 1, 'text-color', heatMapTextData.paint['text-color']);
          this.map.setPaintProperty(layerObj.id + 1, 'text-opacity', heatMapTextData.paint['text-opacity']);
          this.map.setPaintProperty(layerObj.id + 1, 'icon-opacity', heatMapTextData.paint['icon-opacity']);

          this.map.setLayoutProperty(layerObj.id + 1, 'text-field', heatMapTextData.layout['text-field']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-offset', heatMapTextData.layout['text-offset']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-size', heatMapTextData.layout['text-size']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-transform', heatMapTextData.layout['text-transform']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-justify', heatMapTextData.layout['text-justify']);
          this.map.setLayoutProperty(layerObj.id + 1, 'visibility', heatMapTextData.layout['visibility']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-letter-spacing', heatMapTextData.layout['text-letter-spacing'])
          this.map.setLayoutProperty(layerObj.id + 1, 'text-font', heatMapTextData.layout['text-font']);
        }
      } else if (layerObj.style_type === 7) {
        if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
          this.map.setLayerZoomRange(layerObj.id + 1, clusterCountData.minzoom, clusterCountData.maxzoom);
        }
        this.map.setPaintProperty(layerObj.id, 'circle-color', plotdata.paint['circle-color']);
        this.map.setPaintProperty(layerObj.id, 'circle-opacity', plotdata.paint['circle-opacity']);
        this.map.setPaintProperty(layerObj.id, 'circle-stroke-color', plotdata.paint['circle-stroke-color']);
        this.map.setPaintProperty(layerObj.id, 'circle-radius', plotdata.paint['circle-radius']);
        if (clusterCountData) {
          this.map.setPaintProperty(layerObj.id + 1, 'text-color', clusterCountData.paint['text-color']);
          this.map.setPaintProperty(layerObj.id + 1, 'text-opacity', clusterCountData.paint['text-opacity']);
          this.map.setPaintProperty(layerObj.id + 1, 'icon-opacity', clusterCountData.paint['icon-opacity']);

          this.map.setLayoutProperty(layerObj.id + 1, 'text-field', clusterCountData.layout['text-field']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-offset', clusterCountData.layout['text-offset']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-size', clusterCountData.layout['text-size']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-transform', clusterCountData.layout['text-transform']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-justify', clusterCountData.layout['text-justify']);
          this.map.setLayoutProperty(layerObj.id + 1, 'visibility', clusterCountData.layout['visibility']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-letter-spacing', clusterCountData.layout['text-letter-spacing'])
          this.map.setLayoutProperty(layerObj.id + 1, 'text-font', clusterCountData.layout['text-font']);
        }
      } else {
        this.map.setLayoutProperty(layerObj.id, 'visibility', plotdata.layout.visibility);
        this.map.setPaintProperty(layerObj.id, 'icon-color', plotdata.paint['icon-color']);
        this.map.setPaintProperty(layerObj.id, 'text-color', plotdata.paint['text-color']);
        this.map.setPaintProperty(layerObj.id, 'text-opacity', plotdata.paint['text-opacity']);
        this.map.setPaintProperty(layerObj.id, 'icon-opacity', plotdata.paint['icon-opacity']);
        this.map.setLayoutProperty(layerObj.id, 'icon-image', plotdata.layout['icon-image']);
        this.map.setLayoutProperty(layerObj.id, 'icon-size', plotdata.layout['icon-size']);
        this.map.setLayoutProperty(layerObj.id, 'text-field', plotdata.layout['text-field']);
        this.map.setLayoutProperty(layerObj.id, 'text-font', plotdata.layout['text-font']);
        this.map.setLayoutProperty(layerObj.id, 'text-size', plotdata.layout['text-size']);
        this.map.setLayoutProperty(layerObj.id, 'text-transform', plotdata.layout['text-transform']);
        this.map.setLayoutProperty(layerObj.id, 'text-justify', plotdata.layout['text-justify']);
        this.map.setLayoutProperty(layerObj.id, 'text-letter-spacing', plotdata.layout['text-letter-spacing']);
        this.map.setLayoutProperty(layerObj.id, 'text-offset', plotdata.layout['text-offset']);
        if (layerObj.style_type === 2) {
          this.map.setLayoutProperty(layerObj.id, 'text-anchor', plotdata.layout['text-anchor'])
        } else {
          this.map.setLayoutProperty(layerObj.id, 'text-variable-anchor', plotdata.layout['text-variable-anchor'])
        }
        // this.map.setLayoutProperty(layerObj._id, 'text-font', plotdata)
      }
      let index = this.map.getStyle().layers.findIndex(({ id }) => id)
      if (index !== -1) {

        this.map.moveLayer(layerObj.id);
      }
    } else if (
      layerObj.type &&
      (layerObj.type == 'polygon' || layerObj.type == 'multipolygon')
    ) {
      const paintObj = this.getPolygonFillStylesObj(layerObj);
      this.map.setLayerZoomRange(layerObj.id, layerObj.min_zoom, layerObj.max_zoom);
      this.map.setLayoutProperty(layerObj.id, 'visibility', paintObj.layout.visibility);
      this.map.setPaintProperty(layerObj.id, 'fill-opacity', paintObj.paint['fill-opacity']);
      if (layerObj.style_type !== 1) {
        this.map.setPaintProperty(layerObj.id, 'fill-color', paintObj.paint['fill-color']);
        if (this.map.getPaintProperty(layerObj.id, 'fill-pattern')) {
          this.map.setPaintProperty(layerObj.id, 'fill-pattern', null);
        }
      }
      if (this.map.getLayer(layerObj.id + 2)) {
        if (!layerObj.categoryLabel) {
          this.map.setLayoutProperty(layerObj.id + 2, 'visibility', 'none');
        }
      }
      this.map.moveLayer(layerObj.id);
      if ((layerObj.fixed_field_label && layerObj.style_type === 1) ||
        (layerObj.category_label && layerObj.style_type === 2) ||
        (layerObj.style_type === 3)
      ) {

        const textStyle: any = this.getTextStylesForLayer(layerObj);
        if (this.labelLayers['labelLayer' + layerObj.id + 2]) {
          this.map.setLayerZoomRange(layerObj.id + 2, layerObj.min_zoom, layerObj.max_zoom);
          this.map.setLayoutProperty(layerObj.id + 2, 'visibility', textStyle.layout.visibility);
          this.map.setLayoutProperty(layerObj.id + 2, 'text-field', textStyle.layout['text-field']);
          this.map.setLayoutProperty(layerObj.id + 2, 'text-font', textStyle.layout['text-font']);

          this.map.setLayoutProperty(layerObj.id + 2, 'text-size', textStyle.layout['text-size']);
          this.map.setLayoutProperty(layerObj.id + 2, 'text-transform', textStyle.layout['text-transform']);
          this.map.setLayoutProperty(layerObj.id + 2, 'text-justify', textStyle.layout['text-justify']);

          this.map.setPaintProperty(layerObj.id + 2, 'text-opacity', textStyle.paint['text-opacity']);
          this.map.setPaintProperty(layerObj.id + 2, 'text-color', textStyle.paint['text-color']);
          this.map.setLayoutProperty(layerObj.id + 2, 'text-letter-spacing', textStyle.layout['text-letter-spacing']);
          this.map.moveLayer(layerObj.id + 2);

        } else {
          // Add text layer
          this.labelLayers['labelLayer' + layerObj.id + 2] = this.map.addLayer(
            this.plotLayer(
              layerObj,
              layerObj.id + 2,
              textStyle.paint,
              textStyle.layout,
              'symbol'
            )
          );
        }
      } else {
        if (layerObj.layer_type === 6 && this.labelLayers['labelLayer' + layerObj.id + 2]) {
          this.map.setPaintProperty(layerObj.id + 2, 'visibility', 'none');
        }
      }
      // const isPolylineLayerNeeded = (fallbackIconStyle.polygonStyle === 'dotted') ? false : this.isPolylineNeeded(layerObj);
      // if (isPolylineLayerNeeded) {

      let polyLineObj: any = {}
      if ((layerObj.layer_type === 3) && (layerObj.style_type === 2)) {
        polyLineObj = this.getDataCollectedPolylineCategoryStyle(layerObj);
      } else {

        polyLineObj = this.getPolylineStylesObj(layerObj);
      }

      if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
        this.map.setLayerZoomRange(layerObj.id + 1, layerObj.min_zoom, layerObj.max_zoom);
        this.map.setLayoutProperty(layerObj.id + 1, 'visibility', polyLineObj.layout['visibility']);
        this.map.setPaintProperty(layerObj.id + 1, 'line-width', polyLineObj.paint['line-width']);
        this.map.setPaintProperty(layerObj.id + 1, 'line-color', polyLineObj.paint['line-color']);
        this.map.setPaintProperty(layerObj.id + 1, 'line-opacity', polyLineObj.paint['line-opacity'])
        // this.map.setPaintProperty(layerObj.id + 1, 'line-width', polyLineObj.paint['line-width']);
        this.map.moveLayer(layerObj.id + 1);
      } else {
        if ((layerObj.type !== 'polyline') && (layerObj.type !== 'lineString') && (layerObj.type !== 'multilinestring')) {
          this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
            this.plotLayer(
              layerObj,
              layerObj.id + 1,
              polyLineObj.paint,
              polyLineObj.layout,
              'line')
          );
        }
      }
      // }
      //  else {
      //   if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
      //     this.removeLayerById(layerObj.id + 1, false);
      //   }
      // }
      // let fallbackIconStyle =layerObj.icon_style.find(({is_fall_back}) => is_fall_back);
      if ((layerObj.style_type == 1) || (layerObj.style_type === 2)) {
        let polygonStyles: any = {}
        polygonStyles = this.getPolygonFillStylesObj(layerObj);
        if (fallbackIconStyle.polygonStyle === 'solid') {
          this.map.setPaintProperty(layerObj.id, 'fill-color', polygonStyles.paint['fill-color']);
          this.map.setPaintProperty(layerObj.id, 'fill-opacity', polygonStyles.paint['fill-opacity']);
          this.map.on('load', () => {
            if (this.map.getPaintProperty(layerObj.id, 'fill-pattern')) {
              this.map.setPaintProperty(layerObj.id, 'fill-pattern', polygonStyles.paint['fill-pattern'])
            }
          })

        } else if (fallbackIconStyle.polygonStyle === 'dotted') {
          // making line as transparent while getting DottedStyles
          let dottedStyles = this.getDottedStyles(layerObj);
          this.map.setPaintProperty(layerObj.id, 'fill-color', dottedStyles['fill-color']);

          this.map.setPaintProperty(layerObj.id, 'fill-pattern', dottedStyles['fill-pattern']);
        } else if (fallbackIconStyle.polygonStyle === 'hollow') {
          this.map.setPaintProperty(layerObj.id, 'fill-color', polygonStyles.paint['fill-color']);
          this.map.setPaintProperty(layerObj.id, 'fill-pattern', polygonStyles.paint['fill-pattern'])
        }
      }
    } else {
      // polyline
      let polyLineObj: any = []
      if (layerObj.layer_type !== 3) {

        polyLineObj = this.getPolylineStylesObj(layerObj);
      } else {

        if (layerObj.style_type === 1) {

          polyLineObj = this.getDataCollectedPolylineFixedStyle(layerObj);
        } else if (layerObj.style_type === 2) {
          polyLineObj = this.getDataCollectedPolylineCategoryStyle(layerObj);
        }

      }
      this.map.setLayerZoomRange(layerObj.id, layerObj.min_zoom, layerObj.max_zoom);
      this.map.setLayoutProperty(layerObj.id, 'visibility', polyLineObj.layout['visibility']);
      this.map.setPaintProperty(layerObj.id, 'line-width', polyLineObj.paint['line-width']);
      // alert(polyLineObj.paint['line-width']);
      this.map.setPaintProperty(layerObj.id, 'line-opacity', (polyLineObj.paint['line-opacity']));

      this.map.setPaintProperty(layerObj.id, 'line-color', polyLineObj.paint['line-color']);
      // this.map.setPaintProperty(layerObj.id, 'line-width', polyLineObj.paint['line-width']);
      this.map.setPaintProperty(layerObj.id, 'line-dasharray', polyLineObj.paint['line-dasharray']);
      this.map.setLayoutProperty(layerObj.id, 'line-cap', polyLineObj.layout['line-cap']);
      this.map.moveLayer(layerObj.id);
      if ((layerObj.fixed_field_label && layerObj.style_type === 1) ||
        (layerObj.category_label && layerObj.style_type === 2) ||
        (layerObj.style_type === 3)
      ) {
        const textStyle: any = this.getTextStylesForLayer(layerObj);
        if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
          this.map.setLayerZoomRange(layerObj.id + 1, layerObj.min_zoom, layerObj.max_zoom);
          this.map.setLayoutProperty(layerObj.id + 1, 'visibility', textStyle.layout.visibility);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-field', textStyle.layout['text-field']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-font', textStyle.layout['text-font']);

          this.map.setLayoutProperty(layerObj.id + 1, 'text-size', textStyle.layout['text-size']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-transform', textStyle.layout['text-transform']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-justify', textStyle.layout['text-justify']);

          this.map.setPaintProperty(layerObj.id + 1, 'text-opacity', textStyle.paint['text-opacity']);
          this.map.setPaintProperty(layerObj.id + 1, 'text-color', textStyle.paint['text-color']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-offset', textStyle.layout['text-offset']);
          this.map.setLayoutProperty(layerObj.id + 1, 'text-anchor', textStyle.layout['text-anchor']);
          if (textStyle.layout && textStyle.layout['text-letter-spacing'] && textStyle.layout['text-letter-spacing'].length) {
            this.map.setLayoutProperty(layerObj.id + 1, 'text-letter-spacing', textStyle.layout['text-letter-spacing'])
          }

          this.map.moveLayer(layerObj.id + 1);
        } else {
          // Add text layer
          this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
            this.plotLayer(
              layerObj,
              layerObj.id + 1,
              textStyle.paint,
              textStyle.layout,
              'symbol'
            )
          );
        }
      } else {
        if (layerObj.layer_type === 6 && this.labelLayers['labelLayer' + layerObj.id + 1]) {
          this.map.setPaintProperty(layerObj.id + 1, 'visibility', 'none');
        }
      }
    }
    this.renderLineMeasure();
  }
  addLayerToMap(layerObj) {
    // For styling only.
    if (this.labelLayers['labelLayer' + layerObj.id]) return;
    if (layerObj.type && layerObj.type == 'point') {
      const iconObj = this.getPointSymbolStylesObj(layerObj);


      // plot point
      let plotdata: any = null;
      let bubbleTextData: any = null;
      let heatMapTextData: any = null;
      let clusterCountData: any = null;
      let unClusteredData: any = null;
      if (layerObj.style_type === 4) {
        plotdata = this.plotLayer(
          layerObj,
          layerObj.id,
          this.filterBubblePaintProps(iconObj.paint, 'circle'),
          { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
          'circle'
        );
        bubbleTextData = this.plotLayer(
          layerObj,
          layerObj.id + 1,
          this.filterBubblePaintProps(iconObj.paint, 'text'),
          iconObj.layout,
          'symbol'
        )
      } else if (layerObj.style_type === 5) {
        plotdata = this.plotLayer(
          layerObj,
          layerObj.id,
          this.filterHeatMapPaintProps(iconObj.paint, 'heatmap'),
          { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
          'heatmap'
        );
        heatMapTextData = this.plotLayer(
          layerObj,
          layerObj.id + 1,
          this.filterHeatMapPaintProps(iconObj.paint, 'text'),
          iconObj.layout,
          'symbol'
        )
      } else if (layerObj.style_type === 7) {
        plotdata = this.plotLayer(
          layerObj,
          layerObj.id,
          this.filterBubblePaintProps(iconObj.paint, 'circle'),
          { visibility: layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none' },
          'circle'
        );
        clusterCountData = this.plotLayer(
          layerObj,
          layerObj.id + 1,
          this.filterBubblePaintProps(iconObj.paint, 'text'),
          iconObj.layout,
          'symbol'
        )
        unClusteredData = this.plotLayer(
          layerObj,
          layerObj.id + 2,
          {
            'circle-color': '#11b4da',
            'circle-radius': 10,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
          },
          {},
          'circle'
        )
      } else {
        plotdata = this.plotLayer(
          layerObj,
          layerObj.id,
          iconObj.paint,
          iconObj.layout,
          'symbol'
        );
      }
      this.labelLayers['labelLayer' + layerObj.id] = this.map.addLayer(
        (layerObj.style_type === 7) ? { ...plotdata, filter: ['has', 'point_count'] } : plotdata
      );
      if (bubbleTextData) {
        this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
          bubbleTextData
        );
      }
      if (heatMapTextData) {
        this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
          heatMapTextData
        );
      }
      if (clusterCountData) {
        this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
          { ...clusterCountData, filter: ['has', 'point_count'] }
        )
      }
      if (unClusteredData) {
        this.labelLayers['labelLayer' + layerObj.id + 2] = this.map.addLayer(
          { ...unClusteredData, filter: ['!', ['has', 'point_count']] }
        )
      }
      this.setFitToBound(true);
    } else if (
      layerObj.type &&
      (layerObj.type == 'polygon' || layerObj.type == 'multipolygon')
    ) {
      // plot polygon
      let polyLineObj: any = {};
      const textStyle = this.getTextStylesForLayer(layerObj);
      const paintObj = this.getPolygonFillStylesObj(layerObj);
      if ((layerObj.layer_type === 3) && (layerObj.style_type == 2)) {
        polyLineObj = this.getDataCollectedPolylineCategoryStyle(layerObj);
      } else {

        polyLineObj = this.getPolylineStylesObj(layerObj);
      }


      this.labelLayers['labelLayer' + layerObj.id] = this.map.addLayer(
        this.plotLayer(layerObj, layerObj.id, paintObj.paint, paintObj.layout, 'fill')
      );
      if ((layerObj.type !== 'polyline') && (layerObj.type !== 'lineString') && (layerObj.type !== 'multilinestring')) {
        if (layerObj.style_type === 1) {
          let fallbackIconStyle = layerObj.icon_style.find((iconObj) => iconObj.is_fall_back) || POLYGON_DEFAULT_STYLE
          if (fallbackIconStyle.polygonStyle !== 'dotted') {
            this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
              this.plotLayer(
                layerObj,
                layerObj.id + 1,
                polyLineObj.paint,
                polyLineObj.layout,
                'line')
            );
          }
        } else {
          this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
            this.plotLayer(
              layerObj,
              layerObj.id + 1,
              polyLineObj.paint,
              polyLineObj.layout,
              'line')
          );

        }
      }
      // } 
      // else {
      //   if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
      //     this.removeLayerById(layerObj.id + 1, false);
      //   }
      // }
      // always plot text after polyline layer.
      if ((layerObj.fixed_field_label && layerObj.style_type === 1) ||
        (layerObj.category_label && layerObj.style_type === 2) ||
        (layerObj.style_type === 3)) {
        this.labelLayers['labelLayer' + layerObj.id + 2] = this.map.addLayer(
          this.plotLayer(
            layerObj,
            layerObj.id + 2,
            textStyle.paint,
            textStyle.layout,
            'symbol'
          )
        );
      }
      this.setFitToBound(true);

    } else if (
      layerObj.type &&
      (layerObj.type == 'polyline' || layerObj.type == 'linestring' || layerObj.type == 'multilinestring')
    ) {
      const polyLineObj = this.getPolylineStylesObj(layerObj);
      const textStyleForPolyline = this.getTextStylesForLayer(layerObj);
      if (layerObj.layer_type !== 3) {

        this.labelLayers['labelLayer' + layerObj.id] = this.map.addLayer(
          this.plotLayer(
            layerObj,
            layerObj.id,
            polyLineObj.paint,
            polyLineObj.layout,
            'line'
          )
        );
      } else {
        if (layerObj.style_type === 1) {

          const polylineObj = this.getDataCollectedPolylineFixedStyle(layerObj);
          this.labelLayers['labelLayer' + layerObj._id] = this.map.addLayer(
            this.plotLayer(
              layerObj,
              layerObj.id,
              polylineObj.paint,
              polylineObj.layout,
              'line'

            )
          )

        } else if (layerObj.style_type === 2) {
          const polylineObj = this.getDataCollectedPolylineCategoryStyle(layerObj);
          this.labelLayers['labelLayer' + layerObj.id] = this.map.addLayer(
            this.plotLayer(
              layerObj,
              layerObj.id,
              polylineObj.paint,
              polylineObj.layout,
              'line'
            )
          )
        }

      }

      if ((layerObj.fixed_field_label && layerObj.style_type === 1) ||
        (layerObj.category_label && layerObj.style_type === 2) ||
        (layerObj.graduated_label && layerObj.style_type === 3)
      ) {
        if (this.map.getLayer(layerObj.id + 1)) {
          // this.map.removeLayer(layerObj.id + 1);
        }
        this.labelLayers['labelLayer' + layerObj.id + 1] = this.map.addLayer(
          this.plotLayer(
            layerObj,
            layerObj.id + 1,
            textStyleForPolyline.paint,
            textStyleForPolyline.layout,
            'symbol'
          )
        );
      }
      this.setFitToBound(true);
    }
  }

  plotLayer(layerObj, uniqueID, paintObj, layoutObj, type) {
    this.plotLayersCalled = true;
    // let layer_id = layerObj.parent_layer_id + '_no_prop';
    let layer_id = layerObj.parent_layer_id;
    // if (this.getCurrentMapZoom() >= this.geoJsonTHLevel) {
    //   layer_id = layerObj.parent_layer_id;
    // } else {
    //   if ((layerObj.fixed_field_label && layerObj.style_type === 1) ||
    //     (layerObj.category_label && layerObj.style_type === 2 && layerObj.layer_type !== 3 && layerObj.layer_type !== 4) ||
    //     ((this.selectedLayersInTools.indexOf(layerObj.parent_layer_id) >= 0) && this.toolsActive && (layerObj.style_type === 1))
    //   ) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.style_type === 3 && layerObj.layer_type === 1) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.style_type === 1 && (layerObj.layer_type === 3 || layerObj.layer_type === 4)) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.style_type === 2 && layerObj.layer_type === 3) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.layer_type === 6) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.layer_type === 1 && layerObj.style_type === 4) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.layer_type === 1 && layerObj.style_type === 5) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.layer_type === 1 && layerObj.style_type === 7) {
    //     layer_id = layerObj.parent_layer_id;
    //   } else if (layerObj.layer_type === 1 && layerObj.style_type === 6 && layerObj.dotted_display_label) {
    //     layer_id = layerObj.parent_layer_id;
    //   }
    // }

    const source = layerObj.layer_type === 6 ? layerObj.source_layer : `${layer_id}`;
    let data = layerObj.filter_query && layerObj.filter_query.length ? {
      id: uniqueID,
      type: type,
      source,//: `${layer_id}`,
      filter: layerObj.filter_query,
      paint: paintObj,
      layout: layoutObj,
      maxzoom: layerObj.max_zoom,
      minzoom: layerObj.min_zoom
    } : {
        id: uniqueID,
        type: type,
        source,//: `${layer_id}`,
        paint: paintObj,
        layout: layoutObj,
        maxzoom: layerObj.max_zoom,
        minzoom: layerObj.min_zoom
      };
    // if ((((this.getCurrentMapZoom() < this.geoJsonTHLevel) && ([1, 5].includes(layerObj.layer_type))) || (layerObj.layer_type === 6)) &&
    //   (layerObj.layer_type !== 3 &&
    //     layerObj.layer_type !== 4) &&
    //     (layerObj.style_type !== 7) &&
    //   !(layerObj.layer_type === 1 && (this.selectedLayersInTools.indexOf(layerObj.parent_layer_id) >= 0) && this.toolsActive && ([1, 4, 5, 6, 7].includes(layerObj.style_type)))) {
    //   data['source-layer'] = source;//`${layer_id}`;
    // }
    if (
      ((this.getCurrentMapZoom() < this.geoJsonTHLevel) &&
        (layerObj.layer_type === 1) &&
        !((this.selectedLayersInTools.indexOf(layerObj.parent_layer_id) >= 0) && this.toolsActive)) ||
      (layerObj.layer_type === 6)
    ) { data['source-layer'] = source; }
    return data;
  }

  removeLayerById(layerId, permanentLayerRemove) {
    // return false;
    if (this.labelLayers['labelLayer' + layerId]) {
      // this.map.removeLayer(this.labelLayers['labelLayer' + layerId]);
      if (this.map.getLayer(layerId)) {

        this.map.removeLayer(layerId);
      }
      delete this.labelLayers['labelLayer' + layerId];
      // L.DomUtil.remove('labelLayer' + layerId);
    }
    if (this.labelLayers['labelLayer' + layerId + 1]) {
      // this.map.removeLayer(this.labelLayers['labelLayer' + layerId + 1]);
      this.map.removeLayer(layerId + 1);
      delete this.labelLayers['labelLayer' + layerId + 1];
      // L.DomUtil.remove('labelLayer' + layerId + 1);
    }
    if (this.labelLayers['labelLayer' + layerId + 2]) {

      // this.map.removeLayer(this.labelLayers['labelLayer' + layerId + 1]);
      this.map.removeLayer(layerId + 2);
      delete this.labelLayers['labelLayer' + layerId + 2];
      // L.DomUtil.remove('labelLayer' + layerId + 1);
    }
    if (this.loadedLayerData && this.loadedLayerData[layerId]) {
      delete this.loadedLayerData[layerId];
    }

    if (permanentLayerRemove) {
      this.sharedService.activeLegends.splice(
        this.sharedService.activeLegends.findIndex(i => {
          return i.layerId === layerId;
        }),
        1
      );
    }
  }
  removeDataCollectedLayer(layerObj) {
    // return false;
    // let lineArrayIds = [`${layerObj.id + 'approvedLineDashArrayDashCustom'}`, `${layerObj.id + 'approvedLineDashArrayDottedCustom'}`, `${layerObj.id + 'approvedLineDashArraySolidCustom'}, ${layerObj.id + 'unApprovedLineDashArrayDashCustom'}`, `${layerObj.id + 'unApprovedLineDashArrayDottedCusom'}`, `${layerObj.id + 'unApprovedLineDashArraySolidCustom'}`];
    if (this.map.getLayer(layerObj.id)) {
      this.removeLayerById(layerObj.id, true);
    }
    let allLayers = this.map.getStyle().layers.map(({ id }) => id);
    allLayers.forEach((layerid) => {
      if (layerid == (layerObj.id)) {
        this.map.removeLayer(layerid);
      }
    })
    // this.map.removeSource(layerObj.parent_layer_id)
  }

  removeSourceById(layerId) {

    if (!this.isSwipeEnable && this.map.getSource(layerId)) {
      this.map.removeSource(layerId);
    }
    
    // if (this.map.getSource(layerId + '_no_prop')) {
    //   this.map.removeSource(layerId + '_no_prop');
    // }
  }
  removeSourceForLayer(layerObj) {
    let layer_id = layerObj.parent_layer_id;
    if (layerObj.layer_type === 6) layer_id = layerObj.source_layer;
    this.map.getStyle().layers.forEach(layer => {
      // if((layer.source === layer_id) || (layer.source === layer_id + '_no_prop')) this.removeLayerById(layer.id, false);
      if (layer.source === layer_id) this.removeLayerById(layer.id, false);
    });
    this.removeSourceById(layer_id);
  }
  removeAllOSMLayers() {
    const sources = Object.keys(this.map.getStyle().sources);
    const layers = this.map.getStyle().layers;
    sources.forEach(source => {
      const sourceLayers = Object.keys(this.sessionService.osmTranserveSources);
      if (sourceLayers.length && sourceLayers.includes(source)) {
        const layer_id = layers.find(layer => layer.source === source).id;
        if (this.labelLayers['labelLayer' + layer_id]) {
          this.map.removeLayer(layer_id);
          delete this.labelLayers['labelLayer' + layer_id];
        }
        if (this.labelLayers['labelLayer' + layer_id + 1]) {
          this.map.removeLayer(layer_id + 1);
          delete this.labelLayers['labelLayer' + layer_id + 1];
        }
        if (this.labelLayers['labelLayer' + layer_id + 2]) {
          this.map.removeLayer(layer_id + 2);
          delete this.labelLayers['labelLayer' + layer_id + 2];
        }
        if (this.map.getSource(source)) this.map.removeSource(source);
      };
    });
  }
  removeAllLayersAndSources() {
    if (this.isSwipeEnable) return;
    let layerIds = this.map.getStyle().layers.map(layer => layer.id);

    layerIds.map(layerId => {
      this.removeLayerById(layerId, true);
    })
  }
  getLayerDetailsForId = layerId => {
    const matchedResults = this.sharedService.activeLegends.find(
      item => item.layerId == layerId
    );
    return matchedResults;
  };

  getTextStylesForLayer = (layerObj, hideUnapproved?) => {
    let layoutObjValue = {};
    let textsizeArr: Array<any> = ['step', ['zoom']];
    let textTransformArr: Array<any> = ['step', ['zoom']];
    // let textFieldStateArr = ["to-string", ["get", layerObj.fixed_field_label]];
    let textFieldStateArr = this.setFieldLabel(layerObj);
    let paintObjValue = {};
    let textOpacityArr: Array<any> = ['step', ['zoom']];
    let textColorArr: Array<any> = ['step', ['zoom']];
    let textFontArr = [];
    let textJustifyArr: Array<any> = ['step', ['zoom']];
    let textLetterSpacingArr: Array<any> = ['interpolate', ['linear'], ['zoom']];
    if (this.isPolyline(layerObj)) {
      layoutObjValue["symbol-placement"] = "line-center";
      layoutObjValue["text-anchor"] = "center";
      // layoutObjValue["text-allow-overlap"] = true;
    }

    if (layerObj.style_type === 1 && layerObj.fixed_field_label !== undefined) {
      layoutObjValue['text-field'] = textFieldStateArr;
      layoutObjValue['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';
      let textStyles = layerObj.text_style;
      let iconStyles = layerObj.icon_style;
      let textFallbackStyle = textStyles.find(({ is_fall_back }) => is_fall_back);
      textFontArr = ['step', ['zoom'], ['literal', [textFallbackStyle.textFont.trim() + ' ' + textFallbackStyle.textFontWeight.trim(), 'Open Sans Regular']]];
      textJustifyArr = ['step', ['zoom'], textFallbackStyle.textAlignment];

      textStyles.forEach((objOfTextStyle, i) => {
        if (objOfTextStyle.minZoomLevel < objOfTextStyle.maxZoomLevel) {
          let textSpacingValue = +objOfTextStyle.letterSpacing / 16;
          textsizeArr.splice(2, 1, objOfTextStyle.minZoomLevel)
          textsizeArr.push(objOfTextStyle.minZoomLevel);
          textLetterSpacingArr.push(objOfTextStyle.minZoomLevel);
          textFontArr.push(objOfTextStyle.minZoomLevel);
          textJustifyArr.push(objOfTextStyle.minZoomLevel);
          if ((layerObj.layer_type != 3 || layerObj.layer_type !== 4)) {
            textsizeArr.push(+objOfTextStyle.textSize);
          } else {
            textsizeArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              12,
              +objOfTextStyle.textSize
            ]);
          }

          if (layerObj.layer_type !== 3) {
            textFontArr.push(['literal', [objOfTextStyle.textFont + ' ' + objOfTextStyle.textFontWeight.trim(), 'Open Sans Regular']]);
            textJustifyArr.push(objOfTextStyle.textAlignment);
            textLetterSpacingArr.push(textSpacingValue)
          } else {
            textFontArr.push([
              'match',
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              ['literal', [textFallbackStyle.textFont + ' ' + textFallbackStyle.textFontWeight.trim(), 'Open Sans Regular']],
              ['literal', [objOfTextStyle.textFont + ' ' + textFallbackStyle.textFontWeight.trim(), 'Open Sans Regular']]

            ])
            textLetterSpacingArr.push([
              'match',
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              +textFallbackStyle.letterSpacing / 16,
              +objOfTextStyle.letterSpacing / 16
            ])
            textJustifyArr.push([
              'match',
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              textFallbackStyle.textAlignment,
              objOfTextStyle.textAlignment
            ])

          }
          layoutObjValue['text-size'] = textsizeArr;
          layoutObjValue['text-ignore-placement'] = false;
          textTransformArr.splice(2, 1, objOfTextStyle.textTransform ? (objOfTextStyle.textTransform === 'capitalize' ? 'none' : objOfTextStyle.textTransform) : 'none');
          textTransformArr.push(objOfTextStyle.minZoomLevel);
          switch (objOfTextStyle.textTransform) {
            case 'capitalize':
              objOfTextStyle.textTransform = 'none';
          }
          if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
            textTransformArr.push(objOfTextStyle.textTransform);
          } else {
            textTransformArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              'none',
              objOfTextStyle.textTransform
            ]);
          }

          if (hideUnapproved) textOpacityArr.splice(2, 1, 0);
          else textOpacityArr.splice(2, 1, parseInt(objOfTextStyle.textOpacity));
          textOpacityArr.push(objOfTextStyle.minZoomLevel);
          if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
            textOpacityArr.push(parseFloat(objOfTextStyle.textOpacity));
          } else {
            if (hideUnapproved) {
              textOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                0,
                parseFloat(objOfTextStyle.textOpacity)
              ])
            } else {
              textOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                1,
                parseFloat(objOfTextStyle.textOpacity)
              ])
            }
          }
          textColorArr.splice(2, 1, objOfTextStyle.textColor);
          textColorArr.push(objOfTextStyle.minZoomLevel);
          if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
            textColorArr.push(objOfTextStyle.textColor);
          } else {
            textColorArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              "#000000",
              objOfTextStyle.textColor
            ])
          }

          if (this.isPolyline(layerObj)) {
            const lineWidth = iconStyles[i].iconSize;
            const offsetY = parseFloat(this.sessionService.toDecimalString(+((((lineWidth / 2) * (1 / 16)) + (1))), 1));
            layoutObjValue['text-offset'] = [offsetY, -offsetY]

          }

          layoutObjValue['text-transform'] = textTransformArr;
          if (layerObj.fixed_field_label) {

            layoutObjValue['text-justify'] = textJustifyArr;
            layoutObjValue['text-letter-spacing'] = textLetterSpacingArr;
          }
          paintObjValue['text-opacity'] = textOpacityArr;
          paintObjValue['text-color'] = textColorArr;
          layoutObjValue['text-font'] = textFontArr;
        }
      });
    } else if (layerObj.style_type === 2 && layerObj.category_label && layerObj.show_text_style && layerObj.category_field_label) {
      layoutObjValue['text-anchor'] = 'left';
      layoutObjValue['text-field'] = this.setFieldLabel(layerObj);
      layoutObjValue['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';


      const styledata = layerObj.category_text_style.filter(style => style.categoryKey === layerObj.category_label && !style.is_fall_back);
      let textStyles: Array<any> = styledata && styledata.length ? _.uniqBy(styledata, 'categoryValue') : [];
      const fallbackStyle = layerObj.category_text_style.find(obj => obj.is_fall_back);
      const fallbackColor = fallbackStyle ? fallbackStyle.textColor : '#DCDCDC';
      const fallbackOpacity = fallbackStyle ? parseFloat(fallbackStyle.textOpacity) : 1;
      const fallbackTransformation = fallbackStyle ? (fallbackStyle.textTransform === 'capitalize' ? 'none' : fallbackStyle.textTransform) : 'none';
      const fallbackSize = fallbackStyle ? +fallbackStyle.textSize : 10;
      let activeColors = [];
      let activeOpacity = [];
      let activeTransformation = [];
      let activeSize = [];
      let inactiveValues = [];
      let colorStructure: Array<any> = [];
      let opacityStructure: Array<any> = [];
      let transformStructure: Array<any> = [];
      let sizeStructure: Array<any> = [];
      let activeFonts: Array<any> = [];
      let activeJustify: Array<any> = [];
      let activeLetterSpacing: Array<any> = [];
      let categoryKey;
      textColorArr.push(fallbackColor);
      textOpacityArr.push(fallbackOpacity);
      textTransformArr.push(fallbackTransformation);
      textsizeArr.push(fallbackSize);
      textJustifyArr = ['step', ['zoom'], fallbackStyle.textAlignment];
      textFontArr = ['step', ['zoom'], ['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight.trim(), 'Open Sans Regular']]];
      textLetterSpacingArr = ['interpolate', ['linear'], ['zoom']];
      let fontStructure: Array<any> = [];
      let justifyStructure: Array<any> = [];
      let letterSpacingStructure: Array<any> = [];
      for (let zoom_value = 1; zoom_value <= 22; zoom_value++) {
        if (textStyles.length) {
          categoryKey = textStyles[0].categoryKey;
          inactiveValues = textStyles.filter((style: any) => (style.maxZoomLevel <= zoom_value)).map((obj: any) => (obj.categoryValue + ''));
          const activeConditions = textStyles.filter((style: any) => ((style.minZoomLevel <= zoom_value) && (style.maxZoomLevel > zoom_value)));
          activeConditions.forEach((value: any) => {
            activeColors.push([`${value.categoryValue}`], value.textColor);
            activeOpacity.push([`${value.categoryValue}`], parseFloat(value.textOpacity));
            activeTransformation.push([`${value.categoryValue}`], value.textTransform);
            activeSize.push([`${value.categoryValue}`], value.textSize);
            if (layerObj.layer_type == 3) {
              activeFonts.push([`${value.categoryValue}`], [
                'match',
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                ['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight.trim(), 'Open Sans Regular']],
                ['literal', [value.textFont + ' ' + value.textFontWeight.trim(), 'Open Sans Reqular']]
              ])
              activeLetterSpacing.push([`${value.categoryValue}`], [
                'match',
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                +fallbackStyle.letterSpacing / 16,
                +value.letterSpacing / 16
              ])
              activeJustify.push([`${value.categoryValue}`], [
                'match',
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                +fallbackStyle.textAlignment || 'left',
                value.textAlignment
              ])
            } else {
              activeFonts.push([`${value.categoryValue}`], ['literal', [value.textFont + ' ' + value.textFontWeight.trim(), 'Open Sans Regular']]);
              activeLetterSpacing.push([`${value.categoryValue}`], +value.letterSpacing / 16);
              activeJustify.push([`${value.categoryValue}`], value.textAlignment)
            }
          })
          if (activeColors.length) {
            colorStructure.push("match", ["to-string", ['get', categoryKey]], ...activeColors);
            if (inactiveValues.length) {
              colorStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor]);
            } else {
              colorStructure.push(fallbackColor);
            }
          } else if (inactiveValues.length) {
            colorStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor);
          } else {
            colorStructure.push(fallbackColor);
          }

          if (activeOpacity.length) {
            opacityStructure.push("match", ["to-string", ['get', categoryKey]], ...activeOpacity);
            if (inactiveValues.length) {
              opacityStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackOpacity]);
            } else {
              opacityStructure.push(fallbackOpacity);
            }
          } else if (inactiveValues.length) {
            opacityStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackOpacity);
          } else {
            opacityStructure.push(fallbackOpacity);
          }

          if (activeTransformation.length) {
            transformStructure.push("match", ["to-string", ['get', categoryKey]], ...activeTransformation);
            if (inactiveValues.length) {
              transformStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "lowercase", fallbackTransformation]);
            } else {
              transformStructure.push(fallbackTransformation);
            }
          } else if (inactiveValues.length) {
            transformStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "lowercase", fallbackTransformation);
          } else {
            transformStructure.push(fallbackTransformation);
          }

          if (activeSize.length) {
            sizeStructure.push("match", ["to-string", ['get', categoryKey]], ...activeSize);
            if (inactiveValues.length) {
              sizeStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackSize]);
            } else {
              sizeStructure.push(fallbackSize);
            }
          } else if (inactiveValues.length) {
            sizeStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackSize);
          } else {
            sizeStructure.push(fallbackSize);
          }

          if (activeFonts.length) {
            fontStructure.push('match', ["to-string", ['get', categoryKey]], ...activeFonts);
            if (inactiveValues.length) {
              fontStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], ['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight, 'Open Sans Regular']], ['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight, 'Open Sans Regular']]])
            } else {
              fontStructure.push(['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight.trim(), 'Open Sans Regular']])
            }

          } else if (inactiveValues.length) {
            fontStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], ['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight, 'Open Sans Regular']], ['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight, 'Open Sans Regular']]])

          } else {
            fontStructure.push(['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight.trim(), 'Open Sans Regular']])
          }
          if (activeJustify.length) {
            justifyStructure.push('match', ["to-string", ['get', categoryKey]], ...activeJustify);
            if (inactiveValues.length) {
              justifyStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackStyle.textAlignment, fallbackStyle.textAlignment])
            } else {
              justifyStructure.push(fallbackStyle.textAlignment)

            }
          } else if (inactiveValues.length) {
            justifyStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackStyle.textAlignment, fallbackStyle.textAlignment])
          } else {
            justifyStructure.push(fallbackStyle.textAlignment)
          }
          if (activeLetterSpacing.length) {
            letterSpacingStructure.push('match', ["to-string", ['get', categoryKey]], ...activeLetterSpacing);
            if (inactiveValues.length) {
              letterSpacingStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackStyle.letterSpacing, fallbackStyle.letterSpacing])
            } else {
              letterSpacingStructure.push(+fallbackStyle.letterSpacing / 16);
            }
          } else if (inactiveValues.length) {
            letterSpacingStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackStyle.letterSpacing, fallbackStyle.letterSpacing])
          } else {
            letterSpacingStructure.push(+fallbackStyle.letterSpacing / 16);
          }
        }


        textFontArr.push(zoom_value);
        if (!fontStructure.length) {
          textFontArr.push(['literal', [fallbackStyle.textFont + ' ' + fallbackStyle.textFontWeight.trim(), 'Open Sans Regular']])
        } else if (fontStructure.length > 1) {
          textFontArr.push([...fontStructure])
        } else {
          textFontArr.push(...fontStructure)
        }


        textJustifyArr.push(zoom_value);
        if (!justifyStructure.length) {
          textJustifyArr.push(fallbackStyle.textAlignment);

        } else if (justifyStructure.length > 1) {
          textJustifyArr.push([...justifyStructure])
        } else {
          textJustifyArr.push(...justifyStructure)
        }

        textLetterSpacingArr.push(zoom_value);
        if (!letterSpacingStructure.length) {
          textLetterSpacingArr.push(+fallbackStyle.letterSpacing / 16);
        } else if (letterSpacingStructure.length > 1) {
          textLetterSpacingArr.push([...letterSpacingStructure])
        } else {
          textLetterSpacingArr.push(...letterSpacingStructure);
        }



        textColorArr.push(zoom_value);
        if (!colorStructure.length) {
          textColorArr.push(fallbackColor);
        } else if (colorStructure.length > 1) {
          textColorArr.push([...colorStructure])
        } else {
          textColorArr.push(...colorStructure)
        }

        textOpacityArr.push(zoom_value);
        if (!opacityStructure.length) {
          textOpacityArr.push(fallbackOpacity);
        } else if (opacityStructure.length > 1) {
          textOpacityArr.push([...opacityStructure])
        } else {
          textOpacityArr.push(...opacityStructure)
        }

        textTransformArr.push(zoom_value);
        if (!transformStructure.length) {
          textTransformArr.push(fallbackTransformation);
        } else if (transformStructure.length > 1) {
          textTransformArr.push([...transformStructure])
        } else {
          textTransformArr.push(...transformStructure)
        }

        textsizeArr.push(zoom_value);
        if (!sizeStructure.length) {
          textsizeArr.push(fallbackSize);
        } else if (sizeStructure.length > 1) {
          textsizeArr.push([...sizeStructure])
        } else {
          textsizeArr.push(...sizeStructure)
        }

        colorStructure = [];
        opacityStructure = [];
        transformStructure = [];
        sizeStructure = [];
        activeColors = [];
        activeOpacity = [];
        activeTransformation = [];
        activeSize = [];
        inactiveValues = [];
        activeFonts = [];
        activeJustify = [];
        activeLetterSpacing = [];
        letterSpacingStructure = [];
        justifyStructure = [];
        fontStructure = [];
      }
      paintObjValue['text-color'] = textColorArr;
      paintObjValue['text-opacity'] = textOpacityArr;
      layoutObjValue['text-transform'] = textTransformArr;
      layoutObjValue['text-size'] = textsizeArr;
      layoutObjValue['text-font'] = textFontArr;
      layoutObjValue['text-letter-spacing'] = textLetterSpacingArr;
      if (layerObj.category_label) {

        layoutObjValue['text-justify'] = textJustifyArr
      }
    } else if (layerObj.style_type === 3 && layerObj.graduated_label && layerObj.show_text_style && layerObj.classification_method) {
      let textStyle = layerObj.graduated_text_style[0];
      layoutObjValue['text-anchor'] = 'left';
      layoutObjValue['text-field'] = this.setFieldLabel(layerObj);
      layoutObjValue['text-font'] = ['literal', [textStyle.textFont + ' ' + textStyle.textFontWeight, "Open Sans Regular"]];
      layoutObjValue['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';
      layoutObjValue['text-size'] = textStyle.textSize || 10;
      layoutObjValue['text-ignore-placement'] = false;
      layoutObjValue['text-transform'] = (textStyle.textTransform === 'capitalize') ? 'none' : textStyle.textTransform;
      layoutObjValue['text-justify'] = textStyle.textAlignment || 'left';
      let classes = this.getClasses(layerObj);
      if (classes.length) {
        paintObjValue['text-opacity'] = (textStyle.textOpacity && layerObj.show_text_style) ? parseFloat(textStyle.textOpacity) : 0;
      } else {
        paintObjValue['text-opacity'] = 0
      }
      paintObjValue['text-color'] = textStyle.textColor || '#000000';
    } else {
      layoutObjValue['visibility'] = 'none';
    }
    return { paint: {} = paintObjValue, layout: {} = layoutObjValue };
  };

  getIconStyleObj = layerobj => {
    let paintObj = {};
    let fillColorArr = ['interpolate', ['exponential', 1.01], ['zoom']];
    // let fillTranslateAnchorArr = ['step', ['zoom'], 'map', 22, 'map'];
    let fillTranslateArr = [0, 0];
    let fillOpacityArr: any = ['interpolate', ['linear'], ['zoom']];
    let fillOutlineColorArr = ['interpolate', ['linear'], ['zoom']];
    let iconStyle = layerobj.icon_style.filter(obj => (obj.type === 1));

    iconStyle.forEach(obj => {
      if (obj.minZoomLevel < obj.maxZoomLevel) {

        fillColorArr.push(obj.minZoomLevel);
        if (layerobj.layer_type !== 3 && layerobj.layer_type !== 4) {
          fillColorArr.push(obj.iconColor);
        } else {
          fillColorArr.push([
            "match",
            ["number", ["get", "is_approved"]],
            UNAPPROVED_STATUS,
            "#ff0000",
            obj.iconColor
          ])
        }
        paintObj['fill-color'] = fillColorArr;
        // paintObj['fill-translate-anchor'] = fillTranslateAnchorArr;
        paintObj['fill-translate'] = fillTranslateArr;
        fillOpacityArr.push(obj.minZoomLevel);
        if (layerobj.layer_type !== 3 && layerobj.layer_type !== 4) {
          fillOpacityArr.push(parseFloat(obj.opacity));
        } else {
          fillOpacityArr.push([
            "match",
            ["number", ["get", "is_approved"]],
            UNAPPROVED_STATUS,
            1,
            parseFloat(obj.opacity)
          ])
        }
        paintObj['fill-opacity'] = fillOpacityArr;
        fillOutlineColorArr.push(obj.minZoomLevel);
        if (layerobj.layer_type !== 3 && layerobj.layer_type !== 4) {
          fillOutlineColorArr.push(obj.borderColor);
        } else {
          fillOutlineColorArr.push([
            "match",
            ["number", ["get", "is_approved"]],
            UNAPPROVED_STATUS,
            "#000000",
            obj.borderColor
          ])
        }
      }
    });
    return { paint: {} = paintObj };
  };

  getPolygonFillStylesObj = (layerobj, hideUnapproved?: boolean) => {
    let paintObj = {};
    let layoutObj = { visibility: 'visible' };
    let fillColorArr: any = [];
    // let fillOutlineColorArr: any = [];
    let fillOpacityArr: any = [];
    let fillPattern = null;
    layoutObj['visibility'] = layerobj.show && !layerobj.isSwipeLayer? 'visible' : 'none';
    if ((layerobj.style_type === 1) || (layerobj.layer_type === 4)) {
      fillColorArr = ['interpolate', ['linear'], ['zoom']];
      fillOpacityArr = ['interpolate', ['linear'], ['zoom']];
      // fillOutlineColorArr = ['interpolate', ['linear'], ['zoom']];
      let iconStyle = [];
      let unApprovedIconObj: any = {};
      iconStyle = layerobj.icon_style;
      if (layerobj.layer_type === 3) {
        iconStyle = layerobj.icon_style.filter(({ is_approved }) => is_approved);
        unApprovedIconObj = layerobj.icon_style.find(({ is_approved }) => !is_approved);
      }
      iconStyle.forEach(obj => {
        if (obj.minZoomLevel === obj.maxZoomLevel) return
        if (obj.minZoomLevel < obj.maxZoomLevel) {
          fillColorArr.push(obj.minZoomLevel);
          if (layerobj.layer_type !== 3) {
            fillColorArr.push(obj.iconColor);
          } else {
            fillColorArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              (unApprovedIconObj.iconColor) ? unApprovedIconObj.iconColor : "#ff0000",
              obj.iconColor
            ])
          }
          paintObj['fill-color'] = fillColorArr;
          fillOpacityArr.push(obj.minZoomLevel);
          if (layerobj.layer_type !== 3) {
            fillOpacityArr.push(parseFloat(obj.opacity));
          } else {
            if (hideUnapproved) {
              fillOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                0,
                parseFloat(obj.opacity)
              ])
            } else {
              fillOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                (unApprovedIconObj.opacity) ? parseFloat(unApprovedIconObj.opacity) : 1,
                parseFloat(obj.opacity)
              ])
            }
          }
          paintObj['fill-opacity'] = fillOpacityArr;
        }
      })

    } else if ((layerobj.style_type === 2) && (layerobj.layer_type !== 4)) {

      let activeValues = [];
      let activeOpacities = [];
      // let activeOutlines = [];
      let inactiveValues = [];
      let categoryKey;
      let styleStructure: Array<any> = [];
      let opacityStructure: Array<any> = [];
      let outlineStructure: Array<any> = [];
      let iconStyle = layerobj.category_icon_style.filter(obj => ((obj.categoryKey === layerobj.category_label) && (!obj.is_fall_back)));
      const fallbackStyle = layerobj.category_icon_style.find(obj => obj.is_fall_back);
      const fallbackColor = fallbackStyle ? fallbackStyle.iconColor : '#DCDCDC';
      const fallbackOpacity = fallbackStyle ? parseFloat(fallbackStyle.opacity) : 1;
      // const fallbackOutlineColor = fallbackStyle ? fallbackStyle.borderColor : '#000000';

      const fallbackApprovedIconStyle = layerobj.category_icon_style.filter((obj) => obj.is_approved && obj.is_fall_back)[0] || {}
      const fallbackUnApprovedIconStyle = layerobj.category_icon_style.filter((obj) => !obj.is_approved && obj.is_fall_back)[0] || {};
      let colorMatchExpression = [
        'match',
        ['number', ['get', 'is_approved']],
        UNAPPROVED_STATUS,
        (layerobj.layer_type === 3) ? (hideUnapproved) ? 'transparent' : fallbackUnApprovedIconStyle.iconColor || '#ff0000' : '#ff0000',
        (layerobj.layer_type === 3) ? fallbackApprovedIconStyle.iconColor || '#000000' : '#000000'

      ]
      let opacityMatchExpression = [
        'match',
        ['number', ['get', 'is_approved']],
        UNAPPROVED_STATUS,
        (layerobj.layer_type === 3) ? (hideUnapproved) ? 0 : parseFloat(fallbackUnApprovedIconStyle.opacity || 1) : 1,
        (layerobj.layer_type === 3) ? parseFloat(fallbackApprovedIconStyle.opacity || 1) : 1
      ]
      let borderColorMatchExpression = [
        'match',
        ['number', ['get', 'is_approved']],
        UNAPPROVED_STATUS,
        (layerobj.layer_type == 3) ? (hideUnapproved) ? 'transparent' : fallbackUnApprovedIconStyle.borderColor || '#ff0000' : '#ff0000',
        (layerobj.layer_type === 3) ? fallbackApprovedIconStyle.borderColor || '#000000' : '#000000'
      ]
      if (!(fillColorArr && fillColorArr.length > 0)) {
        if (layerobj.layer_type !== 3) {

          fillColorArr = ['step', ['zoom'], fallbackColor];
        } else {
          fillColorArr = ['step', ['zoom'], [
            'match',
            ['number', ['get', 'is_approved']],
            UNAPPROVED_STATUS,
            fallbackUnApprovedIconStyle.iconColor || '#ff0000',
            fallbackApprovedIconStyle.iconColor || '#000000'
          ]]
        }
      }
      if (!(fillOpacityArr && fillOpacityArr.length > 0)) {
        if (layerobj.layer_type !== 3) {

          fillOpacityArr = ['step', ['zoom'], fallbackOpacity];
        } else {
          fillOpacityArr = ['step', ['zoom'], ['match',
            ['number', ['get', 'is_approved']],
            UNAPPROVED_STATUS,
            parseFloat(fallbackUnApprovedIconStyle.opacity || 1),
            parseFloat(fallbackApprovedIconStyle.opacity || 1)
          ]]
        }
      }
      for (let zoom_value = 1; zoom_value <= 22; zoom_value++) {
        if (iconStyle.length) {
          categoryKey = iconStyle[0].categoryKey
          inactiveValues = iconStyle.filter(style => (style.maxZoomLevel <= zoom_value || style.minZoomLevel > zoom_value)).map(obj => (obj.categoryValue + ''));
          const activeConditions = iconStyle.filter(style => ((style.minZoomLevel <= zoom_value) && (style.maxZoomLevel > zoom_value)));
          activeConditions.map(value => {
            if (layerobj.layer_type !== 3) {
              activeValues.push([`${value.categoryValue}`], value.iconColor);
              activeOpacities.push([`${value.categoryValue}`], parseFloat(value.opacity));
              // activeOutlines.push([value.categoryValue], value.borderColor);
            } else {
              activeValues.push([`${value.categoryValue}`], [
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                fallbackUnApprovedIconStyle.iconColor || '#ff0000',
                value.iconColor || '#000000'
              ])
              activeOpacities.push([`${value.categoryValue}`], [
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                (hideUnapproved) ? 0 : parseFloat(fallbackUnApprovedIconStyle.opacity) || 1,
                parseFloat(value.opacity)
              ])

            }
          })
          if (activeValues.length) {
            styleStructure.push("match", ["to-string", ['get', categoryKey]], ...activeValues);
            if (inactiveValues.length) {
              if (layerobj.layer_type !== 3) {

                styleStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor]);
              } else {
                styleStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], 'transparent', [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  fallbackUnApprovedIconStyle.iconColor || '#ff0000',
                  fallbackApprovedIconStyle.iconColor || '#000000'
                ]])
              }
            } else {
              if (layerobj.layer_type !== 3) {

                styleStructure.push(fallbackColor);
              } else {
                styleStructure.push(
                  ['match',
                    ['number', ['get', 'is_approved']],
                    UNAPPROVED_STATUS,
                    fallbackUnApprovedIconStyle.iconColor || '#ff0000',
                    fallbackApprovedIconStyle.iconColor || '#000000'
                  ]
                )
              }
            }
          } else if (inactiveValues.length) {
            if (layerobj.layer_type !== 3) {
              styleStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor);

            } else {
              styleStructure.push('match', ["to-string", ['get', categoryKey]], [...inactiveValues], 'transparent',
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (layerobj.layer_type === 3) ? fallbackUnApprovedIconStyle.iconColor || '#ff0000' : '#ff0000',
                  (layerobj.layer_type === 3) ? fallbackApprovedIconStyle.iconColor || '#000000' : '#000000'

                ]
              )
            }
          } else {
            (layerobj.layer_type !== 3) ? styleStructure.push(fallbackColor) : styleStructure.push([
              'match',
              ['number', ['get', 'is_approved']],
              UNAPPROVED_STATUS,
              (layerobj.layer_type === 3) ? fallbackUnApprovedIconStyle.iconColor || '#ff0000' : '#ff0000',
              (layerobj.layer_type === 3) ? fallbackApprovedIconStyle.iconColor || '#000000' : '#000000'

            ]);
          }

          if (activeOpacities.length) {

            opacityStructure.push("match", ["to-string", ['get', categoryKey]], ...activeOpacities);
            if (inactiveValues.length) {
              if (layerobj.layer_type !== 3) {

                opacityStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackOpacity]);
              } else {
                opacityStructure.push(['match',
                  ["to-string", ['get', categoryKey]],
                  [...inactiveValues],
                  0,
                  opacityMatchExpression
                ])
              }
            } else {
              if (layerobj.layer_type !== 3) {

                opacityStructure.push(fallbackOpacity);
              } else {
                opacityStructure.push(opacityMatchExpression)
              }
            }
          } else if (inactiveValues.length) {
            opacityStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackOpacity);
          } else {
            opacityStructure.push(fallbackOpacity);
          }
        }
        fillColorArr.push(zoom_value);
        if (!styleStructure.length) {
          if (layerobj.layer_type !== 3) {

            fillColorArr.push(fallbackColor);
          } else {
            fillColorArr.push(colorMatchExpression)
          }
        } else if (styleStructure.length > 1) {
          fillColorArr.push(
            [...styleStructure]
          )
        } else {
          fillColorArr.push(
            ...styleStructure
          )
        }

        fillOpacityArr.push(zoom_value);
        if (!opacityStructure.length) {
          if (layerobj.layer_type !== 3) {

            fillOpacityArr.push(fallbackOpacity);
          } else {
            fillOpacityArr.push(opacityMatchExpression)
          }
        } else if (opacityStructure.length > 1) {
          fillOpacityArr.push([...opacityStructure])
        } else {
          fillOpacityArr.push(...opacityStructure)
        }

        styleStructure = [];
        opacityStructure = [];
        outlineStructure = [];
        activeValues = [];
        activeOpacities = [];
        // activeOutlines = [];
        inactiveValues = [];
        paintObj['fill-color'] = fillColorArr;
        paintObj['fill-opacity'] = fillOpacityArr;
      }
    } else if (layerobj.style_type === 3) {
      if ((layerobj.layer_type === 1 || layerobj.layer_type === 6) && layerobj.graduated_label && layerobj.classification_method) {
        if (!this.layerService.filterGraduatedLayers(layerobj)) {
          layoutObj['visibility'] = 'none';
        } else {
          // let iconStyle = layerobj.graduated_icon_style[0];
          let classes: Array<any> = [];
          classes = this.getClasses(layerobj);
          // let fillColorArray = ['interpolate', ['linear'], ['get', layerobj.graduated_label]]
          let fillColorArray = ['step', ['get', layerobj.graduated_label], classes[0].color];

          let value = layerobj.classification_style.default_feature.value;

          classes.forEach((element, Index) => {
            if (![undefined, null].includes(element.from)) {
                if(classes[Index - 1] && (classes[Index - 1].from === classes[Index - 1].to)) {
                  fillColorArray.push(+element.from + 1)
                } else {
                  fillColorArray.push(+element.from);
                }
                fillColorArray.push(element.color);
              }
          });
          if (classes.length) {
            let matchColorExpression =
              ['match', ["to-string", ["has", layerobj.graduated_label]], "true",
                [
                  'match',
                  ["to-string", ["get", layerobj.graduated_label]],
                  `${layerobj.classification_style.default_feature.value}`,
                  layerobj.classification_style.default_feature.color,
                  fillColorArray
                ], layerobj.classification_style.default_feature.color
              ];
          // if(value == null) {
          //   paintObj['fill-color'] = fillColorArray.length > 3 ? fillColorArray : '#123456';
          // } else {
          //   paintObj['fill-color'] = matchColorExpression
          // }
          paintObj['fill-color'] = matchColorExpression
          }
          paintObj['fill-opacity'] = layerobj.graduated_icon_style[0].opacity;
        }
      } else {
        layoutObj['visibility'] = 'none';
      }
    }
    if ((layerobj.style_type === 1) || (layerobj.style_type === 2)) {
      let fallbackIconStyle: any = {}
      if (layerobj.style_type === 1) {

        fallbackIconStyle = layerobj.icon_style.find(({ is_fall_back }) => is_fall_back);
      } else {
        fallbackIconStyle = layerobj.category_icon_style.find(({ is_fall_back }) => is_fall_back)
      }
      

      if (fallbackIconStyle.polygonStyle === 'solid') {
        if (this.map) {

          this.map.on('load', () => {

            if (this.map.getPaintProperty(layerobj.id, 'fill-pattern')) {

              paintObj['fill-pattern'] = fillPattern
            }

          })
        }

      } else if (fallbackIconStyle.polygonStyle === 'dotted') {
        // making line as transparent while getting DottedStyles
        let dottedStyles = this.getDottedStyles(layerobj);
        paintObj['fill-color'] = dottedStyles['fill-color'];
        paintObj['fill-pattern'] = dottedStyles['fill-pattern'];

      } else if (fallbackIconStyle.polygonStyle === 'hollow') {
        paintObj['fill-color'] = 'transparent';

        if (this.map) {

          this.map.on('load', () => {

            if (this.map.getPaintProperty(layerobj.id, 'fill-pattern')) {

              paintObj['fill-pattern'] = fillPattern
            }

          })
        }
      }

    }
    paintObj['fill-outline-color'] = 'transparent';
    return { paint: paintObj, layout: layoutObj };
  };
  getPointSymbolStylesObj = (layerObj, hideUnapproved?) => {
    let layoutObj = { visibility: 'visible', "icon-allow-overlap": true };
    let paintObj: any = {};
    layoutObj['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';
    let iconImage: string = "";
    let iconSize: number = null;
    let iconOpacity: number = null;
    let textColor: string = "";
    let textSize: number = null;
    let textTransform: string = "";
    let textJustify: string = "";
    let textOpacity: number = null;
    let textFont: any = null;
    let iconImageArr: Array<any> = [];
    let iconSizeArr: Array<any> = [];
    let iconColorArr: Array<any> = [];
    let iconOpacityArr: Array<any> = [];
    let textColorArr: Array<any> = [];
    let textFieldArr: Array<any> = [];
    let textSizeArr: Array<any> = [];
    let textOpacityArr: Array<any> = [];
    let textFontArr: Array<any> = ['step', ['zoom'], ['literal', ['Open Sans Regular', 'Open Sans Regular']]];
    let textJustifyArr: Array<any> = ['step', ['zoom'], 'center'];
    let textTransformArr: Array<any> = [];
    let textLetterSpacingArr: Array<any> = ['interpolate', ['linear'], ['zoom']];
    let approvedIconStyle = [];
    let unApprovedIconStyle: any = {};
    layoutObj['text-offset'] = [0, 1.5];
    let textOffsetArr: Array<any> = [];
    let textAnchorArr: Array<any> = [];
    layoutObj['text-anchor'] = "top";
    let classes: Array<any> = [];
    let minVal: number = null;
    let maxVal: number = null;
    if (layerObj.style_type == 2) {

      textAnchorArr = [
        'step',
        ['zoom'],
        'top'
      ]
    } else {
      textAnchorArr = [
        'step',
        ['zoom'],
        ['literal', ['top']]
      ]

    }
    if (layerObj.layer_type !== 3) {
      textOffsetArr = [
        'step',
        ['zoom'],
        ['literal', [0, 1.5]]
      ];
    } else {
      approvedIconStyle = layerObj.icon_style.filter(({ is_approved }) => is_approved) || []
      unApprovedIconStyle = layerObj.icon_style.find(({ is_approved }) => !is_approved) || {}
      textOffsetArr = [
        'step',
        ['zoom'],
        [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          ['literal', [0, ((+unApprovedIconStyle.iconSize) / 2 * (1 / 16)) + 1.5]],
          ['literal', [0, 1.5]]
        ]
      ]
    }
    if (Object.keys(this.minMaxLayerValues).includes(layerObj.id)) {
      minVal = this.minMaxLayerValues[layerObj.id].minDataValue;
      maxVal = this.minMaxLayerValues[layerObj.id].maxDataValue;
    }
    if (layerObj.style_type === 4) {
      if (!layerObj.bubble_label) {
        return {
          paint: {},
          layout: {}
        }
      }
      if (layerObj.bubble_label &&
        (!(minVal || +minVal == 0) || !(maxVal || +maxVal == 0) || (maxVal <= minVal))) {
        this.toastr.warning(`The layer '${layerObj.name}' either has only one point geometry or, the selected 'Data Field' does not have multiple unique values`)
        return {
          paint: {},
          layout: {}
        }
      }
      const iconStyle = (layerObj.heat_map_style.bubble_icon_style) ? layerObj.heat_map_style.bubble_icon_style[0] : BUBBLE_ICON_STYLE;
      const textStyle = (layerObj.heat_map_style.bubble_text_style) ? layerObj.heat_map_style.bubble_text_style[0] : BUBBLE_TEXT_STYLE;
      const maxIconSize = iconStyle.maxIconSize;
      const minIconSize = iconStyle.minIconSize;
      const noOfBreaks = iconStyle.noOfBreaks || 2;
      const radiusInterval = this.sessionService.toDecimalString(((maxIconSize - minIconSize) / noOfBreaks), 2);
      const dataInterval = this.sessionService.toDecimalString(((maxVal - minVal) / noOfBreaks), 2);
      let radiusStops: any = [];
      for (let i = 0; i <= noOfBreaks; i++) {
        radiusStops.push([minVal + (+dataInterval * i), minIconSize + (+radiusInterval * i)])
      }
      let textOffsetStops = [];
      radiusStops.forEach(ele => {
        textOffsetStops.push(ele[0], ['literal', [0, ele[1] * 0.0625 + 0.5]])
      })
      return {
        paint: {
          "circle-color": iconStyle.iconColor,
          "circle-opacity": +iconStyle.opacity,
          "circle-stroke-color": iconStyle.borderColor,
          // "circle-stroke-width": [
          //   'step', ['get', layerObj.bubble_label],
          //   0,
          //   1, 0.75
          // ],
          "circle-stroke-width": 0.75,
          // "circle-radius": {
          //   "property": layerObj.bubble_label,
          //   "stops": [...radiusStops]
          // },
          "circle-radius": [
            "interpolate", ["linear"],
            ["to-number", ['get', layerObj.bubble_label]],
            ...radiusStops.flat()
          ],
          "text-color": textStyle.textColor || '#000000',
          "text-opacity": +textStyle.textOpacity || 1,
          "icon-opacity": 0
        },
        layout: {
          "text-field": this.setFieldLabel(layerObj),
          "text-anchor": 'top',
          // "text-offset": [
          //   'step', ['get', layerObj.bubble_label],
          //   ['literal', [0, 0]],
          //   ...textOffsetStops
          // ],
          "text-offset": [
            'interpolate', ['linear'],
            ["to-number", ['get', layerObj.bubble_label]],
            ...textOffsetStops
          ],
          "text-size": +textStyle.textSize || 10,
          "text-transform": textStyle.textTransform == "capitalize" ? 'none' : textStyle.textTransform,
          "text-justify": textStyle.textAlignment,
          "icon-image": 'circle',
          "text-font": ['literal', [textStyle.textFont + ' ' + textStyle.textFontWeight.trim(), "Open Sans Regular"]],
          "text-letter-spacing": +textStyle.letterSpacing / 16,
          "visibility": (layerObj.show && !layerObj.isSwipeLayer&& layerObj.show_text_style) ? 'visible' : 'none'
        }
      }
    } else if (layerObj.style_type === 5) {
      if (!layerObj.heat_map_label) {
        return {
          paint: {},
          layout: {}
        }
      }
      if (layerObj.heat_map_label &&
        (!(minVal || +minVal == 0) || !(maxVal || +maxVal == 0) || (maxVal <= minVal))) {
        this.toastr.warning(`The layer '${layerObj.name}' either has only one point geometry or, the selected 'Data Field' does not have multiple unique values`)
        return {
          paint: {},
          layout: {}
        }
      }
      const iconStyle = layerObj.heat_map_style.heat_map_icon_style[0];
      const textStyle = layerObj.heat_map_style.heat_map_text_style[0];
      const iconColorFrom = iconStyle.iconColorFrom;
      const iconColorTo = iconStyle.iconColorTo;
      let hexColorArray = this.utilService.getGradientColors(
        iconColorFrom,
        iconColorTo,
        6
      );
      let rgbColorArray: Array<any> = []
      if (hexColorArray.length) {
        hexColorArray.forEach(color => {
          rgbColorArray.push(this.hexToRgb(color));
        });
      }
      const minZoom = +iconStyle.minZoomLevel;
      const maxZoom = +iconStyle.maxZoomLevel;
      return {
        paint: {
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ["to-number", ['get', layerObj.heat_map_label]],
            minVal, +iconStyle.minIconWeight,
            maxVal, +iconStyle.maxIconWeight,
          ],
          'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            minZoom, +iconStyle.minIconIntensity,
            maxZoom, +iconStyle.maxIconIntensity
          ],
          'heatmap-color': [
            'interpolate',
            ['linear'], ['heatmap-density'],
            0, `rgba(${rgbColorArray[0].r}, ${rgbColorArray[0].g}, ${rgbColorArray[0].b}, 0)`,
            0.2, `rgb(${rgbColorArray[1].r}, ${rgbColorArray[1].g}, ${rgbColorArray[1].b})`,
            0.4, `rgb(${rgbColorArray[2].r}, ${rgbColorArray[2].g}, ${rgbColorArray[2].b})`,
            0.6, `rgb(${rgbColorArray[3].r}, ${rgbColorArray[3].g}, ${rgbColorArray[3].b})`,
            0.8, `rgb(${rgbColorArray[4].r}, ${rgbColorArray[4].g}, ${rgbColorArray[4].b})`,
            1, `rgb(${rgbColorArray[5].r}, ${rgbColorArray[5].g}, ${rgbColorArray[5].b})`
          ],
          'heatmap-radius': [
            'interpolate',
            ['linear'], ['zoom'],
            minZoom, +iconStyle.minIconSize,
            maxZoom, +iconStyle.maxIconSize
          ],
          'heatmap-opacity': +iconStyle.opacity,
          "text-color": textStyle.textColor || '#000000',
          "text-opacity": +textStyle.textOpacity || 1,
          "icon-opacity": 0
        },
        layout: {
          "text-field": this.setFieldLabel(layerObj),
          "text-anchor": 'top',
          "text-offset": [0, 1.5],
          "text-size": +textStyle.textSize || 10,
          "text-transform": textStyle.textTransform == "capitalize" ? 'none' : textStyle.textTransform,
          "text-justify": 'center',
          "icon-image": 'circle',
          "visibility": (layerObj.show && !layerObj.isSwipeLayer&& layerObj.show_text_style) ? 'visible' : 'none',
          "text-font": ['literal', [textStyle.textFont + ' ' + textStyle.textFontWeight.trim(), "Open Sans Regular"]],
          "text-letter-spacing": +textStyle.letterSpacing / 16,
        }
      }
    } else if (layerObj.style_type === 6) {
      const iconStyle = layerObj.heat_map_style.dotted_icon_style[0];
      const textStyle = layerObj.heat_map_style.dotted_text_style[0];
      return {
        paint: {
          'text-color': textStyle.textColor || '#FF0000',
          'text-opacity': +textStyle.textOpacity || 1,
          'icon-color': iconStyle.iconColor || '#000000',
          'icon-opacity': +iconStyle.opacity || 1
        },
        layout: {
          "text-field": layerObj.dotted_display_label ? ["to-string", ["get", layerObj.dotted_display_label]] : "",
          "text-anchor": 'top',
          "text-size": +textStyle.textSize || 10,
          "text-transform": textStyle.textTransform == "capitalize" ? 'none' : textStyle.textTransform,
          "text-justify": 'center',
          "icon-image": 'circle',
          "icon-size": 0.1,
          // "icon-allow-overlap": true,
          "visibility": (layerObj.show) ? 'visible' : 'none',
          "text-font": ['literal', [textStyle.textFont + ' ' + textStyle.textFontWeight.trim(), "Open Sans Regular"]],
          "text-letter-spacing": +textStyle.letterSpacing / 16,
        }
      }
    } else if (layerObj.style_type === 7) {
      const iconStyle = layerObj.heat_map_style.clustered_icon_style[0];
      const textStyle = layerObj.heat_map_style.clustered_text_style[0];
      const iconColorFrom = iconStyle.iconColorFrom;
      const iconColorTo = iconStyle.iconColorTo;
      const maxIconSize = iconStyle.maxIconSize;
      const minIconSize = iconStyle.minIconSize;
      const clusterStops = iconStyle.clusterStops || [];
      const stopsCount = clusterStops.length;
      const radiusInterval = ((maxIconSize - minIconSize) / stopsCount);
      let colorStops: any = [];
      let radiusStops: any = [];
      if (stopsCount) {
        for (let i = 0; i < stopsCount; i++) {
          radiusStops.push([clusterStops[i], minIconSize + parseFloat((radiusInterval * (i + 1)).toFixed(2))])
        }
      }
      let colorArray = stopsCount ? (this.utilService.getGradientColors(
        iconColorFrom,
        iconColorTo,
        stopsCount + 1
      )) : [];
      if (colorArray.length) {
        for (let i = 0; i < stopsCount; i++) {
          colorStops.push([clusterStops[i], colorArray[i + 1]]);
        }
      }
      return {
        paint: {
          "circle-color": colorStops.length ? (['step',
            ['get', 'point_count'],
            colorArray[0],
            ...colorStops.flat()]) : '#51bbd6',
          "circle-opacity": +iconStyle.opacity,
          "circle-radius": radiusStops.length ? ([
            'step',
            ["get", 'point_count'],
            minIconSize,
            ...radiusStops.flat()
          ]) : 20,
          "text-color": textStyle.textColor || '#000000',
          "text-opacity": +textStyle.textOpacity || 1,
          "icon-opacity": 0
        },
        layout: {
          "text-field": '{point_count_abbreviated}',
          "text-anchor": 'center',
          "text-size": +textStyle.textSize || 10,
          "text-transform": textStyle.textTransform == "capitalize" ? 'none' : textStyle.textTransform,
          "text-justify": 'center',
          "icon-image": 'circle',
          "visibility": (layerObj.show) ? 'visible' : 'none',
          "text-font": ['literal', [textStyle.textFont + ' ' + textStyle.textFontWeight.trim(), "Open Sans Regular"]],
          "text-letter-spacing": +textStyle.letterSpacing / 16,
        }
      }
    } else {
      if ((layerObj.style_type === 1 || layerObj.layer_type === 4)) {
        let iconArr = layerObj.icon_style;
        let textStyle = layerObj.text_style;
        let unapprovedIconObj: any = {};
        if (layerObj.layer_type === 3) {
          unapprovedIconObj = layerObj.icon_style.find(({ is_approved }) => !is_approved);
          iconArr = layerObj.icon_style.filter(({ is_approved }) => is_approved);
        }
        // textFieldArr = [
        //   'to-string',
        //   ['get', layerObj.field_label || 'invalid']
        // ];
        textFieldArr = this.setFieldLabel(layerObj);
        textSizeArr = ['interpolate', ['linear'], ['zoom']];
        textTransformArr = ['step', ['zoom']];
        let textFallbackStyle = layerObj.text_style.find(({ is_fall_back }) => is_fall_back);

        textFontArr = ['step', ['zoom'], ['literal', [textFallbackStyle.textFont + ' ' + textFallbackStyle.textFontWeight, 'Open Sans Regular']]];
        textJustifyArr = ['step', ['zoom'], textFallbackStyle.textAlignment]

        // for interpolation minimum  fallback value is not required
        iconArr.forEach(obj => {
          if (obj.minZoomLevel < obj.maxZoomLevel) {
            if (!(iconImageArr && iconImageArr.length > 0)) {
              if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
                iconImageArr = ['step', ['zoom'], (obj.iconImage ? obj.iconImage.split('.')[0] : 'airport')];
              } else {
                iconImageArr = ['step', ['zoom'], [
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  unapprovedIconObj.iconImage ? unapprovedIconObj.iconImage.split('.')[0] : 'circle',
                  // "circle", //unapprovedIconArr.iconImage ? unapprovedIconArr.iconImage.split('.)[0] : 'circle'  
                  obj.iconImage ? obj.iconImage.split('.')[0] : 'circle',
                ]];
              }
            }
            iconImageArr.push(obj.minZoomLevel);
            if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
              iconImageArr.push(obj.iconImage ? obj.iconImage.split('.')[0] : 'airport');
            } else {
              iconImageArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                unapprovedIconObj.iconImage ? unapprovedIconObj.iconImage.split('.')[0] : 'circle',
                // "circle",
                obj.iconImage ? obj.iconImage.split('.')[0] : 'circle',
              ])
            }
            if (!(iconColorArr && iconColorArr.length > 0)) {
              if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
                iconColorArr = ['step', ['zoom'], obj.iconColor];
              } else {
                iconColorArr = ['step', ['zoom'], [
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  unapprovedIconObj.iconImage ? unapprovedIconObj.iconColor : '#FF0000',
                  // "#FF0000",
                  obj.iconColor,
                ]];
              }
            }
            iconColorArr.push(obj.minZoomLevel);
            if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
              iconColorArr.push(obj.iconColor);
            } else {
              iconColorArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                unapprovedIconObj.iconColor ? unapprovedIconObj.iconColor : '#FF0000',
                // "#FF0000",
                obj.iconColor,
              ]);
            }

            if (!(iconSizeArr && iconSizeArr.length > 0)) {
              iconSizeArr = ["interpolate", ["linear"], ['zoom'], 0, 5];
            }
            if (obj.minZoomLevel <= 1) {

              iconSizeArr.push(1);
            } else {
              iconSizeArr.push(obj.minZoomLevel)
            }
            if (layerObj.layer_type != 3 && layerObj.layer_type !== 4) {
              iconSizeArr.push(parseInt(obj.iconSize) / 5);
            } else {
              iconSizeArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                unapprovedIconObj.iconSize ? parseInt(unapprovedIconObj.iconSize) / 5 : 1,
                parseInt(obj.iconSize) / 5,
              ]);
            }

          }

          if (!(iconOpacityArr && iconOpacityArr.length > 0)) {
            if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
              // iconSizeArr = ['step', ['zoom'], parseInt(obj.iconSize) / 10];
              iconOpacityArr = ["step", ["zoom"], 1]
            } else {
              if (hideUnapproved) {
                iconOpacityArr = ['step', ['zoom'], [
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  0,
                  parseFloat(obj.opacity)
                ]];
              } else {
                iconOpacityArr = ['step', ['zoom'], [
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  (unapprovedIconObj.opacity) ? parseFloat(unapprovedIconObj.opacity) : 1,
                  parseFloat(obj.opacity)
                ]];
              }
            }
          }
          iconOpacityArr.push(obj.minZoomLevel);
          if (layerObj.layer_type != 3 && layerObj.layer_type !== 4) {
            iconOpacityArr.push(parseFloat(obj.opacity));
          } else {
            if (hideUnapproved) {
              iconOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                0,
                parseFloat(obj.opacity)
              ])
            } else {
              iconOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                (unapprovedIconObj.opacity) ? parseFloat(unapprovedIconObj.opacity) : 1,
                parseFloat(obj.opacity)
              ]);
            }
          }
        });
        textStyle.forEach(obj => {
          let textSpacingValue = (+obj.letterSpacing / 16);
          textSizeArr.push(obj.minZoomLevel);
          textSizeArr.push(+obj.textSize);
          textTransformArr.splice(2, 1, obj.textTransform == "capitalize" ? 'none' : obj.textTransform);
          textTransformArr.push(obj.minZoomLevel);
          textTransformArr.push(obj.textTransform == "capitalize" ? 'none' : obj.textTransform);
          if (!(textColorArr && textColorArr.length > 0)) {
            if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
              textColorArr = ['step', ['zoom'], obj.textColor];

            } else {
              textColorArr = ['step', ['zoom'], [
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                '#000000',
                obj.textColor
              ]];
            }
          }
          textFontArr.push(obj.minZoomLevel);

          textColorArr.push(obj.minZoomLevel);
          textJustifyArr.push(obj.minZoomLevel);
          textLetterSpacingArr.push(obj.minZoomLevel);

          if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
            textJustifyArr.push(obj.textAlignment);
            textLetterSpacingArr.push(textSpacingValue);
            textColorArr.push(obj.textColor);
            textFontArr.push(['literal', [obj.textFont + ' ' + obj.textFontWeight.trim(), 'Open Sans Regular']]);
          } else {
            textColorArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              '#000000',
              obj.textColor
            ]);
            textFontArr.push([
              'match',
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              ['literal', [textFallbackStyle.textFont + ' ' + textFallbackStyle.textFontWeight, 'Open Sans Regular']],
              ['literal', [obj.textFont + ' ' + obj.textFontWeight, 'Open Sans Regular']]
            ])
            textLetterSpacingArr.push([
              'match',
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              +textFallbackStyle.letterSpacing / 16,
              textSpacingValue
            ])
            textJustifyArr.push([
              'match',
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              textFallbackStyle.textAlignment,
              obj.textAlignment
            ])
          }

          if (!(textOpacityArr && textOpacityArr.length)) {
            if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
              textOpacityArr = ['step', ['zoom'], parseFloat(obj.textOpacity)];
            } else {
              if (hideUnapproved) {
                textOpacityArr = ['step', ['zoom'], [
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  0,
                  parseFloat(obj.textOpacity)
                ]];
              } else {
                textOpacityArr = ['step', ['zoom'], [
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  1,
                  parseFloat(obj.textOpacity)
                ]];
              }
            }
          }
          textOpacityArr.push(obj.minZoomLevel);
          if (layerObj.layer_type !== 3 && layerObj.layer_type !== 4) {
            textOpacityArr.push(parseFloat(obj.textOpacity));
          } else {
            if (hideUnapproved) {
              textOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                0,
                parseFloat(obj.textOpacity)
              ]);
            } else {
              textOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                1,
                parseFloat(obj.textOpacity)
              ]);
            }
          }
          textAnchorArr.push(obj.minZoomLevel, ['literal', [obj.textAnchor]])
          if (layerObj.layer_type !== 3) {

            textOffsetArr.push(obj.minZoomLevel, ['literal', [obj.textOffsetX / 16, obj.textOffsetY / 16]]);
          } else {
            textOffsetArr.push(obj.minZoomLevel, [
              'match',
              ['get', 'is_approved'],
              UNAPPROVED_STATUS,
              ['literal', [0, 1.5]],
              ['literal', [obj.textOffsetX / 16, obj.textOffsetY / 16]]
            ]);
          }
        })


      } else if ((layerObj.style_type === 2) && layerObj.category_label && layerObj.layer_type !== 4) {
        let iconStyle = layerObj.category_icon_style.filter(obj => ((obj.categoryKey === layerObj.category_label) && (!obj.is_fall_back)));
        let textStyle = layerObj.category_text_style.filter(obj => ((obj.categoryKey === layerObj.category_label) && (!obj.is_fall_back)));
        let activeIcons = [];
        // let activeTextOffsetArr = [];
        let activeIconSizes = [];
        let activeIconColors = [];
        let activeIconOpacities = [];
        let inactiveValues = [];
        let activeTextColors = [];
        let activeTextOpacities = [];
        let activeTextTransformations = [];
        let activeTextSizes = [];
        let iconImageStructure: Array<any> = [];
        // let textOffsetStructure: any[] = []
        let iconSizeStructure: Array<any> = [];
        let iconColorStructure: Array<any> = [];
        let iconOpacityStructure: Array<any> = [];
        let textColorStructure: Array<any> = [];
        let textOpacityStructure: Array<any> = [];
        let textTransformStructure: Array<any> = [];
        let textSizeStructure: Array<any> = [];
        const fallbackStyle = layerObj.category_icon_style.find(obj => obj.is_fall_back);
        const fallbackTextStyle = layerObj.category_text_style.find(obj => obj.is_fall_back);
        const fallbackIcon = fallbackStyle ? fallbackStyle.iconImage.split('.')[0] : 'circle';
        const fallbackIconSize = fallbackStyle ? parseInt(fallbackStyle.iconSize) / 5 : 1;
        const fallbackIconColor = fallbackStyle ? fallbackStyle.iconColor : '#000000';
        const fallbackIconOpacity = fallbackStyle ? parseFloat(fallbackStyle.opacity) : 1;
        const fallbackTextColor = fallbackTextStyle ? fallbackTextStyle.textColor : '#000000';
        const fallbackTextSize = fallbackTextStyle ? +fallbackTextStyle.textSize : 10;
        const fallbackTextTransform = fallbackTextStyle ? (fallbackTextStyle.textTransform === 'capitalize' ? 'none' : fallbackTextStyle.textTransform) : 'none';
        const fallbackTextOpacity = fallbackTextStyle ? +fallbackTextStyle.textOpacity : 1;
        let fallbackApprovedIconStyle = layerObj.category_icon_style.filter(({ is_approved }) => is_approved).filter(({ is_fall_back }) => is_fall_back)[0] || {};
        // let fallbackUnapTextStyle =layerObj.category_text_style.filter((obj) => !obj.is_approved)[0]
        let matFallbackIconColor = [];
        let matchIconOpacity = [];
        let fallbackUnApprovedIconStyle = layerObj.category_icon_style.filter(({ is_approved }) => !is_approved)[0] || {};
        let activeFonts: Array<any> = [];
        let activeJustify: Array<any> = [];
        let activeLetterSpacing: Array<any> = [];
        let fontStructure: Array<any> = [];
        let justifyStructure: Array<any> = [];
        let letterSpacingStructure: Array<any> = [];
        let textAnchorStructure: Array<any> = [];
        let textOffsetStructure: Array<any> = []

        let categoryKey = [];
        let activeTextOffsets: Array<any> = [];
        let activeTextAnchors: Array<any> = []
        if (layerObj.layer_type !== 3) {
          textOffsetArr = [
            'step',
            ['zoom'],
            ['literal', [0, 1.5]],
          ]
        } else {
          textOffsetArr = [
            'step',
            ['zoom'],
            [
              'match',
              ['number', ['get', 'is_approved']],
              UNAPPROVED_STATUS,
              ['literal', [0, 1.5]],
              ['literal', [0, 1.5]]
            ]
          ]
        }

        if (!(iconImageArr && iconImageArr.length > 0)) {
          if (layerObj.layer_type !== 3) {

            iconImageArr = ['step', ['zoom'], fallbackIcon];
          } else {
            iconImageArr = ['step', ['zoom'],
              [
                'match',
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                (fallbackUnApprovedIconStyle.iconImage || '').split('.')[0] || 'circle',
                (fallbackApprovedIconStyle.iconImage || '').split('.')[0] || 'circle'
              ]]
          }
        }
        if (!(iconSizeArr && iconSizeArr.length > 0)) {
          iconSizeArr = ["interpolate", ['linear'], ['zoom']];

        }
        if (!(iconColorArr && iconColorArr.length > 0)) {
          if (layerObj.layer_type !== 3) {

            iconColorArr = ['step', ['zoom'], fallbackIconColor];
          } else {
            iconColorArr = ['step', ['zoom'], [
              'match',
              ['number', ['get', 'is_approved']],
              UNAPPROVED_STATUS,
              fallbackUnApprovedIconStyle.iconColor || '#ff0000',
              fallbackApprovedIconStyle.iconColor || '#000000'
            ]]
          }
        }
        if (!(iconOpacityArr && iconOpacityArr.length)) {
          if (layerObj.layer_type !== 3) {

            iconOpacityArr = ['step', ['zoom'], fallbackIconOpacity];
          } else {
            iconOpacityArr = ['step', ['zoom'], [
              'match',
              ['number', ['get', 'is_approved']],
              UNAPPROVED_STATUS,
              parseFloat(fallbackUnApprovedIconStyle.opacity) || 1,
              parseFloat(fallbackApprovedIconStyle.opacity) || 1
            ]]
          }
        }
        if (!(textColorArr && textColorArr.length > 0)) {

          textColorArr = ['step', ['zoom'], fallbackTextColor];

        }
        if (!(textSizeArr && textSizeArr.length)) {

          textSizeArr = ['step', ['zoom'], fallbackTextSize];

        }
        if (!(textTransformArr && textTransformArr.length)) {

          textTransformArr = ['step', ['zoom'], fallbackTextTransform];

        }
        if (!(textOpacityArr && textOpacityArr.length)) {

          textOpacityArr = ['step', ['zoom'], fallbackTextOpacity];


        }
        textFieldArr = this.setFieldLabel(layerObj);
        for (let zoom_value = 1; zoom_value <= 22; zoom_value++) {
          if (iconStyle.length) {
            categoryKey = iconStyle[0].categoryKey
            inactiveValues = iconStyle.filter(style => (style.maxZoomLevel < zoom_value || style.minZoomLevel > zoom_value)).map(obj => (obj.categoryValue + ''));
            const activeConditions = iconStyle.filter(style => ((style.minZoomLevel <= zoom_value) && (style.maxZoomLevel >= zoom_value)));
            if (layerObj.layer_type !== 3) {
              activeConditions.map(value => {
                activeIcons.push([`${value.categoryValue}`], value.iconImage.split('.')[0]);
                activeIconSizes.push([`${value.categoryValue}`], parseInt(value.iconSize) / 5);
                activeIconColors.push([`${value.categoryValue}`], value.iconColor);
                activeIconOpacities.push([`${value.categoryValue}`], parseFloat(value.opacity));
              })
            } else {
              activeConditions.map(value => {
                activeIcons.push([`${value.categoryValue}`], [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  fallbackUnApprovedIconStyle.iconImage.split('.')[0],
                  value.iconImage.split('.')[0]
                ]);
                activeIconSizes.push([`${value.categoryValue}`], [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  parseInt(fallbackUnApprovedIconStyle.iconSize) / 5 || 1,
                  parseInt(value.iconSize) / 5 || 1
                ]);
                activeIconColors.push([`${value.categoryValue}`], [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  fallbackUnApprovedIconStyle.iconColor || '#ff0000',
                  value.iconColor || '#000000'
                ])
                activeIconOpacities.push([`${value.categoryValue}`], [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  parseFloat(fallbackUnApprovedIconStyle.opacity) || 1,
                  parseFloat(value.opacity) || 1
                ])
              })

            }
            if (activeIcons.length) {
              iconImageStructure.push("match", ["to-string", ['get', categoryKey]], ...activeIcons);
              if (inactiveValues.length) {
                iconImageStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "circle", fallbackIcon]);
              } else {
                if (layerObj.layer_type !== 3) {

                  iconImageStructure.push(fallbackIcon);
                } else {
                  iconImageStructure.push(
                    [
                      'match',
                      ["number", ["get", "is_approved"]],
                      UNAPPROVED_STATUS,
                      (fallbackUnApprovedIconStyle.iconImage || '').split('.')[0],
                      (fallbackApprovedIconStyle.iconImage || '').split('.')[0]
                    ]
                  )
                }
              }
            } else if (inactiveValues.length) {
              if (layerObj.layer_type !== 3) {

                iconImageStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "circle", fallbackIcon);
              } else {
                iconImageStructure.push(
                  'match',
                  ["to-string", ['get', categoryKey]],
                  [...inactiveValues],
                  'circle',
                  [
                    'match',
                    ["number", ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    (fallbackUnApprovedIconStyle.iconImage || '').split('.')[0] || 'circle',
                    (fallbackApprovedIconStyle.iconImage || '').split('.')[0] || 'circle'
                  ]
                )
              }
            } else {
              if (layerObj.layer_type !== 3) {

                iconImageStructure.push(fallbackIcon);
              } else {
                iconImageStructure.push(
                  [
                    'match',
                    ["number", ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    fallbackUnApprovedIconStyle.iconImage.split('.')[0] || 'circle',
                    fallbackApprovedIconStyle.iconImage.split('.')[0] || 'circle'
                  ]
                )
              }
            }
            if (activeIconSizes.length) {
              iconSizeStructure.push("match", ["to-string", ['get', categoryKey]], ...activeIconSizes);
              if (inactiveValues.length) {
                if (layerObj.layer_type !== 3) {

                  iconSizeStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 1, fallbackIconSize]);
                } else {
                  iconImageStructure.push([
                    'match',
                    ["number", ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    [...inactiveValues],
                    1,
                    [
                      'match',
                      ["number", ["get", "is_approved"]],
                      UNAPPROVED_STATUS,
                      +(fallbackUnApprovedIconStyle.iconSize || 1) / 5,
                      +(fallbackApprovedIconStyle.iconSize || 1) / 5
                    ]
                  ])
                }
              } else {
                if (layerObj.layer_type !== 3) {

                  iconSizeStructure.push(fallbackIconSize);
                } else {
                  iconSizeStructure.push([
                    'match',
                    ['number', ['get', 'is_approved']],
                    UNAPPROVED_STATUS,
                    +(fallbackUnApprovedIconStyle.iconSize || 1) / 5,
                    +(fallbackApprovedIconStyle.iconSize || 1) / 5
                  ])
                }
              }
            } else if (inactiveValues.length) {
              if (layerObj.layer_type !== 3) {

                iconSizeStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 1, fallbackIconSize);
              } else {
                iconSizeStructure.push('match',
                  ["to-string", ['get', categoryKey]],
                  [...inactiveValues],
                  1,
                  [
                    'match',
                    ["number", ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    +(fallbackUnApprovedIconStyle.iconSize || 1) / 5,
                    +(fallbackApprovedIconStyle.iconSize || 1) / 5
                  ]
                )
              }
            } else {
              if (layerObj.layer_type !== 3) {

                iconSizeStructure.push(fallbackIconSize);
              } else {
                iconSizeStructure.push(
                  [
                    'match',
                    ["number", ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    +(fallbackUnApprovedIconStyle.iconSize || 1) / 5,
                    +(fallbackApprovedIconStyle.iconSize || 1) / 5
                  ]
                )
              }
            }
            if (layerObj.layer_type === 3) {

              matFallbackIconColor = [
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                fallbackUnApprovedIconStyle.iconColor || '#000000',
                fallbackApprovedIconStyle.iconColor || '#ff0000'
              ]
            }

            if (activeIconColors.length) {
              iconColorStructure.push("match", ["to-string", ['get', categoryKey]], ...activeIconColors);
              if (inactiveValues.length) {
                iconColorStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 'transparent', (layerObj.layer_type !== 3) ? fallbackIconColor : matFallbackIconColor]);
              } else {
                if (layerObj.layer_type !== 3) {

                  iconColorStructure.push(fallbackIconColor);
                } else {
                  iconColorStructure.push(matFallbackIconColor)
                }
              }
            } else if (inactiveValues.length) {
              iconColorStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 'transparent', (layerObj.layer_type !== 3) ? fallbackIconColor : matFallbackIconColor);
            } else {
              if (layerObj.layer_type !== 3) {

                iconColorStructure.push(fallbackIconColor);
              } else {
                iconColorStructure.push(matFallbackIconColor);
              }
            }
            if (layerObj.layer_type === 3) {

              matchIconOpacity = [
                'match',
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                +(fallbackUnApprovedIconStyle.opacity || 1) > 1 ? +(fallbackUnApprovedIconStyle.opacity || 1) / 100 : +(fallbackUnApprovedIconStyle.opacity || 1),
                +(fallbackApprovedIconStyle.opacity || 1) > 1 ? +(fallbackApprovedIconStyle.opacity || 1) / 100 : +(fallbackApprovedIconStyle.opacity || 1)
              ]
            }
            if (activeIconOpacities.length) {
              iconOpacityStructure.push("match", ["to-string", ['get', categoryKey]], ...activeIconOpacities);
              if (inactiveValues.length) {
                iconOpacityStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, (layerObj.layer_type !== 3) ? fallbackIconOpacity : matchIconOpacity]);
              } else {
                if (layerObj.layer_type !== 3) {

                  iconOpacityStructure.push(fallbackIconOpacity);
                } else {
                  iconOpacityStructure.push(matchIconOpacity)
                }
              }
            } else if (inactiveValues.length) {
              iconOpacityStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, (layerObj.layer_type !== 3) ? fallbackIconOpacity : matchIconOpacity);
            } else {
              if (layerObj.layer_type !== 3) {

                iconOpacityStructure.push(fallbackIconOpacity);
              } else {
                iconOpacityStructure.push(matchIconOpacity)
              }
            }
          }
          if (textStyle.length) {
            categoryKey = textStyle[0].categoryKey;
            inactiveValues = textStyle.filter(style => (style.maxZoomLevel <= zoom_value || style.minZoomLevel > zoom_value)).map(obj => obj.categoryValue + '');
            const activeConditions = textStyle.filter(style => ((style.minZoomLevel <= zoom_value) && (style.maxZoomLevel > zoom_value)));
            activeConditions.map(value => {
              activeTextColors.push([`${value.categoryValue}`], value.textColor);
              activeTextSizes.push([`${value.categoryValue}`], +value.textSize);
              activeTextTransformations.push([`${value.categoryValue}`], value.textTransform === 'capitalize' ? 'none' : value.textTransform);
              activeTextOpacities.push([`${value.categoryValue}`], parseFloat(value.textOpacity));
              if (layerObj.layer_type == 3) {
                activeFonts.push([`${value.categoryValue}`], [
                  'match',
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  ['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight, 'Open Sans Regular']],
                  ['literal', [value.textFont + ' ' + value.textFontWeight, 'Open Sans Reqular']]
                ])
                activeLetterSpacing.push([`${value.categoryValue}`], [
                  'match',
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  +fallbackTextStyle.letterSpacing / 16,
                  +value.letterSpacing / 16
                ])
                activeJustify.push([`${value.categoryValue}`], [
                  'match',
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  fallbackTextStyle.textAlignment || 'left',
                  value.textAlignment
                ])
                activeTextOffsets.push([`${value.categoryValue}`], [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  ['literal', [0, 1.5]],
                  ['literal', [accurateTextOffsetValues[value.textAnchor]['textOffsetX'] * value.textOffsetX / 16, accurateTextOffsetValues[value.textAnchor]['textOffsetY'] * value.textOffsetY / 16]]
                ]);
                activeTextAnchors.push([`${value.categoryValue}`], [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  'top',
                  value.textAnchor || 'top'
                ])
              } else {
                activeFonts.push([`${value.categoryValue}`], ['literal', [value.textFont + ' ' + value.textFontWeight.trim(), 'Open Sans Regular']]);
                activeLetterSpacing.push([`${value.categoryValue}`], +value.letterSpacing / 16);
                activeJustify.push([`${value.categoryValue}`], value.textAlignment);
                activeTextOffsets.push([`${value.categoryValue}`], ['literal', [accurateTextOffsetValues[value.textAnchor]['textOffsetX'] * value.textOffsetX / 16, accurateTextOffsetValues[value.textAnchor]['textOffsetY'] * value.textOffsetY / 16]]);
                activeTextAnchors.push([`${value.categoryValue}`], value.textAnchor || 'top')
              }
            })

            if (activeTextColors.length) {
              textColorStructure.push("match", ["to-string", ['get', categoryKey]], ...activeTextColors);
              if (inactiveValues.length) {
                textColorStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackTextColor]);
              } else {
                textColorStructure.push(fallbackTextColor);
              }
            } else if (inactiveValues.length) {
              textColorStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackTextColor);
            } else {
              textColorStructure.push(fallbackTextColor);
            }

            if (activeTextOpacities.length) {
              textOpacityStructure.push("match", ["to-string", ['get', categoryKey]], ...activeTextOpacities);
              if (inactiveValues.length) {
                textOpacityStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackTextOpacity]);
              } else {
                textOpacityStructure.push(fallbackTextOpacity);
              }
            } else if (inactiveValues.length) {
              textOpacityStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackTextOpacity);
            } else {
              textOpacityStructure.push(fallbackTextOpacity);
            }

            if (activeTextTransformations.length) {
              textTransformStructure.push("match", ["to-string", ['get', categoryKey]], ...activeTextTransformations);
              if (inactiveValues.length) {
                textTransformStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "lowercase", fallbackTextTransform]);
              } else {
                textTransformStructure.push(fallbackTextTransform);
              }
            } else if (inactiveValues.length) {
              textTransformStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "lowercase", fallbackTextTransform);
            } else {
              textTransformStructure.push(fallbackTextTransform);
            }

            if (activeTextSizes.length) {
              textSizeStructure.push("match", ["to-string", ['get', categoryKey]], ...activeTextSizes);
              if (inactiveValues.length) {
                textSizeStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 10, fallbackTextSize]);
              } else {
                textSizeStructure.push(fallbackTextSize);
              }
            } else if (inactiveValues.length) {
              textSizeStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 10, fallbackTextSize);
            } else {
              textSizeStructure.push(fallbackTextSize);
            }


            if (activeFonts.length) {
              fontStructure.push('match', ["to-string", ['get', categoryKey]], ...activeFonts);
              if (inactiveValues.length) {
                fontStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], ['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight, 'Open Sans Regular']], ['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight, 'Open Sans Regular']]])
              } else {
                fontStructure.push(['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight.trim(), 'Open Sans Regular']])
              }

            } else if (inactiveValues.length) {
              fontStructure.push('match', ["to-string", ['get', categoryKey]], [...inactiveValues], ['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight, 'Open Sans Regular']], ['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight, 'Open Sans Regular']])

            } else {
              fontStructure.push(['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight.trim(), 'Open Sans Regular']])
            }


            if (activeJustify.length) {
              justifyStructure.push('match', ["to-string", ['get', categoryKey]], ...activeJustify);
              if (inactiveValues.length) {
                justifyStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackTextStyle.textAlignment, fallbackTextStyle.textAlignment])
              } else {
                justifyStructure.push(fallbackTextStyle.textAlignment);
              }
            } else if (inactiveValues.length) {
              justifyStructure.push('match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackTextStyle.textAlignment, fallbackTextStyle.textAlignment)
            } else {
              justifyStructure.push(fallbackTextStyle.textAlignment)
            }

            if (activeLetterSpacing.length) {
              letterSpacingStructure.push('match', ["to-string", ['get', categoryKey]], ...activeLetterSpacing);
              if (inactiveValues.length) {
                letterSpacingStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackTextStyle.letterSpacing, fallbackTextStyle.letterSpacing])
              } else {
                letterSpacingStructure.push(+fallbackTextStyle.letterSpacing / 16);
              }
            } else if (inactiveValues.length) {
              letterSpacingStructure.push('match', ["to-string", ['get', categoryKey]], [...inactiveValues], fallbackTextStyle.letterSpacing, fallbackTextStyle.letterSpacing)
            } else {
              letterSpacingStructure.push(+fallbackTextStyle.letterSpacing / 16);
            }
            // for text-anchor
            if (activeTextAnchors.length) {
              textAnchorStructure.push('match', ['to-string', ['get', categoryKey]], ...activeTextAnchors);
              if (inactiveValues.length) {
                textAnchorStructure.push(['match', ['to-string', ['get', categoryKey]], [...inactiveValues], fallbackTextStyle.textAnchor, fallbackTextStyle.textAnchor])
              } else {
                textAnchorStructure.push(fallbackTextStyle.textAnchor);
              }
            } else if (inactiveValues.length) {
              textAnchorStructure.push('match', ['to-string', ['get', categoryKey]], [...inactiveValues], fallbackTextStyle.textAnchor, fallbackTextStyle.textAnchor)

            } else {
              textAnchorStructure.push(fallbackTextStyle.textAnchor);
            }

            // for text-offset
            if (activeTextOffsets.length) {
              textOffsetStructure.push('match', ['to-string', ['get', categoryKey]], ...activeTextOffsets);
              if (inactiveValues.length) {
                textOffsetStructure.push(['match', ['to-string', ['get', categoryKey]], [...inactiveValues],
                  ['literal', [accurateTextOffsetValues[fallbackTextStyle.textAnchor]['textOffsetX'] * fallbackTextStyle.textOffsetX / 16, accurateTextOffsetValues[fallbackTextStyle.textAnchor]['textOffsetY'] * fallbackTextStyle.textOffsetY / 16]],
                  ['literal', [accurateTextOffsetValues[fallbackTextStyle.textAnchor]['textOffsetX'] * fallbackTextStyle.textOffsetX / 16, accurateTextOffsetValues[fallbackTextStyle.textAnchor]['textOffsetY'] * fallbackTextStyle.textOffsetY / 16]]
                ])
              } else {
                textOffsetStructure.push(['literal', [fallbackTextStyle.textOffsetX / 16, fallbackTextStyle.textOffsetY / 16]]);
              }

            } else if (inactiveValues.length) {
              textOffsetStructure.push('match', ['to-string', ['get', categoryKey]], [...inactiveValues],
                ['literal', [fallbackTextStyle.textOffsetX / 16, fallbackTextStyle.textOffsetY / 16]],
                ['literal', [fallbackTextStyle.textOffsetX / 16, fallbackTextStyle.textOffsetY / 16]]
              )
            } else {
              textOffsetStructure.push(['literal', [fallbackTextStyle.textOffsetX / 16, fallbackTextStyle.textOffsetY / 16]]);
            }
          }
          iconImageArr.push(zoom_value);
          if (!iconImageStructure.length) {
            if (layerObj.layer_type !== 3) {

              iconImageArr.push(fallbackIcon);
            } else {
              iconImageArr.push(
                [
                  "match",
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (fallbackUnApprovedIconStyle.iconImage || '').split('.')[0] || 'circle',
                  (fallbackApprovedIconStyle.iconImage || '').split('.')[0] || 'circle'
                ]
              )
            }
          } else if (iconImageStructure.length > 1) {
            iconImageArr.push(
              [...iconImageStructure]
            )
          } else {
            iconImageArr.push(
              ...iconImageStructure
            )
          }

          iconSizeArr.push(zoom_value);
          if (!iconSizeStructure.length) {
            if (layerObj.layer_type !== 3) {

              iconSizeArr.push(fallbackIconSize);
            } else {
              iconSizeArr.push(
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  parseInt(fallbackUnApprovedIconStyle.iconSize || 1) / 5 || 1,
                  parseInt(fallbackApprovedIconStyle.iconSize || 1) / 5 || 1
                ]
              )
            }
          } else if (iconSizeStructure.length > 1) {
            iconSizeArr.push([...iconSizeStructure])
          } else {
            iconSizeArr.push(...iconSizeStructure)
          }

          iconColorArr.push(zoom_value);
          if (!iconColorStructure.length) {
            if (layerObj.layer_type !== 3) {

              iconColorArr.push(fallbackIconColor);
            } else {
              iconColorArr.push([
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                fallbackUnApprovedIconStyle.iconColor || '#000000',
                fallbackApprovedIconStyle.iconColor || '#ff0000'
              ])
            }
          } else if (iconColorStructure.length > 1) {
            iconColorArr.push([...iconColorStructure])
          } else {
            iconColorArr.push(...iconColorStructure)
          }


          iconOpacityArr.push(zoom_value);
          if (!iconOpacityStructure.length) {
            if (layerObj.layer_type !== 3) {

              iconOpacityArr.push(fallbackIconOpacity);
            } else {
              iconOpacityArr.push([
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                parseFloat(fallbackUnApprovedIconStyle.opacity || 1) || 1,
                parseFloat(fallbackApprovedIconStyle.opacity || 1) || 1
              ])
            }
          } else if (iconOpacityStructure.length > 1) {
            iconOpacityArr.push([...iconOpacityStructure])
          } else {
            iconOpacityArr.push(...iconOpacityStructure)
          }

          textColorArr.push(zoom_value);
          if (!textColorStructure.length) {
            textColorArr.push(fallbackTextColor);
          } else if (textColorStructure.length > 1) {
            textColorArr.push([...textColorStructure]);
          } else {
            textColorArr.push(...textColorStructure);
          }

          textOpacityArr.push(zoom_value);
          if (!textOpacityStructure.length) {
            textOpacityArr.push(fallbackTextOpacity);
          } else if (textOpacityStructure.length > 1) {
            textOpacityArr.push([...textOpacityStructure]);
          } else {
            textOpacityArr.push(...textOpacityStructure);
          }

          textTransformArr.push(zoom_value);
          if (!textTransformStructure.length) {
            textTransformArr.push(fallbackTextTransform);
          } else if (textTransformStructure.length > 1) {
            textTransformArr.push([...textTransformStructure]);
          } else {
            textTransformArr.push(...textTransformStructure);
          }

          textSizeArr.push(zoom_value);
          if (!textSizeStructure.length) {
            textSizeArr.push(fallbackTextSize);
          } else if (textSizeStructure.length > 1) {
            textSizeArr.push([...textSizeStructure]);
          } else {
            textSizeArr.push(...textSizeStructure);
          }


          textFontArr.push(zoom_value);
          if (!fontStructure.length) {
            textFontArr.push(['literal', [fallbackTextStyle.textFont + ' ' + fallbackTextStyle.textFontWeight.trim(), 'Opens Sans Regular']])
          } else if (fontStructure.length > 1) {
            textFontArr.push([...fontStructure])
          } else {
            textFontArr.push(...fontStructure)
          }
          textJustifyArr.push(zoom_value);
          if (!justifyStructure.length) {
            textJustifyArr.push(fallbackTextStyle.textAlignment);

          } else if (justifyStructure.length > 1) {
            textJustifyArr.push([...justifyStructure])
          } else {
            textJustifyArr.push(...justifyStructure)
          }

          textLetterSpacingArr.push(zoom_value);
          if (!letterSpacingStructure.length) {
            textLetterSpacingArr.push(+fallbackTextStyle.letterSpacing / 16);
          } else if (letterSpacingStructure.length > 1) {
            textLetterSpacingArr.push([...letterSpacingStructure])
          } else {
            textLetterSpacingArr.push(...letterSpacingStructure);
          }
          // text-offset
          textOffsetArr.push(zoom_value);
          if (!textOffsetStructure.length) {

            textOffsetArr.push(['literal', [accurateTextOffsetValues[fallbackTextStyle.textAnchor]['textOffsetX'] * fallbackTextStyle.textOffsetX / 16, accurateTextOffsetValues[fallbackTextStyle.textAnchor]['textOffsetY'] * fallbackTextStyle.textOffsetY / 16]])
          } else if (textOffsetStructure.length > 1) {
            textOffsetArr.push([...textOffsetStructure])
          } else {
            textOffsetArr.push(...textOffsetStructure)
          }
          // text-anchor
          textAnchorArr.push(zoom_value);
          if (!textAnchorStructure.length) {
            textAnchorArr.push(fallbackTextStyle.textAnchor)
          } else if (textAnchorStructure.length > 1) {
            textAnchorArr.push([...textAnchorStructure]);
          } else {
            textAnchorArr.push(...textAnchorStructure)
          }

          iconImageStructure = [];
          iconSizeStructure = [];
          iconColorStructure = [];
          iconOpacityStructure = [];
          textColorStructure = [];
          textOpacityStructure = [];
          textTransformStructure = [];
          textSizeStructure = [];
          activeIcons = [];
          activeIconSizes = [];
          activeIconColors = [];
          activeIconOpacities = [];
          activeTextColors = [];
          activeTextOpacities = [];
          activeTextTransformations = [];
          activeTextSizes = [];
          inactiveValues = [];
          justifyStructure = [];
          letterSpacingStructure = [];
          fontStructure = [];
          activeFonts = [];
          activeJustify = [];
          activeLetterSpacing = []
          activeTextOffsets = [];
          activeTextAnchors = [];
          textOffsetStructure = [];
          textAnchorStructure = [];

        }
      } else if (layerObj.style_type === 3) {
        if ((layerObj.layer_type === 1 || layerObj.layer_type === 6) && layerObj.graduated_label && layerObj.classification_method) {
          if (!this.layerService.filterGraduatedLayers(layerObj)) {
            layoutObj['visibility'] = 'none';
          } else {
            let iconStyle = layerObj.graduated_icon_style[0];
            let textStyle = layerObj.graduated_text_style[0];
            textFieldArr = this.setFieldLabel(layerObj);
            iconImage = (iconStyle.iconImage ? iconStyle.iconImage.split('.')[0] : 'airport');
            textOffsetArr = ['literal', [textStyle.textOffsetX / 16, textStyle.textOffsetY / 16]];
            textAnchorArr = ['literal', [textStyle.textAnchor]];
            classes = this.getClasses(layerObj);
            iconColorArr = ['step', ['get', layerObj.graduated_label], classes[0].color];
          
            classes.forEach((element, i) => {
              if (![undefined, null].includes(element.from)) {
                if(classes[i - 1] && (classes[i - 1].from === classes[i - 1].to)) {
                  iconColorArr.push(+element.from + 1);
                } else {
                  iconColorArr.push(element.from);

                }
                iconColorArr.push(element.color);
              }
            })
         
            iconSize = iconStyle.iconSize ? (parseInt(iconStyle.iconSize)) / 5 : 1;
            iconOpacity = 1;

            textSize = textStyle.textSize ? textStyle.textSize : 10;
            textTransform = textStyle.textTransform == "capitalize" ? 'none' : textStyle.textTransform;
            textJustify = textStyle.textAlignment || 'left';
            textColor = textStyle.textColor || '#000000';
            textOpacity = textStyle.textOpacity ? parseFloat(textStyle.textOpacity) : 1;
            textFont = ['literal', [textStyle.textFont + ' ' + textStyle.textFontWeight, 'Opens Sans Regular']];
          }
        } else {
          layoutObj['visibility'] = 'none';
        }
      }
    }


    if (layerObj.style_type === 1 || layerObj.layer_type === 4) {
      layoutObj['text-field'] = textFieldArr.length ? textFieldArr : '';
      layoutObj['text-size'] = textSizeArr.length ? textSizeArr : 1;
      layoutObj['text-transform'] = textTransformArr.length ? textTransformArr : 'none';
      layoutObj['icon-image'] = iconImageArr.length ? iconImageArr : 'circle';
      layoutObj['icon-size'] = iconSizeArr.length ? iconSizeArr : 1;
      layoutObj['text-offset'] = textOffsetArr;
      layoutObj['text-variable-anchor'] = textAnchorArr;
      layoutObj['symbol-placement'] = 'point'


    } else if (layerObj.style_type === 2 && layerObj.category_label && layerObj.layer_type !== 4) {
      layoutObj['text-field'] = textFieldArr.length ? textFieldArr : '';
      layoutObj['text-offset'] = textOffsetArr;
      layoutObj['text-anchor'] = textAnchorArr;
      layoutObj['text-size'] = (textSizeArr.length && layerObj.show_text_style) ? textSizeArr : 0;
      layoutObj['text-transform'] = (textTransformArr.length && layerObj.show_text_style) ? textTransformArr : 'none';
      layoutObj['text-justify'] = (textJustifyArr.length && layerObj.show_text_style) ? textJustifyArr : 'left';
      layoutObj['icon-image'] = iconImageArr.length ? iconImageArr : 'circle';
      layoutObj['icon-size'] = iconSizeArr.length ? iconSizeArr : 1;
    } else if (layerObj.style_type === 2 && !layerObj.category_label && layerObj.layer_type !== 4) {
      const fallbackIconStyle = layerObj.category_icon_style.find(obj => obj.is_fall_back);
      const fallbackIcon = fallbackIconStyle ? fallbackIconStyle.iconImage.split('.')[0] : 'circle';
      const fallbackIconSize = fallbackIconStyle ? parseInt(fallbackIconStyle.iconSize) / 5 : 0.5;
      layoutObj['icon-image'] = fallbackIcon;
      layoutObj['icon-size'] = fallbackIconSize;
    } else if ((layerObj.style_type === 2) && (layerObj.layer_type === 3)) {
      const fallbackCategoryTextStyle = layerObj.category_text_style;
      const fallbackApprovedIconStyle = layerObj.category_icon_style.filter(({ is_approved }) => is_approved).filter(({ is_fall_back }) => is_fall_back)[0] || {}
      const fallbackUnApprovedIconStyle = layerObj.category_icon_style.filter(({ is_approved }) => !is_approved).filter(({ is_fall_back }) => is_fall_back)[0] || {}
      layoutObj['icon-image'] = iconImageArr.length ? iconImageArr : [
        "match",
        ['number', ["get", "is_approved"]],
        UNAPPROVED_STATUS,
        (fallbackUnApprovedIconStyle.iconImage || '').split('.')[0] || 'circle',
        (fallbackApprovedIconStyle.iconImage || '').split('.')[0] || 'circle'
      ]
      paintObj['icon-color'] = iconColorArr.length ? iconColorArr : [
        "match",
        ["number", ["get", "is_approved"]],
        UNAPPROVED_STATUS,
        fallbackUnApprovedIconStyle.iconColor || '#ff0000',
        fallbackApprovedIconStyle.iconColor || '#000000'
      ]
      layoutObj['icon-size'] = iconSizeArr.length ? iconSizeArr : [
        'match',
        ['number', ['get', "is_approved"]],
        UNAPPROVED_STATUS,
        parseInt(fallbackUnApprovedIconStyle.iconSize || 1) / 5 || 1,
        parseInt(fallbackApprovedIconStyle.iconSize || 1) / 5 || 1
      ]
      paintObj['icon-opacity'] = iconOpacityArr.length ? iconOpacityArr : [
        "match",
        ["number", ["get", "is_approved"]],
        UNAPPROVED_STATUS,
        parseFloat(fallbackUnApprovedIconStyle.opacity || 1) || 1,
        parseFloat(fallbackApprovedIconStyle.opacity || 1) || 1
      ]
      if (layerObj.show_text_style) {

        layoutObj['text-field'] = textFieldArr.length ? textFieldArr : '';
      }
      layoutObj['text-size'] = textSizeArr.length ? textSizeArr : fallbackCategoryTextStyle[0].textSize || 1
      layoutObj['text-transform'] = textTransformArr.length ? textTransformArr : fallbackCategoryTextStyle[0].textTransform || 'none';
      // layoutObj['text-justify'] = textJustifyArr.length ? textJustifyArr : fallbackCategoryTextStyle[0].textAlignment || 'left';
      paintObj['text-color'] = textColorArr.length ? textColorArr : fallbackCategoryTextStyle[0].textColor || '#000000';
      paintObj['text-opacity'] = textOpacityArr.length ? textOpacityArr : fallbackCategoryTextStyle[0].textOpacity || 1;

    } else if (layerObj.style_type === 3 && (layerObj.layer_type === 1 || layerObj.layer_type === 6)) {
      if (layerObj.show_text_style) {

        layoutObj['text-field'] = textFieldArr.length ? textFieldArr : '';
      }
      if (layerObj.graduated_label && layerObj.show_text_style) {
        layoutObj['text-size'] = textSize;
        layoutObj['text-transform'] = textTransform || 'none';
        layoutObj['text-justify'] = textJustify || 'left';
        layoutObj['icon-image'] = iconImage || 'circle';
        layoutObj['icon-size'] = iconSize || 1;
        layoutObj['symbol-placement'] = "point"
      } else {
        layoutObj['icon-image'] = iconImage || 'circle';
        layoutObj['icon-size'] = iconSize || 1;
      }
    }

    if (layerObj.style_type === 1 || layerObj.layer_type === 4) {
      paintObj['text-color'] = textColorArr.length ? textColorArr : '';
      paintObj['text-opacity'] = textOpacityArr.length ? textOpacityArr : 1;
      paintObj['icon-color'] = iconColorArr.length ? iconColorArr : '#000000',
        paintObj['icon-opacity'] = iconOpacityArr.length ? iconOpacityArr : 1;
      layoutObj['text-font'] = textFontArr;
      layoutObj['text-letter-spacing'] = textLetterSpacingArr;
      layoutObj['text-justify'] = textJustifyArr.length ? textJustifyArr : 'left';

    } else if (layerObj.style_type === 2 && layerObj.category_label && layerObj.layer_type !== 4) {
      paintObj['text-color'] = (textColorArr.length && layerObj.show_text_style) ? textColorArr : '#000000';
      paintObj['text-opacity'] = (textOpacityArr.length && layerObj.show_text_style) ? textOpacityArr : 0;
      paintObj['icon-color'] = iconColorArr.length ? iconColorArr : '#000000'
      paintObj['icon-opacity'] = iconOpacityArr.length ? iconOpacityArr : 1;
      layoutObj['text-font'] = textFontArr;
      layoutObj['text-letter-spacing'] = textLetterSpacingArr;;
      layoutObj['text-justify'] = textJustifyArr;
    } else if (layerObj.style_type === 2 && !layerObj.category_label && layerObj.layer_type !== 4) {
      const fallbackIconStyle = layerObj.category_icon_style.find(obj => obj.is_fall_back);
      const fallbackIconColor = fallbackIconStyle ? fallbackIconStyle.iconColor : '#000000';
      const fallbackIconOpacity = fallbackIconStyle ? parseFloat(fallbackIconStyle.opacity) : 1;
      paintObj['icon-color'] = fallbackIconColor,
        paintObj['icon-opacity'] = fallbackIconOpacity
    } else if (layerObj.style_type === 3 && (layerObj.layer_type === 1 || layerObj.layer_type === 6)) {
      if (layerObj.graduated_label && layerObj.show_text_style) {
        let matchColorExpression = 
        ['match', ["to-string", ["has", layerObj.graduated_label]], "true",
          [
            'match',
            ["to-string", ["get", layerObj.graduated_label]],
            `${layerObj.classification_style.default_feature.value}`,
            layerObj.classification_style.default_feature.color,
            iconColorArr
          ], layerObj.classification_style.default_feature.color
        ];
        const value = layerObj.classification_style.default_feature.value;
        if(value == null) {
          paintObj['icon-color'] = iconColorArr.length > 3 ? iconColorArr : '#000000';
        } else {
          paintObj['icon-color'] = matchColorExpression
        }
        let classes = this.getClasses(layerObj);
        if (classes.length) {
          paintObj['text-opacity'] = textOpacity;
        } else {
          paintObj['text-opacity'] = 0;
        }
        paintObj['text-color'] = textColor || '#000000';
        // paintObj['icon-color'] = iconColorArr.length ? iconColorArr : '#000000',
        paintObj['icon-opacity'] = iconOpacity || 0
        layoutObj['text-font'] = textFont;
        layoutObj['text-offset'] = textOffsetArr;
        layoutObj['text-variable-anchor'] = textAnchorArr;
      } else {
        paintObj['icon-color'] = iconColorArr.length ? iconColorArr : '#000000',
        paintObj['icon-opacity'] = iconOpacity || 0,
        paintObj['text-opacity'] = 0;
      }
    }
    return {
      paint: paintObj,
      layout: layoutObj
    };
  };

  getPolylineStylesObj = (layerObj, hideUnapproved?) => {
    let layoutObj = {};
    let paintObj = {};
    const polyLineLayer = this.isPolyline(layerObj);

    layoutObj['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';
    if (layerObj.style_type === 1) {
      let lineWidthValue: any = ['interpolate', ['linear'], ['zoom'], 0, 1];
      let lineColorValue: any = ['step', ['zoom'], '#000000'];
      let iconArr = layerObj.icon_style;
      let unApprovedObj: any = {};
      let fallBackApprovedIconStyle: any = {};
      let fallbackUnApprovedIconStyle: any = {};
      let fallbackIconStyle: any = {}
      if (layerObj.layer_type == 3) {
        let defaultApprovedStyle = {};
        let defaultUnApprovedStyle = {};
        if (['polygon', 'multipolygon'].includes(layerObj.type)) {
          defaultApprovedStyle = POLYGON_APPROVED_ICON_STYLE;
          defaultUnApprovedStyle = POLYGON_UNAPPROVED_ICON_STYLE;
        }
        iconArr = layerObj.icon_style.filter(({ is_approved }) => is_approved);
        unApprovedObj = layerObj.icon_style.find(({ is_approved }) => !is_approved);
        if (iconArr.length === 0) {
          iconArr = [defaultApprovedStyle];
        }
        if (unApprovedObj.length === 0) {
          unApprovedObj = defaultUnApprovedStyle;
        }
        fallBackApprovedIconStyle = layerObj.icon_style.find((obj) => obj.is_fall_back && obj.is_approved) || defaultApprovedStyle;
        fallbackUnApprovedIconStyle = layerObj.icon_style.find((obj) => obj.is_fall_back && !obj.is_approved) || defaultUnApprovedStyle;
        fallBackApprovedIconStyle.borderOpacity = (fallBackApprovedIconStyle.borderOpacity > 1) ? fallBackApprovedIconStyle.borderOpacity / 100 : fallBackApprovedIconStyle.borderOpacity || 1;
        fallbackUnApprovedIconStyle.borderOpacity = (fallbackUnApprovedIconStyle.borderOpacity > 1) ? fallbackUnApprovedIconStyle.borderOpacity / 100 : fallbackUnApprovedIconStyle.borderOpacity || 1;
      } else {
        fallbackIconStyle = layerObj.icon_style.find(({ is_fall_back }) => is_fall_back) || {};
      }
      let lineOpacityArr: any = ['step', ['zoom'], 1.0];
      let borderOpacityArr: any = ['step', ['zoom'], 1.0];

      if (layerObj.layer_type === 3) {
        lineOpacityArr.splice(2, 1, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          +(fallbackUnApprovedIconStyle.opacity || 1),
          +(fallBackApprovedIconStyle.opacity || 1)
        ])
        borderOpacityArr.splice(2, 1, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          parseFloat(fallbackUnApprovedIconStyle.borderOpacity || 1),
          parseFloat(fallBackApprovedIconStyle.borderOpacity || 1)
        ])
        lineColorValue.splice(2, 1, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          +fallbackUnApprovedIconStyle.iconColor || '#ff0000',
          fallBackApprovedIconStyle.iconColor || '#000000'
        ])
      } else {
        lineOpacityArr.splice(2, 1, parseFloat(fallbackIconStyle.opacity));
        borderOpacityArr.splice(2, 1, parseFloat(fallbackIconStyle.borderOpacity));
        lineColorValue.splice(2, 1, fallbackIconStyle.iconColor);

      }
      iconArr.forEach(obj => {
        if (obj.minZoomLevel < obj.maxZoomLevel) {
          obj.borderOpacity = (obj.borderOpacity > 1) ? obj.borderOpacity / 100 : obj.borderOpacity
          if ((layerObj.layer_type !== 3 && layerObj.layer_type !== 4) && ((layerObj.type !== 'polygon') && (layerObj.type !== 'multipolygon'))) {
            if (obj.minZoomLevel <= 1) {
              lineWidthValue.push(1);
              lineWidthValue.push(+(obj.iconSize));
            }
            if (obj.minZoomLevel > 1) {
              lineWidthValue.push(obj.minZoomLevel)

              lineWidthValue.push(+(obj.iconSize));
            }
            // for polyline stylings
            lineOpacityArr.push(obj.minZoomLevel);
            lineOpacityArr.push(parseFloat(obj.opacity));
            // lineWidthValue.push(+obj.iconSize)
          }
          else if ((layerObj.layer_type == 3 || layerObj.layer_type === 4) && ((layerObj.type == "polygon") || (layerObj.type === "multipolygon"))) {


            //polygon shouldn't have line opacity
            if (obj.minZoomLevel <= 1) {
              lineWidthValue.push(1);
              if (hideUnapproved) {
                //same for both approved and unapprovedcase
                if (layerObj.layer_type === 3) {
                  lineWidthValue.push([
                    "match",
                    ['number', ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    0,
                    parseInt(obj.iconSize),
                  ]);
                } else {
                  lineWidthValue.push(+obj.iconSize);
                }

              } else {
                if (layerObj.layer_type !== 3) {
                  lineWidthValue.push(+(obj.iconSize));
                } else {
                  lineWidthValue.push([
                    'match',
                    ['number', ['get', 'is_approved']],
                    UNAPPROVED_STATUS,
                    +unApprovedObj.iconSize || 1,
                    +obj.iconSize
                  ])
                }
              }
            }
            if (obj.minZoomLevel > 1) {
              lineWidthValue.push(obj.minZoomLevel)
              if (hideUnapproved) {
                //same for both approved and unapprovedcase
                if (layerObj.layer_type === 3) {

                  lineWidthValue.push([
                    "match",
                    ['number', ["get", "is_approved"]],
                    UNAPPROVED_STATUS,
                    0,
                    parseInt(obj.iconSize),
                  ]);
                } else {
                  lineWidthValue.push(+obj.iconSize)
                }
              } else {
                if (layerObj.layer_type !== 3) {

                  lineWidthValue.push(+(obj.iconSize));
                } else {
                  lineWidthValue.push([
                    'match',
                    ['number', ['get', 'is_approved']],
                    UNAPPROVED_STATUS,
                    +unApprovedObj.iconSize || 1,
                    +obj.iconSize
                  ])
                }
              }
            }
            borderOpacityArr.push(obj.minZoomLevel)
            if (hideUnapproved) {
              //same for both approved and unapprovedcase

              borderOpacityArr.push([
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                0,
                parseFloat(obj.borderOpacity)
              ])

            } else {
              if (layerObj.layer_type !== 3) {

                borderOpacityArr.push(+obj.borderOpacity)
              } else {
                borderOpacityArr.push([
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  +unApprovedObj.borderOpacity,
                  +obj.borderOpacity
                ])
              }
            }
          }
          else if ((layerObj.layer_type !== 3 && layerObj.layer_type !== 4) && ((layerObj.type == 'polygon') || (layerObj.type == "multipolygon"))) {

            if (obj.minZoomLevel <= 1) {
              lineWidthValue.push(1);
            } else {

              lineWidthValue.push(obj.minZoomLevel)
            }
            lineWidthValue.push(+obj.iconSize);
            borderOpacityArr.push(obj.minZoomLevel)
            borderOpacityArr.push(parseFloat(obj.borderOpacity))
          }
          else if ((layerObj.layer_type == 3 || layerObj.layer_type === 4) && ((layerObj.type == "polyline") || (layerObj.type === "linestring") || (layerObj.type === "multilinestring"))) {
            if (obj.minZoomLevel <= 1) {
              lineWidthValue.push(1)
            } else {

              lineWidthValue.push(obj.minZoomLevel);
            }
            lineOpacityArr.push(obj.minZoomLevel);
            if (layerObj.layer_type === 3) {

              lineWidthValue.push([
                "match",
                ['number', ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                +(unApprovedObj.iconSize),
                parseInt(obj.iconSize)
              ]);

            } else {
              lineWidthValue.push(parseInt(obj.iconSize));
            }
            if (hideUnapproved) {
              lineOpacityArr.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                0,
                parseFloat(obj.opacity)
              ])
            } else {
              if (layerObj.layer_type === 3) {

                lineOpacityArr.push([
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  (unApprovedObj.opacity) ? parseFloat(unApprovedObj.opacity) : 1,
                  parseFloat(obj.opacity)
                ])
              } else {
                lineOpacityArr.push([
                  "match",
                  ["number", ["get", "is_approved"]],
                  UNAPPROVED_STATUS,
                  1,
                  parseFloat(obj.opacity)
                ])
              }
            }
          }
          // linePatternValue.push(obj.minZoomLevel, obj.iconImage);
          lineColorValue.push(obj.minZoomLevel);
          if ((layerObj.layer_type !== 3 && layerObj.layer_type !== 4) && ((layerObj.type !== 'polygon') && (layerObj.type !== 'multipolygon'))) {
            lineColorValue.push(obj.iconColor);
          }
          else if ((layerObj.layer_type !== 3 && layerObj.layer_type !== 4) && ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon'))) {
            lineColorValue.push(obj.borderColor);
          }
          else if ((layerObj.layer_type == 3 || layerObj.layer_type === 4) && ((layerObj.type === "polyline") || (layerObj.type === "linestring") || (layerObj.type === "multilinestring"))) {
            if (layerObj.layer_type === 3) {

              lineColorValue.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                (unApprovedObj.iconColor) ? unApprovedObj.iconColor : "#FF0000",
                obj.iconColor
              ])
            } else {
              lineColorValue.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                "#FF0000",
                obj.borderColor
              ]);

            }
          }
          else {
            if (layerObj.layer_type === 3) {
              lineColorValue.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                (unApprovedObj.borderColor) ? unApprovedObj.borderColor : "#FF0000",
                obj.borderColor
              ]);
            } else {
              lineColorValue.push([
                "match",
                ["number", ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                "#FF0000",
                obj.borderColor
              ]);

            }
          }
        }
      });
      layoutObj['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';
      paintObj['line-width'] = lineWidthValue;
      paintObj['line-color'] = lineColorValue;

      if (layerObj.type == 'polyline' || layerObj.type === 'multipolyline' || layerObj.type == 'linestring' || (layerObj.type === "multilinestring")) {
        paintObj['line-opacity'] = lineOpacityArr;
        if (layerObj.layer_type === 3) {
          const approvedFallbackIconStyle = layerObj.icon_style.find((obj) => obj.is_fall_back && obj.is_approved);
          if ((approvedFallbackIconStyle.iconImage === 'dash.png') || (approvedFallbackIconStyle.iconImage === 'dash.png')) {
            paintObj['line-dasharray'] = [3, 3]

          } else if ((approvedFallbackIconStyle.iconImage === 'dotted.png') || (approvedFallbackIconStyle.iconImage === 'dottted')) {
            paintObj['line-dasharray'] = [0, 2];
            layoutObj['line-cap'] = 'round'

          } else if ((approvedFallbackIconStyle.iconImage === 'solid.png') || (approvedFallbackIconStyle.iconImage === 'solid')) {
            paintObj['line-dasharray'] = [3, 0];
          }
          paintObj['line-color'] = lineColorValue;
          layoutObj['visibility'] = (layerObj.show) ? 'visible' : 'none';
        } else {

          const fallBackStyle = layerObj.icon_style.find(style => style.is_fall_back);
          if ((fallBackStyle.iconImage === "dash.png") || (fallBackStyle.iconImage === 'dash')) {
            paintObj['line-dasharray'] = [3, 3];
          }
          else if ((fallBackStyle.iconImage === "dotted.png") || (fallBackStyle.iconImage === 'dotted')) {
            paintObj['line-dasharray'] = [0, 2];
            layoutObj['line-cap'] = 'round';
          } else if ((fallBackStyle.iconImage === 'solid.png') || (fallBackStyle.iconImage === 'solid')) {
            paintObj['line-dasharray'] = [3, 0];
            layoutObj['line-cap'] = 'butt'
          }
        }
      } else {
        paintObj['line-opacity'] = borderOpacityArr;
      }
    } else if (layerObj.style_type === 2 && layerObj.layer_type !== 4) {
      let lineWidthArr: Array<any> = [];
      let lineColorArr: Array<any> = [];
      let lineOpacityArr: Array<any> = [];
      let activeFills = [];
      let activeOpacities = [];
      let activeWidths = [];
      let inactiveValues = [];
      let categoryKey;
      let fillStructure: Array<any> = [];
      let opacityStructure: Array<any> = [];
      let WidthStructure: Array<any> = [];
      let borderOpacityArr: any = [];
      let iconStyle = layerObj.category_icon_style.filter(obj => ((obj.categoryKey === layerObj.category_label) && (!obj.is_fall_back)));
      const fallbackStyle = layerObj.category_icon_style.find(obj => obj.is_fall_back) || {};
      let fallbackColor: any = '#000000';
      let activeBorderOpacity: any = [];
      let borderOpacityStructure: any = [];
      if (polyLineLayer) {
        fallbackColor = fallbackStyle ? fallbackStyle.iconColor : '#000000';
      } else {
        fallbackColor = fallbackStyle ? fallbackStyle.borderColor : '#000000';
      }
      const fallbackOpacity = fallbackStyle ? parseFloat(fallbackStyle.opacity || 1) : 1;
      const iconImage = fallbackStyle.iconImage || 'solid.png';
      const fallbackSymbol = (fallbackStyle && iconImage) ? (iconImage.includes('.png')) ? iconImage.substr(0, iconImage.length - 4) : iconImage : 'solid';
      const fallbackDashArray = DashArray[fallbackSymbol];
      const fallbackDashCap = CapArray[fallbackSymbol];
      let fallbackWidth;
      let fallbackBorderOpacity: number;

      fallbackWidth = fallbackStyle ? +fallbackStyle.iconSize : 1;

      if (!(lineColorArr && lineColorArr.length > 0)) {
        lineColorArr = ['step', ['zoom'], fallbackColor];
      }
      if (!(lineWidthArr && lineWidthArr.length > 0)) {
        lineWidthArr = ['step', ['zoom'], fallbackWidth];
      }
      if (!(lineOpacityArr && lineOpacityArr.length > 0)) {
        lineOpacityArr = ['step', ['zoom'], fallbackOpacity];
      }
      if ((layerObj.type === 'polygon') || (layerObj.type == 'multipolygon')) {
        fallbackBorderOpacity = +(fallbackStyle.borderOpacity > 1) ? fallbackStyle.borderOpacity / 100 : +fallbackStyle.borderOpacity
        borderOpacityArr = ['step', ['zoom'], (fallbackStyle.borderOpacity > 1) ? +fallbackStyle.borderOpacity / 100 : +fallbackStyle.borderOpacity];
      }
      for (let zoom_value = 1; zoom_value <= 22; zoom_value++) {
        if (iconStyle.length) {
          categoryKey = iconStyle[0].categoryKey;
          inactiveValues = iconStyle.filter(style => (style.maxZoomLevel <= zoom_value || style.minZoomLevel > zoom_value)).map(obj => `${obj.categoryValue}`);
          const activeConditions = iconStyle.filter(style => ((style.minZoomLevel <= zoom_value) && (style.maxZoomLevel > zoom_value)));
          activeConditions.map(value => {

            if (layerObj.type === 'polygon' || layerObj.type === 'multipolygon') {
              value.borderOpacity = (+value.borderOpacity > 1) ? +value.borderOpacity / 100 : +value.borderOpacity;
              activeFills.push([`${value.categoryValue}`], value.borderColor);

              activeBorderOpacity.push([`${value.categoryValue}`], +value.borderOpacity)

            }
            else {
              activeFills.push([`${value.categoryValue}`], value.iconColor);
            }
            activeOpacities.push([`${value.categoryValue}`], parseFloat(value.opacity));
            activeWidths.push([`${value.categoryValue}`], polyLineLayer ? +value.iconSize : (+value.iconSize > 1 ? +value.iconSize : 1));

          })
          if (activeBorderOpacity.length) {
            borderOpacityStructure.push('match', ["to-string", ['get', categoryKey]], ...activeBorderOpacity)
            if (inactiveValues.length) {
              borderOpacityStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackStyle.borderOpacity || 0])

            } else {

              borderOpacityStructure.push(fallbackBorderOpacity);
            }
          } else if (inactiveValues.length) {

            borderOpacityStructure.push(['match', ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackStyle.borderOpacity || 0])
          } else {

            borderOpacityStructure.push(fallbackBorderOpacity);

          }


          if (activeFills.length) {
            fillStructure.push("match", ["to-string", ['get', categoryKey]], ...activeFills);
            if (inactiveValues.length) {
              if (polyLineLayer) {
                fillStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor]);
              } else {
                fillStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor]);
              }
            } else {
              fillStructure.push(fallbackColor);
            }
          } else if (inactiveValues.length) {
            if (polyLineLayer) {
              fillStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor);
            } else {
              fillStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], "transparent", fallbackColor);
            }
          } else {
            fillStructure.push(fallbackColor);
          }

          if (activeOpacities.length) {
            opacityStructure.push("match", ["to-string", ['get', categoryKey]], ...activeOpacities);
            if (inactiveValues.length) {
              opacityStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackOpacity]);
            } else {
              opacityStructure.push(fallbackOpacity);
            }
          } else if (inactiveValues.length) {
            opacityStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackOpacity);
          } else {
            opacityStructure.push(fallbackOpacity);
          }

          if (activeWidths.length) {
            WidthStructure.push("match", ["to-string", ['get', categoryKey]], ...activeWidths);
            if (inactiveValues.length) {
              WidthStructure.push(["match", ["to-string", ['get', categoryKey]], [...inactiveValues], 0, fallbackWidth]);
              // if (polyLineLayer) {
              // } else {
              //   WidthStructure.push(["match", ["get", categoryKey], [...inactiveValues], 1, fallbackWidth]);
              // }
            } else {
              WidthStructure.push(fallbackWidth);
            }
          } else if (inactiveValues.length) {
            WidthStructure.push("match", ["to-string", ['get', categoryKey]], [...inactiveValues], 1, fallbackWidth);
            // if (polyLineLayer) {
            // } else {
            //   WidthStructure.push("match", ["get", categoryKey], [...inactiveValues], 0, fallbackWidth);
            // }
          } else {
            WidthStructure.push(fallbackWidth);
          }
        }
        lineColorArr.push(zoom_value);
        if (!fillStructure.length) {
          lineColorArr.push(fallbackColor);
        } else if (fillStructure.length > 1) {
          lineColorArr.push(
            [...fillStructure]
          )
        } else {
          lineColorArr.push(
            ...fillStructure
          )
        }

        lineOpacityArr.push(zoom_value);
        if (!opacityStructure.length) {
          lineOpacityArr.push(fallbackOpacity);
        } else if (opacityStructure.length > 1) {
          lineOpacityArr.push([...opacityStructure])
        } else {
          lineOpacityArr.push(...opacityStructure)
        }

        lineWidthArr.push(zoom_value);
        if (!WidthStructure.length) {
          lineWidthArr.push(fallbackWidth);
        } else if (WidthStructure.length > 1) {
          lineWidthArr.push([...WidthStructure])
        } else {
          lineWidthArr.push(...WidthStructure)
        }
        borderOpacityArr.push(zoom_value);
        if (!borderOpacityStructure.length) {
          borderOpacityArr.push(+(fallbackBorderOpacity))
        } else if (borderOpacityStructure.length > 1) {
          borderOpacityArr.push([...borderOpacityStructure])
        } else {
          borderOpacityArr.push(...borderOpacityStructure)
        }

        fillStructure = [];
        opacityStructure = [];
        WidthStructure = [];
        activeFills = [];
        activeOpacities = [];
        activeWidths = [];
        inactiveValues = [];
        borderOpacityStructure = [];
        activeBorderOpacity = []
        // paintObj['line-color'] = lineColorArr;
        // paintObj['line-opacity'] = lineOpacityArr;
        // paintObj['line-width'] = lineWidthArr;
      }
      if (layerObj.layer_type !== 3) {
        paintObj['line-dasharray'] = fallbackDashArray;
        layoutObj['line-cap'] = fallbackDashCap;
      }
      paintObj['line-color'] = lineColorArr;
      if (['polyline', 'line', 'multilinestring', 'multiLineString', 'linestring'].includes(layerObj.type)) {

        paintObj['line-opacity'] = lineOpacityArr;
      } else {
        paintObj['line-opacity'] = borderOpacityArr || [];
      }
      paintObj['line-width'] = lineWidthArr;
    } else if (layerObj.style_type === 3) {
      if ((layerObj.layer_type === 1 || layerObj.layer_type === 6) && layerObj.classification_method && layerObj.graduated_label) {
        if (!this.layerService.filterGraduatedLayers(layerObj)) {
          layoutObj['visibility'] = 'none';
        } else {
          let iconStyle = layerObj.graduated_icon_style[0];
          layoutObj['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none';
          if ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) {
            paintObj['line-width'] = parseInt(iconStyle.iconSize) ? parseInt(iconStyle.iconSize) : 0;
            paintObj['line-color'] = iconStyle.borderColor || '#000000';
            paintObj['line-opacity'] = +iconStyle.borderOpacity;
          } else if ((layerObj.type == "polyline") || (layerObj.type === "linestring") || (layerObj.type === "multilinestring")) {
            paintObj['line-width'] = parseInt(iconStyle.iconSize) || 1;
            let classes = this.getClasses(layerObj);
            let lineColorArr = ['step', ['get', layerObj.graduated_label], classes[0].color]

            classes.forEach(element => {
              if (![undefined, null].includes(element.from)) {
                lineColorArr.push(element.from);
                lineColorArr.push(element.color);
              }
            })
            let matchColorExpression =
            ['match', ["to-string", ["has", layerObj.graduated_label]], "true",
              [
                'match',
                ["to-string", ["get", layerObj.graduated_label]],
                `${layerObj.classification_style.default_feature.value}`,
                layerObj.classification_style.default_feature.color,
                lineColorArr
              ], layerObj.classification_style.default_feature.color
            ];
            
            paintObj['line-color'] = matchColorExpression ;
            if ((iconStyle.iconImage === "dash.png") || (iconStyle.iconImage === 'dash')) {
              paintObj['line-dasharray'] = [3, 3];
            }
            else if ((iconStyle.iconImage === "dotted.png") || (iconStyle.iconImage === 'dotted')) {
              paintObj['line-dasharray'] = [0, 2];
              layoutObj['line-cap'] = 'round';
            }
            paintObj['line-opacity'] = +iconStyle.borderOpacity;

          }
        }
      } else {
        layoutObj['visibility'] = 'none';
      }

    }
    return { paint: paintObj, layout: layoutObj };
  };
  isPolylineNeeded(layerObj) {
    let iconArr = [];
    if (layerObj.style_type === 1) {
      iconArr = layerObj.icon_style;
    } else if (layerObj.style_type === 2) {
      iconArr = layerObj.category_icon_style;
    } else if (layerObj.style_type === 3) {
      iconArr = layerObj.graduated_icon_style;
    }
    const widthFilter = iconArr.filter(style => parseFloat(style.iconSize) > 1.0);
    if (widthFilter && widthFilter.length > 0) {
      return true;
    }
    return false;
  }



  isPolyline(layerObj) {
    if (["polyline", "multipolyline", "linestring", "multilinestring"].includes(layerObj.type)) return true;
    return false;
  }

  showHideLayer(showLayer, layer, icon) {
    let addedFeatureLayers = [`${layer._id}` + "geo-editted-polygon", `${layer._id}` + "geo-editted-line", `${layer._id}` + "geo-editted-point", `${layer._id}` + "geo-original-polygon", `${layer._id}` + "geo-original-line", `${layer._id}` + "geo-original-point"]
    const plottedLayers = [`${layer.id}`, `${layer.id}` + 1, `${layer.id}` + 2];
    plottedLayers.forEach(layerId => {
      if(icon) {
        if(this.map.getLayer(layerId)) {
          this.map.setLayoutProperty(
            layerId,
            'visibility',
            showLayer ? 'visible' : 'none'
          );
        }
      } else {
        if (this.labelLayers['labelLayer' + layerId]) {
          this.map.setLayoutProperty(
            layerId,
            'visibility',
            showLayer ? 'visible' : 'none'
          );
        }
      }
    });
    addedFeatureLayers.forEach((layerId) => {
      if (this.map.getLayer(layerId)) {
        this.map.setLayoutProperty(layerId, 'visibility', showLayer ? 'visible' : 'none')
      }

    })

  }

  renderLineMeasure() {
    let layerIds = this.map.getStyle().layers.filter((layer) => layer.id != 'wms-base-layer').map(layer => layer.id);
    if (this.map.getSource('lineMeasureSource')) {
      const measureLayers = ['graphic-points', 'graphic-polygons', 'graphic-polylines', 'measure-lines', 'measure-points', 'measure-inner-points'];
      if (this.map.getLayer('measure-lines-text')) {
        measureLayers.unshift('measure-lines-text')
      }
      measureLayers.forEach(id => {
        if (layerIds.indexOf(id) > -1) this.map.moveLayer(id);
      })
    } else if (this.map.getSource('AdvancedToolSearchResults')) {
      const measureLayers = ['graphic-points', 'graphic-polygons', 'graphic-polylines'];
      measureLayers.forEach(id => {
        if (layerIds.indexOf(id) > -1) this.map.moveLayer(id);
      })
    } else if (this.map.getSource('geometry-original')) {
      layerIds.forEach((layerId) => {
        if (layerId.indexOf('geo-original') > -1) this.map.moveLayer(layerId)
      })
      if (this.map.getSource('geometry-editted')) {
        layerIds.forEach((layerId) => {
          if (layerId.indexOf('geo-editted') > -1) this.map.moveLayer(layerId)
        })
      }
    } else if (this.map.getSource('pointMeasureSource')) {
      const pointMapLayers = ['map-points'];
      pointMapLayers.forEach(id => {
        if (this.map.getLayer(id)) {
          this.map.moveLayer(id)
        }
      })
    } else {
      layerIds.forEach((layerId) => {
        if (
          (layerId.indexOf('gl-draw-point') > -1) ||
          (layerId.indexOf('gl-draw-polygon') > -1) ||
          (layerId.indexOf('gl-draw-line') > -1) ||
          (layerId.indexOf('map-points') > -1) ||
          (layerId.indexOf('graphic-points') > -1) ||
          (layerId.indexOf('graphic-polygons') > -1) ||
          (layerId.indexOf('graphic-polylines') > -1) ||
          (layerId.indexOf('unapproved-points') > -1) ||
          (layerId.indexOf('unapproved-polygons') > -1) ||
          (layerId.indexOf('unapproved-polylines') > -1)
        ) {
          this.map.moveLayer(layerId);
        }
      })
    }
  }

  reOrderLayers(layerIds) {
    let mapLayerIds = this.map.getStyle().layers.filter((layer) => layer.id != 'wms-base-layer').map(layer => layer.id);
    if (!(layerIds && layerIds.length)) return;
    layerIds.forEach(id => {
      if (mapLayerIds.indexOf(id) > -1) this.map.moveLayer(id);
      if (mapLayerIds.indexOf(id + 1) > -1) this.map.moveLayer(id + 1);
      if (mapLayerIds.indexOf(id + 2) > -1) this.map.moveLayer(id + 2);
    });
    this.renderLineMeasure()
  }

  resetSessionLayersOrder(layersList) {
    if (layersList && layersList.length > 0) {
      const reverseOrder = Object.assign([], layersList).reverse();
      reverseOrder.forEach(layerObj => {
        // check if it is plotted
        if (this.labelLayers['labelLayer' + layerObj.id]) {
          if ((this.map.getStyle().layers.filter((layer) => layer.id == 'wms-base-layer')).length) {
            this.map.moveLayer(layerObj.id, 'wms-base-layer');
          }
          else {
            this.map.moveLayer(layerObj.id);
          }
          if (this.labelLayers['labelLayer' + layerObj.id + 1]) {
            if ((this.map.getStyle().layers.filter((layer) => layer.id == 'wms-base-layer')).length) {
              this.map.moveLayer(layerObj.id, 'wms-base-layer');
            }
            else {
              this.map.moveLayer(layerObj.id + 1);
            }
          }
          if (this.labelLayers['labelLayer' + layerObj.id + 2]) {
            if ((this.map.getStyle().layers.filter((layer) => layer.id == 'wms-base-layer')).length) {
              this.map.moveLayer(layerObj.id, 'wms-base-layer');
            }
            else {
              this.map.moveLayer(layerObj.id + 2);
            }
          }
        }
      });
      this.renderLineMeasure();
    }
  }

  showBoundBox(coord = null, layer?: any) {
    // ENABLE THIS AFTER API is READY WITH BBOX for layer.

    let finalBBox = new mapboxgl.LngLatBounds();
    if (coord) {
      if (this.advancedToolsGraphicsSelectionActive) return;
      if (Object.keys(coord).length > 0) {
        const sw = new mapboxgl.LngLat(coord.xMin, coord.yMin);
        const ne = new mapboxgl.LngLat(coord.xMax, coord.yMax);
        const newBounds = new mapboxgl.LngLatBounds(sw, ne);
        finalBBox.extend(newBounds);
      }
    } else if (layer === "specificLayer" || layer === "graphicSelected" || layer === "queryLayer") {
      let selectedLayer: any;
      if (layer === "specificLayer") {
        selectedLayer = this.sessionService.selectedLayers[0];
      } else if (layer === "queryLayer") {
        selectedLayer = this.sessionService.sessionLayers.find(layer => layer.parent_layer_id === this.sessionService.activeLayer);
      } else if (layer === "graphicSelected") {
        if (!this.sessionService.selectedGeometryLayerId) return;
        selectedLayer = this.sessionService.sessionLayers.find(layer => layer.parent_layer_id === this.sessionService.selectedGeometryLayerId);
      }
      if (Object.keys(selectedLayer).length > 0) {
        let boundingBoxForSelectedLayer = selectedLayer.bounding_box;
        if (selectedLayer.bounding_box.length) {
          const sw = new mapboxgl.LngLat(boundingBoxForSelectedLayer[0], boundingBoxForSelectedLayer[1])
          const ne = new mapboxgl.LngLat(boundingBoxForSelectedLayer[2], boundingBoxForSelectedLayer[3])
          const newBounds = new mapboxgl.LngLatBounds(sw, ne);
          finalBBox.extend(newBounds)
        } else {
          this.toastr.info('cannot find bounds')
        }

      }
    }

    else {
      this.sharedService.mapLayers.forEach((layer, i) => {
        if (!layer.show) {
          return;
        }
        if (layer.bounding_box && layer.bounding_box.length > 0) {
          const sw = new mapboxgl.LngLat(layer.bounding_box[0], layer.bounding_box[1]);
          const ne = new mapboxgl.LngLat(layer.bounding_box[2], layer.bounding_box[3]);
          const newBounds = new mapboxgl.LngLatBounds(sw, ne);
          finalBBox.extend(newBounds);
        }
      });

    }

    if (Object.keys(finalBBox).length > 0) {
      if (layer === "queryLayer") {
        this.map.fitBounds(finalBBox);
      } else if (layer !== "specificLayer") {
        this.map.fitBounds(finalBBox);
      } else {
        this.map.fitBounds(finalBBox, { padding: { 'bottom': 20, 'left': 350, 'right': 0, 'top': 20 } });
      }
      // this.map.fitBounds(finalBBox)
      // let point = this.map.getCenter();
      // point.lng = point.lng+20;
      // this.map.flyTo(point);

    }
  }

  fitFeaturesToBoundingBox(data, layerType?) {
    if (layerType) {
      let boundingBoxes = [];
      data.forEach(element => {
        if (element.geometry.bbox) {
          boundingBoxes.push(element.geometry.bbox);
        } else {
          let type = (layerType !== "multipolygon") ? layerType : "polygon";
          boundingBoxes.push(turf.bbox(this.sessionService.getBoundingBox(layerType, element.geometry.coordinates)))
        }
      });
      let finalBBox = new mapboxgl.LngLatBounds();
      boundingBoxes.forEach(bbox => {
        if (bbox && bbox.length > 0) {

          const sw = new mapboxgl.LngLat(bbox[0], bbox[1]);
          const ne = new mapboxgl.LngLat(bbox[2], bbox[3]);
          const newBounds = new mapboxgl.LngLatBounds(sw, ne);
          finalBBox.extend(newBounds);
        }
      })
      this.map.fitBounds(finalBBox);
    }
  }
  setBaseLayer() {
    if (!this.map) return;
    const baseLayer = BASE_LAYER_URLS[this.sessionService.sessionDetails.base_layer];
    const credits = this.sessionService.sessionDetails.credits;
    if (["OSM", "HERE-BASE", "HERE-AERIAL", "HERE-TRAFFIC"].includes(this.sessionService.setBaseLayer)) {
      if (baseLayer) {
        this.removeRasterSource();
        if (!this.map.getSource(BASE_MAP_SOURCES[this.sessionService.setBaseLayer])) {
          this.map.addSource(BASE_MAP_SOURCES[this.sessionService.setBaseLayer], this.getSourceProps());
        }
        let array = [];
        array = this.map.getStyle().layers.filter((layer) => layer.id == BASE_MAP_LAYERS[this.sessionService.setBaseLayer])
        if (array.length == 0) {
          this.map.addLayer({
            'id': BASE_MAP_LAYERS[this.sessionService.setBaseLayer],
            'type': 'raster',
            'source': BASE_MAP_SOURCES[this.sessionService.setBaseLayer],
            'paint': {},
            'source-layer': BASE_MAP_SOURCES[this.sessionService.setBaseLayer]
          });
        }
      }
    }
    if (["NONE"].includes(this.sessionService.setBaseLayer)) {
      this.removeRasterSource();
    }
    // this.setMapAttribution(credits);
  }

  getSourceProps() {
    // const baseLayer = this.sessionService.sessionDetails.base_layer;
    const baseLayer = BASE_MAP_TILES[this.sessionService.setBaseLayer];
    if (this.sessionService.setBaseLayer === "OSM") {
      return {
        'type': 'raster',
        'tiles': [`${baseLayer}`],
        'tileSize': 256,
        // 'attribution': ' © <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>' + ' © <a href="https://transerve.com" target="_blank">Transerve Online Stack</a>'
      }
    } else {
      return {
        'type': 'raster',
        'tiles': [`${baseLayer}`]
      }
    }
  }

  removeRasterSource() {
    const keys = Object.keys(BASE_MAP_SOURCES);
    keys.forEach(key => {
      if (this.map.getSource(BASE_MAP_SOURCES[key]) && this.sessionService.setBaseLayer !== key) {
        this.removeRasterLayers(key);
        this.map.removeSource(BASE_MAP_SOURCES[key]);
      }
    });
  }

  removeRasterLayers(flag) {
    switch (flag) {
      case "OSM":
      case "HERE-BASE":
      case "HERE-AERIAL":
      case "HERE-TRAFFIC":
        if ((this.map.getStyle().layers.filter((layer) => layer.id == BASE_MAP_LAYERS[flag])).length) {
          this.map.removeLayer(BASE_MAP_LAYERS[flag]);
        }
        break;
      case "ALL":
        if ((this.map.getStyle().layers.filter((layer) => layer.id == 'wms-base-layer')).length) {
          this.map.removeLayer("wms-base-layer");
        }
        if ((this.map.getStyle().layers.filter((layer) => layer.id == 'wms-base-layer')).length) {
          this.map.removeLayer("wms-base-layer");
        }
        if ((this.map.getStyle().layers.filter((layer) => layer.id == 'here-aerial-layer')).length) {
          this.map.removeLayer("here-aerial-layer");
        }
        if ((this.map.getStyle().layers.filter((layer) => layer.id == 'here-traffic-layer')).length) {
          this.map.removeLayer("here-traffic-layer");
        }
        break;
    }
  }

  setMapAttribution(attribution: any) {
    try {
      if (this.credits) this.map.removeControl(this.credits);
    } catch (error) {

    }
    let index = this.map.getStyle().layers.findIndex((layer) => layer.id === 'wms-base-layer');
    this.count++;
    if (this.sessionService.setBaseLayer === '') {
      this.count = 0;
    }
    // if (index === -1) {

    // if (this.sessionService.setBaseLayer === ''){
    let credits = '';
    if ((this.sessionService.setBaseLayer == "NONE") || (this.sessionService.setBaseLayer == "")) {
      credits = '© <a href="https://transerve.com" target="_blank">Transerve Online Stack</a>';
    } else {
      credits = '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>' + ' © <a href="https://transerve.com" target="_blank">Transerve Online Stack</a>'
    }
    this.credits = new mapboxgl.AttributionControl({ compact: false, customAttribution: credits });
    this.map.addControl(this.credits);
  }
  getDataCollectedPolylineFixedStyle(layerObj, hideUnapproved: boolean = false) {
    let paintObj = {};
    let layoutObj = {};
    let lineWidthValue: any = ['interpolate', ['linear'], ['zoom'], 0, 1];
    let lineColorValue: any = ['step', ['zoom'], '#000000'];
    let iconArr = layerObj.icon_style;
    let unApprovedObj: any = {};
    if (layerObj.layer_type == 3) {
      iconArr = layerObj.icon_style.filter(({ is_approved }) => is_approved)
      unApprovedObj = layerObj.icon_style.find((layer) => layer.is_approved === false)
    }
    let lineOpacityArr: any = ['step', ['zoom'], 1.0];
    if ((layerObj.layer_type == 3 || layerObj.layer_type === 4) && ((layerObj.type == "polygon") || (layerObj.type === "multipolygon"))) {
      lineOpacityArr.splice(2, 1, 0);
    }
    iconArr.forEach(obj => {
      if (obj.minZoomLevel < obj.maxZoomLevel) {
        lineOpacityArr.splice(2, 1, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          parseFloat(unApprovedObj.opacity),
          parseFloat(obj.opacity)
        ])

        lineWidthValue.splice(4, 1, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          +unApprovedObj.iconSize,
          +obj.iconSize
        ])
        lineColorValue.splice(2, 1, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          +unApprovedObj.iconColor || '#ff0000',
          obj.iconColor || '#000000'
        ])

        if (layerObj.layer_type == 3 && ((layerObj.type == "polygon") || (layerObj.type === "multipolygon"))) {

          //polygon shouldn't have line opacity
          if (obj.minZoomLevel <= 1) {
            lineWidthValue.push(1)
          } else {

            lineWidthValue.push(obj.minZoomLevel);
          }
          if (hideUnapproved) {
            lineWidthValue.push([
              "match",
              ['number', ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              0,
              parseInt(obj.iconSize),
            ]);

          } else {
            if (layerObj.layer_type !== 3) {

              lineWidthValue.push([
                "match",
                ['number', ["get", "is_approved"]],
                UNAPPROVED_STATUS,
                1,
                parseInt(obj.iconSize),
              ]);
            } else {
              lineWidthValue.push([
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                +unApprovedObj.iconSize || 1,
                +obj.iconSize
              ])
            }
          }
        } else if (layerObj.layer_type == 3 && ((layerObj.type == "polyline") || (layerObj.type === "linestring") || (layerObj.type === "multilinestring"))) {
          if (obj.minZoomLevel <= 1) {
            lineWidthValue.push(1)

          } else {
            lineWidthValue.push(obj.minZoomLevel);
          }



          lineOpacityArr.push(obj.minZoomLevel);

          lineWidthValue.push([
            "match",
            ['number', ["get", "is_approved"]],
            UNAPPROVED_STATUS,
            parseInt(unApprovedObj.iconSize),
            parseInt(obj.iconSize)
          ]);

          if (hideUnapproved) {
            lineOpacityArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              0,
              parseFloat(obj.opacity)
            ])
          } else {

            lineOpacityArr.push([
              "match",
              ["number", ["get", "is_approved"]],
              UNAPPROVED_STATUS,
              (unApprovedObj.opacity) ? parseFloat(unApprovedObj.opacity) : 1,
              parseFloat(obj.opacity)
            ])

          }
        }
        lineColorValue.push(obj.minZoomLevel);
        if ((layerObj.layer_type !== 3 && layerObj.layer_type !== 4) && ((layerObj.type !== 'polygon') && (layerObj.type !== 'multipolygon'))) {
          lineColorValue.push(obj.iconColor);
        }
        else if ((layerObj.layer_type !== 3 && layerObj.layer_type !== 4) && ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon'))) {
          lineColorValue.push(obj.borderColor);
        }
        else if ((layerObj.layer_type == 3 || layerObj.layer_type === 4) && ((layerObj.type === "polyline") || (layerObj.type === "linestring") || (layerObj.type === "multilinestring"))) {
          lineColorValue.push([
            "match",
            ["number", ["get", "is_approved"]],
            UNAPPROVED_STATUS,
            (unApprovedObj.iconColor) ? unApprovedObj.iconColor : "#FF0000",
            obj.iconColor
          ])

        } else {
          lineColorValue.push([
            "match",
            ["number", ["get", "is_approved"]],
            UNAPPROVED_STATUS,
            (unApprovedObj.borderColor) ? unApprovedObj.borderColor : "#FF0000",
            obj.borderColor
          ]);

        }
      }
    });

    const approvedFallbackIconStyle = layerObj.icon_style.find((obj) => obj.is_fall_back && obj.is_approved);

    if ((approvedFallbackIconStyle.iconImage === 'dash.png') || (approvedFallbackIconStyle.iconImage == 'dash')) {

      paintObj['line-dasharray'] = [3, 3]
      layoutObj['line-cap'] = 'butt'


    } else if ((approvedFallbackIconStyle.iconImage === 'dotted.png') || (approvedFallbackIconStyle.iconImage === 'dotted')) {

      paintObj['line-dasharray'] = [0, 2];
      layoutObj['line-cap'] = 'round'

    } else if ((approvedFallbackIconStyle.iconImage === 'solid.png') || (approvedFallbackIconStyle.iconImage === 'solid')) {

      paintObj['line-dasharray'] = [3, 0];
      layoutObj['line-cap'] = 'butt'

    }
    paintObj['line-width'] = lineWidthValue;
    paintObj['line-opacity'] = lineOpacityArr;
    paintObj['line-color'] = lineColorValue;
    layoutObj['visibility'] = (layerObj.show) ? 'visible' : 'none'
    return { paint: paintObj, layout: layoutObj }
  }
  getDataCollectedPolylineCategoryStyle(layerObj, hideUnApproved: boolean = false) {
    let iconStyle = layerObj.category_icon_style.filter(obj => ((obj.categoryKey === layerObj.category_label) && (!obj.is_fall_back)));
    let paintObj = {};
    let layoutObj = {};
    const polyLineLayer = this.isPolyline(layerObj);
    let fallbackApprovedCategoryStyle = layerObj.category_icon_style.find((category_icon_style) => category_icon_style.is_approved && category_icon_style.is_fall_back) || {};
    let fallbackUnApprovedCategoryStyle = layerObj.category_icon_style.find((category_icon_style) => !category_icon_style.is_approved && category_icon_style.is_fall_back) || {};
    fallbackUnApprovedCategoryStyle.borderOpacity = (fallbackUnApprovedCategoryStyle.borderOpacity > 1) ? fallbackUnApprovedCategoryStyle.borderOpacity / 100 : (fallbackUnApprovedCategoryStyle.borderOpacity || 1)
    fallbackApprovedCategoryStyle.borderOpacity = (fallbackApprovedCategoryStyle.borderOpacity > 1) ? fallbackApprovedCategoryStyle.borderOpacity / 100 : (fallbackApprovedCategoryStyle.borderOpacity || 1)

    let lineColorArray: any = [
      'step',
      ['zoom'],
      ['match',
        ['number', ['get', 'is_approved']],
        UNAPPROVED_STATUS,
        ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) ? fallbackUnApprovedCategoryStyle.borderColor : fallbackUnApprovedCategoryStyle.iconColor || '#ff0000',
        ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) ? fallbackApprovedCategoryStyle.borderColor : fallbackApprovedCategoryStyle.iconColor || '#ff0000',
      ]];
    let borderOpacityArr: any = []
    if ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) {

      borderOpacityArr = ['step',
        ['zoom'],
        [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          fallbackUnApprovedCategoryStyle.borderOpacity || 1,
          fallbackApprovedCategoryStyle.borderOpacity || 1

        ]
      ]
    }

    let lineOpacityArray: any = [
      'step',
      ['zoom'],
      ['match',
        ['number', ['get', 'is_approved']],
        UNAPPROVED_STATUS,
        +(fallbackUnApprovedCategoryStyle.opacity || 1),
        +(fallbackApprovedCategoryStyle.opacity || 1)]
    ];
    let lineWidthArray: any = [
      'step',
      ['zoom'],
      ['match',
        ['number', ['get', 'is_approved']],
        UNAPPROVED_STATUS,
        +fallbackUnApprovedCategoryStyle.iconSize || 1,
        +fallbackApprovedCategoryStyle.iconSize || 1
      ]];
    // ((layerObj.type == "polygon") || (layerObj.type === "multipolygon"))
    if (iconStyle.length) {
      let categoryKey = iconStyle[0].categoryKey;
      for (let zoom_value = 1; zoom_value <= 22; zoom_value++) {
        let matchLineOpacityArr: any = ['match', ["to-string", ['get', categoryKey]]]
        let matchLineWidthArr: any = ['match', ["to-string", ['get', categoryKey]]]
        let matchPolylineColorArr: any = ['match', ["to-string", ['get', categoryKey]]];
        let matchBorderOpacityArr: any = ['match', ["to-string", ['get', categoryKey]]];

        lineColorArray.push(zoom_value);

        lineOpacityArray.push(zoom_value);
        lineWidthArray.push(zoom_value);
        borderOpacityArr.push(zoom_value)
        iconStyle.forEach((condition, i) => {
          let categoryValue = condition.categoryValue;
          if ((layerObj.type === 'polygon') || (layerObj.type == 'multipolygon')) {
            condition.borderOpacity = (condition.borderOpacity > 1) ? condition.borderOpacity / 100 : condition.borderOpacity
          }
          if (zoom_value < +condition.maxZoomLevel) {
            if (zoom_value < +condition.minZoomLevel) {
              matchPolylineColorArr.push([`${categoryValue}`], [
                'match',
                ['number', ['get', 'is_approved']],
                UNAPPROVED_STATUS,
                ((layerObj.type === "polygon") || (layerObj.type === "multipolygon")) ? fallbackUnApprovedCategoryStyle.borderColor || '#ff0000' : fallbackUnApprovedCategoryStyle.iconColor || '#ff0000',
                ((layerObj.type === 'polygon') || (layerObj.type == 'multipolygon')) ? fallbackApprovedCategoryStyle.borderColor || '#000000' : fallbackApprovedCategoryStyle.iconColor || '#000000'
              ]);
              matchLineOpacityArr.push([`${categoryValue}`],
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (hideUnApproved) ? 0 : +(fallbackUnApprovedCategoryStyle.opacity) > 1 ? +fallbackUnApprovedCategoryStyle.opacity / 100 : +fallbackUnApprovedCategoryStyle.opacity,
                  +(fallbackApprovedCategoryStyle.opacity) > 1 ? +fallbackApprovedCategoryStyle.opacity / 100 : +fallbackApprovedCategoryStyle.opacity || 1,
                ],

              );

              matchLineWidthArr.push([`${categoryValue}`],
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (hideUnApproved) ? 0 : +(fallbackUnApprovedCategoryStyle.iconSize),
                  +(fallbackApprovedCategoryStyle.iconSize || 1)
                ])
              matchBorderOpacityArr.push([`${categoryValue}`],
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (hideUnApproved) ? 0 : fallbackUnApprovedCategoryStyle.borderOpacity || 0,
                  +fallbackApprovedCategoryStyle.borderOpacity || 0
                ])
            } else {
              matchPolylineColorArr.push([`${categoryValue}`],
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? fallbackUnApprovedCategoryStyle.borderColor || '#ff0000' : fallbackUnApprovedCategoryStyle.iconColor || '#ff0000',
                  ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? condition.borderColor || '#000000' : condition.iconColor || '#000000'
                ],

              );
              matchLineOpacityArr.push([`${categoryValue}`],
                ['match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (hideUnApproved) ? 0 : +fallbackUnApprovedCategoryStyle.opacity || 1,
                  +(condition.opacity || 1) > 1 ? +(condition.opacity || 1) / 100 : +(condition.opacity || 1)],


              );

              matchLineWidthArr.push([`${categoryValue}`],
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (hideUnApproved) ? 0 : +fallbackUnApprovedCategoryStyle.iconSize || 1,
                  +(condition.iconSize || 1)
                ]
              )
              matchBorderOpacityArr.push([`${categoryValue}`],
                [
                  'match',
                  ['number', ['get', 'is_approved']],
                  UNAPPROVED_STATUS,
                  (hideUnApproved) ? 0 : fallbackUnApprovedCategoryStyle.borderOpacity || 1,
                  +fallbackApprovedCategoryStyle.borderOpacity || 1
                ])
            }
          } else {

            matchPolylineColorArr.push(
              [`${categoryValue}`],
              'transparent',
            );
            matchLineOpacityArr.push(
              [`${categoryValue}`],
              0,

            )

            matchLineWidthArr.push(
              [`${categoryValue}`],
              0
            );
            matchBorderOpacityArr.push(
              [`${categoryValue}`],
              0
            )
          }

        });
        lineWidthArray.push([...matchLineWidthArr,
        [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          (hideUnApproved) ? 0 : +fallbackUnApprovedCategoryStyle.iconSize || 1,
          +(fallbackApprovedCategoryStyle.iconSize || 1)
        ]]
        )


        lineOpacityArray.push([...matchLineOpacityArr,
        ['match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          (hideUnApproved) ? 0 : +fallbackUnApprovedCategoryStyle.opacity || 1,
          +(fallbackApprovedCategoryStyle.opacity || 1) > 1 ? +(fallbackApprovedCategoryStyle.opacity || 1) / 100 : +(fallbackApprovedCategoryStyle.opacity || 1)]

        ])
        lineColorArray.push([...matchPolylineColorArr,
        [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? fallbackUnApprovedCategoryStyle.borderColor || '#ff0000' : fallbackUnApprovedCategoryStyle.iconColor || '#000000',
          ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? fallbackApprovedCategoryStyle.borderColor || '#000000' : fallbackApprovedCategoryStyle.iconColor || '#000000'
        ]]
        )
        if ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) {

          borderOpacityArr.push([...matchBorderOpacityArr,
          [
            'match',
            ['number', ['get', 'is_approved']],
            UNAPPROVED_STATUS,
            fallbackUnApprovedCategoryStyle.borderOpacity || 1,
            fallbackApprovedCategoryStyle.borderOpacity || 1
          ]])
        }


      }
      if ((fallbackApprovedCategoryStyle.iconImage === 'solid.png') || (fallbackApprovedCategoryStyle.iconImage === 'solid')) {

        paintObj['line-dasharray'] = [3, 0];
        layoutObj['line-cap'] = 'butt'


      } else if ((fallbackApprovedCategoryStyle.iconImage === 'dash.png') || (fallbackApprovedCategoryStyle.iconImage === 'dash')) {


        paintObj['line-dasharray'] = [3, 3];
        layoutObj['line-cap'] = 'butt'

      } else {
        if ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) {
          paintObj['line-dasharray'] = [3, 0];
          layoutObj['line-cap'] = 'butt'
        } else {

          paintObj['line-dasharray'] = [0, 2];
          layoutObj['line-cap'] = 'round'
        }

      }
      paintObj['line-width'] = lineWidthArray;
      paintObj['line-opacity'] = lineOpacityArray;
      paintObj['line-color'] = lineColorArray;
      layoutObj['visibility'] = (layerObj.show) ? 'visible' : 'none';
      if ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) {
        paintObj['line-opacity'] = borderOpacityArr;
      }
    } else {

      let fallbackPolylineColorArray: any = [
        'step',
        ['zoom'],
        ['match', ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? fallbackUnApprovedCategoryStyle.borderColor || '#ff0000' : fallbackUnApprovedCategoryStyle.iconColor || '#ff0000',
          ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? fallbackApprovedCategoryStyle.borderColor || '#ff0000' : fallbackApprovedCategoryStyle.iconColor || '#ff0000'
        ]];

      let fallbackOpacityArray: any = ['step',
        ['zoom'],
        ['match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          (hideUnApproved) ? 0 : +(fallbackUnApprovedCategoryStyle.opacity) > 1 ? +fallbackUnApprovedCategoryStyle.opacity / 100 : +fallbackUnApprovedCategoryStyle.opacity,
          +(fallbackApprovedCategoryStyle.opacity || 1) > 1 ? +(fallbackApprovedCategoryStyle.opacity || 1) / 100 : +(fallbackApprovedCategoryStyle.opacity || 1),
        ]];;
      let fallbackLineWidthArray: any = [
        'step',
        ['zoom'],
        [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          (hideUnApproved) ? 0 : (polyLineLayer) ? +fallbackUnApprovedCategoryStyle.iconSize || 1 : (+fallbackUnApprovedCategoryStyle.iconSize > 1) ? +fallbackUnApprovedCategoryStyle.iconSize : 0,
          (polyLineLayer) ? +fallbackApprovedCategoryStyle.iconSize || 1 : (+fallbackApprovedCategoryStyle.iconSize || 1 > 1) ? +fallbackApprovedCategoryStyle.iconSize || 1 : 0]
      ];
      let fallbackBorderOpacityArr: any = [
        'step',
        ['zoom'],
        [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          fallbackUnApprovedCategoryStyle.borderOpacity || 0,
          fallbackApprovedCategoryStyle.borderOpacity || 0
        ]
      ]
      let approvedFallbackStyle = layerObj.category_icon_style.find(obj => obj.is_fall_back && obj.is_approved) || {};
      let unApprovedFallbackStyle = layerObj.category_icon_style.find(obj => obj.is_fall_back && !obj.is_approved) || {};
      for (let zoom_value = 1; zoom_value <= 22; zoom_value++) {

        fallbackPolylineColorArray.push(zoom_value, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? unApprovedFallbackStyle.borderColor || '#ff0000' : unApprovedFallbackStyle.iconColor || '#ff0000',
          ((layerObj.type == 'polygon') || (layerObj.type == 'multipolygon')) ? approvedFallbackStyle.borderColor || '#000000' : approvedFallbackStyle.iconColor || '#000000'
        ]);
        fallbackOpacityArray.push(zoom_value, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          +unApprovedFallbackStyle.opacity || 1,
          +approvedFallbackStyle.opacity || 1
        ]);

        fallbackLineWidthArray.push(zoom_value, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          (polyLineLayer) ? +unApprovedFallbackStyle.iconSize || 1 : (+unApprovedFallbackStyle.iconSize > 1) ? +unApprovedFallbackStyle.iconSize || 1 : 0,
          (polyLineLayer) ? +approvedFallbackStyle.iconSize || 1 : (+approvedFallbackStyle.iconSize > 1) ? +approvedFallbackStyle.iconSize || 1 : 0
        ]);
        fallbackBorderOpacityArr.push(zoom_value, [
          'match',
          ['number', ['get', 'is_approved']],
          UNAPPROVED_STATUS,
          fallbackUnApprovedCategoryStyle.borderOpacity || 0,
          fallbackApprovedCategoryStyle.borderOpacity || 0
        ])
      }


      if (approvedFallbackStyle.iconImage) {

        paintObj['line-dasharray'] = (approvedFallbackStyle.iconImage === 'line') ? [3, 0] : (approvedFallbackStyle.iconImage.includes('.png')) ? DashArray[(approvedFallbackStyle.iconImage || '').replace('.png', '')] : DashArray[(approvedFallbackStyle.iconImage || '')];
      } else {
        paintObj['line-dasharray'] = [3, 0]
      }
      paintObj['line-width'] = fallbackLineWidthArray || [];
      paintObj['line-color'] = fallbackPolylineColorArray || [];
      paintObj['line-opacity'] = fallbackOpacityArray || [];
      if (approvedFallbackStyle.iconImage) {

        layoutObj['line-cap'] = CapArray[(approvedFallbackStyle.iconImage || '').replace('.png', '')];
      } else {
        layoutObj['line-cap'] = 'butt'
      }
      if ((layerObj.type === 'polygon') || (layerObj.type === 'multipolygon')) {
        paintObj['line-opacity'] = fallbackBorderOpacityArr || []
      }
      layoutObj['visibility'] = layerObj.show && !layerObj.isSwipeLayer? 'visible' : 'none'

    }
    return { paint: paintObj, layout: layoutObj }

  }
  polygonSymbolStyleSelected(style, selectedLayer) {
    this.selectedPolygonSymbol = style.type;
    this.sessionService.selectedLayers[0].symbol = style.type;
    switch (style.type) {
      case 'hollow':
        this.plotHollowStyle(selectedLayer);
        break;
      case 'solid':
        this.plotSolidFillStyle(selectedLayer);
        break;
      case 'verticalLines':
        this.plotVerticalStyle(selectedLayer);
        break;
      case 'horizontalLines':
        this.plotHorizontalStyles(selectedLayer);
        break;
      case 'dotted':
        this.plotDottedStyles(selectedLayer);
    }
  }

  plotHollowStyle(layerObj, makeApiCall: boolean = true) {
    let hollowStyles = this.getPolygonFillStylesObj(layerObj);
    this.map.setPaintProperty(layerObj.id, 'fill-pattern', hollowStyles.paint['fill-pattern']);
    this.map.setPaintProperty(layerObj.id, 'fill-color', hollowStyles.paint['fill-color']);
    this.map.setPaintProperty(layerObj.id, 'fill-opacity', hollowStyles.paint['fill-opacity']);
    let iconStyles = []
    if (layerObj.style_type == 1) {
      iconStyles = layerObj.icon_style;
    } else if (layerObj.style_type === 2) {
      iconStyles = layerObj.category_icon_style
    }

    iconStyles = iconStyles.filter((obj) => {
      if (obj.is_fall_back) {
        obj.polygonStyle = 'hollow'
      }
      return obj;
    })
    if (makeApiCall) {
      if (layerObj.style_type == 1) {

        this.sessionService.selectedLayers[0].icon_style = iconStyles;
      } else if (layerObj.style_type === 2) {
        this.sessionService.selectedLayers[0].category_icon_style = iconStyles
      }
      this.sessionService.sendLayerDetailsToServer();
    }
  }

  plotSolidFillStyle(layerObj, makeApiCall: boolean = true) {
    let solidFillStyles: any = {}
    if ((layerObj.style_type === 1) || (layerObj.style_type === 2)) {
      solidFillStyles = this.getPolygonFillStylesObj(layerObj);
    }
    if (this.map.getLayer(layerObj._id)) {

      this.map.setPaintProperty(layerObj._id, 'fill-color', solidFillStyles.paint['fill-color']);
      this.map.setPaintProperty(layerObj._id, 'fill-opacity', solidFillStyles.paint['fill-opacity']);
      if (this.map.getPaintProperty(layerObj._id, 'fill-pattern')) {
        this.map.setPaintProperty(layerObj._id, 'fill-pattern', solidFillStyles.paint['fill-pattern']);
      }
    }
    let iconStyles = [];
    if (layerObj.style_type == 1) {
      iconStyles = layerObj.icon_style
    } else if (layerObj.style_type === 2) {
      iconStyles = layerObj.category_icon_style
    }
    iconStyles = iconStyles.filter((obj) => {
      if (obj.is_fall_back) {

        obj.polygonStyle = 'solid';
      }
      return obj
    })

    if (makeApiCall) {
      if (layerObj.style_type == 1) {

        this.sessionService.selectedLayers[0].icon_style = iconStyles;
      } else if (layerObj.style_type == 2) {
        this.sessionService.selectedLayers[0].category_icon_style = iconStyles
      }
      this.sessionService.sendLayerDetailsToServer();
    }
  }

  plotVerticalStyle(layerObj) {
    throwError('Method not yet implemented')

  }
  plotHorizontalStyles(layerObj) {
    let paintObj = {};
    throwError('Method not yet implemented')

  }

  plotDottedStyles(layerObj, makeApicall: boolean = true) {
    const paintObj = this.getDottedStyles(layerObj);
    this.map.setPaintProperty(layerObj._id, 'fill-color', paintObj['fill-color']),
      this.map.setPaintProperty(layerObj._id, 'fill-pattern', paintObj['fill-pattern'])
    let iconStyle = [];
    if (layerObj.style_type == 1) {
      iconStyle = layerObj.icon_style
    } else if (layerObj.style_type === 2) {
      iconStyle = layerObj.category_icon_style
    }
    iconStyle = iconStyle.filter((obj) => {
      if (obj.is_fall_back) {
        obj.polygonStyle = 'dotted'
      }
      return obj
    })

    if (makeApicall) {
      if (layerObj.style_type == 1) {

        this.sessionService.selectedLayers[0].icon_style = iconStyle;
      } else if (layerObj.style_type === 2) {
        this.sessionService.selectedLayers[0].category_icon_style = iconStyle
      }
      this.sessionService.sendLayerDetailsToServer();
    }
  }
  getDottedStyles(layerObj) {
    let paintObj = {};
    // let fillColorArr: any = ['interpolate', ['linear'], ['zoom']];
    let fillPatternArr: any = ['step', ['zoom'], 'circle'];
    let iconStyle = [];
    let unApprovedObj: any = {};
    if (layerObj.layer_type !== 3) {

      iconStyle = layerObj.icon_style
    } else {
      iconStyle = layerObj.icon_style.filter(({ is_approved }) => is_approved);
      unApprovedObj = layerObj.icon_style.find(({ is_approved }) => !is_approved)

    }
    if (layerObj.style_type === 1) {
      iconStyle.forEach((iconObj) => {
        if (+iconObj.minZoomLevel === +iconObj.maxZoomLevel) return
        if (iconObj.minZoomLevel < iconObj.maxZoomLevel) {
          // fillColorArr.push(iconObj.minZoomLevel);
          // fillOutlineColorArr.push(iconObj.minZoomLevel);
          fillPatternArr.push(iconObj.minZoomLevel);
          if (layerObj.layer_type !== 3) {
            // fillColorArr.push('transparent');
            fillPatternArr.push('circle')

          } else {
            // fillColorArr.push([
            //   'match',
            //   ['number', ['get', 'is_approved']],
            //   UNAPPROVED_STATUS,
            //   'transparent',
            //   'transparent'
            // ]);

            fillPatternArr.push([
              'match',
              ['number', ['get', 'is_approved']],
              UNAPPROVED_STATUS,
              'circle',
              'circle'
            ])

          }
        }

      })
    }
    if (this.map.getLayer(layerObj.id + 1)) {
      this.map.setPaintProperty(layerObj.id + 1, 'line-width', null);
      this.map.setPaintProperty(layerObj.id + 1, 'line-color', null);
      this.map.setLayoutProperty(layerObj.id + 1, 'visibility', 'none')
    }
    // paintObj['fill-color'] = fillColorArr;
    paintObj['fill-pattern'] = fillPatternArr;
    return paintObj;
  }

  applyLabel(layerObj, expression) {
    let finalExpression: Array<any> = [];
    if (!(layerObj.show_text_style && expression.length)) {
      finalExpression = ['to-string', ['get', 'invalid']];
    } else if (expression.length > 1) {
      finalExpression = ['concat']
      expression.forEach(exp => {
        finalExpression.push(exp);
      });
    } else {
      finalExpression = ['to-string', expression[0]];
    }
    if ([1, 3, 4, 6].includes(layerObj.layer_type) && [1, 2, 3, 4, 5].includes(layerObj.style_type)) {
      const geometryType = layerObj.type;
      if (['polygon', 'multipolygon'].includes(geometryType)) {
        if (this.map.getLayer(layerObj.id + 2)) {
          this.map.setLayoutProperty(layerObj.id + 2, "text-field", finalExpression.length ? finalExpression : "");
        }
      } else {
        if (this.map.getLayer(layerObj.id + 1)) {
          this.map.setLayoutProperty(layerObj.id + 1, "text-field", finalExpression.length ? finalExpression : "");
        }
      }
    }
  }

  setFieldLabel(layerObj) {
    const layerType = layerObj.layer_type;
    const styleType = layerObj.style_type;
    const selectedClassId = layerObj[TEXT_LABEL_ATTRIBUTE[styleType]].selected_class_id;
    const expression = layerObj[TEXT_LABEL_ATTRIBUTE[styleType]].classes.find(cls => cls.class_id === selectedClassId).map_expression;
    let finalExpression: Array<any> = [];
    if ([1, 3, 4, 6].includes(layerObj.layer_type) && [1, 2, 3, 4, 5].includes(layerObj.style_type)) {
      if (!(layerObj.show_text_style && expression.length)) {
        finalExpression = ['to-string', ['get', 'invalid']];
      } else if (expression.length > 1) {
        finalExpression = ['concat']
        expression.forEach(exp => {
          finalExpression.push(exp);
        });
      } else {
        finalExpression = ['to-string', expression[0]];
      }
    } else {
      finalExpression = ['to-string', ['get', 'invalid']];
    }
    return finalExpression;
  }

  setMapFilter(layerObj) {
    if (!([1, 3, 4].includes(layerObj.layer_type))) return;
    let style = layerObj.filter_query;
    if (!style.length) return;
    if (this.map.getLayer(layerObj.id)) {
      this.map.setFilter(layerObj.id, style);
    }
    if (this.map.getLayer(layerObj.id + 1)) {
      this.map.setFilter(layerObj.id + 1, style);
    }
    if (this.map.getLayer(layerObj.id + 2)) {
      this.map.setFilter(layerObj.id + 2, style);
    }
  }

  clearMapFilter(layerObj) {
    if (this.map.getLayer(layerObj.id)) {
      this.map.setFilter(layerObj.id, null);
    }
    if (this.map.getLayer(layerObj.id + 1)) {
      this.map.setFilter(layerObj.id + 1, null);
    }
    if (this.map.getLayer(layerObj.id + 2)) {
      this.map.setFilter(layerObj.id + 2, null);
    }
  }

  setPreviewIcon() {
    let blob = this.map.getCanvas().toDataURL();
    let file = new Blob([blobToBinary(blob)], { type: 'image/png' });
    // const url = window.URL.createObjectURL(file);
    // window.open(url);
    const dataObj = {
      file_name: "preview.png",
      file_type: "image/png",
      type: "map-file"
    }
    let formData = new FormData();
    formData.set('upfile', file);
    formData.set('file_name', dataObj.file_name);
    formData.set('file_type', "image/png");
    this.apiService.getFormDataToken().subscribe((resp: any) => {
      if (resp && resp.success) {
        formData.append('csrf_token', resp.data.csrf_token);
        this.layerService.saveMapOnCloud(formData).subscribe((resp: any) => {
          if (resp && resp.success) {
            this.metaImageSubject.next(resp.data.file_path)
            let image = new Image;
            image.src = resp.data.file_path;
            image.crossOrigin = "Anonymous";
            let hidden = document.createElement('div');
            hidden.className = 'hidden-icon';
            document.body.appendChild(hidden);
            let canvas = document.createElement('canvas');
            canvas.id = 'canvas'
            hidden.appendChild(canvas);
            let ctx = canvas.getContext('2d');
            ctx.canvas.height = 160;
            ctx.canvas.width = 160;
            image.onload = ((_) => {
              ctx.drawImage(image, (image.width / 2) - 80, (image.height / 2) - 80, 160, 160, 0, 0, 160, 160);
              const blb = canvas.toDataURL();
              let fData = new FormData();
              fData.set('pic', new Blob([blobToBinary(blb)], { type: 'image/png' }));
              this.apiService.getFormDataToken().subscribe((resp: any) => {
                if (resp && resp.data) {
                  fData.append('csrf_token', resp.data.csrf_token);
                  this.layerService.setSessionPreviewIcon(fData, this.sessionService.sessionId).subscribe(res => {
                    hidden.parentNode.removeChild(hidden);
                  });
                }

              })
            })
          }
        })
      }
    })

  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  getClasses(layerobj) {
    switch (layerobj.classification_method) {
      case 1:
        return layerobj.classification_style.equal_interval['ranges'];
      case 2:
        return layerobj.classification_style.quantile['ranges'];
      case 3:
        return layerobj.classification_style.natural_breaks['ranges'];
      case 4:
        return layerobj.classification_style.standard_deviation['ranges'];
      case 5:
        return layerobj.classification_style.pretty_breaks['ranges'];
      case 6:
        return layerobj.classification_style.manual['ranges']
      default:
        return []
    }

  }
}



export const DashArray = {
  solid: [3, 0],
  dash: [3, 3],
  dotted: [0, 2],
}





export const CapArray = {
  solid: 'butt',
  dash: 'butt',
  dotted: 'round',
  'line': 'butt'
}
export const UNAPPROVED_STATUS = [1, 3, 4]

export function blobToBinary(blob) {
  const BASE64_MARKER = ';base64,';
  const base64Index = blob.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  let raw = window.atob(blob.substring(base64Index));
  return Uint8Array.from(Array.prototype.map.call(raw, function (x) {
    return x.charCodeAt(0);
  }));
}

export const TEXT_LABEL_ATTRIBUTE = {
  1: "fixed_field_label",
  2: "category_field_label",
  3: "graduated_field_label",
  4: "bubble_field_label",
  5: "heat_map_field_label",
  6: "dotted_field_label",
  7: "clustered_field_label"
}