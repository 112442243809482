import { BasemapDialogComponent } from './dialogs/basemap-dialog/basemap-dialog.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IdlePreload, IdlePreloadModule } from "angular-idle-preload";
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { APP_IMPORTS } from "./app.imports";
import { APP_PROVIDERS } from "./app.providers";
import { APP_DECLARATIONS } from "./app.declaration";
import { COMPONENT_DECLARATIONS, routes } from "./app.routing";
import { UploadComponent } from "./dialogs/upload/upload.component";
import { ConfirmDialogComponent } from "./dialogs/confirm-dialog/confirm-dialog.component";
import { CommonModule as common } from "./dialogs/common.module";
import { MatProgressSpinnerModule } from '@angular/material';



// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider("Google-OAuth-Client-Id")
//   }
// ]);
import { DeleteDialogComponent } from "./dialogs/delete-dialog/delete-dialog.component";
import { MatButtonModule, MatRadioModule, MatSliderModule } from "@angular/material";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserManagementEffects } from "./store/effects/user-management.effects";
// import { UserManagementComponent } from './user-management/user-management.component';
// import { UserManagementListComponent } from './user-management/user-management-list/user-management-list.component';
// import { AddEditUserComponent } from './user-management/add-edit-user/add-edit-user.component';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { reducers, metaReducers } from "./store/reducers/index";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { AssignedDialogComponent } from "./dialogs/assigned-dialog/assigned-dialog.component";
import { DataManagementEffects } from "./store/effects/data-management.effects";
import { NgxSpinnerModule } from "ngx-spinner";
import { DeleteSessionsDialogComponent } from './dialogs/delete-sessions-dialog/delete-sessions-dialog.component';
import { ProfileEffects } from './store/effects/profile.effects';
import { DashboardEffects } from './store/effects/dashboard.effects';
import { DataapprovalmanagementEffects } from './store/effects/data-approval-management.effects'
import { CityOsPropertyTaxEffects } from './store/effects/city-os-property-tax.effects';
import { SessionManagementEffects } from './store/effects/session-management.effects';
import { PropertyTaxEffects } from './store/effects/property-tax.effects';
import { SurveyAnalyticsEffects } from './store/effects/survey-analytics.effects';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { TableDialogComponent } from './dialogs/table-dialog/table-dialog.component';
import { QueryDialogComponent } from './dialogs/query/query-dialog/query-dialog.component';
import { publishDialogComponent } from './dialogs/publish/publish-dialog.component';
import { QueryNameDialogComponent } from "./dialogs/query-name-dialog/query-name-dialog.component";
import { ShareMapComponent } from './share-map/share-map.component';
import { IdentifyComponent } from './app-home/app-side-nav-tools/tools/identify/identify.component';
import { PrintComponent } from './app-home/app-side-nav-tools/tools/print/print.component';
import { ExportComponent } from './app-home/app-side-nav-tools/tools/export/export.component';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { AssignSurveyorsDialogComponent } from './dialogs/assign-surveyors-dialog/assign-surveyors-dialog.component';
import { CreateVectorLayerComponent } from './dialogs/create-layer/create-vector-layer/create-vector-layer.component';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { MapIdentifyComponent } from './app-home/map-identify/map-identify.component';
import { PendingApprovalFilterComponent } from './dialogs/pending-approval-filters-dialog/pending-approval-filters-dialog.component';
import { AdvancedToolsDialogComponent } from './dialogs/advanced-tools-dialog/advanced-tools-dialog.component';
import { LayerNameDialogComponent } from './dialogs/layer-name/layer-name.component';
import { ChartsModule } from 'ng2-charts';
import { DataExportDialogComponent } from './dialogs/data-export-dialog/data-export-dialog.component';
import { BuildingFilterDialogComponent } from './dialogs/building-filter-dialog/building-filter-dialog.component';
import { DataApprovalEditDialogComponent } from './dialogs/data-approval-edit-dialog/data-approval-edit-dialog.component';

import { BuildingApprovalWarningComponent } from './dialogs/building-approval-warning/building-approval-warning.component';// import  {BarChartComponent } from './app-management/city-os-dashboard/bar-chart/bar-chart.component';
import { ProjectManagementEffects } from './store/effects/project-management.effects';
import { RasterBandDialogComponent } from './dialogs/raster-band/raster-band-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { PremiseFilterDialogComponent } from './dialogs/premise-filter-dialog/premise-filter-dialog.component'
import { DataApprovalErrorComponent } from './dialogs/data-approval-error/data-approval-error.component'
import { from } from 'rxjs';
import { InputDialogComponent } from './dialogs/input-dialog/input-dialog.component';
import { BookmarkDialogComponent } from './dialogs/bookmark-dialog/bookmark-dialog.component';
import { MapExportDialogComponent } from './dialogs/map-export-dialog/map-export-dialog.component';
import { GroupLayerComponent } from './dialogs/grouplayer-dialog/grouplayer-dialog.component';
import { SettingsDialogComponent } from './dialogs/settings-dialog/settings-dialog.component';
import { ExportDialogComponent } from './dialogs/export-dialog/export-dialog.component';

import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { SurveyAnalyticsDialogComponent } from './dialogs/survey-analytics-dialog/survey-analytics-dialog.component';
import { WmsPreviewComponent } from './wms-preview/wms-preview.component';
import { ProjectionInputDialogComponent } from './dialogs/projection-input-dialog/projection-input-dialog.component';
import { ProcessLayerDialogComponent } from './dialogs/process-layers-dialog/process-layer-dialog.component';
import { CustomPipeModule } from './pipes/pipe.module';
import { FeatureDetectionDialogComponent } from './dialogs/feature-detection-dialog/feature-detection-dialog.component';
import { SubscriptionEffects } from './store/effects/subscription.effects';
import { SubscriptionUpgradeDialogComponent } from './dialogs/subscription/subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { PlanDetailsDialogComponent } from './dialogs/plan-details-dialog/plan-details-dialog.component';
import { InvoiceDialogComponent } from './dialogs/invoice-dialog/invoice-dialog.component';
import { ObjectPickerDialogComponent } from './dialogs/object-picker-dialog/object-picker-dialog.component'
import { errorComponent } from './errorcomponent/error-component';
import { SubscriptionErrorDialogComponent } from './dialogs/subscription/subscription-error-dialog/subscription-error-dialog.component';
import { CommonDialogComponent } from './dialogs/common-dialog/common-dialog.component';
import { ClipDialogComponent } from './dialogs/raster-tools-dialogs/clip-dialog/clip-dialog.component';
import { SlopeDialogComponent } from './dialogs/raster-tools-dialogs/slope-dialog/slope-dialog.component';
import { GradualPathDialogComponent } from './dialogs/raster-tools-dialogs/gradual-path-dialog/gradual-path-dialog.component';
import { VisibilityCoverageDialogComponent } from './dialogs/raster-tools-dialogs/visibility-coverage-dialog/visibility-coverage-dialog.component';
import { SpatialFilterDialogComponent } from './dialogs/raster-tools-dialogs/spatial-filter-dialog/spatial-filter-dialog.component';
import { DataLibraryLayerDetailsDialog } from './dialogs/data-library/data-library-layer-details-dialog/data-library-layer-details-dialog.component';
import { DataLibraryFilterDialog } from './dialogs/data-library/data-library-filter-dialog/data-library-filter-dialog.component';
import { LayerSelectionDialog } from './dialogs/data-library/layer-selection-dialog/layer-selection-dialog.component';
import { FieldSelectionDialog } from './dialogs/data-library/field-selection-dialog/field-selection-dialog.component';
import { JoinResultDialog } from './dialogs/data-library/join-result-dialog/join-result-dialog.component';
import { JoinedLayerNameDialog } from './dialogs/data-library/joined-layer-name-dialog/joined-layer-name-dialog.component';
import { JoinDataSuccessDialog } from './dialogs/data-library/join-data-success-dialog/join-data-success-dialog.component';
import { SnapDialogComponent } from './dialogs/snap-dialog/snap-dialog.component';
import { SelectFormsSurveyorsDialogComponent } from './app-management/project-management/projects/select-forms-surveyors/select-forms-surveyors.component';
import { UseInMapSessionDialogComponent } from './dialogs/data-library/use-in-map-session-dialog/use-in-map-session-dialog.component';
import { DataLibraryPreviewDialog } from './dialogs/data-library/data-library-preview-dialog/data-library-preview-dialog.component';
import { VectorJoinSelectionDialogComponent } from './dialogs/data-library/vector-join-selection-dialog/vector-join-selection-dialog.component';
import { InfocontactDialogComponent } from './dialogs/info-contact-dialog/info-contact-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { RenameDialogComponent } from './dialogs/rename-dialog/rename-dialog.component'
import { datalibraryupload } from './datalibrary-upload/datalibrary-upload.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import { YourDataLayerSelectionDialogComponent } from './dialogs/data-library/your-data-layer-selection-dialog/your-data-layer-selection-dialog.component';
import { YourDataPreviewComponent } from './dialogs/your-data-preview/your-data-preview.component';
import { BackgroundStylesComponent } from './dialogs/background-styles-dialog/background-styles-dialog.component';
import { NavDialogComponent } from './dialogs/nav-tab-dialog/nav-tab-dialog.component';
import { DCApprovalFilterDialogComponent } from './dialogs/dc-approval-filter-dialog/dc-approval-filter-dialog.component';
import { MapDashboardDialogComponent } from './dialogs/map-session-dashboard-dialog/map-session-dashboard-dialog.component';
import { FilterDataToDisplayDialogComponent } from './dialogs/query/filter-data-to-display-dialog/filter-data-to-display-dialog.component';
import { AdvancedLabelDialogComponent } from './dialogs/advanced-label-dialog/advanced-label-dialog.componrnt';
import { ExpressionDialogComponent } from './dialogs/advanced-label-dialog/expression-dialog/expression-dialog.component';
import { PublishLayerDialogComponent } from './dialogs/cityos-dialogs/publish-layer-dialog/publish-layer-dialog.component';
import { GraduatedClassificationDialog } from './dialogs/graduated-classification-dialog/graduated-classification-dialog.component';
import { ServiceAreaDialog } from './dialogs/tools/vector-processing/network-analysis/service-area-dialog/service-area-dialog.component';
import { RegressionOLSDialog } from './dialogs/tools/vector-processing/statistical-analysis/regression-ols-dialog/regression-ols-dialog.component';
import { RegressionSummaryDialog } from './dialogs/tools/vector-processing/statistical-analysis/regression-summary-dialog/regression-summary-dialog.component';
import { DistanceToNearestFacilityDialog } from './dialogs/tools/vector-processing/network-analysis/distance-to-nearest-facility/distance-to-nearest-facility.component';
import { SpatialJoinSummaryDialog } from './dialogs/tools/vector-processing/statistical-analysis/spatial-join-summary/spatial-join-summary.component';
import { SpatialJoinAddDialog } from './dialogs/tools/vector-processing/statistical-analysis/spatial-join-add/spatial-join-add.component';
import { SpatialJoinDialog } from './dialogs/tools/vector-processing/statistical-analysis/spatial-join/spatial-join.component';
import { CancelSubscriptionDialog } from './dialogs/subscription/cancel-subscription-dialog/cancel-subscription-dialog.component';
import { RestoreBuildingDialogComponent } from './dialogs/restore-building-dialog/restore-building-dialog.component';
import { SimilaritySearch } from './dialogs/tools/vector-processing/statistical-analysis/similarity-search/similarity-search.component';



// import  {BarChartComponent } from './app-management/city-os-dashboard/bar-chart/bar-chart.component';
// import { DashboardZoomComponent } from './app-management/city-os-dashboard/dashboard-zoom/dashboard-zoom.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}
// export function provideConfig() {
//   return config;
// }


@NgModule({
  declarations: [
    AppComponent,
    // ...COMPONENT_DECLARATIONS,
    ...APP_DECLARATIONS,
    IdentifyComponent,
    PrintComponent,
    ExportComponent,
    ShareMapComponent,
    MapIdentifyComponent,
    WmsPreviewComponent,
    errorComponent,
    datalibraryupload

    // ChartsModule,
    // BarChartComponent,
    // DashboardZoomComponent,
  ],
  imports: [
    NgxAudioPlayerModule,
    ...APP_IMPORTS,
    common,
    IdlePreloadModule.forRoot(), // forRoot ensures the providers are only created once
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: IdlePreload,
      enableTracing: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      toastClass: "toast toast-bootstrap-compatibility-fix",
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      maxOpened: 5
    }),
    // SocialLoginModule
    NgxSpinnerModule,
    MatButtonModule,
    MatRadioModule,
    MatSliderModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserManagementEffects, DataManagementEffects,
      DataapprovalmanagementEffects, SessionManagementEffects, ProfileEffects,
      DashboardEffects, PropertyTaxEffects, ProjectManagementEffects,
      CityOsPropertyTaxEffects, SubscriptionEffects, SurveyAnalyticsEffects]),
    StoreRouterConnectingModule.forRoot(),
    ScrollingModule,
    StoreDevtoolsModule.instrument({
      maxAge: 20
    }),
    // 
    CustomPipeModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatBadgeModule
  ],
  providers: [
    APP_PROVIDERS
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [
    UploadComponent,
    ConfirmDialogComponent,
    DeleteDialogComponent,
    AssignedDialogComponent,
    AssignSurveyorsDialogComponent,
    DeleteSessionsDialogComponent,
    TableDialogComponent,
    QueryDialogComponent,
    publishDialogComponent,
    QueryNameDialogComponent,
    InfoDialogComponent,
    BasemapDialogComponent,
    CreateVectorLayerComponent,
    GroupLayerComponent,
    MapIdentifyComponent,
    PendingApprovalFilterComponent,
    AdvancedToolsDialogComponent,
    LayerNameDialogComponent,
    DataExportDialogComponent,
    BuildingFilterDialogComponent,
    DataApprovalEditDialogComponent,
    BuildingApprovalWarningComponent,
    SelectFormsSurveyorsDialogComponent,
    MessageDialogComponent,
    NavDialogComponent,
    InputDialogComponent,
    BookmarkDialogComponent,
    PremiseFilterDialogComponent,
    MapExportDialogComponent,
    SettingsDialogComponent,
    ExportDialogComponent,
    UploadDialogComponent,
    DataApprovalErrorComponent,
    SurveyAnalyticsDialogComponent,
    ProjectionInputDialogComponent,
    ProcessLayerDialogComponent,
    FeatureDetectionDialogComponent,
    SubscriptionUpgradeDialogComponent,
    SubscriptionErrorDialogComponent,
    PlanDetailsDialogComponent,
    InvoiceDialogComponent,
    InfocontactDialogComponent,
    ObjectPickerDialogComponent,
    CommonDialogComponent,
    ClipDialogComponent,
    SlopeDialogComponent,
    GradualPathDialogComponent,
    VisibilityCoverageDialogComponent,
    SpatialFilterDialogComponent,
    DataLibraryLayerDetailsDialog,
    DataLibraryFilterDialog,
    DataLibraryPreviewDialog,
    LayerSelectionDialog,
    FieldSelectionDialog,
    JoinResultDialog,
    JoinedLayerNameDialog,
    JoinDataSuccessDialog,
    SnapDialogComponent,
    UseInMapSessionDialogComponent,
    VectorJoinSelectionDialogComponent,
    YourDataLayerSelectionDialogComponent,
    RenameDialogComponent,
    RenameDialogComponent,
    YourDataPreviewComponent,
    BackgroundStylesComponent,
    MapDashboardDialogComponent,
    DCApprovalFilterDialogComponent,
    FilterDataToDisplayDialogComponent,
    AdvancedLabelDialogComponent,
    ExpressionDialogComponent,
    PublishLayerDialogComponent,
    GraduatedClassificationDialog,
    ServiceAreaDialog,
    RegressionOLSDialog,
    RegressionSummaryDialog,
    DistanceToNearestFacilityDialog,
    SpatialJoinDialog,
    SpatialJoinAddDialog,
    SpatialJoinSummaryDialog,
    CancelSubscriptionDialog,
    RestoreBuildingDialogComponent,
    SimilaritySearch
  ]
})
export class AppModule { }
