import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import moment from "moment-mini-ts";

@Component({
  selector: "app-cancel-subscription",
  templateUrl: "./cancel-subscription-dialog.component.html",
  styleUrls: ["./cancel-subscription-dialog.component.scss"]
})

export class CancelSubscriptionDialog {
  cancelInfo: Array<CancelInfo> = [];
  selectedReasons: Array<number> = [];
  dialogHeader: string = null;
  infoText: string = null;
  constructor(
    private dialogRef: MatDialogRef<CancelSubscriptionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) { }
  
  ngOnInit() {
    let endDate = new Date(this.data.endDate*1000);
    let dates = endDate.getDate() + ' ' + endDate.toLocaleString('default', { month: 'short' })
    + ' ' + endDate.getFullYear();
    this.dialogHeader = `Sorry to see you go${this.data.userName ? ', ' + this.data.userName : ''}!`;
    this.infoText = `Once you cancel this subscription, you will be able to use the platform until ${dates}. We will retain your data for 3 months, free of charge after the expiry date.
    You can restart your subscription any time during this window by upgrading your subscription.
    After 3 months, if you do not renew your subscription, then we will delete your data.`
  }
  cancelSubscription() {
    const cancelDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        alertTitle: 'CANCEL SUBSCRIPTION',
        alertText: 'Would you like to cancel current subscription?',
        confirmText: 'CONFIRM'
      }
    });
    cancelDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        const payload = {
          cancel_info: this.cancelInfo
        }
        this.dialogRef.close(payload);
      } else {
        this.dialogRef.close(false);
      }
    })
  }

  updateSelectedReasons(event, reason) {
    if(event.checked) {
      this.selectedReasons.push(+reason);
      this.cancelInfo.push({
        reason: +reason,
        feedback: ""
      })
    } else {
      const ind = this.selectedReasons.findIndex(x => +x === +reason);
      if(ind > -1) this.selectedReasons.splice(ind, 1);
      const cancelInd = this.cancelInfo.findIndex(x => +x.reason === +reason);
      if(cancelInd > -1) this.cancelInfo.splice(cancelInd, 1);
    }
  }

  setFeedback(reason, val) {
    if(!val.trim()) return;
    const cancelInd = this.cancelInfo.findIndex(x => +x.reason === +reason);
    if(cancelInd > -1) this.cancelInfo[cancelInd].feedback = val.trim();
  }

  close(val) {
    this.dialogRef.close(val);
  }
}

interface CancelInfo {
  reason: number,
  feedback: string
}