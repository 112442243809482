import { Component, OnInit, Inject} from '@angular/core';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-object-picker-dialog',
  templateUrl: './object-picker-dialog.component.html',
  styleUrls: ['./object-picker-dialog.component.scss']
})
export class ObjectPickerDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ObjectPickerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  selectedObject : any = []
  removableChip: boolean = true;
  ngOnInit() {
    this.selectedObject = this.data.objectList
  }

  cloaseObjectPicker(){
    this.dialogRef.close(this.selectedObject);
  }

  removePreTOSModel(label) {
    this.selectedObject.splice(this.selectedObject.findIndex(model => model == label), 1)
    // widget.selectedObjectsLength = widget.selectedObjects.length
  }
}
