import { DataapprovalmanagementActions, dataapprovalmanagementTypes } from '../actions/data-approval-management.actions';
import { isArray } from 'util';
// import { type } from 'os';

export interface User {
  username: string,
  email: string,
  user_role: string,
}

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;

  layerTableData: {
    layerId: string,
    totalPages: number,
    totalRecords: number,
    pageNumber: number,
    dataStatus: number,
    data: Array<any>,
    templateProperties: Array<string>,
    propertiesType: any,

  },
  layerPremiseTableData: {
    layerId: string,
    totalPages: number,
    totalRecords: number,
    pageNumber: number,
    dataStatus: number,
    data: Array<any>,
    templateProperties: Array<string>,
    propertiesType: any,
    floorNumbers: Array<any>

  },
  layerAttributes: Array<any>,
  data: Array<User>,
  selectedUser: User,
  page: number,
  count: number,
  roles: Array<any>,
  layerPremiseAttributes: Array<any>,
  buildingsData: Array<any>,
  featureDetails: Array<any>,
  features: any,
  premises: any
  // },
  // layerPremiseTableData: {
  //   layerId: string,
  //   totalPages: number,
  //   totalRecords: number,
  //   pageNumber: number,
  //   dataStatus: number,
  //   data: Array<any>,
  //   templateProperties: Array<string>,
  //   propertiesType: any,

  // },
  // layerAttributes: Array<any>,
  // data: Array<User>;
  // selectedUser: User;
  // page: number;
  // count: number;
  // roles: Array<any>
  // layerPremiseAttributes: Array<any>,
}

export const initialState: State = {
  loading: false,
  loaded: false,
  error: null,
  buildingsData : [],
  layerTableData: {
    layerId: "",
    totalPages: 0,
    totalRecords: 0,
    pageNumber: 0,
    dataStatus: null,
    data: [],
    templateProperties: [],
    propertiesType: {},

  },
  layerPremiseTableData: {
    layerId: "",
    totalPages: 0,
    totalRecords: 0,
    pageNumber: 0,
    dataStatus: null,
    data: [],
    templateProperties: [],
    propertiesType: {},
    floorNumbers: []
  },
  layerAttributes: [],
  data: [],
  selectedUser: null,
  page: 1,
  count: 0,
  roles: [],
  layerPremiseAttributes: [],
  featureDetails: [],
  features: [],
  premises:[]
}

export function reducer(state = initialState, action: DataapprovalmanagementActions): State {
  switch (action.type) {
    case dataapprovalmanagementTypes.getLayerTableDataSuccess:
      if (action.data.layer_id === state.layerTableData.layerId &&
        +action.data.page !== 1 &&
        action.data.is_approved === state.layerTableData.dataStatus
      ) {
        return {
          ...state, layerTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: [...state.layerTableData.data, ...action.data.docs],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,

          }
        };
      } else {
        return {
          ...state, layerTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: action.data.docs,
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
          }
        }
      }
    case dataapprovalmanagementTypes.getLayerPremiseTableDataSuccess:
      if (action.data.layer_id === state.layerPremiseTableData.layerId &&
        +action.data.page !== 1 &&
        action.data.is_approved === state.layerPremiseTableData.dataStatus
      ) {
        return {
          ...state, layerPremiseTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: [...state.layerPremiseTableData.data, ...action.data.docs],
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            floorNumbers: action.data.floorNumbers
          }
        };
      } else {
        return {
          ...state, layerPremiseTableData: {
            layerId: action.data.layer_id,
            totalPages: action.data.pages,
            totalRecords: action.data.total,
            pageNumber: +action.data.page,
            dataStatus: action.data.is_approved,
            data: action.data.docs,
            templateProperties: action.data.template_properties,
            propertiesType: action.data.attribute_type,
            floorNumbers: action.data.floorNumbers
          }
        }
      }
    case dataapprovalmanagementTypes.getBuildingDataSuccess:
      return{...state, buildingsData: action.data}
    case dataapprovalmanagementTypes.getLayerPremiseTableDataFailed:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      }
    case dataapprovalmanagementTypes.getSelectedFeatureDetailsSuccess:
      return {
        ...state,
        featureDetails: action.response.geo_json ? [...action.response.geo_json.features] : [],
        features : {geoJson: action.response.geo_json}
      }
      case dataapprovalmanagementTypes.getPremiseDataSuccessAction:
        return {
          ...state,
          premises: action.response
        }
    default:
      return state;
  }
}