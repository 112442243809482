import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: "app-layer-name",
  templateUrl: "./layer-name.component.html",
  styleUrls: ["./layer-name.component.scss"]
})

export class LayerNameDialogComponent{
  layerNameForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LayerNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.layerNameForm = this.fb.group({
      name: ["", [Validators.required, this.noWhitespaceValidator]]
    })
  }

  onSubmit() {
    if(this.layerNameForm.valid) {
      this.dialogRef.close(this.layerNameForm.value.name);
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
  public noWhitespaceValidator(control: FormControl) {
    if (!control.value) return null;
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}