import { DeleteDialogComponent } from 'src/app/dialogs/delete-dialog/delete-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule as common } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { UploadComponent } from './upload/upload.component';
import { AssignedDialogComponent } from './assigned-dialog/assigned-dialog.component';
import { DeleteSessionsDialogComponent } from './delete-sessions-dialog/delete-sessions-dialog.component';
import { LoaderComponent } from '../loader/loader.component';
import { TableDialogComponent } from './table-dialog/table-dialog.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { EmbedVideo } from 'ngx-embed-video';
import { HttpClientModule } from '@angular/common/http';
import { MatVideoModule } from 'mat-video';
import { QueryDialogComponent } from './query/query-dialog/query-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule, MatDatepickerModule, MatChipsModule, MatIconModule, MatAutocompleteModule, MatSlideToggleModule, MatNativeDateModule, MatRadioModule, MatSliderModule, MatTableModule, MatDialogModule, MatListModule, MatTabsModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { publishDialogComponent } from './publish/publish-dialog.component';
import { OrderModule } from "ngx-order-pipe";
import { FilterPipeModule } from 'ngx-filter-pipe';
import { QueryNameDialogComponent } from "./query-name-dialog/query-name-dialog.component";
import { BasemapDialogComponent } from './basemap-dialog/basemap-dialog.component';
import { CreateVectorLayerComponent } from './create-layer/create-vector-layer/create-vector-layer.component';
import { PendingApprovalFilterComponent } from './pending-approval-filters-dialog/pending-approval-filters-dialog.component';
import { AssignSurveyorsDialogComponent } from './assign-surveyors-dialog/assign-surveyors-dialog.component';
import { AdvancedToolsDialogComponent } from './advanced-tools-dialog/advanced-tools-dialog.component';
import { LayerNameDialogComponent } from './layer-name/layer-name.component';
import { DataExportDialogComponent } from './data-export-dialog/data-export-dialog.component';
import { BuildingFilterDialogComponent } from './building-filter-dialog/building-filter-dialog.component';
import { BuildingApprovalWarningComponent } from './building-approval-warning/building-approval-warning.component';
import { RasterBandDialogComponent } from './raster-band/raster-band-dialog.component';
import { MatCheckboxModule } from '@angular/material'
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { BookmarkDialogComponent } from './bookmark-dialog/bookmark-dialog.component';
import { PremiseFilterDialogComponent } from './premise-filter-dialog/premise-filter-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MapExportDialogComponent } from './map-export-dialog/map-export-dialog.component';
import { GroupLayerComponent } from './grouplayer-dialog/grouplayer-dialog.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DataApprovalErrorComponent } from './data-approval-error/data-approval-error.component';
import { SurveyAnalyticsDialogComponent } from './survey-analytics-dialog/survey-analytics-dialog.component';
import { DataApprovalEditDialogComponent } from './data-approval-edit-dialog/data-approval-edit-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ProjectionInputDialogComponent } from './projection-input-dialog/projection-input-dialog.component';
import { ProcessLayerDialogComponent } from './process-layers-dialog/process-layer-dialog.component';
import { FeatureDetectionDialogComponent } from './feature-detection-dialog/feature-detection-dialog.component';
import { SubscriptionUpgradeDialogComponent } from './subscription/subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { PlanDetailsDialogComponent } from './plan-details-dialog/plan-details-dialog.component';
import { InvoiceDialogComponent } from './invoice-dialog/invoice-dialog.component';
import { ObjectPickerDialogComponent } from './object-picker-dialog/object-picker-dialog.component';
import { SubscriptionErrorDialogComponent } from './subscription/subscription-error-dialog/subscription-error-dialog.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ClipDialogComponent } from './raster-tools-dialogs/clip-dialog/clip-dialog.component';
import { SlopeDialogComponent } from './raster-tools-dialogs/slope-dialog/slope-dialog.component';
import { GradualPathDialogComponent } from './raster-tools-dialogs/gradual-path-dialog/gradual-path-dialog.component';
import { VisibilityCoverageDialogComponent } from './raster-tools-dialogs/visibility-coverage-dialog/visibility-coverage-dialog.component';
import { SpatialFilterDialogComponent } from './raster-tools-dialogs/spatial-filter-dialog/spatial-filter-dialog.component';
import { DataLibraryLayerDetailsDialog } from './data-library/data-library-layer-details-dialog/data-library-layer-details-dialog.component';
import { DataLibraryFilterDialog } from './data-library/data-library-filter-dialog/data-library-filter-dialog.component';
import { LayerSelectionDialog } from './data-library/layer-selection-dialog/layer-selection-dialog.component';
import { FieldSelectionDialog } from './data-library/field-selection-dialog/field-selection-dialog.component';
import { JoinResultDialog } from './data-library/join-result-dialog/join-result-dialog.component';
import { JoinedLayerNameDialog } from './data-library/joined-layer-name-dialog/joined-layer-name-dialog.component';
import { JoinDataSuccessDialog } from './data-library/join-data-success-dialog/join-data-success-dialog.component';
import { SnapDialogComponent } from './snap-dialog/snap-dialog.component';
import { SelectFormsSurveyorsDialogComponent } from '../app-management/project-management/projects/select-forms-surveyors/select-forms-surveyors.component';
import { UseInMapSessionDialogComponent } from './data-library/use-in-map-session-dialog/use-in-map-session-dialog.component';
import { DataLibraryPreviewDialog } from './data-library/data-library-preview-dialog/data-library-preview-dialog.component';
import { VectorJoinSelectionDialogComponent } from './data-library/vector-join-selection-dialog/vector-join-selection-dialog.component';
import { InfocontactDialogComponent } from './info-contact-dialog/info-contact-dialog.component';
import { RenameDialogComponent } from './rename-dialog/rename-dialog.component';
import { YourDataLayerSelectionDialogComponent } from './data-library/your-data-layer-selection-dialog/your-data-layer-selection-dialog.component';
import { YourDataPreviewComponent } from './your-data-preview/your-data-preview.component';
import { BackgroundStylesComponent } from './background-styles-dialog/background-styles-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NavDialogComponent } from './nav-tab-dialog/nav-tab-dialog.component';
import {MatBadgeModule} from '@angular/material/badge';
import { DCApprovalFilterDialogComponent } from './dc-approval-filter-dialog/dc-approval-filter-dialog.component';
import { MapDashboardDialogComponent } from './map-session-dashboard-dialog/map-session-dashboard-dialog.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { ViewDataDialog } from './view-data/view-data.component';
import { QuerySkeletonComponent } from './query/query-skeleton/query-skeleton.component';
import { FilterDataToDisplayDialogComponent } from './query/filter-data-to-display-dialog/filter-data-to-display-dialog.component';
import { AdvancedLabelDialogComponent } from './advanced-label-dialog/advanced-label-dialog.componrnt';
import { DefaultAdvancedLabel } from './advanced-label-dialog/default-label/default-label.component';
import { CustomAdvancedLabel } from './advanced-label-dialog/custom-label/custom-label.component';
import { ExpressionDialogComponent } from './advanced-label-dialog/expression-dialog/expression-dialog.component';
import { PublishLayerDialogComponent } from './cityos-dialogs/publish-layer-dialog/publish-layer-dialog.component';
import { GraduatedClassificationDialog } from './graduated-classification-dialog/graduated-classification-dialog.component';
import { RegressionOLSDialog } from './tools/vector-processing/statistical-analysis/regression-ols-dialog/regression-ols-dialog.component';
import { RegressionSummaryDialog } from './tools/vector-processing/statistical-analysis/regression-summary-dialog/regression-summary-dialog.component';
import { ServiceAreaDialog } from './tools/vector-processing/network-analysis/service-area-dialog/service-area-dialog.component';
import { DistanceToNearestFacilityDialog } from './tools/vector-processing/network-analysis/distance-to-nearest-facility/distance-to-nearest-facility.component';
import { SpatialJoinDialog } from './tools/vector-processing/statistical-analysis/spatial-join/spatial-join.component';
import { SpatialJoinAddDialog } from './tools/vector-processing/statistical-analysis/spatial-join-add/spatial-join-add.component';
import { SpatialJoinSummaryDialog } from './tools/vector-processing/statistical-analysis/spatial-join-summary/spatial-join-summary.component';
import { CancelSubscriptionDialog } from './subscription/cancel-subscription-dialog/cancel-subscription-dialog.component';
import { RestoreBuildingDialogComponent } from './restore-building-dialog/restore-building-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimilaritySearch } from './tools/vector-processing/statistical-analysis/similarity-search/similarity-search.component';
@NgModule({
  imports: [
    common,
    NgxSpinnerModule,
    NgxAudioPlayerModule,
    HttpClientModule,
    MatVideoModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    EmbedVideo.forRoot(),
    OrderModule,
    FilterPipeModule,
    MatDatepickerModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTooltipModule,
    MatTabsModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSliderModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatDialogModule,
    Ng5SliderModule,
    MatListModule,
    ColorPickerModule,
    MatBadgeModule,
    ScrollingModule,
    FontAwesomeModule
  ],
  declarations: [
    ConfirmDialogComponent,
    InfoDialogComponent,
    UploadComponent,
    DeleteDialogComponent,
    AssignedDialogComponent,
    AssignSurveyorsDialogComponent,
    DeleteSessionsDialogComponent,
    LoaderComponent,
    TableDialogComponent,
    QueryDialogComponent,
    publishDialogComponent,
    QueryNameDialogComponent,
    BasemapDialogComponent,
    CreateVectorLayerComponent,
    PendingApprovalFilterComponent,
    AdvancedToolsDialogComponent,
    LayerNameDialogComponent,
    DataExportDialogComponent,
    BuildingFilterDialogComponent,
    BuildingApprovalWarningComponent,
    RasterBandDialogComponent,
    SelectFormsSurveyorsDialogComponent,
    MessageDialogComponent,
    NavDialogComponent,
    InputDialogComponent,
    BookmarkDialogComponent,
    PremiseFilterDialogComponent,
    MapExportDialogComponent,
    GroupLayerComponent,
    SettingsDialogComponent,
    ExportDialogComponent,
    UploadDialogComponent,
    DataApprovalErrorComponent,
    SurveyAnalyticsDialogComponent,
    DataApprovalEditDialogComponent,
    ProjectionInputDialogComponent,
    ProcessLayerDialogComponent,
    FeatureDetectionDialogComponent,
    SubscriptionUpgradeDialogComponent,
    SubscriptionErrorDialogComponent,
    PlanDetailsDialogComponent,
    InvoiceDialogComponent,
    ObjectPickerDialogComponent,
    CommonDialogComponent,
    ClipDialogComponent,
    SlopeDialogComponent,
    GradualPathDialogComponent,
    VisibilityCoverageDialogComponent,
    SpatialFilterDialogComponent,
    DataLibraryLayerDetailsDialog,
    DataLibraryFilterDialog,
    DataLibraryPreviewDialog,
    LayerSelectionDialog,
    FieldSelectionDialog,
    JoinResultDialog,
    JoinedLayerNameDialog,
    JoinDataSuccessDialog,
    SnapDialogComponent,
    InfocontactDialogComponent,
    UseInMapSessionDialogComponent,
    VectorJoinSelectionDialogComponent,
    YourDataLayerSelectionDialogComponent,
    RenameDialogComponent,
    RenameDialogComponent,
    YourDataPreviewComponent,
    BackgroundStylesComponent,
    MapDashboardDialogComponent,
    DCApprovalFilterDialogComponent,
    ViewDataDialog,
    QuerySkeletonComponent,
    FilterDataToDisplayDialogComponent,
    AdvancedLabelDialogComponent,
    CustomAdvancedLabel,
    DefaultAdvancedLabel,
    ExpressionDialogComponent,
    PublishLayerDialogComponent,
    GraduatedClassificationDialog,
    ServiceAreaDialog,
    RegressionOLSDialog,
    RegressionSummaryDialog,
    DistanceToNearestFacilityDialog,
    SpatialJoinDialog,
    SimilaritySearch,
    SpatialJoinAddDialog,
    SpatialJoinSummaryDialog,
    CancelSubscriptionDialog,
    RestoreBuildingDialogComponent
  ],
  exports: [
    ConfirmDialogComponent,
    InfoDialogComponent,
    UploadComponent,
    DeleteDialogComponent,
    AssignedDialogComponent,
    DeleteSessionsDialogComponent,
    LoaderComponent,
    TableDialogComponent,
    QueryDialogComponent,
    publishDialogComponent,
    QueryNameDialogComponent,
    BasemapDialogComponent,
    CreateVectorLayerComponent,
    PendingApprovalFilterComponent,
    MatDatepickerModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatAutocompleteModule,
    AssignSurveyorsDialogComponent,
    AdvancedToolsDialogComponent,
    LayerNameDialogComponent,
    DataExportDialogComponent,
    RasterBandDialogComponent,
    MatSlideToggleModule,
    SelectFormsSurveyorsDialogComponent,
    BuildingFilterDialogComponent,
    BuildingApprovalWarningComponent,
    MessageDialogComponent,
    NavDialogComponent,
    InputDialogComponent,
    BookmarkDialogComponent,
    PremiseFilterDialogComponent,
    MapExportDialogComponent,
    GroupLayerComponent,
    SettingsDialogComponent,
    ExportDialogComponent,
    UploadDialogComponent,
    DataApprovalErrorComponent,
    SurveyAnalyticsDialogComponent,
    DataApprovalEditDialogComponent,
    ProjectionInputDialogComponent,
    ProcessLayerDialogComponent,
    FeatureDetectionDialogComponent,
    SubscriptionUpgradeDialogComponent,
    SubscriptionErrorDialogComponent,
    PlanDetailsDialogComponent,
    InvoiceDialogComponent,
    InfocontactDialogComponent,
    ObjectPickerDialogComponent,
    CommonDialogComponent,
    ClipDialogComponent,
    SlopeDialogComponent,
    GradualPathDialogComponent,
    VisibilityCoverageDialogComponent,
    SpatialFilterDialogComponent,
    DataLibraryLayerDetailsDialog,
    DataLibraryFilterDialog,
    DataLibraryPreviewDialog,
    LayerSelectionDialog,
    FieldSelectionDialog,
    JoinResultDialog,
    JoinedLayerNameDialog,
    JoinDataSuccessDialog,
    SnapDialogComponent,
    BackgroundStylesComponent,
    MapDashboardDialogComponent,
    ColorPickerModule,
    YourDataPreviewComponent,
    DCApprovalFilterDialogComponent,
    ViewDataDialog,
    QuerySkeletonComponent,
    FilterDataToDisplayDialogComponent,
    AdvancedLabelDialogComponent,
    CustomAdvancedLabel,
    DefaultAdvancedLabel,
    ExpressionDialogComponent,
    PublishLayerDialogComponent,
    GraduatedClassificationDialog,
    ServiceAreaDialog,
    RegressionOLSDialog,
    RegressionSummaryDialog,
    DistanceToNearestFacilityDialog,
    SpatialJoinDialog,
    SimilaritySearch,
    SpatialJoinAddDialog,
    SpatialJoinSummaryDialog,
    CancelSubscriptionDialog,
    RestoreBuildingDialogComponent
  ],
  entryComponents:[RasterBandDialogComponent, ViewDataDialog],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  

})
export class CommonModule { }
