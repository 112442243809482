import { Inject, Component, QueryList, ViewChild, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GetProjectionSystemsStartAction } from 'src/app/store/actions/data-management.actions';
import { projectionsSelector } from 'src/app/store/selectors/data-management.selector';
import _ from 'lodash';

@Component({
  selector: 'projection-dialog',
  styleUrls: ['./projection-input-dialog.component.scss'],
  templateUrl: './projection-input-dialog.component.html'
})
export class ProjectionInputDialogComponent implements OnDestroy {
  projectionFormControl: FormControl = new FormControl('', [Validators.required]);
  selectedGField: FormControl = new FormControl(null);
  projections: any[] = [];

  @ViewChild('auto') projectionsAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('myProjectionInput') myProjectionInput: ElementRef;
  pageNumber: number = 1;
  totalPages: number = 2;
  responseFromProjectionCall: boolean = true;
  stopper$: Subject<any> = new Subject();
  loadMoreCalled: boolean = false;
  selectedProjection: any = {};
  showProjectionUpload: boolean = false;
  showUploadedProjectionFileName: boolean = false;
  showDataSetSelection: boolean = false;
  showGeometrySelection: boolean = false;
  projectionFile: File = null;
  projectionFileName: string = null;
  projectionFileUploaded: boolean = false;
  // selectedGField: string = null;
  removable: boolean = true;
  dataSetTypes = ['Point', 'LineString', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon'];
  selectedDataSetType: string = null;
  canUploadFile: boolean = false;
  constructor(public _dialogRef: MatDialogRef<ProjectionInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _store: Store<any>) {
    _dialogRef.disableClose = true;
  }
  ngOnDestroy() {
    this.stopper$.next('')
  }

  ngOnInit() {
    this.projectionFormControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
      if (val) {
        this.loadMoreCalled = true;
        this._store.dispatch(new GetProjectionSystemsStartAction({ search: val.trim(), page: 1, limit: 20 }));
      }
    })
    this.projections = [...this.data.projectionData];
    this.pageNumber = +this.data.projections.currentPage;
    this.totalPages = +this.data.projections.totalPages;
    if(this.data.type === "csvFile") {
      this.showProjectionUpload = true;
    }
    this._store.select(projectionsSelector).pipe(takeUntil(this.stopper$)).subscribe((resp: any) => {
      if (resp && this.loadMoreCalled) {
        this.pageNumber = +resp.currentPage;
        if (this.pageNumber === 1) {
          this.projections = _.cloneDeep(resp.data)
        } else {
          let data = _.cloneDeep(resp.data)
          this.projections = this.projections.concat(data);
        }
        setTimeout(() => this.loadMoreCalled = false);
        this.responseFromProjectionCall = true;
      }
    })

  }
  autocompleteScroll() {
    setTimeout(() => {
      if ((this.projectionsAutocompleteRef) && this.autocompleteTrigger) {
        if (this.projectionsAutocompleteRef.panel) {

          fromEvent(this.projectionsAutocompleteRef.panel.nativeElement, 'scroll').pipe(takeUntil(this.autocompleteTrigger.panelClosingActions)).subscribe((resp: any) => {

            const viewHeight = this.projectionsAutocompleteRef.panel.nativeElement
              .offsetHeight;
            const scrollHeight = this.projectionsAutocompleteRef.panel.nativeElement
              .scrollHeight;
            const scrollLocation = this.projectionsAutocompleteRef.panel.nativeElement
              .scrollTop;
            let limit = scrollHeight - viewHeight;
            if (scrollLocation >= limit - 10) {
              this.loadMore();
            }

          })
        }
      }
    }, 100)

  }
  loadMore() {
    let payloadData = {
      search: "",
      limit: 20,
      page: 1
    }
    if (this.responseFromProjectionCall) {
      this.loadMoreCalled = true;
      if (this.pageNumber < this.totalPages) {
        this.pageNumber++;
        payloadData.search = this.projectionFormControl.value;
        payloadData.page = this.pageNumber
        this._store.dispatch(new GetProjectionSystemsStartAction(payloadData));
        this.responseFromProjectionCall = false;
      }
    }

  }

  selectedOption(projection) {
    this.selectedProjection = projection;
    this.projectionFormControl.setValue(`${this.selectedProjection.epsg}: ${this.selectedProjection.name}`);
    if(this.data.type === "csvFile") {
      this.showDataSetSelection = true;
      this.projectionFileUploaded = false;
      this.showProjectionUpload = true;
      this.projectionFile = null;
      this.projectionFileName = null;
      this.showUploadedProjectionFileName = false;
      this.canUploadFile = this.setUploadBtnState();
    } else {
      this.canUploadFile = this.setUploadBtnState();
    }
  }
  onClose(resp) {
    if (!resp) {
      this._dialogRef.close('')
    } else {
      if(!this.canUploadFile) return;
      const response = {
        projection_id: this.selectedProjection ? this.selectedProjection._id : null,
        projection: this.projectionFile ? this.projectionFile : null,
        shape: this.selectedDataSetType,
        geometry: this.selectedGField.value
      }
      this._dialogRef.close(response);
    }
  }
  projectionUploaded(event) {
    this.showDataSetSelection = true;
    this.selectedProjection = null;
    this.projectionFormControl.setValue("");
    if(event.target.files && event.target.files.length) {
      this.projectionFile = event.target.files[0];
      this.projectionFileName = event.target.files[0].name;
      this.showUploadedProjectionFileName = true;
      this.showProjectionUpload = false;
      this.projectionFileUploaded = true;
      this.selectedDataSetType = null;
      this.showDataSetSelection = true;
      this.selectedGField.setValue(null);
      this.showGeometrySelection = false;
      this.canUploadFile = this.setUploadBtnState();
    }
  }

  removeProjectionFile() {
    this.projectionFile = null;
    this.projectionFileName = null;
    this.projectionFileUploaded = false;
    this.showUploadedProjectionFileName = false;
    this.showProjectionUpload = true;
    this.selectedDataSetType = null;
    this.showDataSetSelection = false;
    this.selectedGField.setValue(null);
    this.showGeometrySelection = false;
    this.canUploadFile = this.setUploadBtnState();
    this.myProjectionInput.nativeElement.value = null;
  }

  setSelectedDataSet(dataset) {
    this.selectedDataSetType = dataset;
    this.selectedGField.setValue(null);
    this.showGeometrySelection = true;
    this.canUploadFile = this.setUploadBtnState();
  }

  gFieldChanged(event) {
    this.selectedGField.setValue(event.value);
    this.canUploadFile = this.setUploadBtnState();
  }

  setUploadBtnState() {
    if(this.data.type === "csvFile") {
      return (!!this.selectedProjection || this.projectionFileUploaded) && !!this.selectedDataSetType && !!this.selectedGField.value;
    } else {
      return this.selectedProjection ? true : false;
    }
  }
}