import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { PALLETE_OBJ } from "src/app/app.constants";
import { activeSessionSelector } from "src/app/store/selectors/session-management.selector";
import { cloneDeep } from 'lodash'
import { SessionService } from "src/app/_services/session.service";

@Component({
  selector: "app-background-styles",
  templateUrl: "./background-styles-dialog.component.html",
  styleUrls: ["./background-styles-dialog.component.scss"]
})

export class BackgroundStylesComponent implements OnInit {
  palleteObj = PALLETE_OBJ;
  subscriptionsArr: Array<Subscription> = [];
  tempSessionDetails: any = null;
  sessionDetails: any = null;
  hasChanges = false;
  hex: any = null;
  constructor(
    private dialogRef: MatDialogRef<BackgroundStylesComponent>,
    private _store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.subscriptionsArr.push(
      this._store.select(activeSessionSelector).subscribe((session: any) => {
        this.sessionDetails = cloneDeep(session);
        this.tempSessionDetails = cloneDeep(session);
        this.tempSessionDetails.background_opacity *= 100;
      })
    );
  }

  applyTempStyles() {
    const bgColor = this.tempSessionDetails.background_color;
    const opac = +this.tempSessionDetails.background_opacity;
    const bgOpacity = +this.sessionService.toDecimalString((((opac * 10) / 10) / 100), 2);
    this.sessionService.map.setPaintProperty("background", 'background-color', bgColor);
    this.sessionService.map.setPaintProperty("background", 'background-opacity', bgOpacity);

  }

  applyStyles() {
    this.tempSessionDetails.background_opacity = +this.sessionService.toDecimalString((((this.tempSessionDetails.background_opacity * 10) / 10) / 100), 2);
    console.log("Map Styles")
    this.sessionService.updateSession(this.tempSessionDetails);
    this.close();
  }

  close(revert = false) {
    if(revert && this.hasChanges) this.revertAppliedStyles()
    this.dialogRef.close(false);
  }

  revertAppliedStyles() {
    const bgColor = this.sessionDetails.background_color;
    const opac = +this.sessionDetails.background_opacity;
    this.sessionService.map.setPaintProperty("background", 'background-color', bgColor);
    this.sessionService.map.setPaintProperty("background", 'background-opacity', opac);

    this.sessionService.afterMap.setPaintProperty("background", 'background-color', bgColor);
    this.sessionService.afterMap.setPaintProperty("background", 'background-opacity', opac);
  }
}