import {Action} from '@ngrx/store'

export enum dashboardactionTypes {
  getDashboardStart = "[DASHBOARD] Get Dashboard Start",
  getDashboardSuccess = "[DASHBOARD] Get Dashboard Success",
}




export class GetDashboardStartAction implements Action {
  readonly type = dashboardactionTypes.getDashboardStart;
  constructor() { }
}


export class GetDashboardSuccessAction implements Action {
  readonly type = dashboardactionTypes.getDashboardSuccess;
  data;
  constructor (response: any) {
    this.data = response;
  }
}


export type DashboardActions = GetDashboardSuccessAction;
