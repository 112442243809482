import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { FormControl, Validators } from '@angular/forms'
import { Storeservice } from 'src/app/_services/store.services'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { GetSessionDetailsStartAction } from 'src/app/store/actions/session-management.actions';
import { SessionService } from 'src/app/_services/session.service';

@Component({
  selector: 'input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})

export class InputDialogComponent {
  url = new FormControl('');
  name = new FormControl('');
  sessionId: string = '';
  type: string ='';
  selectedValue: string ='';
  constructor(
    private _store: Store<any>,
    public _dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public storeService: Storeservice,
    public router: Router,
    public toaster: ToastrService,
    public sessionService: SessionService
  ) { };
  ngOnInit() {

    this.sessionId = this.router.url.split('/').pop();
    this.type =this.data.type || 'wms/wfs'
    if(this.data.type === 'data-management') {
      this.name.setValue(`${this.data.formDetails.name}`)
      this.name.setValidators([Validators.required])
    }
  }

    getMoreInformation(): string {
      return ' 1.Only Https URLs are  allowed \n 2.Google map Urls are not allowed \n 3.Need to give z-x-y format in URL (eg: https://tile.opentopomap.org/{z}/{x}/{y}.png) ';
     }

  buttonClicked(resp) {
    if(this.type === 'wms/wfs') { 
      if (resp) {
        let payload = {
          'name': this.name.value,
          'url': this.url.value,
          "session_id": this.sessionId
        }
        if (this.name.value.trim().length && this.url.value.trim().length) {
          if(this.isALink(this.url.value.trim())) {
  
            this.addUrlAsLayer(payload)
          } else {
            this.toaster.error('Invalid URL');
          }
        }
        else {
          this.toaster.warning('Please provide a valid name and URL  to generate')
        }
      }
      else {
        this._dialogRef.close('')
      }
    } else if(this.type === 'data-management') {
      if(resp) {
       if(this.name.status === 'VALID') {
        if(this.name.value.trim().length) {
          this._dialogRef.close({name: this.name.value.trim(), selectedValue:this.selectedValue})
        } else {
          this.toaster.warning('Please provide a valid name')
        }
       }
      } else {
        this._dialogRef.close(false)
      }
    }
  }

  async addUrlAsLayer(payload) {
    let resp = await this.storeService.addUrlAsLayer(payload)
    resp.subscribe((response: any) => {
      if (response.status === 'success') {
        this.toaster.success('URL saved successfully');
        this._dialogRef.close('');
        this._store.dispatch(new GetSessionDetailsStartAction(this.sessionId));
        this.sessionService.setHideNewLayer(true);
      }
    })
  }

  isALink(data) {
    if (data && (data.toString().startsWith("https") || data.toString().startsWith("http"))) {
      return true
    } else {
      return false
    }
  }
  onChange(event) {
    this.selectedValue = event.value;
  }
}