import { SharedService } from './../_helpers/shared';
// import { animate } from '@angular/animations';
import { Injectable } from "@angular/core";
import { initZoomBox } from "../_conf/zoombox.conf";
// declare let L;
import * as turf from '@turf/turf';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { SessionService } from './session.service';
import { Storeservice } from './store.services';
import { Subject, fromEvent, Subscription, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { LayerService } from './layer.service';
import { Store } from '@ngrx/store';
import { GetGraphicsLayerDataStartAction } from '../store/actions/session-management.actions';
import { layerTableDataSelector, totalRecordsSelector, myUnapprovedEditedFeatruesSelector } from '../store/selectors/session-management.selector';
import { LegendCtrl } from './mapLegendsCtrl.service';
import { MatDialog } from '@angular/material';
import { InfoDialogComponent } from '../dialogs/info-dialog/info-dialog.component';
import { ADVANCED_TOOL_TYPE, paintObject, GEOMETRY_TYPES, MODE_OBJ, layoutObject, sourceObject, ploatLayerType, unapprovedLayersObject } from '../app.constants';
import { RASTER_TOOL_TYPE } from '../app.constants';
import { Router } from '@angular/router';

@Injectable()
export class NavigationCtrl {
  MagnifiedLayersAddOn: Array<any>;
  magnifyGlassLayers: Array<any>;
  activeTools: Array<any>;
  map: any;
  bounds: any;
  zoom_box: any;
  magnifyingGlass: any;
  identify: BehaviorSubject<boolean> = new BehaviorSubject(false);
  linemeasureActive = false;
  polygonemeasureActive = false;
  mapHistory: Array<any> = [];
  mapIndex = 0;
  mapFlag = true;
  measureDistance = '';
  geojson: any;
  linestring: any;
  pointsData: any;
  mapboxDrawObj: any;
  mapboxGeometryObj: any;
  mapboxGeoGeometryObj: any;
  mapboxMultipleGeometryObj: any;
  measureArea = '';
  lineMeasureFeature: boolean = true;
  feature: any = {};
  measureActive = false;
  identifyPopup:mapboxgl.Popup;
  selectedGraphicsLayerId: string = '';
  selectedGraphicsLayerType: string = '';
  selectedGraphicsType: string = '';
  stopper$: Subject<any> = new Subject();
  pointMeasureSelection: boolean;
  linemeasureSelection: boolean;
  polygonMeasureSelction: boolean;
  onlyLineDraw: boolean;
  pointsInLineSelection: any = [];
  pointPlotSubscription: Subscription;
  polylinePlotSubscription: Subscription;
  onlyPolygonDraw: boolean;
  totalRecordsCount: number;
  linePlotSubscription: any;
  editVectorAttribute = false;
  geometryCoordinates = [];
  editedGeometryCoordinates: BehaviorSubject<any> = new BehaviorSubject([]);
  editedGeometryType: BehaviorSubject<any> = new BehaviorSubject("");
  showAddNewVectorProperties: BehaviorSubject<boolean> = new BehaviorSubject(false);
  addNewGeometry: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showLayerTable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  addGeometryActive = false;
  advancedToolsGraphicsSelectionActive = false;
  showAdvancedToolsSelectionData = false;
  searchActive = false;
  advancedToolsActive = false;
  multipleGeometryCreationSubscriptions: Subscription = null;
  drawGeometrySubscriptionArr: Array<Subscription> = [];
  addMoreClicked = false;
  selectedAdvancedToolNumber: number = null;
  advancedToolsSelectedCoords: string = null;
  selectedTool: Subject<any> =new Subject();
  selectedMeasureToolUnitObs: BehaviorSubject<string> = new BehaviorSubject('km');
  measureToolUnit: string = 'km';
  selectedVectorDataId: string = null;
  constructor(
    private sharedService: SharedService,
    private sessionService: SessionService,
    private _helper: Storeservice,
    private legendService: LegendCtrl,
    private _store: Store<any>,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.MagnifiedLayersAddOn = [];
    this.magnifyGlassLayers = [];
    this.activeTools = [];
    this.sessionService.getEditVectorAttribute().subscribe(value => {
      this.editVectorAttribute = value;
    })
  }

  getSelectedMeasureToolUnit(): Observable<string> {
    return this.selectedMeasureToolUnitObs.asObservable();
  }

  setSelectedMeasureToolUnit(str) {
    this.measureToolUnit = str;
    this.selectedMeasureToolUnitObs.next(str);
  }
  setInstance(mapRef, boundsRef) {
    this.map = mapRef;
    this.bounds = boundsRef;
    this.zoom_box = initZoomBox(mapRef);
    this.zoom_box = initZoomBox(mapRef);
    this.removeDrawLayersForPolygon();
    this.removeDrawSourceForPolygon();
    this.mapboxDrawObj = undefined;
    this.pointPlotSubscription = null;
    this.polylinePlotSubscription = null;
    this.linePlotSubscription = null

  }

  initNavigationCtrl(mapRef, boundsRef) {
    this.map = mapRef;
    this.bounds = boundsRef;
    /* initializing zoom box */
    this.zoom_box = initZoomBox(mapRef);
    this.removeDrawLayersForPolygon();
    this.removeDrawSourceForPolygon();
    this.mapboxDrawObj = undefined;
    this.pointPlotSubscription = null;
    this.polylinePlotSubscription = null;
    this.linePlotSubscription = null

    // const mapHistory = new L.HistoryControl({
    //     useExternalControls: true
    // }).addTo(this.map);
    this.sessionService.getAddNewGeometryActive().subscribe(value => this.addGeometryActive = value);
    this.sessionService.getShowAdvancedToolsSelectionData().subscribe(value => this.showAdvancedToolsSelectionData = value);
    this.sessionService.getAdvancedToolsActive().subscribe(value => this.advancedToolsActive = value);
    this.sessionService.getAdvancedToolsGraphicsSelectionActive().subscribe(value => this.advancedToolsGraphicsSelectionActive = value);
    this.sessionService.getMultiGraphicsLayerCreationActive().subscribe(value => {this.advancedToolsGraphicsSelectionActive = value});
    this._store.select(totalRecordsSelector).subscribe(count => this.totalRecordsCount = count);

    this.map.on('movestart', this.onMapMove.bind(this));
    this.sessionService.getGraphicsSearchActive().subscribe(val => this.searchActive = val)
    this.sessionService.getSelectedVectorDataId().subscribe(id => this.selectedVectorDataId = id);
    this._store.select(layerTableDataSelector).subscribe((response: any) => {
      if(response.length) this.plotLayerTableFeatures(response);
    })
    this._store.select(myUnapprovedEditedFeatruesSelector).subscribe(data => {
      if (data.length && data.length <= 100) {
        this.feature = {
          "type": "FeatureCollection",  
          "features": []
        }
        data.forEach(feature => {
          this.feature.features.push(feature);
        })
        let source = '';
        let type = '';
        if (this.sessionService.selectedGeometryLayerId && this.sessionService.selectedGraphicsLayerType) {
          source = `${this.sessionService.selectedGeometryLayerId}Unapproved`;
          type = `${this.sessionService.selectedGraphicsLayerType}`;
        } else {
          return;
        }

        if (this.map.getSource(source)) {

          this.map.getSource(source).setData(this.feature);
        } else {
          this.map.addSource(source, {
            type: 'geojson',
            data: this.feature,
            tolerance: 3
          })
          this.map.addLayer({
            id: unapprovedLayersObject[type],
            type: ploatLayerType[type],
            source: source,
            paint: paintObject[type],
            layout: layoutObject[type]
          });
          if ((type == 'polygon') || (type == "multipolygon")) {
            this.map.addLayer({
              id: unapprovedLayersObject['polyline'],
              type: 'line',
              source: source,
              paint: paintObject.line,
              layout: layoutObject[type]
            });
          }
          this.legendService.renderLineMeasure();
        }
        this.legendService.fitFeaturesToBoundingBox(data, this.sessionService.selectedGraphicsLayerType);
      } else {
        // zoom and set the layer fit to bound
        this.legendService.showBoundBox(null, 'graphicSelected')
      }
    })
    this.sessionService.getSelectedAdvancedToolType().subscribe(tool_num => this.selectedAdvancedToolNumber = tool_num);
    this.sessionService.getAdvancedToolsDrawnCoordinates().subscribe(coords => this.advancedToolsSelectedCoords = coords);
  }

  plotLayerTableFeatures(response = []) {
    if(!response.length) {
      this._store.select(layerTableDataSelector).subscribe((result: any) => {
        this.plotFeatures(result);
      })
    } else {
      this.plotFeatures(response);
    }
  }

  plotFeatures(response) {
    if(this.sessionService.tableJoinClicked) return;
    if(!this.showLayerTable.getValue()) return;
    if (response.length) {
      this.feature = {
        "type": "FeatureCollection",
        "features": []
      }
      response.forEach(feature => {
        this.feature.features.push(feature);
      })
      let source = '';
      let type = '';
      if(this.advancedToolsActive) {
        source = `AdvancedToolSearchResults`
        type = this.feature.features[0].geometry.type.toLowerCase()
      } else {
        if (this.selectedGraphicsLayerId && this.selectedGraphicsLayerType) {
          source = `${this.selectedGraphicsLayerId}Graphics`;
          type = `${this.selectedGraphicsLayerType}`;
        } else if (this.sessionService.selectedQueryLayerId && this.sessionService.selectedQueryLayerType) {
          source = `${this.sessionService.selectedQueryLayerId}Query`
          type = `${this.sessionService.selectedQueryLayerType}`
        } else {
          return;
        }
      }
      if (this.map.getSource(source)) {

        this.map.getSource(source).setData(this.feature);
      } else {
        // if (this.totalRecordsCount <= 40) {
          this.map.addSource(source, {
            type: 'geojson',
            data: this.feature,
            tolerance: 3
          })
          this.map.addLayer({
            id: sourceObject[type],
            type: ploatLayerType[type],
            source: source,
            paint: paintObject[type],
            layout: layoutObject[type]
          });
          if ((type == 'polygon') || (type == "multipolygon")) {
            this.map.addLayer({
              id: sourceObject['polyline'],
              type: 'line',
              source: source,
              paint: paintObject.line,
              layout: layoutObject[type]
            });
          }
          this.legendService.renderLineMeasure();
        // }
        // else {
        //   //do the changes here.
        // }
      }
      if (this.sessionService.selectedGeometryLayerId && this.searchActive) {
        this.sessionService.setShowGraphicsLayerData(true);
      } else if (this.sessionService.selectedQueryLayerId && this.searchActive) {
        this.sessionService.setShowQueryData(true);
      }
    }
  }

  onMapMove() {

    if (this.mapFlag) {
      const temp = {
        bounds: "",
        zoom: ""
      };
      const bounds = this.map.getBounds();
      const zoom = this.map.getZoom();
      temp.bounds = bounds;
      temp.zoom = zoom;
      this.mapHistory.push(temp);
      this.mapIndex = this.mapHistory.length - 1;
    }
    // else {
    //     this.mapFlag = true;
    // }

  }

  activeNavigationTools(activeTool: string) {
    if (this.activeTools.length === 0) {
      this.activeTools.push(activeTool);
      this[this.activeTools[0]] = true;
    } else {
      const found = this.activeTools.find(o => o === activeTool);
      if (found === undefined) {
        this[this.activeTools[0]] = false;
        this.activeTools = [];
        this.activeTools.push(activeTool);
        this[this.activeTools[0]] = true;
      } else {
        this[this.activeTools[0]] = false;
        this.activeTools = [];
      }
    }
  }

  zoomIn() {
    if(this.advancedToolsGraphicsSelectionActive) return;
    this.mapFlag = true
    // if (this.map.hasLayer(this.magnifyingGlass)) {
    //     this.map.removeLayer(this.magnifyingGlass);
    // }

    // if (this.activeTools[0] === 'zoomInActive') {
    //     this.zoom_box.deactivate();
    //     this.activeNavigationTools('zoomInActive');
    //     return false;
    // } else {
    //     this.zoom_box.activate();
    //     this.activeNavigationTools('zoomInActive');
    // }
    if (this.activeTools[0] === 'zoomInActive') {
      this.zoom_box.deactivate();
      this.activeNavigationTools('zoomInActive');
    }



    this.map.setZoom(this.map.getZoom() + 1, { animate: true });

  }

  zoomOut() {
    if(this.advancedToolsGraphicsSelectionActive) return;
    this.mapFlag = true

    if (this.activeTools[0] === 'zoomInActive') {
      this.zoom_box.deactivate();
      this.activeNavigationTools('zoomInActive');
    }
    this.map.setZoom(this.map.getZoom() - 1, { animate: true });
  }

  goBack() {
    if(this.advancedToolsGraphicsSelectionActive) return;
    if (this.activeTools[0] === 'zoomInActive') {
      this.zoom_box.deactivate();
      this.activeNavigationTools('zoomInActive');
    }
    this.mapFlag = false;
    --this.mapIndex;
    const lat = (this.mapHistory[this.mapIndex].bounds._ne.lat + this.mapHistory[this.mapIndex].bounds._sw.lat) / 2
    const lng = (this.mapHistory[this.mapIndex].bounds._ne.lng + this.mapHistory[this.mapIndex].bounds._sw.lng) / 2
    const zoom = this.mapHistory[this.mapIndex].zoom;
    this.map.setZoom(zoom);
    this.map.panTo([lng, lat], { animate: true });
  }

  goAhead() {
    if(this.advancedToolsGraphicsSelectionActive) return;
    ++this.mapIndex;
    if (this.activeTools[0] === 'zoomInActive') {
      this.zoom_box.deactivate();
      this.activeNavigationTools('zoomInActive');
    }
    this.mapFlag = false
    const lat = (this.mapHistory[this.mapIndex].bounds._ne.lat + this.mapHistory[this.mapIndex].bounds._sw.lat) / 2
    const lng = (this.mapHistory[this.mapIndex].bounds._ne.lng + this.mapHistory[this.mapIndex].bounds._sw.lng) / 2
    const zoom = this.mapHistory[this.mapIndex].zoom;
    this.map.setZoom(zoom);
    this.map.panTo([lng, lat], { animate: true });
  }
  getIdentifyActiveValue(){
    return this.identify.asObservable();
  }
  setIdentifyActiveValue(value) {
    if(!value && this.identifyPopup && this.identifyPopup.isOpen()) {
      this.identifyPopup.remove();
      this.identifyPopup = null;
    }
    this.identify.next(value);
  }
  defaultPaneCursor() {
    if(this.advancedToolsGraphicsSelectionActive) return;
    this.sessionService.selectedGeometryIndex = null;
    this.sessionService.selectedGeometryType = null;
    this.sessionService.selectedCoordinates = '';
    // this.sessionService.setSelectedCoordinatesEmptied(true);
    this.polygonemeasureActive = false;
    this.linemeasureActive = false;
    this.measureDistance = '';
    this.measureArea = '';
    this.identify.next(false);
    this.sessionService.measureActive = false;
    this.setSelectedMeasureToolUnit('km');
    if (this.identifyPopup && this.identifyPopup.isOpen()) {
      this.identifyPopup.remove();
      this.identifyPopup = null;
    }
  }
  isIdenfify() {
    if(this.advancedToolsGraphicsSelectionActive) return;
    this.polygonemeasureActive = false;
    this.linemeasureActive = false;
    this.measureDistance = '';
    this.measureArea = '';
    if(!this.identify.getValue()) this.defaultPaneCursor();
    this.identify.next(!this.identify.getValue());
    if(!this.identify.getValue() && this.identifyPopup) {
      this.identifyPopup.remove();
      this.identifyPopup = null;
    }
    this.closeAllMeasure('both');
    this.closeMeasureSelection('all');
    // this.sessionService.setShowGraphicsLayerData(false);
    // this.sessionService.setShowQueryData(false);
    // this.sessionService.setShowEdittedFeatures(false);
    this.sessionService.setSelectedVectorDataId('');
    this.sessionService.setShowEdittedFeatureComparator(false);
    this.sessionService.setCollectedOrAttributeEdit(null);
    this.sessionService.setIsSwipeOpened(false);
  }


  // showBoundBox(coord = null, layer?: any) {
  //   // ENABLE THIS AFTER API is READY WITH BBOX for layer.
  //   // console.log(this.sessionService.selectedLayers)
  //   let finalBBox = new mapboxgl.LngLatBounds();
  //   if (coord) {
  //     if (Object.keys(coord).length > 0) {
  //       const sw = new mapboxgl.LngLat(coord.xMin, coord.yMin);
  //       const ne = new mapboxgl.LngLat(coord.xMax, coord.yMax);
  //       const newBounds = new mapboxgl.LngLatBounds(sw, ne);
  //       finalBBox.extend(newBounds);
  //     }
  //   } else if (layer === "specificLayer") {
  //     let selectedLayer = this.sessionService.selectedLayers[0];
  //     if (Object.keys(selectedLayer).length > 0) {
  //       let boundingBoxForSelectedLayer = selectedLayer.bounding_box;
  //       const sw = new mapboxgl.LngLat(boundingBoxForSelectedLayer[0], boundingBoxForSelectedLayer[1])
  //       const ne = new mapboxgl.LngLat(boundingBoxForSelectedLayer[2], boundingBoxForSelectedLayer[3])
  //       const newBounds = new mapboxgl.LngLatBounds(sw, ne);

  //       finalBBox.extend(newBounds)
  //     }
  //   }

  //   else {
  //     this.sharedService.mapLayers.forEach(layer => {
  //       if (!layer.show) {
  //         return;
  //       }
  //       if (layer.bounding_box && layer.bounding_box.length > 0) {
  //         const sw = new mapboxgl.LngLat(layer.bounding_box[0], layer.bounding_box[1]);
  //         const ne = new mapboxgl.LngLat(layer.bounding_box[2], layer.bounding_box[3]);
  //         const newBounds = new mapboxgl.LngLatBounds(sw, ne);
  //         finalBBox.extend(newBounds);
  //       }
  //     });
  //   }

  //   if (Object.keys(finalBBox).length > 0) {
  //     // this.map.fitBounds(finalBBox, { padding: { 'bottom': 20, 'left': 350, 'right': 0, 'top': 20 } });
  //     this.map.fitBounds(finalBBox)
  //     // let point = this.map.getCenter();
  //     // point.lng = point.lng+20;
  //     // this.map.flyTo(point);

  //   }
  // }

  lineMeasure(onlyDraw?) {
    this.selectedTool.next('polyline')
    if (!onlyDraw) {
      this.sessionService.selectedGeometryIndex = null
      this.closeMeasureSelection('all');
      // this.linemeasureActive = true;
      this.polygonemeasureActive = false;
      this.sessionService.selectedGeometryLayerId = '';
      this.sessionService.selectedGraphicsLayerType = '';
      this.sessionService.selectedGeometryType = '';
      this.sessionService.selectedCoordinates = '';
      this.sessionService.selectedQueryLayerId = '';
      this.sessionService.selectedQueryLayerType = '';
      this.sessionService.setShowEdittedFeatures(false);
      this.sessionService.setSelectedVectorDataId('');
      this.sessionService.setShowEdittedFeatureComparator(false);
      this.sessionService.setCollectedOrAttributeEdit(null);
      this.selectedGraphicsLayerId = '';
      this.selectedGraphicsLayerType = '';
      this.selectedGraphicsType = '';
    }
    this.measureDistance = '';
    this.identify.next(false);
    this.measureActive = true;
    this.linemeasureActive = true;
    // this.removeDrawLayersForPolygon();
    // this.removeDrawSourceForPolygon();
    // this.removeLineMeasureLayers();

    this.closeAllMeasure('polygon')
    this.geojson = {
      "type": "FeatureCollection",
      "features": []
    };

    // Used to draw a line between points
    this.linestring = {
      "type": "Feature",
      "geometry": {
        "type": "LineString",
        "coordinates": []
      },
      "properties": {
        "id": String(new Date().getTime())
      }
    };
    if (!this.map.getSource('lineMeasureSource')) {
      this.map.addSource('lineMeasureSource', {
        "type": "geojson",
        "data": this.geojson,
        tolerance: 3
      });

      // Add styles to the map
      this.map.addLayer({
        id: 'measure-points',
        type: 'circle',
        source: 'lineMeasureSource',
        paint: {
          'circle-radius': 5,
          'circle-color': '#FFF'
        },
        filter: ['in', '$type', 'Point']
      });
      this.map.addLayer({
        id: 'measure-inner-points',
        type: 'circle',
        source: 'lineMeasureSource',
        "filter": ['in', '$type', 'Point'],
        "paint": {
          "circle-radius": 3,
          "circle-color": "#D20C0C",
        }

      })
      this.map.addLayer({
        id: 'measure-lines',
        type: 'line',
        source: 'lineMeasureSource',
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#D20C0C",
          "line-dasharray": [0.2, 2],
          "line-width": 2
        },
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
      }, 'measure-points');
      if (!onlyDraw) {


        this.map.addLayer({
          id: 'measure-lines-text',
          type: 'symbol',
          source: 'lineMeasureSource',
          layout: {
            'text-field': ['get', 'distance'],
            'text-font': ['literal' ,['Open Sans Regular', "Open Sans Regular"]],
            'text-anchor': 'left',
            'text-size': 9,
            'text-offset': [2.5, -1.5]
          },
          paint: {
            'text-color': '#000',
            'text-opacity': 1
          }
        }, 'measure-lines')
      }
    }
    if (onlyDraw) {
      this.onlyLineDraw = true;
    }
    else {
      this.onlyLineDraw = false;
    }
    if (this.linePlotSubscription) return;
    this.linePlotSubscription = this.map.on('click', (e) => {
      if (this.sessionService.selectedGeometryType === "polyline" || !this.onlyLineDraw) {

        let clicked;
        let iTimeOut;
        if (clicked) {
          clicked = false;
          clearTimeout(iTimeOut);
        } else {
          clicked = true;
          iTimeOut = setTimeout(() => {
            this.addPoints(e);
          }, 100)
        }
      }
    });
    if (this.polylinePlotSubscription) return;
    this.polylinePlotSubscription = this.map.on('dblclick', () => {

      this.map.doubleClickZoom.disable();
      this.lineMeasureFeature = false;
      if (this.onlyLineDraw && this.sessionService.selectedGeometryType === "polyline") {
        this.polylineLayerGraphics()
      }

    })
      ;
  }
  polygonMeasure(onlyDraw?) {
    this.selectedTool.next('polygon')
    if (!onlyDraw) {
      this.polygonemeasureActive = true;
      this.linemeasureActive = false;
      this.closeMeasureSelection('all');
      this.sessionService.selectedGeometryLayerId = '';
      this.sessionService.selectedGraphicsLayerType = '';
      this.sessionService.selectedGeometryType = '';
      this.sessionService.selectedCoordinates = '';
      this.sessionService.selectedQueryLayerId = '';
      this.sessionService.selectedQueryLayerType = '';
      this.selectedGraphicsLayerId = '';
      this.selectedGraphicsLayerType = '';
      this.selectedGraphicsType = '';
    }

    this.measureArea = '';
    this.measureDistance = '';
    this.identify.next(false);
    this.measureActive = true;
    // this.closeAllMeasure('polyline')
    // // this.removeDrawLayersAndSource();
    // this.removeDrawLayersForPolyline();
    // this.removeDrawSourceForPolyline();
    this.closeMeasureSelection('all');

    try {
      if (this.mapboxDrawObj) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Error removing draw object. ", error);
    }
    try {
      if (this.mapboxGeoGeometryObj) this.map.removeControl(this.mapboxGeoGeometryObj);
    } catch (error) {
      console.log("Error removing geometry draw object. ", error);
    }
    try {
      if (this.mapboxGeometryObj) this.map.removeControl(this.mapboxGeometryObj);
    } catch (error) {
      console.log("Error removing geometry draw object. ", error);
    }
    this.mapboxDrawObj = new MapboxDraw({
      displayControlsDefault: false,
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          "id": "gl-draw-line",
          "type": "line",
          "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 2
          }
        },
        // polygon fill
        {
          "id": "gl-draw-polygon-fill",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          "paint": {
            "fill-color": "#D20C0C",
            "fill-outline-color": "#D20C0C",
            "fill-opacity": 0.1
          }
        },
        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
          "id": "gl-draw-polygon-stroke-active",
          "type": "line",
          "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#D20C0C",
            "line-dasharray": [0.2, 2],
            "line-width": 2
          }
        },
        // vertex point halos
        {
          "id": "gl-draw-polygon-and-line-vertex-halo-active",
          "type": "circle",
          "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
          "paint": {
            "circle-radius": 5,
            "circle-color": "#FFF"
          }
        },
        // vertex points
        {
          "id": "gl-draw-polygon-and-line-vertex-active",
          "type": "circle",
          "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
          "paint": {
            "circle-radius": 3,
            "circle-color": "#D20C0C",
          }
        },

        // INACTIVE (static, already drawn)
        // line stroke
        {
          "id": "gl-draw-line-static",
          "type": "line",
          "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#000",
            "line-width": 3
          }
        },
        // polygon fill
        {
          "id": "gl-draw-polygon-fill-static",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
          "paint": {
            "fill-color": "#000",
            "fill-outline-color": "#000",
            "fill-opacity": 0.1
          }
        },
        // polygon outline
        {
          "id": "gl-draw-polygon-stroke-static",
          "type": "line",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
          "layout": {
            "line-cap": "round",
            "line-join": "round"
          },
          "paint": {
            "line-color": "#000",
            "line-width": 3
          }
        }
      ]


    });
    try {
      this.map.addControl(this.mapboxDrawObj, 'top-left');
    } catch (error) {
      console.log("Could not add control because it already exists. ", error);      
    }
    this.mapboxDrawObj.changeMode('draw_polygon');
    if (onlyDraw) {
      this.onlyPolygonDraw = true;
    }
    else {
      this.onlyPolygonDraw = false;
    }
    if (!onlyDraw) {

      this.map.on('draw.create', this.updateArea);
      this.map.on('draw.delete', this.updateArea);
      this.map.on('draw.update', this.updateArea);
    }
    else {
      this.map.on('draw.create', this.getBounds);
      this.map.on('draw.delete', this.getBounds);
      this.map.on('draw.update', this.getBounds);

    }
    // this.mapboxDrawObj.add({ type: 'Point', coordinates: [0, 0] })
    // this.mapboxDrawObj.set({
    //   type: 'FeatureCollection',
    //   features: [{
    //     type: 'feature',
    //     properties: {},
    //     id: 'example-id',
    //     geometry: { type: 'Point', coordinates: [0, 0] }
    //   }]
    // })
    fromEvent(this.map, 'mousemove').pipe(debounceTime(500)).subscribe((_) => {

      console.log("May 25 map ended")
      console.log(this.map.getStyle().sources, "sources")
    })
  
  }
  updateArea = (e) => {
    const data = this.mapboxDrawObj.getAll();
    let area = turf.area(data);
    if(this.measureToolUnit === 'km') {
      area /= 1000000;
      this.measureArea = "Area (in sq.km) :" + this.sessionService.toDecimalString(area, 3)+ ' sq. km';
    } else {
      this.measureArea = "Area (in sq.m) :" + this.sessionService.toDecimalString(area, 3)+ ' sq. m';
    }
    console.log(this.map.getStyle().sources, "sources")

  }
  getBounds = (a) => {
    let coords = a.features[0].geometry.coordinates[0];
    if(this.advancedToolsGraphicsSelectionActive) {
      this.sessionService.setAdvancedToolsDrawnCoordinates(JSON.stringify(coords));
    } else {
      this.sessionService.selectedCoordinates = JSON.stringify(coords);
    }
    // this.sessionService.setSelectedCoordinatesEmptied(false);
    let graphicLayerId = this.sessionService.selectedGeometryLayerId;
    let type = 'polygon'
    let coordinates = JSON.stringify(coords);
    let dataObj = {
      layer: graphicLayerId,
      coordinates: coordinates,
      type: type,
      page: 1,
      limit: 40
    }
    if(!this.advancedToolsGraphicsSelectionActive) this._store.dispatch(new GetGraphicsLayerDataStartAction(dataObj));
  }
  polylineLayerGraphics() {
    if (!this.pointsInLineSelection.length) return;
    const coords = Object.assign([], this.pointsInLineSelection);
    let type = 'polyline';
    let graphicLayerId = this.sessionService.selectedGeometryLayerId;
    let coordinates = JSON.stringify(coords);
    if(this.advancedToolsGraphicsSelectionActive) {
      this.sessionService.setAdvancedToolsDrawnCoordinates(coords);
    } else {
      this.sessionService.selectedCoordinates = coords;
    }
    // this.sessionService.setSelectedCoordinatesEmptied(false);
    let dataObj = {
      layer: graphicLayerId,
      coordinates: coordinates,
      type: type,
      page: 1,
      limit: 40
    }

    if(!this.advancedToolsGraphicsSelectionActive) this._store.dispatch(new GetGraphicsLayerDataStartAction(dataObj));
    this.pointsInLineSelection = [];
  }



  addPoints = (e) => {


    if (!this.lineMeasureFeature || !this.linemeasureActive) {
      return;
    }


    var features = this.map.queryRenderedFeatures(e.point, { layers: ['measure-points'] });

    // Remove the linestring from the group
    // So we can redraw it based on the points collection
    if (this.geojson.features.length > 1) {
      this.geojson.features.pop();
    }

    // Clear the Distance container to populate it with a new value
    // distanceContainer.innerHTML = '';

    // If a feature was clicked, remove it from the map
    if (features.length) {
      var id = features[0].properties.id;
      this.geojson.features = this.geojson.features.filter(function (point) {
        return point.properties.id !== id;
      });
    } else {
      var point = {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [e.lngLat.lng, e.lngLat.lat]
        },
        "properties": {
          "id": String(new Date().getTime()),
          "distance": ''
        }
      };
      if (this.onlyLineDraw) {

        this.pointsInLineSelection.push([e.lngLat.lng, e.lngLat.lat]);
      }
      this.geojson.features.push(point);
    }
    if (!this.onlyLineDraw) {

      this.geojson.features.forEach((point, index) => {
        point.properties.distance = '';
        if (index > 0) {
          const lastPoint = this.geojson.features[index - 1];
          const distance = turf.distance(lastPoint.geometry.coordinates, point.geometry.coordinates)
          if (distance > 0) {
            const finalVal = this.sessionService.toDecimalString(distance, 3);
            const finalStr = finalVal + ' KM';
            point.properties.distance = finalStr;
          }
        }
      });
    }
    if (this.geojson.features.length > 1) {


      this.linestring.geometry.coordinates = this.geojson.features.map(function (point) {
        return point.geometry.coordinates;
      });




      this.geojson.features.push(this.linestring);
      // Populate the distanceContainer with total distance
      // const measureTotDistance = turf.lineDistance(this.linestring).toLocaleString() + ' KM';
      if (!this.onlyLineDraw) {

        const distance = turf.lineDistance(this.linestring);
        if(this.measureToolUnit === 'km') {
          const measureTotDistance = this.sessionService.toDecimalString(distance, 3) + ' km';
          this.measureDistance = 'Length (in "km") :' + measureTotDistance;
        } else {
          const measureTotDistance = this.sessionService.toDecimalString(distance * 1000, 3) + ' m';
          this.measureDistance = 'Length (in "m") :' + measureTotDistance;
        }
      }



    } else {
      if (!this.onlyLineDraw) {
        this.measureDistance = '';
      }

    }
    if (this.map.getSource('lineMeasureSource')) {

      this.map.getSource('lineMeasureSource').setData(this.geojson);
    }
  }


  moveMeasureLayersToTop() {
    const val = this.map.getStyle().layers;
  }
  removeDrawLayersForPolygon() {
    var layers = this.map.getStyle().layers;
    var layerIds = layers.filter(layer => (layer.source == 'mapbox-gl-draw-hot'));
    layerIds.forEach(layer => {
      this.map.removeLayer(layer.id);
    });
    layerIds = layers.filter(layer => (layer.source == 'mapbox-gl-draw-cold'));
    layerIds.forEach(layer => {
      this.map.removeLayer(layer.id);
    });

    if (this.mapboxDrawObj) {
      try {
        this.mapboxDrawObj.deleteAll();
      } catch (error) {
        console.log("Draw object delete all layers. ", error);
      }
    }
    if (this.mapboxGeometryObj) {
      try {
        this.mapboxGeometryObj.deleteAll();
      } catch (error) {
        console.log("Draw object delete all layers. ", error);
      }
    }
    this.measureArea = '';

    this.map.off('draw.create', this.updateArea);
    this.map.off('draw.delete', this.updateArea);
    this.map.off('draw.update', this.updateArea);
    this.map.off('draw.create', this.getBounds);
    this.map.off('draw.delete', this.getBounds);
    this.map.off('draw.update', this.getBounds);
  }
  removeDrawSourceForPolygon() {
    if (this.map.getSource('mapbox-gl-draw-hot')) {
      try {
        this.map.removeSource('mapbox-gl-draw-hot');
        this.map.removeSource('mapbox-gl-draw-cold');
      }
      catch (err) {
        // do nothing
      }

    }

  }
  removeDrawLayersForPolyline() {

    this.lineMeasureFeature = true;
    if (this.map.getSource('lineMeasureSource')) {
      try {
        if (this.map.getLayer('measure-points')) {

          this.map.removeLayer('measure-points');
        }
        if (this.map.getLayer('measure-lines')) {

          this.map.removeLayer('measure-lines');
        }
        if (this.map.getLayer('measure-lines-text')) {

          this.map.removeLayer('measure-lines-text');
        }
        if (this.map.getLayer('measure-inner-points')) {

          this.map.removeLayer('measure-inner-points')
        }

      }
      catch (err) {
        // nothing to do here
      }

    }
    this.measureDistance = '';
    this.map.off('click', this.addPoints);
  }
  removeDrawSourceForPolyline() {
    if (this.map.getSource('lineMeasureSource')) {
      this.map.removeSource('lineMeasureSource');
    }


  }
  removeDrawLayerForPoint() {
    if (this.map.getSource('pointMeasureSource')) {
      if (this.map.getLayer('map-points')) {
        this.map.removeLayer('map-points');
      }
    }
    this.map.off('click', this.plotPoint);
    this.map.off('click', this.plotMultiplePoints);
  }
  removeDrawSourceForPoint() {
    if (this.map.getSource('pointMeasureSource')) {
      this.map.removeSource('pointMeasureSource')
    }



  }
  closeAllMeasure(type) {
    this.lineMeasureFeature = true;

    if (type == 'polyline') {
      this.closeMeasureSelection('all');
      this.removeDrawLayersForPolyline();
      this.removeDrawSourceForPolyline();
      this.linemeasureActive = false;
    }

    else if (type == 'polygon') {
      this.closeMeasureSelection('all');

      this.removeDrawLayersForPolygon();
      this.removeDrawSourceForPolygon();
      this.polygonemeasureActive = false;
    }
    else if (type === 'both') {
      this.closeMeasureSelection('all');

      this.removeDrawLayersForPolyline();
      this.removeDrawLayersForPolygon();
      this.removeDrawSourceForPolygon();



      this.linemeasureActive = false;
      this.polygonemeasureActive = false;

    }

  }
  closeMeasureSelection(type) {
    if (type == "point") {
      // this.closeAllMeasure('both')
      this.removeDrawLayerForPoint();
      this.removeDrawSourceForPoint();
      this.removeGraphicContent();


    }
    else if (type == "polyline") {
      this.removeDrawLayersForPolyline();
      this.removeDrawSourceForPolyline();

      // this.closeAllMeasure('both')
      this.removeGraphicContent();

    }
    else if (type == "polygon") {
      // this.closeAllMeasure('both')
      this.removeDrawLayersForPolygon();
      this.removeDrawSourceForPolygon();

      this.removeGraphicContent();


    }
    else if (type == "all") {
      this.removeDrawLayerForPoint();
      this.removeDrawLayersForPolyline();
      this.removeDrawLayersForPolygon();
      this.removeDrawSourceForPolygon();
      this.removeDrawSourceForPolyline();
      this.removeDrawSourceForPoint();
      this.removeGraphicContent();
      this.removeGeometrySources();
    }

  }
  removeGraphicContent() {
    let source = '';
    if (this.advancedToolsActive) {
      source = "AdvancedToolSearchResults"
      this.removePlottedSource(source);
    } 
    if (this.selectedGraphicsLayerId) {
      source = `${this.selectedGraphicsLayerId}Graphics`;
      this.removePlottedSource(source);
    } else if (this.sessionService.selectedQueryLayerId) {
      source = `${this.sessionService.selectedQueryLayerId}Query`
      this.removePlottedSource(source);
    }
  }
  removePlottedUnapprovedFeatures() {
    let source = '';
    if (this.sessionService.selectedGeometryLayerId) {
      source = `${this.sessionService.selectedGeometryLayerId}Unapproved`
    }
    this.removePlottedSource(source);
  }
  
  removePlottedSource(source) {
    let layers = this.map.getStyle().layers;
    if (this.map.getSource(source)) {
      layers.forEach(layer => {
        if (layer.source === source) {
          this.map.removeLayer(layer.id);
        }
      })
      this.map.removeSource(source);
    }
  }

  pointMeasure() {
    this.geojson = {
      "type": "FeatureCollection",
      "features": []
    };

    // Used to draw a line between points
    this.pointsData = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": []
      },
      "properties": {
        "id": String(new Date().getTime())
      }
    };
    if (!this.map.getSource('pointMeasureSource')) {
      this.map.addSource('pointMeasureSource', {
        "type": "geojson",
        "data": this.geojson
      });

      // Add styles to the map
      this.map.addLayer({
        id: 'map-points',
        type: 'symbol',
        source: 'pointMeasureSource',
        paint: {
          'icon-color': '#FF0000'
        },
        layout: {
          'icon-image': 'circle',
          'icon-size': 1,
          'text-field': ["to-string", ["get", ("coordinates")]],
          'text-anchor': 'top',
          'text-size': 10,
          'text-offset': [0, 1]
        },
        filter: ['in', '$type', 'Point']
      });
    }
    if (this.pointPlotSubscription || this.polylinePlotSubscription) return;
    if (this.advancedToolsGraphicsSelectionActive &&
      this.selectedAdvancedToolNumber &&
      ADVANCED_TOOL_TYPE[this.selectedAdvancedToolNumber] === 'midpoint') {
      this.geojson.features = [];
      if(this.drawGeometrySubscriptionArr.length) {
        this.drawGeometrySubscriptionArr.map(sub => sub.unsubscribe());
        this.drawGeometrySubscriptionArr = [];
      }
      this.drawGeometrySubscriptionArr.push(
        this.pointPlotSubscription = fromEvent(this.map, 'click').subscribe(e => {
          const plotMultipleP = this.plotMultiplePoints.bind(this);
          plotMultipleP(e);
        })
      );
    } else if (this.sessionService.selectedRasterToolIndex === RASTER_TOOL_TYPE.compute_gradual_path) {
      this.geojson.features = [];
      if(this.drawGeometrySubscriptionArr.length) {
        this.drawGeometrySubscriptionArr.map(sub => sub.unsubscribe());
        this.drawGeometrySubscriptionArr = [];
      }
      this.drawGeometrySubscriptionArr.push(
        this.pointPlotSubscription = fromEvent(this.map, 'click').subscribe(e => {
          const plotMultipleP = this.plotMultiplePoints.bind(this);
          plotMultipleP(e);
        })
      );
    } else {
      this.pointPlotSubscription = fromEvent(this.map, 'click').subscribe(e => {
        const plotPoint =  this.plotPoint.bind(this);
        plotPoint(e)
      })  
    }
  }

  plotMultiplePoints(e) {
    const coords = JSON.stringify([e.lngLat.lng, e.lngLat.lat]);
    const coOrdObj = this.advancedToolsSelectedCoords ? JSON.parse(this.advancedToolsSelectedCoords) : null;
    if (coOrdObj) {
      if (!Array.isArray(coOrdObj[0])) {
        this.sessionService.setAdvancedToolsDrawnCoordinates(JSON.stringify([coOrdObj, [e.lngLat.lng, e.lngLat.lat]]));
      } else {
        return;
      }
    } else {
      this.sessionService.setAdvancedToolsDrawnCoordinates(coords);
    }
    let point = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [e.lngLat.lng, e.lngLat.lat]
      },
      "properties": {
        "id": String(new Date().getTime()),
        "coordinates": `(${this.sessionService.toDecimalString(e.lngLat.lng,3)}, ${this.sessionService.toDecimalString(e.lngLat.lat,3)})`
      }
    };
    this.geojson.features.push(point);

    if (this.map.getSource('pointMeasureSource')) {
      this.map.getSource('pointMeasureSource').setData(this.geojson);
    }
  }

  plotPoint(e) {
    if(this.editVectorAttribute && this.selectedVectorDataId) return;
    this.geojson.features = [];
    let point = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [e.lngLat.lng, e.lngLat.lat]
      },
      "properties": {
        "id": String(new Date().getTime()),
        "coordinates": `(${this.sessionService.toDecimalString(e.lngLat.lng,3)}, ${this.sessionService.toDecimalString(e.lngLat.lat,3)})`
      }
    };
    if ((this.selectedGraphicsType === 'point') && (this.pointMeasureSelection === true))  {
      this.geojson.features.push(point);

      if (this.map.getSource('pointMeasureSource')) {
        this.map.getSource('pointMeasureSource').setData(this.geojson);
      }
      const coords = JSON.stringify([e.lngLat.lng, e.lngLat.lat]);
      if(this.advancedToolsGraphicsSelectionActive) {
        this.sessionService.setAdvancedToolsDrawnCoordinates(coords);
      } else {
        this.sessionService.selectedCoordinates = coords;
      }
      const payload: any = {
        layer: this.selectedGraphicsLayerId,
        type: "point",
        coordinates: coords,
        page: 1,
        limit: 40
      }
      this.feature.features = [];
      // if(this.editVectorAttribute) this.sessionService.setCollectedOrAttributeEdit("vectorGeometryEdit");
      
      if(!(this.addGeometryActive || this.advancedToolsGraphicsSelectionActive) || this.editVectorAttribute ) this._store.dispatch(new GetGraphicsLayerDataStartAction(payload));
    } else if (this.sessionService.selectedRasterToolIndex === RASTER_TOOL_TYPE.visibility_coverage) {
      const coords = JSON.stringify([e.lngLat.lng, e.lngLat.lat]);
      this.sessionService.setAdvancedToolsDrawnCoordinates(coords);
      this.geojson.features.push(point);
      if (this.map.getSource('pointMeasureSource')) {
        this.map.getSource('pointMeasureSource').setData(this.geojson);
      }
    }
  }

  showGeometry(showPopup= true) {
    if (this.sessionService.selectedGeometryType) {
      // this.pointPlotSubscription.unsubscribe();
      if(this.sessionService.selectedGeometryLayerId) this.selectedGraphicsLayerId = this.sessionService.selectedGeometryLayerId;
      this.selectedGraphicsType = this.sessionService.selectedGeometryType;
        this.selectedGraphicsLayerType = this.sessionService.selectedGraphicsLayerType
      switch (this.sessionService.selectedGeometryType) {
        case 'point':
          this.pointMeasureSelection = true;
          this.linemeasureSelection = false;
          this.polygonMeasureSelction = false;
          this.closeMeasureSelection('polyline');
          this.closeMeasureSelection('polygon');
          if(!showPopup) {
            this.pointMeasure();
          } else {
            this.pointMeasure()
          }
          break;
        case 'polyline':
          this.pointMeasureSelection = false;
          this.linemeasureSelection = true;
          this.polygonMeasureSelction = false;
          this.closeMeasureSelection('polygon');
          this.closeMeasureSelection('point');
          this.lineMeasure(true);
          break;
        case 'polygon':
          this.pointMeasureSelection = false;
          this.linemeasureSelection = false;
          this.polygonMeasureSelction = true;
          this.closeMeasureSelection('polyline');
          this.closeMeasureSelection('point')
          this.polygonMeasure(true);

          break;
        case 'rectangle':
          break;
        default:
          break;
      }
    }
    if ([RASTER_TOOL_TYPE.compute_gradual_path, RASTER_TOOL_TYPE.visibility_coverage].includes(this.sessionService.selectedRasterToolIndex)) {
      this.pointMeasureSelection = true;
      this.linemeasureSelection = false;
      this.polygonMeasureSelction = false;
      this.closeMeasureSelection('polyline');
      this.closeMeasureSelection('polygon');
      this.pointMeasure();
    }
  }

  drawGeometryOnMap() {
    this.resetAttributesAndLayers();
    if(this.drawGeometrySubscriptionArr.length) {
      this.drawGeometrySubscriptionArr.map(sub => sub.unsubscribe());
      this.drawGeometrySubscriptionArr = [];
    }
    try {
      if (this.mapboxDrawObj) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Removing Draw object. ", error);
    }
    try {
      if (this.mapboxGeoGeometryObj) this.map.removeControl(this.mapboxGeoGeometryObj);
    } catch (error) {
      console.log("Error removing geometry draw object. ", error);
    }
    try {
      if (this.mapboxGeometryObj) this.map.removeControl(this.mapboxGeometryObj);
    } catch (error) {
      console.log("Removing Geometry Draw object. ", error);
    }
    this.mapboxGeometryObj = new MapboxDraw({
      displayControlsDefault: false,
    });
    try{
      this.map.addControl(this.mapboxGeometryObj, 'top-left');
    } catch(error) {
      console.log("Could not add control because it already exists. ", error);
    }
    this.mapboxGeometryObj.changeMode(MODE_OBJ[this.sessionService.selectedGraphicsLayerType]);
    // this.map.on('draw.create', this.updateCoordinaesAndAttEdit.bind(this));
    // this.map.on('draw.update', this.updateCoordinaesAndAttEdit.bind(this));
    this.drawGeometrySubscriptionArr.push(
      fromEvent(this.map, 'draw.create').subscribe(e => {
        const updateCoords = this.updateCoordinaesAndAttEdit.bind(this);
        updateCoords(e);
      })
    );
    this.drawGeometrySubscriptionArr.push(
      fromEvent(this.map, 'draw.update').subscribe(e => {
        const updateCoords = this.updateCoordinaesAndAttEdit.bind(this);
        updateCoords(e);
      })
    );
  }

  disableDoubleClickZoom() {
    this.map.doubleClickZoom.disable();
  }

  enableDoubleClickZoom() {
    this.map.doubleClickZoom.enable();
  }

  drawMultipleGeometryOnMap() {
    if(this.drawGeometrySubscriptionArr.length) {
      this.drawGeometrySubscriptionArr.map(sub => sub.unsubscribe());
      this.drawGeometrySubscriptionArr = [];
    }
    try {
      if (this.mapboxDrawObj) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Removing Draw object. ", error);
    }
    try {
      if (this.mapboxGeoGeometryObj) this.map.removeControl(this.mapboxGeoGeometryObj);
    } catch (error) {
      console.log("Removing Geometry Draw object. ", error);
    }
    try {
      if (this.mapboxGeometryObj) this.map.removeControl(this.mapboxGeometryObj);
    } catch (error) {
      console.log("Removing Geometry Draw object. ", error);
    }
    this.mapboxGeometryObj = new MapboxDraw({
      displayControlsDefault: false,
    });
    try{
      this.map.addControl(this.mapboxGeometryObj, 'top-left');
    } catch(error) {
      console.log("Could not add control because it already exists. ", error);
    }
    this.mapboxGeometryObj.changeMode(MODE_OBJ[this.sessionService.selectedGraphicsLayerType]);
    this.multipleGeometryCreationSubscriptions = fromEvent(this.map, 'draw.create').subscribe(e => {
      const addCord = this.addCoordinates.bind(this);
      addCord(e);
    });
    // this.map.on('draw.create', this.addCoordinates.bind(this));
    // this.map.on('draw.update', this.updateCoordinaesAndAttEdit.bind(this));
  }

  plotGeometryOnMap(data?) {
    this.resetAttributesAndLayers();
    try {
      if (this.mapboxGeometryObj) this.map.removeControl(this.mapboxGeometryObj);
    } catch (error) {
      console.log("Removing Draw object. ", error);
    }
    try {
      if (this.mapboxGeoGeometryObj) {
        const features = this.mapboxGeoGeometryObj.getAll().features.map(feature => feature.id);
        this.mapboxGeoGeometryObj.delete(features);
        this.map.removeControl(this.mapboxGeoGeometryObj);
      }
    } catch (error) {
      console.log("Error removing geometry draw object. ", error);
    }
    try {
      if (this.mapboxDrawObj) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Removing Draw object. ", error);
    }
    
    if(!(data.type || data.coordinates)) {
      this.removeFeature('geometry-draw-id');
    } else {
      try{
        this.removeFeature('geometry-draw-id');
      } catch(error) {
        console.log("cannot remove feature, because it does not exist on map");
      }
      this.mapboxGeoGeometryObj = new MapboxDraw({
        displayControlsDefault: false,
      });
      try{
        this.map.addControl(this.mapboxGeoGeometryObj, 'top-left');
      } catch(error) {
        console.log("Could not add control because it already exists. ", error);
      }
      this.mapboxGeoGeometryObj.changeMode(MODE_OBJ[data.type]);
      let feature = {
        id: 'geometry-draw-id',
        type: 'Feature',
        properties: {},
        geometry: { type: GEOMETRY_TYPES[data.type], coordinates: data.coordinates }
      };
      let featureIds = this.mapboxGeoGeometryObj.add(feature);
      // if(feature.geometry.type !== "Point") {
      // }
      try {
        this.mapboxGeoGeometryObj.changeMode('direct_select', { featureId: feature.id });
      } catch (error) {
        console.log("error: ", error);
      }
      this.map.on('draw.update', this.updateCoordinates.bind(this));
    }
  }

  plotEdittedGeometry(data){
    if(this.sessionService.selectedLayers && !this.sessionService.selectedLayers.length) return
    let selecetedLayerId = this.sessionService.selectedLayers[0]._id 
    let coordinates = data.coordinates;
    let gType = data.type;
    this.map.addSource("geometry-editted", {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          "geometry": {
            "type": GEOMETRY_TYPES[gType],
            "coordinates": coordinates
          }
        }]
      }
    })
    switch (GEOMETRY_TYPES[gType]) {
      case "Polygon":
      case "MultiPolygon":
        this.map.addLayer({
          "id": `${selecetedLayerId}` +"geo-editted-polygon",
          "type": "fill",
          "source": "geometry-editted",
          "paint": {
            "fill-color": "#47b47b",
            "fill-outline-color": "#47b47b",
            "fill-opacity": 0.4
          },
          "filter": ["==", "$type", "Polygon"]
        });
        break;
      case "LineString":
      case "MultiLineString":
        this.map.addLayer({
          "id": `${selecetedLayerId}` +"geo-editted-line",
          "type": "line",
          "source": "geometry-editted",
          "paint": {
            "line-color": "#47b47b",
            "line-width": 2,
            "line-opacity": 0.4
          },
          "filter": ["==", "$type", "LineString"]
        });
        break;
      case "Point":
        this.map.addLayer({
          "id": `${selecetedLayerId}` +"geo-editted-point",
          "type": "circle",
          "source": "geometry-editted",
          "paint": {
            "circle-radius": 6,
            "circle-color": "#47b47b"
          },
          "filter": ["==", "$type", "Point"],
        });
        break;
    }
  }

  plotOriginalGeometry(data) {
    if(this.sessionService.selectedLayers && !this.sessionService.selectedLayers.length) return
    let selectedLayerId =this.sessionService.selectedLayers[0]._id;
    let coordinates = data.coordinates;
    let gType = data.type;
    this.map.addSource("geometry-original", {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          "geometry": {
            "type": GEOMETRY_TYPES[gType],
            "coordinates": coordinates
          }
        }]
      }
    })
    switch (GEOMETRY_TYPES[gType]) {
      case "Polygon":
      case "MultiPolygon":
        this.map.addLayer({
          "id": `${selectedLayerId}` +"geo-original-polygon",
          "type": "fill",
          "source": "geometry-original",
          "paint": {
            "fill-color": "#ee4040",
            "fill-opacity": 1
          },
          "filter": ["==", "$type", "Polygon"]
        });
        break;
      case "LineString":
      case "MultiLineString":
        this.map.addLayer({
          "id": `${selectedLayerId}` + "geo-original-line",
          "type": "line",
          "source": "geometry-original",
          "paint": {
            "line-color": "#ee4040",
            "line-width": 2,
            "line-opacity": 1
          },
          "filter": ["==", "$type", "LineString"]
        });
        break;
      case "Point":
        this.map.addLayer({
          "id": `${selectedLayerId}` + "geo-original-point",
          "type": "circle",
          "source": "geometry-original",
          "paint": {
            "circle-radius": 6,
            "circle-color": "#ee4040"
          },
          "filter": ["==", "$type", "Point"],
        });
        break;
    }
  }


  resetAttributesAndLayers() {
    this.polygonemeasureActive = false;
    this.linemeasureActive = false;
    this.closeMeasureSelection('all');
    // this.sessionService.selectedGeometryLayerId = '';
    // this.sessionService.selectedGraphicsLayerType = '';
    // this.sessionService.selectedGeometryType = '';
    // this.sessionService.selectedCoordinates = '';
    this.sessionService.selectedQueryLayerId = '';
    this.sessionService.selectedQueryLayerType = '';
    // this.selectedGraphicsLayerId = '';
    // this.selectedGraphicsLayerType = '';
    // this.selectedGraphicsType = '';
    this.measureArea = '';
    this.measureDistance = '';
    this.identify.next(false);
    this.measureActive = true;
    // this.closeAllMeasure('polyline');
    // this.removeDrawLayersForPolygon();
    // this.removeDrawSourceForPolygon();
    // this.removeDrawLayerForPoint();
    // this.removeDrawSourceForPoint();
    // // this.removeDrawLayersAndSource();
    // this.removeDrawLayersForPolyline();
    // this.removeDrawSourceForPolyline();
  }

  updateCoordinaesAndAttEdit(e) {
    this.updateCoordinates(e);
    // this.sessionService.setDataFormEdit(true);
    if(!this.searchActive && !this.sessionService.measureActive && !this.advancedToolsActive) this.sessionService.setCollectedOrAttributeEdit('addNewVectorData');
    // this.sessionService.setActivateMultiLayerDraw(true);
  }

  updateCoordinates(e) {
    if(!e.features.length) return;
    this.editedGeometryType.next(e.features[0].geometry.type);
    this.editedGeometryCoordinates.next(e.features[0].geometry.coordinates);
    this.geometryCoordinates = e.features[0].geometry.coordinates;
  }

  addCoordinates(e) {
    if (!e.features.length) return;
    if (['multipolygon', 'multilinestring'].includes(this.sessionService.selectedGraphicsLayerType)) {
      if (this.geometryCoordinates.length) {
        let type = "";
        switch (this.sessionService.selectedGraphicsLayerType) {
          case 'multipolygon':
            type = 'MultiPolygon';
            break;
          case 'multilinestring':
            type = 'MultiLineString';
            break;
        }
        if ((this.geometryCoordinates.length === 1 && type === 'MultiPolygon') || this.addMoreClicked) {
          this.geometryCoordinates = [this.geometryCoordinates];
          this.addMoreClicked = false;
        }
        this.geometryCoordinates.push(e.features[0].geometry.coordinates);
        this.plotGeometryOnMap({ type: type, coordinates: this.geometryCoordinates });
        this.editedGeometryType.next(type);
      } else {
        this.addMoreClicked = true;
        this.geometryCoordinates = e.features[0].geometry.coordinates;
        let type = "";
        switch (this.sessionService.selectedGraphicsLayerType) {
          case 'multipolygon':
            type = 'Polygon';
            break;
          case 'multilinestring':
            type = 'LineString';
            break;
        }
        this.plotGeometryOnMap({ type: type, coordinates: this.geometryCoordinates });
        this.editedGeometryType.next(type);
      }
      this.editedGeometryCoordinates.next(this.geometryCoordinates);
      this.multipleGeometryCreationSubscriptions.unsubscribe();
    }
  }

  getEditedGeometryType(): Observable<any> {
    return this.editedGeometryType.asObservable();
  }

  setEditedGeometryType(value) {
    this.editedGeometryType.next(value);
  }

  getEditedGeometryCoordinates(): Observable<any> {
    return this.editedGeometryCoordinates.asObservable();
  }

  setEditedGeometryCoordinates(value) {
    this.geometryCoordinates = value;
    this.editedGeometryCoordinates.next(value);
  }

  getShowAddNewVectorProperties(): Observable<boolean> {
    return this.showAddNewVectorProperties.asObservable();
  }

  setShowAddNewVectorProperties(value) {
    this.showAddNewVectorProperties.next(value);
  }

  getAddNewGeometryFlag(): Observable<boolean> {
    return this.addNewGeometry.asObservable();
  }

  setAddNewGeometryFlag(value) {
    this.addNewGeometry.next(value);
  }

  getShowLayerTable(): Observable<boolean> {
    return this.showLayerTable.asObservable();
  }

  setShowLayerTable(val) {
    this.showLayerTable.next(val);
  }

  removeFeature(id) {
    if(!id) return
    try {
      this.mapboxGeometryObj.delete(id);
    } catch (error) {
      console.log("Error removing feature. ", error);
    }
    try {
      this.mapboxGeoGeometryObj.delete(id);
    } catch (error) {
      console.log("Error removing feature. ", error);
    }
  }

  removeGeometrySources() {
    if(this.legendService.isSwipeEnable){
      return false;
    }
    if(!this.map) {
      return;
    }
    let layerIds = this.map.getStyle().layers.map(layer => layer.id);
    if (this.map.getSource("geometry-editted")) {
      ["geo-editted-polygon", "geo-editted-line", "geo-editted-point"].forEach(layer => {
        let index = layerIds.findIndex(layerId => layerId.includes(layer));
        if(index !== -1) {
          let selectedLayerId = [...layerIds][index];
          this.removeGeometryLayer(selectedLayerId);
        }
      });
      this.map.removeSource("geometry-editted");
    }
    if(this.map.getSource("geometry-original")) {
      ["geo-original-polygon", "geo-original-line", "geo-original-point"].forEach(layer => { 
        let index =layerIds.findIndex(layerId => layerId.includes(layer));
        if(index !== -1) {
          let selectedLayerId =[...layerIds][index];
          this.removeGeometryLayer(selectedLayerId)
        }
      });
      this.map.removeSource("geometry-original");
    }
  }

  removeGeometryLayer(layer) {
    this.map.removeLayer(layer);
  }

  removeDrawObjects() {
    try {
      if (this.mapboxDrawObj) this.map.removeControl(this.mapboxDrawObj);
    } catch (error) {
      console.log("Error removing draw object mapboxDrawObj. ", error);
    }
    try {
      if (this.mapboxGeoGeometryObj) this.map.removeControl(this.mapboxGeoGeometryObj);
    } catch (error) {
      console.log("Error removing geometry draw object. ", error);
    }
    try {
      if (this.mapboxGeometryObj) this.map.removeControl(this.mapboxGeometryObj);
    } catch (error) {
      console.log("Error removing draw object mapboxGeometryObj. ", error);
    }
  }

  getBoundingBox(type, coordinates) {
    let value: any = [];
    if (type == "Point") {
      value = turf.point(coordinates);
    }
    else if (type == "Polygon") {
      value = turf.polygon(coordinates);
    }
    else if (type == "LineString") {
      value = turf.lineString(coordinates);
    }
    else if (type == "MultiPolygon") {
      value = turf.multiPolygon(coordinates);
    }
    else if (type == "MultiLineString") {
      value = turf.multiLineString(coordinates);
    }
    return value;
  }

  openSession(id) {
    this.router.navigate(['./home', id]);
  }

  logoutClicked() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    this._helper.tokenChangeSubject({val: false})
    this.router.navigate(["sign/signIn"]);
  }
}




