import { UserProfileActions, userProfileactionTypes } from '../actions/profile.action';
import { DashboardActions, dashboardactionTypes } from '../actions/dashboard.actions';

export interface DashboradData {
    total_layers:number,
    vector_layers:number,
    raser_layers:number,
    data_collected_layers:number,
    api_hits:number,
    available_hits:number,
    total_form_templates:number,
    plan_end_date: string,
    subscription_plan:string
}

export interface State {
  data: DashboradData;
}

export const initialState: State = {
  data: null
};

export function dashboardReducer(state = initialState, action: DashboardActions): State {
  switch (action.type) {
    case dashboardactionTypes.getDashboardSuccess:
      return {...state, data: action.data};
      
  }
  return state;
}