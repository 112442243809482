export class SubscriptionHelper {
  constructor() { }

  getValue(slide, prop) {
    if (!["name", "amount", "currency", "period", "mapSessions", "numberOfProjects",
      "user", "hits", "numberOfSurveyors", "storage", "plan_type"].includes(prop)) {
      if (this.isOnpremisePlan(slide)) return true;
      return slide[prop]
    };
    switch (prop) {
      case "name":
        return (slide.name) ? slide.name : "—";
      case "amount":
        if (this.isOnpremisePlan(slide)) return "—";
        return slide.amount ? slide.amount : "0";
      case "currency":
        if (this.isOnpremisePlan(slide)) return "—";
        return slide.currency ? slide.currency : "—";
      case "period":
        if (this.isOnpremisePlan(slide)) return "—";
        return slide.period ? slide.period : "—";
      case "mapSessions":
        if (this.isOnpremisePlan(slide)) return "Unlimited";
        return (+slide.mapSessions === -1) ? "Unlimited" : slide.mapSessions;
      case "numberOfProjects":
        if (this.isOnpremisePlan(slide)) return "Unlimited";
        return (+slide.numberOfProjects === -1) ? "Unlimited" : slide.numberOfProjects;
      case "user":
        if (this.isOnpremisePlan(slide)) return "Unlimited";
        return (+slide.user === -1) ? "Unlimited" : `${slide.user}`;
      case "hits":
        if (this.isOnpremisePlan(slide)) return "Unlimited";
        return (+slide.hits === -1) ? "Unlimited" : slide.hits;
      case "numberOfSurveyors":
        if (this.isOnpremisePlan(slide)) return "Unlimited";
        return (+slide.numberOfSurveyors === -1) ? "Unlimited" : slide.numberOfSurveyors;
      case "storage":
        // return (slide.storage && slide.storage.limit && slide.storage.unit) ? `${slide.storage.limit} ${slide.storage.unit}` : "—";
        if (this.isOnpremisePlan(slide)) return "Scalable";
        if (slide.storage) {
          if (typeof slide.storage === 'number') {
            return slide.storage < 1024 ? `${slide.storage} MB` : `${slide.storage / 1024} GB`
          }
          else {
            return slide.storage
          }
        }
        return "—"
      case "plan_type":
        return slide.plan_type;
      default:
        return null;
    }
  }

  isOnpremisePlan(slide) {
    return slide ? slide.plan_type === 3 : false;
  }
}

export const PLAN_PERIOD = {
  daily: "per day",
  weekly: "per week",
  monthly: "per month",
  quarterly: "per quarter year",
  halfyearly: "per half year",
  yearly: "per year"
}
