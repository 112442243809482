import { SubscriptionActionTypes, SubscriptionActions } from '../actions/subscription.action';

export interface State {
  plans: Array<any>,
  currency: string,
  invoices: Array<any>,
  sentEmail: any,
  freePlan:any
}

export const initialState = {
  plans: [],
  currency: "",
  invoices: [],
  sentEmail: {},
  freePlan: {}
}

export function subscriptionReducer(state = initialState, action: SubscriptionActionTypes): State {
  switch (action.type) {
    case SubscriptionActions.getSubscriptionPlansSuccess:
      return { ...state, plans: action.data.data, currency: action.data.currency };
    case SubscriptionActions.getInvoicesSuccessAction:
      return { ...state, invoices: action.response }
    case SubscriptionActions.sendEmailSuccessAction:
      return { ...state, sentEmail: action.response }
    case SubscriptionActions.optFreePlanSuccessAction:
      return { ...state, freePlan: action.response }
  }
  return state;
}