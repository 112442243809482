import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogContent } from "@angular/material";
import { FormControl } from '@angular/forms';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/_services/socket.service';
import { ProcessLayerDialogComponent } from '../process-layers-dialog/process-layer-dialog.component';
import { Store } from '@ngrx/store';
import { userProfileSelector } from 'src/app/store/selectors/profile.selector';
import { environment as env} from '../../../environments/environment';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
@Component({
  selector: 'nav-tab-dialog',
  templateUrl: './nav-tab-dialog.component.html',
  styleUrls: ['./nav-tab-dialog.component.scss']
})


export class NavDialogComponent implements OnDestroy {
  userProfileLogo: string = "assets/images/profile_2.svg";
  @ViewChild('notificationDiv') notificationDiv: ElementRef;
  badgeCount: any = 0;
  chatCount: any = 0;
  userData: any = null;
  // notificationDisplay: boolean = true;
  // chatIconVisibility: boolean = false;\
  subscriptionArr: any[] = [];
  isProductionEnv: boolean = true;
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    public socketService: SocketService,
    public router: Router,
    private _store: Store<any>,
    private navCtrlService: NavigationCtrl) {
      if(["local", "dev", "uat", "cityos"].includes(env.envName)) {
        this.isProductionEnv = false;
      }
    }
  ngOnDestroy() {
    this.subscriptionArr.map((eachSub) => eachSub.unsubscribe())
    this.subscriptionArr = [];
  }

  ngOnInit() {
    const rightMostPos = window.innerWidth - Number(this.data.setPosition.left - 186.5);
    this.dialogRef.updatePosition({ top: `${this.data.setPosition.top + 53}px`, right: `${rightMostPos - 250}px` });
    this.subscriptionArr.push(this.socketService.unreadCountSubject.subscribe((resp: any) => {
      if (resp && resp.success) {
        this.badgeCount = resp.data.count;
        if (resp.data.count > 9) {
          this.badgeCount = "9+"
        }

        // if (resp.data.count) {
        //     this.socketService.highLightBadge = true
        // } else {
        //     this.socketService.highLightBadge = false;
        // }
      }
    }))

    // this.socketService.unreadCount.subscribe((resp: any) => {
    //     if (resp && resp.success) {
    //         this.chatCount = resp.data.count;
    //         if (resp.data.count > 9) {
    //             this.badgeCount = "9+"
    //         }

    //         if (resp.data.count) {
    //             this.socketService.highLightBadge = true
    //         } else {
    //             this.socketService.highLightBadge = false;
    //         }
    //     }
    // })

    this._store.select(userProfileSelector).subscribe((data: any) => {
      if (Object.keys(data).length) {
        this.userData = data;
        this.userProfileLogo = this.userData.profilePic || "assets/images/profile_2.svg";
      }
    })
    // use  
  }

  toggleClicked(event) {
    // this.activatedStatus =event.checked;
    this.dialogRef.close(event.checked)
  }

  chatClicked() {
    this.dialog.closeAll();
    this.router.navigate(['./', 'app-management', 'chat'])
  }
  proFile() {
    this.dialog.closeAll();
    this.router.navigate(['./', 'app-management', 'profile'])
  }
  notificationIconClicked() {
    this.dialog.closeAll();

    let dialogPosition = {
      top: this.notificationDiv.nativeElement.getBoundingClientRect().top,
      left: this.notificationDiv.nativeElement.getBoundingClientRect().left,
      bottom: this.notificationDiv.nativeElement.getBoundingClientRect().bottom,
      right: this.notificationDiv.nativeElement.getBoundingClientRect().right,
    }
    this.dialog.open(ProcessLayerDialogComponent, {
      width: '325px',
      panelClass: 'custo-sess',
      data: {
        position: dialogPosition,
        type: 'socketNotifications',
      }
    })

  }
  logout() {
    this.dialogRef.close(false);
    this.navCtrlService.logoutClicked();
  }
}