import { Component } from "@angular/core";
import * as mapboxgl from 'mapbox-gl';
import { ActivatedRoute } from '@angular/router';
import * as ConnectionProps from 'src/assets/connection.properties.json'
import { LayerService } from '../_services';
import { fromEvent, combineLatest, Observable } from 'rxjs';
import { SessionService } from "../_services/session.service";




@Component({
  selector: 'wms-preview',
  styleUrls: ['./wms-preview.component.scss'],
  templateUrl: './wms-preview.component.html'
})

export class WmsPreviewComponent {
  map;
  zoom: number = 1;
  genericStyle = '/assets/style1.json';
  centerValue: any = [78.8723, 20.8167];
  mouseClickCoordinates: any = { lat: 20.8167, lng: 78.8723 };
sessionId: any;
  accessToken: any;
  connProps = ConnectionProps;
jsonData: mapboxgl.Style = {
    "version": 8,
    "name": "Transerve",
    "metadata": {
      "mapbox:autocomposite": true,
      "mapbox:type": "template",
      "mapbox:sdk-support": {
        "js": "0.54.0",
        "android": "6.7.0",
        "ios": "4.7.0"
      }
    },
    "center": [77.4853726043873, 19.59197266470518],
    "zoom": 8.05536269977162,
    "bearing": 0,
    "pitch": 0,
    "glyphs": "https://sgp1.digitaloceanspaces.com/tos-fileserver/fonts/{fontstack}/{range}.pbf",
    "sprite": "https://tosapi.transerve.com/api/v2/img/sprite",
    "light": {
      "anchor": "viewport",
      "color": "hsl(0, 0%, 0%)",
      "intensity": 0.96
    },
    "layers": [
      {
          "id": "background",
          "type": "background",
          "layout": {},
          "paint": {
              "background-color": "#dcdcdc",
              "background-opacity": 1
          }
      }
    ]
  }

  constructor(public activatedRoute: ActivatedRoute, public layerService: LayerService,private sessionservice:SessionService) {
    this.sessionId = this.activatedRoute.snapshot.params.id;
    this.accessToken = this.activatedRoute.snapshot.queryParams.access_token;
  }
  ngOnInit() {
    this.map = new mapboxgl.Map({
      container: 'map',
      minZoom: 1,
      maxZoom: 22,
      zoom: this.zoom,
      style: `${this.genericStyle}`,
      localIdeographFontFamily: 'OpenSans-Regular',
      center: this.centerValue,
      attributionControl: false
    });
    if (this.sessionId && this.accessToken) {
      const mapLoad: Observable<any> = fromEvent(this.map, 'load');
      const getStyleObj: Observable<any> = this.layerService.getWmsStyleObj(this.sessionId, this.accessToken);
      combineLatest(mapLoad, getStyleObj).subscribe(([_, resp]) => {
        this.map.dragRotate.enable();
        var nav = new mapboxgl.NavigationControl({ showCompass: false });
        this.map.addControl(nav, 'bottom-right');
        if (resp && Object.keys(resp).length) {
          this.plotMapWithJsonData(resp);
        }
      })
    }
  }

  plotMapWithJsonData(apijsonData) {
    if (apijsonData) {
      this.jsonData = apijsonData;
      console.log(apijsonData)
    }
    if (this.map) {
      this.updateSourcesWithStyle(this.jsonData);
    }
  }

  mapZoom() {
    let zoom = this.map.getZoom();
    const with1Decimal = this.sessionservice.toDecimalString(zoom, 1);
    return with1Decimal;
}

  updateSourcesWithStyle(styleObj: mapboxgl.Style) {
    if (styleObj.center.length) {
      this.map.setCenter({ lng: styleObj.center[0], lat: styleObj.center[1] });
    }
    this.map.setZoom(styleObj.zoom);
    let sources = styleObj.sources;
    let sourceId = Object.keys(sources)[0]
    if (sources && Object.keys(sources).length) {
      this.map.addSource(sourceId, sources[sourceId]);
    }
    this.map.addLayer({
      id: 'raster-format',
      type: 'raster',
      source: sourceId,
      minZoom: styleObj.zoom,
    })
  }
}           