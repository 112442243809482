import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatSelect
} from "@angular/material";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatInput } from '@angular/material';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { from, Subject } from 'rxjs';
import { GetUsersStartAction, GetRolesStartAction } from "src/app/store/actions/user-management.actions";
import { usersSelector, rolesSelector, usersCountSelector } from "src/app/store/selectors/user-management.selector";
import { Store } from '@ngrx/store';
import * as PropertyTaxActions from '../../store/actions/property-tax.actions';
import { Subscription } from 'rxjs';
import { Pagination } from "../../models/pagination"
import { User, GetParam, Ward } from "../../models/user-ward-assignments"
import { listWardsSelector, listWardsCountSelector, filteredDataSelector } from "../../store/selectors/property-tax.selector";
import { UrbanLocalBodiesService } from 'src/app/app-management/cityos/urban-local-bodies/urban-local-bodies.service';
import { DataApprovalManagementService } from 'src/app/_services/data-approval-management.service';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment-mini-ts';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
}

@Component({
  selector: 'app-building-filter-dialog',
  templateUrl: './building-filter-dialog.component.html',
  styleUrls: ['./building-filter-dialog.component.scss'],

  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class BuildingFilterDialogComponent implements OnDestroy, AfterViewInit {

  surveyor: FormControl = new FormControl("");
  ward: FormControl = new FormControl("");
  startDate: FormControl = new FormControl("");
  endDate: FormControl = new FormControl("");
  approvalStatus: FormControl = new FormControl("");
  page: number = 1;
  searchText = "";
  filterValue = 5;
  user: Array<any> = [];
  allUsersCount = 0;
  today = new Date();
  timeFormat: any = "T00:00:00.000Z"
  lastDate: any
  date: any
  formatedDate: any
  start: any
  end: any
  wardCount: any
  offset: any
  lenght: any
  totalSurveyorPages: any
  totalWardsPages: any
  tableData: any
  limit: number = 20;
  totalPages: number
  private wardsSubscription: Subscription;
  private formsPagination: Pagination = new Pagination();
  wards: Ward[] = [];
  private wardsPagination: Pagination = new Pagination();
  selectedTiers: FormControl = new FormControl();
  selectedLayers: any[] = [];
  tierArrayForFiltering: any[] = [];

  tierFormGroup: FormGroup = new FormGroup({
    tiers: new FormArray([])
  })
  filteredSubscription: Subscription;
  stopper$: Subject<any> = new Subject();
  dataFields: any[] = [{ text: 'Created At', type: 'createdAt' }, { text: 'Updated At', type: 'updatedAt' }, { text: "Sync Date", type: 'sync_date' }];
  dataFieldControl: FormControl = new FormControl('');
  subscriptionArr: any[] = [];
  tierFormArray: FormArray;
  clearFilter=false;
  // firstAssessmentStartPickerControl: FormControl = new FormControl('');
  // firstAssessmentLastPickerControl: FormControl = new FormControl('');
  // lastAssessmentStartPickerControl: FormControl = new FormControl('');
  // lastAssessmentLastPickerControl: FormControl = new FormControl('');
  // assessmentStatusOptionArr: Array<String> = ['first_assessment', 'last_assessment'];
  // assessmentStatus: FormControl = new FormControl('');
  //   assessment_type = "first_assessment"   //last_assessment
  // assessment_start_date = 
  // assessment_end_date = 
  // keysObj = {
  //   0: "assessment_start_date",
  //   1: "assessment_end_date",
  // }
  // controlsObj = {
  //   0: this.firstAssessmentStartPickerControl,
  //   1: this.firstAssessmentLastPickerControl,
  //   2: this.lastAssessmentStartPickerControl,
  //   3: this.lastAssessmentLastPickerControl,

  // }
  // validatorControlObj = {
  //   0: this.firstAssessmentLastPickerControl,
  //   1: this.firstAssessmentStartPickerControl,
  //   2: this.lastAssessmentLastPickerControl,
  //   3: this.lastAssessmentStartPickerControl
  // }
  // dateControlArr = [this.firstAssessmentStartPickerControl, this.firstAssessmentLastPickerControl, this.lastAssessmentStartPickerControl, this.lastAssessmentLastPickerControl]
  errorInDateFormat: boolean;
  selectedAssessmentStatus: String = '';
  errorType: String = '';
  assessmentStatusControl: FormControl = new FormControl('');
  assessmentStatusOptions: any[] = [{ type: 'first_assessment_date', text: "First Assessment Date" }, { type: 'last_assessment_date', text: 'Recent Assessment Date' }]
  reset: boolean = false;
  constructor(
    private _store: Store<any>,
    public dialogRef: MatDialogRef<BuildingFilterDialogComponent>,
    public _urbanLocalBodiesService: UrbanLocalBodiesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public DAMService: DataApprovalManagementService,
    private fb: FormBuilder
  ) {

  }
  ngAfterViewInit() {

    this.filteredSubscription = this._store.select(filteredDataSelector).subscribe((resp: any) => {
      if (resp && resp.success) {
        this.tierFormArray.at(resp.selectedTierNumber - 1).get('tierEntities').setValue(resp.data);
      }
      if (resp.selectedTierNumber < this.tierArrayForFiltering.length) {
        this.createOptionsWithTierData(resp.selectedTierNumber + 1)
      }
    })
  }
  ngOnDestroy() {
    this.stopper$.next();
    this.filteredSubscription.unsubscribe();
    this.wardsSubscription.unsubscribe();
    this.subscriptionArr.map((eachSub) => eachSub.unsubscribe());
  }

  ngOnInit() {
    this.tierFormArray = this.tierFormGroup.get('tiers') as FormArray;
    this.subscriptionArr.push(this._store.select(usersCountSelector).subscribe(count => this.allUsersCount = count));
    let tableData = {
      page: this.page,
      limit: 100,
      filter: this.filterValue,
      search: this.searchText
    };
    //this._store.dispatch(new PropertyTaxActions.ResetWardsAndUsers());
    this._store.dispatch(new GetUsersStartAction(tableData));
    this.user = []
    this.subscriptionArr.push(this._store.select(usersSelector).subscribe((users: any) => {

      this.totalPages = users.pages
      this.totalSurveyorPages = Math.ceil(this.allUsersCount / this.limit)

      if (this.allUsersCount === 0) {
        this.user = [];
        return;
      }
      this.user = [...users.data].filter(user => user.user_role === 5);

      //this.user.push(...users)
      //this.user.push(...users)
      //  if (Object.entries(users).length === 0 && users.constructor === Object) {
      //   return;
      // }
      if (this.page < this.totalPages) {  // this.offset=this.allUsersCount - users.length
        this.page++;
        // let tableData = {
        //   page: this.page,
        //   limit: 100,
        //   filter: this.filterValue,
        //   search: this.searchText
        // };
        // this._store.dispatch(new GetUsersStartAction(tableData));
        return;
      }
    }));



    // let apiData: GetParam = {
    //   params: ["wards"],
    //   queryParams: this.wardsPagination
    // };

    // this._store.select(listWardsCountSelector).subscribe(count => this.wardCount = count);

    // this._store.dispatch(new PropertyTaxActions.ResetWardsAndUsers());
    // this._store.dispatch(new PropertyTaxActions.ListWards(apiData));

    this.wardsSubscription = this._store.select(listWardsSelector).pipe(takeUntil(this.stopper$)).subscribe((wards: Ward[]) => {
      this._store.select(listWardsCountSelector).subscribe(count => this.wardCount = count);
      this.totalWardsPages = Math.ceil(this.wardCount / this.limit)

      if (this.wardCount === 0) {
        this.wards = [];
        return;
      }
      this.wards.push(...wards);

      if (this.wards.length < this.wardCount) {

        this.wardsPagination.page++;
        let apiData: GetParam = {
          params: ["wards"],
          queryParams: this.wardsPagination
        };
        // this._store.dispatch(new PropertyTaxActions.ListWards(apiData));
        return;
      }
    });



    // this.endDate.setValue(new Date(this.data.endDate));

    if (this.data.startDate) {

      this.startDate.setValue(new Date(this.data.startDate));
    }
    if (this.data.endDate) {

      this.endDate.setValue(moment(new Date(this.data.endDate)).subtract(5, 'h').subtract(30, 'm'));
    }

    this.surveyor.setValue(this.data.surveyor);
    this.ward.setValue(this.data.ward);
    this.approvalStatus.setValue(this.data.approvalStatus);
    if (this.data.assessmentDate) {
      let selectedOption = this.assessmentStatusOptions.find(({ type }) => type == this.data.assessmentDate)
      this.assessmentStatusControl.setValue(selectedOption);
    }
    // this.assessmentStatus.setValue(this.data.assessment_type)

    // for (let index in this.keysObj) {
    //   if (this.data[this.keysObj[index]]) {
    //     this.controlsObj[index].setValue(this.data[this.keysObj[index]]);
    //     this.setValidators(this.validatorControlObj[index])
    //   }
    // }

    // this.dataFieldControl.setValue(this.data.dataField);
    if (this.DAMService.selectedTab === 0) {
      if (this.DAMService.buildingDataField && Object.keys(this.DAMService.buildingDataField).length) {

        this.dataFieldControl.setValue(this.DAMService.buildingDataField);
      }

    } else {
      if (this.DAMService.adminBuildingDataField && Object.keys(this.DAMService.adminBuildingDataField).length) {

        this.dataFieldControl.setValue(this.DAMService.adminBuildingDataField);
      }

    }
    // tiers here
    this.tierArrayForFiltering = this.data.allTierIds.map((tier, i) => {
      return { index: i + 1, tierId: tier }
    });
    let selectedTiers = this.tierArrayForFiltering.length;
    // console.log(this.data.tierTitles)
    this.removeTiers(selectedTiers);
    this.addTiers(selectedTiers);

    if ((this.DAMService.selectedTab === 0) && (this.DAMService.tierFormArray)) {
      let wardArrayLength = this.DAMService.tierFormArray.controls.length;
      for (let i = 0; i < wardArrayLength; i++) {
        this.tierFormArray.at(i).get('tierEntity').setValue(this.DAMService.tierFormArray.at(i).get('tierEntity').value);
      }
    } else if (this.DAMService.selectedTab === 2 && this.DAMService.adminTierFormArray) {
      let wardArrayLength = this.DAMService.adminTierFormArray.controls.length;
      for (let i = 0; i < wardArrayLength; i++) {
        this.tierFormArray.at(i).get('tierEntity').setValue(this.DAMService.adminTierFormArray.at(i).get('tierEntity').value);
      }
    }
    // after addition of tier controls 
    if (this.selectedTiers) {
      this.createOptionsWithTierData(1);
    }
    // this.subscriptionArr.push(this.firstAssessmentStartPickerControl.valueChanges.subscribe((res: any) => {
    //   this.setValidators(this.firstAssessmentLastPickerControl);
    // }))
    // this.subscriptionArr.push(this.firstAssessmentLastPickerControl.valueChanges.subscribe((res: any) => {
    //   this.setValidators(this.firstAssessmentStartPickerControl);
    // }))
    // this.subscriptionArr.push(this.lastAssessmentStartPickerControl.valueChanges.subscribe((res: any) => {
    //   this.setValidators(this.lastAssessmentLastPickerControl);
    // }))
    // this.subscriptionArr.push(this.lastAssessmentLastPickerControl.valueChanges.subscribe((res: any) => {

    //   this.setValidators(this.lastAssessmentStartPickerControl)

    // }))


  }

  resetFilters() {
    this.clearFilter = true;
    this.reset = true
    this.startDate.setValue("");
    this.endDate.setValue("");
    this.surveyor.setValue("");
    this.ward.setValue("");
    this.approvalStatus.setValue("");
    this.dataFieldControl.setValue({});
    let tierFormArray = <FormArray>this.tierFormGroup.get('tiers');
    let wardArrayLength = tierFormArray.controls.length;
    this.assessmentStatusControl.setValue({});

    for (let i = wardArrayLength - 1; i >= 0; i--) {
      tierFormArray.at(i).get('tierEntity').setValue({})
      if (this.DAMService.selectedTab === 0) {
        // if (this.DAMService.tierFormArray) {
        // this.DAMService.tierFormArray.at(i).get('tierEntity').setValue({});
        // }
      } else if (this.DAMService.selectedTab === 2) {
        if (this.DAMService.adminTierFormArray) {
          this.DAMService.adminTierFormArray.at(i).get('tierEntity').setValue({});
        }
      }
    }
    if (this.DAMService.selectedTab === 0) {
      this.DAMService.buildingIds = [];
      this.DAMService.buildingIdsWithoutGeometryEdit = [];
    } else if (this.DAMService.selectedTab === 1) {
      this.DAMService.premiseIds = [];
    } else if (this.DAMService.selectedTab === 2) {
      this.DAMService.adminBuildingIds = [];
    }
    this.DAMService.buildingFilters = {
      startDate: "",
      endDate: "",
      surveyor: "",
      ward: "",
      approvalStatus: null,
      date: "",
      assessmentDate: "",
    }
    // for (let index in this.controlsObj) {
    //   this.controlsObj[index].setValue('', { emitEvent: false });
    // }
  }

  applyFilters() {
    let filterArray: any = []
    let filter: any = {};
    let filteredItems: any = {}
    if(this.clearFilter ==false){
      this.DAMService.appliedFilter = true;
    }else{
      this.DAMService.appliedFilter = false;
    }
   


    if (this.startDate.value && this.startDate.value != "" && this.startDate.value != undefined) {
      if (this.startDate.value instanceof Date) {
        // this.formatDate(this.startDate.value);
        filter.startDate = moment(this.startDate.value).startOf('day').toISOString();
        filteredItems.startDate = moment(this.startDate.value).startOf('day').toISOString();
      } else if (this.startDate.value._d instanceof Date) {
        // this.formatDate(this.startDate.value._d);
        filter.startDate = moment(this.startDate.value._d).startOf('day').toISOString();

        filteredItems.startDate = moment(this.startDate.value._d).startOf('day').toISOString();
      }
      else {
        filter.startDate = this.startDate.value;
        filteredItems.startDate = this.startDate.value;
      }
    }


    if (this.endDate.value && this.endDate.value != "" && this.endDate.value != undefined) {
      if (this.endDate.value instanceof Date) {
        this.lastDate = this.endDate.value
        this.formatLastDate(this.lastDate);
        // filter.endDate = this.end
        // filter.endDate = moment(this.endDate.value).endOf('day').add(5,'h').add(30, 'm').toISOString();
        // filteredItems.endDate = moment(this.endDate.value).endOf('day').add(5,'h').add(30, 'm').toISOString();
        filter.endDate = moment(this.endDate.value).endOf('day').toISOString();
        filteredItems.endDate = moment(this.endDate.value).endOf('day').toISOString();
      }
      else if (this.endDate.value._d instanceof Date) {
        this.lastDate = this.endDate.value._d
        this.formatLastDate(this.lastDate);
        // filter.endDate = this.end
        // filter.endDate = moment(this.endDate.value._d).endOf('day').add(5,'h').add(30, 'm').toISOString();

        // filteredItems.endDate = moment(this.endDate.value._d).endOf('day').add(5,'h').add(30, 'm').toISOString();
        filter.endDate = moment(this.endDate.value._d).endOf('day').toISOString();

        filteredItems.endDate = moment(this.endDate.value._d).endOf('day').toISOString();
      }
      // filter.endDate = this.formatDate(this.endDate.value._d);
      else {
        // this.formatdate(this.data.endDate)
        // filter.endDate = this.data.endDate
        // this.formatdate(this.endDate.value)
        // filter.endDate = this.formatedDate
        filter.endDate = this.endDate.value
        // filteredItems.date = this.endDate.value
        filteredItems.endDate = this.endDate.value
      }
    }
    if (this.assessmentStatusControl.value.type) {
      filter['assessmentDate'] = this.assessmentStatusControl.value.type;
      filteredItems['assessmentDate'] = this.assessmentStatusControl.value.type;
    }
    // if (this.assessmentStatus.value) {
    //   let dateControlArr = [];
    //   switch (this.assessmentStatus.value) {
    //     case 'first_assessment':

    //       dateControlArr = [this.firstAssessmentStartPickerControl, this.firstAssessmentLastPickerControl];
    //       this.lastAssessmentLastPickerControl.setValue('', { emitEvent: false });
    //       this.lastAssessmentLastPickerControl.setValue('', { emitEvent: false })
    //       break;
    //     case 'recent_assessment':

    //       dateControlArr = [this.lastAssessmentStartPickerControl, this.lastAssessmentLastPickerControl];
    //       this.firstAssessmentStartPickerControl.setValue('', { emitEvent: false });
    //       this.firstAssessmentLastPickerControl.setValue('', { emitEvent: false });
    //       break;
    //   }
    //   for (let i = 0; i < dateControlArr.length; i++) {
    //     let ele: FormControl = dateControlArr[i];
    //     this.errorInDateFormat = !ele.valid;
    //     if (this.errorInDateFormat) {
    //       break;
    //     } else {
    //       if (ele.value instanceof Date) {
    //         filter[this.keysObj[i]] = this.formatDateInRespectiveFormat(ele.value, i);
    //         filteredItems[this.keysObj[i]] = this.formatDateInRespectiveFormat(ele.value, i);
    //       } else if (ele.value._d instanceof Date) {
    //         filter[this.keysObj[i]] = this.formatDateInRespectiveFormat(ele.value._d, i);
    //         filteredItems[this.keysObj[i]] = this.formatDateInRespectiveFormat(ele.value._d, i);
    //       }
    //     }
    //   }
    //   filter.assessment_type = this.assessmentStatus.value;
    //   filteredItems.assessment_type = this.assessmentStatus.value;

    // }



    if (this.surveyor.value != "" || this.surveyor.value != undefined) {
      filter.surveyor = this.surveyor.value
      filteredItems.surveyor = this.surveyor.value
    }
    if (this.DAMService.selectedTab === 0) {

      this.DAMService.tierFormArray = <FormArray>this.tierFormGroup.get('tiers');
    } else {
      this.DAMService.adminTierFormArray = <FormArray>this.tierFormGroup.get('tiers');
    }

    if (this.ward.value != "" || this.ward.value != undefined) {
      if (this.DAMService.selectedTab === 0) {
        filter.ward = this.DAMService.tierFormArray.at(this.DAMService.tierFormArray.controls.length - 1).get('tierEntity').value._id;
        filteredItems.ward = this.DAMService.tierFormArray.at(this.DAMService.tierFormArray.controls.length - 1).get('tierEntity').value._id;
      } else {

        filter.ward = this.DAMService.adminTierFormArray.at(this.DAMService.adminTierFormArray.controls.length - 1).get('tierEntity').value._id;
        filteredItems.ward = this.DAMService.adminTierFormArray.at(this.DAMService.adminTierFormArray.controls.length - 1).get('tierEntity').value._id;

      }


    }

    if (this.approvalStatus.value == undefined) {
      this.approvalStatus.setValue(null);
    }
    if (this.approvalStatus.value != "") {
      filter.approvalStatus = this.approvalStatus.value;
      filteredItems.approvalStatus = this.approvalStatus.value;
    }
    if (filter.approvalStatus === 2) {
      filter['is_deleted'] = false
    }
    if (filter.approvalStatus === 5) {
      filter.approvalStatus = 2
      filter['is_deleted'] = true
    }
    filter.dataField = this.dataFieldControl.value;
    // if(Object.keys(filter.dataField).length === 0){
    //   filter.dataField={
    //     text:"Created At",
    //     type:"createdAt"
    //   }
    // }
    filteredItems.dataField = this.dataFieldControl.value;
    filterArray.push({ filter: filter, filterItems: filteredItems });
    if (this.DAMService.selectedTab == 0) {

      this.DAMService.buildingDataField = this.dataFieldControl.value;
    } else {
      this.DAMService.adminBuildingDataField = this.dataFieldControl.value;
    }
    this.dialogRef.close(filterArray);
  }

  closeFilters() {
    this.dialogRef.close(null);
  }

  // formatDate(date) {
  //   //date.setDate(date.getDate() + 1) 
  //   let firstDate = date.getDate();
  //   if (firstDate < 10) {
  //     firstDate = "0" + firstDate;
  //   }

  //   let firstMonth = date.getMonth() + 1;
  //   if (firstMonth < 10) {
  //     firstMonth = "0" + firstMonth;
  //   }
  //   this.start = date.getFullYear() + "-" +
  //     firstMonth + "-" +
  //     firstDate + this.timeFormat;

  //   //return date.toISOString().substring(0, 10) + 'T00:00:00.000Z';
  // }
  formatLastDate(date) {

    let newdate = new Date(date)
    newdate.setDate(newdate.getDate())
    let secondDate = newdate.getDate()
    let formatdate = date.getDate()
    if (formatdate < 10) {
      formatdate = "0" + secondDate
    }
    let second = secondDate.toString()
    if (secondDate < 10) {
      second = "0" + secondDate
    }
    let secondMonth = newdate.getMonth() + 1
    let secondmon = secondMonth.toString()
    if (secondMonth < 10) {
      secondmon = "0" + secondMonth
    }
    this.end = newdate.getFullYear() + "-" +
      secondmon + "-" +
      second + this.timeFormat
    this.date = date.getFullYear() + "-" +
      secondmon + "-" +
      formatdate + this.timeFormat


  }
  formatdate(date) {

    let newdate = new Date(date)
    newdate.setDate(newdate.getDate() + 1)
    let secondDate = newdate.getDate()
    let second = secondDate.toString()
    if (secondDate < 10) {
      second = "0" + secondDate
    }
    let secondMonth = newdate.getMonth() + 1
    let secondmon = secondMonth.toString()
    if (secondMonth < 10) {
      secondmon = "0" + secondMonth
    }
    this.formatedDate = newdate.getFullYear() + "-" +
      secondmon + "-" +
      second + this.timeFormat
  }

  listRemainingWards() {

    this.wardsPagination.page++;
    //this.wardsPagination.search = this.searchWard.value;
    let apiData: GetParam = {
      params: ["wards"],
      queryParams: this.wardsPagination
    };
    this._store.dispatch(new PropertyTaxActions.ListWards(apiData));
  };
  onWardsTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight; // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 0;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation >= limit) {
      this.wardsPagination.page++;
      this.listRemainingWards();
    }
  };

  removeTiers(tier) {

    let tierFormArray: FormArray = <FormArray>this.tierFormGroup.get('tiers');
    let wardArrayLength = tierFormArray.controls.length;
    if (tier < wardArrayLength) {
      for (let i = wardArrayLength; i > tier; i--) {
        tierFormArray.removeAt(i - 1);
      }
    }

  }
  addTiers(val) {
    // let tierFormArray: FormArray = <FormArray>this.tierFormGroup.get('tiers');
    let wardArrayLength = this.tierFormArray.controls.length;
    if (val > wardArrayLength) {
      for (let i = wardArrayLength; i < val; i++) {
        this.tierFormArray.push(this.setFormGroup(i));
        // if(i !== 0) {
        this.tierFormArray.at(i).get('tierEntity').setValue(this.tierArrayForFiltering[i].tierId);
        // }
      }
    }
  }

  setFormGroup(i) {

    return this.fb.group({
      tierLabel: this._urbanLocalBodiesService.setRomanConvention(i),
      // tierLabel: this.setLabel(i),
      tierEntity: {},
      tierEntities: [],
      tierErrorValidation: false,
      tierEntityValidation: false,
      disabled: (i == 0) ? false : true
    })
  }

  setLabel(val) {
    switch (val) {
      case 0:
        return this.data.tierTitles[0].title
      case 1:
        return this.data.tierTitles[1].title
      case 2:
        return this.data.tierTitles[2].title
      case 3:
        return this.data.tierTitles[3].title
    }
  }

  createOptionsWithTierData(selectedTier) {
    // get entities from tierId

    let queryParams = this.setRequiredQueryParams(selectedTier);
    // if (this.filteredSubscription) this.filteredSubscription.unsubscribe();
    this._store.dispatch(new PropertyTaxActions.GetFilteredDataStartAction(selectedTier, queryParams));

  }

  setRequiredQueryParams(tier) {
    let obj = {};
    if (tier == 1) {
      return {}
    } else if (tier == 2) {
      if (typeof (this.tierFormArray.at(0).get('tierEntity').value) == 'object') {

        obj['ward'] = this.tierFormArray.at(0).get('tierEntity').value._id;
      } else {

        obj['ward'] = this.tierFormArray.at(0).get('tierEntity').value;
      }

    } else if (tier == 3) {
      if (typeof (this.tierFormArray.at(0).get('tierEntity').value) === 'object') {
        obj['ward'] = this.tierFormArray.at(0).get('tierEntity').value._id;
      } else {
        obj['ward'] = this.tierFormArray.at(0).get('tierEntity').value;

      }
      if (typeof (this.tierFormArray.at(1).get('tierEntity').value) === 'object') {
        obj['tier2'] = this.tierFormArray.at(1).get('tierEntity').value._id
      } else {
        obj['tier2'] = this.tierFormArray.at(1).get('tierEntity').value;
      }

    } else if (tier === 4) {
      if (typeof (this.tierFormArray.at(0).get('tierEntity').value) === 'object') {
        obj['ward'] = this.tierFormArray.at(0).get('tierEntity').value._id;
      } else {
        obj['ward'] = this.tierFormArray.at(0).get('tierEntity').value;
      }
      if (typeof (this.tierFormArray.at(1).get('tierEntity').value) === 'object') {

        obj['tier2'] = this.tierFormArray.at(1).get('tierEntity').value._id;
      } else {

        obj['tier2'] = this.tierFormArray.at(1).get('tierEntity').value;
      }
      if (typeof (this.tierFormArray.at(2).get('tierEntity').value) === 'object') {

        obj['tier3'] = this.tierFormArray.at(2).get('tierEntity').value._id;
      } else {

        obj['tier3'] = this.tierFormArray.at(2).get('tierEntity').value;
      }




    }
    return obj
  }

  entityComparision(o1: any, o2: any) {
    return o1 && o2 && o1._id === o2._id
  }

  onEntitySelection(event, index) {
    if (event.value !== '') {
      if (this.tierFormArray.controls.length - 1 > index) {
        this.tierFormArray.at(index + 1).enable();
        this.createOptionsWithTierData(index + 2);
      }
    }
  }
  dataFieldComparision(o1: any, o2: any) {

    return (o1 && o2 && o1.type === o2.type)

  }
  formatDateInRespectiveFormat(date, index) {
    if (date) {
      if ([1, 3, 5].includes(index)) {
        return moment(date).endOf('day').toISOString();
      } else {

        let firstDate = date.getDate();
        if (firstDate < 10) {
          firstDate = "0" + firstDate;
        }

        let firstMonth = date.getMonth() + 1;
        if (firstMonth < 10) {
          firstMonth = "0" + firstMonth;
        }
        return date.getFullYear() + "-" +
          firstMonth + "-" +
          firstDate + this.timeFormat;
      }
    } else {
      return false
    }
  }
  setValidators(formConrol: FormControl) {
    formConrol.setValidators([Validators.required])
    formConrol.updateValueAndValidity({ emitEvent: false })
  }
  compareFn(o1, o2) {
    return o1 && o2 && o1.type == o2.type
  }

  dataFieldEmpty() {
    // console.log(Object.keys(this.dataFieldControl.value).length !== 0)
    return Object.keys(this.dataFieldControl.value).length !== 0
  }

  disableCondition() {
    let disable = false
    if (this.assessmentStatusControl.value.type) {
      if (this.startDate.value != "" && this.startDate.value != undefined && this.endDate.value != "" && this.endDate.value != undefined) {
        disable = false
      }
      else {
        disable = true
      }
    }
    if (this.approvalStatus.value != "" && this.approvalStatus.value) {
      if (this.startDate.value != "" && this.startDate.value != undefined && this.endDate.value != "" && this.endDate.value != undefined) {
        disable = false
      }
      else {
        disable = true
      }
    }

    if (Object.keys(this.dataFieldControl.value).length !== 0) {
      if (this.startDate.value != "" && this.startDate.value != undefined && this.endDate.value != "" && this.endDate.value != undefined) {
        disable = false
      }
      else {
        disable = true
      }
    }

    if (this.surveyor.value != "" || this.surveyor.value) {
      if (this.startDate.value == "" && this.startDate.value == undefined && this.endDate.value == "" && this.endDate.value == undefined) {
        disable = false
      }
      if (this.startDate.value != "" && this.endDate.value == "") {
        disable = true
      }
      if (this.startDate.value == "" && this.endDate.value != "") {
        disable = true
      }
      if (this.startDate.value != "" && this.startDate.value != undefined && this.endDate.value != "" && this.endDate.value != undefined) {
        disable = false
      }
    }
    let array = <FormArray>this.tierFormGroup.get('tiers')
    let ward = array.at(array.controls.length - 1).get('tierEntity').value._id;
    if (ward) {
      if (this.startDate.value == "" && this.startDate.value == undefined && this.endDate.value == "" && this.endDate.value == undefined) {
        disable = false
      }
      if (this.startDate.value != "" && this.endDate.value == "") {
        disable = true
      }
      if (this.startDate.value == "" && this.endDate.value != "") {
        disable = true
      }
      if (this.startDate.value != "" && this.startDate.value != undefined && this.endDate.value != "" && this.endDate.value != undefined) {
        disable = false
      }
    }
    if (((this.startDate.value != "" && this.startDate.value != undefined) || (this.endDate.value != "" && this.endDate.value != undefined)) && Object.keys(this.dataFieldControl.value).length === 0) {
      disable = true
    }
    return disable
  }

  getTierEntity() {
    let disable
    let array = <FormArray>this.tierFormGroup.get('tiers')
    let ward = array.at(array.controls.length - 1).get('tierEntity').value._id;
    let tier = ward ? true : false
    disable = tier
    if (this.reset) {
      let surveyor = (this.surveyor.value != "" && this.surveyor.value != undefined) ? true : false
      let assessment = this.assessmentStatusControl.value.type ? true : false
      let dataApproval = (this.approvalStatus.value != "" && this.approvalStatus.value) ? true : false
      let dataField = (Object.keys(this.dataFieldControl.value).length !== 0) ? true : false
      let to = (this.startDate.value != "" && this.startDate.value != undefined) ? true : false
      let from = (this.endDate.value != "" && this.endDate.value != undefined) ? true : false
      if (!surveyor && !tier &&!assessment && !dataApproval && !dataField && !to && !from){
        disable = false
      }
      else{
        this.reset=false
        disable = true
      }
    }
    return disable
  }

  disableFunction() {
    let disable = false
    let surveyor = (this.surveyor.value != "" && this.surveyor.value != undefined) ? true : false
    let array = <FormArray>this.tierFormGroup.get('tiers')
    let ward = array.at(array.controls.length - 1).get('tierEntity').value._id;
    let tier = ward ? true : false
    let assessment = this.assessmentStatusControl.value.type ? true : false
    let dataApproval = (this.approvalStatus.value != "" && this.approvalStatus.value) ? true : false
    let dataField = (Object.keys(this.dataFieldControl.value).length !== 0) ? true : false
    let to = (this.startDate.value != "" && this.startDate.value != undefined) ? true : false
    let from = (this.endDate.value != "" && this.endDate.value != undefined) ? true : false

    if (!tier && !this.reset) {
      this.errorType = 'ward'
      return disable = true
    }

    if (surveyor && !tier) {
      // if (dataApproval && !dataField) {
      //   this.errorType='dataField'
      //   return disable = true
      // }
      if (dataApproval && dataField) {
        return disable = false
      }
      if ((from || to) && !(dataField || assessment)) {
        this.errorType = 'dataField'
        return disable = true
      }
      if ((from || to) && (dataField || assessment)) {
        return disable = false
      }
    }

    if (tier && !surveyor) {
      // if (dataApproval && !dataField) {
      //   this.errorType='dataField'
      //   return disable = true
      // }
      if (dataApproval && dataField) {
        return disable = false
      }
      if ((from || to) && !(dataField || assessment)) {
        this.errorType = 'dataField'
        return disable = true
      }
      if ((from || to) && (dataField || assessment)) {
        return disable = false
      }
    }

    if (surveyor && tier) {
      if ((to || from) && !dataField && !assessment) {
        this.errorType = 'dataField'
        return disable = true
      }
      // if (assessment && dataApproval && !dataField) {
      //   this.errorType='dataField'
      //   return disable = true
      // }
      // if (assessment && dataApproval && !(to || from)) {
      //   this.errorType='date'
      //   return disable = true
      // }
      // if (assessment && dataApproval && dataField) {
      //   if (to || from) {
      //     return disable = false
      //   }
      //   else {
      //     this.errorType="date"
      //     return disable = true
      //   }
      // }
      // if (assessment && (to || from) && !dataField) {
      //   this.errorType='dataField'
      //   return disable = true
      // }
      if (assessment && (to || from)) {
        return disable = false
      }
      if (dataField && (to || from)) {
        return disable = false
      }
    }

    if (assessment && !surveyor && !tier) {
      // if (dataApproval) {
      //   if (to || from) {
      //     return disable = false
      //   }
      //   else {
      //     this.errorType="date"
      //     return disable = true
      //   }
      // }
    }

    if (dataApproval && !surveyor && !tier) {
      // if (!dataField) {
      //   this.errorType="dataField"
      //   return disable = true
      // }
      if ((dataField || assessment) && (to || from)) {
        return disable = false
      }
      if (!(dataField || assessment) && (to || from)) {
        this.errorType = "dataField"
        return disable = true
      }
      // if(!dataField) {
      //   this.errorType="dataField"
      //   return disable = true
      // }
    }

    if (!surveyor && !tier && !assessment && !dataApproval && !dataField && (to || from)) {
      this.errorType = "dataField"
      return disable = true
    }
    else if (!surveyor && !tier && !assessment && !dataApproval && dataField && (to || from)) {
      return disable = false
    }
    return disable
  }

  // onAssessmentChange(event) {
  //   switch (event.value) {
  //     case 'first_assessment':
  //       this.lastAssessmentStartPickerControl.setValue('', { emitEvent: false });
  //       this.lastAssessmentLastPickerControl.setValue('', { emitEvent: false });
  //       break;
  //     case 'last_assessment':
  //       this.firstAssessmentStartPickerControl.setValue('', { emitEvent: false });
  //       this.firstAssessmentLastPickerControl.setValue('', { emitEvent: false });
  //       break;
  //   }
  // }

}
