import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {  Actions, ofType } from '@ngrx/effects';
import {  Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HideLoaderAction, ShowLoaderAction } from 'src/app/store/actions/loader.actions';
import { AddLayersStartAction, GetSessionDetailsStartAction, SessionManagementTypes } from 'src/app/store/actions/session-management.actions';
import { activeSessionSelector } from 'src/app/store/selectors/session-management.selector';
import { LayerService } from 'src/app/_services';
import { SessionService } from 'src/app/_services/session.service';
import { SessionPublishService } from 'src/app/_services/sessionPublish.service';
import connProp from "src/assets/connection.properties.json";
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'publish-layer-dialog',
    templateUrl: './publish-layer-dialog.component.html',
    styleUrls: ['./publish-layer-dialog.component.scss']
})

export class PublishLayerDialogComponent implements OnDestroy {

    viewType: string = 'addToSession';
    connectionProps: any = connProp;

    subscriptionArr: any[] = []
    sessionLayers: any[] = [];
    buttonClicked: boolean = false;
    publishButtonClicked: boolean = false;


    constructor(public dialogRef: MatDialogRef<PublishLayerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
        private publishService: SessionPublishService,
        private _store: Store<any>, private sessionService: SessionService, private layerService: LayerService,
        private toaster: ToastrService, private actions$:Actions

    ) {

    }
    ngOnDestroy() {
        this.subscriptionArr.forEach((sub) => sub.unsubscribe());
        this.subscriptionArr = [];
    }

    ngOnInit() {
        if(this.data.type === 'publish') {

            this.viewType= 'publishSession';
        } else {

            this.viewType ='addToSession';
        }

        this.subscriptionArr.push(this._store.select(activeSessionSelector).subscribe((resp: any) => {
            if (resp && resp.orders && this.buttonClicked) {
                this.buttonClicked = false;
                if (this.viewType === 'publishSession') {
                    this._store.dispatch(new HideLoaderAction());
                    // this.sessionLayers = resp.orders.map(({ layer_id }) => layer_id).filter((layer) => layer.show);
                   
                    this.sessionLayers = resp.orders.map(({ layer_id }) => layer_id).filter((layer) => layer.show || false).filter(({ layer_type }) => layer_type !== 4).filter((layer) => this.layerService.filterGraduatedLayers(layer)).map((layer) => layer.parent_layer_id);
                    if (this.publishButtonClicked) {
                        this.publishService.sessionDetails = { ...resp };
                        this.publishService.layerDetails = resp.orders.filter(({ layer_id }) => layer_id.show);
                        this.publishService.baseLayer = resp.base_layer;
                        this.sessionService.setBaseLayer = resp.base_layer;
    
                        this.sessionService.showUnApprovedData = true;
                        this.publishService.wfsPublishDetails= {
                            customMaxZoomRange: 22,
                            customMinZoomRange: 1,
                            searchBar: false,
                            showMapLegend: false,
                            zoomRangesDefault: false,
                            attributeDisplayOnHover: true
                        }
                        this.publishService.publishSession(this.data.sessionId, 'public', true, 'WFS', { 'session_layers': this.sessionLayers })
                    }

                }
            }
        }))

     this.subscriptionArr.push( this.actions$.pipe(
            ofType(SessionManagementTypes.uploadStyleForSessionPublishSuccess),
            tap((response: any) => {
                this._store.dispatch(new HideLoaderAction());
                this.toaster.success('WFS Published');
                this.dialogRef.close(true);


            })).subscribe());

    }

    buttonClick(bool: boolean) {
        this.buttonClicked = true;
        if (bool) {
            if (this.viewType === 'addToSession') {
                let finalLayer = [];
                this.data.layer.forEach((item)=>{
                    finalLayer.push(item.layer_id)
                })
                const finalObj = { layer_ids: finalLayer };
                const subURL =
                    `${this.connectionProps.addLayersToSession}` + this.data.sessionId;
                const payload = {
                    subURL: subURL,
                    datum: finalObj
                }
                this.publishService.typeOfAddSession=this.data.typeOfAddSession
                this._store.dispatch(new AddLayersStartAction(payload));
                this.viewType = 'publishSession';
            } else {
                this.publishButtonClicked = true;
                this._store.dispatch(new ShowLoaderAction());

                this._store.dispatch(new GetSessionDetailsStartAction(this.data.sessionId));

            }
        } else {
            this.dialogRef.close(false);

        }
    }
}