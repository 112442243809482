import { Action } from '@ngrx/store';

export enum DataManagementActionTypes {
  getAssignedUsersSurveyersDataStart = "[DATA-MANAGEMENT] Get Assigned Users Surveyers Data Start",
  getAssignedUsersSurveyersDataSuccess = "[DATA-MANAGEMENT] Get Assigned Users Surveyers Data Success",
  assignUsersSurveyersToFormStart = "[DATA-MANAGEMENT] Assign Users Surveyers To Form Start",
  assignUsersSurveyersToFormSuccess = "[DATA-MANAGEMENT] Assign Users Surveyers To Form Success",
  getAvailableFormWidgetsStart = "[DATA-MANAGEMENT] Get Available Form Widgets Start",
  getAvailableFormWidgetsSuccess = "[DATA-MANAGEMENT] Get Available Form Widgets Success",
  getAllDataCollectedLayersStart = "[DATA-MANAGEMENT] Get All Data Collected Layers Start",
  getAllDataCollectedLayersSuccess = "[DATA-MANAGEMENT] Get All Data Collected Layers Success",
  getSelectedTemplateDetailsStart = "[DATA-MANAGEMENT] Get Selected Template Details Start",
  getSelectedTemplateDetailsSuccess = "[DATA-MANAGEMENT] Get Selected Template Details Success",
  deleteTemplateStart = "[DATA-MANAGEMENT] Delete Template Start",
  deleteTemplateSuccess = "[DATA-MANAGEMENT] Delete Template Success",
  createTemplateStart = "[DATA-MANAGEMENT] Create Template Start",
  createTemplateSuccess = "[DATA-MANAGEMENT] Create Template Success",
  updateTemplateStart = "[DATA-MANAGEMENT] Update Template Start",
  updateTemplateSuccess = "[DATA-MANAGEMENT] Update Template Success",
  getVectorLayersStart = "[DATA-MANAGEMENT] search vector layer start",
  getVectorLayersSuccess = "[DATA-MANAGEMENT] search vector layer success",
  DuplicateLayerStart = "[DATA-MANAGEMENT] duplicate layer start",
  DuplicateLayerSuccess = "[DATA-MANAGEMENT] duplicate layer success",
  exportVectorLayerStart = "[DATA-MANAGEMENT] Export Vector Layer Start",
  exportVectorLayerSuccess = "[DATA-MANAGEMENT] Export Vector Layer Success",
  createEmptyVectorLayerStart = "[DATA-MANAGEMENT] Create Empty Vector Layer Start",
  createEmptyVectorLayerSuccess = "[DATA-MANAGEMENT] Create Empty Vector Layer Success",
  resetStateStart = "[data -Management] resest state for forms start",
  getCallForProjects = "[Data-Management] get call for ",
  getProjectionSystemsStart = "[FILE-UPLOAD] Get Projection Systems Start",
  getProjectionSystemsSuccess = "[FILE-UPLOAD] Get Projection Systems Success",
  statusChangeStartAction = "[DATA-MANAGEMENT] status change start action",
  statusChangeSuccessAction = "[DATA-MANAGEMENT] status change success action",
  dataCollectedToVectorLayerStartAction = "[DATA-MANAGEMENT] dataCollected vector layer start action",
  getLayerById = "[DATA-MANAGEMENT] Get Layer By ID",
  getLayerByIdSuccess = "[DATA-MANAGEMENT] Get Layer By ID Success",
  editLayerAttributes = "[DATA-MANAGEMENT] edit vector layer",
  getAllDataLibraryCategoriesStart = "[DATA-LIBRARY] Get All Data Library Categories Start",
  getAllDataLibraryCategoriesSuccess = "[DATA-LIBRARY] Get All Data Library Categories Success",
  getDataLibraryCategoryDetailsStart = "[DATA-LIBRARY] Get Data Library Category Details Start",
  getDataLibraryCategoryDetailsSuccess = "[DATA-LIBRARY] Get Data Library Category Details Success",
  getDataLibrarySearchStart = "[DATA-LIBRARY] Get Data Library Search Start",
  getDataLibrarySearchSuccess = "[DATA-LIBRARY] Get Data Library Search Success",
  addSelectedDataLayerToSessionStart = "[DATA-LIBRARY] Add Selected Data Layer To Session Start",
  addSelectedDataLayerToSessionSuccess = "[DATA-LIBRARY] Add Selected Data Layer To Session Success",
  getVectorListInfoStart = "[DATA-LIBRARY] Get Vector List Info Start",
  getVectorListInfoSuccess = "[DATA-LIBRARY] Get Vector List Info Success",
  getDLAttributesStart = "[DATA-LIBRARY] Get Data Library Attributes Start",
  getDLAttributesSuccess = "[DATA-LIBRARY] Get Data Library Attributes Success",
  getTabularDLAttributesStart = "[DATA-LIBRARY] Get Data Library Vector-Tabular Attributes Start",
  getTabularDLAttributesSuccess = "[DATA-LIBRARY] Get Data Library Vector-Tabular Attributes Success",
  joinYourDataStart = "[DATA-LIBRARY] Join Your Data Start",
  joinYourDataSuccess = "[DATA-LIBRARY] Join Your Data Success",
  joinYourDataTabularStart = "[DATA-LIBRARY] Join Your Data For Vector-Tabular Start",
  joinYourDataTabularSuccess = "[DATA-LIBRARY] Join Your Data  Vector-Tabular Success",
  saveJoinResultAsLayerStart = "[DATA-LIBRARY] Save Join Result As Layer Start",
  saveJoinResultAsLayerSuccess = "[DATA-LIBRARY] Save Join Result As Layer Success",
  saveVTJoinResultAsLayerStart = "[DATA-LIBRARY] Save Vector-Tabular Join Result As Layer Start",
  saveVTJoinResultAsLayerSuccess = "[DATA-LIBRARY] Save Vector-Tabular Join Result As Layer Success",
  addJoinedLayerToNewSessionStart = "[DATA-LIBRARY] Add Joined Layer To New Session Start",
  addJoinedLayerToNewSessionSuccess = "[DATA-LIBRARY] Add Joined Layer To New Session Success",
  getTabularDataStart = "[DATA-MANAGEMENT] Get Tabular Data Start",
  getTabularDataSuccess = "[DATA-MANAGEMENT] Get Tabular Data Success",
  getLibraryAndYourDataLayersListStart = "[DATA-MANAGEMENT] Get Library And Your-Data Layers List Start",
  getLibraryAndYourDataLayersListSuccess = "[DATA-MANAGEMENT] Get Library And Your-Data Layers List Success",
  deleteTabularDataStart = "[DATA-MANAGEMENT] Delete Tabular Data Start",
  deleteTabularDataSuccess = "[DATA-MANAGEMENT] Delete Tabular Data Success",
  getDataLibraryLayerDetailsStart = "[DATA-LIBRARY] Get Data Library Layer Details Start",
  getDataLibraryLayerDetailsSuccess = "[DATA-LIBRARY] Get Data Library Layer Details Success",
  getGroupNamesStart = "[DATA-LIBRARY] Get Group Names Start",
  getGroupNamesSuccess = "[DATA-LIBRARY] Get Group Names Success",
  getGroupLayersStart = "[DATA-LIBRARY] Get Group Layers Start",
  getGroupLayersSuccess = "[DATA-LIBRARY] Get Group Layers Success",
  uploadLibraryDataStart = "[DATA-LIBRARY] Upload Library Data Start",
  uploadLibraryDataSuccess = "[DATA-LIBRARY] Upload Library Data Success",
  // getYourDataGroupsStart = "[YOUR-DATA] Get Your Data Groups Start",
  // getYourDataGroupsSuccess = "[YOUR-DATA] Get Your Data Groups Success",
  getYourDataLayersListStart = "[YOUR-DATA] Get Your Data Layers List Start",
  getYourDataLayersListSuccess = "[YOUR-DATA] Get Your Data Layers List Success",
  exportTabularDataStart = "[YOUR-DATA] Export Tabular Data Start",
  exportTabularDataSuccess = "[YOUR-DATA] Export Tabular Data Success",
  layersListStart = "[YOUR-DATA] Get Layers List Start Action",
  layersListSuccess ="[YOUR-DATA] Get Layers List Success Action",
  getLayerPreviewDataStart = "[YOUR-DATA] Get Layer Preview Data Start Action",
  getLayerPreviewDataSuccess = "[YOUR-DATA] Get Layer Preview Data Success Action",
  getDataLibraryDataStart = "[DATA-LIBRARY] Get Data Library Data Start",
  getDataLibraryDataSuccess = "[DATA-LIBRARY] Get Data Library Data Success",
  getSelectedGroupLayerIdsStart = "[YOUR-DATA] Get Selected Group Layer Ids Start",
  getSelectedGroupLayerIdsSuccess = "[YOUR-DATA] Get Selected Group Layer Ids Success",
  createUpdateGroupStart = "[YOUR-DATA] Create Update Group Start",
  createUpdateGroupSuccess = "[YOUR-DATA] Create Update Group Success",
  uploadRasterDataStart = "[YOUR-DATA] upload Raster Data Start",
  uploadRasterDataSuccess = "[YOUR-DATA] upload Raster Data Success"
}

export class GetAssignedUsersSurveyersDataStartAction implements Action {
  readonly type = DataManagementActionTypes.getAssignedUsersSurveyersDataStart;
  constructor(public payload) { }
}

export class GetAssignedUsersSurveyersDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getAssignedUsersSurveyersDataSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class AssignUsersSurveyersToFormStartAction implements Action {
  readonly type = DataManagementActionTypes.assignUsersSurveyersToFormStart;
  constructor(public payload) { }
}

export class AssignUsersSurveyersToFormSuccessAction implements Action {
  readonly type = DataManagementActionTypes.assignUsersSurveyersToFormSuccess;
  constructor(public data: any) { }
}

export class GetAvailableFormWidgetsStartAction implements Action {
  readonly type = DataManagementActionTypes.getAvailableFormWidgetsStart;
  constructor() { }
}

export class GetAvailableFormWidgetsSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getAvailableFormWidgetsSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class GetAllDataCollectedLayersStartAction implements Action {
  readonly type = DataManagementActionTypes.getAllDataCollectedLayersStart;
  constructor(public payload: any, public status: boolean = false) { }
}

export class GetAllDataCollectedLayersSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getAllDataCollectedLayersSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class GetSelectedTemplateDetailsStartAction implements Action {
  readonly type = DataManagementActionTypes.getSelectedTemplateDetailsStart;
  constructor(public paylod) { }
}

export class GetSelectedTemplateDetailsSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getSelectedTemplateDetailsSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}

export class DeleteTemplateStartAction implements Action {
  readonly type = DataManagementActionTypes.deleteTemplateStart;
  constructor(public payload) { }
}

export class DeleteTemplateSuccessAction implements Action {
  readonly type = DataManagementActionTypes.deleteTemplateSuccess;
  constructor(public response: any) { }
}

export class CreateTemplateStartAction implements Action {
  readonly type = DataManagementActionTypes.createTemplateStart;
  constructor(public payload) { }
}

export class CreateTemplateSuccessAction implements Action {
  readonly type = DataManagementActionTypes.createTemplateSuccess;
  constructor() { }
}

export class UpdateTemplateStartAction implements Action {
  readonly type = DataManagementActionTypes.updateTemplateStart;
  constructor(public payload) { }
}

export class UpdateTemplateSuccessAction implements Action {
  readonly type = DataManagementActionTypes.updateTemplateSuccess;
  constructor() { }
}

export class GetVectorLayersStartAction implements Action {
  readonly type = DataManagementActionTypes.getVectorLayersStart;
  constructor(public payload: any) { }
}

export class GetVectorLayersSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getVectorLayersSuccess;
  data;
  constructor(response: any) {
    this.data = response;
  }
}
export class DuplicateLayerStartAction implements Action {
  readonly type = DataManagementActionTypes.DuplicateLayerStart;
  constructor(public sessionId: string) { }
}

export class DuplicateLayerSuccessAction implements Action {
  readonly type = DataManagementActionTypes.DuplicateLayerSuccess;
  constructor(public data) { }
}

export class ExportVectorLayerStartAction implements Action {
  readonly type = DataManagementActionTypes.exportVectorLayerStart;
  constructor(public payload: any) { }
}

export class ExportVectorLayerSuccessAction implements Action {
  readonly type = DataManagementActionTypes.exportVectorLayerSuccess;
  constructor(public response: any) { }
}

export class CreateEmptyVectorLayerStartAction implements Action {
  readonly type = DataManagementActionTypes.createEmptyVectorLayerStart;
  constructor(public payload: any) { }
}

export class CreateEmptyVectorLayerSuccessAction implements Action {
  readonly type = DataManagementActionTypes.createEmptyVectorLayerSuccess;
  constructor(public response: any) { }
}
export class ResetStateForFormsAction implements Action {
  readonly type = DataManagementActionTypes.resetStateStart;
  constructor() { }
}
// export class GetCallForProjectAction implements Action {
//   // readonly type =DataManagementActionTypes.
// }

export class GetProjectionSystemsStartAction implements Action {
  readonly type = DataManagementActionTypes.getProjectionSystemsStart;
  constructor(public payload: any) { }
}

export class GetProjectionSystemsSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getProjectionSystemsSuccess;
  constructor(public response: any) { }
}
export class ChangeStatusStartAction implements Action {
  readonly type = DataManagementActionTypes.statusChangeStartAction;
  constructor(public id, public payload) { }
}
export class ChangeStatusSuccessAction implements Action {
  readonly type = DataManagementActionTypes.statusChangeSuccessAction;
  constructor(public response) { }
}
export class DataCollectedToVectorLayerStartAction implements Action {
  readonly type = DataManagementActionTypes.dataCollectedToVectorLayerStartAction;
  constructor(public obj, public params: any) { }
}

export class GetLayerById implements Action {
  readonly type = DataManagementActionTypes.getLayerById;
  constructor(public id) { }
}

export class GetLayerByIdSuccess implements Action {
  readonly type = DataManagementActionTypes.getLayerByIdSuccess;
  constructor(public response) { }
}

export class EditVectorlayerAttributes implements Action {
  readonly type = DataManagementActionTypes.editLayerAttributes;
  data
  constructor(public id, public payload) {
    this.data = payload
  }
}

export class GetAllDataLibraryCategoriesStartAction implements Action {
  readonly type = DataManagementActionTypes.getAllDataLibraryCategoriesStart;
  constructor() { }
}

export class GetAllDataLibraryCategoriesSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getAllDataLibraryCategoriesSuccess;
  constructor(public response) { }
}

export class GetDataLibraryCategoryDetailsStartAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibraryCategoryDetailsStart;
  constructor(public payload) { }
}

export class GetDataLibraryCategoryDetailsSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibraryCategoryDetailsSuccess;
  constructor(public response) { }
}

export class GetDataLibrarySearchStartAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibrarySearchStart;
  constructor(public payload) { }
}

export class GetDataLibrarySearchSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibrarySearchSuccess;
  constructor(public response) { }
}

export class AddSelectedDataLayerToSessionStartAction implements Action {
  readonly type = DataManagementActionTypes.addSelectedDataLayerToSessionStart;
  constructor(public payload) { }
}

export class AddSelectedDataLayerToSessionSuccessAction implements Action {
  readonly type = DataManagementActionTypes.addSelectedDataLayerToSessionSuccess;
  constructor(public response) { }
}

export class GetVectorListInfoStartAction implements Action {
  readonly type = DataManagementActionTypes.getVectorListInfoStart;
  constructor(public payload) { }
}

export class GetVectorListInfoSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getVectorListInfoSuccess;
  constructor(public response) { }
}

export class GetDLAttributesStartAction implements Action {
  readonly type = DataManagementActionTypes.getDLAttributesStart;
  constructor(public payload) { }
}

export class GetDLAttributesSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getDLAttributesSuccess;
  constructor(public response) { }
}

export class GetTabularDLAttributesStartAction implements Action {
  readonly type = DataManagementActionTypes.getTabularDLAttributesStart;
  constructor(public payload) { }
}

export class GetTabularDLAttributesSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getTabularDLAttributesSuccess;
  constructor(public response) { }
}

export class JoinYourDataStartAction implements Action {
  readonly type = DataManagementActionTypes.joinYourDataStart;
  constructor(public payload) { }
}

export class JoinYourDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.joinYourDataSuccess;
  constructor(public response) { }
}

export class JoinYourDataTabularStartAction implements Action {
  readonly type = DataManagementActionTypes.joinYourDataTabularStart;
  constructor(public payload) { }
}

export class JoinYourDataTabularSuccessAction implements Action {
  readonly type = DataManagementActionTypes.joinYourDataTabularSuccess;
  constructor(public response) { }
}
export class SaveJoinResultAsLayerStartAction implements Action {
  readonly type = DataManagementActionTypes.saveJoinResultAsLayerStart;
  constructor(public payload) { }
}

export class SaveJoinResultAsLayerSuccessAction implements Action {
  readonly type = DataManagementActionTypes.saveJoinResultAsLayerSuccess;
  constructor(public response) { }
}
export class SaveVTJoinResultAsLayerStartAction implements Action {
  readonly type = DataManagementActionTypes.saveVTJoinResultAsLayerStart;
  constructor(public payload) { }
}
export class SaveVTJoinResultAsLayerSuccessAction implements Action {
  readonly type = DataManagementActionTypes.saveVTJoinResultAsLayerSuccess;
  constructor(public response) { }
}

export class AddJoinedLayerToNewSessionStartAction implements Action {
  readonly type = DataManagementActionTypes.addJoinedLayerToNewSessionStart;
  constructor(public payload) { }
}

export class AddJoinedLayerToNewSessionSuccessAction implements Action {
  readonly type = DataManagementActionTypes.addJoinedLayerToNewSessionSuccess;
  constructor(public response) { }
}

export class GetTabularDataStartAction implements Action {
  readonly type = DataManagementActionTypes.getTabularDataStart;
  constructor(public payload) { }
}

export class GetTabularDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getTabularDataSuccess;
  constructor(public response) { }
}

export class GetLibraryAndYourDataLayersListStartAction implements Action {
  readonly type = DataManagementActionTypes.getLibraryAndYourDataLayersListStart;
  constructor(public payload) { }
}

export class GetLibraryAndYourDataLayersListSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getLibraryAndYourDataLayersListSuccess;
  constructor(public response) { }
}

export class DeleteTabularDataStartAction implements Action {
  readonly type = DataManagementActionTypes.deleteTabularDataStart;
  constructor(public id) { }
}

export class DeleteTabularDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.deleteTabularDataSuccess;
  constructor(public response) { }
}

export class GetDataLibraryLayerDetailsStartAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibraryLayerDetailsStart;
  constructor(public payload) { }
}

export class GetDataLibraryLayerDetailsSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibraryLayerDetailsSuccess;
  constructor(public response) { }
}

export class GetGroupNamesStartAction implements Action {
  readonly type = DataManagementActionTypes.getGroupNamesStart;
  constructor(public payload) { }
}

export class GetGroupNamesSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getGroupNamesSuccess;
  constructor(public response) { }
}

export class GetGroupLayersStartAction implements Action {
  readonly type = DataManagementActionTypes.getGroupLayersStart;
  constructor(public payload) { }
}

export class GetGroupLayersSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getGroupLayersSuccess;
  constructor(public response) { }
}

// export class GetYourDataGroupsStartAction implements Action {
//   readonly type = DataManagementActionTypes.getYourDataGroupsStart;
//   constructor(public payload) { }
// }

// export class GetYourDataGroupsSuccessAction implements Action {
//   readonly type = DataManagementActionTypes.getYourDataGroupsSuccess;
//   constructor(public response) { }
// }

export class GetYourDataLayersListStartAction implements Action {
  readonly type = DataManagementActionTypes.getYourDataLayersListStart;
  constructor(public payload) { }
}

export class GetYourDataLayersListSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getYourDataLayersListSuccess;
  constructor(public response) { }
}
export class ExportTabularDataStartAction implements Action {
  readonly type = DataManagementActionTypes.exportTabularDataStart;
  constructor(public payload) { }
}

export class ExportTabularDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.exportTabularDataSuccess;
  constructor(public response) { }
}

export class UploadLibraryDataStartAction implements Action {
  readonly type = DataManagementActionTypes.uploadLibraryDataStart;
  constructor(public payload) { }
}

export class UploadLibraryDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.uploadLibraryDataSuccess;
  constructor(public response) { }
}
export class GetLayersListStartAction implements Action {
  readonly type = DataManagementActionTypes.layersListStart;
  constructor(public payload) {}
}
export class GetLayersListSuccessAction implements Action {
  readonly type = DataManagementActionTypes.layersListSuccess;
  constructor(public resp: any) {}
}
export class GetLayerPreviewDataStartAction implements Action {
  readonly type = DataManagementActionTypes.getLayerPreviewDataStart;
  constructor(public payload) {}
}
export class GetLayerPreviewDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getLayerPreviewDataSuccess;
  constructor(public response: any) {}
}

export class GetDataLibraryDataStarAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibraryDataStart;
  constructor(public payload: any) {}
}

export class GetDataLibraryDataSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getDataLibraryDataSuccess;
  constructor(public resp: any) {}
}

export class GetSelectedGroupLayerIdsStartAction implements Action {
  readonly type = DataManagementActionTypes.getSelectedGroupLayerIdsStart;
  constructor(public payload: any) {}
}

export class GetSelectedGroupLayerIdsSuccessAction implements Action {
  readonly type = DataManagementActionTypes.getSelectedGroupLayerIdsSuccess;
  constructor(public response: any) {}
}

export class CreateUpdateGroupStartAction implements Action {
  readonly type = DataManagementActionTypes.createUpdateGroupStart;
  constructor(public payload: any) {}
}

export class CreateUpdateGroupSuccessAction implements Action {
  readonly type = DataManagementActionTypes.createUpdateGroupSuccess;
  constructor(public response: any) {}
}

export class UploadRasterDataStartAction implements Action {
  readonly type = DataManagementActionTypes.uploadRasterDataStart;
  constructor(public payload: any) { }
}

export class UploadRasterDataSUccessAction implements Action {
  readonly type = DataManagementActionTypes.uploadRasterDataSuccess;
  constructor(public response: any) { }
}

export type DataManagementActions = GetAssignedUsersSurveyersDataSuccessAction |
  GetAvailableFormWidgetsSuccessAction | GetAllDataCollectedLayersSuccessAction |
  GetSelectedTemplateDetailsSuccessAction | AssignUsersSurveyersToFormSuccessAction |
  GetVectorLayersSuccessAction | ExportVectorLayerSuccessAction |
  CreateEmptyVectorLayerSuccessAction | ResetStateForFormsAction |
  GetProjectionSystemsSuccessAction | ChangeStatusSuccessAction | DeleteTemplateSuccessAction |
  GetLayerById | GetLayerByIdSuccess | EditVectorlayerAttributes | GetAllDataLibraryCategoriesSuccessAction |
  GetDataLibrarySearchSuccessAction | GetDataLibraryCategoryDetailsSuccessAction |
  GetVectorListInfoSuccessAction | GetDLAttributesSuccessAction | GetTabularDLAttributesSuccessAction | JoinYourDataTabularSuccessAction | JoinYourDataSuccessAction |
  GetTabularDataSuccessAction | GetDataLibraryLayerDetailsSuccessAction |
  GetVectorListInfoSuccessAction | GetDLAttributesSuccessAction | JoinYourDataSuccessAction |
  GetLibraryAndYourDataLayersListSuccessAction | GetDataLibraryLayerDetailsSuccessAction | GetGroupNamesSuccessAction |
  GetGroupLayersSuccessAction | GetYourDataLayersListSuccessAction | GetLayersListSuccessAction |
  GetLayerPreviewDataSuccessAction | GetDataLibraryDataSuccessAction | GetSelectedGroupLayerIdsSuccessAction;
