import { Action } from "@ngrx/store";

export enum LoaderActionTypes {
  ShowLoader = "[Loader] Show Loader",
  HideLoader = "[Loader] Hide Loader",
}

export class ShowLoaderAction implements Action {
  readonly type = LoaderActionTypes.ShowLoader;
}

export class HideLoaderAction implements Action {
  readonly type = LoaderActionTypes.HideLoader;
}

export type LoaderActions = ShowLoaderAction | HideLoaderAction;
