import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { NavigationCtrl } from 'src/app/_services/navigationCtrl.service';
import { SessionService } from 'src/app/_services/session.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'gradual-path-dialog',
  styleUrls: ['./gradual-path-dialog.component.scss'],
  templateUrl: './gradual-path-dialog.component.html'
})

export class GradualPathDialogComponent implements OnInit {
  rasterFile = new FormControl();
  constructor(
    public dialogRef: MatDialogRef<any>,
    private navigationService: NavigationCtrl,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toasterService: ToastrService
  ) { 
    dialogRef.disableClose = true;

  }

  ngOnInit() { }

  cancelClicked() {
    this.sessionService.selectedRasterToolIndex = null;
    this.dialogRef.close();
    this.sessionService.setDefaultRasterToolIndex.next(null);
  }

  selectPointsButtonClicked() {
    if(!(!!this.rasterFile.value)) return;
    if(this.rasterFile.value && this.rasterFile.value.parent_layer_id) {
      this.sessionService.setAdvancedToolsGraphicsSelectionActive(true);
      this.navigationService.showGeometry();
      const rData = {
        layerId: this.rasterFile.value.parent_layer_id,
        sessionId: this.data.sessionId
      }
      this.sessionService.setSelectedRasterToolData(rData);
      this.dialogRef.close();
    } else {
      this.toasterService.info('please select raster layer to  select points')
    }
  }

}