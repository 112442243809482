import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogActions, MatDialogContent } from '@angular/material';
import { Store } from '@ngrx/store';
import { GetInvoicesStartAction } from 'src/app/store/actions/subscription.action';
import { Subject } from 'rxjs';
import { subscriptionInvoiceSelector } from 'src/app/store/selectors/subscription.selector';
import { takeUntil, reduce } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { uniq } from 'lodash'
import { API } from 'src/app/_services';
import { trigger, transition, style, animate } from '@angular/animations';
import {Utilities} from '../../_helpers/util'

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss'],
  // animations: [
  //   trigger('fade', [
  //     // state(),
  //     transition('void => *', [
  //       style({
  //         backgroundColor:'red',
  //         opacity:0
  //       }),
  //       animate(1000, style({backgroundColor:"yellow"}))
        
  //     ])
  //   ])
  // ]
})
export class InvoiceDialogComponent implements OnDestroy {
  years: Array<string> = [];
  stopper$: Subject<any> = new Subject();
  purchaseList: Array<any> = [];
  yearControl: FormControl = new FormControl();
  filteredPurchaseList: any[] = [];
  constructor(public dialogRef: MatDialogRef<InvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _store: Store<any>, public apiService: API, private _utils: Utilities) { }
  ngOnDestroy() {
    this.stopper$.next();
  }

  ngOnInit() {
    this._store.dispatch(new GetInvoicesStartAction());
    this._store.select(subscriptionInvoiceSelector).pipe(takeUntil(this.stopper$)).subscribe((response: any) => {
      if (response && response.success) {
        this.purchaseList = response.data.invoice_list;
        this.filteredPurchaseList = [...this.purchaseList]
        this.years = uniq(response.data.invoice_list.map(({ paid_at }) =>  new Date(this._utils.getYearFromApoch(paid_at)).getFullYear()));
        this.yearControl.setValue('all')
      }
    })
  }

  purchaseYearSelected(event) {
    switch (event.value) {
      case 'all':
        this.filteredPurchaseList = [...this.purchaseList]
        break;
      default:
        this.filteredPurchaseList = [...this.purchaseList].filter(({ paid_at  }) => [parseInt(event.value)].includes(new Date(this._utils.getYearFromApoch(paid_at)).getFullYear()))
        break;

    }
  }
  viewClicked(url) {
    if (url.length) {
      window.open(url, "_blank")
    }

  }
}
