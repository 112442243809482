import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'settings-dialog',
    styleUrls: ['./settings-dialog.component.scss'],
    templateUrl: './settings-dialog.component.html'
})

export class SettingsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
    ngOnInit() {
        const rightMostPos = window.innerWidth - Number(this.data.settingsIconPosition.left -186.5);
        this.dialogRef.updatePosition({ top:`${this.data.settingsIconPosition.top + 30 }px`, right: `${rightMostPos -250}px`});
    }
    toggleClicked(event) {
        // this.activatedStatus =event.checked;
        this.dialogRef.close(event.checked)
    }

}