import { NgModule } from "@angular/core";
import { DateCustomPipe } from './date.pipe';
import { CommonModule } from '@angular/common';


@NgModule({
declarations:[
    DateCustomPipe
],
imports: [CommonModule],
exports:[
    DateCustomPipe
]

})

export class CustomPipeModule {

}