import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetDataLibraryDataStarAction, GetGroupLayersStartAction, GetGroupNamesStartAction, GetLibraryAndYourDataLayersListStartAction, GetTabularDataStartAction } from 'src/app/store/actions/data-management.actions';
import { groupLayersSelector, groupNamesSelector, joinLayersListSelector, tabularDataSelector } from 'src/app/store/selectors/data-management.selector';
import { LayerService } from 'src/app/_services';
import { FieldSelectionDialog } from '../field-selection-dialog/field-selection-dialog.component';
import _ from 'lodash';
import { LAYERS_LIST_PARAMS } from 'src/app/app-management/data-management/data-management-home/data-management-home.component';
import { GROUP_LAYERS_LIST_PARAMS, GROUP_LIST_PARAMS, LAYER_ACTIVE_ICONS, LIBRARY_SEARCH_PARAMS, SELECTED_LAYER_TYPE_FOR_LIBRARY } from 'src/app/app.constants';

@Component({
  selector: 'app-your-data-layer-selection-dialog',
  templateUrl: './your-data-layer-selection-dialog.component.html',
  styleUrls: ['./your-data-layer-selection-dialog.component.scss']
})
export class YourDataLayerSelectionDialogComponent implements OnInit {

  subscriptionArray: Array<Subscription> = [];
  page: number = 0;
  totalPages: number = 0;
  dataList: Array<any> = [];
  groupList: Array<any> = [];
  groupLayersList: Array<any> = [];
  searchField: FormControl = new FormControl("");
  searchValue: string = "";
  selectedLayer: any = null;
  hasError: boolean = false;
  serchSelected : boolean = false;
  showDataFrom: string = 'your-data'; // your-data / data-library
  internalPage: number = 0;
  totalInternalPages: number = 0;
  layerIcons = LAYER_ACTIVE_ICONS;
  @ViewChild('lList') lListRef: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<YourDataLayerSelectionDialogComponent>,
    private dialog: MatDialog,
    private _store: Store<any>,
    private layerservice: LayerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.getGroupAndLayers();
    this.subscriptionArray.push(
      this._store.select(joinLayersListSelector).subscribe((vList: any) => {
        if(vList.data.length) {
          this.page = vList.page;
          this.totalPages = vList.totalPages;
          this.dataList = vList.data;
        } else {
          this.page = 0;
          this.totalPages = 0;
          this.dataList = [];
        }
      })
    );
    this.subscriptionArray.push(
      this.searchField.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(val => {
        if(val){
          this.serchSelected = true;
        }
        else{
          this.serchSelected = false;
        }
        this.searchValue = val.trim();
        this.getGroupAndLayers();
      })
    )
    this.subscriptionArray.push(
      this._store.select(groupNamesSelector).subscribe((data: Array<any>) => {
        this.groupList = data;
      })
    );
    this.subscriptionArray.push(
      this._store.select(groupLayersSelector).subscribe((gList: any) => {
        if(gList.data.length) {
          this.internalPage = gList.page;
          this.totalInternalPages = gList.totalPages;
          this.groupLayersList = gList.data;
        } else {
          this.internalPage = 0;
          this.totalInternalPages = 0;
          this.groupLayersList = [];
        }
      })
    );
  }
  crossClicked(){
    this.serchSelected = !this.serchSelected; 
    this.searchField.setValue('');
  }
  getGroupAndLayers() {
    if(this.showDataFrom === 'your-data') {
      this.getGroups();
    }
    this.getLayers();
  }

  getGroups() {
    let params: any = _.cloneDeep(GROUP_LIST_PARAMS);
    params.search = this.searchValue;
    params.layerType = SELECTED_LAYER_TYPE_OBJ[this.data.selectedType];
    this._store.dispatch(new GetGroupNamesStartAction(params));
  }

  getLayers(loadMore = false) {
    if(this.showDataFrom === 'your-data') {
      let params: any = _.cloneDeep(LAYERS_LIST_SEARCH_PARAMS);
      params.layerType = SELECTED_LAYER_TYPE_OBJ[this.data.selectedType];
      params.type = SELECTED_TYPE;
      params.search = this.searchValue;
      if(loadMore) params.page = this.page + 1;
      this._store.dispatch(new GetLibraryAndYourDataLayersListStartAction(params));
    } else {
      let params: any = _.cloneDeep(LIBRARY_SEARCH_PARAMS);
      params.layerType = SELECTED_LAYER_TYPE_FOR_LIBRARY[this.data.selectedType];
      params.search = this.searchValue;
      params.remove_id = this.data.layer_id;
      if(loadMore) params.page = this.page + 1;
      this._store.dispatch(new GetDataLibraryDataStarAction(params));
    }
  }

  radioSelectionChanged(event) {
    this.showDataFrom = event.value;
    if(this.showDataFrom === 'data-library') {
      this.groupList = [];
      this.groupLayersList = [];
      this.internalPage = 0;
      this.totalInternalPages = 0;
    }
    this.lListRef.nativeElement.scrollTop = 0;
    if(this.searchValue) {
      this.searchField.setValue("");
    } else {
      this.getGroupAndLayers();
    }
  }

  openAccordion(groupName){
    let params = _.cloneDeep(GROUP_LAYERS_LIST_PARAMS);
    params.search = this.searchValue;
    params.groupName = groupName;
    params.layerType = SELECTED_LAYER_TYPE_OBJ[this.data.selectedType];
    this._store.dispatch(new GetGroupLayersStartAction(params))
  }

  onInternalScroll(event, groupName) {
    if (this.shouldLoadMore(event)) {
      setTimeout(() => {
        this.loadMoreGroupLayers(groupName);
      }, 200);
    }
  }

  loadMoreGroupLayers(groupName) {
    if (this.internalPage >= this.totalInternalPages) return;
    let payload = _.cloneDeep(GROUP_LAYERS_LIST_PARAMS);
    payload.search = this.searchValue;
    payload.page = this.internalPage + 1;
    payload.groupName = groupName;
    payload.layerType = SELECTED_LAYER_TYPE_OBJ[this.data.selectedType];
    this._store.dispatch(new GetGroupLayersStartAction(payload));
  }

  shouldLoadMore(event) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight;
    const scrollLocation = event.target.scrollTop;
    const buffer = 0;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    return scrollLocation >= limit;
  }

  setSelectedLayer(data) {
    this.selectedLayer = data;
    this.hasError = false;
  }

  openFieldSelectionDialog() {
    if(!this.selectedLayer) {
      this.hasError = true;
      return;
    }
    const data = {
      selectedLayer: this.selectedLayer,
      selectedLibrary: this.data,
      dataType: this.showDataFrom === 'data-library' ? 'library': 'your-data',
      type: this.data.selectedType === 'tabular' ? 'vector-tabular' : 'vector-vector'
    }
    const filedSelectionDialog = this.dialog.open(FieldSelectionDialog, {
      hasBackdrop: true,
      width: '800px',
      disableClose: true,
      data
    })
    filedSelectionDialog.afterClosed().subscribe(_ => {});
    this.close(false);
  }

  onScroll(event) {
    if (this.shouldLoadMore(event)) {
      setTimeout(() => {
        this.loadMore();
      }, 200);
    }
  }

  loadMore() {
    if (this.page >= this.totalPages) return;
    this.getLayers(true);
  }

  close(flag) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if(this.subscriptionArray.length) {
      this.subscriptionArray.map(sub => sub.unsubscribe());
      this.subscriptionArray = [];
    }
  }

}

export const LAYERS_LIST_SEARCH_PARAMS = {
  search: "",
  page: 1,
  limit: 10,
  layerType: null, // 1 or 8
  type: '' // library (or) your-data
}

// export const LIBRARY_SEARCH_PARAMS = {
//   search: "",
//   page: 1,
//   limit: 10,
//   layerType: null
// }

export const SELECTED_LAYER_TYPE_OBJ = {
  tabular: 8,
  vector: 1
}

export const SELECTED_TYPE = 'your-data'

// export const SELECTED_LAYER_TYPE_FOR_LIBRARY = {
//   vector: 1,
//   tabular: 2
// }