import { createAction, props } from '@ngrx/store';
import {Action} from '@ngrx/store'

export enum propertyTaxTypes {
getPropertyTaxDataStart = "[PROPERTY_TAX_DATA] Get Property Tax Data Start",
getPropertyTaxDataSuccess = "[PROPERTY_TAX_DATA] Get Property Tax Data Success",
getWaterSupplyDataStart = "[WATER_SUPPLY_DATA] Get Water Supply Data Start",
getWaterSupplyDataSuccess = "[WATER_SUPPLY_DATA] Get Water Supply Data Success",
getSlumDataStart = "[SLUM_DATA] Get Slum Data Start",
getSlumDataSuccess = "[SLUM_DATA} Get Slum Data Success",
getSlumDemographicsDataStart = "[SLUM_DEMOGRAPHICS_DATA] Get Slum Demographics Data Start",
getSlumDemographicsDataSuccess = "[SLUM_DEMOGRAPHICS_DATA] Get Slum Demographics Data Success",
getRatioChartDataStart = "[RATIO_CHART_DATA] Get Ration Chart Data Start",
getRatioChartDataSuccess = "[RATIO_CHART_DATA] Get Ration Chart Data Success",
getSlumSanitationDataStart = "[SLUM_SANITATION_DATA] Get slum sanitation data start",
getSlumSanitationDataSuccess = "[SLUM_SANITATION_DATA] Get slum sanitation data success"
} 

export class getPropertyTaxDataStartAction implements Action {
  
  readonly type = propertyTaxTypes.getPropertyTaxDataStart;
  constructor() { }
}

export class getPropertyTaxDataSuccessAction implements Action {
  readonly type = propertyTaxTypes.getPropertyTaxDataSuccess;
  data;
  constructor (response: any) {
  this.data = response;  
  }
}
export class getWaterSupplyDataStartAction implements Action {
  
  readonly type = propertyTaxTypes.getWaterSupplyDataStart;
  constructor() { }
}

export class getWaterSupplyDataSuccessAction implements Action {
  readonly type = propertyTaxTypes.getWaterSupplyDataSuccess;
  data;
  constructor (response: any) {
  this.data = response;  
  }
}

export class getSlumDataStartAction implements Action{
  readonly type = propertyTaxTypes.getSlumDataStart;
  constructor(){}
}

export class getSlumDataSuccessAction implements Action{
  readonly type = propertyTaxTypes.getSlumDataSuccess;
  data;
  constructor(response:any){
    this.data = response;
  }
}

export class getSlumDemographicsDataStartAction implements Action{
  readonly type = propertyTaxTypes.getSlumDemographicsDataStart;
  constructor (){}
}

export class getSlumDemographicsDataSuccessAction implements Action{
  readonly type = propertyTaxTypes.getSlumDemographicsDataSuccess;
  data;
  constructor(response:any){
    this.data = response;
  }
}

export class getRatioChartDataStartAction implements Action{
  readonly type = propertyTaxTypes.getRatioChartDataStart;
  constructor (){}
}

export class getRatioChartDataSuccessAction implements Action{
  readonly type = propertyTaxTypes.getRatioChartDataSuccess;
  data;
  constructor(response:any){
    this.data = response;
  }

}

export class getSlumSanitationDataStartAction implements Action{
  readonly type = propertyTaxTypes.getSlumSanitationDataStart;
  constructor (){}
}

export class getSlumSanitationDataSuccessAction implements Action{
  readonly type = propertyTaxTypes.getSlumSanitationDataSuccess;
  data;
  constructor(response:any){
    this.data = response;
  }
  
}
export type PropertyTaxDataActions = getPropertyTaxDataSuccessAction | getWaterSupplyDataSuccessAction |
 getSlumDataSuccessAction | getSlumDemographicsDataSuccessAction|getRatioChartDataSuccessAction |getSlumSanitationDataSuccessAction
