import { Injectable } from '@angular/core';
import { PropertyTax } from '../models/city-os-main-class';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API } from "./API.service";


@Injectable({
  providedIn: 'root'
})
export class CityOsPropertyTaxService {
  data: any;

  _propertyTaxUrl = '/assets/propertytax.json';
  _waterSupplyUrl = '/assets/watersupply.json';
  _slum = '/assets/slum.json';
  _slumDemographics = '/assets/slumdemographics.json';
  _ratioChart = '/assets/ratiochart.json';
  _slumSanitation = '/assets/slumsanitation.json';

  constructor(
    private apiService: API, private http: HttpClient
  ) { }

  getPropertyTaxData() {
    return this.http.get(this._propertyTaxUrl);
  }

  getWaterSupplyData() {
    return this.http.get(this._waterSupplyUrl);
  }

  getSlumData() {
    return this.http.get(this._slum);
  }

  getSlumDemographicsData() {
    return this.http.get(this._slumDemographics);
  }

  getRatioChartData() {
    return this.http.get(this._ratioChart);
  }
  getSlumSanitationData() {
    return this.http.get(this._slumSanitation);
  }

}
