import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-subscription-error-dialog',
  templateUrl: './subscription-error-dialog.component.html',
  styleUrls: ['./subscription-error-dialog.component.scss']
})
export class SubscriptionErrorDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SubscriptionErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }
}