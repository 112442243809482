import { AuthGuard } from "./_guards/auth.guard";
import {
  API,
  ToastsManager,
  AutoUnsubscribeService,
  LayerService,

} from "./_services/index";

import { SharedService } from "./_helpers/shared";
import { Utilities } from "./_helpers/util";
import { SessionService } from './_services/session.service';
import { Storeservice } from './_services/store.services';
import { ServerURLInterceptor } from './_services/interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SignInAuthGuard } from './_guards/sign_in.guard';
import { SignUpAuthGuard } from './_guards/sign_up.guard';
import { LegendCtrl } from './_services/mapLegendsCtrl.service';
import { NavigationCtrl } from './_services/navigationCtrl.service';
import { SessionPublishService } from './_services/sessionPublish.service';
import { DynamicComponentService } from './_services/dynamic-component.service';
import { MatDialogRef, MAT_CHIPS_DEFAULT_OPTIONS, MAT_DIALOG_DATA } from '@angular/material';
import { DataApprovalManagementService } from './_services/data-approval-management.service';
import { from } from 'rxjs';
import { ProjectManagementService } from './_services/projectManagement.service';
import { SurveyAnalyticsService } from './_services/survey-analytics.service'
import { SocketService } from './_services/socket.service';
import { SubscriptionService } from './_services/subscription.service';
import { SubscriptionHelper } from './_helpers/subscription.helper';
import { GoogleAnalyticsService } from './_services/google-analytics.service';

export const APP_PROVIDERS = [
  DataApprovalManagementService,
  SurveyAnalyticsService,
  API,
  AuthGuard,
  SignInAuthGuard,
  SignUpAuthGuard,
  ToastsManager,
  AutoUnsubscribeService,
  LayerService,
  SharedService,
  Utilities,
  SessionService,
  Storeservice,
  LegendCtrl,
  NavigationCtrl,
  SessionPublishService,
  DynamicComponentService,
  GoogleAnalyticsService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ServerURLInterceptor,
    multi: true
  },
  {
    provide: MAT_CHIPS_DEFAULT_OPTIONS,
    useValue: {},
  },
  { provide: MAT_DIALOG_DATA, useValue: {} },
  { provide: MatDialogRef, useValue: {} },
  ProjectManagementService,
  SocketService,
  SubscriptionService,
  SubscriptionHelper
];
