import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { LegendCtrl } from '../_services/mapLegendsCtrl.service';
import * as mapboxgl from 'mapbox-gl';
import { ActivatedRoute } from '@angular/router';
import { Storeservice } from '../_services/store.services';
import { LayerService } from '../_services';
import { Store } from '@ngrx/store';
import { activeSessionSelector, SeachedMapValues, PlaceDetails, getIconsSelector } from '../store/selectors/session-management.selector';
import { NavigationCtrl } from '../_services/navigationCtrl.service';
import { DynamicComponentService } from '../_services/dynamic-component.service';
import { MapIdentifyComponent } from '../app-home/map-identify/map-identify.component';
import { GetSessionDetailsStartAction, GetSearchedMapResults, GetPlaceDetails, GetIconsListStartAction } from '../store/actions/session-management.actions';
import { SessionService } from '../_services/session.service';
import { fromEvent, combineLatest, Subject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap, throttleTime, map } from 'rxjs/operators';
import { uniqBy } from 'lodash';
import { uniq, cloneDeep } from 'lodash';
import { layerTypes, GEOJSONLEVEL, BASE_MAP_LAYERS } from '../app.constants';

// declare let L;


@Component({
  selector: 'app-share-map',
  templateUrl: './share-map.component.html',
  styleUrls: ['./share-map.component.scss']
})
export class ShareMapComponent implements OnInit {
  map: any;
  zoom = 3;
  genericStyle = '/assets/style1.json';
  centerValue: any = [78.8723, 20.8167]
  sessionHitId: any;
  mapData: any;
  userToken: any;
  updatedIconObj = {};
  @ViewChild('activeIconDiv') activeIconDiv: ElementRef;
  genericErrMsg = 'Unable to load the map at this moment please check Transerve Session Share status';
  jsonData: mapboxgl.Style = {
    "version": 8,
    "name": "Transerve",
    "sources": {
      "5d46bb75acb1f825df6e7162_no_prop": {
        "type": "vector",
        "tiles": [
          "https://sgp1.digitaloceanspaces.com/tos-fileserver/protofiles/5d46bb75acb1f825df6e7162/0/{z}/{x}/{y}.pbf"
        ]
      },
      "5d458b8c51b773735967f440_no_prop": {
        "type": "vector",
        "tiles": [
          "https://sgp1.digitaloceanspaces.com/tos-fileserver/protofiles/5d46bb75acb1f825df6e7162/0/{z}/{x}/{y}.pbf"

        ]
      },
      "5d462e53e2790811368b5f58_no_prop": {
        "type": "vector",
        "tiles": [
          "https://sgp1.digitaloceanspaces.com/tos-fileserver/protofiles/5d46bb75acb1f825df6e7162/0/{z}/{x}/{y}.pbf"

        ]
      }
    },
    "metadata": {
      "mapbox:autocomposite": true,
      "mapbox:type": "template",
      "mapbox:sdk-support": {
        "js": "0.54.0",
        "android": "6.7.0",
        "ios": "4.7.0"
      }
    },
    "center": [77.4853726043873, 19.59197266470518],
    "zoom": 8.05536269977162,
    "bearing": 0,
    "pitch": 0,
    "glyphs": "https://sgp1.digitaloceanspaces.com/tos-fileserver/fonts/{fontstack}/{range}.pbf",
     "sprite": "https://dev.transerve.com/tos-fileserver/img/sprite",
    "light": {
      "anchor": "viewport",
      "color": "hsl(0, 0%, 0%)",
      "intensity": 0.96
    },
    "layers": [
      {
        "id": "background",
        "type": "background",
        "layout": {},
        "paint": {
          "background-color": "#FFFFFF",
          "background-opacity": 1
        }
      },
      {
        "id": "5d4794b939733f55070578ba",
        "type": "fill",
        "source": "5d462e53e2790811368b5f58_no_prop",
        "paint": {
          "fill-color": ["interpolate", ["linear"], ["zoom"], 0, "#00FFB3"],
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1],
          "fill-outline-color": ["interpolate", ["linear"], ["zoom"], 0, "#003D04"]
        },
        "layout": { "visibility": "visible" },
        "maxzoom": 21,
        "minzoom": 0,
        "source-layer": "5d462e53e2790811368b5f58_no_prop"
      },
      {
        "id": "5d4794b939733f55070578b9",
        "type": "fill",
        "source": "5d458b8c51b773735967f440_no_prop",
        "paint": {
          "fill-color": ["interpolate", ["linear"], ["zoom"], 0, "#FA856E"],
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1],
          "fill-outline-color": ["interpolate", ["linear"], ["zoom"], 0, "#003D04"]
        },
        "layout": { "visibility": "visible" },
        "maxzoom": 21,
        "minzoom": 0,
        "source-layer": "5d458b8c51b773735967f440_no_prop"
      },
      {
        "id": "5d4794b939733f55070578bb",
        "type": "fill", "source": "5d46bb75acb1f825df6e7162_no_prop",
        "paint": {
          "fill-color": ["interpolate", ["linear"], ["zoom"], 0, "#E9E9FF"],
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1],
          "fill-outline-color": ["interpolate", ["linear"], ["zoom"], 0, "#4D0048"]
        },
        "layout": { "visibility": "visible" },
        "maxzoom": 21,
        "minzoom": 0,
        "source-layer": "5d46bb75acb1f825df6e7162_no_prop"
      }
    ]
  }
  attribControl = new mapboxgl.AttributionControl({ compact: false, customAttribution: ' © <a href="https://transerve.com" target="_blank">Transerve Online Stack</a>' });
  public errorMsg = '';
  sessionLayersList: Array<any> = [];
  tooltipData: Array<any> = [];
  mouseClickCoordinates: any = { lat: 20.8167, lng: 78.8723 };
  sessionId: string = null;
  osmLayersInfo: Array<any> = [];
  showLegends: boolean = false;
  mapSearch: FormControl = new FormControl('')
  searchedPlaces: any[] = [];
  optionSelected: boolean = false;
  searchedParam: string = '';
  marker: any;
  showSearchBar: boolean = false;
  attributeDisplayOnClick: boolean = true;
  timeout: any;
  showLegendsDiv: boolean = true;
  legendLayers: any[] = [];
  openedInIframe: boolean = false;
  identifyPopup: mapboxgl.Popup;
  pointerTimeout;
  hiddenLayers: any[] = [];
  pointTypeArray = ['point'];
  polygonTypeArray = ['polygon', 'multipolygon']
  polylineArray = ['polyline', 'multilinestring', 'multipolyline', 'linestring']

  mapMouseEventSubject: Observable<any> = new Observable();
  layerDetailsInMap: any[] = [];
  geoJsonData: any = {};
  shareJson: any = {};
  makingApiCallStart: boolean = false;
  styleObj: any;
  layersThatNeedGraphicCall: any[] = [];
  indexes = [];
  legendsToDisplay: any = []
  geoJsonObjSource: any = {};
  sessionIdData: any = null;
  geoJsonSourceIds: any[] = [];
  baseLayer: string = null;
  count: number = 0;
  lat;
  lng;
  constructor(private legendService: LegendCtrl,
    private _store: Store<any>,
    public activeroute: ActivatedRoute,
    public storeService: Storeservice,
    private dynamicCompService: DynamicComponentService,
    private layerService: LayerService,
    private renderer: Renderer2,
    private sessionservice:SessionService,

  ) { }

  ngOnInit() {
    this.lat = this.activeroute.snapshot.queryParamMap.get('lat');
    this.lng = this.activeroute.snapshot.queryParamMap.get('lng');
 

    
// this._store.dispatch(new GetIconsListStartAction());
    this.openedInIframe = (window.location !== window.parent.location) ? true : false;
    let snapshot = this.activeroute.snapshot;
    this.sessionHitId = snapshot.params.id;
    this.userToken = snapshot.queryParams.access_token;
    // this._store.dispatch(new GetIconsListStartAction());
    // let getIconsSelector$ = this._store.select(getIconsSelector);


    if(this.lat!=undefined && this.lng!=undefined){
      this.map = new mapboxgl.Map({
        container: 'map',
        minZoom: 1,
        maxZoom: 21,
        zoom: this.zoom,
        // interactive:true,
        // failIfMajorPerformanceCaveat: true,
        // pitchWithRotate: false,
        style: `${this.genericStyle}`,
        localIdeographFontFamily: 'OpenSans-Regular',
        center: [this.lat,this.lng],
        attributionControl: false
      });

    }


    else{
      this.map = new mapboxgl.Map({
        container: 'map',
        minZoom: 1,
        maxZoom: 21,
        zoom: this.zoom,
        // interactive:true,
        // failIfMajorPerformanceCaveat: true,
        // pitchWithRotate: false,
        style: `${this.genericStyle}`,
        localIdeographFontFamily: 'OpenSans-Regular',
        center: this.centerValue,
        attributionControl: false
      });

    }

    




    

    // this.map.on('idle', function () {
    //   console.log("Fly To")

    //   if(this.lat!=undefined && this.lng!=undefined){
      
    //     this.map.flyTo({
    //       center: [this.lng,this.lat],
    //       animate: false
    //     });
  
      
        
    //   }
     

    // })

    let point = this.map.getCenter();
    point.lng = point.lng+20;

    console.log("Testing 123")

    
  
    

    this.layerService.getOSMLayersInfo(this.sessionHitId).subscribe((info: any) => {
      this.osmLayersInfo = info.data;
      this.sessionId = info.sessionId;
      this.sessionLayersList = info.vectorData;
    })
    if (this.sessionHitId && this.userToken) {
      const mapLoad = fromEvent(this.map, 'load');
      const getShareJson: any = this.layerService.getShareStyleJson(this.sessionHitId, this.userToken);
      const getWfsRequirements = this.layerService.getPublishInfo(this.sessionHitId, this.userToken);
      // const iconSelector = this._store.select(getIconsSelector);
      const iconSelector: any = this.layerService.getPointLayerIcons(this.userToken);

      combineLatest(mapLoad, getShareJson, getWfsRequirements, iconSelector).subscribe(([_, sharejson, wfsRequirements, iconsRes]) => {
        this.shareJson = sharejson
        let data: any = sharejson;
        let wfsDetails: any = wfsRequirements;
        if (!data) return;
        if (wfsDetails && Object.keys(wfsDetails).length && wfsDetails.success) {
          if (wfsDetails.data.wfs_publish_details) {
            this.showLegends = wfsDetails.data.wfs_publish_details.showMapLegend;
            this.showSearchBar = wfsDetails.data.wfs_publish_details.searchBar;
            this.attributeDisplayOnClick = wfsDetails.data.wfs_publish_details.attributeDisplayOnHover;
          }
          this.baseLayer = wfsDetails.data.session_layers[0].base_layer;
          this.layerDetailsInMap = wfsDetails.data.session_layers[0].orders.map(({ layer_id }) => layer_id);
          if (iconsRes.data && iconsRes.data.icons) {
            const iconsArr = iconsRes.data.icons;
            this.createLegendList(wfsDetails.data.session_layers[0].orders.filter(({ layer_id }) => layer_id.layer_type !== 4) || [], iconsArr)
          }
          this.plotMapWithJsonData(data, wfsDetails.data.session_layers[0].orders.filter(({ layer_id }) => layer_id.layer_type !== 4));
          if (!this.attributeDisplayOnClick) {
            // this.mapMouseEventSubject = fromEvent(this.map,'mousemove').pipe(throttleTime(500));
            // this.mapMouseEventSubject.subscribe((resp) => {
            //   console.log(resp, "response")
            //   if(this.identifyPopup && this.identifyPopup.isOpen()) {
            //     this.identifyPopup.remove();
            //   }
            //   this.onMapClicked(resp)
            // })
            this.map.on('mousemove', (e) => {
              if (this.identifyPopup) {
                this.map.getCanvas().style.cursor = 'grab';

              }
              if (this.identifyPopup && this.identifyPopup.isOpen()) return

              clearTimeout(this.timeout);
              this.timeout = setTimeout(() => {
                if (this.identifyPopup && this.identifyPopup.isOpen()) {

                  this.identifyPopup.remove();
                }
                this.onMapClicked(e);
              }, 500)
            })
          } else {
            this.map.on('click', this.onMapClicked)
          }
          if (wfsDetails.data.wfs_publish_details && wfsDetails.data.wfs_publish_details.zoomRangesDefault) {

            this.map.setMinZoom(wfsDetails.data.wfs_publish_details.customMinZoomRange)
            this.map.setMaxZoom(wfsDetails.data.wfs_publish_details.customMaxZoomRange)
          }
        }
        this.map.dragRotate.disable();
        if (data.errors && data.errors.length > 0 && data.errors[0].message) {
          this.errorMsg = data.errors[0].message;
        } else {
          if (data && data.layers && data.layers.length > 0) {
            const bgLayer = data.layers.find(layer => layer.id === 'background');
            if (!bgLayer) {
              data.layers.unshift({
                "id": "background",
                "type": "background",
                "paint": {
                  "background-color": "#FFFFFF",
                  "background-opacity": 1
                }
              });
            }
            if (!this.showLegends) {
              this.plotMapWithJsonData(data);
            }
          } else {
            this.errorMsg = 'No Layers info found';
          }
          const nav = new mapboxgl.NavigationControl({ showCompass: false });
          this.map.addControl(nav, 'bottom-right');
        }
      }, err => {
        if (err) {
          if (err.error && err.error.error && err.error.error.length > 0) {
            this.errorMsg = err.error.error[0].message;
          } else {
            this.errorMsg = this.genericErrMsg;
          }
        }
      });
    }

    this.mapSearch.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((searchParam) => {
      if (this.optionSelected && (this.searchedParam !== searchParam)) {
        this.optionSelected = false
      }
      if (this.marker && !searchParam.length) {
        this.marker.remove();
      }
      if (searchParam.length > 1) {
        let payload = {
          "zoomLevel": this.map.getZoom(),
          "location": this.map.getCenter().lat + "," + this.map.getCenter().lng
        }
        this._store.dispatch(new GetSearchedMapResults(searchParam, payload, true))
      }
      else {
        this.searchedPlaces = []
      }
    })
    this._store.select(SeachedMapValues).subscribe(res => {
      this.searchedPlaces = this.layerService.validateAddress(res || [])
    })
    fromEvent(this.map, 'zoomend').pipe(map((_) => +this.map.getZoom().toFixed(1)), debounceTime(1000), distinctUntilChanged()).subscribe((event) => {
      if (this.makingApiCallStart) return
      if (this.map.getZoom() >= GEOJSONLEVEL) {
        this.layersThatNeedGraphicCall = this.layerService.segregateLayersForGraphicsCall(this.map, this.legendsToDisplay, this.layerDetailsInMap);
        this.geoJsonSourceIds = this.layersThatNeedGraphicCall.map(({ parent_layer_id }) => parent_layer_id)
        this.layersThatNeedGraphicCall.forEach((layer, i) => {
          setTimeout(() => {
            this.makeGraphicCallForrequiredLayer(layer, i)
          }, 100)
        })
        // check layer if its present in screen bounds
        // this.layerDetailsInMap.filter(({layer_type}) => layer_type).map(({parent_layer_id}) => parent_layer_id)
      } else {
        let plottedMapSourceObj = this.map.getStyle().sources;
        let styleTobeChanged = false;
        for (let sourceId in plottedMapSourceObj) {
          if (plottedMapSourceObj[sourceId].type === 'geojson') {
            styleTobeChanged = true;
            break;
          }
        }
        if (styleTobeChanged) {
          this.showLayersWithLegendCondition(this.shareJson, 'sharejson')
        }

      }
    })

  }

  onMapClicked = e => {

    console.log("MAP CLICKED")
  //  this.map.flyTo(15.2993,74.1240)

//    this.map.flyTo({
//     center: [this.lng,this.lat],
//     animate: false
//  });

  

    if (!(e && e.lngLat)) return;
    this.mouseClickCoordinates = e.lngLat;
    let osmFeatures = [];
    let features = this.map.queryRenderedFeatures(e.point);
    features = uniqBy(features, (feature: any) => feature.layer.id)
    if (features.length) {
      this.tooltipData = [];
      const osmSources = this.osmLayersInfo.length ? this.osmLayersInfo.map(source => source.source_layer) : [];
      features.forEach(element => {
        const source = element.sourceLayer
        const is_osm = osmSources.includes(source);
        const sourceLayer = is_osm ? this.osmLayersInfo.find(src => src.source_layer === source) : source;
        if (is_osm) {
          osmFeatures.push({
            _id: sourceLayer.id.replace("_no_prop", ""),
            properties: element.properties,
            property_keys: Object.keys(element.properties),
            name: sourceLayer.name,
            type: sourceLayer.type
          })
        } else if (Object.keys(element.properties).length && this.sessionLayersList.length) {
          const layer = this.sessionLayersList.find(layer => layer.id === element.layer.id);
          if (layer) {
            if (Object.keys(element.properties).includes('is_approved')) {
              const predicate = property => property !== 'is_approved';
              element.properties = Object.keys(element.properties)
                .filter(key => predicate(key))
                .reduce((res, key) => Object.assign(res, { [key]: element.properties[key] }), {});
            }
            osmFeatures.push({
              _id: layer.id.replace("_no_prop", ""),
              properties: element.properties,
              property_keys: Object.keys(element.properties),
              name: layer.name,
              type: layer.type
            })
          }
        } else {
          if (
            this.tooltipData.find(
              data => data === sourceLayer
            ) === undefined
          ) {
            this.tooltipData.push(sourceLayer.replace("_no_prop", ""));
          }
        }
      });
      let popupContent: any = null;
      if (this.tooltipData.length > 0) {
        if (!this.attributeDisplayOnClick) {
          this.map.getCanvas().style.cursor = 'wait';
        }
        const payload = {
          layers: this.tooltipData,
          coordinates: [e.lngLat.lng, e.lngLat.lat],
          sessionId: this.sessionId
        };
        this.layerService
          .getIdentifyResultsForWMS(payload)
          .subscribe((response: any) => {
            if (response && response.length > 0) {
              popupContent = this.dynamicCompService.injectComponent(
                MapIdentifyComponent,
                x => (x.identifyInput = [...response.map(obj => obj[0]), ...osmFeatures])
              );
              if (!this.attributeDisplayOnClick) {
                this.identifyPopup = new mapboxgl.Popup({ closeButton: true }).setLngLat(this.mouseClickCoordinates).setDOMContent(popupContent).addTo(this.map)
                clearTimeout(this.pointerTimeout)
                this.pointerTimeout = setTimeout(() => this.map.getCanvas().style.cursor = 'grab', 200)
                this.identifyPopup.on('close', (e) => {
                  this.identifyPopup = null;
                  this.map.getCanvas().style.cursor = 'grab';
                })
              } else {
                this.identifyPopup = new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
                  .setLngLat(this.mouseClickCoordinates)
                  .setDOMContent(popupContent)
                  .addTo(this.map)
              }
            }
          });
      } else {
        if (!osmFeatures.length) return;
        popupContent = this.dynamicCompService.injectComponent(
          MapIdentifyComponent,
          x => (x.identifyInput = osmFeatures)
        );
        if (!this.attributeDisplayOnClick) {
          this.identifyPopup = new mapboxgl.Popup().setLngLat(this.mouseClickCoordinates).setDOMContent(popupContent).addTo(this.map);
        } else {

          this.identifyPopup = new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
            .setLngLat(this.mouseClickCoordinates)
            .setDOMContent(popupContent)
            .addTo(this.map);
        }
      }
    }
  }
  plotMapWithJsonData(apijsonData, layersList = []) {
    if (!layersList.length) {
      if (apijsonData) {
        this.jsonData = apijsonData;
      }
    } else {
      this.jsonData = apijsonData;
    }

    this.updateSourcesWithStyle(this.jsonData);
    // only after you get a response from server
  }
  updateSourcesWithStyle(styleObj: mapboxgl.Style) {
    if (styleObj.center.length) {
      this.map.setCenter({ lng: styleObj.center[0], lat: styleObj.center[1] });
    }
    this.map.setZoom(styleObj.zoom);
    this.styleObj = cloneDeep(this.shareJson);
    if (styleObj.zoom >= GEOJSONLEVEL) {
      // plot geoJson
      this.makingApiCallStart = true;
      this.layersThatNeedGraphicCall = this.layerService.segregateLayersForGraphicsCall(this.map, this.legendsToDisplay, this.layerDetailsInMap).slice();
      this.geoJsonSourceIds = this.layersThatNeedGraphicCall.map(({ parent_layer_id }) => parent_layer_id);
      if (this.layersThatNeedGraphicCall.length) {

        this.layersThatNeedGraphicCall.forEach((layer, i) => {
          setTimeout(() => {
            this.makeGraphicCallForrequiredLayer(layer, i)
          }, i * 50)
        })
      } else {
        this.showLayersWithLegendCondition(styleObj, 'styleObj');
      }


    } else {
      //plot normal style Obj
      this.showLayersWithLegendCondition(styleObj, 'styleObj')


    }
  }
  legendToggleClicked(event) {
    this.showLegendsDiv = event.target.checked;
  }

  mapZoom() {
    let zoom = this.map.getZoom();
    const with1Decimal = this.sessionservice.toDecimalString(zoom, 1);
    return with1Decimal;
}
selectPlace(placeObj) {
    this.optionSelected = true;
    this.searchedParam = placeObj.properties.Address
    this._store.dispatch(new GetPlaceDetails(placeObj.id, placeObj.index, true))
    this._store.select(PlaceDetails).subscribe((res: any) => {
      if (res && Object.keys(res).length) {
        switch (res.data.geometry.type) {
          case 'Point':
            if (this.marker) {
              this.marker.remove();
            }
            this.marker = new mapboxgl.Marker().setLngLat([res.data.geometry.coordinates[0], res.data.geometry.coordinates[1]]).addTo(this.map)
            this.map.flyTo({
              center: [
                res.data.geometry.coordinates[0],
                res.data.geometry.coordinates[1]
              ],
              zoom: 10,
              // essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });

        }


      }
    })
  }
  crossClicked() {
    this.optionSelected = !this.optionSelected;
    if (this.marker) {
      this.marker.remove();
      this.mapSearch.setValue('');
    }
  }
  createLegendList(layers, iconsArr) {
    let icons = iconsArr;
    let baseLayerGroupNames = [];
    let actualLayers = layers.map(({ layer_id }) => layer_id)
    this.legendLayers = (actualLayers || []).reduce((prev, curr) => {
      let layerStyle = this.shareJson.layers.find(layer => layer.id === curr.id);
      let layerObj = {}
      layerObj['zoom_ranges'] = this.layerService.getLegendZoomRanges(curr)
      layerObj['legend_color'] = this.layerService.getLegendColor(curr)
      layerObj['name'] = curr.name;
      layerObj['_id'] = curr.id;
      layerObj['style_type'] = curr.style_type;
      layerObj['show_legend'] = !!curr.show_legend;
      layerObj['type'] = curr.type;
      layerObj['source'] = (curr.layer_type == 6) ? curr.source_layer : curr.parent_layer_id;
      layerObj['group_name'] = curr.group_name || '';
      layerObj['layer_type'] = curr.layer_type;
      layerObj['image'] = (curr.layer_type !== 2) ? this.getIcon(curr.type) : '';
      if ((curr.type === 'point') && ([1, 2, 3].includes(curr.style_type))) {
        if (curr.icon_style && curr.icon_style.length && curr.icon_style[0].iconImage) {

          layerObj['iconImageProperty'] = icons.find((icon) => icon.name.trim() === curr.icon_style[0].iconImage.trim())
        }

      }
      return [...prev, layerObj];
    }, []);
    layers.forEach(({ layer_id }) => {
      if (layer_id.layer_type === 6) {
        let index = baseLayerGroupNames.findIndex((name) => name === layer_id.group_name)
        if (index === -1) {
          baseLayerGroupNames.push(layer_id.group_name)
        }
      }
    })
    this.legendsToDisplay = [...this.legendLayers].filter((layer) => {
      if (layer.group_name) {
        return !baseLayerGroupNames.includes(layer.group_name)
      }
      return layer
    })

  }
  getIcon(type) {
    if (this.pointTypeArray.includes(type)) {
      return 'assets/images/point_sample.svg'
    } else if (this.polygonTypeArray.includes(type)) {
      return 'assets/images/polygon_sample.svg'
    } else {
      return 'assets/images/polyline_sample.svg'
    }
  }

  showHideLegendClick(legend) {
    let mapLayerIds = this.map.getStyle().layers.map(({ id }) => id)
    if (this.identifyPopup && this.identifyPopup.isOpen()) {
      this.identifyPopup.remove()
    }
    if (legend.layer_type == 1) { // vector
      if (this.polygonTypeArray.includes(legend.type)) {

        this.hideLayerInMap(mapLayerIds, legend, [legend._id, legend._id + 1, legend._id + 2]);
      } else if ([...this.polylineArray, ...this.pointTypeArray].includes(legend.type)) {

        this.hideLayerInMap(mapLayerIds, legend, [legend._id, legend._id + 1])
      }

    }
    else {
      this.map.setLayoutProperty(
        legend._id,
        'visibility',
        !legend.show_legend ? 'visible' : 'none'
      )
    }
    let index = this.legendLayers.findIndex(({ _id }) => _id === legend._id)

    this.legendLayers[index].show_legend = !legend.show_legend
  }

  hideLayerInMap(mapLayerIds, legend, specificArrayTypes) {
    mapLayerIds.forEach((mapLayerId) => {
      if (specificArrayTypes.includes(mapLayerId)) {
        this.map.setLayoutProperty(
          mapLayerId,
          'visibility',
          !legend.show_legend ? 'visible' : 'none'
        )
      }
    })

  }

  changeSourceForLayers(id) {
    if (this.map.getSource(id)) {

      this.map.getSource(id).setData(this.geoJsonData[id])
    }

  }

  makeGraphicCallForrequiredLayer(layer, index) {
    const layerType = layer.type ? layer.type : 'polygon';
    const coordinates = this.layerService.getScreenBounds(this.map).geometry.coordinates[0];
    const coordData = JSON.stringify(coordinates);
    const dataObj = {
      layer: layer.parent_layer_id,
      type: layerTypes[layerType],
      coordinates: coordData,
      simplify: true,
      zoom_level: Math.floor(+(this.map.getZoom().toFixed(1)))
    };

    this.layerService.getPublicLayerGraphicsData(this.sessionHitId, this.userToken, dataObj).subscribe(
      (response: any) => {

        let geoJson = response.data[0].geo_json;
        geoJson.features.forEach(feature => {
          if (feature.properties) feature.properties['is_approved'] = feature.is_approved;
          else feature.properties = { is_approved: feature.is_approved };
        });
        let obj = {
          geojson: cloneDeep(geoJson),
          style_type: layer.style_type
        }

        this.geoJsonData[response.data[0].id] = obj// id is parent layerId
        let ind = this.indexes.findIndex((ind) => ind === index)
        if (ind === -1) {
          this.indexes.push(index)
        }
        this.setRequiredSource(response.data[0].id)
      });

  }
  showLayersWithLegendCondition(styleObj, type) {
    let legendLayersShow = [...this.legendLayers].filter(({ show_legend }) => show_legend).map(({ _id }) => _id).reduce((prev, curr) => {
      prev.push(curr, curr + 1, curr + 2)
      return prev
    }, []) // legend layersto show only contains layer ID's
    const baseRaster = BASE_MAP_LAYERS[this.baseLayer]
    if (baseRaster && !legendLayersShow.includes(baseRaster)) {
      legendLayersShow.push(baseRaster)
    }
    let layers = cloneDeep(styleObj.layers).reduce((prev, curr) => {
      if (curr.id == 'background') {
        return [...prev, curr]
      }
      if (this.legendLayers.length) {

        if (legendLayersShow.includes(curr.id)) {
          curr['layout']['visibility'] = 'visible'
        } else {
          curr['layout']['visibility'] = 'none'
        }
      } else {
        curr['layout']['visibility'] = 'visible'
      }
      if (this.map.getZoom() >= GEOJSONLEVEL) {
        if (this.geoJsonSourceIds.includes(curr['source'])) {
          if (curr['source-layer']) {
            delete curr['source-layer']
          }
        } else {
          curr['source-layer'] = curr['source']
        }
      }
      return [...prev, curr];
    }, [])
    if (type === 'sharejson') {
      this.shareJson.layers = layers;
    } else {
      this.styleObj.layers = layers
    }
    // inner style Obj
    styleObj.layers = layers
    this.map.setStyle(styleObj);
    // setTimeout(() => console.log(this.map.getStyle()), 2000)
  }


  setRequiredSource(id) {
    this.geoJsonObjSource[id] = { data: this.geoJsonData[id].geojson, type: 'geojson' }
    this.styleObj.sources = { ...this.geoJsonObjSource }
    if (this.indexes.length === this.layersThatNeedGraphicCall.length) {
      let pbfSources = cloneDeep(this.shareJson.sources);
      for (let pbfSourceId in pbfSources) {
        if (!this.geoJsonSourceIds.includes(pbfSourceId)) {
          this.styleObj.sources[pbfSourceId] = pbfSources[pbfSourceId]
        }
      }
      setTimeout(() => {
        this.makingApiCallStart = false;
        this.showLayersWithLegendCondition(this.styleObj, 'styleObj')
        this.indexes = [];
      }, 50)
    }
    // written for checking purpose
  }
  setSpriteStyle(legendLayer) {
    if (legendLayer.iconImageProperty) {
      let xValue = (legendLayer.iconImageProperty.properties.x > 0) ? -legendLayer.iconImageProperty.properties.x : 0;
      let yValue = (legendLayer.iconImageProperty.properties.y) > 0 ? -legendLayer.iconImageProperty.properties.y : 0;
      return {
        'background-image': 'url(/assets/images/iconsSprite.png)',
        'width': '22px',
        'height': '22px',
        'border': 'none',
        'padding': '0px 0px 0px 21px',
        'backgroundPosition': `${xValue}px ${yValue}px`
      }
    } else {
      return ''
    }
  }
}
